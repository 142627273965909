import { Button, ButtonBase, CircularProgress, IconButton, Modal, Snackbar } from '@material-ui/core';
import React, { ReactElement, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { ZoomArrowLeftIcon } from '../../components/icons/ZoomArrowLeftIcon';
import { ZoomArrowRightIcon } from '../../components/icons/ZoomArrowRightIcon';
import { ZoomCrossIcon } from '../../components/icons/ZoomCrossIcon';
import { ZoomGroupIcon } from '../../components/icons/ZoomGroupIcon';
import { ZoomWarningIcon } from '../../components/icons/ZoomWarningIcon';
import {
  CompleteDemandAction,
  DemandOverviewAction,
  DemandOverviewActionLoadTabMobile,
} from '../../store/demand/actions';
import { filterNavigationOrder } from '../../store/demand/utils';
import {
  LastDemandDataTakeoverGetAction,
  LastDemandDataTakeoverAction,
} from '../../store/last-demand-data-takeover/actions';
import { ParentsDataAction, ParentsDataGetAction } from '../../store/parents-data/actions';
import { PersonalDataAction, PersonalDataActionType } from '../../store/personal-data/actions';
import { RoutingAction, RoutingRedirectToAction } from '../../store/routing/actions';
import { getCategoryIcon } from './../../store/demand/helpers';
import { white } from './../../theme';
import { mapStateToDemandOverviewProps } from './DemandOverview';
import './DemandOverviewMobile.scss';
import { getTabProgress, loadTab } from './helpers';
import {
  DemandOverviewProps,
  initialContentTabState,
  MobileNavigationButtonProps,
  NavigationForm,
  NavigationTab,
} from './types';

const MobileNavigationButton = (props: MobileNavigationButtonProps) => {
  return (
    <ButtonBase
      id={props.id}
      className="mobile-navigation-button"
      style={{ border: '1px solid white', marginLeft: 5, height: 43 }}
      focusRipple
      onClick={props.onClick}
    >
      {props.element}
    </ButtonBase>
  );
};

interface MobileCategoryButtonProps {
  title: string;
  tab: string;
  active: boolean;
  progress: number;
  onClick?: () => void;
}

const MobileCategoryButton = (props: MobileCategoryButtonProps): ReactElement => {
  const cssClass = props.active ? 'mobile-menu-category-button-active' : 'mobile-menu-category-button';
  const backgroundColor = props.active ? white.main : 'rgba(255, 255, 255, 0.21)';
  return (
    <ButtonBase
      className={cssClass}
      style={{ backgroundColor: backgroundColor, marginTop: 3, padding: 14, height: 52 }}
      focusRipple
      onClick={props.onClick}
    >
      {getCategoryIcon(props.tab, props.active, false, props.progress)}
      <p>{props.title}</p>
      {props.active && <ZoomArrowRightIcon viewBox="0 0 80 80" htmlColor={white.main}></ZoomArrowRightIcon>}
    </ButtonBase>
  );
};

export const DemandOverviewMobile = (props: DemandOverviewProps): ReactElement => {
  const [initialLoad, setInitialLoad] = useState(true);
  const [isMenuOpened, setMenuOpened] = useState(false);
  const [activeForm, setActiveForm] = useState(props.activeForm ? props.activeForm : 'education');
  const [activeTabMobile, setActiveTabMobile] = useState(props.activeTabMobile ? props.activeTabMobile : 0);
  const leftIcon = <ZoomArrowLeftIcon htmlColor={white.main} viewBox="0 0 80 80"></ZoomArrowLeftIcon>;
  const rightIcon = <ZoomArrowRightIcon htmlColor={white.main} viewBox="0 0 80 80"></ZoomArrowRightIcon>;
  const groupIcon = <ZoomGroupIcon htmlColor={white.main} viewBox="0 0 80 80"></ZoomGroupIcon>;
  const [contentTabState, setContentTabState] = useState(
    props.contentTabState ? props.contentTabState : initialContentTabState,
  );

  const [contentTabProgress, setContentTabProgress] = useState(
    props.contentTabProgress ? props.contentTabProgress : initialContentTabState,
  );
  const order = filterNavigationOrder(props.orderedTabs);

  const [openFirstAcknowlegde, setOpenFirstAcknowledge] = useState(false);
  const [openSecondAcknowledge, setOpenSecondAcknowledge] = useState(false);
  const [openErrorAcknowledge, setOpenErrorAcknowledge] = useState(false);

  useEffect(() => {
    loadTab('education', props);
    if (props.dispatch) {
      const getLastDemandData: LastDemandDataTakeoverGetAction = {
        type: LastDemandDataTakeoverAction.GET,
        payload: props.dossierId,
      };
      props.dispatch(getLastDemandData);
    }
  }, []);

  useEffect(() => {
    if (props.dispatch && props.initialDataLoaded) {
      const loadPersonalDataAction: PersonalDataActionType = {
        type: PersonalDataAction.GET,
        demandId: props.demandId,
        pifId: props.pifId,
      };
      props.dispatch(loadPersonalDataAction);
      const getParentsData: ParentsDataGetAction = {
        type: ParentsDataAction.GET,
        payload: {},
        pifId: props.pifId,
      };
      props.dispatch(getParentsData);
      props.dispatch({ type: DemandOverviewAction.COMPONENT_OPENED });
    }
  }, [props.initialDataLoaded]);

  useEffect(() => {
    setContentTabState(props.contentTabState ? props.contentTabState : initialContentTabState);
    setActiveForm(props.activeForm ? props.activeForm : 'education');
    setContentTabProgress(props.contentTabProgress ? props.contentTabProgress : initialContentTabState);
  }, [props.contentTabState, props.contentTabProgress]);

  const closeMenu = () => {
    setMenuOpened(false);
  };

  const openMenu = () => {
    setMenuOpened(true);
  };

  const getActiveMenuTitle = () => {
    for (const tab of order.tabs) {
      for (let formIndex = 0; formIndex < tab.forms.length; formIndex++) {
        const form = tab.forms[formIndex];
        if (form.form === activeForm) {
          return `${formIndex + 1}/${tab.forms.length} ${getTabContentLabel(form)}`;
        }
      }
    }
  };

  const getActiveMenuIcon = () => {
    for (const tab of order.tabs) {
      for (const form of tab.forms) {
        if (form.form === activeForm) {
          return getCategoryIcon(tab.tab, false, false, getTabProgress(tab.tab, props.tabProgress));
        }
      }
    }
  };

  const getNextMenuTitle = () => {
    let indexFound = false;
    for (const tab of order.tabs) {
      for (const form of tab.forms) {
        if (indexFound) {
          return `${getTabContentLabel(form)}`;
        }
        if (form.form === activeForm) {
          indexFound = true;
        }
      }
    }
  };

  const getNextForm = (): NavigationForm | null => {
    let indexFound = false;
    for (const tab of order.tabs) {
      for (const form of tab.forms) {
        if (indexFound) {
          return form;
        }
        if (form.form === activeForm) {
          indexFound = true;
        }
      }
    }
    return null;
  };

  const getPreviousForm = (): NavigationForm | null => {
    let previousForm = null;
    for (const tab of order.tabs) {
      for (const form of tab.forms) {
        if (form.form === activeForm) {
          return previousForm;
        }
        previousForm = form;
      }
    }
    return null;
  };

  const getFormTabIndex = (form: NavigationForm): number => {
    let tabIndex = -1;
    order.tabs.forEach((tab: NavigationTab, index: number) => {
      if (tab.forms.find((f: NavigationForm) => f.form === form.form)) {
        tabIndex = index;
      }
    });
    return tabIndex;
  };

  const setNextTab = () => {
    const nextForm = getNextForm();
    if (nextForm) {
      const tabIndex = getFormTabIndex(nextForm);
      setActiveForm(nextForm.form);
      setActiveTabMobile(tabIndex);
      loadForm(nextForm);
    }
  };

  const setPreviousTab = () => {
    const previousForm = getPreviousForm();
    if (previousForm) {
      const tabIndex = getFormTabIndex(previousForm);
      setActiveForm(previousForm.form);
      setActiveTabMobile(tabIndex);
      loadForm(previousForm);
    }
  };

  const selectTab = (tab: NavigationTab, index: number) => {
    if (index !== activeTabMobile) {
      setActiveForm(tab.forms[0].form);
      setActiveTabMobile(index);
      loadForm(tab.forms[0]);
      setMenuOpened(false);
    }
  };

  const loadForm = (form: NavigationForm) => {
    setInitialLoad(false);
    if (props.dispatch) {
      loadTab(form.form, props);
      const changeTabAction: DemandOverviewActionLoadTabMobile = {
        type: DemandOverviewAction.LOAD_TAB_MOBILE,
        payload: form.form,
      };
      props.dispatch(changeTabAction);
    }
  };

  const submitDemand = () => {
    if (props.piaUnder18) {
      setOpenErrorAcknowledge(true);
    } else {
      if (props.dispatch) {
        const completeDemandAction: CompleteDemandAction = {
          type: DemandOverviewAction.COMPLETE_DEMAND,
          payload: { dossierId: props.dossierId, demandId: props.demandId },
        };
        props.dispatch(completeDemandAction);
      }
    }
  };

  const openSecondAcknowledgeModal = () => {
    setOpenFirstAcknowledge(false);
    setOpenSecondAcknowledge(true);
  };

  if (initialLoad && !contentTabState[activeForm] && !contentTabState[activeForm]) {
    loadForm(order.tabs[0].forms[0]);
  }

  const getTabElementLabel = (tab: NavigationTab): string => {
    switch (tab.tab) {
      case 'scholarship':
        return props.tabLabelScholarshipData;
      case 'pia':
        return props.tabLabelPifData;
      case 'parents':
        return props.tabLabelParentsData;
      case 'partner':
        return props.tabLabelPartnerData;
      case 'children':
        return props.tabLabelChildrenData;
      case 'siblings':
        return props.tabLabelSiblingsData;
      case 'payout':
        return props.tabLabelPayoutData;
      case 'documents':
        return props.tabLabelAttachments;
      case 'notes':
        return props.contentLabelNotes;
    }
    return '';
  };

  const getTabContentLabel = (form: NavigationForm): string => {
    switch (form.form) {
      case 'education':
        return props.contentLabelEducation;
      case 'request':
        return props.contentLabelRequest;
      case 'personalData':
        return props.contentLabelPersonalData;
      case 'contactData':
        return props.contentLabelContactData;
      case 'cv':
        return props.contentLabelCv;
      case 'income':
        return props.contentLabelIncome;
      case 'expenses':
        return props.contentLabelExpenses;
      case 'parents':
        return props.contentLabelParents;
      case 'father':
        return props.contentLabelFather;
      case 'mother':
        return props.contentLabelMother;
      case 'guardian':
        return props.contentLabelGuardian;
      case 'partner':
        return props.contentLabelPartner;
      case 'children':
        return props.contentLabelChildren;
      case 'siblings':
        return props.contentLabelSiblings;
      case 'payout':
        return props.contentLabelPayout;
      case 'documents':
        return props.contentLabelDocuments;
      case 'notes':
        return props.contentLabelNotes;
    }
    return '';
  };

  const orderedTabs = order.tabs.map((tab, index) => {
    return (
      <MobileCategoryButton
        key={index}
        active={index === activeTabMobile}
        title={getTabElementLabel(tab)}
        tab={tab.tab}
        progress={getTabProgress(tab.tab, props.tabProgress)}
        onClick={() => selectTab(tab, index)}
      ></MobileCategoryButton>
    );
  });

  const getActiveForm = () => {
    for (const tab of order.tabs) {
      for (const form of tab.forms) {
        if (form.form === activeForm) {
          return form;
        }
      }
    }
    return null;
  };

  const closeSecondAcknowledgeAndResetError = () => {
    setOpenSecondAcknowledge(false);
    if (props.dispatch) {
      props.dispatch({ type: DemandOverviewAction.CLEAR_DEMAND_ERROR });
    }
  };

  const isFinalCompleteDemandButtonDisabled = props.demandCompletionError !== undefined;

  const goToDashboard = () => {
    if (props.dispatch) {
      const redirectAction: RoutingRedirectToAction = {
        type: RoutingAction.REDIRECT_TO,
        payload: '/dashboard/dossier/' + props.dossierId,
      };
      props.dispatch(redirectAction);
    }
  };

  return (
    <>
      {props.showSuccess && (
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          autoHideDuration={2000}
          message={props.labelSavedSuccessfully}
          key={activeForm}
        />
      )}
      <div id="complete-demand" className="demand-overview-mobile-complete-request">
        <div>
          <Button
            variant="contained"
            size="large"
            color="primary"
            fullWidth
            onClick={() => setOpenFirstAcknowledge(true)}
          >
            {props.labelCompleteDemand}
          </Button>
        </div>
      </div>
      <div className="demand-overview-mobile-main-content">
        {contentTabState[activeForm] && getActiveForm()?.component}
        {contentTabProgress[activeForm] && getActiveForm()?.component !== undefined && <CircularProgress />}
        {getActiveForm()?.component === undefined && <p>Komponente existiert nicht</p>}
        {!contentTabState[activeForm] &&
          !contentTabProgress[activeForm] &&
          getActiveForm()?.component !== undefined && <p>{props.labelSavedSuccessfully}</p>}
      </div>
      {!isMenuOpened && (
        <div className="demand-overview-mobile-fixed-menu">
          <div className="mobile-menu-status">
            {getActiveMenuIcon()}
            <div className="mobile-menu-info">
              <p>
                {activeTabMobile + 1}. {getTabElementLabel(order.tabs[activeTabMobile])}
              </p>
              <p>{getActiveMenuTitle()}</p>
              <small>
                {props.labelNext}: {getNextMenuTitle()}
              </small>
            </div>
          </div>
          <div className="mobile-navigation">
            <MobileNavigationButton
              id="previous-tab"
              element={leftIcon}
              onClick={() => setPreviousTab()}
            ></MobileNavigationButton>
            <MobileNavigationButton id="open-menu" onClick={openMenu} element={groupIcon}></MobileNavigationButton>
            <MobileNavigationButton
              id="next-tab"
              element={rightIcon}
              onClick={() => setNextTab()}
            ></MobileNavigationButton>
          </div>
        </div>
      )}
      {isMenuOpened && (
        <div className="demand-overview-mobile-fixed-menu-opened">
          <div className="demand-overview-mobile-content-title">
            <h2>{props.mobileTabsTitleLabel}</h2>
            <IconButton onClick={closeMenu} style={{ padding: 0 }}>
              <ZoomCrossIcon viewBox="0 0 80 80" htmlColor="white"></ZoomCrossIcon>
            </IconButton>
          </div>
          <div className="demand-overview-mobile-content-tab-container">{orderedTabs}</div>
        </div>
      )}

      <Modal
        open={openFirstAcknowlegde}
        aria-labelledby="demand-complete-acknowledge"
        aria-describedby="demand-complete-acknowledge-description"
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <div className="demand-modal-desktop">
          <h1 id="demand-complete-acknowledge">{props.contentLabelFirstAcknowledgementTitle}</h1>
          <div id="demand-complete-acknowledge-description">
            <p>{props.contentLabelFirstAcknowledgementParagraph1}</p>
            <p>{props.contentLabelFirstAcknowledgementParagraph2}</p>
          </div>

          <div className="demand-modal-desktop-actions">
            <Button color="primary" variant="outlined" fullWidth onClick={() => setOpenFirstAcknowledge(false)}>
              {props.contentLabelModalCancel}
            </Button>
            <Button color="primary" variant="contained" fullWidth onClick={() => openSecondAcknowledgeModal()}>
              {props.contentLabelFirstAcknowledgementSubmit}
            </Button>
          </div>
        </div>
      </Modal>

      <Modal
        open={openSecondAcknowledge}
        aria-labelledby="demand-complete-acknowledge-two"
        aria-describedby="demand-complete-acknowledge-two-description"
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <div className="demand-modal-desktop">
          <h1 id="demand-complete-acknowledge-two">{props.contentLabelSecondAcknowledgementTitle}</h1>
          <div id="demand-complete-acknowledge-two-description">
            <p dangerouslySetInnerHTML={{ __html: props.contentLabelSecondAcknowledgementParagraph1 }}></p>
            <p dangerouslySetInnerHTML={{ __html: props.contentLabelSecondAcknowledgementParagraph2 }}></p>
          </div>

          {props.demandCompletionError && (
            <div className="demand-error-message">
              <ZoomWarningIcon htmlColor={white.main} viewBox="0 0 80 80"></ZoomWarningIcon>
              <span>{props.demandCompletionError}</span>
            </div>
          )}

          {!props.inProgress && (
            <div className="demand-modal-desktop-actions">
              <Button
                color="primary"
                variant="outlined"
                fullWidth
                onClick={() => closeSecondAcknowledgeAndResetError()}
              >
                {props.contentLabelModalCancel}
              </Button>
              <Button
                color="primary"
                variant="contained"
                disabled={isFinalCompleteDemandButtonDisabled}
                fullWidth
                onClick={() => submitDemand()}
              >
                {props.contentLabelSecondAcknowledgementSubmit}
              </Button>
            </div>
          )}
          {!!props.inProgress && (
            <div className="demand-modal-desktop-progress">
              <CircularProgress></CircularProgress>
            </div>
          )}
        </div>
      </Modal>

      <Modal
        open={openErrorAcknowledge}
        aria-labelledby="demand-complete-error"
        aria-describedby="demand-complete-error"
        onClose={() => {
          setOpenErrorAcknowledge(false);
          setOpenSecondAcknowledge(false);
        }}
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <div className="demand-modal-desktop">
          <h1 id="demand-complete-acknowledge-two">{props.contentLabelCompleteDemandErrorTitle}</h1>
          <div id="demand-complete-acknowledge-two-description">
            <p>{props.contentLabelCompleteDemandErrorParagraph1}</p>
            <p>{props.contentLabelCompleteDemandErrorParagraph2}</p>
          </div>
        </div>
      </Modal>

      <Modal
        open={!!props.completed}
        aria-labelledby="demand-complete-success"
        aria-describedby="demand-complete-success"
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <div className="demand-modal-desktop">
          <h1>{props.completionTitle}</h1>
          <div id="demand-complete-success-mobile">
            <p dangerouslySetInnerHTML={{ __html: props.completionParagraph1 }}></p>
            <p>{props.completionParagraph2}</p>
            <p>{props.completionParagraph3}</p>
          </div>
          <div className="demand-modal-desktop-actions">
            <Button variant="contained" color="primary" fullWidth onClick={goToDashboard}>
              {props.labelBackToDashboard}
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

// default export the connected component
export default withRouter(connect(mapStateToDemandOverviewProps)(DemandOverviewMobile));
