import { Action } from 'redux';
import { Epic, ofType } from 'redux-observable';
import { Observable, of } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { customAjax } from '../../utils/ajax-wrapper';
import { handleRequestFailureInEpic } from '../../utils/error-handling';
import { RoutingAction, RoutingActionType } from '../routing/actions';
import { RepaymentConditionsChangeAction, RepaymentConditionsDataAction } from './actions';

const getRepaymentConditionsChangeRequestSubmitResult = (
  payload: AjaxResponse,
  dossierId: number,
): RoutingActionType => {
  return {
    type: RoutingAction.REDIRECT_TO,
    payload: `/my-contracts/${dossierId}`,
  };
};

export const sendRepaymentConditionsChangeRequest: Epic<Action, Action> = (
  action$: Observable<Action>,
): Observable<Action> =>
  action$.pipe(
    ofType(RepaymentConditionsDataAction.REPAYMENT_CONDITIONS_CHANGE),
    mergeMap((action) => {
      const changeAction: RepaymentConditionsChangeAction = action as RepaymentConditionsChangeAction;
      const dossierId = changeAction.dossierId;
      return customAjax({
        subRoute: `/dossier-intranet/v1/cases/${dossierId}`,
        withCredentials: true,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept-Language': 'de-CH',
        },
        body: changeAction.payload,
      }).pipe(
        map(
          (response) => getRepaymentConditionsChangeRequestSubmitResult(response, dossierId),
          catchError((error) => {
            return of(handleRequestFailureInEpic(error));
          }),
        ),
      );
    }),
    catchError((error) => {
      return of(handleRequestFailureInEpic(error));
    }),
  );
