import { applyMiddleware, createStore } from 'redux';
import { combineEpics, createEpicMiddleware } from 'redux-observable';
import { demoSetDescriptionTimeoutEpic, demoSetTitleTimeoutEpic } from './demo/epics';
import { demoReducer } from './demo/reducers';
import { documentsReducer } from './documents/reducers';
import {
  sendFileEpic,
  resetStatusEpic,
  showStatusMessageEpic,
  showErrorEpic,
  getDocumentRolesEpic,
  deleteDocumentEpic,
} from './documents/epics';
import { personalDataReducer } from './personal-data/reducers';
import { getPersonalDataEpic, sendPersonalDataEpic } from './personal-data/epics';
import { getContactDataEpic, getPostalCodeDataEpic, sendContactDataEpic } from './contact-data/epics';
import { headerDataReducer } from './header/reducers';
import { footerDataReducer } from './footer/reducers';
import { dashboardReducer } from './dashboard/reducers';
import { educationDataReducer } from './education-data/reducers';
import { payoutDataReducer } from './payout-data/reducers';
import { userDataReducer, userMyEducationDataReducer, userMyDataReducer } from './user-data/reducers';
import { composeWithDevTools } from 'redux-devtools-extension';
import { demandReducer } from './demand/reducers';
import {
  getEducationDataEpic,
  sendEducationDataEpic,
  sendEducationInterruptRequest,
  sendEducationChangeRequest,
  sendEducationCompletedRequest,
  sendEducationCanceledRequest,
  getInstitutionEpic,
  getInstitutionTypesEpic,
  getDegreeProgramsEpic,
} from './education-data/epics';
import { getFatherDataEpic, editFatherDataEpic, getFatherPostalCodeDataEpic } from './father-data/epics';
import { getMotherDataEpic, editMotherDataEpic, getMotherPostalCodeDataEpic } from './mother-data/epics';
import { getGuardianDataEpic, editGuardianDataEpic, getGuardianPostalCodeDataEpic } from './guardian-data/epics';
import {
  getPayoutDataEpic,
  sendPayoutDataEpic,
  getPayoutPostalCodeDataEpic,
  getParentDataForPayoutEpic,
  getPersonalDataForPayoutEpic,
} from './payout-data/epics';
import { outgoingDataReducer } from './outgoing-data/reducers';
import { getOutgoingDataEpic, editOutgoingDataEpic } from './outgoing-data/epics';
import { editIncomingDataEpic, getIncomingDataEpic } from './incoming-data/epics';
import { partnerDataReducer } from './partner-data/reducers';
import { siblingsDataReducer } from './siblings-data/reducers';
import { addCVItemDataEpic, deleteCVItemDataEpic, editCVItemDataEpic, getCVDataEpic } from './cv-data/epics';
import { addChildDataEpic, deleteChildDataEpic, editChildDataEpic, getChildrenDataEpic } from './children-data/epics';
import { getScholarshipInfoDataEpic, editScholarshipDataEpic, addScholarshipDataEpic } from './scholarship-data/epics';
import {
  addSiblingDataEpic,
  deleteSiblingDataEpic,
  editSiblingDataEpic,
  getSiblingsDataEpic,
} from './siblings-data/epics';
import {
  getActiveDemandEpic,
  launchCreateNewDemandEpic,
  createNewDemandEpic,
  setActiveDemandEpic,
  getUserDataEpic,
  getUserMyEducationDataEpic,
  getUserMyDataEpic,
  finalizeAccountAfterIdentificationEpic,
  finalizeUserAccountEpic,
  onSuccessfulFinalizationEpic,
  getActiveDemandProgressEpic,
  canCreateNewDemandEpic,
  setNotificationSettingsEpic,
} from './user-data/epics';
import { getParentsDataEpic, editParentsDataEpic } from './parents-data/epics';
import { cvDataReducer } from './cv-data/reducers';
import { getParentDataEpic, sendPartnerDataEpic } from './partner-data/epics';
import { incomingDataReducer } from './incoming-data/reducers';
import { childrenDataReducer } from './children-data/reducers';
import { submitIdentificationDataEpic, identificationTriggerErrorHideEpic } from './identification-data/epics';
import { scholarshipDataReducer } from './scholarship-data/reducers';
import { fatherDataReducer } from './father-data/reducers';
import { motherDataReducer } from './mother-data/reducers';
import { guardianDataReducer } from './guardian-data/reducers';
import { parentsDataReducer } from './parents-data/reducers';
import { errorHandlingReducer } from './error-handling/reducers';
import { i18nReducer, i18nOptionReducer } from './i18n/i18nReducer';
import { getAllTranslationLabelsEpic } from './i18n/epics';
import { holderDataReducer } from './holder-data/reducers';
import { getHolderDataEpic } from './holder-data/epics';
import {
  getMandatoryPowerOfAttorneyDataEpic,
  addMandatoryPowerOfAttorneyDataEpic,
  deleteMandatoryPowerOfAttorneyDataEpic,
  getDossierProfilesAndRedirectToDashboardEpic,
  mandotoryPoaTriggerErrorHideEpic,
} from './mandatory-power-of-attorney-data/epics';
import { mandatoryPowerOfAttorneyDataReducer } from './mandatory-power-of-attorney-data/reducers';
import { finalizeDemandEpic, refreshDemandProgressEpic, requestDemandProgressEpic } from './demand/epics';
import { redirectEpic } from './routing/epics';
import { persistStore, persistCombineReducers } from 'redux-persist';
import { identificationDataReducer } from './identification-data/reducers';
import autoMergeLevel1 from 'redux-persist/lib/stateReconciler/autoMergeLevel1';
import storageSession from 'redux-persist/lib/storage/session';
import { createBrowserHistory } from 'history';
import { editContactDataPageReducer } from './my-data-contact/reducers';
import { getEditContactDataPageEpic, sendEditContactDataPageEpic } from './my-data-contact/epics';
import { editPersonalDataPageReducer } from './my-data-personal/reducers';
import { getEditPersonalDataPageEpic, sendEditPersonalDataPageEpic } from './my-data-personal/epics';
import { editAddressDataPageReducer } from './my-data-address/reducers';
import {
  getAddressPostalCodeDataEpic,
  getEditAddressDataPageEpic,
  sendEditAddressDataPageEpic,
} from './my-data-address/epics';
import { myDataOverviewPageReducer } from './my-data/reducers';
import { getMyDataOverviewPageEpic } from './my-data/epics';
import { getEditPayoutAddressDataPageEpic, sendEditPayoutAddressDataPageEpic } from './my-data-payout-address/epics';
import { editPayoutAddressDataPageReducer } from './my-data-payout-address/reducers';
import { customAjax } from '../utils/ajax-wrapper';
import { notificationReducer } from './notifications/notificationReducers';
import { myDemandPageReducer } from './my-demand/reducers';
import { getMyDemandDataEpic } from './my-demand/epics';
import {
  getMessagesEpic,
  getSingleMessageEpic,
  createConversationEpic,
  replyToConversationEpic,
  sendPaymentAddressChangeFileEpic,
  showCaseUploadStatusMessageEpic,
  showCaseUploadErrorEpic,
  resetCaseUploadStatusEpic,
  markNotificationAsReadEpic,
  markAllNotificationsAsReadEpic,
  deleteNotificationDocumentEpic,
} from './notifications/epics';
import { authoritiesDataReducer } from './authorities/reducers';
import {
  getAuthoritiesDataEpic,
  addAuthorityDataEpic,
  editAuthorityDataEpic,
  deleteAuthorityDataEpic,
} from './authorities/epics';
import { myLoanReducer } from './my-loan/reducers';
import { getMyLoanDataEpic } from './my-loan/epics';
import { getLastDemandDataEpic } from './last-demand-data-takeover/epics';
import { getSingleMyContractsDataEpic, getAllMyContractsDataEpic } from './my-contracts/epics';
import { myContractsReducer } from './my-contracts/reducers';
import { sendRepaymentConditionsChangeRequest } from './repayment-conditions/epics';
import { sendForbearanceRequestEpic } from './forbearance/epics';
import { newPermissionDataReducer } from './admin-portal/new-permission/reducers';
import { addPermissionDataEpic, getNewPermissionUsersDataEpic } from './admin-portal/new-permission/epics';
import { overviewDataReducer } from './admin-portal/overview/reducers';
import { getOverviewDataEpic, deleteOverviewDataEpic, getOverviewUsersDataEpic } from './admin-portal/overview/epics';
import {
  editPersonDetailsDataEpic,
  getPersonDetailsDataEpic,
  editPermissionDataEpic,
  deletePermissionDataEpic,
  sendManualDemandActivation,
} from './admin-portal/person-details/epics';
import { personDetailsDataReducer } from './admin-portal/person-details/reducers';
import { notesReducer } from './notes-data/notesReducer';
import { editNotesDataEpic, getNotesDataEpic } from './notes-data/epics';

export const history = createBrowserHistory();

// Global Store Configuration
const epicMiddleware = createEpicMiddleware({
  dependencies: {
    history: history,
    customAjax,
  },
});

const persistConfig = {
  key: 'root',
  storage: storageSession,
  stateReconciler: autoMergeLevel1,
  whitelist: ['userData'],
};

// Add reducers here
const combinedReducers = persistCombineReducers(persistConfig, {
  demo: demoReducer,
  personalData: personalDataReducer,
  headerData: headerDataReducer,
  footerData: footerDataReducer,
  dashboardData: dashboardReducer,
  educationData: educationDataReducer,
  payoutData: payoutDataReducer,
  demandData: demandReducer,
  outgoingData: outgoingDataReducer,
  partnerData: partnerDataReducer,
  cvData: cvDataReducer,
  incomingData: incomingDataReducer,
  childrenData: childrenDataReducer,
  siblingsData: siblingsDataReducer,
  documentsData: documentsReducer,
  scholarshipData: scholarshipDataReducer,
  fatherData: fatherDataReducer,
  motherData: motherDataReducer,
  guardianData: guardianDataReducer,
  parentsData: parentsDataReducer,
  errorHandling: errorHandlingReducer,
  userData: userDataReducer,
  userMyEducationData: userMyEducationDataReducer,
  userMyData: userMyDataReducer,
  translationLabels: i18nReducer,
  mandatoryPowerOfAttorneyData: mandatoryPowerOfAttorneyDataReducer,
  holderData: holderDataReducer,
  identificationData: identificationDataReducer,
  editContactDataPage: editContactDataPageReducer,
  editPersonalDataPage: editPersonalDataPageReducer,
  editPayoutAddressDataPage: editPayoutAddressDataPageReducer,
  editAddressDataPage: editAddressDataPageReducer,
  myDataOverview: myDataOverviewPageReducer,
  notificationData: notificationReducer,
  myDemandsData: myDemandPageReducer,
  authoritiesData: authoritiesDataReducer,
  myLoanData: myLoanReducer,
  myContractsData: myContractsReducer,
  notesData: notesReducer,
  translationOptions: i18nOptionReducer,
  newPermissionDataStore: newPermissionDataReducer,
  overviewDataStore: overviewDataReducer,
  personDetailsDataStore: personDetailsDataReducer,
});

// Add epics here
const combinedEpics = combineEpics(
  demoSetTitleTimeoutEpic,
  demoSetDescriptionTimeoutEpic,
  sendPersonalDataEpic,
  getScholarshipInfoDataEpic,
  editScholarshipDataEpic,
  addScholarshipDataEpic,
  getContactDataEpic,
  sendContactDataEpic,
  getPostalCodeDataEpic,
  getPersonalDataEpic,
  getEducationDataEpic,
  sendEducationDataEpic,
  sendEducationInterruptRequest,
  sendEducationChangeRequest,
  sendEducationCompletedRequest,
  sendEducationCanceledRequest,
  getParentsDataEpic,
  editParentsDataEpic,
  getFatherDataEpic,
  editFatherDataEpic,
  getFatherPostalCodeDataEpic,
  getMotherDataEpic,
  editMotherDataEpic,
  getMotherPostalCodeDataEpic,
  getGuardianDataEpic,
  editGuardianDataEpic,
  getGuardianPostalCodeDataEpic,
  getPayoutDataEpic,
  sendPayoutDataEpic,
  getPayoutPostalCodeDataEpic,
  getParentDataForPayoutEpic,
  getPersonalDataForPayoutEpic,
  getOutgoingDataEpic,
  editOutgoingDataEpic,
  getIncomingDataEpic,
  editIncomingDataEpic,
  getCVDataEpic,
  addCVItemDataEpic,
  editCVItemDataEpic,
  deleteCVItemDataEpic,
  getParentDataEpic,
  sendPartnerDataEpic,
  getChildrenDataEpic,
  addChildDataEpic,
  editChildDataEpic,
  deleteChildDataEpic,
  getSiblingsDataEpic,
  addSiblingDataEpic,
  editSiblingDataEpic,
  deleteSiblingDataEpic,
  sendFileEpic,
  deleteDocumentEpic,
  showStatusMessageEpic,
  showErrorEpic,
  resetStatusEpic,
  getDocumentRolesEpic,
  getActiveDemandEpic,
  createNewDemandEpic,
  launchCreateNewDemandEpic,
  setActiveDemandEpic,
  requestDemandProgressEpic,
  refreshDemandProgressEpic,
  getAllTranslationLabelsEpic,
  getMandatoryPowerOfAttorneyDataEpic,
  addMandatoryPowerOfAttorneyDataEpic,
  deleteMandatoryPowerOfAttorneyDataEpic,
  getDossierProfilesAndRedirectToDashboardEpic,
  submitIdentificationDataEpic,
  finalizeDemandEpic,
  getHolderDataEpic,
  getUserDataEpic,
  getUserMyEducationDataEpic,
  getUserMyDataEpic,
  redirectEpic,
  mandotoryPoaTriggerErrorHideEpic,
  identificationTriggerErrorHideEpic,
  getEditContactDataPageEpic,
  sendEditContactDataPageEpic,
  getEditPersonalDataPageEpic,
  sendEditPersonalDataPageEpic,
  getEditAddressDataPageEpic,
  sendEditAddressDataPageEpic,
  getAddressPostalCodeDataEpic,
  getEditAddressDataPageEpic,
  sendEditAddressDataPageEpic,
  getAddressPostalCodeDataEpic,
  finalizeUserAccountEpic,
  finalizeAccountAfterIdentificationEpic,
  onSuccessfulFinalizationEpic,
  redirectEpic,
  mandotoryPoaTriggerErrorHideEpic,
  identificationTriggerErrorHideEpic,
  getMyDataOverviewPageEpic,
  getEditPayoutAddressDataPageEpic,
  sendEditPayoutAddressDataPageEpic,
  getActiveDemandProgressEpic,
  canCreateNewDemandEpic,
  getMyDemandDataEpic,
  getMessagesEpic,
  getSingleMessageEpic,
  createConversationEpic,
  replyToConversationEpic,
  getAuthoritiesDataEpic,
  addAuthorityDataEpic,
  editAuthorityDataEpic,
  deleteAuthorityDataEpic,
  sendPaymentAddressChangeFileEpic,
  showCaseUploadStatusMessageEpic,
  showCaseUploadErrorEpic,
  resetCaseUploadStatusEpic,
  markNotificationAsReadEpic,
  markAllNotificationsAsReadEpic,
  deleteNotificationDocumentEpic,
  getMyLoanDataEpic,
  getLastDemandDataEpic,
  getAllMyContractsDataEpic,
  getSingleMyContractsDataEpic,
  sendRepaymentConditionsChangeRequest,
  sendForbearanceRequestEpic,
  getNewPermissionUsersDataEpic,
  getOverviewDataEpic,
  deleteOverviewDataEpic,
  getOverviewUsersDataEpic,
  getPersonDetailsDataEpic,
  editPersonDetailsDataEpic,
  editPermissionDataEpic,
  deletePermissionDataEpic,
  addPermissionDataEpic,
  sendManualDemandActivation,
  editNotesDataEpic,
  getNotesDataEpic,
  setNotificationSettingsEpic,
  getInstitutionTypesEpic,
  getInstitutionEpic,
  getDegreeProgramsEpic,
);

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const configureStore = () => {
  const store = createStore(combinedReducers, composeWithDevTools(applyMiddleware(epicMiddleware)));
  epicMiddleware.run(combinedEpics);

  const persistor = persistStore(store);
  return { store, persistor };
};
