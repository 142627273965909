import produce from 'immer';
import { EditPersonalDataPageAction, EditPersonalDataPageActionType } from './actions';
import { EditPersonalDataPage } from './types';

export const initialState: EditPersonalDataPage = {};

export function editPersonalDataPageReducer(
  state = initialState,
  action: EditPersonalDataPageActionType,
): EditPersonalDataPage {
  return produce(state, (draft) => {
    switch (action.type) {
      case EditPersonalDataPageAction.GET:
        draft.isLoading = true;
        break;
      case EditPersonalDataPageAction.RESULT:
        draft = action.payload;
        break;
      case EditPersonalDataPageAction.GET_RESULT:
        draft = action.payload;
        draft.isLoading = false;
        break;
    }
    return draft;
  });
}
