import produce from 'immer';
import { EditAddressDataPageAction, EditAddressDataPageActionType } from './actions';
import { EditAddressDataPage } from './types';

export const initialState: EditAddressDataPage = {};

export function editAddressDataPageReducer(
  state = initialState,
  action: EditAddressDataPageActionType,
): EditAddressDataPage {
  return produce(state, (draft) => {
    switch (action.type) {
      case EditAddressDataPageAction.GET:
        draft.isLoading = true;
        break;
      case EditAddressDataPageAction.RESULT:
        draft = action.payload;
        break;
      case EditAddressDataPageAction.GET_RESULT:
        draft = action.payload;
        draft.isLoading = false;
        break;
      case EditAddressDataPageAction.GET_POSTAL_CODES:
        draft = action.payload;
        break;
      case EditAddressDataPageAction.GET_POSTAL_CODES_RESULT:
        draft.town = action.payload.extendedName;
        break;
    }
    return draft;
  });
}
