import {
  Breadcrumbs,
  Button,
  CircularProgress,
  Container,
  Link,
  ListItemText,
  Menu,
  MenuItem,
  MenuProps,
  withStyles,
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import React, { ReactElement, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Action } from 'redux';
import { HolderDropDownProps } from '../../components/base-page-components/holder-dropdown/HolderDropDown';
import ZoomNotification, {
  ZoomNotificationProps,
} from '../../components/base-page-components/notification/ZoomNotification';
import { ZoomArrowDownIcon } from '../../components/icons/ZoomArrowDownIcon';
import { ZoomScholarshipIcon } from '../../components/icons/ZoomScholarshipIcon';
import { HolderDataAction, HolderDataGetAction } from '../../store/holder-data/actions';
import { i18nLabel } from '../../store/i18n/helpers';
import { WIAAStore } from '../../store/types';
import { UserDataAction, UserMyEducationDataGetAction } from '../../store/user-data/actions';
import { blue, darkBlue } from '../../theme';
import { mapStoreToHolderDropDownProps } from '../../utils/mappers';
import { formatUnreadNotifications } from '../../utils/unread-notifications';
import ZoomFooter, {
  mapStoreToFooterProps,
  ZoomFooterProps,
} from './../../components/base-page-components/footer/ZoomFooter';
import ZoomHeader, {
  mapStoreToHeaderProps,
  ZoomHeaderProps,
} from './../../components/base-page-components/header/ZoomHeader';
import { NavigationItems } from '../../components/base-page-components/navigation/Navigation';

export interface EducationProps {
  educationId?: string;
  educationType?: string;
  educationFacilityName?: string;
  educationFacilityLocation?: string;
  duration?: string;
  isCurrent?: boolean;
}

export interface MyEducationsOverviewProps {
  labelTitle?: string;
  labelDescription?: string;
  labelCurrentEducationSubTitle?: string;
  labelPastEducationsSubtitle?: string;
  labelEducationType?: string;
  labelEducationFacilityName?: string;
  labelEducationFacilityLocation?: string;
  labelDuration?: string;
  headerProps: ZoomHeaderProps;
  footerProps: ZoomFooterProps;
  breadcrumbLabels: Array<string>;
  labelBackArrow: string;
  educations: Array<EducationProps>;
  notifications: Array<ZoomNotificationProps>;
  labelChooseEducationState?: string;
  labelEducationFinishedState?: string;
  labelEducationChangedState?: string;
  labelEducationInterruptedState?: string;
  labelEducationCanceledState?: string;
  labelMarkAsRead?: string;
  labelGoToAction?: string;
  errorLabels?: { [key: string]: string };
  dossierId: number;
  isLoading?: boolean;
  holderDropDownProps: HolderDropDownProps;
  dispatch?: (action: Action) => void;
}

const StyledMenu = withStyles({
  paper: {
    border: '1px solid ' + blue.main,
  },
})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    color: blue.main,
    width: '240px',
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
    '& .MuiListItemText-root': {
      marginLeft: '7px',
    },
  },
}))(MenuItem);

export const MyEducationsOverview = (props: MyEducationsOverviewProps): ReactElement => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (props.dispatch) {
      const getUserMyEducationData: UserMyEducationDataGetAction = {
        type: UserDataAction.GET_USER_EDUCATION_DATA,
        payload: props.dossierId,
      };
      props.dispatch(getUserMyEducationData);
    }
  }, []);

  useEffect(() => {
    if (props.dispatch && props.dossierId) {
      const getHolderData: HolderDataGetAction = {
        type: HolderDataAction.GET,
      };
      props.dispatch(getHolderData);
    }
  }, []);

  const renderCurrentEducations = props.educations.map((currentEducation) => {
    if (currentEducation.isCurrent) {
      return (
        <div key={currentEducation.educationId} className="education-info-content clearfix">
          <Grid container spacing={2} className="education-info-wrapper">
            <Grid item xs={12} md={4} className="education-info-block">
              <p className="ei-label-line">{props.labelEducationType}</p>
              <p className="ei-value-line">{currentEducation.educationType}</p>
            </Grid>
            <Grid item xs={12} md={4} className="education-info-block">
              <p className="ei-label-line">{props.labelEducationFacilityName}</p>
              <p className="ei-value-line">{currentEducation.educationFacilityName}</p>
            </Grid>
            <Grid item xs={12} md={4} className="education-info-block">
              <p className="ei-label-line">{props.labelEducationFacilityLocation}</p>
              <p className="ei-value-line">{currentEducation.educationFacilityLocation}</p>
            </Grid>
            <Grid item xs={12} md={4} className="education-info-block">
              <p className="ei-label-line">{props.labelDuration}</p>
              <p className="ei-value-line">{currentEducation.duration}</p>
            </Grid>
          </Grid>
          <p className="ei-btn-line">
            <Button
              aria-controls="customized-menu"
              aria-haspopup="true"
              color="primary"
              type="reset"
              variant="outlined"
              onClick={handleClick}
            >
              {props.labelChooseEducationState}
              <ZoomArrowDownIcon viewBox="0 0 80 80" htmlColor={blue.main} />
            </Button>
            <StyledMenu
              id="customized-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <Link href={'/edit-complete-education/' + props.dossierId}>
                <StyledMenuItem>
                  <ListItemText primary={props.labelEducationFinishedState} />
                </StyledMenuItem>
              </Link>

              <Link href={'/edit-change-education/' + props.dossierId}>
                <StyledMenuItem>
                  <ListItemText primary={props.labelEducationChangedState} />
                </StyledMenuItem>
              </Link>
              <Link href={'/edit-interrupted-education/' + props.dossierId}>
                <StyledMenuItem>
                  <ListItemText primary={props.labelEducationInterruptedState} />
                </StyledMenuItem>
              </Link>
              <Link href={'/edit-canceled-education/' + props.dossierId}>
                <StyledMenuItem>
                  <ListItemText primary={props.labelEducationCanceledState} />
                </StyledMenuItem>
              </Link>
            </StyledMenu>
          </p>
        </div>
      );
    }
  });

  const renderPastEducations = props.educations.map((pastEducation) => {
    if (!pastEducation.isCurrent) {
      return (
        <div key={pastEducation.educationId} className="education-info-content clearfix">
          <Grid container spacing={2} className="education-info-wrapper">
            <Grid item xs={12} md={4} className="education-info-block">
              <p className="ei-label-line">{props.labelEducationType}</p>
              <p className="ei-value-line">{pastEducation.educationType}</p>
            </Grid>
            <Grid item xs={12} md={4} className="education-info-block">
              <p className="ei-label-line">{props.labelEducationFacilityName}</p>
              <p className="ei-value-line">{pastEducation.educationFacilityName}</p>
            </Grid>
            <Grid item xs={12} md={4} className="education-info-block">
              <p className="ei-label-line">{props.labelEducationFacilityLocation}</p>
              <p className="ei-value-line">{pastEducation.educationFacilityLocation}</p>
            </Grid>
            <Grid item xs={12} md={4} className="education-info-block">
              <p className="ei-label-line">{props.labelDuration}</p>
              <p className="ei-value-line">{pastEducation.duration}</p>
            </Grid>
          </Grid>
        </div>
      );
    }
  });

  const notificationElements = props.notifications.map((notification, index) => (
    <ZoomNotification
      id={notification.id}
      labelText={notification.labelText}
      labelButtonText={props.labelMarkAsRead}
      labelGoToActionText={props.labelGoToAction}
      key={index}
      dossierId={props.dossierId}
      clientEventIds={notification.clientEventIds}
      isSuccess={notification.isSuccess}
      isWarning={notification.isWarning}
      isError={notification.isError}
      dispatch={props.dispatch}
    />
  ));

  return (
    <>
      <ZoomHeader {...props.headerProps} dispatch={props.dispatch} />
      <main className="main">
        {props.isLoading ? (
          <div style={{ textAlign: 'center' }}>
            <CircularProgress />
          </div>
        ) : (
          <>
            {' '}
            <Container disableGutters={window.innerWidth <= 960}>{notificationElements}</Container>
            <Container>
              <div id="main-page-container">
                <Breadcrumbs separator=">" aria-label="breadcrumb">
                  <Link
                    style={{ fontFamily: 'Proxima Nova Cond' }}
                    color="inherit"
                    href={'/dashboard/dossier/' + props.dossierId}
                  >
                    <b>{props.breadcrumbLabels && props.breadcrumbLabels[0]}</b>
                  </Link>
                  <Link style={{ fontFamily: 'Proxima Nova Cond' }} color="inherit">
                    {props.breadcrumbLabels && props.breadcrumbLabels[1]}
                  </Link>
                </Breadcrumbs>
                <NavigationItems
                  holderDropDownProps={props.holderDropDownProps}
                  mainPageTopInfo={false}
                  labelBackArrow={props.labelBackArrow}
                  dispatch={props.dispatch}
                  title={props.labelTitle}
                />
                <p className="subtitle-line">{props.labelDescription}</p>
                <div className="education-info-container">
                  <h2 className="education-info-title">
                    <ZoomScholarshipIcon viewBox="0 0 80 80" htmlColor={darkBlue.main} />
                    {props.labelCurrentEducationSubTitle}
                  </h2>
                  {renderCurrentEducations}
                </div>
                <div className="education-info-container">
                  <h2 className="education-info-title">
                    <ZoomScholarshipIcon viewBox="0 0 80 80" htmlColor={darkBlue.main} />
                    {props.labelPastEducationsSubtitle}
                  </h2>
                  {renderPastEducations}
                </div>
              </div>
            </Container>
          </>
        )}
      </main>
      <ZoomFooter {...props.footerProps} />
    </>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export function mapStateToProps(store: WIAAStore, ownProps: any): MyEducationsOverviewProps {
  const { dossierId } = ownProps.match.params;
  const allEducations: Array<EducationProps> = [];
  if (store.userMyEducationData.formations) {
    store.userMyEducationData.formations.map((education) => {
      const tempEducation: EducationProps = {
        educationId: education.formationDbKey ? education.formationDbKey.toString() : '',
        educationType: education.formationArt,
        educationFacilityName: education.formationInstitution,
        educationFacilityLocation: education.formationLocation,
        duration: education.formationStartDate + ' - ' + education.formationEndDate,
        isCurrent: education.currentFormation,
      };
      allEducations.push(tempEducation);
    });
  }

  const headerProps: ZoomHeaderProps = mapStoreToHeaderProps(store, dossierId);
  const footerProps: ZoomFooterProps = mapStoreToFooterProps(store);
  return {
    dossierId: dossierId,
    headerProps,
    footerProps,
    holderDropDownProps: mapStoreToHolderDropDownProps(store, parseInt(dossierId)),
    notifications: formatUnreadNotifications(
      store.notificationData.notifications.filter((notification) => notification.isNew),
    ),
    labelTitle: i18nLabel(store.translationLabels, 'My_Educations_Overview_Title', 'Meine Ausbildungen'),
    labelDescription: i18nLabel(
      store.translationLabels,
      'My_Educations_Overview_Title_Description',
      'Diese Angaben werden in kommende Stipendienanträge übernommen und müssen aktuell sein.',
    ),
    labelCurrentEducationSubTitle: i18nLabel(
      store.translationLabels,
      'My_Educations_Overview_Current_Education_Subtitle',
      'Aktuelle Ausbildung',
    ),
    labelPastEducationsSubtitle: i18nLabel(
      store.translationLabels,
      'My_Educations_Overview_Past_Education_Subtitle',
      'Vergangene Ausbildung',
    ),
    labelEducationType: i18nLabel(
      store.translationLabels,
      'My_Educations_Overview_Education_Type',
      'Art der Ausbildung',
    ),
    labelEducationFacilityName: i18nLabel(
      store.translationLabels,
      'My_Educations_Overview_Education_Facility_Name',
      'Ausbildungsstätte',
    ),
    labelEducationFacilityLocation: i18nLabel(
      store.translationLabels,
      'My_Educations_Overview_Education_Facility_Location',
      'Ort der Ausbildungsstätte',
    ),
    labelDuration: i18nLabel(store.translationLabels, 'My_Educations_Overview_Education_Duration', 'Ausbildungsdauer'),
    labelChooseEducationState: i18nLabel(
      store.translationLabels,
      'My_Educations_Overview_Select_Education_Default_State',
      'Ausbildungsstand melden',
    ),
    labelEducationFinishedState: i18nLabel(
      store.translationLabels,
      'My_Educations_Overview_Select_Education_Finished_State',
      'Ausbildung abgeschlossen',
    ),
    labelEducationChangedState: i18nLabel(
      store.translationLabels,
      'My_Educations_Overview_Select_Education_Changed_State',
      'Ausbildung gewechselt',
    ),
    labelEducationInterruptedState: i18nLabel(
      store.translationLabels,
      'My_Educations_Overview_Select_Education_Interrupted_State',
      'Ausbildung unterbrochen',
    ),
    labelEducationCanceledState: i18nLabel(
      store.translationLabels,
      'My_Educations_Overview_Select_Education_Canceled_State',
      'Ausbildung abgebrochen',
    ),
    labelBackArrow: i18nLabel(store.translationLabels, 'My_Educations_Overview_Back_Arrow', 'Zurück'),
    breadcrumbLabels: [
      i18nLabel(store.translationLabels, 'My_Educations_Overview_Breadcrumb_Label_1', 'Übersicht'),
      i18nLabel(store.translationLabels, 'My_Educations_Overview_Breadcrumb_Label_2', 'Meine Ausbildungen'),
    ],
    educations: allEducations,
    errorLabels: store.translationLabels,
    labelMarkAsRead: i18nLabel(store.translationLabels, 'Notifications_Box_Mark_As_Read', 'Schliessen'),
    labelGoToAction: i18nLabel(store.translationLabels, 'Notifications_Box_Go_To_Action', 'Zum Upload'),
    isLoading: store.userMyEducationData.isLoading,
  };
}

export default withRouter(connect(mapStateToProps)(MyEducationsOverview));
