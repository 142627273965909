import { Container } from '@material-ui/core';
import React, { ReactElement } from 'react';
import { connect } from 'react-redux';
import ZoomFooter, {
  mapStoreToFooterProps,
  ZoomFooterProps,
} from '../../components/base-page-components/footer/ZoomFooter';
import ZoomHeader, {
  mapStoreToHeaderProps,
  ZoomHeaderProps,
} from '../../components/base-page-components/header/ZoomHeader';
import { i18nLabel } from '../../store/i18n/helpers';
import { WIAAStore } from '../../store/types';

export interface Error403Props {
  message: string;
  headerProps: ZoomHeaderProps;
  footerProps: ZoomFooterProps;
}

export const Error403 = (props: Error403Props): ReactElement => {
  return (
    <>
      <ZoomHeader {...props.headerProps} />
      <main className="main">
        <Container>
          <div id="main-page-container">
            <h2>{props.message}</h2>
          </div>
        </Container>
      </main>
      <ZoomFooter {...props.footerProps}></ZoomFooter>
    </>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function mapError403StateToProps(store: WIAAStore): Error403Props {
  const headerProps: ZoomHeaderProps = mapStoreToHeaderProps(store);
  const footerProps: ZoomFooterProps = mapStoreToFooterProps(store);

  return {
    headerProps: headerProps,
    footerProps: footerProps,
    message: i18nLabel(store.translationLabels, 'Error_403_Message', 'Fehler: Zugriff verweigert'),
  };
}

export default connect(mapError403StateToProps)(Error403);
