import { Grid, TextField } from '@material-ui/core';
import React, { ChangeEvent, useState, ReactElement, useEffect } from 'react';
import './ZoomMultipleTextInput.scss';
import { ZoomInfoIcon } from '../../icons/ZoomInfoIcon';
import { ZoomTextInputHelpTooltip } from '../../tooltips/ZoomTextInputHelpTooltip';

export type GridSize = boolean | 'auto' | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | undefined;

export interface ZoomLabelValueProps {
  label: string;
  value?: string;
  gridSize: GridSize;
  name?: string;
  error?: boolean;
  errorMessage?: string;
  onChange?: (index: number, event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

export interface ZoomMultipleTextInputProps {
  data: ZoomLabelValueProps[];
  helpText?: string;
}

const ZoomMultipleTextInput = (props: ZoomMultipleTextInputProps): ReactElement => {
  const [data, setData] = useState(props.data ? props.data : []);

  useEffect(() => {
    setData(props.data ? props.data : []);
  }, [props.data]);

  const handleInputChange = (index: number, e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    const updatedData = [...data];
    updatedData[index].value = e.target.value;
    setData(updatedData);
    const currentObject = props.data[index];
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    if (currentObject.onChange) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      currentObject.onChange(index, e);
    }
  };

  const handleInputBlur = (index: number, e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    const currentObject = props.data[index];
    if (currentObject.onChange) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      currentObject.onChange(index, e);
    }
  };

  return (
    <div>
      <div>
        <Grid container direction="row" alignItems="stretch" justify="space-between">
          <Grid item xs={12} sm={6}>
            <div className="label">
              {data.map((currData, index) => (
                <label key={index}>{index === props.data.length - 1 ? currData.label : currData.label + ' / '}</label>
              ))}
              {props.helpText && (
                <ZoomTextInputHelpTooltip placement="bottom" arrow={true} title={<p>{props.helpText}</p>}>
                  <span style={{ width: '24px', height: '24px' }}>
                    <ZoomInfoIcon color="primary" viewBox="0 0 80 80"></ZoomInfoIcon>
                  </span>
                </ZoomTextInputHelpTooltip>
              )}
            </div>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Grid container direction="row" alignItems="stretch" justify="flex-start" spacing={1}>
              {data.map((currData, index) => (
                <Grid item xs={12} sm={currData.gridSize} key={index}>
                  <div>
                    <TextField
                      fullWidth
                      data-testid="textfield-input"
                      variant="outlined"
                      size="small"
                      name={data[index].name}
                      value={data[index].value}
                      error={data[index].error}
                      helperText={data[index].error ? data[index].errorMessage : null}
                      onChange={(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
                        handleInputChange(index, event)
                      }
                      onBlur={(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
                        handleInputBlur(index, event)
                      }
                    ></TextField>
                  </div>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default ZoomMultipleTextInput;
