import { ReactElement } from 'react';
import { Action } from 'redux';
import { ZoomFooterProps } from '../../components/base-page-components/footer/ZoomFooter';
import { ZoomHeaderProps } from '../../components/base-page-components/header/ZoomHeader';

export const initialContentTabState = {
  education: false,
  payout: false,
  loan: false,
  personalData: false,
  cv: false,
  contactData: false,
  income: false,
  expenses: false,
  parents: false,
  father: false,
  mother: false,
  guardian: false,
  partner: false,
  children: false,
  siblings: false,
  documents: false,
  request: false,
  notes: false,
};

export type ContentTabKey = keyof typeof initialContentTabState;

export interface NavigationForm {
  form: ContentTabKey;
  label?: string;
  component?: ReactElement;
  loadAction?: Action;
  hidden?: boolean;
}

export interface NavigationTab {
  tab: string;
  forms: Array<NavigationForm>;
  title: string;
  hidden?: boolean;
}

export interface NavigationOrder {
  tabs: Array<NavigationTab>;
}

export interface MobileNavigationButtonProps {
  element: ReactElement;
  onClick?: () => void;
  id?: string;
}

export const orderOfTabs = [
  'default',
  'scholarship',
  'pia',
  'parents',
  'partner',
  'children',
  'siblings',
  'payout',
  'documents',
  'notes',
];

export interface TabProgress {
  id: number;
  percentage: number;
}

export interface DemandOverviewProps {
  title: string;
  labelNext: string;
  labelCompleteDemand: string;
  labelSavedSuccessfully: string;
  tabLabelScholarshipData: string;
  tabLabelPifData: string;
  tabLabelParentsData: string;
  tabLabelPartnerData: string;
  tabLabelChildrenData: string;
  tabLabelAdditionalData: string;
  tabLabelSiblingsData: string;
  tabLabelAttachments: string;
  tabLabelPayoutData: string;
  contentLabelPersonalData: string;
  contentLabelChildren: string;
  contentLabelCv: string;
  contentLabelContactData: string;
  contentLabelIncome: string;
  contentLabelExpenses: string;
  contentLabelLoan: string;
  contentLabelPayout: string;
  contentLabelEducation: string;
  contentLabelPartner: string;
  contentLabelRequest: string;
  contentLabelParents: string;
  contentLabelFather: string;
  contentLabelMother: string;
  contentLabelGuardian: string;
  contentLabelSiblings: string;
  contentLabelDocuments: string;
  contentLabelFirstAcknowledgementTitle: string;
  contentLabelFirstAcknowledgementParagraph1: string;
  contentLabelFirstAcknowledgementParagraph2: string;
  contentLabelFirstAcknowledgementSubmit: string;
  contentLabelSecondAcknowledgementTitle: string;
  contentLabelSecondAcknowledgementParagraph1: string;
  contentLabelSecondAcknowledgementParagraph2: string;
  contentLabelSecondAcknowledgementSubmit: string;
  contentLabelModalCancel: string;
  contentLabelCompleteDemandErrorTitle: string;
  contentLabelCompleteDemandErrorParagraph1: string;
  contentLabelCompleteDemandErrorParagraph2: string;
  contentLabelNotes: string;
  mobileTabsTitleLabel: string;
  activeTab?: number;
  activeTabMobile?: number;
  activeForm?: ContentTabKey;
  orderedTabs: NavigationOrder;
  showSuccess?: boolean;
  pifId: number;
  demandId: number;
  dossierId: number;
  contentTabState: typeof initialContentTabState;
  contentTabProgress: typeof initialContentTabState;
  labelBackArrow?: string;
  breadcrumbLabels?: Array<string>;
  piaUnder18?: boolean;
  demandCompletionError?: string;
  completed?: boolean;
  tabProgress?: Array<TabProgress>;
  globalProgress?: number;
  labelGlobalProgressReady?: string;
  inProgress?: boolean;
  initialDataLoaded?: boolean;
  completionTitle: string;
  completionParagraph1: string;
  completionParagraph2: string;
  completionParagraph3: string;
  labelBackToDashboard: string;
  headerProps: ZoomHeaderProps;
  footerProps: ZoomFooterProps;
  dispatch?: (action: Action) => void;
}
