import { MyDataOverviewPage } from './types';

export enum MyDataOverviewPageAction {
  GET = 'MY_DATA_OVERVIEW_PAGE_GET',
  GET_RESULT = 'MY_DATA_OVERVIEW_PAGE_GET_RESULT',
  RESULT = 'MY_DATA_OVERVIEW_PAGE_RESULT',
  NON_EXISTENT_MY_DATA_OVERVIEW_PAGE = 'NON_EXISTENT_MY_DATA_OVERVIEW_PAGE',
}

export interface MyDataOverviewPageGetAction {
  type: typeof MyDataOverviewPageAction.GET;
  payload: number;
}

export interface MyDataOverviewPageGetResultAction {
  type: typeof MyDataOverviewPageAction.GET_RESULT;
  payload: MyDataOverviewPage;
}

export interface MyDataOverviewPageResultAction {
  type: typeof MyDataOverviewPageAction.RESULT;
  payload: MyDataOverviewPage;
}

export type MyDataOverviewPageActionType =
  | MyDataOverviewPageGetAction
  | MyDataOverviewPageGetResultAction
  | MyDataOverviewPageResultAction;
