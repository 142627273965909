import React, { ReactElement } from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import { black } from '../../theme';

export function ZoomQuestionIcon(props: SvgIconProps): ReactElement {
  const color = props.htmlColor ? props.htmlColor : black.main;

  return (
    <SvgIcon {...props}>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <path d="M0 0H80V80H0z" transform="translate(-256 -157) translate(256 157)" />
            <path
              fill={color}
              fillRule="nonzero"
              d="M40.6 64.51c-13.255 0-24-10.745-24-24s10.745-24 24-24 24 10.745 24 24-10.745 24-24 24zm0-44c-11.046 0-20 8.954-20 20s8.954 20 20 20 20-8.954 20-20c.008-5.31-2.096-10.404-5.847-14.162C51 22.591 45.91 20.48 40.6 20.48v.03z"
              transform="translate(-256 -157) translate(256 157)"
            />
            <path
              fill={color}
              fillRule="nonzero"
              d="M38 45.92v-.63c0-2.23.72-4.21 3.84-7 2.17-2 2.58-3.44 2.58-5 .052-1.066-.357-2.102-1.122-2.845-.766-.742-1.815-1.12-2.878-1.035-2.82 0-4.1 1.78-4.47 4.37H32.1c.16-2.62 1.9-7.58 8.42-7.56 6 0 7.92 4.1 7.92 7.15 0 2.75-.88 4.56-3.81 7.21-1.94 1.78-2.57 3.15-2.57 5v.35L38 45.92zm0 8.46v-5.73h4.05v5.73H38z"
              transform="translate(-256 -157) translate(256 157)"
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}
