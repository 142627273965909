import moment from 'moment';
import { i18nLabel } from '../store/i18n/helpers';

export enum ValidationErrorLabels {
  FieldRequired = 'FIELD_REQUIRED',
  Ahvn13WrongFormat = 'AHVN13_WRONG_FORMAT',
  EmailWrongFormat = 'EMAIL_WRONG_FORMAT',
  NotANumber = 'NOT_A_NUMBER',
  MaxLength = 'MAX_LENGTH',
  MinLength = 'MIN_LENGTH',
  MinValue = 'LESS_THAN_MIN_VALUE',
  MaxValue = 'GREATER_THAN_MAX_VALUE',
  DateToCannotBeBeforeFrom = 'DATE_TO_CANNOT_BE_BEFORE_FROM',
  PostCodeWrongFormat = 'POST_CODE_WRONG_FORMAT',
  IBANWrongFormat = 'IBAN_WRONG_FORMAT',
  Ahvn13WrongChecksum = 'AHVN13_WRONG_CHECKSUM',
  EmailsNotSame = 'EMAILS_ARE_NOT_THE_SAME',
  DateNotInFuture = 'DATE_NOT_IN_FUTURE',
}

export const requiredValidation = (value: string, translationLabels?: { [key: string]: string }): string =>
  value && value !== ''
    ? ''
    : i18nLabel(translationLabels, ValidationErrorLabels.FieldRequired, ValidationErrorLabels.FieldRequired);

export const dateFromToValidation = (
  from: Date | null,
  to: Date | null,
  translationLabels: { [key: string]: string },
): string => {
  if (moment(from).isAfter(moment(to))) {
    return i18nLabel(
      translationLabels,
      ValidationErrorLabels.DateToCannotBeBeforeFrom,
      ValidationErrorLabels.DateToCannotBeBeforeFrom,
    );
  }
  return '';
};

export const emailComparisonValidation = (
  value1: string,
  value2: string,
  translationLabels: { [key: string]: string },
): string => {
  if (emailValidation(value1, translationLabels) === '' && emailValidation(value2, translationLabels) === '') {
    if (value1 === value2) {
      return '';
    }
  }
  return i18nLabel(translationLabels, ValidationErrorLabels.EmailsNotSame, ValidationErrorLabels.EmailsNotSame);
};

export const emailValidation = (value: string, translationLabels: { [key: string]: string }): string => {
  const requiredError = requiredValidation(value, translationLabels);
  if (requiredError) {
    return requiredError;
  }

  const regexEmail = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  if (!regexEmail.test(value)) {
    return i18nLabel(translationLabels, ValidationErrorLabels.EmailWrongFormat, ValidationErrorLabels.EmailWrongFormat);
  }
  return '';
};

export const postCodeValidation = (value: string, translationLabels: { [key: string]: string }): string => {
  const requiredError = requiredValidation(value, translationLabels);
  if (requiredError) {
    return requiredError;
  }
  if (value === '?') {
    return '';
  }
  const regexPostCode = RegExp(/^\d{4}$/);
  if (!regexPostCode.test(value)) {
    return i18nLabel(
      translationLabels,
      ValidationErrorLabels.PostCodeWrongFormat,
      ValidationErrorLabels.PostCodeWrongFormat,
    );
  }
  return '';
};

export const ibanValidation = (value: string, translationLabels: { [key: string]: string }): string => {
  const requiredError = requiredValidation(value, translationLabels);
  if (requiredError) {
    return requiredError;
  }

  const iban = value.split(' ').join('');

  const regexIBAN = RegExp(/^[0-9]{2}[0-9]{5}[A-Z|0-9]{12}$/);
  if (!regexIBAN.test(iban)) {
    return i18nLabel(translationLabels, ValidationErrorLabels.IBANWrongFormat, ValidationErrorLabels.IBANWrongFormat);
  }

  const subIban = iban.substring(2) + 'CH' + iban.substring(0, 2);

  let checksum = '';
  for (const char of subIban) {
    let currentValue = '';
    if (/[A-Z]/.test(char)) {
      currentValue = (char.charCodeAt(0) - 55).toString();
    } else {
      currentValue = char.toString();
    }
    checksum = checksum + currentValue;
  }
  return BigInt(checksum) % BigInt(97) === BigInt(1)
    ? ''
    : i18nLabel(translationLabels, ValidationErrorLabels.IBANWrongFormat, ValidationErrorLabels.IBANWrongFormat);
};

export const ahvn13validation = (value: string, translationLabels: { [key: string]: string }): string => {
  const requiredError = requiredValidation(value, translationLabels);

  if (requiredError) {
    return requiredError;
  }

  const regexFirstThreeDigits = /^756/;
  if (!regexFirstThreeDigits.test(value)) {
    return i18nLabel(
      translationLabels,
      ValidationErrorLabels.Ahvn13WrongFormat,
      ValidationErrorLabels.Ahvn13WrongFormat,
    );
  }

  if (value.includes('.')) {
    const regexDotSeparated = /^756\.\d{4}\.\d{4}\.\d{2}$/;
    if (regexDotSeparated.test(value)) {
      if (ahvn13ValidateChecksum(value)) {
        return '';
      }
    }
  }

  if (value.includes('-')) {
    const regexDashSeparated = /^756-\d{4}-\d{4}-\d{2}$/;
    if (regexDashSeparated.test(value)) {
      if (ahvn13ValidateChecksum(value)) {
        return '';
      }
    }
  }

  const regexOnly13Digits = /^756\d{10}$/;
  if (regexOnly13Digits.test(value)) {
    if (ahvn13ValidateChecksum(value)) {
      return '';
    }
  }

  return i18nLabel(translationLabels, ValidationErrorLabels.Ahvn13WrongFormat, ValidationErrorLabels.Ahvn13WrongFormat);
};

export const ahvn13ValidateChecksum = (ahvn13Number: string): boolean => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let ahvn13: any = ahvn13Number;
  if (ahvn13 === undefined || ahvn13 === null) {
    return false;
  }
  ahvn13 = ahvn13.split('-').join('');
  ahvn13 = ahvn13.split('.').join('');

  if (ahvn13 === '7560000000000') {
    return true;
  }

  const checksum = parseInt(ahvn13[12]);
  let sum = 0;
  for (let i = 11; i >= 0; i--) {
    let value = parseInt(ahvn13[i]);
    if (!isNaN(value)) {
      if (i % 2 == 1) {
        value *= 3;
      }
      sum += value;
    }
  }
  sum %= 10;
  if (sum > 0) {
    sum = 10 - sum;
  }
  return sum === checksum;
};

export const integerValidation = (value: string, translationLabels?: { [key: string]: string }): string => {
  const requiredError = requiredValidation(value, translationLabels);
  if (requiredError) {
    return requiredError;
  }

  const intValue = parseInt(value);
  if (isNaN(intValue)) {
    return i18nLabel(translationLabels, ValidationErrorLabels.NotANumber, ValidationErrorLabels.NotANumber);
  }

  const regexOnlyDigits = /^\d+$/;
  if (!regexOnlyDigits.test(value)) {
    return i18nLabel(translationLabels, ValidationErrorLabels.NotANumber, ValidationErrorLabels.NotANumber);
  }
  return '';
};

export const maxLengthValidation = (
  value: string,
  maxLength: number,
  translationLabels: { [key: string]: string },
): string => {
  if (value.length > maxLength) {
    return i18nLabel(translationLabels, ValidationErrorLabels.MaxLength, ValidationErrorLabels.MaxLength);
  }
  return '';
};

export const minLengthValidation = (
  value: string,
  minLength: number,
  translationLabels?: { [key: string]: string },
): string => {
  if (value.length < minLength) {
    return i18nLabel(translationLabels, ValidationErrorLabels.MinLength, ValidationErrorLabels.MinLength);
  }
  return '';
};

export const minMaxValueValidation = (
  value: string,
  minValue: number,
  maxValue: number,
  translationLabels?: { [key: string]: string },
): string => {
  const integerError = integerValidation(value, translationLabels);
  if (integerError) {
    return integerError;
  }
  return (
    maxValueValidation(value, maxValue, translationLabels) + minValueValidation(value, minValue, translationLabels)
  );
};

export const minValueValidation = (
  value: string,
  minValue: number,
  translationLabels?: { [key: string]: string },
): string => {
  const integerError = integerValidation(value, translationLabels);
  if (integerError) {
    return integerError;
  }

  const intValue = parseInt(value);
  if (intValue < minValue) {
    return i18nLabel(translationLabels, ValidationErrorLabels.MinValue, ValidationErrorLabels.MinValue);
  }

  return '';
};

export const maxValueValidation = (
  value: string,
  maxValue: number,
  translationLabels?: { [key: string]: string },
): string => {
  const integerError = integerValidation(value, translationLabels);
  if (integerError) {
    return integerError;
  }

  const intValue = parseInt(value);
  if (intValue > maxValue) {
    return i18nLabel(translationLabels, ValidationErrorLabels.MaxValue, ValidationErrorLabels.MaxValue);
  }

  return '';
};

export const dateInFutureValidation = (value: Date | null, translationLabels: { [key: string]: string }): string => {
  const currentDate = moment();
  if (currentDate.isAfter(moment(value)) || currentDate.format('DD.MM.YYYY') === moment(value).format('DD.MM.YYYY')) {
    return i18nLabel(translationLabels, ValidationErrorLabels.DateNotInFuture, ValidationErrorLabels.DateNotInFuture);
  }
  return '';
};
