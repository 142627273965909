import produce from 'immer';
import { MyLoanAction, MyLoanActionType } from './actions';
import { MyLoanDataStore } from './types';

export const initialState: MyLoanDataStore = {};

export function myLoanReducer(state = initialState, action: MyLoanActionType): MyLoanDataStore {
  return produce(state, (draft) => {
    switch (action.type) {
      case MyLoanAction.GET:
        draft.isLoading = true;
        break;
      case MyLoanAction.GET_RESULT:
        draft = action.payload;
        draft.isLoading = false;
        break;
    }
    return draft;
  });
}
