import React, { ReactElement } from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import { black } from '../../theme';

export function ZoomWarningIcon(props: SvgIconProps): ReactElement {
  const color = props.htmlColor ? props.htmlColor : black.main;
  return (
    <SvgIcon {...props}>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <path d="M0 0H80V80H0z" transform="translate(-356 -357) translate(356 357)" />
            <path
              fill={color}
              fillRule="nonzero"
              d="M63.85 54.57L44.71 20.92c-.849-1.52-2.458-2.46-4.2-2.45-1.745-.011-3.358.927-4.21 2.45L17.16 54.57c-.844 1.5-.831 3.334.035 4.82.865 1.487 2.454 2.404 4.175 2.41h38.28c1.721-.003 3.312-.92 4.176-2.409.865-1.488.874-3.324.024-4.821zm-3.48 2.81c-.144.262-.42.424-.72.42H21.37c-.3.006-.578-.156-.72-.42-.15-.256-.15-.574 0-.83L39.78 22.9c.147-.263.424-.426.725-.426s.578.163.725.426l19.14 33.65c.155.255.155.575 0 .83z"
              transform="translate(-356 -357) translate(356 357)"
            />
            <path
              fill={color}
              fillRule="nonzero"
              d="M40.6 50.44c-1.13.006-2.044.926-2.04 2.057.004 1.13.922 2.045 2.053 2.043 1.131-.002 2.047-.92 2.047-2.05 0-.545-.217-1.068-.604-1.453s-.91-.6-1.456-.597zM40.51 48.34c1.105 0 2-.895 2-2V32.18c0-1.105-.895-2-2-2s-2 .895-2 2v14.16c0 1.105.895 2 2 2z"
              transform="translate(-356 -357) translate(356 357)"
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}
