import { Observable, of } from 'rxjs';
import { Epic, ofType } from 'redux-observable';
import {
  EducationCanceledSubmitAction,
  EducationChangeAction,
  EducationCompletedSubmitAction,
  EducationDataAction,
  EducationDataGetAction,
  EducationDataGetDegreeProgramsAction,
  EducationDataGetDegreeProgramsSuccessAction,
  EducationDataGetInstitutionsAction,
  EducationDataGetInstitutionsSuccessAction,
  EducationDataGetInstitutionTypesSuccessAction,
  EducationDataGetResultAction,
  EducationDataResultAction,
  EducationDataSubmitAction,
  EducationInterruptAction,
} from './actions';
import { Action } from 'redux';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { AjaxResponse } from 'rxjs/ajax';
import { handleRequestFailureInEpic } from '../../utils/error-handling';
import { customAjax } from '../../utils/ajax-wrapper';
import { RoutingAction, RoutingActionType } from '../routing/actions';

const addEducation = (payload: AjaxResponse): EducationDataGetResultAction => {
  return {
    type: EducationDataAction.GET_RESULT,
    payload: { ...payload.response },
  };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const addEducationOnError = (error: any): any => {
  if (error.status == 404) {
    return {
      type: EducationDataAction.NON_EXISTENT_EDUCATION,
    };
  }

  return handleRequestFailureInEpic(error);
};

const getSubmitResult = (payload: AjaxResponse): EducationDataResultAction => {
  return {
    type: EducationDataAction.RESULT,
    payload: { ...payload.response },
  };
};

export const getEducationDataEpic: Epic<Action, Action> = (action$: Observable<Action>): Observable<Action> =>
  action$.pipe(
    ofType(EducationDataAction.GET),
    mergeMap((action) => {
      const demandId = (action as EducationDataGetAction).demandId;

      return customAjax({
        subRoute: `/dossier/v1/demands/${demandId}/formation`,
        withCredentials: true,
        method: 'GET',
      }).pipe(
        map((response) => addEducation(response)),
        catchError((error) => {
          return of(addEducationOnError(error));
        }),
      );
    }),
  );

export const sendEducationDataEpic: Epic<Action, Action> = (action$: Observable<Action>): Observable<Action> =>
  action$.pipe(
    ofType(EducationDataAction.SUBMIT),
    mergeMap((action) => {
      const educationDataAction: EducationDataSubmitAction = action as EducationDataSubmitAction;
      const demandId = educationDataAction.demandId;

      return customAjax({
        subRoute: `/dossier/v1/demands/${demandId}/formation`,
        withCredentials: true,
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(educationDataAction.payload),
      }).pipe(
        map((response) => getSubmitResult(response)),
        catchError((error) => {
          return of(handleRequestFailureInEpic(error));
        }),
      );
    }),
  );

const getEducationRequestSubmitResult = (payload: AjaxResponse, dossierId: number): RoutingActionType => {
  return {
    type: RoutingAction.REDIRECT_TO,
    payload: `/dossier-intranet/myformation/${dossierId}`,
  };
};

export const sendEducationInterruptRequest: Epic<Action, Action> = (action$: Observable<Action>): Observable<Action> =>
  action$.pipe(
    ofType(EducationDataAction.INTERRUPT),
    mergeMap((action) => {
      const interruptAction: EducationInterruptAction = action as EducationInterruptAction;
      const dossierId = interruptAction.dossierId;
      return customAjax({
        subRoute: `/dossier-intranet/v1/cases/${dossierId}`,
        withCredentials: true,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept-Language': 'de-CH',
        },
        body: interruptAction.payload,
      }).pipe(
        map(
          (response) => getEducationRequestSubmitResult(response, dossierId),
          catchError((error) => {
            return of(handleRequestFailureInEpic(error));
          }),
        ),
      );
    }),
    catchError((error) => {
      return of(handleRequestFailureInEpic(error));
    }),
  );

export const sendEducationChangeRequest: Epic<Action, Action> = (action$: Observable<Action>): Observable<Action> =>
  action$.pipe(
    ofType(EducationDataAction.EDUCATION_CHANGE),
    mergeMap((action) => {
      const changeAction: EducationChangeAction = action as EducationChangeAction;
      const dossierId = changeAction.dossierId;
      return customAjax({
        subRoute: `/dossier-intranet/v1/cases/${dossierId}`,
        withCredentials: true,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept-Language': 'de-CH',
        },
        body: changeAction.payload,
      }).pipe(
        map(
          (response) => getEducationRequestSubmitResult(response, dossierId),
          catchError((error) => {
            return of(handleRequestFailureInEpic(error));
          }),
        ),
      );
    }),
    catchError((error) => {
      return of(handleRequestFailureInEpic(error));
    }),
  );

export const sendEducationCompletedRequest: Epic<Action, Action> = (action$: Observable<Action>): Observable<Action> =>
  action$.pipe(
    ofType(EducationDataAction.COMPLETE),
    mergeMap((action) => {
      const outgoingDataAction: EducationCompletedSubmitAction = action as EducationCompletedSubmitAction;
      const dossierId = outgoingDataAction.dossierId;
      return customAjax({
        subRoute: `/dossier-intranet/v1/cases/${dossierId}`,
        withCredentials: true,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept-Language': 'de-CH',
        },
        body: outgoingDataAction.payload,
      }).pipe(
        map(
          (response) => getEducationRequestSubmitResult(response, dossierId),
          catchError((error) => {
            return of(handleRequestFailureInEpic(error));
          }),
        ),
      );
    }),
  );

export const sendEducationCanceledRequest: Epic<Action, Action> = (action$: Observable<Action>): Observable<Action> =>
  action$.pipe(
    ofType(EducationDataAction.CANCELED),
    mergeMap((action) => {
      const outgoingDataAction: EducationCanceledSubmitAction = action as EducationCanceledSubmitAction;
      const dossierId = outgoingDataAction.dossierId;
      return customAjax({
        subRoute: `/dossier-intranet/v1/cases/${dossierId}`,
        withCredentials: true,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept-Language': 'de-CH',
        },
        body: outgoingDataAction.payload,
      }).pipe(
        map(
          (response) => getEducationRequestSubmitResult(response, dossierId),
          catchError((error) => {
            return of(handleRequestFailureInEpic(error));
          }),
        ),
      );
    }),
  );

const addInstitutionTypes = (payload: AjaxResponse): EducationDataGetInstitutionTypesSuccessAction => {
  return {
    type: EducationDataAction.GET_INSTITUTION_TYPES_SUCCESS,
    payload: payload.response,
  };
};

export const getInstitutionTypesEpic: Epic<Action, Action> = (action$: Observable<Action>): Observable<Action> =>
  action$.pipe(
    ofType(EducationDataAction.GET_INSTITUTION_TYPES),
    mergeMap(() => {
      return customAjax({
        subRoute: `/master-data/v1/institutions-types`,
        withCredentials: true,
        method: 'GET',
        headers: {
          'Accept-Language': 'de-CH',
        },
      }).pipe(
        map((response) => addInstitutionTypes(response)),
        catchError((error) => {
          return of({
            type: EducationDataAction.GET_INSTITUTION_TYPES_ERROR,
            payload: error,
          });
        }),
      );
    }),
  );

const addInstitution = (payload: AjaxResponse): EducationDataGetInstitutionsSuccessAction => {
  return {
    type: EducationDataAction.GET_INSTITUTIONS_SUCCESS,
    payload: payload.response,
  };
};

export const getInstitutionEpic: Epic<Action, Action> = (action$: Observable<Action>): Observable<Action> =>
  action$.pipe(
    ofType(EducationDataAction.GET_INSTITUTIONS),
    mergeMap((action) => {
      const getInstitutionTypeAction = action as EducationDataGetInstitutionsAction;
      return customAjax({
        subRoute: `/master-data/v1/institutions/${getInstitutionTypeAction.payload}`,
        withCredentials: true,
        method: 'GET',
        headers: {
          'Accept-Language': 'de-CH',
        },
      }).pipe(
        map((response) => addInstitution(response)),
        catchError((error) => {
          return of({
            type: EducationDataAction.GET_INSTITUTIONS_ERROR,
            payload: error,
          });
        }),
      );
    }),
  );

const addDegreePrograms = (payload: AjaxResponse): EducationDataGetDegreeProgramsSuccessAction => {
  return {
    type: EducationDataAction.GET_DEGREE_PROGRAMS_SUCCESS,
    payload: payload.response,
  };
};

export const getDegreeProgramsEpic: Epic<Action, Action> = (action$: Observable<Action>): Observable<Action> =>
  action$.pipe(
    ofType(EducationDataAction.GET_DEGREE_PROGRAMS),
    mergeMap((action) => {
      const getInstitutionTypeAction = action as EducationDataGetDegreeProgramsAction;
      return customAjax({
        subRoute: `/master-data/v1/degree-programs/${getInstitutionTypeAction.payload}`,
        withCredentials: true,
        method: 'GET',
        headers: {
          'Accept-Language': 'de-CH',
        },
      }).pipe(
        map((response) => addDegreePrograms(response)),
        catchError((error) => {
          return of({
            type: EducationDataAction.GET_DEGREE_PROGRAMS_ERROR,
            payload: error,
          });
        }),
      );
    }),
  );
