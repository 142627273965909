import React, { ReactElement } from 'react';
import { ZoomFile } from '../forms/file-upload/ZoomFileUpload';
import './Message.scss';

export interface InboxMessageProps {
  date: string;
  text: string;
  dossierId: number;
  caseId: number;
  clientEventId: number;
  inbox?: boolean;
  eventTypeId?: number;
  files?: Array<ZoomFile>;
}

export default function InboxMessage(props: InboxMessageProps): ReactElement {
  const getFileHtml = (f: ZoomFile) => {
    const downloadLink = `${process.env.REACT_APP_API_GATEWAY_URL}/dossier-intranet/v1/clientevent/${props.dossierId}/documents?streamId=${f.streamId}&clientEventId=${f.clientEventId}`;
    return (
      <div key={f.id + f.streamId} className="single-file-container">
        <a href={downloadLink} download className="zoom-download-file">
          {f.fileName}
        </a>
      </div>
    );
  };

  const files = props.files ? props.files : [];
  const filesToRender = files.map((f) => getFileHtml(f));
  return (
    <div className="message-container inbox-message">
      <div className="files-container chat-files-container">{filesToRender}</div>
      <p className="message-date">{props.date}</p>
      <div className="message-content">{props.text}</div>
    </div>
  );
}
