import { FormControl, FormControlLabel, FormHelperText, Grid, makeStyles, RadioGroup } from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import React, { ChangeEvent, ReactElement, useEffect, useState } from 'react';
import { black, red } from './../../../theme';
import { ZoomInfoIcon } from './../../icons/ZoomInfoIcon';
import { ZoomTextInputHelpTooltip } from './../../tooltips/ZoomTextInputHelpTooltip';
import './ZoomRadioInput.scss';

const useStyles = makeStyles({
  labelFonts: {
    fontFamily: 'Proxima Nova Cond',
    fontWeight: 'lighter',
    fontStyle: 'normal',
  },
  primary: {
    color: black.main,
  },
  error: {
    color: red.main,
  },
});

export interface ZoomRadioInputProps {
  title: string;
  value?: string;
  label1: string;
  value1: string;
  label2: string;
  value2: string;
  error?: boolean;
  errorMessage?: string;
  name?: string;
  helpText?: string;
  onChange?: (value: string) => void;
}

const ZoomRadioInput = (props: ZoomRadioInputProps): ReactElement => {
  const [value, setValue] = useState(props.value !== undefined ? props.value : '');
  const [error, setError] = useState(props.error ? true : false);
  const [errorMessage, setErrorMessage] = useState(props.errorMessage);
  const classes = useStyles();

  const style = error ? classes.error : classes.primary;

  useEffect(() => {
    setValue(props.value !== undefined ? props.value : '');
    setError(props.error ? true : false);
    setErrorMessage(props.errorMessage ? props.errorMessage : '');
  }, [props.value, props.error, props.errorMessage]);

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    setValue(e.target.value);
    if (props.onChange) {
      props.onChange(e.target.value);
    }
  };

  return (
    <div>
      <Grid container direction="row" alignItems="stretch" justify="flex-start" className="radio-container">
        <Grid item xs={12} sm={6}>
          <div className="radio-label">
            <label>{props.title}</label>
            {props.helpText && (
              <ZoomTextInputHelpTooltip placement="bottom" arrow={true} title={<p>{props.helpText}</p>}>
                <span style={{ width: '24px', height: '24px' }}>
                  <ZoomInfoIcon color="primary" viewBox="0 0 80 80"></ZoomInfoIcon>
                </span>
              </ZoomTextInputHelpTooltip>
            )}
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl error={error} className={classes.labelFonts}>
            <RadioGroup row value={value} onChange={handleChange}>
              <FormControlLabel
                value={props.value1}
                control={<Radio name={props.name} data-testid="radio-1" color="primary" className={style} />}
                label={props.label1}
              />
              <FormControlLabel
                name={props.name}
                value={props.value2}
                control={<Radio name={props.name} data-testid="radio-2" color="primary" className={style} />}
                label={props.label2}
              />
            </RadioGroup>
            <FormHelperText data-testid="error-paragraph">{errorMessage ? errorMessage : null}</FormHelperText>
          </FormControl>
        </Grid>
      </Grid>
    </div>
  );
};

export default ZoomRadioInput;
