import { Action } from 'redux';
import { Epic, ofType } from 'redux-observable';
import { createPatch } from 'rfc6902';
import { Observable } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { concatMap, map, mergeMap } from 'rxjs/operators';
import { customAjax } from '../../utils/ajax-wrapper';
import {
  PersonalDataAction,
  PersonalDataGetResultAction,
  PersonalDataResultAction,
  PersonalDataRetrieveAction,
  PersonalDataSubmitAction,
} from './actions';
import { PersonalData } from './types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getSubmitResult = (payload: AjaxResponse): PersonalDataResultAction => {
  return {
    type: PersonalDataAction.RESULT,
    payload: { ...payload.response },
  };
};

const addUser = (payload: PersonalData): PersonalDataGetResultAction => {
  return {
    type: PersonalDataAction.GET_RESULT,
    payload: payload,
  };
};

export const sendPersonalDataEpic: Epic<Action, Action> = (action$: Observable<Action>): Observable<Action> =>
  action$.pipe(
    ofType(PersonalDataAction.SUBMIT),
    mergeMap((action) => {
      const pdAction: PersonalDataSubmitAction = action as PersonalDataSubmitAction;

      const personalData: PersonalData = pdAction.payload;
      const { ...pif } = personalData;

      return customAjax({
        subRoute: `/dossier/v1/demands/${pdAction.demandId}/person-in-formation`,
        withCredentials: true,
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json-patch+json',
        },
        body: JSON.stringify(createPatch({}, pif)),
      }).pipe(map((response) => getSubmitResult(response)));
    }),
  );

export const getPersonalDataEpic: Epic<Action, Action> = (action$: Observable<Action>): Observable<Action> =>
  action$.pipe(
    ofType(PersonalDataAction.GET),
    mergeMap((action) => {
      const pdAction: PersonalDataRetrieveAction = action as PersonalDataRetrieveAction;

      return customAjax({
        subRoute: `/dossier/v1/demands/${pdAction.demandId}/person-in-formation`,
        withCredentials: true,
        method: 'GET',
      }).pipe(
        concatMap((response) => {
          const personalData = response.response as PersonalData;
          return new Observable<Action>((subscriber) => subscriber.next(addUser(personalData)));
        }),
      );
    }),
  );
