import {
  EducationDataAction,
  EducationDataGetDegreeProgramsSuccessAction,
  EducationDataGetInstitutionsSuccessAction,
  EducationDataGetInstitutionTypesSuccessAction,
  EducationDataGetResultAction,
  EducationDataResultAction,
  EducationDataUpdateAction,
  EducationDataUpdateAvailableDegreeProgramsAction,
  EducationDataUpdateAvailableInstituionsAction,
} from './actions';
import { EducationData, EducationDataStore } from './types';
import { produce } from 'immer';
import { LastDemandDataTakeoverAction, LastDemandDataTakeoverResultAction } from '../last-demand-data-takeover/actions';
import { Action } from 'redux';

export const initialState: EducationDataStore = {
  education: {},
  availableInstitutionTypes: [],
  availableInstitutions: [],
  availableDegreePrograms: [],
};

export function educationDataReducer(state = initialState, action: Action): EducationDataStore {
  return produce(state, (draft) => {
    switch (action.type) {
      case EducationDataAction.RESULT:
        const resultAction = action as EducationDataResultAction;
        if (resultAction.payload) {
          draft.education = { ...resultAction.payload };
        }
        break;
      case EducationDataAction.GET_RESULT:
        const result = action as EducationDataGetResultAction;
        if (result.payload) {
          draft.education = result.payload;
        }
        break;
      case LastDemandDataTakeoverAction.RESULT:
        const lastDemandData = (action as LastDemandDataTakeoverResultAction).payload;
        if (!draft.education.educationTypeId) {
          draft.education = { ...lastDemandData.formation };
          if (!draft.education.educationTypeId && lastDemandData.formation.formationType) {
            draft.education.educationTypeId = lastDemandData.formation.formationType;
          }
        }
        break;
      case EducationDataAction.GET_INSTITUTION_TYPES_SUCCESS:
        const institutionTypes = (action as EducationDataGetInstitutionTypesSuccessAction).payload;
        draft.availableInstitutionTypes = institutionTypes;
        if (!draft.availableInstitutionTypes.find((institutionType) => institutionType.id === 1)) {
          draft.availableInstitutionTypes = [
            { id: 1, name: '-', inSwitzerland: true, programChoiceAvailable: true },
            ...draft.availableInstitutionTypes,
          ];
        }
        break;
      case EducationDataAction.GET_INSTITUTIONS_SUCCESS:
        const institutions = (action as EducationDataGetInstitutionsSuccessAction).payload;
        draft.availableInstitutions = institutions;
        if (!draft.availableInstitutions.find((institution) => institution.id === 1)) {
          draft.availableInstitutions = [{ id: 1, name: '-' }, ...draft.availableInstitutions];
        }
        break;
      case EducationDataAction.GET_DEGREE_PROGRAMS_SUCCESS:
        const degreePrograms = (action as EducationDataGetDegreeProgramsSuccessAction).payload;
        draft.availableDegreePrograms = degreePrograms;
        if (!draft.availableDegreePrograms.find((degreeProgram) => degreeProgram.id === 1)) {
          draft.availableDegreePrograms = [{ id: 1, name: '-' }, ...draft.availableDegreePrograms];
        }
        break;
      case EducationDataAction.UPDATE_DATA:
        const educationData: EducationData = (action as EducationDataUpdateAction).payload;
        if (educationData) {
          draft.education = { ...educationData };
        }
        break;
      case EducationDataAction.UPDATE_AVAILABLE_INSTITUTIONS:
        const availableInstitutions = (action as EducationDataUpdateAvailableInstituionsAction).payload;
        draft.availableInstitutions = [...availableInstitutions];
        break;
      case EducationDataAction.UPDATE_AVAILABLE_DEGREE_PROGRAMS:
        const availableDegreePrograms = (action as EducationDataUpdateAvailableDegreeProgramsAction).payload;
        draft.availableDegreePrograms = [...availableDegreePrograms];
        break;
    }
    return draft;
  });
}
