import React, { ReactElement, useEffect, useState } from 'react';
import { Education, CVItemForm } from './item/form/CVItemForm';
import { CVItemInfo } from './item/info/CVItemInfo';
import produce from 'immer';
import { Button, Grid, ListItemText, useMediaQuery } from '@material-ui/core';
import { connect } from 'react-redux';
import './CVForm.scss';
import moment from 'moment';
import { WIAAStore } from '../../store/types';
import { getDefaultSelectOption } from '../../utils/form-data';
import { CVDataStore, CvItemData } from '../../store/cv-data/types';
import {
  CVDataAction,
  CVDataActionType,
  CVDataAddItemAction,
  CVDataDeleteItemAction,
  CVDataEditItemAction,
} from '../../store/cv-data/actions';
import { withRouter } from 'react-router-dom';
import { i18nLabel } from '../../store/i18n/helpers';
import { ZoomStyledMenu, ZoomStyledMenuItem } from '../../utils/zoom-styled-menu';
import { ZoomArrowDownIcon } from '../../components/icons/ZoomArrowDownIcon';
import { blue, lightGrey } from '../../theme';

const EDUCATION_TYPE_VOLKSSCHULE = '119';

export interface CVFormProps {
  labelEducationType: string;
  labelFromTo: string;
  labelDegree: string;
  labelCity: string;
  labelKanton: string;
  labelIsFinished: string;
  labelYes: string;
  labelNo: string;
  labelSave: string;
  labelCancel: string;
  kantonOptions: Array<{ name: string; value: string }>;
  labelEmploymentPercentage: string;
  labelJobDescription: string;
  labelMonthlySalary: string;
  labelAddAdditionalPositions: string;
  labelEducationEntriesError: string;
  labelNoEntries: string;
  labelNoEntriesWithDataFromPreviousRequests: string;
  educations: Array<Education>;
  educationsBeingEdited: Array<number>;
  errorEntriesCrossing: string;
  errorInitialEntryWrongType: string;
  errorTimeBetweenEntries: string;
  errorMissingEntriesBeforeEndDate: string;
  errorMissingEntriesBeforeEndDateFromPreviousDemandTakeover: string;
  errorEntriesAfterEndDate: string;
  labelTakeoverDataFromPreviousYear?: string;
  educationStart?: Date;
  helpTextType?: string;
  demandId: number;
  errorLabels?: { [key: string]: string };
  cvItemsFromPreviousYear?: Array<CvItemData>;
  formationType?: { name: string; value: string }[];
  areas?: Array<{ name: string; value: string }>;
  dispatch?: (action: CVDataActionType) => void;
}

const cvItemData2education = (cvItemData: CvItemData): Education => {
  return {
    id: cvItemData.id,
    type: cvItemData.activityTypeId ? cvItemData.activityTypeId.toString() : '',
    from: cvItemData.activityStartDate,
    to: cvItemData.activityEndDate,
    isFinished: cvItemData.formationFinished,
    degree: cvItemData.formationCertificate,
    city: cvItemData.legalResidence,
    kanton: cvItemData.civilLegalResidenceKey ? cvItemData.civilLegalResidenceKey.toUpperCase() : undefined,
    employmentQuota: cvItemData.worktimeQuota ? cvItemData.worktimeQuota.toString() : '',
    jobDescription: cvItemData.activityTitle,
    monthlySalary: cvItemData.salaryPerMonthNetAverage ? cvItemData.salaryPerMonthNetAverage.toString() : '',
  };
};

const areEducationTimeGapsPresent = (educations: Array<Education>): boolean => {
  let gapsPresent = false;

  if (educations) {
    let lastDate: Date | null = null;
    educations.forEach((education) => {
      if (lastDate === null) {
        lastDate = education.to ? education.to : null;
      } else {
        const startDate = education.from;
        if (startDate) {
          const momentNew = moment(startDate);
          const momentLast = moment(lastDate);
          const differenceInMonths = momentNew.diff(momentLast, 'months');
          gapsPresent = gapsPresent || differenceInMonths !== 1;
        }
        lastDate = education.to ? education.to : null;
      }
    });
  }

  return gapsPresent;
};

const areEducationEntriesCrossing = (educations: Array<Education>): boolean => {
  let crossing = false;
  let lastDate: Date | null = null;
  educations.forEach((education) => {
    if (lastDate === null) {
      lastDate = education.to ? education.to : null;
    } else {
      const startDate = education.from;
      if (startDate) {
        crossing = crossing || moment(startDate).isBefore(moment(lastDate));
        lastDate = education.to ? education.to : null;
      }
    }
  });
  return crossing;
};

/***
 * Checking if first education entry type is of type "VOLKSSCHULE"
 * If it's a subsequent demand, then this function should return true
 * because CV items were already sent in previous demands.
 * @param educations
 * @param hasPreviousDemand
 */
const isInitialEducationEntryCorrectType = (educations: Array<Education>, hasPreviousDemand: boolean): boolean => {
  if (hasPreviousDemand) {
    return true;
  }
  if (educations && educations[0]) {
    return educations[0].type === EDUCATION_TYPE_VOLKSSCHULE;
  }
  return true;
};

const isCvCompleteUntilEducationStart = (educations: Array<Education>, educationStart: Date | undefined): boolean => {
  let isComplete = true;

  if (educations.length > 0 && educationStart) {
    const lastEntry = educations[educations.length - 1];
    if (lastEntry.to) {
      const momentLastEntryEnd = moment(lastEntry.to);
      const momentEducationStart = moment(educationStart);
      let educationStartIsAfterLastEntry = momentEducationStart.isAfter(momentLastEntryEnd);
      if (!educationStartIsAfterLastEntry) {
        educationStartIsAfterLastEntry =
          momentEducationStart.format('MM.YYYY') === momentLastEntryEnd.format('MM.YYYY');
      }
      const differenceInMonths = momentEducationStart.diff(momentLastEntryEnd, 'months');
      isComplete = differenceInMonths < 2 && educationStartIsAfterLastEntry;
    }
  }

  return isComplete;
};

const isCvAfterEducationStart = (educations: Array<Education>, educationStart: Date | undefined): boolean => {
  let isAfterEducationStart = false;
  if (educations.length > 0 && educationStart) {
    const lastEntry = educations[educations.length - 1];
    if (lastEntry.to) {
      const momentLastEntryEnd = moment(lastEntry.to);
      const momentEducationStart = moment(educationStart);
      isAfterEducationStart = momentEducationStart.isBefore(momentLastEntryEnd);
    }
  }
  return isAfterEducationStart;
};

export const CVForm = (props: CVFormProps): ReactElement => {
  const hasPreviousDemand = props.cvItemsFromPreviousYear && props.cvItemsFromPreviousYear.length > 0 ? true : false;
  const [educations, setEducations] = useState(props.educations ? props.educations : []);
  const [educationsBeingEdited, setEducationsBeingEdited] = useState(
    props.educationsBeingEdited ? props.educationsBeingEdited : [],
  );
  const [errorEntriesCrossing, setErrorEntriesCrossing] = useState(areEducationEntriesCrossing(educations));
  const [errorGapsPresent, setErrorGapsPresent] = useState(areEducationTimeGapsPresent(educations));
  const [errorFirstEntryIncorrectType, setErrorFirstEntryIncorrectType] = useState(
    !isInitialEducationEntryCorrectType(educations, hasPreviousDemand),
  );
  const [errorCvNotComplete, setErrorCvNotComplete] = useState(
    !isCvCompleteUntilEducationStart(educations, props.educationStart),
  );
  const [errorCvAfterEducationStart, setErrorCvAfterEducationStart] = useState(
    isCvAfterEducationStart(educations, props.educationStart),
  );
  const [disableControls, setDisableControls] = useState(false);

  const validateMultipleEntries = (educations: Array<Education>) => {
    setErrorEntriesCrossing(areEducationEntriesCrossing(educations));
    setErrorGapsPresent(areEducationTimeGapsPresent(educations));
    setErrorFirstEntryIncorrectType(!isInitialEducationEntryCorrectType(educations, hasPreviousDemand));
    setErrorCvNotComplete(!isCvCompleteUntilEducationStart(educations, props.educationStart));
    setErrorCvAfterEducationStart(isCvAfterEducationStart(educations, props.educationStart));
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const dataFromPreviousDemand = props.cvItemsFromPreviousYear ? props.cvItemsFromPreviousYear : [];
  const isSmallDevice = useMediaQuery('(max-width: 600px)');

  useEffect(() => {
    setEducations(props.educations ? props.educations : []);
    validateMultipleEntries(props.educations ? props.educations : []);
  }, [props.educations]);

  const onSave = (index: number, education: Education) => {
    if (props.dispatch) {
      const cvDataAction: CVDataAddItemAction | CVDataEditItemAction = {
        type: !education.id || education.id === 0 ? CVDataAction.ADD_ITEM : CVDataAction.EDIT_ITEM,
        payload: {
          id: education.id,
          activityTypeId: education.type,
          activityStartDate: education.from,
          activityEndDate: education.to,
          formationFinished: education.isFinished,
          salaryPerMonthNetAverage: education.monthlySalary,
          worktimeQuota: education.employmentQuota,
          formationCertificate: education.degree,
          activityTitle: education.jobDescription,
          civilLegalResidenceKey: education.kanton,
          legalResidence: education.city,
        } as CvItemData,
        demandId: props.demandId,
      };
      setDisableControls(false);
      props.dispatch(cvDataAction);
    }

    const nextStateBeingEdited = produce(educationsBeingEdited, (draft) => {
      const indexToDelete = draft.findIndex((i) => i === index);
      if (indexToDelete > -1) {
        draft.splice(indexToDelete, 1);
      }
    });
    setEducationsBeingEdited(nextStateBeingEdited);
  };

  const onEdit = (index: number) => {
    const nextStateBeingEdited = produce(educationsBeingEdited, (draft) => {
      draft.push(index);
    });
    setEducationsBeingEdited(nextStateBeingEdited);
    setDisableControls(true);
  };

  const onDelete = (index: number) => {
    const cvItemId = educations[index].id;
    const nextStateEducations = produce(educations, (draft) => {
      draft.splice(index, 1);
    });
    setEducations(nextStateEducations);

    const nextStateBeingEdited = produce(educationsBeingEdited, (draft) => {
      const indexToDelete = draft.findIndex((i) => i === index);
      if (indexToDelete > -1) {
        draft.splice(indexToDelete, 1);
      }
    });
    setEducationsBeingEdited(nextStateBeingEdited);
    validateMultipleEntries(nextStateEducations);

    if (cvItemId && cvItemId > 0 && props.dispatch) {
      const deleteCvItemData: CVDataDeleteItemAction = {
        type: CVDataAction.DELETE_ITEM,
        payload: cvItemId,
        demandId: props.demandId,
      };
      props.dispatch(deleteCvItemData);
    }
    setDisableControls(false);
  };

  const addNewEducation = () => {
    const nextState = produce(educations, (draft) => {
      let newStartDate: Date | undefined = undefined;
      if (draft.length > 0 && draft[draft.length - 1]) {
        newStartDate = draft[draft.length - 1].to;
        if (newStartDate) {
          newStartDate = moment(newStartDate).add(1, 'month').toDate();
        }
      }
      const educationType = newStartDate !== undefined ? '0' : EDUCATION_TYPE_VOLKSSCHULE;
      draft.push({ from: newStartDate, type: educationType });
    });
    setEducations(nextState);
    const nextStateBeingEdited = produce(educationsBeingEdited, (draft) => {
      draft.push(nextState.length - 1);
    });
    setEducationsBeingEdited(nextStateBeingEdited);
    setDisableControls(true);
  };

  const addEducationFromPreviousYear = (cvItem: CvItemData) => {
    const nextState = produce(educations, (draft) => {
      draft.push(cvItemData2education(cvItem));
    });
    setEducations(nextState);
    const nextStateBeingEdited = produce(educationsBeingEdited, (draft) => {
      draft.push(nextState.length - 1);
    });
    setEducationsBeingEdited(nextStateBeingEdited);
    setDisableControls(true);
  };

  const toRender = educations.map((education, index) => {
    if (educationsBeingEdited.includes(index)) {
      const params = { ...props, ...education };
      return (
        <div className="cv-education-form-wrapper" key={index}>
          <CVItemForm
            {...params}
            onSave={(educationToSave: Education) => {
              onSave(index, educationToSave);
            }}
            onDelete={() => {
              onDelete(index);
            }}
          ></CVItemForm>
        </div>
      );
    }

    const from = education.from ? moment(education.from).format('MM.YYYY') : '';
    const to = education.to ? moment(education.to).format('MM.YYYY') : '';
    const duration = `${from} - ${to}`;
    const kanton = props.kantonOptions.find((k) => k.value === education.kanton);
    const educationType = props.formationType?.find((e) => e.value === education.type);
    return (
      <CVItemInfo
        kanton={kanton?.name}
        type={educationType?.name}
        duration={duration}
        id={index}
        key={index}
        onEdit={() => {
          onEdit(index);
        }}
        onDelete={() => {
          onDelete(index);
        }}
        disableControls={disableControls}
      ></CVItemInfo>
    );
  });

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const cvDataFromPreviousDemand = dataFromPreviousDemand.map((cvData, index) => {
    const education = cvItemData2education(cvData);
    const educationType = props.formationType?.find((e) => e.value === education.type);
    return (
      <ZoomStyledMenuItem key={index}>
        <a
          data-testid={'previous-data-' + index}
          style={{ display: 'inline-flex', width: '100%' }}
          onClick={() => {
            const count = educations.length;
            addEducationFromPreviousYear(cvData);
            handleClose();
            setTimeout(() => {
              document.getElementById('edit-cv-' + count)?.scrollIntoView();
              window.scrollBy(0, -100);
            }, 200);
          }}
        >
          <ListItemText primary={educationType?.name + ' ' + education.city} />
        </a>
      </ZoomStyledMenuItem>
    );
  });

  return (
    <>
      <div>{toRender}</div>
      {educations.length === 0 && dataFromPreviousDemand.length === 0 && <div>{props.labelNoEntries}</div>}
      {educations.length === 0 && dataFromPreviousDemand.length !== 0 && (
        <div>{props.labelNoEntriesWithDataFromPreviousRequests}</div>
      )}
      <Grid container direction="row" alignItems="flex-end" justify="space-between" className="error-block">
        <Grid item xs={12} sm={8}>
          <div className="cv-multiple-entry-errors">
            {errorEntriesCrossing && (
              <div className="Mui-error" style={{ display: 'none' }}>
                {props.errorEntriesCrossing}
              </div>
            )}
            {errorGapsPresent && (
              <div className="Mui-error" style={{ display: 'none' }}>
                {props.errorTimeBetweenEntries}
              </div>
            )}
            {errorFirstEntryIncorrectType && <div className="Mui-error">{props.errorInitialEntryWrongType}</div>}
            {errorCvNotComplete && cvDataFromPreviousDemand.length > 0 && (
              <div className="Mui-error">
                {props.errorMissingEntriesBeforeEndDateFromPreviousDemandTakeover} (
                {moment(props.educationStart).format('MM.YYYY')})
              </div>
            )}
            {errorCvNotComplete && cvDataFromPreviousDemand.length === 0 && (
              <div className="Mui-error">
                {props.errorMissingEntriesBeforeEndDate} ({moment(props.educationStart).format('MM.YYYY')})
              </div>
            )}
            {errorCvAfterEducationStart && (
              <div className="Mui-error">
                {props.errorEntriesAfterEndDate} ({moment(props.educationStart).format('MM.YYYY')})
              </div>
            )}
          </div>
          {dataFromPreviousDemand.length > 0 && (
            <div style={{ display: 'flex', justifyContent: 'flex-start', marginTop: 10 }}>
              <Button
                aria-controls="customized-menu"
                aria-haspopup="true"
                color="primary"
                type="reset"
                variant="outlined"
                id="data-takeover-button"
                onClick={handleClick}
                fullWidth={isSmallDevice}
                disabled={disableControls}
              >
                {props.labelTakeoverDataFromPreviousYear}
                <ZoomArrowDownIcon
                  viewBox="0 0 80 80"
                  htmlColor={disableControls ? lightGrey.main : blue.main}
                ></ZoomArrowDownIcon>
              </Button>
              <ZoomStyledMenu
                id="customized-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                {cvDataFromPreviousDemand}
              </ZoomStyledMenu>
            </div>
          )}
        </Grid>
        <Grid item xs={12} sm={4} className="btn-grid-block">
          <Button
            variant="outlined"
            color="primary"
            id="add-education-button"
            fullWidth={isSmallDevice}
            style={{ marginTop: isSmallDevice ? 10 : undefined }}
            onClick={addNewEducation}
            disabled={disableControls}
          >
            {props.labelAddAdditionalPositions}
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export function mapStateToProps(store: WIAAStore, ownProps: any): CVFormProps {
  const { demandId } = ownProps.match.params;
  const props: CVFormProps = {
    educations: [],
    educationsBeingEdited: [],
    labelEducationType: i18nLabel(store.translationLabels, 'CV_Education_Type', 'Vorbildung/Tätigkeit*'),
    formationType: store.translationOptions['activity-types']
      ? [
          { value: '0', name: getDefaultSelectOption(store.translationLabels) },
          ...store.translationOptions['activity-types'].map((option) => {
            return { value: option.id.toString(), name: option.name };
          }),
        ]
      : [],
    labelFromTo: i18nLabel(store.translationLabels, 'CV_From_To', 'Dauer*'),
    labelIsFinished: i18nLabel(store.translationLabels, 'CV_Is_Finished', 'Haben Sie die Ausbildung abgeschlossen?*'),
    labelDegree: i18nLabel(store.translationLabels, 'CV_Degree', 'Abschlusstitel*'),
    labelCity: i18nLabel(store.translationLabels, 'CV_City', 'Wohnsitz während dieser Zeit*'),
    labelKanton: i18nLabel(store.translationLabels, 'CV_Kanton', 'Wohnsitzkanton*'),
    kantonOptions: store.translationOptions.areas
      ? [
          ...store.translationOptions.areas.map((option) => {
            return { value: option.key ? option.key : option.id.toString(), name: option.name };
          }),
        ]
      : [],
    labelEmploymentPercentage: i18nLabel(store.translationLabels, 'CV_Employment_Percentage', 'Anstellungsprozent*'),
    labelJobDescription: i18nLabel(store.translationLabels, 'CV_Job_Description', 'Tätigkeit*'),
    labelMonthlySalary: i18nLabel(store.translationLabels, 'CV_Montly_Salary', 'Durchschnittlicher Monatslohn*'),
    labelYes: i18nLabel(store.translationLabels, 'CV_Yes', 'Ja'),
    labelNo: i18nLabel(store.translationLabels, 'CV_No', 'Nein'),
    labelSave: i18nLabel(store.translationLabels, 'CV_Save', 'Speichern'),
    labelCancel: i18nLabel(store.translationLabels, 'CV_Cancel', 'Löschen'),
    labelAddAdditionalPositions: i18nLabel(
      store.translationLabels,
      'CV_Add_Additional_Positions',
      'Weitere Position hinzufügen',
    ),
    labelEducationEntriesError: i18nLabel(
      store.translationLabels,
      'CV_Education_Entries_Error',
      'EDUCATION_ENTRIES_ERROR',
    ),
    labelNoEntries: i18nLabel(store.translationLabels, 'CV_No_Entries', 'Keine Einträge'),
    helpTextType: i18nLabel(
      store.translationLabels,
      'CV_Type_Help_Text',
      'Bitte erfassen Sie hier alle Ihre Ausbildungen inkl. Ausbildungsabbrüche und Erwerbstätigkeiten sowie Militär/Zivilschutz etc.. Die Ausbildung für welche Sie Ihr Gesuch stellen darf hier nicht erfasst werden.',
    ),
    errorEntriesCrossing: i18nLabel(
      store.translationLabels,
      'CV_Entries_Crossing_Error',
      'Es sollen keine zeitliche Überschneidungen vorhanden sein',
    ),
    errorInitialEntryWrongType: i18nLabel(
      store.translationLabels,
      'CV_Initial_Entry_Wrong_Type',
      'Der erste Eintrag muss vom Typ Volksschule sein',
    ),
    errorTimeBetweenEntries: i18nLabel(
      store.translationLabels,
      'CV_Time_Between_Entries_Error',
      'Es darf keine Pause zwischen den Einträgen geben',
    ),
    errorMissingEntriesBeforeEndDate: i18nLabel(
      store.translationLabels,
      'CV_Missing_Entries_Before_End_Date_Error',
      'Der Lebenslauf muss komplett bis zum Beginn der Ausbildung ausgefüllt werden',
    ),
    errorMissingEntriesBeforeEndDateFromPreviousDemandTakeover: i18nLabel(
      store.translationLabels,
      'CV_Missing_Entries_Before_End_Date_Previous_Demand_Takeover',
      'Bitte ergänzen Sie den Lebenslauf ab Ihrer übernehmen aus dem Vorjahr und ergänzen Sie den Lebenslauf bis zum Beginn der aktuellen Ausbildung ',
    ),
    errorEntriesAfterEndDate: i18nLabel(
      store.translationLabels,
      'CV_Entries_After_End_Date_Error',
      'Einträge nach dem Beginn der Ausbildung sind nicht erlaubt',
    ),
    errorLabels: store.translationLabels,
    labelTakeoverDataFromPreviousYear: i18nLabel(
      store.translationLabels,
      'CV_Takeover_Data',
      'Daten aus Vorjahr übernehmen',
    ),
    demandId: Number(demandId),
    cvItemsFromPreviousYear: store.cvData.itemsFromPreviousDemand,
    labelNoEntriesWithDataFromPreviousRequests: i18nLabel(
      store.translationLabels,
      'CV_No_Entries_With_Takeover_Data',
      'Bitte übernehmen Sie die Daten aus dem Vorjahr und ergänzen Sie allfällig weitere Positionen bis zum Beginn der aktuellen Ausbildung.',
    ),
  };

  const data: CVDataStore = store.cvData;
  if (store.educationData.education.formationStartDate) {
    const formationStartDate = moment(store.educationData.education.formationStartDate).toDate();
    if (formationStartDate) {
      props.educationStart = formationStartDate;
    }
  }
  if (data && data.items) {
    props.educations = Object.values(data.items).map(cvItemData2education);
  }

  return props;
}

export default withRouter(connect(mapStateToProps)(CVForm));
