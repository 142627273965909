import { Contract } from './types';

export enum MyContractsAction {
  GET_ALL_CONTRACTS = 'MY_CONTRACTS_GET_ALL_CONTRACTS',
  GET_ALL_CONTRACTS_RESULT = 'MY_CONTRACTS_GET_ALL_CONTRACTS_RESULT',
  RESULT_ALL_CONTRACTS = 'MY_CONTRACTS_ALL_CONTRACTS_RESULT',
  GET_SINGLE_CONTRACT = 'MY_CONTRACTS_GET_SINGLE_CONTRACT',
  GET_SINGLE_CONTRACT_RESULT = 'MY_CONTRACTS_GET_SINGLE_CONTRACT_RESULT',
  RESULT_SINGLE_CONTRACT = 'MY_CONTRACTS_SINGLE_CONTRACT_RESULT',
  NON_EXISTENT_MY_CONTRACTS_DATA = 'NON_EXISTENT_MY_CONTRACTS_DATA',
}

export interface MyContractsAllContractsGetAction {
  type: typeof MyContractsAction.GET_ALL_CONTRACTS;
  payload: number;
}

export interface MyContractsAllContractsGetResultAction {
  type: typeof MyContractsAction.GET_ALL_CONTRACTS_RESULT;
  payload: Array<Contract>;
}

export interface MyContractsAllContractsResultAction {
  type: typeof MyContractsAction.RESULT_ALL_CONTRACTS;
  payload: Array<Contract>;
}

export interface MyContractsSingleContractGetAction {
  type: typeof MyContractsAction.GET_SINGLE_CONTRACT;
  dossierId: number;
  contractId: number;
}

export interface MyContractsSingleContractGetResultAction {
  type: typeof MyContractsAction.GET_SINGLE_CONTRACT_RESULT;
  payload: Contract;
}

export interface MyContractsSingleContractResultAction {
  type: typeof MyContractsAction.RESULT_SINGLE_CONTRACT;
  payload: Contract;
}

export type MyContractsActionType =
  | MyContractsAllContractsGetAction
  | MyContractsAllContractsGetResultAction
  | MyContractsAllContractsResultAction
  | MyContractsSingleContractGetAction
  | MyContractsSingleContractGetResultAction
  | MyContractsSingleContractResultAction;
