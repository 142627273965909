import { Grid } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import NativeSelect from '@material-ui/core/NativeSelect';
import React, { ReactElement, useEffect, useState } from 'react';
import { ZoomInfoIcon } from '../../icons/ZoomInfoIcon';
import { ZoomTextInputHelpTooltip } from '../../tooltips/ZoomTextInputHelpTooltip';
import './ZoomSelectInput.scss';

export interface ZoomSelectInputProps {
  label: string;
  options: { name: string; value: string }[];
  name?: string;
  value?: string;
  disabled?: boolean;
  error?: boolean;
  errorMessage?: string;
  helpText?: string;
  onChange?: (newValue: string) => void;
  hideLabel?: boolean;
}

const ZoomSelectInput = (props: ZoomSelectInputProps): ReactElement => {
  const [state, setState] = React.useState<{ name: string; value: string }>({
    name: props.name !== undefined ? props.name : '',
    value: props.value !== undefined ? props.value : '',
  });
  const [disabled] = useState(props.disabled ? true : false);
  const [errorMessage, setErrorMessage] = useState(props.errorMessage);
  const [error, setError] = useState(props.error ? true : false);

  useEffect(() => {
    setError(props.error ? true : false);
    setErrorMessage(props.errorMessage ? props.errorMessage : '');
    const valueToSet = props.value ? props.value : '';
    const optionToSet = props.options.find((option) => option.value == valueToSet);
    if (optionToSet !== undefined) {
      setState(optionToSet);
    }
  }, [props]);

  const handleChange = (e: React.ChangeEvent<{ name?: string; value: string }>) => {
    let currName = props.options.find((option) => option.value === e.target.value)?.name;
    if (currName === undefined) {
      currName = '';
    }
    setState({
      name: currName,
      value: e.target.value,
    });
    if (props.onChange) {
      props.onChange(e.target.value);
    }
  };

  const currentStyle = error ? 'error-input' : 'input';

  return (
    <>
      <Grid container direction="row" alignItems="stretch" justify="flex-start">
        {!props.hideLabel ? (
          <Grid item xs={12} sm={6}>
            <div className="label">
              <label>{props.label}</label>
              {props.helpText && (
                <ZoomTextInputHelpTooltip
                  placement="bottom"
                  arrow={true}
                  title={<p dangerouslySetInnerHTML={{ __html: props.helpText }}></p>}
                >
                  <span style={{ width: '24px', height: '24px' }}>
                    <ZoomInfoIcon color="primary" viewBox="0 0 80 80"></ZoomInfoIcon>
                  </span>
                </ZoomTextInputHelpTooltip>
              )}
            </div>
          </Grid>
        ) : (
          <></>
        )}
        <Grid item xs={12} sm={!props.hideLabel ? 6 : 12}>
          <FormControl fullWidth>
            <NativeSelect
              disableUnderline={true}
              data-testid="form"
              value={state.value}
              name={props.name}
              disabled={disabled}
              error={error}
              onChange={handleChange}
              className={currentStyle}
              inputProps={{ 'aria-label': state.name }}
            >
              {props.options?.map((option) => {
                return (
                  <option key={option.name} value={option.value}>
                    {option.name}
                  </option>
                );
              })}
            </NativeSelect>
            <FormHelperText error={error} data-testid="error-paragraph">
              {errorMessage ? errorMessage : null}
            </FormHelperText>
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
};

export default ZoomSelectInput;
