import produce from 'immer';
import { exctractNonNullPropertiesFromObject } from '../../utils/object-manipulation';
import { LastDemandDataTakeoverAction, LastDemandDataType } from '../last-demand-data-takeover/actions';
import { PayoutDataAction, PayoutDataActionType } from './actions';
import { PayoutData } from './types';

export const initialState: PayoutData = {};

export function payoutDataReducer(state = initialState, action: PayoutDataActionType | LastDemandDataType): PayoutData {
  return produce(state, (draft) => {
    switch (action.type) {
      case PayoutDataAction.RESULT:
        draft = action.payload;
        break;
      case PayoutDataAction.GET_RESULT:
        const notNullProps = exctractNonNullPropertiesFromObject(action.payload);
        draft = { ...state, ...notNullProps };
        break;
      case PayoutDataAction.GET_POSTAL_CODES:
        draft = action.payload;
        break;
      case PayoutDataAction.GET_PARENT_DATA:
        const getParentDataAction = action;
        draft = { ...state, destinationAddressRoleId: 'father' === getParentDataAction.payload ? 7 : 8 };
        break;
      case PayoutDataAction.GET_PERSONAL_DATA:
        draft = { ...state, destinationAddressRoleId: 1 };
        break;
      case PayoutDataAction.GET_POSTAL_CODES_RESULT:
        const postalCode = action.payload;
        draft = { ...state, zipCode: postalCode.zipCode, town: postalCode.extendedName };
        break;
      case PayoutDataAction.GET_PARENT_DATA_RESULT:
        const payoutDataAction = action;
        draft = {
          ...state,
          street: payoutDataAction.payload.address,
          firstName: payoutDataAction.payload.firstName,
          lastName: payoutDataAction.payload.lastName,
          houseNumber: payoutDataAction.payload.houseNumber,
          zipCode: payoutDataAction.payload.zipCode,
          town: payoutDataAction.payload.town,
        };
        break;
      case PayoutDataAction.GET_PERSONAL_DATA_RESULT:
        const personalDataAction = action;
        draft = {
          ...state,
          street: personalDataAction.payload.address,
          firstName: personalDataAction.payload.firstName,
          lastName: personalDataAction.payload.lastName,
          houseNumber: personalDataAction.payload.houseNumber,
          zipCode: personalDataAction.payload.zipCode,
          town: personalDataAction.payload.town,
        };
        break;
      case PayoutDataAction.RESET:
        draft = {
          ...state,
          destinationAddressRoleId: 99,
          street: '',
          firstName: '',
          lastName: '',
          houseNumber: '',
          zipCode: '',
          town: '',
        };
        break;
      case LastDemandDataTakeoverAction.RESULT:
        const lastDemandData = action.payload;
        draft = lastDemandData.payment;
        break;
    }
    return draft;
  });
}
