import { Button } from '@material-ui/core';
import React, { ChangeEvent, ReactElement, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ZoomDatePicker from '../../components/forms/date-picker/ZoomDatePicker';
import ZoomRadioInput from '../../components/forms/radio-input/ZoomRadioInput';
import ZoomSelectInput from '../../components/forms/select-input/ZoomSelectInput';
import ZoomTextInput from '../../components/forms/text-input/ZoomTextInput';
import ZoomToggleInput from '../../components/forms/toggle-input/ZoomToggleInput';
import { i18nLabel } from '../../store/i18n/helpers';
import {
  PersonalDataAction,
  PersonalDataActionType,
  PersonalDataChangeAction,
} from '../../store/personal-data/actions';
import { PersonalData } from '../../store/personal-data/types';
import { WIAAStore } from '../../store/types';
import { getDefaultSelectOption } from '../../utils/form-data';
import { extractToggleValue } from '../../utils/object-manipulation';
import { ahvn13validation, requiredValidation } from '../../utils/validators';
import './PersonalDataForm.scss';

export interface PersonalDataFormProps {
  id?: string;
  mrMrsType?: string;
  firstName?: string;
  lastName?: string;
  ahvn13?: string;
  dateOfBirth?: Date;
  maritalStatus?: string;
  nationality?: string;
  city?: string;
  existingChildren?: string;
  existingSiblings?: string;
  permitCategoryId?: string;
  inSwitzerlandFrom?: Date;
  labelFormOfAddress?: string;
  labelFirstName?: string;
  labelLastName?: string;
  labelAhvn13?: string;
  labelDateOfBirth?: string;
  labelMaritalStatus?: string;
  labelNationality?: string;
  labelCity?: string;
  labelExistingChildrenLabel?: string;
  labelBrothersAndSistesLabel?: string;
  labelYes: string;
  labelNo: string;
  labelGuardianship?: string;
  labelGuardianshipFirstName?: string;
  labelGuardianshipLastName?: string;
  labelGuardianshipStreetAndNumber?: string;
  labelGuardianshipPostalCode?: string;
  labelGuardianshipCity?: string;
  labelGuardianshipCountry?: string;
  labelResidencePermit?: string;
  labelInSwitzerlandFrom?: string;
  labelSave?: string;
  helpTextAhvn13?: string;
  helpTextResidencePermitCategoryType?: string;
  helpTextCareSituationType?: string;
  helpTextGuardianship?: string;
  helpTextGuardianshipStreetAndNumber?: string;
  helpTextGuardianshipPostalCode?: string;
  helpTextGuardianshipCity?: string;
  helpTextGuardianshipCountry?: string;
  demandId: number;
  countryOptions?: { name: string; value: string }[];
  pifMaritalStatuses?: { name: string; value: string }[];
  mrMrsTypes?: { name: string; value: string }[];
  residencePermitCategories?: { name: string; value: string }[];
  toggleButtonsTypes?: { name: string; value: string }[];
  errorLabels?: { [key: string]: string };
  dispatch?: (action: PersonalDataActionType) => void;
}

interface PersonalDataErrors {
  id?: string;
  mrMrsType?: string;
  firstName?: string;
  lastName?: string;
  ahvn13?: string;
  dateOfBirth?: string;
  maritalStatus?: string;
  nationality?: string;
  city?: string;
  residencePermitType?: string;
  inSwitzerlandFrom?: string;
  existingChildren?: string;
  existingSiblings?: string;
}

export const PersonalDataForm = (props: PersonalDataFormProps): ReactElement => {
  const [mrMrsType, setMrMrsType] = useState(props.mrMrsType ? props.mrMrsType : '');
  const [firstName, setFirstName] = useState(props.firstName ? props.firstName : '');
  const [lastName, setLastName] = useState(props.lastName ? props.lastName : '');
  const [ahvn13, setAhvn13] = useState(props.ahvn13 ? props.ahvn13 : '');
  const [dateOfBirth, setDateOfBirth] = useState(props.dateOfBirth ? props.dateOfBirth : undefined);
  const [existingChildren, setExistingChildren] = useState(props.existingChildren ? props.existingChildren : '');
  const [existingSiblings, setExistingSiblings] = useState(props.existingSiblings ? props.existingSiblings : '');
  const [maritalStatus, setMaritalStatus] = useState(props.maritalStatus ? props.maritalStatus : '10');
  const [inSwitzerlandFrom, setInSwitzerlandFrom] = useState(
    props.inSwitzerlandFrom ? props.inSwitzerlandFrom : undefined,
  );
  const [city, setCity] = useState(props.city ? props.city : '');
  const [nationality, setNationality] = useState(props.nationality ? props.nationality : '8100');
  const [residencePermitType, setResidencePermitType] = useState(props.permitCategoryId ? props.permitCategoryId : '1');
  const initialErrors: PersonalDataErrors = {};
  const [errors, setErrors] = useState(initialErrors);

  useEffect(() => {
    setMrMrsType(props.mrMrsType ? props.mrMrsType : '');
    setFirstName(props.firstName ? props.firstName : '');
    setLastName(props.lastName ? props.lastName : '');
    setAhvn13(props.ahvn13 ? props.ahvn13 : '');
    setDateOfBirth(props.dateOfBirth ? props.dateOfBirth : undefined);
    setExistingChildren(props.existingChildren ? props.existingChildren : '');
    setExistingSiblings(props.existingSiblings ? props.existingSiblings : '');
    setMaritalStatus(props.maritalStatus ? props.maritalStatus : '10');
    setInSwitzerlandFrom(props.inSwitzerlandFrom ? props.inSwitzerlandFrom : undefined);
    setCity(props.city ? props.city : '');
    setNationality(props.nationality ? props.nationality : '8100');
    setResidencePermitType(props.permitCategoryId ? props.permitCategoryId : '0');
  }, [
    props.mrMrsType,
    props.firstName,
    props.lastName,
    props.ahvn13,
    props.dateOfBirth,
    props.existingChildren,
    props.existingSiblings,
    props.maritalStatus,
    props.inSwitzerlandFrom,
    props.city,
    props.nationality,
    props.labelResidencePermit,
  ]);

  const triggerOnChange = (newData: PersonalData) => {
    if (props.dispatch) {
      const changeAction: PersonalDataChangeAction = {
        type: PersonalDataAction.CHANGE,
        payload: getFullPersonalData(newData),
      };
      props.dispatch(changeAction);
    }
  };

  const onChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    switch (e.target.name) {
      case 'firstName':
        setFirstName(e.target.value);
        setErrors({
          ...errors,
          firstName: requiredValidation(e.target.value, props.errorLabels ? props.errorLabels : {}),
        });
        triggerOnChange({ firstName: e.target.value });
        break;
      case 'lastName':
        setLastName(e.target.value);
        setErrors({
          ...errors,
          lastName: requiredValidation(e.target.value, props.errorLabels ? props.errorLabels : {}),
        });
        triggerOnChange({ lastName: e.target.value });
        break;
      case 'ahvn13':
        setAhvn13(e.target.value);
        setErrors({ ...errors, ahvn13: ahvn13validation(e.target.value, props.errorLabels ? props.errorLabels : {}) });
        break;
      case 'city':
        setCity(e.target.value);
        setErrors({ ...errors, city: requiredValidation(e.target.value, props.errorLabels ? props.errorLabels : {}) });
        triggerOnChange({ homeTown: e.target.value });
        break;
    }
  };

  const onChangeDate = (newDate: Date | null) => {
    if (null === newDate) {
      setDateOfBirth(undefined);
    } else {
      setDateOfBirth(newDate);
    }
    setErrors({
      ...errors,
      dateOfBirth: newDate === null ? requiredValidation('', props.errorLabels ? props.errorLabels : {}) : '',
    });
  };

  const onResidencePermitTypeChange = (newValue: string) => {
    setResidencePermitType(newValue);
    setErrors({
      ...errors,
      residencePermitType: newValue === '0' ? requiredValidation('', props.errorLabels ? props.errorLabels : {}) : '',
    });
  };

  const onChangeDateInSwitzerlandFrom = (newDate: Date | null) => {
    if (null === newDate) {
      setInSwitzerlandFrom(undefined);
    } else {
      setInSwitzerlandFrom(newDate);
    }
    setErrors({
      ...errors,
      inSwitzerlandFrom: newDate === null ? requiredValidation('', props.errorLabels ? props.errorLabels : {}) : '',
    });
  };

  const onChangeMrMrsType = (newValue: string) => {
    setMrMrsType(newValue);
    setErrors({ ...errors, mrMrsType: requiredValidation(newValue, props.errorLabels ? props.errorLabels : {}) });
    triggerOnChange({ titleId: parseInt(newValue) });
  };

  const onNationalityChange = (newValue: string) => {
    setNationality(newValue);
    triggerOnChange({ nationalityId: parseInt(newValue) });
  };

  const onMaritalStatusChange = (newValue: string) => {
    setMaritalStatus(newValue);
    triggerOnChange({ maritalStatus: parseInt(newValue) });
  };

  const onChangeExistingChildren = (newValue: string) => {
    setExistingChildren(newValue);
    setErrors({
      ...errors,
      existingChildren: requiredValidation(newValue, props.errorLabels ? props.errorLabels : {}),
    });
    triggerOnChange({ existingChildren: newValue === '1' ? true : false });
  };

  const onChangeExistingSiblings = (newValue: string) => {
    setExistingSiblings(newValue);
    setErrors({
      ...errors,
      existingSiblings: requiredValidation(newValue, props.errorLabels ? props.errorLabels : {}),
    });
    triggerOnChange({ existingSiblings: newValue === '1' ? true : false });
  };

  const getPersonalData = (): PersonalData => {
    return {
      lastName: lastName,
      homeTown: city,
      maritalStatus: maritalStatus ? parseInt(maritalStatus) : undefined,
      titleId: mrMrsType ? parseInt(mrMrsType) : undefined,
      nationalityId: parseInt(nationality),
      permitCategoryId: residencePermitType ? parseInt(residencePermitType) : 0,
      existingChildren: existingChildren === '1' ? true : false,
      existingSiblings: existingSiblings === '1' ? true : false,
      inCountrySince: inSwitzerlandFrom ? inSwitzerlandFrom.toISOString() : undefined,
    };
  };

  const getFullPersonalData = (dataToOverride: PersonalData): PersonalData => {
    let newExistingChildren: boolean | undefined | null = null;
    let newExistingSiblings: boolean | undefined | null = null;
    if (existingChildren) {
      newExistingChildren = existingChildren === '1' ? true : false;
    }
    if (existingSiblings) {
      newExistingSiblings = existingSiblings === '1' ? true : false;
    }

    return {
      firstName: firstName,
      ahvNumber: ahvn13,
      dateOfBirth: dateOfBirth?.toISOString(),
      lastName: lastName,
      homeTown: city,
      maritalStatus: maritalStatus ? parseInt(maritalStatus) : undefined,
      titleId: mrMrsType ? parseInt(mrMrsType) : undefined,
      nationalityId: parseInt(nationality),
      permitCategoryId: residencePermitType ? parseInt(residencePermitType) : 0,
      existingChildren: newExistingChildren,
      existingSiblings: newExistingSiblings,
      inCountrySince: inSwitzerlandFrom ? inSwitzerlandFrom.toISOString() : undefined,
      ...dataToOverride,
    };
  };

  const getFormErrors = (): PersonalDataErrors => {
    const errors: PersonalDataErrors = {};
    errors.mrMrsType = requiredValidation(mrMrsType, props.errorLabels ? props.errorLabels : {});
    errors.firstName = requiredValidation(firstName, props.errorLabels ? props.errorLabels : {});
    errors.lastName = requiredValidation(lastName, props.errorLabels ? props.errorLabels : {});
    errors.ahvn13 = ahvn13validation(ahvn13, props.errorLabels ? props.errorLabels : {});
    errors.dateOfBirth = requiredValidation(
      dateOfBirth ? dateOfBirth.toDateString() : '',
      props.errorLabels ? props.errorLabels : {},
    );
    if (nationality === '8100') {
      errors.city = requiredValidation(city, props.errorLabels ? props.errorLabels : {});
    } else {
      errors.residencePermitType =
        residencePermitType === '0' ? requiredValidation('', props.errorLabels ? props.errorLabels : {}) : '';
      if (residencePermitType === '2') {
        errors.inSwitzerlandFrom = requiredValidation(
          inSwitzerlandFrom ? inSwitzerlandFrom.toDateString() : '',
          props.errorLabels ? props.errorLabels : {},
        );
      }
    }
    errors.existingChildren = requiredValidation(existingChildren, props.errorLabels ? props.errorLabels : {});
    errors.existingSiblings = requiredValidation(existingSiblings, props.errorLabels ? props.errorLabels : {});
    return errors;
  };

  const validateForm = (): boolean => {
    const newErrors = getFormErrors();
    setErrors(newErrors);
    const errorsPresent = Object.values(newErrors).some((value) => value !== '');
    return !errorsPresent;
  };

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    if (validateForm() && props.dispatch) {
      props.dispatch({ type: PersonalDataAction.SUBMIT, payload: getPersonalData(), demandId: props.demandId });
    }
    event.preventDefault();
  };

  return (
    <>
      <form onSubmit={onSubmit}>
        <div className="form-element-container">
          <ZoomRadioInput
            value={mrMrsType}
            error={!!errors.mrMrsType}
            errorMessage={errors.mrMrsType}
            title={props.labelFormOfAddress ? props.labelFormOfAddress : ''}
            label1={props.mrMrsTypes ? props.mrMrsTypes[0].name : ''}
            value1={props.mrMrsTypes ? props.mrMrsTypes[0].value.toString() : ''}
            label2={props.mrMrsTypes ? props.mrMrsTypes[1].name : ''}
            value2={props.mrMrsTypes ? props.mrMrsTypes[1].value.toString() : ''}
            onChange={onChangeMrMrsType}
          ></ZoomRadioInput>
        </div>
        <div className="form-element-container">
          <ZoomTextInput
            label={props.labelFirstName ? props.labelFirstName : ''}
            name="firstName"
            value={firstName}
            onChange={onChange}
            error={!!errors.firstName}
            errorMessage={errors.firstName}
            disabled
          ></ZoomTextInput>
        </div>
        <div className="form-element-container">
          <ZoomTextInput
            label={props.labelLastName ? props.labelLastName : ''}
            name="lastName"
            value={lastName}
            onChange={onChange}
            error={!!errors.lastName}
            errorMessage={errors.lastName}
          ></ZoomTextInput>
        </div>
        <div className="form-element-container">
          <ZoomTextInput
            label={props.labelAhvn13 ? props.labelAhvn13 : ''}
            value={ahvn13}
            name="ahvn13"
            onChange={onChange}
            helpText={props.helpTextAhvn13}
            error={!!errors.ahvn13}
            errorMessage={errors.ahvn13 ? errors.ahvn13 : undefined}
            disabled
          ></ZoomTextInput>
        </div>
        <div className="form-element-container">
          <ZoomDatePicker
            label={props.labelDateOfBirth ? props.labelDateOfBirth : ''}
            onChange={onChangeDate}
            name="dateOfBirth"
            value={dateOfBirth}
            error={!!errors.dateOfBirth}
            errorMessage={errors.dateOfBirth}
            disabled
          ></ZoomDatePicker>
        </div>
        <div className="form-element-container">
          <ZoomSelectInput
            onChange={onMaritalStatusChange}
            label={props.labelMaritalStatus ? props.labelMaritalStatus : ''}
            options={props.pifMaritalStatuses ? props.pifMaritalStatuses : []}
            value={maritalStatus}
            name="maritalStatus"
          ></ZoomSelectInput>
        </div>
        <div className="form-element-container">
          <ZoomSelectInput
            label={props.labelNationality ? props.labelNationality : ''}
            options={props.countryOptions ? props.countryOptions : []}
            value={nationality}
            name="nationality"
            onChange={onNationalityChange}
          ></ZoomSelectInput>
        </div>
        {nationality === '8100' && (
          <div className="form-element-container">
            <ZoomTextInput
              label={props.labelCity ? props.labelCity : ''}
              value={city}
              name="city"
              onChange={onChange}
              error={!!errors.city}
              errorMessage={errors.city ? errors.city : undefined}
            ></ZoomTextInput>
          </div>
        )}
        {nationality !== '8100' && (
          <div className="form-element-container">
            <ZoomSelectInput
              name="residencePermitType"
              value={residencePermitType}
              label={props.labelResidencePermit ? props.labelResidencePermit : ''}
              options={props.residencePermitCategories ? props.residencePermitCategories : []}
              onChange={onResidencePermitTypeChange}
              helpText={residencePermitType === '2' ? props.helpTextResidencePermitCategoryType : ''}
              error={!!errors.residencePermitType}
              errorMessage={errors.residencePermitType}
            ></ZoomSelectInput>
          </div>
        )}
        {nationality !== '8100' && residencePermitType === '2' && (
          <div className="form-element-container">
            <ZoomDatePicker
              name="inSwitzerlandFrom"
              label={props.labelInSwitzerlandFrom ? props.labelInSwitzerlandFrom : ''}
              onChange={onChangeDateInSwitzerlandFrom}
              error={!!errors.inSwitzerlandFrom}
              errorMessage={errors.inSwitzerlandFrom}
              value={inSwitzerlandFrom}
            ></ZoomDatePicker>
          </div>
        )}
        <div className="form-element-container">
          <ZoomToggleInput
            title={props.labelExistingChildrenLabel ? props.labelExistingChildrenLabel : ''}
            value={existingChildren}
            label1={props.toggleButtonsTypes ? props.toggleButtonsTypes[0].name : ''}
            value1={props.toggleButtonsTypes ? props.toggleButtonsTypes[0].value.toString() : ''}
            label2={props.toggleButtonsTypes ? props.toggleButtonsTypes[1].name : ''}
            value2={props.toggleButtonsTypes ? props.toggleButtonsTypes[1].value.toString() : ''}
            name="existingChildren"
            onChange={onChangeExistingChildren}
            error={!!errors.existingChildren}
            errorMessage={errors.existingChildren}
          ></ZoomToggleInput>
        </div>
        <div className="form-element-container">
          <ZoomToggleInput
            title={props.labelBrothersAndSistesLabel ? props.labelBrothersAndSistesLabel : ''}
            value={existingSiblings}
            label1={props.toggleButtonsTypes ? props.toggleButtonsTypes[0].name : ''}
            value1={props.toggleButtonsTypes ? props.toggleButtonsTypes[0].value.toString() : ''}
            label2={props.toggleButtonsTypes ? props.toggleButtonsTypes[1].name : ''}
            value2={props.toggleButtonsTypes ? props.toggleButtonsTypes[1].value.toString() : ''}
            name="existingSiblings"
            onChange={onChangeExistingSiblings}
            error={!!errors.existingSiblings}
            errorMessage={errors.existingSiblings}
          ></ZoomToggleInput>
        </div>
        <div className="form-actions-container">
          <Button color="primary" type="submit" variant="contained" id="submit-personal-data">
            {props.labelSave}
          </Button>
        </div>
      </form>
    </>
  );
};

// Map global store to component parameters
// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export function mapStateToProps(store: WIAAStore, ownProps: any): PersonalDataFormProps {
  const { demandId } = ownProps.match.params;

  const props: PersonalDataFormProps = {
    labelFormOfAddress: i18nLabel(store.translationLabels, 'Personal_User_Form_Of_Address', 'Anrede*'),
    labelFirstName: i18nLabel(store.translationLabels, 'Personal_User_First_Name', 'Vorname*'),
    labelLastName: i18nLabel(store.translationLabels, 'Personal_User_Last_Name', 'Nachname*'),
    labelAhvn13: i18nLabel(store.translationLabels, 'Personal_User_Ahvn13', 'AHV-Nummer*'),
    labelDateOfBirth: i18nLabel(store.translationLabels, 'Personal_User_Date_Of_Birth', 'Geburtsdatum*'),
    labelMaritalStatus: i18nLabel(store.translationLabels, 'Personal_User_Martial_Status', 'Zivilstand*'),
    labelNationality: i18nLabel(store.translationLabels, 'Personal_User_Nationality', 'Nationalität*'),
    labelCity: i18nLabel(store.translationLabels, 'Personal_User_City', 'Heimatort*'),
    labelExistingChildrenLabel: i18nLabel(
      store.translationLabels,
      'Personal_User_Existing_Children',
      'Haben Sie Kinder*',
    ),
    labelBrothersAndSistesLabel: i18nLabel(
      store.translationLabels,
      'Personal_User_Brothers_And_Sisters',
      'Haben Sie Geschwister*',
    ),
    labelYes: i18nLabel(store.translationLabels, 'Personal_User_Yes', 'Ja'),
    labelNo: i18nLabel(store.translationLabels, 'Personal_User_No', 'Nein'),
    labelGuardianship: i18nLabel(
      store.translationLabels,
      'Personal_User_Guardianship',
      'Vormundschaft / Beistandschaft*',
    ),
    labelGuardianshipFirstName: i18nLabel(store.translationLabels, 'Personal_User_Guardianship_First_Name', 'Vorname*'),
    labelGuardianshipLastName: i18nLabel(store.translationLabels, 'Personal_User_Guardianship_Last_Name', 'Nachname*'),
    labelGuardianshipStreetAndNumber: i18nLabel(
      store.translationLabels,
      'Personal_User_Guardianship_Street_And_Number',
      'Strasse und Nr.*',
    ),
    labelGuardianshipPostalCode: i18nLabel(store.translationLabels, 'Personal_User_Guardianship_Postal_Code', 'PLZ*'),
    labelGuardianshipCity: i18nLabel(store.translationLabels, 'Personal_User_Guardianship_City', 'Ort*'),
    labelGuardianshipCountry: i18nLabel(store.translationLabels, 'Personal_User_Guardianship_Country', 'Land*'),
    labelResidencePermit: i18nLabel(store.translationLabels, 'Personal_User_Residence_Permit', 'Aufenthaltsstatus*'),
    labelInSwitzerlandFrom: i18nLabel(
      store.translationLabels,
      'Personal_User_In_Switzerland_From',
      'In der Schweiz seit*',
    ),
    labelSave: i18nLabel(store.translationLabels, 'Personal_User_Save', 'Speichern'),
    helpTextAhvn13: i18nLabel(
      store.translationLabels,
      'Personal_User_Ahvn13_Help_Text',
      'Die 13-stellige Sozialversicherungsnummer ist auf der Versicherungskarte der Krankenkasse oder auf dem Sozialversicherungs-Ausweis ersichtlich. Sollte Ihnen diese Nummer nicht bekannt sein, geben Sie bitte 756.0000.0000.00 ein.',
    ),
    helpTextResidencePermitCategoryType: i18nLabel(
      store.translationLabels,
      'Personal_User_Residence_Permit_Category_Type_Help_Text',
      'Anerkannte Flüchtlinge mit B-Ausweis bitte "anerkannte Flüchtlinge" eingeben',
    ),
    helpTextCareSituationType: i18nLabel(
      store.translationLabels,
      'Personal_User_Care_Situation_Type_Help_Text',
      'Bitte geben Sie an, wer vor der Volljährigkeit der Person in Ausbildung das Sorgerecht oder die elterliche Obhut hatte.',
    ),
    helpTextGuardianship: i18nLabel(
      store.translationLabels,
      'Personal_User_Guardianship_Help_Text',
      'Welche Funktion hat Ihr Vormund/Beistand? z.B. Vormund / umfassende Beistandschaft / Begleitbeistandschaft etc.',
    ),
    helpTextGuardianshipStreetAndNumber: i18nLabel(
      store.translationLabels,
      'Personal_User_Guardianship_Street_And_Number_Help_Text',
      'Wenn Strasse nicht bekannt, "unbekannt" eingeben.',
    ),
    helpTextGuardianshipPostalCode: i18nLabel(
      store.translationLabels,
      'Personal_User_Guardianship_Postal_Code_Help_Text',
      'Wenn PLZ nicht bekannt, dann "?" eingeben.',
    ),
    helpTextGuardianshipCity: i18nLabel(
      store.translationLabels,
      'Personal_User_Guardianship_City_Help_Text',
      'Wenn Ort nicht bekannt, dann "unbekannt" angeben.',
    ),
    helpTextGuardianshipCountry: i18nLabel(
      store.translationLabels,
      'Personal_User_Guardianship_Country_Help_Text',
      'Bitte geben Sie den Wohnsitzstaat Ihrer Vormundschafts- Beistandschaftsbehörde an.',
    ),
    errorLabels: store.translationLabels,
    demandId: Number(demandId),
    countryOptions: store.translationOptions.nationalities
      ? [
          ...store.translationOptions.nationalities.map((option) => {
            return { value: option.id.toString(), name: option.name };
          }),
        ]
      : [],
    mrMrsTypes: store.translationOptions.titles
      ? [
          ...store.translationOptions.titles.map((option) => {
            return { value: option.id.toString(), name: option.name };
          }),
        ]
      : [],
    pifMaritalStatuses: store.translationOptions['piF-marital-statuses']
      ? [
          ...store.translationOptions['piF-marital-statuses'].map((option) => {
            return { value: option.id.toString(), name: option.name };
          }),
        ]
      : [],
    residencePermitCategories: store.translationOptions['permit-categories']
      ? [
          { value: '0', name: getDefaultSelectOption(store.translationLabels) },
          ...store.translationOptions['permit-categories'].map((option) => {
            return { value: option.id.toString(), name: option.name };
          }),
        ]
      : [],
    toggleButtonsTypes: store.translationOptions['toggle-buttons']
      ? [
          ...store.translationOptions['toggle-buttons'].map((option) => {
            return { value: option.id.toString(), name: option.name };
          }),
        ]
      : [],
  };

  const data = store.personalData;
  if (data) {
    return {
      firstName: data.firstName,
      lastName: data.lastName,
      ahvn13: data.ahvNumber,
      city: data.homeTown,
      nationality: data.nationalityId ? data.nationalityId.toString() : '',
      maritalStatus: data.maritalStatus ? data.maritalStatus.toString() : '',
      mrMrsType: data.titleId ? data.titleId.toString() : '',
      dateOfBirth: data.dateOfBirth ? new Date(data.dateOfBirth) : undefined,
      existingChildren: extractToggleValue(data.existingChildren),
      existingSiblings: extractToggleValue(data.existingSiblings),
      permitCategoryId: data.permitCategoryId ? data.permitCategoryId.toString() : '0',
      inSwitzerlandFrom: data.inCountrySince ? new Date(data.inCountrySince) : undefined,
      ...props,
    };
  }
  return props;
}

// default export the connected component
export default withRouter(connect(mapStateToProps)(PersonalDataForm));
