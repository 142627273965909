import { PostalCode } from '../contact-data/types';
import { GuardianData } from './types';

export enum GuardianDataAction {
  CHANGE = 'GUARDIAN_DATA_CHANGE',
  SUBMIT = 'GUARDIAN_DATA_SUBMIT',
  RESULT = 'GUARDIAN_DATA_RESULT',
  GET = 'GUARDIAN_DATA_GET',
  GET_RESULT = 'GUARDIAN_DATA_GET_RESULT',
  GET_VORMUND_POSTAL_CODES = 'GUARDIAN_DATA_GET_VORMUND_POSTAL_CODES',
  GET_VORMUND_POSTAL_CODES_RESULT = 'GUARDIAN_DATA_GET_VORMUND_POSTAL_CODES_RESULT',
  GET_VORMUND_POSTAL_CODES_ERROR = 'GUARDIAN_DATA_GET_VORMUND_POSTAL_CODES_ERROR',
  NON_EXISTENT_GUARDIAN = 'NON_EXISTENT_GUARDIAN',
}

export interface GuardianDataGetAction {
  type: typeof GuardianDataAction.GET;
  payload: GuardianData;
  pifId: number;
}

export interface GuardianDataGetResultAction {
  type: typeof GuardianDataAction.GET_RESULT;
  payload: GuardianData;
}

export interface GuardianDataChangeAction {
  type: typeof GuardianDataAction.CHANGE;
  payload: string;
  changedField: string;
}

export interface GuardianDataSubmitAction {
  type: typeof GuardianDataAction.SUBMIT;
  payload: GuardianData;
  pifId: number;
}

export interface GuardianDataResultAction {
  type: typeof GuardianDataAction.RESULT;
  payload: GuardianData;
}

export interface GuardianDataRetrieveAction {
  type: typeof GuardianDataAction.GET;
  demandId: number;
  pifId: number;
}

export interface GuardianDataGetPostalCodesAction {
  type: typeof GuardianDataAction.GET_VORMUND_POSTAL_CODES;
  payload: GuardianData;
}

export interface GuardianDataGetPostalCodesResultAction {
  type: typeof GuardianDataAction.GET_VORMUND_POSTAL_CODES_RESULT;
  payload: PostalCode;
}

export interface GuardianDataGetPostalCodesErrorAction {
  type: typeof GuardianDataAction.GET_VORMUND_POSTAL_CODES_ERROR;
  payload: string;
}

export type GuardianDataActionType =
  | GuardianDataRetrieveAction
  | GuardianDataChangeAction
  | GuardianDataSubmitAction
  | GuardianDataResultAction
  | GuardianDataGetAction
  | GuardianDataGetResultAction
  | GuardianDataGetPostalCodesAction
  | GuardianDataGetPostalCodesResultAction
  | GuardianDataGetPostalCodesErrorAction;
