import { DegreeProgram, EducationData, Institution, InstitutionType } from './types';
import { CaseCreateDto, CaseDto } from './../notifications/types';

export enum EducationDataAction {
  GET = 'EDUCATION_DATA_GET',
  CHANGE = 'EDUCATION_DATA_CHANGE',
  SUBMIT = 'EDUCATION_DATA_SUBMIT',
  RESULT = 'EDUCATION_DATA_RESULT',
  GET_RESULT = 'EDUCATION_DATE_GET_RESULT',
  NON_EXISTENT_EDUCATION = 'NON_EXISTENT_EDUCATION',
  INTERRUPT = 'EDUCATION_INTERRUPT',
  INTERRUPT_RESULT = 'EDUCATION_INTERRUPT_RESULT',
  INTERRUPT_ERROR = 'EDUCATION_INTERRUPT_ERROR',
  EDUCATION_CHANGE = 'EDUCATION_CHANGE',
  EDUCATION_CHANGE_ERROR = 'EDUCATION_CHANGE_ERROR',
  COMPLETE = 'EDUCATION_COMPLETE',
  CANCELED = 'EDUCATION_CANCELED',
  GET_INSTITUTION_TYPES = 'EDUCATION_DATA_GET_INSTITUTION_TYPES',
  GET_INSTITUTION_TYPES_SUCCESS = 'EDUCATION_DATA_GET_INSTITUTION_TYPES_SUCCESS',
  GET_INSTITUTION_TYPES_ERROR = 'EDUCATION_DATA_GET_INSTITUTION_TYPE_ERROR',
  GET_INSTITUTIONS = 'EDUCATION_DATA_GET_INSTITUTION',
  GET_INSTITUTIONS_SUCCESS = 'EDUCATION_DATA_GET_INSTITUTION_TYPE_SUCCESS',
  GET_INSTITUTIONS_ERROR = 'EDUCATION_DATA_GET_INSTITUTION_TYPE_ERROR',
  GET_DEGREE_PROGRAMS = 'EDUCATION_DATA_GET_DEGREE_PROGRAMS',
  GET_DEGREE_PROGRAMS_SUCCESS = 'EDUCATION_DATA_GET_DEGREE_PROGRAMS_SUCCESS',
  GET_DEGREE_PROGRAMS_ERROR = 'EDUCATION_DATA_GET_DEGREE_PROGRAMS_ERROR',
  UPDATE_DATA = 'EDUCATION_DATA_UPDATE_DATA',
  UPDATE_AVAILABLE_INSTITUTIONS = 'EDUCATION_DATA_UPDATE_AVAILABLE_INSTITUIONS',
  UPDATE_AVAILABLE_DEGREE_PROGRAMS = 'EDUCATION_DATA_UPDATE_AVAILABLE_DEGREE_PROGRAMS',
}

export interface EducationDataGetAction {
  type: typeof EducationDataAction.GET;
  payload: EducationData;
  demandId: number;
}

export interface EducationDataGetResultAction {
  type: typeof EducationDataAction.GET_RESULT;
  payload: EducationData;
}

export interface EducationDataChangeAction {
  type: typeof EducationDataAction.CHANGE;
  payload: string;
  changedField: string;
}

export interface EducationDataSubmitAction {
  type: typeof EducationDataAction.SUBMIT;
  payload: EducationData;
  demandId: number;
}

export interface EducationDataResultAction {
  type: typeof EducationDataAction.RESULT;
  payload: EducationData;
}

export interface NonExistentEducationAction {
  type: typeof EducationDataAction.NON_EXISTENT_EDUCATION;
}

export interface EducationInterruptAction {
  type: typeof EducationDataAction.INTERRUPT;
  payload: CaseCreateDto;
  dossierId: number;
}

export interface EducationInterruptResultAction {
  type: typeof EducationDataAction.INTERRUPT_RESULT;
  payload: CaseDto;
  dossierId: number;
}

export interface EducationInterruptErrorAction {
  type: typeof EducationDataAction.INTERRUPT_ERROR;
  payload: string;
  dossierId: number;
}

export interface EducationChangeAction {
  type: typeof EducationDataAction.EDUCATION_CHANGE;
  payload: CaseCreateDto;
  dossierId: number;
}

export interface EducationChangeErrorAction {
  type: typeof EducationDataAction.EDUCATION_CHANGE_ERROR;
  payload: string;
  dossierId: number;
}

export interface EducationCompletedSubmitAction {
  type: typeof EducationDataAction.COMPLETE;
  payload: CaseCreateDto;
  dossierId: number;
}

export interface EducationCanceledSubmitAction {
  type: typeof EducationDataAction.CANCELED;
  payload: CaseCreateDto;
  dossierId: number;
}

export interface EducationDataGetInstitutionTypesAction {
  type: typeof EducationDataAction.GET_INSTITUTION_TYPES;
}

export interface EducationDataGetInstitutionTypesSuccessAction {
  type: typeof EducationDataAction.GET_INSTITUTION_TYPES_SUCCESS;
  payload: Array<InstitutionType>;
}

export interface EducationDataGetInstitutionTypesErrorAction {
  type: typeof EducationDataAction.GET_INSTITUTION_TYPES_ERROR;
  payload: string;
}

export interface EducationDataGetInstitutionsAction {
  type: typeof EducationDataAction.GET_INSTITUTIONS;
  payload: number;
}

export interface EducationDataGetInstitutionsSuccessAction {
  type: typeof EducationDataAction.GET_INSTITUTIONS_SUCCESS;
  payload: Institution[];
}

export interface EducationDataGetInstitutionsErrorAction {
  type: typeof EducationDataAction.GET_INSTITUTIONS_ERROR;
  payload: string;
}

export interface EducationDataGetDegreeProgramsAction {
  type: typeof EducationDataAction.GET_DEGREE_PROGRAMS;
  payload: number;
}

export interface EducationDataGetDegreeProgramsSuccessAction {
  type: typeof EducationDataAction.GET_DEGREE_PROGRAMS_SUCCESS;
  payload: DegreeProgram[];
}

export interface EducationDataGetDegreeProgramsErrorAction {
  type: typeof EducationDataAction.GET_DEGREE_PROGRAMS_ERROR;
  payload: string;
}

export interface EducationDataUpdateAction {
  type: typeof EducationDataAction.UPDATE_DATA;
  payload: EducationData;
}

export interface EducationDataUpdateAvailableInstituionsAction {
  type: typeof EducationDataAction.UPDATE_AVAILABLE_INSTITUTIONS;
  payload: Institution[];
}

export interface EducationDataUpdateAvailableDegreeProgramsAction {
  type: typeof EducationDataAction.UPDATE_AVAILABLE_DEGREE_PROGRAMS;
  payload: DegreeProgram[];
}

export type EducationDataActionType =
  | EducationDataGetAction
  | EducationDataGetResultAction
  | EducationDataChangeAction
  | EducationDataSubmitAction
  | EducationDataResultAction
  | NonExistentEducationAction
  | EducationInterruptAction
  | EducationInterruptResultAction
  | EducationInterruptErrorAction
  | EducationChangeAction
  | EducationChangeErrorAction
  | EducationCompletedSubmitAction
  | EducationCanceledSubmitAction
  | EducationDataGetInstitutionTypesAction
  | EducationDataGetInstitutionTypesSuccessAction
  | EducationDataGetInstitutionTypesErrorAction
  | EducationDataGetInstitutionsAction
  | EducationDataGetInstitutionsSuccessAction
  | EducationDataGetInstitutionsErrorAction
  | EducationDataGetDegreeProgramsAction
  | EducationDataGetDegreeProgramsSuccessAction
  | EducationDataGetDegreeProgramsErrorAction
  | EducationDataUpdateAction
  | EducationDataUpdateAvailableInstituionsAction
  | EducationDataUpdateAvailableDegreeProgramsAction;
