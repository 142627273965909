// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export function exctractNonNullPropertiesFromObject(obj: any): any {
  const properties = Object.keys(obj);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const objCopy = obj;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const notNullProps: any = {};
  for (const key in properties) {
    const value = objCopy[properties[key]];
    if (value !== null) {
      notNullProps[properties[key]] = value;
    }
  }
  return notNullProps;
}

export function extractToggleValue(value: boolean | undefined | null, trueValue = '1', falseValue = '2'): string {
  return value === null || value === undefined ? '' : value ? trueValue : falseValue;
}

export function extractToggleValueNumber(value: number | undefined | null): string {
  return value === null || value === undefined ? '' : value.toString();
}

/**
 * Retrieving age using birthdate
 * as param
 * @param value
 */
export function getAge(value: string | undefined | null): number | undefined {
  if (value === null || value === undefined) {
    return undefined;
  }
  const timestamp = Date.parse(value);
  if (isNaN(timestamp)) {
    return undefined;
  }
  const parsedDate = new Date(timestamp);

  const timeDiff = Math.abs(Date.now() - parsedDate.getTime());
  return Math.floor(timeDiff / (1000 * 3600 * 24) / 365.25);
}
