import React, { ReactElement } from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import { black } from './../../theme';

export function ZoomPeopleIcon(props: SvgIconProps): ReactElement {
  const color = props.htmlColor ? props.htmlColor : black.main;
  return (
    <SvgIcon {...props}>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <path d="M0 0H80V80H0z" transform="translate(-1048 -157) translate(1048 157)" />
            <path
              fill={color}
              fillRule="nonzero"
              d="M47.7 49c-.552 0-1 .448-1 1s.448 1 1 1c4.38 0 9.33 2.63 10.44 7H24.69c.88-4.22 4.35-7 9-7 .552 0 1-.448 1-1s-.448-1-1-1c-5.747-.207-10.643 4.139-11.12 9.87-.026.282.068.562.26.77.19.21.458.33.74.33h35.75c.282 0 .55-.12.74-.33.192-.208.286-.488.26-.77C59.73 52.72 53.31 49 47.7 49zM58.35 40.55c-.552 0-1 .448-1 1s.448 1 1 1c2.26 0 4.17 1.31 4.17 2.87 0 .552.448 1 1 1s1-.448 1-1c0-2.69-2.77-4.87-6.17-4.87zM55.92 40.06c2.25 0 4-5.81 4-8.09 0-2.228-1.807-4.035-4.035-4.035s-4.035 1.807-4.035 4.035c.04 2.28 1.83 8.09 4.07 8.09zm0-10.13c1.105 0 2 .895 2 2-.142 2.138-.83 4.204-2 6-1.165-1.798-1.853-3.863-2-6 0-1.105.895-2 2-2zM24 35.59c0-.552-.448-1-1-1-3.39 0-6.16 2.19-6.16 4.87 0 .552.448 1 1 1s1-.448 1-1c0-1.55 1.91-2.87 4.16-2.87.552 0 1-.448 1-1zM26.78 35c2.36 0 4.22-6.17 4.22-8.58.076-1.566-.716-3.046-2.06-3.852-1.345-.806-3.025-.806-4.37 0-1.344.806-2.136 2.286-2.06 3.852.02 2.41 1.9 8.58 4.27 8.58zm0-10.83c1.233.011 2.226 1.017 2.22 2.25-.167 2.319-.939 4.553-2.24 6.48-1.305-1.926-2.08-4.16-2.25-6.48 0-.6.24-1.176.666-1.598.426-.423 1.004-.657 1.604-.652z"
              transform="translate(-1048 -157) translate(1048 157)"
            />
            <path
              fill={color}
              fillRule="nonzero"
              d="M30.26 36.66v2.57c.028 1.259.877 2.351 2.09 2.69.65 3.95 3.89 7.92 8.12 7.92 4.23 0 7.47-4 8.12-7.92 1.211-.342 2.058-1.432 2.09-2.69v-2.57c-.052-.908-.545-1.734-1.32-2.21v-1.08c.986-1.275 1.168-2.997.47-4.45-.93-1.93-3.29-3.18-6-3.18-1.856.01-3.668.567-5.21 1.6-4.87.11-7 1.81-7 5.48v1.62c-.79.471-1.3 1.301-1.36 2.22zm2 0c0-.16.25-.49.8-.8l.51-.28v-2.76c0-1.45 0-3.44 5.38-3.49h.3l.26-.18c1.26-.905 2.769-1.398 4.32-1.41 1.92 0 3.61.82 4.2 2.05.381.892.205 1.925-.45 2.64l-.22.27v2.88l.51.28c.56.31.81.64.81.8v2.57c0 .45-.44.79-1.14.89l-.79.11-.07.8c-.26 3.22-2.88 6.81-6.21 6.81s-5.95-3.59-6.22-6.84l-.06-.8-.79-.11c-.72-.1-1.14-.44-1.14-.89v-2.54z"
              transform="translate(-1048 -157) translate(1048 157)"
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}
