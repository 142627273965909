import React, { ChangeEvent, ReactElement, useState } from 'react';
import { Button, Container } from '@material-ui/core';
import './Identification.scss';
import { ahvn13validation, requiredValidation } from '../../utils/validators';
import ZoomDatePicker from '../../components/forms/date-picker/ZoomDatePicker';
import ZoomTextInput from '../../components/forms/text-input/ZoomTextInput';
import { i18nLabel } from '../../store/i18n/helpers';
import { WIAAStore } from '../../store/types';
import { connect } from 'react-redux';
import { IdentificationDataAction, IdentificationDataActionType } from '../../store/identification-data/actions';
import { IdentificationData } from '../../store/identification-data/types';
import ZoomHeader, {
  mapStoreToHeaderProps,
  ZoomHeaderProps,
} from '../../components/base-page-components/header/ZoomHeader';
import ZoomFooter, {
  mapStoreToFooterProps,
  ZoomFooterProps,
} from '../../components/base-page-components/footer/ZoomFooter';
import { setTimeToMiddleOfDay } from '../../utils/date-manipulation';
import { multipleFirstNameFormat } from '../../utils/formaters';

export interface IdentificationProps {
  id?: string;
  firstName?: string;
  lastName?: string;
  ahvn13?: string;
  dateOfBirth?: Date;
  labelWelcome?: string;
  labelDescription?: string;
  labelAhvn13?: string;
  labelDateOfBirth?: string;
  helpTextAhvn13?: string;
  labelNext: string;
  errorLabels?: { [key: string]: string };
  headerProps: ZoomHeaderProps;
  footerProps: ZoomFooterProps;
  error?: string;
  dispatch?: (action: IdentificationDataActionType) => void;
}

interface IdentificationDataFormErrors {
  id?: string;
  ahvn13?: string;
  dateOfBirth?: string;
}

export const Identification = (props: IdentificationProps): ReactElement => {
  const [ahvn13, setAhvn13] = useState(props.ahvn13 ? props.ahvn13 : '');
  const [dateOfBirth, setDateOfBirth] = useState(props.dateOfBirth ? props.dateOfBirth : undefined);
  const initialErrors: IdentificationDataFormErrors = {};
  const [errors, setErrors] = useState(initialErrors);

  const onChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setAhvn13(e.target.value);
    setErrors({ ...errors, ahvn13: ahvn13validation(e.target.value, props.errorLabels ? props.errorLabels : {}) });
  };

  const onChangeDate = (newDate: Date | null) => {
    if (null === newDate) {
      setDateOfBirth(undefined);
    } else {
      setDateOfBirth(newDate);
    }
    setErrors({
      ...errors,
      dateOfBirth: newDate === null ? requiredValidation('', props.errorLabels ? props.errorLabels : {}) : '',
    });
  };

  const getFormErrors = (): IdentificationDataFormErrors => {
    const errors: IdentificationDataFormErrors = {};
    errors.ahvn13 = ahvn13validation(ahvn13, props.errorLabels ? props.errorLabels : {});
    errors.dateOfBirth = requiredValidation(
      dateOfBirth ? dateOfBirth.toDateString() : '',
      props.errorLabels ? props.errorLabels : {},
    );
    return errors;
  };

  const validateForm = (): boolean => {
    const newErrors = getFormErrors();
    setErrors(newErrors);
    const errorsPresent = Object.values(newErrors).some((value) => value !== '');
    return !errorsPresent;
  };

  const getIdentificationData = (): IdentificationData => {
    const normalizedDate = setTimeToMiddleOfDay(dateOfBirth);
    return {
      ahvNumber: ahvn13,
      birthDate: normalizedDate ? normalizedDate.toISOString() : '',
    };
  };

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    if (validateForm() && props.dispatch) {
      props.dispatch({
        type: IdentificationDataAction.SUBMIT,
        payload: getIdentificationData(),
      });
    }
    event.preventDefault();
  };

  return (
    <>
      <ZoomHeader {...props.headerProps}></ZoomHeader>
      <main className="main">
        <Container>
          <div id="main-page-container">
            <form onSubmit={onSubmit}>
              <h1>
                {props.labelWelcome}
                {props.firstName + ' ' + props.lastName}
              </h1>
              <p>{props.labelDescription}</p>
              <div className="form-element-container">
                <ZoomTextInput
                  label={props.labelAhvn13 ? props.labelAhvn13 : ''}
                  value={ahvn13}
                  name="ahvn13"
                  onChange={onChange}
                  helpText={props.helpTextAhvn13}
                  error={!!errors.ahvn13}
                  errorMessage={errors.ahvn13}
                />
              </div>
              <div className="form-element-container">
                <ZoomDatePicker
                  label={props.labelDateOfBirth ? props.labelDateOfBirth : ''}
                  onChange={onChangeDate}
                  name="dateOfBirth"
                  value={dateOfBirth}
                  error={!!errors.dateOfBirth}
                  errorMessage={errors.dateOfBirth}
                />
              </div>
              {props.error && (
                <p className="identification-error">{i18nLabel(props.errorLabels, props.error, props.error)}</p>
              )}
              <div className="form-actions-container">
                <Button color="primary" size="large" type="submit" variant="contained" id="next-identification-data">
                  {props.labelNext}
                </Button>
              </div>
            </form>
          </div>
        </Container>
      </main>
      <ZoomFooter {...props.footerProps}></ZoomFooter>
    </>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export function mapStateToProps(store: WIAAStore, ownProps: any): IdentificationProps {
  const { firstName, lastName } = ownProps.match.params;

  const headerProps: ZoomHeaderProps = mapStoreToHeaderProps(store);
  const footerProps: ZoomFooterProps = mapStoreToFooterProps(store);

  return {
    firstName: multipleFirstNameFormat(firstName),
    lastName,
    headerProps,
    footerProps,
    labelWelcome: i18nLabel(store.translationLabels, 'Identification_Welcome', 'Willkommen, '),
    labelDescription: i18nLabel(
      store.translationLabels,
      'Identification_Description',
      'Um das Stipendienportal nutzen zu können und ein Stipendienantrag zu stellen, müssen Sie sich identifizieren. Bitte machen Sie Ihre Angaben.',
    ),
    helpTextAhvn13: i18nLabel(
      store.translationLabels,
      'Identification_AHV_Help',
      'Die 13-stellige Sozialversicherungsnummer ist auf der Versicherungskarte der Krankenkasse oder auf dem Sozialversicherungs-Ausweis ersichtlich. Sollte Ihnen diese Nummer nicht bekannt sein, geben Sie bitte 756.0000.0000.00 ein.',
    ),
    labelDateOfBirth: i18nLabel(store.translationLabels, 'Identification_BirthDate', 'Geburtsdatum*'),
    labelAhvn13: i18nLabel(store.translationLabels, 'Identification_AHV', 'AHV-Nummer*'),
    labelNext: i18nLabel(store.translationLabels, 'Identification_Next', 'Weiter'),
    errorLabels: store.translationLabels,
    error: store.identificationData.error,
  };
}

export default connect(mapStateToProps)(Identification);
