import { Container, Grid } from '@material-ui/core';
import React, { ReactElement } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import ZoomFooter, {
  mapStoreToFooterProps,
  ZoomFooterProps,
} from '../../components/base-page-components/footer/ZoomFooter';
import ZoomHeader, {
  mapStoreToHeaderProps,
  ZoomHeaderProps,
} from '../../components/base-page-components/header/ZoomHeader';
import { ZoomArrowLeftIcon } from '../../components/icons/ZoomArrowLeftIcon';
import { i18nLabel } from '../../store/i18n/helpers';
import { WIAAStore } from '../../store/types';
import { blue } from '../../theme';

export interface DataImpressumProps {
  labelTitle?: string;
  labelSubtitle1?: string;
  labelSubtitle2?: string;
  labelDepartment1?: string;
  labelDepartment2?: string;
  labelSection1?: string;
  labelSection2?: string;
  labelBranch?: string;
  labelStreet1?: string;
  labelStreet2?: string;
  labelPlz1?: string;
  labelPlz2?: string;
  labelRedirectionLink?: string;
  labelRedirectionLinkUrl?: string;
  labelBackArrow?: string;
  footerProps: ZoomFooterProps;
  headerProps: ZoomHeaderProps;
}

export const DataImpressum = (props: DataImpressumProps): ReactElement => {
  const history = useHistory();

  return (
    <>
      <ZoomHeader
        appName="Stipendienportal"
        userName="Max Mustermann"
        ahvn="756-4352-9990-13"
        hideUserMenu={true}
        labelMyData="Meine Daten"
        labelMyEducation="Meine Ausbildungen"
        labelMyScholarship="Meine Stipendienanträge"
        labelPayout="Auszahlungen"
        labelRefund="Rückzahlungen"
        labelPowerOfAttorney="Vollmachten"
        labelScholarshipHolder="Stipendiat wählen"
        labelLogout="Abmelden"
      />
      <main className="main main-data-protection">
        <Container>
          <div id="main-page-container">
            <div>
              <div className="back-button-desktop" onClick={() => history.goBack()}>
                <ZoomArrowLeftIcon viewBox="0 0 80 80" fontSize="large" htmlColor={blue.main}></ZoomArrowLeftIcon>
                <span className="back-button">{props.labelBackArrow}</span>
              </div>
            </div>
            <h1>{props.labelTitle}</h1>
            <Grid container direction="row" alignItems="stretch" justify="flex-start" spacing={3}>
              <Grid item xs={12}>
                <h2>{props.labelSubtitle1}</h2>
                <ul>
                  <li>{props.labelDepartment1}</li>
                  <li>{props.labelSection1}</li>
                  <li>{props.labelBranch}</li>
                  <li>{props.labelStreet1}</li>
                  <li>{props.labelPlz1}</li>
                  <li>
                    <a href={props.labelRedirectionLinkUrl}>{props.labelRedirectionLink}</a>
                  </li>
                </ul>
              </Grid>
              <Grid item xs={12}>
                <h2>{props.labelSubtitle2}</h2>
                <ul>
                  <li>{props.labelDepartment2}</li>
                  <li>{props.labelSection2}</li>
                  <li>{props.labelStreet2}</li>
                  <li>{props.labelPlz2}</li>
                </ul>
              </Grid>
            </Grid>
          </div>
        </Container>
      </main>
      <ZoomFooter {...props.footerProps}></ZoomFooter>
    </>
  );
};

export function mapStateToProps(store: WIAAStore): DataImpressumProps {
  return {
    labelBackArrow: i18nLabel(store.translationLabels, 'Data_Impressum_Back', 'Zurück'),
    labelTitle: i18nLabel(store.translationLabels, 'Data_Impressum_Title', 'Impressum'),
    labelSubtitle1: i18nLabel(
      store.translationLabels,
      'Data_Impressum_Subtitle1',
      'Gesamtverantwortung für das Stipendienportal',
    ),
    labelSubtitle2: i18nLabel(store.translationLabels, 'Data_Impressum_Subtitle2', 'Betrieb'),
    labelDepartment1: i18nLabel(
      store.translationLabels,
      'Data_Impressum_Department1',
      'Departement Bildung, Kultur und Sport',
    ),
    labelDepartment2: i18nLabel(
      store.translationLabels,
      'Data_Impressum_Department2',
      'Departement Finanzen und Ressourcen',
    ),
    labelSection1: i18nLabel(store.translationLabels, 'Data_Impressum_Section1', 'Abteilung Hochschulen und Sport'),
    labelSection2: i18nLabel(store.translationLabels, 'Data_Impressum_Section2', 'Informatik Aargau'),
    labelBranch: i18nLabel(store.translationLabels, 'Data_Impressum_Branch', 'Sektion Stipendien'),
    labelStreet1: i18nLabel(store.translationLabels, 'Data_Impressum_Street1', 'Bachstrasse 15'),
    labelStreet2: i18nLabel(store.translationLabels, 'Data_Impressum_Street2', 'Hintere Bahnhofstrasse 8'),
    labelPlz1: i18nLabel(store.translationLabels, 'Data_Impressum_Plz1', '5001 Aarau'),
    labelPlz2: i18nLabel(store.translationLabels, 'Data_Impressum_Plz2', '5000 Aarau'),
    labelRedirectionLinkUrl: i18nLabel(
      store.translationLabels,
      'Data_Impressum_Redirection_Link_URL',
      'https://www.ag.ch/stipendien',
    ),
    labelRedirectionLink: i18nLabel(store.translationLabels, 'Data_Impressum_Redirection_Link', 'www.ag.ch/stipendien'),
    headerProps: mapStoreToHeaderProps(store),
    footerProps: mapStoreToFooterProps(store),
  };
}

export default connect(mapStateToProps)(DataImpressum);
