import React, { ReactElement } from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import { blue } from '../../theme';

export function ZoomUploadIcon(props: SvgIconProps): ReactElement {
  const color = props.htmlColor ? props.htmlColor : blue.main;
  return (
    <SvgIcon {...props}>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <path d="M0 0H80V80H0z" transform="translate(-948 -357) translate(948 357)" />
            <path
              fill={color}
              fillRule="nonzero"
              d="M47.36 16.48H28.43c-1.013 0-1.985.402-2.701 1.119-.717.716-1.119 1.688-1.119 2.701v2.53c0 .552.448 1 1 1s1-.448 1-1V20.3c0-1.005.815-1.82 1.82-1.82H46v6.4c0 .552.448 1 1 1h6.63V53.6c.003.484-.188.948-.53 1.29-.342.342-.806.533-1.29.53h-.32c-.552 0-1 .448-1 1s.448 1 1 1h.32c2.11 0 3.82-1.71 3.82-3.82V24.39l-8.27-7.91zm.64 3.35l4.22 4.05H48v-4.05zM48 55.42H28.43c-.484.003-.948-.188-1.29-.53-.342-.342-.533-.806-.53-1.29V26.86c0-.552-.448-1-1-1s-1 .448-1 1V53.6c0 2.11 1.71 3.82 3.82 3.82h10.66v6.06c0 .552.448 1 1 1s1-.448 1-1v-6.06H48c.552 0 1-.448 1-1s-.448-1-1-1z"
              transform="translate(-948 -357) translate(948 357)"
            />
            <path
              fill={color}
              fillRule="nonzero"
              d="M40.09 53.76c.552 0 1-.448 1-1v-9l5.57 5.54c.188.19.443.296.71.296.267 0 .522-.107.71-.296.388-.39.388-1.02 0-1.41l-7.28-7.22-.07-.05c-.077-.063-.16-.116-.25-.16-.246-.103-.524-.103-.77 0-.113.044-.216.112-.3.2l-7.28 7.25c-.388.39-.388 1.02 0 1.41.39.388 1.02.388 1.41 0l5.57-5.54v9c.01.537.443.97.98.98z"
              transform="translate(-948 -357) translate(948 357)"
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}
