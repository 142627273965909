import { ZoomFooterProps } from '../../components/base-page-components/footer/ZoomFooter';

export const initialState: ZoomFooterProps = {
  upward: 'Nach oben',
  contact: 'Kontakt',
  department: 'Departement Bildung, Kultur und Sport',
  subDepartment: 'Abteilung Hochschulen und Sport',
  section: 'Sektion Stipendien',
  street: 'Bachstrasse 15',
  codeAndCity: '5000 Aargau',
  phoneNumber: '+41 (0)62 835 22 70',
  eMail: 'stipendien@ag.ch',
  webPage: 'www.ag.ch/stipendien',
  imprint: 'Impressum',
  legalNotice: 'Rechtliche Hinweise',
  accessibility: 'Barrierefreiheit',
  companyName: '© Kanton Aargau, ',
};

export function footerDataReducer(state = initialState): ZoomFooterProps {
  return state;
}
