import produce from 'immer';
import { Action } from 'redux';
import { NotesDataAction, NotesDataGetResultAction } from './actions';
import { NotesDataStore } from './types';

const initialState: NotesDataStore = {
  notes: '',
};

export const notesReducer = (state = initialState, action: Action): NotesDataStore => {
  return produce(state, (draft) => {
    switch (action.type) {
      case NotesDataAction.GET_RESULT:
        const resultAction = action as NotesDataGetResultAction;
        draft.notes = resultAction.payload;
        break;
    }
    return draft;
  });
};
