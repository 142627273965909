import React, { ReactElement } from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import { black } from './../../theme';

export function ZoomScholarshipIcon(props: SvgIconProps): ReactElement {
  const color = props.htmlColor ? props.htmlColor : black.main;
  return (
    <SvgIcon {...props}>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <path d="M0 0H80V80H0z" transform="translate(-848 -357) translate(848 357)" />
            <path
              fill={color}
              fillRule="nonzero"
              d="M62.61 36.91l-21.23-10.4c-.505-.246-1.095-.246-1.6 0l-21.23 10.4c-.661.322-1.058 1.017-1 1.75.06.736.558 1.363 1.26 1.59l6.85 2.15-.12 9.39c-.03.497.146.985.486 1.348.34.363.816.57 1.314.572.269-.001.535-.06.78-.17 7.955-3.45 16.985-3.45 24.94 0 .57.265 1.236.216 1.76-.13.52-.333.83-.912.82-1.53l-.12-9.48 6.75-2.13c.73-.186 1.268-.808 1.347-1.558.078-.75-.32-1.47-.997-1.802h-.01zm-9.12 4l.14 10.66c-8.346-3.51-17.754-3.51-26.1 0l.12-9.21h.19c.145-.001.288-.032.42-.09 1.986-.885 4.098-1.455 6.26-1.69 2.36-.091 4.723-.061 7.08.09.552.039 1.031-.378 1.07-.93.039-.552-.378-1.031-.93-1.07-2.444-.155-4.894-.182-7.34-.08-2.418.242-4.781.87-7 1.86-.127.063-.242.147-.34.25l-7.21-2.27 20.7-10.14 20.7 10.13-7.76 2.49z"
              transform="translate(-848 -357) translate(848 357)"
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}
