import { Action } from 'redux';
import { Epic, ofType } from 'redux-observable';
import { Observable, of } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { customAjax } from '../../utils/ajax-wrapper';
import { handleRequestFailureInEpic, redirectToDataUnavailableOr } from '../../utils/error-handling';
import { MyDemandPageAction, MyDemandPageGetAction, MyDemandPageGetResultAction } from './actions';

const addMyDemandData = (payload: AjaxResponse): MyDemandPageGetResultAction => {
  return {
    type: MyDemandPageAction.GET_RESULT,
    payload: payload.response,
  };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getMyDemandDataOnError = (error: any, dossierId: number): any => {
  if (error.status === 404) {
    return redirectToDataUnavailableOr(error, { type: MyDemandPageAction.NON_EXISTENT_MY_DEMAND_PAGE }, dossierId);
  }
  return handleRequestFailureInEpic(error);
};

export const getMyDemandDataEpic: Epic<Action, Action> = (action$: Observable<Action>): Observable<Action> =>
  action$.pipe(
    ofType(MyDemandPageAction.GET),
    mergeMap((action) => {
      const dossierId = (action as MyDemandPageGetAction).payload;
      return customAjax({
        subRoute: `/dossier-intranet/v1/mydemand/${dossierId}`,
        withCredentials: true,
        method: 'GET',
        headers: {
          'Accept-Language': 'de-CH',
        },
      }).pipe(
        map((response) => addMyDemandData(response)),
        catchError((error) => {
          return of(getMyDemandDataOnError(error, dossierId));
        }),
      );
    }),
  );
