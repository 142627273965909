import React, { ReactElement } from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import { blue } from './../../theme';

export function ZoomAttachmentIcon(props: SvgIconProps): ReactElement {
  const color = props.htmlColor ? props.htmlColor : blue.main;
  return (
    <SvgIcon {...props}>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <path d="M0 0H80V80H0z" transform="translate(-56 -257) translate(56 257)" />
            <path
              fill={color}
              fillRule="nonzero"
              d="M37.08 64.46c-6.001-.005-10.876-4.849-10.92-10.85L26 31c-.032-3.812 1.453-7.48 4.128-10.196 2.675-2.717 6.32-4.258 10.132-4.284h.1c7.884.016 14.284 6.377 14.35 14.26v22.91h-4v-22.9c-.041-5.716-4.709-10.316-10.425-10.275-5.716.041-10.316 4.709-10.275 10.425l.15 22.65c.027 3.802 3.118 6.87 6.92 6.87h.05c1.84-.016 3.598-.763 4.886-2.076C43.305 57.071 44.02 55.3 44 53.46l-.15-21.27c.007-.92-.353-1.806-1-2.46-.67-.635-1.557-.992-2.48-1-.925.005-1.81.378-2.458 1.037-.65.658-1.01 1.548-1.002 2.473L37 50.11h-4l-.12-17.81c-.018-2.68 1.395-5.165 3.707-6.52 2.311-1.355 5.17-1.374 7.5-.05s3.775 3.79 3.793 6.47L48 53.46c.039 6.033-4.817 10.956-10.85 11h-.07z"
              transform="translate(-56 -257) translate(56 257)"
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}
