import produce from 'immer';
import React, { ReactElement } from 'react';
import { connect } from 'react-redux';
import { DocumentsActionType } from '../../store/documents/actions';
import { i18nLabel } from '../../store/i18n/helpers';
import { OptionTranslation } from '../../store/i18n/types';
import { WIAAStore } from '../../store/types';
import { UploadDocumentGroupForm, ZoomDocumentsRole } from '../upload-document-group-form/UploadDocumentGroupForm';

export interface DocumentsFormProps {
  roles: Array<ZoomDocumentsRole>;
  labelChooseDocument: string;
  labelDragDocument: string;
  labelOr: string;
  labelSuccess: string;
  labelsDocumentTypeName: OptionTranslation[];
  labelsRoles: OptionTranslation[];
  dispatch?: (action: DocumentsActionType) => void;
  demandId: number;
}

export const DocumentsForm = (props: DocumentsFormProps): ReactElement => {
  const renderRoleHtml = (role: ZoomDocumentsRole) => {
    if (role.documentTypes.length > 0) {
      return (
        <UploadDocumentGroupForm
          key={role.role.toString()}
          role={role}
          labelOr={props.labelOr}
          labelChooseDocument={props.labelChooseDocument}
          labelDragDocument={props.labelDragDocument}
          labelSuccess={props.labelSuccess}
          labelsDocumentTypeName={props.labelsDocumentTypeName}
          labelsRoles={props.labelsRoles}
          dispatch={props.dispatch}
          demandId={props.demandId}
        />
      );
    }
    return <></>;
  };
  const rolesToRender = props.roles.map((role) => renderRoleHtml(role));
  return <div>{rolesToRender}</div>;
};

export function mapDocumentsFormStateToProps(store: WIAAStore): DocumentsFormProps {
  const { demandId } = store.userData;
  const roles = produce(store.documentsData.roles, (immerRoles) => {
    for (const role of immerRoles) {
      for (const docType of role.documentTypes) {
        docType.uploadError = i18nLabel(
          store.translationLabels,
          docType.uploadError ? docType.uploadError : '',
          docType.uploadError ? docType.uploadError : '',
        );
      }
    }
    return immerRoles;
  });
  return {
    labelChooseDocument: i18nLabel(store.translationLabels, 'Documents_Choose_Document', 'Datei auswählen'),
    labelOr: i18nLabel(store.translationLabels, 'Documents_Or_Label', 'oder'),
    labelDragDocument: i18nLabel(store.translationLabels, 'Documents_Drag_Document', 'Dokument hierhin ziehen'),
    labelSuccess: i18nLabel(store.translationLabels, 'Documents_Success', 'Dokument erfolgreich hochgeladen'),
    labelsDocumentTypeName: store.translationOptions['document-types'],
    labelsRoles: store.translationOptions['roles'],
    roles,
    demandId: Number(demandId),
  };
}

export default connect(mapDocumentsFormStateToProps)(DocumentsForm);
