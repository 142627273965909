import produce from 'immer';
import { EditPayoutAddressDataPageAction, EditPayoutAddressDataPageActionType } from './actions';
import { EditPayoutAddressDataPage } from './types';

export const initialState: EditPayoutAddressDataPage = {};

export function editPayoutAddressDataPageReducer(
  state = initialState,
  action: EditPayoutAddressDataPageActionType,
): EditPayoutAddressDataPage {
  return produce(state, (draft) => {
    switch (action.type) {
      case EditPayoutAddressDataPageAction.GET:
        draft.isLoading = true;
        break;
      case EditPayoutAddressDataPageAction.RESULT:
        draft = action.payload;
        break;
      case EditPayoutAddressDataPageAction.GET_RESULT:
        draft = action.payload;
        draft.isLoading = false;
        break;
    }
    return draft;
  });
}
