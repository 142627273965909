import produce from 'immer';
import { IdentificationStore } from './types';
import { IdentificationDataAction, IdentificationDataActionType } from './actions';

export const initialState: IdentificationStore = {};
export function identificationDataReducer(
  state = initialState,
  action: IdentificationDataActionType,
): IdentificationStore {
  return produce(state, (draft) => {
    switch (action.type) {
      case IdentificationDataAction.ERROR:
        draft.error = action.payload;
        break;
      case IdentificationDataAction.RESET_ERROR:
        draft.error = undefined;
        break;
    }
    return draft;
  });
}
