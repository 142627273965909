import { Button, Grid, ListItemText, CircularProgress, Menu, MenuItem, MenuProps, withStyles } from '@material-ui/core';
import React, { ReactElement, useState } from 'react';
import { black, blue } from '../../theme';
import { ZoomArrowDownIcon } from '../icons/ZoomArrowDownIcon';
import { ZoomArrowUpIcon } from '../icons/ZoomArrowUpIcon';
import moment from 'moment';
import { ZoomPdfIcon } from '../icons/ZoomPdfIcon';
import {
  MyContractsAction,
  MyContractsActionType,
  MyContractsSingleContractGetAction,
} from '../../store/my-contracts/actions';
import { trailingNumbersFormat } from '../../utils/formaters';

export interface LoanContractDto {
  id: number;
  name: string;
  loanAmount: number;
  reimbursedAmount: number;
  remainingAmount: number;
  reimbursementStartDate?: Date;
  periodicity: number;
  rate: number;
  receivedUntil?: Date;
  scholarshipEndDate?: Date;
  amortizationDueFrom: Date | null;
  dossierId: number;
  dispatch?: (action: MyContractsActionType) => void;
}

export interface LoanContractInfoProps extends LoanContractDto {
  labelLoanAmount: string;
  labelReimbursedAmount: string;
  labelRemainingAmount: string;
  labelReimbursementStartDate: string;
  labelPeriodicity: string;
  labelRate: string;
  labelReceivedUntil: string;
  labelScholarshipEndDate: string;
  labelDocumentDownload: string;
  labelChangeContract: string;
  labelForbearanceRequest: string;
  labelNoPlanArranged: string;
  isOpened?: boolean;
  isLoading?: boolean;
  showOnlyInfoText?: boolean;
  contractPaymentPeriodOptions?: { name: string; value: string }[];
  contractTypes?: { name: string; value: string }[];
}

const StyledMenu = withStyles({
  paper: {
    border: '1px solid ' + blue.main,
  },
})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    color: blue.main,
    width: '320px',
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
    '& .MuiListItemText-root': {
      marginLeft: '7px',
    },
  },
}))(MenuItem);

export const LoanContractInfo = (props: LoanContractInfoProps): ReactElement => {
  const [isOpened, setIsOpened] = useState(props.isOpened);
  const [anchorContractEditableOptions, setAnchorContractEditableOptions] = React.useState<null | HTMLElement>(null);
  const stateClass = isOpened ? 'opened' : 'closed';
  const containerClasses = `demand-information ${stateClass}`;
  const toggleState = () => {
    setIsOpened(!isOpened);
    if (props.dispatch) {
      const singleContractAction: MyContractsSingleContractGetAction = {
        type: MyContractsAction.GET_SINGLE_CONTRACT,
        dossierId: props.dossierId,
        contractId: props.id,
      };
      props.dispatch(singleContractAction);
    }
  };

  const handleClickContractEditableOptions = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorContractEditableOptions(event.currentTarget);
  };

  const handleCloseContractEditableOptions = () => {
    setAnchorContractEditableOptions(null);
  };

  const downloadSaldolisteLink = `${process.env.REACT_APP_API_GATEWAY_URL}/zoom-pdf/v1/pdf-conversion/${props.dossierId}/contract-account-balance/${props.id}`;
  const showChangeConditionsButton = props.remainingAmount > 0 && !props.showOnlyInfoText;
  return (
    <div className={containerClasses}>
      <a className="open-close-switch" onClick={toggleState}>
        {isOpened ? (
          <ZoomArrowUpIcon viewBox="0 0 80 80" htmlColor={black.main}></ZoomArrowUpIcon>
        ) : (
          <ZoomArrowDownIcon viewBox="0 0 80 80" htmlColor={black.main}></ZoomArrowDownIcon>
        )}
      </a>
      <h2 className="conversation-title">
        {props.contractTypes ? props.contractTypes.find((option) => option.value == props.name)?.name : ''}
      </h2>

      {isOpened && !props.isLoading && (
        <div>
          {props.showOnlyInfoText !== true && (
            <Grid container spacing={2}>
              <Grid item xs={12} md={4} className="education-info-block">
                <p className="ei-label-line">{props.labelLoanAmount}</p>
                <p className="ei-value-line">CHF {trailingNumbersFormat(props.loanAmount)}</p>
              </Grid>
              <Grid item xs={12} md={4} className="education-info-block">
                <p className="ei-label-line">{props.labelReimbursedAmount}</p>
                <p className="ei-value-line">CHF {trailingNumbersFormat(props.reimbursedAmount)}</p>
              </Grid>
              <Grid item xs={12} md={4} className="education-info-block">
                <p className="ei-label-line">{props.labelRemainingAmount}</p>
                <p className="ei-value-line">CHF {trailingNumbersFormat(props.remainingAmount)}</p>
              </Grid>
              <Grid item xs={12} md={4} className="education-info-block">
                <p className="ei-label-line">{props.labelReimbursementStartDate}</p>
                <p className="ei-value-line">
                  {props.amortizationDueFrom ? moment(props.amortizationDueFrom).format('DD.MM.YYYY') : ''}
                </p>
              </Grid>
              <Grid item xs={12} md={4} className="education-info-block">
                <p className="ei-label-line">{props.labelPeriodicity}</p>
                <p className="ei-value-line">
                  {props.contractPaymentPeriodOptions
                    ? props.contractPaymentPeriodOptions.find((option) => option.value == props.periodicity.toString())
                        ?.name
                    : ''}
                </p>
              </Grid>
              <Grid item xs={12} md={4} className="education-info-block">
                <p className="ei-label-line">{props.labelRate}</p>
                <p className="ei-value-line">CHF {trailingNumbersFormat(props.rate)}</p>
              </Grid>
              <Grid item xs={12} md={4} className="education-info-block">
                <p className="ei-label-line">{props.labelReceivedUntil}</p>
                <p className="ei-value-line">
                  {props.receivedUntil ? moment(props.receivedUntil).format('DD.MM.YYYY') : ''}
                </p>
              </Grid>
              <Grid item xs={12} md={4} className="education-info-block">
                <p className="ei-label-line">{props.labelScholarshipEndDate}</p>
                <p className="ei-value-line">
                  {props.scholarshipEndDate ? moment(props.scholarshipEndDate).format('DD.MM.YYYY') : ''}
                </p>
              </Grid>
            </Grid>
          )}
          {props.showOnlyInfoText && <div>{props.labelNoPlanArranged}</div>}

          <Button
            style={{ marginTop: 10, marginRight: 10 }}
            aria-controls="customized-menu"
            aria-haspopup="true"
            color="primary"
            type="reset"
            variant="outlined"
            download
            href={downloadSaldolisteLink}
          >
            <ZoomPdfIcon viewBox="0 0 80 80" htmlColor={blue.main}></ZoomPdfIcon>
            {props.labelDocumentDownload}
          </Button>

          {showChangeConditionsButton && (
            <>
              <Button
                style={{ marginTop: 10 }}
                aria-controls="contract-editable-options-menu"
                aria-haspopup="true"
                color="primary"
                type="reset"
                variant="outlined"
                onClick={handleClickContractEditableOptions}
              >
                {props.labelChangeContract}
                <ZoomArrowDownIcon viewBox="0 0 80 80" htmlColor={blue.main}></ZoomArrowDownIcon>
              </Button>
              <StyledMenu
                id="contract-editable-options-menu"
                anchorEl={anchorContractEditableOptions}
                keepMounted
                open={Boolean(anchorContractEditableOptions)}
                onClose={handleCloseContractEditableOptions}
              >
                <StyledMenuItem>
                  <a
                    style={{ display: 'inline-flex', width: '100%' }}
                    href={`/repayment-conditions/${props.dossierId}/${props.id}`}
                  >
                    <ListItemText primary={props.labelChangeContract} />
                  </a>
                </StyledMenuItem>
                <StyledMenuItem>
                  <a
                    style={{ display: 'inline-flex', width: '100%' }}
                    href={`/forbearance-request/${props.dossierId}/${props.id}`}
                  >
                    <ListItemText primary={props.labelForbearanceRequest} />
                  </a>
                </StyledMenuItem>
              </StyledMenu>
            </>
          )}
        </div>
      )}
      {isOpened && props.isLoading && <CircularProgress></CircularProgress>}
    </div>
  );
};
