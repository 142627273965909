import { PartnerData } from './types';

export enum PartnerDataAction {
  GET = 'PARTNER_DATA_GET',
  GET_RESULT = 'PARTNER_DATA_GET_RESULT',
  CHANGE = 'PARTNER_DATA_CHANGE',
  SUBMIT = 'PARTNER_DATA_SUBMIT',
  RESULT = 'PARTNER_DATA_RESULT',
  NON_EXISTENT_PARTNER = 'NON_EXISTENT_PARTNER',
}

export interface PartnerDataGetAction {
  type: typeof PartnerDataAction.GET;
  payload: PartnerData;
  pifId: number;
}

export interface PartnerDataGetResultAction {
  type: typeof PartnerDataAction.GET_RESULT;
  payload: PartnerData;
}

export interface PartnerDataChangeAction {
  type: typeof PartnerDataAction.CHANGE;
  payload: string;
  changeField: string;
}

export interface PartnerDataSubmitAction {
  type: typeof PartnerDataAction.SUBMIT;
  payload: PartnerData;
  pifId: number;
}

export interface PartnerDataResultAction {
  type: typeof PartnerDataAction.RESULT;
  payload: PartnerData;
}

export type PartnerDataActionType =
  | PartnerDataGetAction
  | PartnerDataGetResultAction
  | PartnerDataChangeAction
  | PartnerDataSubmitAction
  | PartnerDataResultAction;
