import { Button } from '@material-ui/core';
import React, { ChangeEvent, ReactElement, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ZoomTextInput from '../../components/forms/text-input/ZoomTextInput';
import { i18nLabel } from '../../store/i18n/helpers';
import { OutgoingDataAction, OutgoingDataActionType } from '../../store/outgoing-data/actions';
import { OutgoingData } from '../../store/outgoing-data/types';
import { WIAAStore } from '../../store/types';
import { integerValidation, maxValueValidation } from '../../utils/validators';

export interface OutgoingDataFormProps {
  title?: string;
  taxesAmount?: string;
  formationFees?: string;
  publicTransportCosts?: string;
  livingCosts?: string;
  alimonyPartnerAmount?: string;
  childCareCosts?: string;
  labelTaxesAmount?: string;
  labelFormationFees?: string;
  labelPublicTransportCosts?: string;
  labelLivingCosts?: string;
  labelAlimonyPartnerAmount?: string;
  labelChildCareCosts?: string;
  labelSave?: string;
  helpTextTaxesAmount?: string;
  helpTextFormationFees?: string;
  helpTextPublicTransportCosts?: string;
  helpTextLivingCosts?: string;
  contactDataLivingType?: boolean;
  personalDataCivilStatus?: boolean;
  childDataHaveKids?: boolean;
  demandId: number;
  errorLabels?: { [key: string]: string };
  dispatch?: (action: OutgoingDataActionType) => void;
}

interface OutgoingDataError {
  id?: string;
  taxesAmount?: string;
  formationFees?: string;
  publicTransportCosts?: string;
  livingCosts?: string;
  alimonyPartnerAmount?: string;
  childCareCosts?: string;
}

export const OutgoingDataForm = (props: OutgoingDataFormProps): ReactElement => {
  const [taxesAmount, setTaxesAmount] = useState(props.taxesAmount ? props.taxesAmount : '0');
  const [formationFees, setFormationFees] = useState(props.formationFees ? props.formationFees : '0');
  const [publicTransportCosts, setPublicTransportCosts] = useState(
    props.publicTransportCosts ? props.publicTransportCosts : '0',
  );
  const [livingCosts, setLivingCosts] = useState(props.livingCosts ? props.livingCosts : '0');
  const [alimonyPartnerAmount, setAlimonyPartnerAmount] = useState(
    props.alimonyPartnerAmount ? props.alimonyPartnerAmount : '0',
  );
  const [childCareCosts, setChildCareCosts] = useState(props.childCareCosts ? props.childCareCosts : '0');

  const initialErrors: OutgoingDataError = {};
  const [errors, setErrors] = useState(initialErrors);

  useEffect(() => {
    setTaxesAmount(props.taxesAmount ? props.taxesAmount : '0');
    setFormationFees(props.formationFees ? props.formationFees : '0');
    setPublicTransportCosts(props.publicTransportCosts ? props.publicTransportCosts : '0');
    setLivingCosts(props.livingCosts ? props.livingCosts : '0');
    setAlimonyPartnerAmount(props.alimonyPartnerAmount ? props.alimonyPartnerAmount : '0');
    setChildCareCosts(props.childCareCosts ? props.childCareCosts : '0');
  }, [
    props.taxesAmount,
    props.formationFees,
    props.publicTransportCosts,
    props.livingCosts,
    props.alimonyPartnerAmount,
    props.childCareCosts,
  ]);

  const onChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    switch (e.target.name) {
      case 'taxesAmount':
        setTaxesAmount(e.target.value);
        setErrors({
          ...errors,
          taxesAmount: maxValueValidation(e.target.value, 9999999, props.errorLabels ? props.errorLabels : {}),
        });
        break;
      case 'formationFees':
        setFormationFees(e.target.value);
        setErrors({
          ...errors,
          formationFees: maxValueValidation(e.target.value, 9999999, props.errorLabels ? props.errorLabels : {}),
        });
        break;
      case 'publicTransportCosts':
        setPublicTransportCosts(e.target.value);
        setErrors({
          ...errors,
          publicTransportCosts: maxValueValidation(e.target.value, 9999999, props.errorLabels ? props.errorLabels : {}),
        });
        break;
      case 'livingCosts':
        setLivingCosts(e.target.value);
        setErrors({
          ...errors,
          livingCosts: maxValueValidation(e.target.value, 9999999, props.errorLabels ? props.errorLabels : {}),
        });
        break;
      case 'alimonyPartnerAmount':
        setAlimonyPartnerAmount(e.target.value);
        setErrors({
          ...errors,
          alimonyPartnerAmount: maxValueValidation(e.target.value, 9999999, props.errorLabels ? props.errorLabels : {}),
        });
        break;
      case 'childCareCosts':
        setChildCareCosts(e.target.value);
        setErrors({
          ...errors,
          childCareCosts: maxValueValidation(e.target.value, 9999999, props.errorLabels ? props.errorLabels : {}),
        });
        break;
    }
  };

  const getOutgoingData = (): OutgoingData => {
    return {
      taxesAmount: taxesAmount ? parseInt(taxesAmount) : undefined,
      formationFees: formationFees ? parseInt(formationFees) : undefined,
      publicTransportCosts: publicTransportCosts ? parseInt(publicTransportCosts) : undefined,
      livingCosts: livingCosts ? parseInt(livingCosts) : undefined,
      alimonyPartnerAmount: alimonyPartnerAmount ? parseInt(alimonyPartnerAmount) : undefined,
      childCareCosts: childCareCosts ? parseInt(childCareCosts) : undefined,
    };
  };

  const getFormErrors = (): OutgoingDataError => {
    const errors: OutgoingDataError = {};
    errors.taxesAmount = integerValidation(taxesAmount, props.errorLabels ? props.errorLabels : {});
    errors.formationFees = integerValidation(formationFees, props.errorLabels ? props.errorLabels : {});
    errors.publicTransportCosts = integerValidation(publicTransportCosts, props.errorLabels ? props.errorLabels : {});
    if (props.contactDataLivingType) {
      errors.livingCosts = integerValidation(livingCosts, props.errorLabels ? props.errorLabels : {});
    }
    if (props.personalDataCivilStatus) {
      errors.alimonyPartnerAmount = integerValidation(alimonyPartnerAmount, props.errorLabels ? props.errorLabels : {});
    }
    if (props.childDataHaveKids) {
      errors.childCareCosts = integerValidation(childCareCosts, props.errorLabels ? props.errorLabels : {});
    }
    return errors;
  };

  const validateForm = (): boolean => {
    const newErrors = getFormErrors();
    setErrors(newErrors);
    const errorsPresent = Object.values(newErrors).some((value) => value !== '');
    return !errorsPresent;
  };

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    if (validateForm() && props.dispatch) {
      props.dispatch({
        type: OutgoingDataAction.SUBMIT,
        payload: getOutgoingData(),
        demandId: props.demandId,
      });
    }
    event.preventDefault();
  };

  return (
    <>
      <form onSubmit={onSubmit}>
        <div className="form-element-container">
          <ZoomTextInput
            label={props.labelTaxesAmount ? props.labelTaxesAmount : ''}
            name="taxesAmount"
            value={taxesAmount}
            onChange={onChange}
            error={!!errors.taxesAmount}
            errorMessage={errors.taxesAmount}
            helpText={props.helpTextTaxesAmount ? props.helpTextTaxesAmount : ''}
          ></ZoomTextInput>
        </div>
        <div className="form-element-container">
          <ZoomTextInput
            label={props.labelFormationFees ? props.labelFormationFees : ''}
            name="formationFees"
            value={formationFees}
            onChange={onChange}
            error={!!errors.formationFees}
            errorMessage={errors.formationFees}
            helpText={props.helpTextFormationFees ? props.helpTextFormationFees : ''}
          ></ZoomTextInput>
        </div>
        <div className="form-element-container">
          <ZoomTextInput
            label={props.labelPublicTransportCosts ? props.labelPublicTransportCosts : ''}
            name="publicTransportCosts"
            value={publicTransportCosts}
            onChange={onChange}
            error={!!errors.publicTransportCosts}
            errorMessage={errors.publicTransportCosts}
            helpText={props.helpTextPublicTransportCosts ? props.helpTextPublicTransportCosts : ''}
          ></ZoomTextInput>
        </div>
        {props.contactDataLivingType && (
          <div className="form-element-container">
            <ZoomTextInput
              label={props.labelLivingCosts ? props.labelLivingCosts : ''}
              name="livingCosts"
              value={livingCosts}
              onChange={onChange}
              error={!!errors.livingCosts}
              errorMessage={errors.livingCosts}
              helpText={props.helpTextLivingCosts ? props.helpTextLivingCosts : ''}
            ></ZoomTextInput>
          </div>
        )}
        {props.personalDataCivilStatus && (
          <div className="form-element-container">
            <ZoomTextInput
              label={props.labelAlimonyPartnerAmount ? props.labelAlimonyPartnerAmount : ''}
              name="alimonyPartnerAmount"
              value={alimonyPartnerAmount}
              onChange={onChange}
              error={!!errors.alimonyPartnerAmount}
              errorMessage={errors.alimonyPartnerAmount}
            ></ZoomTextInput>
          </div>
        )}
        {props.childDataHaveKids && (
          <div className="form-element-container">
            <ZoomTextInput
              label={props.labelChildCareCosts ? props.labelChildCareCosts : ''}
              name="childCareCosts"
              value={childCareCosts}
              onChange={onChange}
              error={!!errors.childCareCosts}
              errorMessage={errors.childCareCosts}
            ></ZoomTextInput>
          </div>
        )}
        <div className="form-actions-container">
          <Button color="primary" type="submit" variant="contained" id="submit-outgoing-data">
            {props.labelSave}
          </Button>
        </div>
      </form>
    </>
  );
};

// Map global store to component parameters
// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export function mapStateToProps(store: WIAAStore, ownProps: any): OutgoingDataFormProps {
  const { demandId } = ownProps.match.params;
  const props: OutgoingDataFormProps = {
    labelTaxesAmount: i18nLabel(
      store.translationLabels,
      'Outgoing_Taxes_Amount',
      'Steuern für aktuelles Ausbildungsjahr (Bund / Kanton / Gemeinde) pro Jahr Fr.*',
    ),
    labelFormationFees: i18nLabel(
      store.translationLabels,
      'Outgoing_Formation_Fees',
      'Schulgeld, Studien- und Prüfungsgebühren  pro Jahr Fr.*',
    ),
    labelPublicTransportCosts: i18nLabel(
      store.translationLabels,
      'Outgoing_Public_Transport_Costs',
      'Fahrkosten (nur öffentliche Verkehrsmittel) pro Jahr Fr.*',
    ),
    labelLivingCosts: i18nLabel(
      store.translationLabels,
      'Outgoing_Living_Costs',
      'Wohnkosten(anteil) bei eigenem Haushalt oder Wochenaufenthalt pro Jahr Fr., sofern Voraussetzungen gemäss § 30 Abs. 3 StipV erfüllt sind.*',
    ),
    labelAlimonyPartnerAmount: i18nLabel(
      store.translationLabels,
      'Outgoing_Alimony_Partner_Amount',
      'Unterhaltsbeiträge an ehemalige(n) Partner/in pro Jahr Fr.*',
    ),
    labelChildCareCosts: i18nLabel(
      store.translationLabels,
      'Outgoing_Child_Care_Costs',
      'Betreuungskosten für eigene Kinder pro Jahr Fr.*',
    ),
    labelSave: i18nLabel(store.translationLabels, 'Outgoing_Save', 'Speichern'),
    helpTextTaxesAmount: i18nLabel(
      store.translationLabels,
      'Outgoing_Taxes_Amount_Help_Text',
      'Steuern Bewerber, nicht Eltern.Der Steuerbetrag betrifft das laufende Ausbildungsjahr. Der Betrag kann mittels Hilfsblatt (www.ag.ch/steuern) berechnet werden. Bitte legen Sie uns diese Berechnung bei.',
    ),
    helpTextFormationFees: i18nLabel(
      store.translationLabels,
      'Outgoing_Formation_Fees_Help_Text',
      'Übrige Auslagen wie Kurse, Lehrmittel, Verbrauchsmaterial und Sprachaufenthalt werden in Form eines in der Stipendienverordnung festgelegten Pauschalbetrags berücksichtigt und müssen daher nicht aufgeführt werden.',
    ),
    helpTextPublicTransportCosts: i18nLabel(
      store.translationLabels,
      'Outgoing_Public_Transport_Costs_Help_Text',
      "Es können nur die Kosten des Jahresstreckenabonnemets zwischen Ihrem Wohn- und Ausbildungsort und/oder der Berufsschule berücksichtigt werden. Falls das Streckenabonnement die Kosten eines Generalabo's übersteigen, wird das GA gewährt.",
    ),
    helpTextLivingCosts: i18nLabel(
      store.translationLabels,
      'Outgoing_Living_Costs_Help_Text',
      '§ 30 Abs. 3 StipV Bei gesuchstellenden Personen, die das 25. Altersjahr noch nicht vollendet haben, werden die Kosten eines eigenen Haushalts berücksichtigt, wenn sie mit einer Partnerin oder einem Partner oder mit mindestens einem eigenen Kind zusammen wohnen oder wenn ein wichtiger Grund vorliegt und sich der Haushalt in der Nähe des Ausbildungsorts befindet. Als wichtige Gründe gelten insbesondere a) eine Reisezeit mit öffentlichen Verkehrsmitteln zwischen der nächstgelegenen Haltestelle am elterlichen Wohnort und derjenigen am Ausbildungsort von über 90 Minuten pro Weg, b)  schwerwiegende familiäre Probleme, c)  gesundheitliche Umstände, die ein Pendeln zwischen elterlichem Wohnort und Ausbildungsort als unzumutbar erscheinen lassen.',
    ),
    contactDataLivingType: true,
    childDataHaveKids: true,
    personalDataCivilStatus: true,
    errorLabels: store.translationLabels,
    demandId: Number(demandId),
  };
  const data = store.outgoingData;
  if (data) {
    props.taxesAmount = data.taxesAmount ? data.taxesAmount.toString() : '';
    props.formationFees = data.formationFees ? data.formationFees.toString() : '';
    props.publicTransportCosts = data.publicTransportCosts ? data.publicTransportCosts.toString() : '';
    props.livingCosts = data.livingCosts ? data.livingCosts.toString() : '';
    props.alimonyPartnerAmount = data.alimonyPartnerAmount ? data.alimonyPartnerAmount.toString() : '';
    props.childCareCosts = data.childCareCosts ? data.childCareCosts.toString() : '';
    props.childDataHaveKids = !!store.personalData.existingChildren;
    props.personalDataCivilStatus =
      store.personalData.maritalStatus === 41 ||
      store.personalData.maritalStatus === 42 ||
      store.personalData.maritalStatus === 50;
    props.contactDataLivingType = store.personalData.livingType === 4 || store.personalData.livingType === 6;
  }
  return props;
}

// default export the connected component
export default withRouter(connect(mapStateToProps)(OutgoingDataForm));
