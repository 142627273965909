import { Button } from '@material-ui/core';
import React, { ReactElement, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ZoomTextInput from '../../components/forms/text-input/ZoomTextInput';
import { i18nLabel } from '../../store/i18n/helpers';
import { ParentsDataAction, ParentsDataActionType } from '../../store/parents-data/actions';
import { ParentsData } from '../../store/parents-data/types';
import { WIAAStore } from '../../store/types';
import { getDefaultSelectOption } from '../../utils/form-data';
import { minMaxValueValidation, requiredValidation } from '../../utils/validators';
import ZoomSelectInput from './../../components/forms/select-input/ZoomSelectInput';
import './ParentsDataForm.scss';
import ZoomToggleInput from '../../components/forms/toggle-input/ZoomToggleInput';
import { extractToggleValue } from '../../utils/object-manipulation';

interface ParentsDataFormErrors {
  familyRelation?: string;
  careSituation?: string;
  fatherMaritalStatus?: string;
  motherMaritalStatus?: string;
  socialCareReceived?: string;
  obhutSituation?: string;
  alimonyType?: string;
  alimonyAmount?: string;
  invalidCombination?: string;
  premiumReductionReceived?: string;
  premiumReductionAmount?: string;
}

const RELATION_MARRIED_TOGETHER = '20';
const RELATION_DIVORCED = '42';
const RELATION_CONCUBINAT = '21';
const RELATION_SEPARATED = '41';
const RELATION_PARENTS_DECEASED = '60';
const RELATION_ONE_PARENT_DECEASED = '30';
const RELATION_NOT_MARRIED = '10';

const PARENT_STATUS_DECEASED = '70';
const PARENT_STATUS_PLEASE_SELECT = '0';

const getMaritalStatesCombinationError = (
  familyRelation: string,
  fatherMaritalStatus: string,
  motherMaritalStatus: string,
  errorMessage: string,
): string => {
  if (familyRelation !== RELATION_ONE_PARENT_DECEASED) {
    return '';
  }

  if (fatherMaritalStatus === PARENT_STATUS_PLEASE_SELECT || motherMaritalStatus === PARENT_STATUS_PLEASE_SELECT) {
    return '';
  }

  return fatherMaritalStatus === PARENT_STATUS_DECEASED || motherMaritalStatus === PARENT_STATUS_DECEASED
    ? ''
    : errorMessage;
};

export interface ParentsDataFormProps {
  labelPremiumReductionReceived: string;
  labelPremiumReductionAmount: string;
  labelFamilyRelation: string;
  labelCareSituation: string;
  labelFatherMaritalStatus: string;
  labelMotherMaritalStatus: string;
  labelObhutSituation: string;
  labelAlimonyType: string;
  labelAlimonyAmount: string;
  labelSocialCareReceived: string;
  labelErrorParentStatusIsNotCorrect: string;
  labelSave: string;
  helpTextMotherFatherMaritalStatus: string;
  helpTextObhutSituation: string;
  obhutSituationOptions: { value: string; name: string }[];
  familyRelation?: string;
  careSituation?: string;
  fatherMaritalStatus?: string;
  motherMaritalStatus?: string;
  obhutSituation?: string;
  alimonyType?: string;
  alimonyAmount?: string;
  socialCareReceived?: string;
  premiumReductionReceived?: string;
  premiumReductionAmount?: string;
  pifId: number;
  alimonySituation?: { name: string; value: string }[];
  parentMaritalStatuses?: { name: string; value: string }[];
  typeOfRelationships?: { name: string; value: string }[];
  careSituationOptions?: { name: string; value: string }[];
  socialCareReceivedOptions?: { name: string; value: string }[];
  toggleButtonsTypes?: { name: string; value: string }[];
  errorLabels?: { [key: string]: string };
  dispatch?: (action: ParentsDataActionType) => void;
}

export const ParentsDataForm = (props: ParentsDataFormProps): ReactElement => {
  const [familyRelation, setFamilyRelation] = useState(
    props.familyRelation ? props.familyRelation : RELATION_MARRIED_TOGETHER,
  );
  const [careSituation, setCareSituation] = useState(props.careSituation ? props.careSituation : '9');
  const [fatherMaritalStatus, setFatherMaritalStatus] = useState(
    props.fatherMaritalStatus ? props.fatherMaritalStatus : RELATION_MARRIED_TOGETHER,
  );
  const [motherMaritalStatus, setMotherMaritalStatus] = useState(
    props.motherMaritalStatus ? props.motherMaritalStatus : RELATION_MARRIED_TOGETHER,
  );
  const [alimonyType, setAlimonyType] = useState(props.alimonyType ? props.alimonyType : '0');
  const [alimonyAmount, setAlimonyAmount] = useState(props.alimonyAmount ? props.alimonyAmount : '0');
  const [obhutSituation, setObhutSituation] = useState(props.obhutSituation ? props.obhutSituation : '0');
  const [socialCareReceived, setSocialCareReceived] = useState(
    props.socialCareReceived ? props.socialCareReceived : '0',
  );
  const [premiumReductionReceived, setPremiumReductionReceived] = useState(props.premiumReductionReceived ?? '');
  const [premiumReductionAmount, setPremiumReductionAmount] = useState(props.premiumReductionAmount ?? '0');

  const initialErrors: ParentsDataFormErrors = {};

  const [errors, setErrors] = useState(initialErrors);

  useEffect(() => {
    setFamilyRelation(props.familyRelation ? props.familyRelation : RELATION_MARRIED_TOGETHER);
    setCareSituation(props.careSituation ? props.careSituation : '9');
    setFatherMaritalStatus(props.fatherMaritalStatus ? props.fatherMaritalStatus : RELATION_MARRIED_TOGETHER);
    setMotherMaritalStatus(props.motherMaritalStatus ? props.motherMaritalStatus : RELATION_MARRIED_TOGETHER);
    setAlimonyType(props.alimonyType ? props.alimonyType : '0');
    setAlimonyAmount(props.alimonyAmount ? props.alimonyAmount : '0');
    setObhutSituation(props.obhutSituation ? props.obhutSituation : '0');
    setSocialCareReceived(props.socialCareReceived ? props.socialCareReceived : '0');
    setPremiumReductionReceived(props.premiumReductionReceived ?? '');
    setPremiumReductionAmount(props.premiumReductionAmount ?? '0');
  }, [props]);

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    if (validateForm() && props.dispatch) {
      props.dispatch({
        type: ParentsDataAction.SUBMIT,
        payload: getParentsData(),
        pifId: props.pifId,
      });
    }
    event.preventDefault();
  };

  const getParentsData = (): ParentsData => {
    const data = {
      typeOfRelationShip: familyRelation ? Number(familyRelation) : undefined,
      fatherMaritalStatus: isFatherStatusVisible() ? Number(fatherMaritalStatus) : undefined,
      motherMaritalStatus: isMotherStatusVisible() ? Number(motherMaritalStatus) : undefined,
      careSituation: isCareSituationVisible() ? Number(careSituation) : undefined,
      alimonySituation: isAlimonyTypeVisible() ? Number(alimonyType) : undefined,
      alimonyAmount: isAlimonyAmountVisible() ? Number(alimonyAmount) : undefined,
      socialCareReceived: isSocialCareReceivedVisible() ? Number(socialCareReceived) : undefined,
      relationCase: isObhutSituationVisible() ? Number(obhutSituation) : undefined,
      premiumReductionReceived: isPremiumReductionVisible() ? premiumReductionReceived === '1' : undefined,
      premiumReductionAmount: isPremiumReductionAmountVisible() ? Number(premiumReductionAmount) : undefined,
    };

    if (familyRelation === RELATION_PARENTS_DECEASED) {
      data.fatherMaritalStatus = Number(PARENT_STATUS_DECEASED);
      data.motherMaritalStatus = Number(PARENT_STATUS_DECEASED);
    }

    return data;
  };

  const validateForm = (): boolean => {
    const newErrors = getFormErrors();
    setErrors(newErrors);
    const errorsPresent = Object.values(newErrors).some((value) => value !== '');
    return !errorsPresent;
  };

  const getFormErrors = (): {
    motherMaritalStatus: string;
    careSituation: string;
    obhutSituation: string;
    invalidCombination: string;
    socialCareReceived: string;
    fatherMaritalStatus: string;
    alimonyAmount: string;
    alimonyType: string;
    premiumReductionReceived: string;
    premiumReductionAmount: string;
  } => {
    return {
      careSituation:
        isCareSituationVisible() && careSituation === '9'
          ? requiredValidation('', props.errorLabels ? props.errorLabels : {})
          : '',
      obhutSituation:
        isObhutSituationVisible() && obhutSituation === '0'
          ? requiredValidation('', props.errorLabels ? props.errorLabels : {})
          : '',
      alimonyAmount: isAlimonyAmountVisible()
        ? minMaxValueValidation(alimonyAmount, 10, 9999999, props.errorLabels ? props.errorLabels : {})
        : '',
      alimonyType:
        isAlimonyTypeVisible() && alimonyType === '0'
          ? requiredValidation('', props.errorLabels ? props.errorLabels : {})
          : '',
      socialCareReceived:
        isSocialCareReceivedVisible() && socialCareReceived === '0'
          ? requiredValidation('', props.errorLabels ? props.errorLabels : {})
          : '',
      fatherMaritalStatus:
        isFatherStatusVisible() && fatherMaritalStatus === '0'
          ? requiredValidation('', props.errorLabels ? props.errorLabels : {})
          : '',
      motherMaritalStatus:
        isMotherStatusVisible() && motherMaritalStatus === '0'
          ? requiredValidation('', props.errorLabels ? props.errorLabels : {})
          : '',
      invalidCombination: getMaritalStatesCombinationError(
        familyRelation,
        fatherMaritalStatus,
        motherMaritalStatus,
        props.labelErrorParentStatusIsNotCorrect,
      ),
      premiumReductionReceived: isPremiumReductionVisible()
        ? requiredValidation(premiumReductionReceived, props.errorLabels ? props.errorLabels : {})
        : '',
      premiumReductionAmount: isPremiumReductionAmountVisible()
        ? minMaxValueValidation(premiumReductionAmount, 10, 9999999, props.errorLabels ? props.errorLabels : {})
        : '',
    };
  };

  const onFamilyRelationChange = (newValue: string) => {
    setFamilyRelation(newValue);
    setErrors({ ...errors, invalidCombination: '' });
    switch (newValue) {
      case RELATION_MARRIED_TOGETHER:
        setMotherMaritalStatus(newValue);
        setFatherMaritalStatus(newValue);
        break;
      case RELATION_CONCUBINAT:
        setMotherMaritalStatus(newValue);
        setFatherMaritalStatus(newValue);
        break;
      case RELATION_PARENTS_DECEASED:
        setMotherMaritalStatus('70');
        setFatherMaritalStatus('70');
        break;
      default:
        setMotherMaritalStatus('0');
        setFatherMaritalStatus('0');
        break;
    }
    setObhutSituation('0');
    setCareSituation('9');
    setAlimonyType('0');
    setAlimonyAmount('0');
    setPremiumReductionReceived('');
    setPremiumReductionAmount('0');
  };

  const onCareSituationChange = (newValue: string) => {
    setCareSituation(newValue);
    setErrors({
      ...errors,
      careSituation: newValue === '9' ? requiredValidation('', props.errorLabels ? props.errorLabels : {}) : '',
    });
  };

  const onObhutSituationChange = (newValue: string) => {
    setObhutSituation(newValue);
    setErrors({
      ...errors,
      obhutSituation:
        newValue === '0' && isObhutSituationVisible()
          ? requiredValidation('', props.errorLabels ? props.errorLabels : {})
          : '',
    });
  };

  const onAlimonyTypeChange = (newValue: string) => {
    setAlimonyType(newValue);
    setErrors({
      ...errors,
      alimonyType:
        newValue === '0' && isAlimonyTypeVisible()
          ? requiredValidation('', props.errorLabels ? props.errorLabels : {})
          : '',
    });
  };

  const onAlimonyAmountChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const newValue = event.target.value;
    setAlimonyAmount(newValue);
    setErrors({
      ...errors,
      alimonyAmount: isAlimonyAmountVisible()
        ? minMaxValueValidation(newValue, 10, 9999999, props.errorLabels ? props.errorLabels : {})
        : '',
    });
  };

  const onPremiumReductionReceivedChange = (newValue: string) => {
    setPremiumReductionReceived(newValue);
    setErrors({
      ...errors,
      premiumReductionReceived: isPremiumReductionVisible()
        ? requiredValidation(newValue, props.errorLabels ?? {})
        : '',
    });
  };

  const onPremiumReductionAmountChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const newValue = event.target.value;
    setPremiumReductionAmount(newValue);
    setErrors({
      ...errors,
      premiumReductionAmount: isPremiumReductionAmountVisible()
        ? minMaxValueValidation(newValue, 10, 9999999, props.errorLabels ? props.errorLabels : {})
        : '',
    });
  };

  const onFatherMaritalStatusChange = (newValue: string) => {
    setFatherMaritalStatus(newValue);
    setErrors({
      ...errors,
      invalidCombination: getMaritalStatesCombinationError(
        familyRelation,
        newValue,
        motherMaritalStatus,
        props.labelErrorParentStatusIsNotCorrect,
      ),
      fatherMaritalStatus:
        newValue === '0' && isFatherStatusVisible()
          ? requiredValidation('', props.errorLabels ? props.errorLabels : {})
          : '',
    });
  };

  const onMotherMaritalStatusChange = (newValue: string) => {
    setMotherMaritalStatus(newValue);
    setErrors({
      ...errors,
      invalidCombination: getMaritalStatesCombinationError(
        familyRelation,
        fatherMaritalStatus,
        newValue,
        props.labelErrorParentStatusIsNotCorrect,
      ),
      motherMaritalStatus:
        newValue === '0' && isMotherStatusVisible()
          ? requiredValidation('', props.errorLabels ? props.errorLabels : {})
          : '',
    });
  };

  const onSocialCareReceivedChange = (newValue: string) => {
    setSocialCareReceived(newValue);
    setErrors({
      ...errors,
      socialCareReceived: newValue === '0' ? requiredValidation('', props.errorLabels ? props.errorLabels : {}) : '',
    });
  };

  const isCareSituationVisible = () => {
    return (
      familyRelation === RELATION_SEPARATED ||
      familyRelation === RELATION_DIVORCED ||
      familyRelation === RELATION_NOT_MARRIED
    );
  };

  const isMotherStatusVisible = () => {
    return familyRelation !== RELATION_PARENTS_DECEASED;
  };

  const isFatherStatusVisible = () => {
    return familyRelation !== RELATION_PARENTS_DECEASED;
  };

  const isPremiumReductionVisible = () => {
    return familyRelation === RELATION_MARRIED_TOGETHER;
  };

  const isPremiumReductionAmountVisible = () => {
    return isPremiumReductionVisible() && premiumReductionReceived === '1';
  };

  const isObhutSituationVisible = () => {
    return isCareSituationVisible() && (careSituation === '1' || careSituation === '2' || careSituation === '3');
  };

  const isAlimonyTypeVisible = () => {
    return isCareSituationVisible();
  };

  const isAlimonyAmountVisible = () => {
    return isAlimonyTypeVisible() && parseInt(alimonyType) > 1;
  };

  const isSocialCareReceivedVisible = () => {
    return familyRelation !== '60';
  };

  return (
    <form onSubmit={onSubmit}>
      <div className="form-element-container">
        <ZoomSelectInput
          name="familyRelation"
          onChange={onFamilyRelationChange}
          label={props.labelFamilyRelation}
          options={props.typeOfRelationships ? props.typeOfRelationships : []}
          value={familyRelation}
        ></ZoomSelectInput>
      </div>

      {isCareSituationVisible() && (
        <div className="form-element-container">
          <ZoomSelectInput
            name="careSituation"
            value={careSituation}
            label={props.labelCareSituation}
            options={props.careSituationOptions ? props.careSituationOptions : []}
            onChange={onCareSituationChange}
            error={!!errors.careSituation}
            errorMessage={errors.careSituation}
          ></ZoomSelectInput>
        </div>
      )}

      {isFatherStatusVisible() && (
        <div className="form-element-container">
          <ZoomSelectInput
            name="fatherMaritalStatus"
            onChange={onFatherMaritalStatusChange}
            label={props.labelFatherMaritalStatus}
            helpText={props.helpTextMotherFatherMaritalStatus}
            options={props.parentMaritalStatuses ? props.parentMaritalStatuses : []}
            value={fatherMaritalStatus}
            error={!!errors.fatherMaritalStatus}
            errorMessage={errors.fatherMaritalStatus}
          ></ZoomSelectInput>
        </div>
      )}

      {isMotherStatusVisible() && (
        <div className="form-element-container">
          <ZoomSelectInput
            name="motherMaritalStatus"
            label={props.labelMotherMaritalStatus}
            helpText={props.helpTextMotherFatherMaritalStatus}
            onChange={onMotherMaritalStatusChange}
            options={props.parentMaritalStatuses ? props.parentMaritalStatuses : []}
            value={motherMaritalStatus}
            error={!!errors.motherMaritalStatus}
            errorMessage={errors.motherMaritalStatus}
          ></ZoomSelectInput>
        </div>
      )}

      {isObhutSituationVisible() && (
        <div className="form-element-container">
          <ZoomSelectInput
            name="obhutSituation"
            label={props.labelObhutSituation}
            options={props.obhutSituationOptions}
            onChange={onObhutSituationChange}
            helpText={props.helpTextObhutSituation}
            value={obhutSituation}
            error={!!errors.obhutSituation}
            errorMessage={errors.obhutSituation}
          ></ZoomSelectInput>
        </div>
      )}

      {isAlimonyTypeVisible() && (
        <div className="form-element-container">
          <ZoomSelectInput
            name="alimonyType"
            value={alimonyType}
            label={props.labelAlimonyType}
            onChange={onAlimonyTypeChange}
            options={props.alimonySituation ? props.alimonySituation : []}
            error={!!errors.alimonyType}
            errorMessage={errors.alimonyType}
          ></ZoomSelectInput>
        </div>
      )}

      {isAlimonyAmountVisible() && (
        <div className="form-element-container">
          <ZoomTextInput
            name="alimonyAmount"
            label={props.labelAlimonyAmount}
            value={alimonyAmount}
            onChange={onAlimonyAmountChange}
            error={!!errors.alimonyAmount}
            errorMessage={errors.alimonyAmount}
          ></ZoomTextInput>
        </div>
      )}

      {isSocialCareReceivedVisible() && (
        <div className="form-element-container">
          <ZoomSelectInput
            name="socialCareReceived"
            value={socialCareReceived}
            label={props.labelSocialCareReceived}
            options={props.socialCareReceivedOptions ? props.socialCareReceivedOptions : []}
            onChange={onSocialCareReceivedChange}
            error={!!errors.socialCareReceived}
            errorMessage={errors.socialCareReceived}
          ></ZoomSelectInput>
        </div>
      )}

      {isPremiumReductionVisible() && (
        <div className="form-element-container">
          <ZoomToggleInput
            name="premiumReductionReceived"
            title={props.labelPremiumReductionReceived}
            value={props.premiumReductionReceived}
            label1={props.toggleButtonsTypes ? props.toggleButtonsTypes[0].name : ''}
            value1={props.toggleButtonsTypes ? props.toggleButtonsTypes[0].value.toString() : ''}
            label2={props.toggleButtonsTypes ? props.toggleButtonsTypes[1].name : ''}
            value2={props.toggleButtonsTypes ? props.toggleButtonsTypes[1].value.toString() : ''}
            onChange={onPremiumReductionReceivedChange}
          ></ZoomToggleInput>
        </div>
      )}

      {isPremiumReductionAmountVisible() && (
        <div className="form-element-container">
          <ZoomTextInput
            name="premiumReductionAmount"
            label={props.labelPremiumReductionAmount}
            onChange={onPremiumReductionAmountChange}
            value={premiumReductionAmount}
            error={!!errors.premiumReductionAmount}
            errorMessage={errors.premiumReductionAmount}
          ></ZoomTextInput>
        </div>
      )}

      {errors.invalidCombination === props.labelErrorParentStatusIsNotCorrect && (
        <p id="parents-status-error" className="Mui-error">
          {props.labelErrorParentStatusIsNotCorrect}
        </p>
      )}

      <div className="form-actions-container">
        <Button color="primary" type="submit" variant="contained" id="submit-parents-data">
          {props.labelSave}
        </Button>
      </div>
    </form>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export function mapStateToProps(store: WIAAStore, ownProps: any): ParentsDataFormProps {
  const { pifId } = ownProps.match.params;
  const props: ParentsDataFormProps = {
    labelPremiumReductionReceived: i18nLabel(
      store.translationLabels,
      'Parents_Premium_Reduction_Received',
      'Erhalten Ihre Eltern Prämienverbilligungen?*',
    ),
    labelPremiumReductionAmount: i18nLabel(
      store.translationLabels,
      'Parents_Premium_Reduction_Amount',
      'Betrag pro Jahr*',
    ),
    labelFamilyRelation: i18nLabel(
      store.translationLabels,
      'Parents_Family_Relation',
      'Wie ist das aktuelle zivilrechtliche Verhältnis Ihrer Eltern zueinander? *',
    ),
    labelCareSituation: i18nLabel(
      store.translationLabels,
      'Parents_Care_Situation',
      'Sorgerecht für die Person in Ausbildung *',
    ),
    labelFatherMaritalStatus: i18nLabel(store.translationLabels, 'Parents_Father_Martial_Status', 'Zivilstand Vater *'),
    labelMotherMaritalStatus: i18nLabel(
      store.translationLabels,
      'Parents_Mother_Martial_Status',
      'Zivilstand Mutter *',
    ),
    labelObhutSituation: i18nLabel(
      store.translationLabels,
      'Parents_Obhut_Situation',
      'Obhutsberechtigung für die Person in Ausbildung *',
    ),
    labelAlimonyType: i18nLabel(store.translationLabels, 'Parents_Alimony_Type', 'Wurden Alimenten geregelt? *'),
    labelAlimonyAmount: i18nLabel(store.translationLabels, 'Parents_Alimony_Amount', 'Betrag pro Jahr *'),
    labelSocialCareReceived: i18nLabel(
      store.translationLabels,
      'Parents_Social_Care_Received',
      'Beziehen die Eltern Sozialhilfe? *',
    ),
    labelSave: i18nLabel(store.translationLabels, 'Parents_Save', 'Speichern'),
    labelErrorParentStatusIsNotCorrect: i18nLabel(
      store.translationLabels,
      'Parents_Error_Invalid_State',
      'Zustand des Vaters oder Zustand der Mutter ungültig',
    ),
    helpTextObhutSituation: i18nLabel(
      store.translationLabels,
      'Parents_Obhut_Situation_Help_Text',
      'Bitte geben Sie an, wer vor der Volljährigkeit der Person in Ausbildung die elterliche Obhut hatte.',
    ),
    helpTextMotherFatherMaritalStatus: i18nLabel(
      store.translationLabels,
      'Parents_Mother_Father_Marital_Status_Help_Text',
      'Sind Ihre Eltern voneinander geschieden? Bitte erfassen Sie den Zivilstand wie folgt: beim geschiedenen Elternteil = Zivilstand geschieden beim verheirateten Elternteil = Zivilstand wiederverheiratet beim Elternteil in Konkubinat = Zivilstand geschieden',
    ),
    errorLabels: store.translationLabels,
    obhutSituationOptions: store.translationOptions['father-mother-care-situation']
      ? [
          { value: '0', name: getDefaultSelectOption(store.translationLabels) },
          ...store.translationOptions['father-mother-care-situation'].map((option) => {
            return { value: option.id.toString(), name: option.name };
          }),
        ]
      : [],
    pifId: Number(pifId),
    alimonySituation: store.translationOptions['alimony-situation']
      ? [
          { value: '0', name: getDefaultSelectOption(store.translationLabels) },
          ...store.translationOptions['alimony-situation'].map((option) => {
            return { value: option.id.toString(), name: option.name };
          }),
        ]
      : [],
    parentMaritalStatuses: store.translationOptions['parent-marital-statuses']
      ? [
          { value: '0', name: getDefaultSelectOption(store.translationLabels) },
          ...store.translationOptions['parent-marital-statuses'].map((option) => {
            return { value: option.id.toString(), name: option.name };
          }),
        ]
      : [],
    typeOfRelationships: store.translationOptions['type-of-relationships']
      ? [
          ...store.translationOptions['type-of-relationships'].map((option) => {
            return { value: option.id.toString(), name: option.name };
          }),
        ]
      : [],
    careSituationOptions: store.translationOptions['care-situation']
      ? [
          { value: '0', name: getDefaultSelectOption(store.translationLabels) },
          ...store.translationOptions['care-situation'].map((option) => {
            return { value: option.id.toString(), name: option.name };
          }),
        ]
      : [],
    socialCareReceivedOptions: store.translationOptions['social-care-received']
      ? [
          { value: '0', name: getDefaultSelectOption(store.translationLabels) },
          ...store.translationOptions['social-care-received'].map((option) => {
            return { value: option.id.toString(), name: option.name };
          }),
        ]
      : [],
    toggleButtonsTypes: store.translationOptions['toggle-buttons']
      ? [
          ...store.translationOptions['toggle-buttons'].map((option) => {
            return { value: option.id.toString(), name: option.name };
          }),
        ]
      : [],
  };

  const data = store.parentsData;
  if (data) {
    props.familyRelation = data.typeOfRelationShip ? data.typeOfRelationShip.toString() : '';
    props.careSituation = data.careSituation ? data.careSituation.toString() : '';
    props.fatherMaritalStatus = data.fatherMaritalStatus ? data.fatherMaritalStatus.toString() : '';
    props.motherMaritalStatus = data.motherMaritalStatus ? data.motherMaritalStatus.toString() : '';
    props.obhutSituation = data.relationCase ? data.relationCase.toString() : '';
    props.alimonyType = data.alimonySituation ? data.alimonySituation.toString() : '';
    props.alimonyAmount = data.alimonyAmount ? data.alimonyAmount.toString() : '';
    props.socialCareReceived = data.socialCareReceived ? data.socialCareReceived.toString() : '';
    props.premiumReductionReceived = extractToggleValue(data.premiumReductionReceived);
    props.premiumReductionAmount = data.premiumReductionAmount ? data.premiumReductionAmount.toString() : '';
  }

  return props;
}

export default withRouter(connect(mapStateToProps)(ParentsDataForm));
