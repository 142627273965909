import React, { ReactElement } from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import { black } from './../../theme';

export function ZoomGroupIcon(props: SvgIconProps): ReactElement {
  const color = props.htmlColor ? props.htmlColor : black.main;
  return (
    <SvgIcon {...props}>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <path d="M0 0H80V80H0z" transform="translate(-256 -257) translate(256 257)" />
            <path
              fill={color}
              d="M16 24.475L24.475 24.475 24.475 16 16 16zM16 44.251L24.475 44.251 24.475 35.775 16 35.775zM16 64.026L24.475 64.026 24.475 55.551 16 55.551zM35.775 24.475L44.25 24.475 44.25 16 35.775 16zM35.775 44.251L44.25 44.251 44.25 35.775 35.775 35.775zM35.775 64.026L44.25 64.026 44.25 55.551 35.775 55.551zM55.551 24.475L64.026 24.475 64.026 16 55.551 16zM55.551 44.251L64.026 44.251 64.026 35.775 55.551 35.775zM55.551 64.026L64.026 64.026 64.026 55.551 55.551 55.551z"
              transform="translate(-256 -257) translate(256 257)"
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}
