import { Action } from 'redux';
import { Epic, ofType } from 'redux-observable';
import {
  NewPermissionAddItemAction,
  NewPermissionDataAction,
  NewPermissionSearchUsersGetAction,
  NewPermissionSearchUsersGetResultAction,
} from './actions';
import { catchError, debounceTime, filter, map, mergeMap } from 'rxjs/operators';
import { customAjax } from '../../../utils/ajax-wrapper';
import { AjaxResponse } from 'rxjs/ajax';
import { Observable, of } from 'rxjs';
import { handleRequestFailureInEpic } from '../../../utils/error-handling';
import { RoutingAction, RoutingActionType } from '../../routing/actions';

const loadUsersData = (payload: AjaxResponse): NewPermissionSearchUsersGetResultAction => {
  return {
    type: NewPermissionDataAction.SEARCH_USERS_GET_RESULT,
    payload: payload.response,
  };
};

export const getNewPermissionUsersDataEpic: Epic<Action, Action> = (action$: Observable<Action>): Observable<Action> =>
  action$.pipe(
    ofType(NewPermissionDataAction.SEARCH_USERS_GET),
    debounceTime(300),
    filter((action) => {
      const queryString = (action as NewPermissionSearchUsersGetAction).searchWord;
      return queryString.length > 0;
    }),
    mergeMap((action) => {
      const searchAction = action as NewPermissionSearchUsersGetAction;
      return customAjax({
        subRoute: `/partner/v1/users/dashboard-index?queryString=${searchAction.searchWord}&excludeUser=${searchAction.currentUserId}`,
        withCredentials: true,
        method: 'GET',
      }).pipe(
        map((response) => loadUsersData(response)),
        catchError((error) => {
          return of(handleRequestFailureInEpic(error));
        }),
      );
    }),
  );

const permissionDataAdded = (payload: AjaxResponse, userId: number): RoutingActionType => {
  return {
    type: RoutingAction.REDIRECT_TO,
    payload: `/admin-portal/user/${userId}/details`,
  };
};

export const addPermissionDataEpic: Epic<Action, Action> = (action$: Observable<Action>): Observable<Action> =>
  action$.pipe(
    ofType(NewPermissionDataAction.ADD_ITEM),
    mergeMap((action) => {
      const newPermission = (action as NewPermissionAddItemAction).payload;
      return customAjax({
        subRoute: `/partner/v1/permissions/dashboard-create`,
        withCredentials: true,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(newPermission),
      }).pipe(
        map((response) => permissionDataAdded(response, newPermission.userId)),
        catchError((error) => {
          return of(handleRequestFailureInEpic(error));
        }),
      );
    }),
  );
