import { Button, Tooltip, withStyles } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import React, { ReactElement, useEffect, useState } from 'react';
import { i18nLabel } from '../../../store/i18n/helpers';
import { WIAAStore } from '../../../store/types';
import { white } from '../../../theme';
import { ZoomArrowRightIcon } from '../../icons/ZoomArrowRightIcon';
import { ZoomEmailIcon } from '../../icons/ZoomEmailIcon';
import { ZoomKantonAargauLogoIcon } from '../../icons/ZoomKantonAargauLogoIcon';
import { ZoomNotificationIcon } from '../../icons/ZoomNotificationIcon';
import { ZoomUserIcon } from '../../icons/ZoomUserIcon';
import { black, blue } from './../../../theme';
import { Notification } from './../../../store/notifications/types';
import './ZoomHeader.scss';
import { UserDataAction, UserDataGetAction } from '../../../store/user-data/actions';
import { NotificationAction, NotificationGetMessagesAction } from '../../../store/notifications/actions';
import { Action } from 'redux';
import { MyDemandPageAction, MyDemandPageGetAction } from '../../../store/my-demand/actions';

export interface ZoomHeaderProps {
  appName: string;
  userName: string;
  ahvn: string;
  labelMyData: string;
  labelMyEducation: string;
  labelMyScholarship: string;
  labelPayout: string;
  labelRefund: string;
  labelPowerOfAttorney: string;
  labelScholarshipHolder: string;
  labelLogout: string;
  labelAllNotifications?: string;
  labelNotifications?: string;
  labelSettings?: string;
  hideUserMenu?: boolean;
  dossierId?: number;
  notifications?: Array<Notification>;
  unreadNotifications?: boolean;
  unreadMessage?: boolean;
  myDataDisabled?: boolean;
  dispatch?: (action: Action) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      fontSize: '5rem',
    },
    appBar: {
      backgroundColor: white.main,
    },
    grow: {
      flexGrow: 1,
    },
    rootList: {
      width: '100%',
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
    dropdown: {
      width: '100%',
      color: white.main,
      backgroundColor: blue.main,
    },
    rootDivider: {
      backgroundColor: white.main,
      marginLeft: '16px',
      marginRight: '16px',
    },
  }),
);

const notificationHtml = (notification: Notification): ReactElement => {
  return (
    <React.Fragment key={notification.id}>
      <li>
        <p className="notification-title">
          {notification.isNew && <span className="new-notification-indicator"></span>}
          {notification.title}
        </p>
        <p className="notification-date">{moment(notification.sendDate).format('DD.MM.YYYY HH:mm')}</p>
      </li>
    </React.Fragment>
  );
};

const ZoomHeader = (props: ZoomHeaderProps): ReactElement => {
  const classes = useStyles();
  const [logoIconColor, setLogoIconColor] = useState(black.main);
  const [notificationIconColor, setNotificationIconColor] = useState(black.main);
  const [mailIconColor, setMailIconColor] = useState(black.main);
  const [profileIconColor, setProfileIconColor] = useState(black.main);
  const [open, setOpen] = React.useState(false);

  const userMenuStyle = props.hideUserMenu ? 'none' : 'flex';

  const [isNotificationsOpen, setIsNotificationsOpen] = useState(false);
  const notifications = props.notifications
    ? props.notifications.map((notification) => notificationHtml(notification))
    : [];

  useEffect(() => {
    if (props.dispatch && props.dossierId) {
      const getUserData: UserDataGetAction = { type: UserDataAction.GET };
      props.dispatch(getUserData);
    }
  }, []);

  useEffect(() => {
    if (props.dispatch && props.dossierId) {
      const getCases: NotificationGetMessagesAction = {
        type: NotificationAction.GET_MESSAGES,
        dossierId: props.dossierId,
      };
      props.dispatch(getCases);
    }
  }, []);

  useEffect(() => {
    if (props.dispatch && props.dossierId) {
      const getMyDemands: MyDemandPageGetAction = {
        type: MyDemandPageAction.GET,
        payload: props.dossierId,
      };
      props.dispatch(getMyDemands);
    }
  }, []);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(!open);
  };

  const changeLogoHoverColor = () => {
    setLogoIconColor(blue.main);
  };

  const changeLogoDefaultColor = () => {
    setLogoIconColor(black.main);
  };

  const changeNotificationHoverColor = () => {
    setNotificationIconColor(blue.main);
  };

  const changeNotificationDefaultColor = () => {
    setNotificationIconColor(black.main);
  };
  const changeMailHoverColor = () => {
    setMailIconColor(blue.main);
  };

  const changeMailDefaultColor = () => {
    setMailIconColor(black.main);
  };
  const changeProfileHoverColor = () => {
    setProfileIconColor(blue.main);
  };

  const changeProfileDefaultColor = () => {
    setProfileIconColor(black.main);
  };

  const handleLogoutAction = () => {
    window.location.href = '/logout';
  };

  return (
    <>
      <div className="header-container">
        <AppBar position="relative" className={classes.appBar} elevation={0}>
          <Toolbar className="toolbar">
            <Typography className="logo-wrapper">
              <a aria-label="Kanton Aargau Logo" className="header__logo" href="/">
                <ZoomKantonAargauLogoIcon
                  className="header__logo__svg"
                  viewBox="0 0 141.8 72"
                  fontSize="large"
                  onMouseEnter={changeLogoHoverColor}
                  onMouseLeave={changeLogoDefaultColor}
                  htmlColor={logoIconColor}
                ></ZoomKantonAargauLogoIcon>
              </a>
            </Typography>

            <IconButton disableFocusRipple disableTouchRipple disableRipple style={{ backgroundColor: 'transparent' }}>
              <a href="/" className="header__department">
                {props.appName}
              </a>
            </IconButton>

            <div style={{ display: userMenuStyle, flexGrow: 4, justifyContent: 'flex-end' }}>
              {props.myDataDisabled !== true && (
                <>
                  <IconButton
                    disableFocusRipple
                    disableTouchRipple
                    disableRipple
                    id="notifications-icon"
                    style={{ backgroundColor: 'transparent', padding: 6 }}
                    onClick={() => setIsNotificationsOpen(!isNotificationsOpen)}
                  >
                    <ZoomNotificationIcon
                      viewBox="0 0 80 80"
                      fontSize="large"
                      onMouseEnter={changeNotificationHoverColor}
                      onMouseLeave={changeNotificationDefaultColor}
                      htmlColor={notificationIconColor}
                    ></ZoomNotificationIcon>
                    {props.unreadNotifications && <span className="badge"></span>}
                  </IconButton>
                  <IconButton
                    disableFocusRipple
                    disableTouchRipple
                    disableRipple
                    id="messages-icon"
                    disabled={props.myDataDisabled}
                    style={{ backgroundColor: 'transparent', padding: 6 }}
                    href={'/messages/' + props.dossierId}
                  >
                    <ZoomEmailIcon
                      viewBox="0 0 80 80"
                      fontSize="large"
                      onMouseEnter={changeMailHoverColor}
                      onMouseLeave={changeMailDefaultColor}
                      htmlColor={mailIconColor}
                    ></ZoomEmailIcon>
                    {props.unreadMessage && <span className="badge"></span>}
                  </IconButton>
                </>
              )}

              <HeaderTooltip
                style={{ width: '384px !important' }}
                className="header-tooltip"
                onClose={handleTooltipClose}
                open={open}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                placement="bottom"
                interactive
                arrow={true}
                title={
                  <List component="nav" className={classes.rootList}>
                    <ListItem button component="a" href={'/settings/' + props.dossierId}>
                      <ListItemText primary={props.labelSettings} />
                    </ListItem>
                    <Divider light />
                    <ListItem button id="logout-button">
                      <ListItemText primary={props.labelLogout} onClick={handleLogoutAction} />
                    </ListItem>
                  </List>
                }
              >
                <IconButton
                  className="profile-icon-desktop"
                  disableFocusRipple
                  disableTouchRipple
                  disableRipple
                  edge="end"
                  id="main-menu-button"
                  style={{ backgroundColor: 'transparent' }}
                  onClick={handleTooltipOpen}
                >
                  <span
                    className="profile-name"
                    onMouseEnter={changeProfileHoverColor}
                    onMouseLeave={changeProfileDefaultColor}
                    style={{
                      color: profileIconColor,
                      fontSize: '15px',
                      fontWeight: 'bold',
                      fontStretch: 'normal',
                      fontStyle: 'normal',
                      lineHeight: 1.4,
                    }}
                  >
                    {props.userName}
                  </span>
                  <ZoomUserIcon
                    viewBox="0 0 80 80"
                    fontSize="large"
                    onMouseEnter={changeProfileHoverColor}
                    onMouseLeave={changeProfileDefaultColor}
                    htmlColor={profileIconColor}
                  ></ZoomUserIcon>
                </IconButton>
              </HeaderTooltip>
              <IconButton
                className="profile-icon-mobile"
                disableFocusRipple
                disableTouchRipple
                disableRipple
                edge="end"
                style={{ backgroundColor: 'transparent' }}
                onClick={handleTooltipOpen}
              >
                <ZoomUserIcon
                  viewBox="0 0 80 80"
                  fontSize="large"
                  onMouseEnter={changeProfileHoverColor}
                  onMouseLeave={changeProfileDefaultColor}
                  htmlColor={profileIconColor}
                ></ZoomUserIcon>
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>
        {open && (
          <div className="dropdown">
            <List className={classes.dropdown} aria-label="mailbox folders">
              <ListItem>
                <h1>{props.userName}</h1>
              </ListItem>
              <ListItem>
                <ListItemText primary={props.ahvn} style={{ marginTop: '-50px' }} />
              </ListItem>
              <Divider className={classes.rootDivider} />
              <ListItem button component="a" href={'/my-data/dossier-intranet/mydata/' + props.dossierId}>
                <ListItemText primary={props.labelMyData} />
                <ZoomArrowRightIcon viewBox="0 0 80 80" fontSize="small" htmlColor={white.main}></ZoomArrowRightIcon>
              </ListItem>
              <Divider className={classes.rootDivider} />
              <ListItem button component="a" href={'/dossier-intranet/myformation/' + props.dossierId}>
                <ListItemText primary={props.labelMyEducation} />
                <ZoomArrowRightIcon viewBox="0 0 80 80" fontSize="small" htmlColor={white.main}></ZoomArrowRightIcon>
              </ListItem>
              <Divider className={classes.rootDivider} />
              <ListItem button component="a" href={'/my-data/dossier-intranet/mydemand/' + props.dossierId}>
                <ListItemText primary={props.labelPayout} />
                <ZoomArrowRightIcon viewBox="0 0 80 80" fontSize="small" htmlColor={white.main}></ZoomArrowRightIcon>
              </ListItem>
              <Divider className={classes.rootDivider} />
              <ListItem button component="a" href={'/my-power-of-attorney-overview/' + props.dossierId}>
                <ListItemText primary={props.labelPowerOfAttorney} />
                <ZoomArrowRightIcon viewBox="0 0 80 80" fontSize="small" htmlColor={white.main}></ZoomArrowRightIcon>
              </ListItem>
              <Divider className={classes.rootDivider} />
              <ListItem button component="a" href={'/holder/' + props.dossierId}>
                <ListItemText primary={props.labelScholarshipHolder} />
                <ZoomArrowRightIcon viewBox="0 0 80 80" fontSize="small" htmlColor={white.main}></ZoomArrowRightIcon>
              </ListItem>
              <ListItem id="dropdown-logout-button">
                <Button
                  id="logout-button"
                  variant="outlined"
                  size="large"
                  fullWidth
                  color="secondary"
                  onClick={handleLogoutAction}
                  style={{ marginTop: 60, color: blue.main, backgroundColor: white.main, marginRight: '4px' }}
                >
                  {props.labelLogout}
                </Button>
              </ListItem>
            </List>
          </div>
        )}
      </div>
      {isNotificationsOpen && (
        <div className="notifications-tooltip-wrapper">
          <div id="notifications-tooltip">
            <div className="notifications-content">
              <h3 className="notifications-tooltip-title">
                {props.labelNotifications ? props.labelNotifications : 'Benachrichtigungen'}
              </h3>
              <ul className="notifications-list">{notifications}</ul>
            </div>
            <p className="notifications-link-line">
              <a className="notifications-link" href={'/notifications/' + props.dossierId}>
                {props.labelAllNotifications}
                <ZoomArrowRightIcon viewBox="0 0 80 80" htmlColor={white.main}></ZoomArrowRightIcon>
              </a>
            </p>
          </div>
        </div>
      )}
    </>
  );
};

const HeaderTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: white.main,
    color: blue.main,
    width: 384,
    border: '1px solid rgba(0, 0, 0, 0.12)',
    marginTop: '10px',
  },
  arrow: {
    marginTop: 20,
    fontSize: 20,
    color: blue.main,
    '&::before': {
      border: '1px solid rgba(0, 0, 0, 0.12)',
      backgroundColor: white.main,
    },
  },
}))(Tooltip);

export default ZoomHeader;

export function mapStoreToHeaderProps(store: WIAAStore, dossierId?: number): ZoomHeaderProps {
  return {
    appName: i18nLabel(store.translationLabels, 'Header_Application_Name', 'Stipendienportal'),
    userName: store.headerData.userName,
    ahvn: store.headerData.ahvn,
    labelMyData: i18nLabel(store.translationLabels, 'Header_My_Data', 'Meine Daten'),
    labelMyEducation: i18nLabel(store.translationLabels, 'Header_My_Education', 'Meine Ausbildungen'),
    labelMyScholarship: i18nLabel(store.translationLabels, 'Header_My_Scholarship', 'Meine Stipendienanträge'),
    labelPayout: i18nLabel(store.translationLabels, 'Header_Payout', 'Auszahlungen'),
    labelRefund: i18nLabel(store.translationLabels, 'Header_Refund', 'Rückzahlungen'),
    labelPowerOfAttorney: i18nLabel(store.translationLabels, 'Header_Power_Of_Attorney', 'Vollmachten'),
    labelScholarshipHolder: i18nLabel(store.translationLabels, 'Header_Scholarship_Holder', 'Stipendiat wählen'),
    labelLogout: i18nLabel(store.translationLabels, 'Header_Logout', 'Abmelden'),
    labelAllNotifications: i18nLabel(store.translationLabels, 'Header_All_Notifications', 'Alle Benachrichtigungen'),
    labelNotifications: i18nLabel(store.translationLabels, 'Header_Notifications_Title', 'Benachrichtigungen'),
    labelSettings: i18nLabel(store.translationLabels, 'Header_Settings', 'Einstellungen'),
    dossierId: dossierId,
    hideUserMenu: dossierId ? false : true,
    notifications: store.notificationData.notifications.slice(0, 4),
    unreadNotifications: store.notificationData.notifications.some((notification) => notification.isNew),
    unreadMessage: store.notificationData.freeTextMessages.some((message) =>
      message.messages.some((messageInMessage) => !messageInMessage.read),
    ),
    myDataDisabled: store.myDemandsData.myDemands.length === 0,
  };
}
