import { Grid, InputAdornment, TextField } from '@material-ui/core';
import React, { ChangeEvent, ReactElement, useEffect, useState } from 'react';
import { ZoomInfoIcon } from './../../icons/ZoomInfoIcon';
import { ZoomTextInputHelpTooltip } from './../../tooltips/ZoomTextInputHelpTooltip';
import './ZoomTextInput.scss';

export interface ZoomTextInputProps {
  label: string;
  error?: boolean;
  errorMessage?: string;
  value?: string;
  name?: string;
  isIBAN?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
  multiline?: boolean;
  helpText?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

const ZoomTextInput = (props: ZoomTextInputProps): ReactElement => {
  const [error, setError] = useState(props.error ? true : false);
  const [value, setValue] = useState(props.value ? props.value : '');
  const [errorMessage, setErrorMessage] = useState(props.errorMessage ? props.errorMessage : '');

  useEffect(() => {
    setError(props.error ? true : false);
    setValue(props.value ? props.value : '');
    setErrorMessage(props.errorMessage ? props.errorMessage : '');
  }, [props.value, props.name, props.error, props.errorMessage]);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    setValue(e.target.value);
    if (props.onChange) {
      props.onChange(e);
    }
  };

  return (
    <Grid container direction="row" alignItems="stretch" justify="flex-start">
      <Grid item xs={12} sm={6}>
        <div className="label">
          <label>{props.label}</label>
          {props.helpText && (
            <ZoomTextInputHelpTooltip placement="bottom" arrow={true} title={<p>{props.helpText}</p>}>
              <span style={{ width: '24px', height: '24px' }}>
                <ZoomInfoIcon color="primary" viewBox="0 0 80 80"></ZoomInfoIcon>
              </span>
            </ZoomTextInputHelpTooltip>
          )}
        </div>
      </Grid>
      <Grid item xs={12} sm={6}>
        <div>
          <TextField
            fullWidth
            data-testid="textfield-input"
            variant="outlined"
            size="small"
            error={error}
            value={value}
            multiline={props.multiline}
            name={props.name}
            disabled={props.disabled}
            onChange={handleInputChange}
            helperText={errorMessage ? errorMessage : null}
            InputProps={
              props.isIBAN
                ? {
                    startAdornment: <InputAdornment position="start">CH</InputAdornment>,
                  }
                : { readOnly: props.readOnly }
            }
          ></TextField>
        </div>
      </Grid>
    </Grid>
  );
};

export default ZoomTextInput;
