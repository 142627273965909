import { connect } from 'react-redux';
import { Homepage, HomepageProps } from '../homepage/Homepage';
import { i18nLabel } from '../../store/i18n/helpers';
import { WIAAStore } from '../../store/types';
import { mapStoreToHeaderProps, ZoomHeaderProps } from '../../components/base-page-components/header/ZoomHeader';
import { mapStoreToFooterProps, ZoomFooterProps } from '../../components/base-page-components/footer/ZoomFooter';

// Map global store to component parameters
export function mapStateToHomepageProps(store: WIAAStore): HomepageProps {
  const headerProps: ZoomHeaderProps = mapStoreToHeaderProps(store);
  const footerProps: ZoomFooterProps = mapStoreToFooterProps(store);

  return {
    headerProps: headerProps,
    footerProps: footerProps,
    title: i18nLabel(store.translationLabels, 'Homepage_Title', 'Homepage Example'),
    imageTextTitle: i18nLabel(store.translationLabels, 'Homepage_Image_Text_Title', 'Stipendienportal Kanton Aargau'),
    imageTextDescription: i18nLabel(
      store.translationLabels,
      'Homepage_Image_Text_Description',
      'Willkommen auf dem Stipendienportal des Kantons Aargau. Das Stipendienportal ist das zentrale Kommunikationsinstrument zwischen den Gesuchstellenden und der Sektion Stipendien des Departements BKS des Kantons Aargau. Das Portal enthält Informationen zu Ihren Anträgen und unterstützt Sie rund um das Thema Ausbildungsbeiträge.',
    ),
    loginTitle: i18nLabel(store.translationLabels, 'Homepage_Login_Title', 'Anmelden'),
    loginFirstParagraph: i18nLabel(
      store.translationLabels,
      'Homepage_Login_First_Paragraph',
      'Möchten Sie Ausbildungsbeiträge beantragen oder den Status Ihres Gesuchs einsehen?',
    ),
    loginSecondParagraph: i18nLabel(
      store.translationLabels,
      'Homepage_Login_Second_Paragraph',
      'Um auf das Stipendienportal zugreifen zu können, melden Sie sich bitte mit dem untenstehenden Button mit Ihrem "Mein Konto"-Login an. Sie werden danach auf das Stipendienportal zurückgeführt.',
    ),
    loginButton: i18nLabel(store.translationLabels, 'Homepage_Login_Button', 'Anmelden'),
    registerTitle: i18nLabel(store.translationLabels, 'Homepage_Register_Title', 'Registrieren'),
    registerFirstParagraph: i18nLabel(
      store.translationLabels,
      'Homepage_Register_First_Paragraph',
      'Sie sind noch nicht auf "Mein Konto" des Kantons Aargau registriert?',
    ),
    registerSecondParagraph: i18nLabel(
      store.translationLabels,
      'Homepage_Register_Second_Paragraph',
      'Um auf das Stipendienportal zugreifen zu können, müssen Sie sich mit untenstehendem Button zuerst ein "Mein Konto"-Login erstellen.',
    ),
    registerButton: i18nLabel(store.translationLabels, 'Homepage_Register_Button', 'Registrieren'),
  };
}

// Default export the connected component
export default connect(mapStateToHomepageProps)(Homepage);
