import { IdentificationData } from './types';

export enum IdentificationDataAction {
  SUBMIT = 'IDENTIFICATION_DATA_SUBMIT',
  IDENTIFICATION_SUCCESS = 'IDENTIFICATION_SUCCESS',
  ERROR = 'IDENTIFICATION_ERROR',
  RESET_ERROR = 'IDENTIFICATION_RESET_ERROR',
}

export interface IdentificationDataSubmitAction {
  type: typeof IdentificationDataAction.SUBMIT;
  payload: IdentificationData;
}

export interface IdentificationSuccessAction {
  type: typeof IdentificationDataAction.IDENTIFICATION_SUCCESS;
  dossierId: number;
  isUnder18: boolean;
}

export interface IdentificationErrorAction {
  type: typeof IdentificationDataAction.ERROR;
  payload: string;
}

export interface IdentificationResetErrorAction {
  type: typeof IdentificationDataAction.RESET_ERROR;
}

export type IdentificationDataActionType =
  | IdentificationDataSubmitAction
  | IdentificationSuccessAction
  | IdentificationErrorAction
  | IdentificationResetErrorAction;
