import { Action } from 'redux';
import { Observable, of } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { Epic, ofType } from 'redux-observable';
import { catchError, map, mergeMap } from 'rxjs/operators';

import {
  CVDataAction,
  CVDataGetCVResultAction,
  CVDataAddItemAction,
  CVDataDeleteItemAction,
  CVDataDeleteResultAction,
  CVDataAddResultAction,
  CVDataEditResultAction,
  CVDataGetCVAction,
  CVDataEditItemAction,
} from './actions';
import { handleRequestFailureInEpic } from '../../utils/error-handling';
import { customAjax } from '../../utils/ajax-wrapper';

const loadCV = (payload: AjaxResponse): CVDataGetCVResultAction => {
  return {
    type: CVDataAction.GET_RESULT,
    payload: payload.response,
  };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const loadCVOnError = (error: any): any => {
  if (error.status == 404) {
    return {
      type: CVDataAction.EMPTY_CV,
    };
  }

  return handleRequestFailureInEpic(error);
};

export const getCVDataEpic: Epic<Action, Action> = (action$: Observable<Action>): Observable<Action> =>
  action$.pipe(
    ofType(CVDataAction.GET),
    mergeMap((action) => {
      const demandId = (action as CVDataGetCVAction).demandId;

      return customAjax({
        subRoute: `/dossier/v1/demands/${demandId}/cv-elements`,
        withCredentials: true,
        method: 'GET',
      }).pipe(
        map((response) => loadCV(response)),
        catchError((error) => {
          return of(loadCVOnError(error));
        }),
      );
    }),
  );

const cvItemAdded = (payload: AjaxResponse): CVDataAddResultAction => {
  return {
    type: CVDataAction.ADD_RESULT,
    payload: { ...payload.response },
  };
};

export const addCVItemDataEpic: Epic<Action, Action> = (action$: Observable<Action>): Observable<Action> =>
  action$.pipe(
    ofType(CVDataAction.ADD_ITEM),
    mergeMap((action) => {
      const item = (action as CVDataAddItemAction).payload;
      const demandId = (action as CVDataAddItemAction).demandId;

      return customAjax({
        subRoute: `/dossier/v1/demands/${demandId}/cv-elements`,
        withCredentials: true,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(item),
      }).pipe(
        map((response) => cvItemAdded(response)),
        catchError((error) => {
          return of(handleRequestFailureInEpic(error));
        }),
      );
    }),
  );

const cvItemEdited = (payload: AjaxResponse): CVDataEditResultAction => {
  return {
    type: CVDataAction.EDIT_RESULT,
    payload: { ...payload.response },
  };
};

export const editCVItemDataEpic: Epic<Action, Action> = (action$: Observable<Action>): Observable<Action> =>
  action$.pipe(
    ofType(CVDataAction.EDIT_ITEM),
    mergeMap((action) => {
      const item = (action as CVDataEditItemAction).payload;
      const demandId = (action as CVDataEditItemAction).demandId;

      return customAjax({
        subRoute: `/dossier/v1/demands/${demandId}/cv-elements/${item.id}`,
        withCredentials: true,
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(item),
      }).pipe(
        map((response) => cvItemEdited(response)),
        catchError((error) => {
          return of(handleRequestFailureInEpic(error));
        }),
      );
    }),
  );

const cvItemDeleted = (payload: number): CVDataDeleteResultAction => {
  return {
    type: CVDataAction.DELETE_RESULT,
    payload,
  };
};

export const deleteCVItemDataEpic: Epic<Action, Action> = (action$: Observable<Action>): Observable<Action> =>
  action$.pipe(
    ofType(CVDataAction.DELETE_ITEM),
    mergeMap((action) => {
      const itemId = (action as CVDataDeleteItemAction).payload;
      const demandId = (action as CVDataDeleteItemAction).demandId;

      return customAjax({
        subRoute: `/dossier/v1/demands/${demandId}/cv-elements/${itemId}`,
        withCredentials: true,
        method: 'DELETE',
      }).pipe(
        map(() => cvItemDeleted(itemId)),
        catchError((error) => {
          return of(handleRequestFailureInEpic(error));
        }),
      );
    }),
  );
