import { Action } from 'redux';
import { Epic, ofType } from 'redux-observable';
import { Observable, of, Subscriber } from 'rxjs';
import { AjaxError, AjaxResponse } from 'rxjs/ajax';
import { catchError, debounceTime, map, mergeMap, switchMap } from 'rxjs/operators';
import { customAjax } from '../../utils/ajax-wrapper';
import { handleRequestFailureInEpic, redirectToDataUnavailableOr } from '../../utils/error-handling';
import {
  NotificationAction,
  NotificationDocumentsActionDeleteFile,
  NotificationDocumentsActionDeleteFileSuccess,
  NotificationDocumentsProgressAction,
  NotificationDocumentsShowErrorAction,
  NotificationDocumentsShowSuccessAction,
  NotificationDocumentsUploadAction,
  NotificationDocumentsUploadErrorAction,
  NotificationDocumentsUploadReadyAction,
  NotificationGetMessagesAction,
  NotificationGetMessagesResultAction,
  NotificationGetSingleMessageAction,
  NotificationMarkAllAsReadAction,
  NotificationMarkAllAsReadResultAction,
  NotificationMarkAsReadAction,
  NotificationMarkAsReadResultAction,
  NotificationSendTextMessageAction,
  NotificationSendTextMessageAnswerAction,
  NotificationSendTextMessageAnswerResultAction,
  NotificationSendTextMessageResultAction,
} from './actions';

const addNotifications = (payload: AjaxResponse): NotificationGetMessagesResultAction => {
  return {
    type: NotificationAction.GET_MESSAGES_RESULT,
    payload: payload.response,
  };
};

const addSingleNotifications = (payload: AjaxResponse): NotificationGetMessagesResultAction => {
  return {
    type: NotificationAction.GET_MESSAGES_RESULT,
    payload: [payload.response],
  };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getNotificationsError = (error: any, dossierId: number): any => {
  return redirectToDataUnavailableOr(error, handleRequestFailureInEpic(error), dossierId);
};

export const getMessagesEpic: Epic<Action, Action> = (action$: Observable<Action>): Observable<Action> =>
  action$.pipe(
    ofType(NotificationAction.GET_MESSAGES),
    mergeMap((action) => {
      const getMessagesAction = action as NotificationGetMessagesAction;
      return customAjax({
        subRoute: `/dossier-intranet/v1/cases/${getMessagesAction.dossierId}`,
        withCredentials: true,
        method: 'GET',
        headers: {
          'Accept-Language': 'de-CH',
        },
      }).pipe(
        map((response) => addNotifications(response)),
        catchError((error) => {
          return of(getNotificationsError(error, getMessagesAction.dossierId));
        }),
      );
    }),
  );

export const getSingleMessageEpic: Epic<Action, Action> = (action$: Observable<Action>): Observable<Action> =>
  action$.pipe(
    ofType(NotificationAction.GET_SINGLE_MESSAGE),
    mergeMap((action) => {
      const getMessageAction = action as NotificationGetSingleMessageAction;
      return customAjax({
        subRoute: `/dossier-intranet/v1/cases/${getMessageAction.dossierId}/case-details/${getMessageAction.caseId}`,
        withCredentials: true,
        method: 'GET',
        headers: {
          'Accept-Language': 'de-CH',
        },
      }).pipe(
        map((response) => addSingleNotifications(response)),
        catchError((error) => {
          return of(getNotificationsError(error, getMessageAction.dossierId));
        }),
      );
    }),
  );

const addNewConversation = (payload: AjaxResponse): NotificationSendTextMessageResultAction => {
  return {
    type: NotificationAction.SEND_TEXT_MESSAGE_RESULT,
    payload: payload.response,
  };
};

export const createConversationEpic: Epic<Action, Action> = (action$: Observable<Action>): Observable<Action> =>
  action$.pipe(
    ofType(NotificationAction.SEND_TEXT_MESSAGE),
    mergeMap((action) => {
      const newMessageAction: NotificationSendTextMessageAction = action as NotificationSendTextMessageAction;
      return customAjax({
        subRoute: `/dossier-intranet/v1/cases/${newMessageAction.dossierId}`,
        method: 'POST',
        withCredentials: true,
        body: newMessageAction.payload,
        headers: {
          'Content-Type': 'application/json',
          'Accept-Language': 'de-CH',
        },
      }).pipe(
        map((response) => addNewConversation(response)),
        catchError((error) => {
          return of(getNotificationsError(error, newMessageAction.dossierId));
        }),
      );
    }),
  );

const addAnswer = (payload: AjaxResponse): NotificationSendTextMessageAnswerResultAction => {
  return {
    type: NotificationAction.SEND_TEXT_MESSAGE_ANSWER_RESULT,
    payload: payload.response,
  };
};

export const replyToConversationEpic: Epic<Action, Action> = (action$: Observable<Action>): Observable<Action> =>
  action$.pipe(
    ofType(NotificationAction.SEND_TEXT_MESSAGE_ANSWER),
    mergeMap((action) => {
      const newMessageAction: NotificationSendTextMessageAnswerAction = action as NotificationSendTextMessageAnswerAction;
      return customAjax({
        subRoute: `/dossier-intranet/v1/cases/${newMessageAction.dossierId}`,
        withCredentials: true,
        method: 'POST',
        body: { ...newMessageAction.payload, typeId: undefined },
        headers: {
          'Content-Type': 'application/json',
          'Accept-Language': 'de-CH',
        },
      }).pipe(
        map((response) => addAnswer(response)),
        catchError((error) => {
          return of(getNotificationsError(error, newMessageAction.dossierId));
        }),
      );
    }),
  );

export class NotificationDocumentAjaxError extends AjaxError {
  documentTypeId?: number;
  fileName?: string;
  caseId?: number;
}

export class NotificationDocumentAjaxResponse extends AjaxResponse {
  caseId?: number;
  fileName?: string;
  streamId?: string;
  fileType?: string;
  documentTypeId?: number;
}

export interface NotificationDocumentProgressEvent {
  event: ProgressEvent;
  documentTypeId: number;
  fileName: string;
  caseId?: number;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getNotificationDocumentUploadResult = (
  payload: NotificationDocumentProgressEvent | NotificationDocumentAjaxResponse | NotificationDocumentAjaxError,
):
  | NotificationDocumentsUploadReadyAction
  | NotificationDocumentsProgressAction
  | NotificationDocumentsUploadErrorAction => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const genericPayload: any = payload;
  if (genericPayload.event) {
    const progressEvent = payload as NotificationDocumentProgressEvent;
    return {
      type: NotificationAction.PROGRESS,
      payload: {
        name: progressEvent.fileName ? progressEvent.fileName : '',
        typeId: progressEvent.documentTypeId,
        progress: (progressEvent.event.loaded / progressEvent.event.total) * 100,
      },
      caseId: progressEvent.caseId,
    };
  }

  if (genericPayload.message) {
    const ajaxError = payload as NotificationDocumentAjaxError;
    const detail = ajaxError.response.detail ? ajaxError.response.detail : ajaxError.response.Detail;
    return {
      type: NotificationAction.UPLOAD_ERROR,
      payload: {
        error: detail ? detail : 'UNKNOWN ERROR',
        typeId: ajaxError.documentTypeId ? ajaxError.documentTypeId : -1,
      },
      caseId: ajaxError.caseId,
    };
  }

  const ajaxResponse = payload as NotificationDocumentAjaxResponse;
  return {
    type: NotificationAction.UPLOAD_READY,
    payload: { ...ajaxResponse.response },
    caseId: ajaxResponse.response.caseId,
    typeId: ajaxResponse.documentTypeId ? ajaxResponse.documentTypeId : -1,
  };
};

export const sendPaymentAddressChangeFileEpic: Epic<Action, Action> = (
  action$: Observable<Action>,
): Observable<Action> =>
  action$.pipe(
    ofType(NotificationAction.UPLOAD),
    switchMap((action) => {
      const documentUploadAction: NotificationDocumentsUploadAction = action as NotificationDocumentsUploadAction;
      const formData = new FormData();
      if (documentUploadAction.payload.originalFile) {
        formData.append('input', documentUploadAction.payload.originalFile, documentUploadAction.payload.fileName);
      }
      return new Observable<
        NotificationDocumentProgressEvent | NotificationDocumentAjaxResponse | NotificationDocumentAjaxError
      >((subscriber) => {
        const documentTypeId = documentUploadAction.typeId;
        const progressSubject = new Subscriber<ProgressEvent>((event) => {
          const progressEvent: NotificationDocumentProgressEvent = {
            event: event,
            documentTypeId: documentTypeId,
            fileName: documentUploadAction.payload.fileName,
            caseId: documentUploadAction.caseId,
          };
          subscriber.next(progressEvent);
        });
        const urlParams = [];
        if (documentUploadAction.caseId) {
          urlParams.push(`caseId=${documentUploadAction.caseId}`);
        } else {
          urlParams.push(`caseTypeId=${Math.abs(documentTypeId)}`);
        }
        return customAjax({
          subRoute: `/dossier-intranet/v1/documents/${documentUploadAction.dossierId}?${urlParams.join('&')}`,
          withCredentials: true,
          method: 'POST',
          body: formData,
          progressSubscriber: progressSubject,
        })
          .pipe(
            map((response) => {
              return {
                ...response,
                documentTypeId: documentTypeId,
                fileName: documentUploadAction.payload.fileName,
              };
            }),
            catchError((error) => {
              const ajaxError = error as AjaxError;
              return of({
                ...ajaxError,
                caseId: documentUploadAction.caseId,
                documentTypeId: documentTypeId,
                fileName: documentUploadAction.payload.fileName,
              });
            }),
          )
          .subscribe(subscriber);
      });
    }),
    map((response) => {
      return getNotificationDocumentUploadResult(response);
    }),
  );

export const showCaseUploadStatusMessageEpic: Epic<Action, Action> = (
  action$: Observable<Action>,
): Observable<Action> =>
  action$.pipe(
    ofType(NotificationAction.UPLOAD_READY),
    map((action) => {
      const uploadReadyAction = action as NotificationDocumentsUploadReadyAction;
      return {
        type: NotificationAction.SHOW_SUCCESS,
        payload: uploadReadyAction.typeId,
        caseId: uploadReadyAction.caseId,
      };
    }),
  );

export const showCaseUploadErrorEpic: Epic<Action, Action> = (action$: Observable<Action>): Observable<Action> =>
  action$.pipe(
    ofType(NotificationAction.UPLOAD_ERROR),
    map((action) => {
      const errorAction = action as NotificationDocumentsUploadErrorAction;
      return { type: NotificationAction.SHOW_ERROR, payload: errorAction.payload, caseId: errorAction.caseId };
    }),
  );

export const resetCaseUploadStatusEpic: Epic<Action, Action> = (action$: Observable<Action>): Observable<Action> =>
  action$.pipe(
    ofType(NotificationAction.SHOW_SUCCESS, NotificationAction.SHOW_ERROR),
    debounceTime(2000),
    map((action) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const anyAction = action as any;
      if ('number' === typeof anyAction.payload) {
        const showSuccessAction = action as NotificationDocumentsShowSuccessAction;
        return {
          type: NotificationAction.RESET_STATUS,
          payload: showSuccessAction.payload,
          caseId: showSuccessAction.caseId,
        };
      } else {
        const errorAction = action as NotificationDocumentsShowErrorAction;
        return {
          type: NotificationAction.RESET_STATUS,
          payload: errorAction.payload.typeId,
          caseId: errorAction.caseId,
        };
      }
    }),
  );

const markNotificationAsRead = (payload: AjaxResponse): NotificationMarkAsReadResultAction => {
  return {
    type: NotificationAction.MARK_AS_READ_RESULT,
    payload: payload.response,
  };
};

export const markNotificationAsReadEpic: Epic<Action, Action> = (action$: Observable<Action>): Observable<Action> =>
  action$.pipe(
    ofType(NotificationAction.MARK_AS_READ),
    mergeMap((action) => {
      const markAsReadAction: NotificationMarkAsReadAction = action as NotificationMarkAsReadAction;
      return customAjax({
        subRoute: `/dossier-intranet/v1/clientevent/${markAsReadAction.dossierId}/mark-as-read`,
        withCredentials: true,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json-patch+json',
          Accept: ' */*',
        },
        body: JSON.stringify(markAsReadAction.clientEventIds),
      }).pipe(
        map((response) => markNotificationAsRead(response)),
        catchError((error) => {
          return of(getNotificationsError(error, markAsReadAction.dossierId));
        }),
      );
    }),
  );

const markAllNotificationAsRead = (): NotificationMarkAllAsReadResultAction => {
  return {
    type: NotificationAction.MARK_ALL_AS_READ_RESULT,
  };
};

export const markAllNotificationsAsReadEpic: Epic<Action, Action> = (action$: Observable<Action>): Observable<Action> =>
  action$.pipe(
    ofType(NotificationAction.MARK_ALL_AS_READ),
    mergeMap((action) => {
      const markAllAsReadAction: NotificationMarkAllAsReadAction = action as NotificationMarkAllAsReadAction;
      return customAjax({
        subRoute: `/dossier-intranet/v1/clientevent/${markAllAsReadAction.dossierId}/mark-all-as-read`,
        withCredentials: true,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json-patch+json',
          Accept: ' */*',
        },
      }).pipe(
        map(() => markAllNotificationAsRead()),
        catchError((error) => {
          return of(getNotificationsError(error, markAllAsReadAction.dossierId));
        }),
      );
    }),
  );

const processNotificationFileDeleteSuccess = (
  response: AjaxResponse,
  streamId: string,
): NotificationDocumentsActionDeleteFileSuccess => {
  return {
    type: NotificationAction.DELETE_FILE_SUCCESS,
    payload: streamId,
  };
};

export const deleteNotificationDocumentEpic: Epic<Action, Action> = (action$: Observable<Action>): Observable<Action> =>
  action$.pipe(
    ofType(NotificationAction.DELETE_FILE),
    mergeMap((action) => {
      const deleteFileAction = action as NotificationDocumentsActionDeleteFile;
      const streamId = deleteFileAction.payload.streamId ? deleteFileAction.payload.streamId : '';
      const deleteUrl = `/dossier-intranet/v1/documents/${deleteFileAction.dossierId}?streamId=${streamId}&caseId=${deleteFileAction.caseId}`;
      return customAjax({
        subRoute: deleteUrl,
        withCredentials: true,
        method: 'DELETE',
      }).pipe(map((response) => processNotificationFileDeleteSuccess(response, streamId)));
    }),
  );
