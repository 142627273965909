import React, { ReactElement } from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import { blue } from './../../theme';

export function ZoomPayoutIcon(props: SvgIconProps): ReactElement {
  const color = props.htmlColor ? props.htmlColor : blue.main;
  return (
    <SvgIcon {...props}>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <path d="M0 0H80V80H0z" transform="translate(-1048 -57) translate(1048 57)" />
            <path
              fill={color}
              fillRule="nonzero"
              d="M50.65 47.22c-.093-.494-.318-.953-.65-1.33l.34-.35c.533-.53.832-1.25.832-2s-.3-1.47-.832-2l-4.7-4.7-1.89.93L49 43c.317.33.317.85 0 1.18l-8.35 8.35c-.33.317-.85.317-1.18 0L20.63 33.67c-.158-.156-.246-.368-.246-.59 0-.222.088-.434.246-.59L29 24.14c.154-.16.368-.246.59-.24.221-.003.434.084.59.24l7.36 7.36c.18-.278.387-.536.62-.77l.3-.3.56-.28-7.43-7.43c-1.11-1.093-2.89-1.093-4 0l-8.35 8.35c-1.093 1.11-1.093 2.89 0 4l.81.8-2.92 4c-.457.61-.649 1.378-.532 2.13.117.753.532 1.427 1.152 1.87L39.24 59.5c.486.34 1.067.515 1.66.5.908.003 1.763-.428 2.3-1.16l6.94-9.55c.433-.597.616-1.34.51-2.07zm-2.13.93l-6.94 9.55c-.274.371-.797.452-1.17.18L18.9 42.23c-.182-.124-.302-.321-.33-.54-.041-.217.01-.442.14-.62l2.74-3.76L38 53.89c.53.533 1.25.832 2 .832s1.47-.3 2-.832l6.57-6.56c.032.063.056.13.07.2.046.214.002.438-.12.62z"
              transform="translate(-1048 -57) translate(1048 57)"
            />
            <path
              fill={color}
              fillRule="nonzero"
              d="M58 36.11l-9.34-9-9.29 4.63-.11.11c-1.038.945-1.256 2.495-.52 3.69.718 1.058 2.013 1.567 3.26 1.28l4-2 6.62 6.7c.24.294.417.635.52 1-.103.94-.406 1.847-.89 2.66-.301.621-.38 1.328-.22 2 .124.45.114.926-.03 1.37-.405.897-.87 1.765-1.39 2.6l-1 1.53h14.9V36.25L58 36.11zm4.57 14.57h-9.35c.23-.43.46-.9.65-1.35.288-.738.358-1.544.2-2.32-.06-.257-.06-.523 0-.78.629-1.075 1.02-2.271 1.15-3.51-.061-.897-.412-1.75-1-2.43l-7.75-7.86-5 2.46c-.416.054-.828-.127-1.07-.47-.26-.38 0-.83.2-1l7.71-3.84 8.88 8.55 5.4.11-.02 12.44zM34.55 34.54c-2.093 0-3.79 1.697-3.79 3.79s1.697 3.79 3.79 3.79 3.79-1.697 3.79-3.79-1.697-3.79-3.79-3.79zm0 5.57c-.725 0-1.378-.437-1.655-1.107-.276-.67-.121-1.441.393-1.952.514-.511 1.285-.662 1.954-.382s1.102.936 1.098 1.661c0 .474-.189.928-.525 1.262-.336.334-.791.52-1.265.518z"
              transform="translate(-1048 -57) translate(1048 57)"
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}
