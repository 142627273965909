import { Breadcrumbs, CircularProgress, Container, Link } from '@material-ui/core';
import React, { ReactElement, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Action } from 'redux';
import { HolderDropDownProps } from '../../components/base-page-components/holder-dropdown/HolderDropDown';
import { DemandInformation, DemandInformationProps } from '../../components/demand-information/DemandInformation';
import { HolderDataAction, HolderDataGetAction } from '../../store/holder-data/actions';
import { i18nLabel } from '../../store/i18n/helpers';
import { MyDemandPageAction, MyDemandPageGetAction } from '../../store/my-demand/actions';
import { MyDemandsDataStore } from '../../store/my-demand/types';
import { WIAAStore } from '../../store/types';
import { mapStoreToHolderDropDownProps } from '../../utils/mappers';
import ZoomFooter, {
  mapStoreToFooterProps,
  ZoomFooterProps,
} from './../../components/base-page-components/footer/ZoomFooter';
import ZoomHeader, {
  mapStoreToHeaderProps,
  ZoomHeaderProps,
} from './../../components/base-page-components/header/ZoomHeader';
import { NavigationItems } from '../../components/base-page-components/navigation/Navigation';

export interface DemandsAndPayoutsProps {
  title: string;
  headerProps: ZoomHeaderProps;
  footerProps: ZoomFooterProps;
  breadcrumbLabels: Array<string>;
  labelBackArrow: string;
  demands: Array<DemandInformationProps>;
  dossierId: number;
  holderDropDownProps: HolderDropDownProps;
  isLoading?: boolean;
  instalmentStates?: { name: string; value: string }[];
  demandDecisionStates?: { name: string; value: string }[];
  creditTypes?: { name: string; value: string }[];
  dispatch?: (action: Action) => void;
}

export const DemandsAndPayouts = (props: DemandsAndPayoutsProps): ReactElement => {
  const demandInfos = props.demands.map((demandInfo, index) => (
    <DemandInformation
      key={index}
      {...demandInfo}
      dossierId={props.dossierId}
      instalmentStates={props.instalmentStates}
      creditTypes={props.creditTypes}
      demandDecisionStates={props.demandDecisionStates}
    />
  ));

  useEffect(() => {
    if (props.dispatch) {
      const getUserMyDemand: MyDemandPageGetAction = {
        type: MyDemandPageAction.GET,
        payload: props.dossierId,
      };
      props.dispatch(getUserMyDemand);
    }
  }, []);

  useEffect(() => {
    if (props.dispatch && props.dossierId) {
      const getHolderData: HolderDataGetAction = {
        type: HolderDataAction.GET,
      };
      props.dispatch(getHolderData);
    }
  }, []);

  return (
    <>
      <ZoomHeader {...props.headerProps} dispatch={props.dispatch} />
      <main className="main">
        {props.isLoading ? (
          <div style={{ textAlign: 'center' }}>
            <CircularProgress />
          </div>
        ) : (
          <>
            <Container>
              <div id="main-page-container">
                <div className="main-content-limited">
                  <Breadcrumbs separator=">" aria-label="breadcrumb">
                    <Link
                      style={{ fontFamily: 'Proxima Nova Cond' }}
                      color="inherit"
                      href={'/dashboard/dossier/' + props.dossierId}
                    >
                      <b>{props.breadcrumbLabels && props.breadcrumbLabels[0]}</b>
                    </Link>
                    <Link style={{ fontFamily: 'Proxima Nova Cond' }} color="inherit">
                      {props.breadcrumbLabels && props.breadcrumbLabels[1]}
                    </Link>
                  </Breadcrumbs>
                  <NavigationItems
                    title={props.title}
                    labelBackArrow={props.labelBackArrow}
                    holderDropDownProps={props.holderDropDownProps}
                    dispatch={props.dispatch}
                    mainPageTopInfo={true}
                  />
                </div>
                <div className="main-content-limited">{demandInfos}</div>
              </div>
            </Container>
          </>
        )}
      </main>
      <ZoomFooter {...props.footerProps} />
    </>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export const mapStateToDemandsAndPayoutsProps = (store: WIAAStore, urlProps: any): DemandsAndPayoutsProps => {
  const { dossierId } = urlProps.match.params;
  const headerProps: ZoomHeaderProps = mapStoreToHeaderProps(store, dossierId);
  const footerProps: ZoomFooterProps = mapStoreToFooterProps(store);

  const props: DemandsAndPayoutsProps = {
    headerProps: headerProps,
    footerProps: footerProps,
    dossierId: dossierId,
    holderDropDownProps: mapStoreToHolderDropDownProps(store, parseInt(dossierId)),
    demands: [],
    title: i18nLabel(store.translationLabels, 'Demands_And_Payouts_Title', 'Eingereichte Anträge und Auszahlungen'),
    labelBackArrow: i18nLabel(store.translationLabels, 'Demands_And_Payouts_Back_Arrow', 'Zurück'),
    breadcrumbLabels: [
      i18nLabel(store.translationLabels, 'Demands_And_Payouts_Breadcrumb_Label_1', 'Übersicht'),
      i18nLabel(
        store.translationLabels,
        'Demands_And_Payouts_Breadcrumb_Label_2',
        'Eingereichte Anträge und Auszahlungen',
      ),
    ],
    instalmentStates: store.translationOptions['instalment-states']
      ? [
          ...store.translationOptions['instalment-states'].map((option) => {
            return { value: option.id.toString(), name: option.name };
          }),
        ]
      : [],
    demandDecisionStates: store.translationOptions['demand-decision-states']
      ? [
          ...store.translationOptions['demand-decision-states'].map((option) => {
            return { value: option.id.toString(), name: option.name };
          }),
        ]
      : [],
    creditTypes: store.translationOptions['credit-types']
      ? [
          ...store.translationOptions['credit-types'].map((option) => {
            return { value: option.id.toString(), name: option.name };
          }),
        ]
      : [],
    isLoading: store.myDemandsData.isLoading,
  };

  const data: MyDemandsDataStore = store.myDemandsData;
  if (data && data.myDemands) {
    const demands = [...data.myDemands].reverse();
    props.demands = demands.map((demand) => ({
      demandId: demand.detail.id,
      titleYear: demand.detail.demandYear ? demand.detail.demandYear.toString() : '',
      submitDate: demand.detail.entryDate ? demand.detail.entryDate : new Date(),
      decisionDate: demand.detail.decisionDate ? demand.detail.decisionDate : undefined,
      decision: demand.detail.decisionResult ? demand.detail.decisionResult : undefined,
      scholarshipTotal: demand.scholarshipTotal.total,
      scholarshipPaidOut: demand.scholarshipTotal.paid,
      scholarshipStatus: demand.scholarshipTotal.toPay === 0 ? 'ausbezahlt' : 'offen',
      debtTotal: demand.loanTotal.total,
      debtPaidOut: demand.loanTotal.paid,
      debtStatus: demand.loanTotal.toPay === 0 ? 'ausbezahlt' : 'offen',
      isOpened: false,
      institution: demand.detail.formationInstitute ? demand.detail.formationInstitute : '',
      instalments: demand.paymentDetails.map((payment) => ({
        instalmentType: payment.paymentType.toString(),
        amount: payment.amount.toString(),
        date: payment.dueDate,
        finishedDate: payment.valuta,
        status: payment.state,
        beneficiary: payment.recipient,
      })),
      files: demand.documents.map((document, index) => ({
        id: index.toString(),
        typeId: document.documentTypeId,
        type: document.documentTypeName,
        streamId: document.streamId,
        fileName: document.fileName,
      })),
      labelTitle: i18nLabel(store.translationLabels, 'Demands_And_Payouts_Request_Title', 'Antrag'),
      labelDebt: i18nLabel(store.translationLabels, 'Demands_And_Payouts_Debt', 'Darlehen ausbezahlt'),
      labelScholarship: i18nLabel(store.translationLabels, 'Demands_And_Payouts_Scholarship', 'Stipendium ausbezahlt'),
      labelDecisionResult: i18nLabel(store.translationLabels, 'Demands_And_Payouts_Decision_Result', 'Resultat'),
      labelDecision: i18nLabel(store.translationLabels, 'Demands_And_Payouts_Decision', 'Entscheid am'),
      labelSubmittedOn: i18nLabel(store.translationLabels, 'Demands_And_Payouts_Submitted_On', 'Eingereicht am'),
      labelInstalments: i18nLabel(store.translationLabels, 'Demands_And_Payouts_Instalments', 'Auszahlungsraten'),
      labelInstalmentAmount: i18nLabel(store.translationLabels, 'Demands_And_Payouts_Instalment_Amount', 'Betrag'),
      labelInstalmentBeneficiary: i18nLabel(
        store.translationLabels,
        'Demands_And_Payouts_Instalment_Beneficiary',
        'Begünstigter',
      ),
      labelInstalmentDate: i18nLabel(store.translationLabels, 'Demands_And_Payouts_Instalment_Date', 'Fälligkeit'),
      labelInstalmentFinishedDate: i18nLabel(
        store.translationLabels,
        'Demands_And_Payouts_Instalment_Finished_Date',
        'Auszahlung am',
      ),
      labelInstalmentStatus: i18nLabel(
        store.translationLabels,
        'Demands_And_Payouts_Instalment_Status',
        'Auszahlungsstand',
      ),
      labelInstalmentType: i18nLabel(store.translationLabels, 'Demands_And_Payouts_Instalment_Type', 'Beschrieb'),
      labelDocumentDownload: i18nLabel(
        store.translationLabels,
        'Demands_And_Payouts_Document_Download_Title',
        'Dokumente herunterladen',
      ),
      labelDesicionForEducation: i18nLabel(
        store.translationLabels,
        'Demands_And_Payouts_Desicion_For_Education_Document_Download',
        'Entscheid für Ausbildungsbeiträge',
      ),
      labelPaymentPaper: i18nLabel(
        store.translationLabels,
        'Demands_And_Payouts_Payment_Paper_Download',
        'Berrechnungsblatt',
      ),
    }));
  }
  return props;
};

export default withRouter(connect(mapStateToDemandsAndPayoutsProps)(DemandsAndPayouts));
