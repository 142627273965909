import { createMuiTheme } from '@material-ui/core';
import { PaletteColor } from '@material-ui/core/styles/createPalette';

export const grey: PaletteColor = {
  main: '#767676',
  light: '#767676',
  dark: '#767676',
  contrastText: '#fff',
};

export const silverGrey: PaletteColor = {
  main: '#CDCDCD',
  light: '#CDCDCD',
  dark: '#CDCDCD',
  contrastText: '#262626',
};

export const lightGrey: PaletteColor = {
  main: '#DBDBDB',
  light: '#DBDBDB',
  dark: '#DBDBDB',
  contrastText: '#262626',
};

export const black: PaletteColor = {
  main: '#262626',
  light: '#262626',
  dark: '#262626',
  contrastText: '#fff',
};

export const white: PaletteColor = {
  main: '#FFFFFF',
  light: '#FFFFFF',
  dark: '#FFFFFF',
  contrastText: '#262626',
};

export const blue: PaletteColor = {
  main: '#007AB8',
  light: '#007AB8',
  dark: '#007AB8',
  contrastText: '#fff',
};

export const lightBlue: PaletteColor = {
  main: '#E5F4FC',
  light: '#E5F4FC',
  dark: '#E5F4FC',
  contrastText: '#004774',
};

export const darkBlue: PaletteColor = {
  main: '#004774',
  light: '#004774',
  dark: '#004774',
  contrastText: '#fff',
};

export const scholarshipBackgroundBlue: PaletteColor = {
  main: '#99b5c7',
  light: '#99b5c7',
  dark: '#99b5c7',
  contrastText: '#fff',
};

export const red: PaletteColor = {
  main: '#B80D22',
  light: '#B80D22',
  dark: '#B80D22',
  contrastText: '#fff',
};

export const darkRed: PaletteColor = {
  main: '#8C0011',
  light: '#8C0011',
  dark: '#8C0011',
  contrastText: '#fff',
};

export const green: PaletteColor = {
  main: '#81AF17',
  light: '#81AF17',
  dark: '#81AF17',
  contrastText: '#fff',
};

export const orange: PaletteColor = {
  main: '#F6F2E7',
  light: '#F6F2E7',
  dark: '#F6F2E7',
  contrastText: '#fff',
};

export const darkOrange: PaletteColor = {
  main: '#da6f00',
  light: '#da6f00',
  dark: '#da6f00',
  contrastText: '#fff',
};

const theme = createMuiTheme({
  palette: {
    common: {
      black: black.main,
      white: white.main,
    },
    primary: blue,
    secondary: black,
    error: red,
    warning: orange,
    info: grey,
    success: green,
  },
  overrides: {
    MuiButton: {
      root: {
        textTransform: 'none',
      },
    },
    MuiInput: {
      root: {
        '&$disabled': {
          color: grey.main,
          backgroundColor: lightGrey.main,
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        borderColor: black.main,
        borderStyle: 'solid',
        borderWidth: 0,
      },
      notchedOutline: {
        borderColor: black.main,
      },
      marginDense: {
        paddingTop: 3,
        paddingBottom: 3,
      },
    },
    MuiFormHelperText: {
      contained: {
        marginLeft: 0,
        marginRight: 0,
      },
    },
    MuiFormControlLabel: {
      label: {
        fontSize: '1.2em',
        fontFamily: 'Proxima Nova Cond',
      },
    },
    MuiInputBase: {
      root: {
        paddingTop: 6,
        paddingBottom: 6,
      },
      input: {
        borderColor: green.main,
        fontFamily: 'Proxima Nova Cond',
        fontSize: '1.2em',
        fontWeight: 600,
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: '0.15px',
      },
    },
    MuiChip: {
      root: {
        borderRadius: 0,
      },
    },
    MuiSvgIcon: {
      fontSizeLarge: {
        fontSize: '1.9rem',
      },
    },
    MuiTextField: {
      root: {
        fontSize: '1.3em',
        fontFamily: 'Proxima Nova Cond',
      },
    },
  },
  shape: {
    borderRadius: 0,
  },
  props: {
    MuiButton: {
      disableElevation: true,
      disableRipple: true,
    },
    MuiPaper: {
      elevation: 0,
    },
  },
  typography: {
    fontFamily: ['Proxima Nova', 'Proxima Nova Cond', 'Georgia'].join(','),
    h1: {
      fontSize: 32,
      lineHeight: 1.313,
    },
    h2: {
      fontSize: 21,
      lineHeight: 1.667,
    },
    h3: {
      fontSize: 18,
      lineHeight: 1.722,
    },
    subtitle1: {
      fontSize: 18,
      lineHeight: 1.889,
    },
    subtitle2: {
      fontSize: 18,
      lineHeight: 1.889,
    },
    body1: {
      fontSize: 15,
    },
    body2: {
      fontSize: 15,
    },
    caption: {
      fontSize: 13,
      lineHeight: 1.385,
    },
  },
});

export default theme;
