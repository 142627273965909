import React, { ReactElement } from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import { black } from '../../theme';

export function ZoomEmailIcon(props: SvgIconProps): ReactElement {
  const color = props.htmlColor ? props.htmlColor : black.main;
  return (
    <SvgIcon {...props}>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <path d="M0 0H80V80H0z" transform="translate(-256 -357) translate(256 357)" />
            <path
              fill={color}
              fillRule="nonzero"
              d="M16.9 56.1h47.52V23.55H16.9V56.1zm43.52-4H20.9V29l11.63 9-9 7 1.83 2.37L34.87 40l-.32-.42 6 4.66 6-4.6-.27.36 9.56 7.37L57.66 45l-9-7 11.76-9v23.1zM40.57 40.44L23.93 27.55h33.42L40.57 40.44z"
              transform="translate(-256 -357) translate(256 357)"
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}
