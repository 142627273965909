import { Button, Container, Grid } from '@material-ui/core';
import React, { ReactElement } from 'react';
import { connect } from 'react-redux';
import ZoomFooter from '../../components/base-page-components/footer/ZoomFooter';
import ZoomHeader from './../../components/base-page-components/header/ZoomHeader';
import { mapStateToHomepageProps } from './ConnectedHomepage';
import { HomepageProps } from './Homepage';
import './HomepageMobile.scss';

export const HomepageMobile = (props: HomepageProps): ReactElement => {
  const openRegisterUrlInNewWindow = () => {
    window.open(process.env.REACT_APP_REGISTER_URL, '_blank');
  };
  return (
    <>
      <ZoomHeader
        appName="Stipendienportal"
        userName="Max Mustermann"
        ahvn="756-4352-9990-13"
        labelMyData="Meine Daten"
        labelMyEducation="Meine Ausbildungen"
        labelMyScholarship="Meine Stipendienanträge"
        labelPayout="Auszahlungen"
        labelRefund="Rückzahlungen"
        labelPowerOfAttorney="Vollmachten"
        labelScholarshipHolder="Stipendiat wählen"
        labelLogout="Abmelden"
        hideUserMenu={true}
      ></ZoomHeader>
      <div className="main-section-mobile">
        <div className="top-div-mobile">
          <Container className="intro-image-wrapper">
            <div className="image-text-mobile">
              <h1>{props.imageTextTitle}</h1>
              <p>{props.imageTextDescription}</p>
            </div>
          </Container>
        </div>

        <Container className="main-page-mobile">
          <Grid container direction="row" alignItems="stretch" justify="space-around" spacing={3}>
            <Grid item xs={12}>
              <div>
                <h1>{props.loginTitle}</h1>
                <p>{props.loginFirstParagraph}</p>
                <p>{props.loginSecondParagraph}</p>
                <Button
                  color="primary"
                  href={process.env.REACT_APP_LOGIN_URL}
                  size="large"
                  variant="contained"
                  className="buttons"
                >
                  {props.loginButton}
                </Button>
              </div>
            </Grid>
            <Grid item xs={12}>
              <div>
                <h1>{props.registerTitle}</h1>
                <p>{props.registerFirstParagraph}</p>
                <p>{props.registerSecondParagraph}</p>
                <Button
                  color="primary"
                  onClick={openRegisterUrlInNewWindow}
                  size="large"
                  variant="outlined"
                  className="buttons"
                >
                  {props.registerButton}
                </Button>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
      <ZoomFooter {...props.footerProps}></ZoomFooter>
    </>
  );
};

export default connect(mapStateToHomepageProps)(HomepageMobile);
