import { Button } from '@material-ui/core';
import React, { ChangeEvent, ReactElement, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ZoomDatePicker from '../../components/forms/date-picker/ZoomDatePicker';
import ZoomSelectInput from '../../components/forms/select-input/ZoomSelectInput';
import ZoomTextInput from '../../components/forms/text-input/ZoomTextInput';
import ZoomToggleInput from '../../components/forms/toggle-input/ZoomToggleInput';
import { i18nLabel } from '../../store/i18n/helpers';
import { MotherDataAction, MotherDataActionType } from '../../store/mother-data/actions';
import { MotherData } from '../../store/mother-data/types';
import { WIAAStore } from '../../store/types';
import { setTimeToMiddleOfDay } from '../../utils/date-manipulation';
import {
  ahvn13validation,
  maxValueValidation,
  minMaxValueValidation,
  postCodeValidation,
  requiredValidation,
} from '../../utils/validators';
import ZoomMultipleTextInput from './../../components/forms/multiple-text-input/ZoomMultipleTextInput';
import './MotherDataForm.scss';
import { extractToggleValue } from '../../utils/object-manipulation';

export interface MotherDataFormProps {
  id?: string;
  firstName?: string;
  lastName?: string;
  ahvn13?: string;
  dateOfBirth?: Date;
  street?: string;
  streetNumber?: string;
  zipCode?: string;
  town?: string;
  countryIdType?: string;
  profession?: string;
  worktimeQuota?: string;
  phoneNumberPrivate?: string;
  pensionReceived?: string;
  pensionAmount?: string;
  additionalAssistanceReceived?: string;
  additionalAssistanceAmount?: string;
  alimonyReceived?: string;
  alimonyReceivedAmount?: string;
  firstNameStepFather?: string;
  lastNameStepFather?: string;
  ahvn13StepFather?: string;
  pifId: number;
  labelFirstName?: string;
  labelLastName?: string;
  labelAhvn13?: string;
  labelDateOfBirth?: string;
  labelStreet?: string;
  labelStreetNumber?: string;
  labelZipCode?: string;
  labelTown?: string;
  labelCountryIdType?: string;
  labelProfession?: string;
  labelWorktimeQuota?: string;
  labelPhoneNumberPrivate?: string;
  labelPensionReceived?: string;
  labelPensionAmount?: string;
  labelAdditionalAssistanceReceived?: string;
  labelAdditionalAssistanceAmount?: string;
  labelAlimonyReceived?: string;
  labelAlimonyReceivedAmount?: string;
  labelFirstNameStepFather?: string;
  labelLastNameStepFather?: string;
  labelAhvn13StepFather?: string;
  labelSave?: string;
  helpTextAhvn13?: string;
  helptextStreetAndStreetNumber?: string;
  helptextZipCodeAndTown?: string;
  helpTextCountry?: string;
  helpTextAhvn13Father?: string;
  parentsMarried?: boolean;
  parentsSeparated?: boolean;
  labelPremiumReductionReceived: string;
  labelPremiumReductionAmount: string;
  premiumReductionReceived?: string;
  premiumReductionAmount?: string;
  motherCivilStatus?: boolean;
  countryOptions?: { name: string; value: string }[];
  toggleButtonsTypes?: { name: string; value: string }[];
  errorLabels?: { [key: string]: string };
  dispatch?: (action: MotherDataActionType) => void;
}

interface MotherDataErrors {
  id?: string;
  firstName?: string;
  lastName?: string;
  ahvn13?: string;
  dateOfBirth?: string;
  street?: string;
  streetNumber?: string;
  zipCode?: string;
  town?: string;
  profession?: string;
  worktimeQuota?: string;
  phoneNumberPrivate?: string;
  pensionReceived?: string;
  pensionAmount?: string;
  additionalAssistanceReceived?: string;
  additionalAssistanceAmount?: string;
  alimonyReceived?: string;
  alimonyReceivedAmount?: string;
  premiumReductionReceived?: string;
  premiumReductionAmount?: string;
  firstNameStepFather?: string;
  lastNameStepFather?: string;
  ahvn13StepFather?: string;
}

export const MotherDataForm = (props: MotherDataFormProps): ReactElement => {
  const [firstName, setFirstName] = useState(props.firstName ? props.firstName : '');
  const [lastName, setLastName] = useState(props.lastName ? props.lastName : '');
  const [ahvn13, setAhvn13] = useState(props.ahvn13 ? props.ahvn13 : '');
  const [dateOfBirth, setDateOfBirth] = useState(props.dateOfBirth ? props.dateOfBirth : undefined);
  const [street, setStreet] = useState(props.street ? props.street : '');
  const [streetNumber, setStreetNumber] = useState(props.streetNumber ? props.streetNumber : '');
  const [zipCode, setZipCode] = useState(props.zipCode ? props.zipCode : '');
  const [town, setTown] = useState(props.town ? props.town : '');
  const [countryIdType, setCountryIdType] = useState(props.countryIdType ? props.countryIdType : '8100');
  const [profession, setProfession] = useState(props.profession ? props.profession : '');
  const [worktimeQuota, setWorktimeQuota] = useState(props.worktimeQuota ? props.worktimeQuota : '');
  const [phoneNumberPrivate, setPhoneNumberPrivate] = useState(
    props.phoneNumberPrivate ? props.phoneNumberPrivate : '',
  );
  const [pensionReceived, setPensionReceived] = useState(props.pensionReceived ? props.pensionReceived : '2');
  const [pensionAmount, setPensionAmount] = useState(props.pensionAmount ? props.pensionAmount : '');
  const [additionalAssistanceReceived, setAdditionalAssistanceReceived] = useState(
    props.additionalAssistanceReceived ? props.additionalAssistanceReceived : '2',
  );
  const [additionalAssistanceAmount, setAdditionalAssistanceAmount] = useState(
    props.additionalAssistanceAmount ? props.additionalAssistanceAmount : '',
  );
  const [alimonyReceived, setAlimonyReceived] = useState(props.alimonyReceived ? props.alimonyReceived : '2');
  const [alimonyReceivedAmount, setAlimonyReceivedAmount] = useState(
    props.alimonyReceivedAmount ? props.alimonyReceivedAmount : '',
  );
  const [firstNameStepFather, setFirstNameStepFather] = useState(
    props.firstNameStepFather ? props.firstNameStepFather : '',
  );
  const [lastNameStepFather, setLastNameStepFather] = useState(
    props.lastNameStepFather ? props.lastNameStepFather : '',
  );
  const [ahvn13StepFather, setAhvn13StepFather] = useState(props.ahvn13StepFather ? props.ahvn13StepFather : '');
  const [premiumReductionReceived, setPremiumReductionReceived] = useState(props.premiumReductionReceived ?? '');
  const [premiumReductionAmount, setPremiumReductionAmount] = useState(props.premiumReductionAmount ?? '0');
  const [parentsMarried, setParentsMarried] = useState(props.parentsMarried ?? undefined);

  const initialErrors: MotherDataErrors = {};
  const [errors, setErrors] = useState(initialErrors);

  useEffect(() => {
    setZipCode(props.zipCode ? props.zipCode : '');
    setTown(props.town ? props.town : '');
    setPremiumReductionReceived(props.premiumReductionReceived ?? '');
    setPremiumReductionAmount(props.premiumReductionAmount ?? '0');
    setParentsMarried(props.parentsMarried ?? undefined);
  }, [props]);

  const onChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    switch (e.target.name) {
      case 'firstName':
        setFirstName(e.target.value);
        setErrors({
          ...errors,
          firstName: requiredValidation(e.target.value, props.errorLabels ? props.errorLabels : {}),
        });
        break;
      case 'lastName':
        setLastName(e.target.value);
        setErrors({
          ...errors,
          lastName: requiredValidation(e.target.value, props.errorLabels ? props.errorLabels : {}),
        });
        break;
      case 'ahvn13':
        setAhvn13(e.target.value);
        setErrors({
          ...errors,
          ahvn13: ahvn13validation(e.target.value, props.errorLabels ? props.errorLabels : {}),
        });
        break;
      case 'street':
        setStreet(e.target.value);
        setErrors({
          ...errors,
          street: requiredValidation(e.target.value, props.errorLabels ? props.errorLabels : {}),
        });
        break;
      case 'streetNumber':
        setStreetNumber(e.target.value);
        setErrors({
          ...errors,
          streetNumber: requiredValidation(e.target.value, props.errorLabels ? props.errorLabels : {}),
        });
        break;
      case 'zipCode':
        const fieldValue = e.target.value;

        if (fieldValue !== zipCode) {
          setZipCode(fieldValue);
          const postalCodeError = postCodeValidation(fieldValue, props.errorLabels ? props.errorLabels : {});
          setErrors({ ...errors, zipCode: postalCodeError });

          if (props.dispatch && !postalCodeError) {
            const oldMotherData = getMotherData();

            props.dispatch({
              type: MotherDataAction.GET_POSTAL_CODES,
              payload: { ...oldMotherData, zipCode: fieldValue },
            });
          }
        }
        break;
      case 'town':
        setTown(e.target.value);
        setErrors({ ...errors, town: requiredValidation(e.target.value, props.errorLabels ? props.errorLabels : {}) });
        break;
      case 'profession':
        setProfession(e.target.value);
        e.target.value !== ''
          ? setErrors({
              ...errors,
              profession: requiredValidation(e.target.value, props.errorLabels ? props.errorLabels : {}),
            })
          : setErrors({ ...errors, profession: '' });
        break;
      case 'worktimeQuota':
        setWorktimeQuota(e.target.value);
        e.target.value !== ''
          ? setErrors({
              ...errors,
              worktimeQuota: maxValueValidation(e.target.value, 100, props.errorLabels ? props.errorLabels : {}),
            })
          : setErrors({ ...errors, worktimeQuota: '' });
        break;
      case 'phoneNumberPrivate':
        setPhoneNumberPrivate(e.target.value);
        e.target.value !== ''
          ? setErrors({
              ...errors,
              phoneNumberPrivate: requiredValidation(e.target.value, props.errorLabels ? props.errorLabels : {}),
            })
          : setErrors({ ...errors, phoneNumberPrivate: '' });
        break;
      case 'pensionAmount':
        setPensionAmount(e.target.value);
        setErrors({
          ...errors,
          pensionAmount: maxValueValidation(e.target.value, 9999999, props.errorLabels ? props.errorLabels : {}),
        });
        break;
      case 'additionalAssistanceAmount':
        setAdditionalAssistanceAmount(e.target.value);
        e.target.value !== ''
          ? setErrors({
              ...errors,
              additionalAssistanceAmount: maxValueValidation(
                e.target.value,
                9999999,
                props.errorLabels ? props.errorLabels : {},
              ),
            })
          : setErrors({ ...errors, additionalAssistanceAmount: '' });
        break;
      case 'alimonyReceivedAmount':
        setAlimonyReceivedAmount(e.target.value);
        e.target.value !== ''
          ? setErrors({
              ...errors,
              alimonyReceivedAmount: maxValueValidation(
                e.target.value,
                9999999,
                props.errorLabels ? props.errorLabels : {},
              ),
            })
          : setErrors({ ...errors, alimonyReceivedAmount: '' });
        break;
      case 'firstNameStepFather':
        setFirstNameStepFather(e.target.value);
        e.target.value !== ''
          ? setErrors({
              ...errors,
              firstNameStepFather: requiredValidation(e.target.value, props.errorLabels ? props.errorLabels : {}),
            })
          : setErrors({ ...errors, firstNameStepFather: '' });
        break;
      case 'lastNameStepFather':
        setLastNameStepFather(e.target.value);
        e.target.value !== ''
          ? setErrors({
              ...errors,
              lastNameStepFather: requiredValidation(e.target.value, props.errorLabels ? props.errorLabels : {}),
            })
          : setErrors({ ...errors, lastNameStepFather: '' });
        break;
      case 'ahvn13StepFather':
        setAhvn13StepFather(e.target.value);
        e.target.value !== ''
          ? setErrors({
              ...errors,
              ahvn13StepFather: ahvn13validation(e.target.value, props.errorLabels ? props.errorLabels : {}),
            })
          : setErrors({ ...errors, ahvn13StepFather: '' });
        break;
    }
  };

  const onChangeDate = (newDate: Date | null) => {
    if (null === newDate) {
      setDateOfBirth(undefined);
    } else {
      setDateOfBirth(newDate);
    }
    setErrors({
      ...errors,
      dateOfBirth: newDate === null ? requiredValidation('', props.errorLabels ? props.errorLabels : {}) : '',
    });
  };

  const onMultipleTextFieldChange = (index: number, event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    onChange(event);
  };

  const onCountryChange = (newValue: string) => {
    setCountryIdType(newValue);
  };

  /**
   * Premium reduction is only visible
   * when parents aren't married
   */
  const isPremiumReductionVisible = () => {
    return !parentsMarried;
  };

  const isPremiumReductionAmountVisible = () => {
    return isPremiumReductionVisible() && premiumReductionReceived === '1';
  };

  const onPremiumReductionReceivedChange = (newValue: string) => {
    setPremiumReductionReceived(newValue);
    setErrors({
      ...errors,
      premiumReductionReceived: isPremiumReductionVisible()
        ? requiredValidation(newValue, props.errorLabels ?? {})
        : '',
    });
  };

  const onPremiumReductionAmountChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const newValue = event.target.value;
    setPremiumReductionAmount(newValue);
    setErrors({
      ...errors,
      premiumReductionAmount: isPremiumReductionAmountVisible()
        ? minMaxValueValidation(newValue, 10, 9999999, props.errorLabels ? props.errorLabels : {})
        : '',
    });
  };

  const onClickPensionReceived = (newValue: string) => {
    setPensionReceived(newValue);
    if (newValue !== '1') {
      setAdditionalAssistanceReceived(newValue);
      setAdditionalAssistanceAmount('');
    }
    setErrors({
      ...errors,
      pensionReceived: requiredValidation(newValue, props.errorLabels ? props.errorLabels : {}),
    });
  };

  const onClickAdditionalAssistanceReceived = (newValue: string) => {
    setAdditionalAssistanceReceived(newValue);
    setErrors({
      ...errors,
      additionalAssistanceReceived: requiredValidation(newValue, props.errorLabels ? props.errorLabels : {}),
    });
  };

  const onClickAlimonyReceived = (newValue: string) => {
    setAlimonyReceived(newValue);
    setErrors({
      ...errors,
      alimonyReceived: requiredValidation(newValue, props.errorLabels ? props.errorLabels : {}),
    });
  };

  const normalizedDateOfBirth = setTimeToMiddleOfDay(dateOfBirth);
  const getMotherData = (): MotherData => {
    return {
      firstName: firstName,
      lastName: lastName,
      ahvNumber: ahvn13 ? ahvn13 : undefined,
      dateOfBirth: normalizedDateOfBirth ? normalizedDateOfBirth.toISOString() : '',
      address: street,
      houseNumber: streetNumber,
      zipCode: zipCode,
      town: town,
      countryId: parseInt(countryIdType),
      profession: profession ? profession : undefined,
      workTimeQuota: worktimeQuota ? Number(worktimeQuota) : undefined,
      phoneNumberPrivate: phoneNumberPrivate ? phoneNumberPrivate : undefined,
      pensionReceived: pensionReceived === '1',
      pensionAmount: pensionAmount ? parseInt(pensionAmount) : undefined,
      additionalAssistanceReceived: additionalAssistanceReceived === '1',
      additionalAssistanceAmount: additionalAssistanceAmount ? Number(additionalAssistanceAmount) : undefined,
      premiumReductionReceived: premiumReductionReceived === '1',
      premiumReductionAmount: premiumReductionAmount ? Number(premiumReductionAmount) : undefined,
      alimonyPaidToPartner: alimonyReceived ? alimonyReceived === '1' : undefined,
      alimonyPaidAmount: alimonyReceivedAmount ? Number(alimonyReceivedAmount) : undefined,
      firstNameStepFather: firstNameStepFather ? firstNameStepFather : undefined,
      lastNameStepFather: lastNameStepFather ? lastNameStepFather : undefined,
      ahvNbrStepFather: ahvn13StepFather ? ahvn13StepFather : undefined,
    };
  };

  const getFormErrors = (): MotherDataErrors => {
    const errors: MotherDataErrors = {};
    errors.firstName = requiredValidation(firstName, props.errorLabels ? props.errorLabels : {});
    errors.lastName = requiredValidation(lastName, props.errorLabels ? props.errorLabels : {});
    errors.ahvn13 = ahvn13validation(ahvn13, props.errorLabels ? props.errorLabels : {});
    errors.dateOfBirth = requiredValidation(
      dateOfBirth ? dateOfBirth.toDateString() : '',
      props.errorLabels ? props.errorLabels : {},
    );
    errors.street = requiredValidation(street, props.errorLabels ? props.errorLabels : {});
    errors.streetNumber = requiredValidation(streetNumber, props.errorLabels ? props.errorLabels : {});
    errors.zipCode = requiredValidation(zipCode, props.errorLabels ? props.errorLabels : {});
    errors.town = requiredValidation(town, props.errorLabels ? props.errorLabels : {});
    errors.profession = requiredValidation(profession, props.errorLabels ? props.errorLabels : {});
    if (worktimeQuota !== '') {
      errors.worktimeQuota = maxValueValidation(worktimeQuota, 100, props.errorLabels ? props.errorLabels : {});
    }
    errors.pensionReceived = requiredValidation(pensionReceived, props.errorLabels ? props.errorLabels : {});
    if (pensionReceived === '1') {
      errors.pensionAmount = maxValueValidation(pensionAmount, 9999999, props.errorLabels ? props.errorLabels : {});
    }
    errors.additionalAssistanceReceived = requiredValidation(
      additionalAssistanceReceived,
      props.errorLabels ? props.errorLabels : {},
    );
    if (additionalAssistanceReceived === '1') {
      errors.additionalAssistanceAmount = maxValueValidation(
        additionalAssistanceAmount,
        9999999,
        props.errorLabels ? props.errorLabels : {},
      );
    }
    errors.alimonyReceived = requiredValidation(alimonyReceived, props.errorLabels ? props.errorLabels : {});
    if (alimonyReceived === '1') {
      errors.alimonyReceivedAmount = maxValueValidation(
        alimonyReceivedAmount,
        9999999,
        props.errorLabels ? props.errorLabels : {},
      );
    }
    if (ahvn13StepFather !== '') {
      errors.ahvn13StepFather = ahvn13validation(ahvn13StepFather, props.errorLabels ? props.errorLabels : {});
    }
    if (isPremiumReductionVisible()) {
      errors.premiumReductionReceived = requiredValidation(
        premiumReductionReceived,
        props.errorLabels ? props.errorLabels : {},
      );
    }
    if (isPremiumReductionAmountVisible()) {
      errors.premiumReductionAmount = minMaxValueValidation(
        premiumReductionAmount,
        10,
        9999999,
        props.errorLabels ? props.errorLabels : {},
      );
    }
    return errors;
  };

  const validateForm = (): boolean => {
    const newErrors = getFormErrors();
    setErrors(newErrors);
    const errorsPresent = Object.values(newErrors).some((value) => value !== '');
    return !errorsPresent;
  };

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    if (validateForm() && props.dispatch) {
      props.dispatch({
        type: MotherDataAction.SUBMIT,
        payload: getMotherData(),
        pifId: props.pifId,
      });
    }
    event.preventDefault();
  };

  return (
    <>
      <form onSubmit={onSubmit}>
        <div className="form-element-container">
          <ZoomTextInput
            label={props.labelFirstName ? props.labelFirstName : ''}
            name="firstName"
            value={firstName}
            onChange={onChange}
            error={!!errors.firstName}
            errorMessage={errors.firstName}
          ></ZoomTextInput>
        </div>
        <div className="form-element-container">
          <ZoomTextInput
            label={props.labelLastName ? props.labelLastName : ''}
            name="lastName"
            value={lastName}
            onChange={onChange}
            error={!!errors.lastName}
            errorMessage={errors.lastName}
          ></ZoomTextInput>
        </div>
        <div className="form-element-container">
          <ZoomTextInput
            label={props.labelAhvn13 ? props.labelAhvn13 : ''}
            value={ahvn13}
            name="ahvn13"
            onChange={onChange}
            error={!!errors.ahvn13}
            errorMessage={errors.ahvn13 ? errors.ahvn13 : undefined}
            helpText={props.helpTextAhvn13}
          ></ZoomTextInput>
        </div>
        <div className="form-element-container">
          <ZoomDatePicker
            label={props.labelDateOfBirth ? props.labelDateOfBirth : ''}
            name="dateOfBirth"
            onChange={onChangeDate}
            value={dateOfBirth}
            error={!!errors.dateOfBirth}
            errorMessage={errors.dateOfBirth}
          ></ZoomDatePicker>
        </div>
        <div className="form-element-container">
          <ZoomMultipleTextInput
            data={[
              {
                label: props.labelStreet ? props.labelStreet : '',
                value: street,
                gridSize: 9,
                name: 'street',
                error: !!errors.street,
                errorMessage: errors.street,
                onChange: onMultipleTextFieldChange,
              },
              {
                label: props.labelStreetNumber ? props.labelStreetNumber : '',
                value: streetNumber,
                gridSize: 3,
                name: 'streetNumber',
                error: !!errors.streetNumber,
                errorMessage: errors.streetNumber,
                onChange: onMultipleTextFieldChange,
              },
            ]}
            helpText={props.helptextStreetAndStreetNumber}
          ></ZoomMultipleTextInput>
        </div>
        <div className="form-element-container">
          <ZoomMultipleTextInput
            data={[
              {
                label: props.labelZipCode ? props.labelZipCode : '',
                value: zipCode,
                gridSize: 3,
                name: 'zipCode',
                error: !!errors.zipCode,
                errorMessage: errors.zipCode,
                onChange: onMultipleTextFieldChange,
              },
              {
                label: props.labelTown ? props.labelTown : '',
                value: town,
                gridSize: 9,
                name: 'town',
                error: !!errors.town,
                errorMessage: errors.town,
                onChange: onMultipleTextFieldChange,
              },
            ]}
            helpText={props.helptextZipCodeAndTown}
          ></ZoomMultipleTextInput>
        </div>
        <div className="form-element-container">
          <ZoomSelectInput
            label={props.labelCountryIdType ? props.labelCountryIdType : ''}
            options={props.countryOptions ? props.countryOptions : []}
            value={countryIdType}
            name="countryIdType"
            onChange={onCountryChange}
            helpText={props.helpTextCountry}
          ></ZoomSelectInput>
        </div>
        <div className="form-element-container">
          <ZoomTextInput
            label={props.labelProfession ? props.labelProfession : ''}
            name="profession"
            value={profession}
            onChange={onChange}
            error={!!errors.profession}
            errorMessage={errors.profession}
          ></ZoomTextInput>
        </div>
        <div className="form-element-container">
          <ZoomTextInput
            label={props.labelWorktimeQuota ? props.labelWorktimeQuota : ''}
            name="worktimeQuota"
            value={worktimeQuota}
            onChange={onChange}
            error={!!errors.worktimeQuota}
            errorMessage={errors.worktimeQuota}
          ></ZoomTextInput>
        </div>
        <div className="form-element-container">
          <ZoomTextInput
            label={props.labelPhoneNumberPrivate ? props.labelPhoneNumberPrivate : ''}
            name="phoneNumberPrivate"
            value={phoneNumberPrivate}
            onChange={onChange}
            error={!!errors.phoneNumberPrivate}
            errorMessage={errors.phoneNumberPrivate}
          ></ZoomTextInput>
        </div>
        <div className="form-element-container">
          <ZoomToggleInput
            value={pensionReceived}
            title={props.labelPensionReceived ? props.labelPensionReceived : ''}
            label1={props.toggleButtonsTypes ? props.toggleButtonsTypes[0].name : ''}
            value1={props.toggleButtonsTypes ? props.toggleButtonsTypes[0].value.toString() : ''}
            label2={props.toggleButtonsTypes ? props.toggleButtonsTypes[1].name : ''}
            value2={props.toggleButtonsTypes ? props.toggleButtonsTypes[1].value.toString() : ''}
            onChange={onClickPensionReceived}
          ></ZoomToggleInput>
        </div>
        {pensionReceived === '1' && (
          <div className="form-element-container">
            <ZoomTextInput
              label={props.labelPensionAmount ? props.labelPensionAmount : ''}
              name="pensionAmount"
              value={pensionAmount}
              onChange={onChange}
              error={!!errors.pensionAmount}
              errorMessage={errors.pensionAmount}
            ></ZoomTextInput>
          </div>
        )}
        {pensionReceived === '1' && (
          <div className="form-element-container">
            <ZoomToggleInput
              value={additionalAssistanceReceived}
              title={props.labelAdditionalAssistanceReceived ? props.labelAdditionalAssistanceReceived : ''}
              label1={props.toggleButtonsTypes ? props.toggleButtonsTypes[0].name : ''}
              value1={props.toggleButtonsTypes ? props.toggleButtonsTypes[0].value.toString() : ''}
              label2={props.toggleButtonsTypes ? props.toggleButtonsTypes[1].name : ''}
              value2={props.toggleButtonsTypes ? props.toggleButtonsTypes[1].value.toString() : ''}
              onChange={onClickAdditionalAssistanceReceived}
            ></ZoomToggleInput>
          </div>
        )}
        {pensionReceived === '1' && additionalAssistanceReceived === '1' && (
          <div className="form-element-container">
            <ZoomTextInput
              label={props.labelAdditionalAssistanceAmount ? props.labelAdditionalAssistanceAmount : ''}
              name="additionalAssistanceAmount"
              value={additionalAssistanceAmount}
              onChange={onChange}
              error={!!errors.additionalAssistanceAmount}
              errorMessage={errors.additionalAssistanceAmount}
            ></ZoomTextInput>
          </div>
        )}
        {props.parentsSeparated && (
          <div className="form-element-container">
            <ZoomToggleInput
              value={alimonyReceived}
              title={props.labelAlimonyReceived ? props.labelAlimonyReceived : ''}
              label1={props.toggleButtonsTypes ? props.toggleButtonsTypes[0].name : ''}
              value1={props.toggleButtonsTypes ? props.toggleButtonsTypes[0].value.toString() : ''}
              label2={props.toggleButtonsTypes ? props.toggleButtonsTypes[1].name : ''}
              value2={props.toggleButtonsTypes ? props.toggleButtonsTypes[1].value.toString() : ''}
              onChange={onClickAlimonyReceived}
            ></ZoomToggleInput>
          </div>
        )}
        {props.parentsSeparated && alimonyReceived === '1' && (
          <div className="form-element-container">
            <ZoomTextInput
              label={props.labelAlimonyReceivedAmount ? props.labelAlimonyReceivedAmount : ''}
              name="alimonyReceivedAmount"
              value={alimonyReceivedAmount}
              onChange={onChange}
              error={!!errors.alimonyReceivedAmount}
              errorMessage={errors.alimonyReceivedAmount}
            ></ZoomTextInput>
          </div>
        )}
        {props.motherCivilStatus && (
          <div className="form-element-container">
            <ZoomTextInput
              label={props.labelFirstNameStepFather ? props.labelFirstNameStepFather : ''}
              name="firstNameStepFather"
              value={firstNameStepFather}
              onChange={onChange}
              error={!!errors.firstNameStepFather}
              errorMessage={errors.firstNameStepFather}
            ></ZoomTextInput>
          </div>
        )}
        {props.motherCivilStatus && (
          <div className="form-element-container">
            <ZoomTextInput
              label={props.labelLastNameStepFather ? props.labelLastNameStepFather : ''}
              name="lastNameStepFather"
              value={lastNameStepFather}
              onChange={onChange}
              error={!!errors.lastNameStepFather}
              errorMessage={errors.lastNameStepFather}
            ></ZoomTextInput>
          </div>
        )}
        {props.motherCivilStatus && (
          <div className="form-element-container">
            <ZoomTextInput
              label={props.labelAhvn13StepFather ? props.labelAhvn13StepFather : ''}
              value={ahvn13StepFather}
              name="ahvn13StepFather"
              onChange={onChange}
              error={!!errors.ahvn13StepFather}
              errorMessage={errors.ahvn13StepFather ? errors.ahvn13StepFather : undefined}
              helpText={props.helpTextAhvn13Father}
            ></ZoomTextInput>
          </div>
        )}
        {isPremiumReductionVisible() && (
          <div className="form-element-container">
            <ZoomToggleInput
              name="premiumReductionReceived"
              title={props.labelPremiumReductionReceived}
              value={props.premiumReductionReceived}
              label1={props.toggleButtonsTypes ? props.toggleButtonsTypes[0].name : ''}
              value1={props.toggleButtonsTypes ? props.toggleButtonsTypes[0].value.toString() : ''}
              label2={props.toggleButtonsTypes ? props.toggleButtonsTypes[1].name : ''}
              value2={props.toggleButtonsTypes ? props.toggleButtonsTypes[1].value.toString() : ''}
              onChange={onPremiumReductionReceivedChange}
            ></ZoomToggleInput>
          </div>
        )}
        {isPremiumReductionAmountVisible() && (
          <div className="form-element-container">
            <ZoomTextInput
              name="premiumReductionAmount"
              label={props.labelPremiumReductionAmount}
              onChange={onPremiumReductionAmountChange}
              value={premiumReductionAmount}
              error={!!errors.premiumReductionAmount}
              errorMessage={errors.premiumReductionAmount}
            ></ZoomTextInput>
          </div>
        )}
        <div className="form-actions-container">
          <Button color="primary" type="submit" variant="contained" id="submit-mother-data">
            {props.labelSave}
          </Button>
        </div>
      </form>
    </>
  );
};

// Map global store to component parameters
// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export function mapStateToMotherProps(store: WIAAStore, ownProps: any): MotherDataFormProps {
  const { pifId } = ownProps.match.params;
  const props: MotherDataFormProps = {
    labelPremiumReductionReceived: i18nLabel(
      store.translationLabels,
      'Mother_Premium_Reduction_Received',
      'Erhält Ihre Mutter Prämienverbillingungen?*',
    ),
    labelPremiumReductionAmount: i18nLabel(
      store.translationLabels,
      'Mother_Premium_Reduction_Amount',
      'Betrag pro Jahr Fr.*',
    ),
    labelFirstName: i18nLabel(store.translationLabels, 'Mother_First_Name', 'Vorname*'),
    labelLastName: i18nLabel(store.translationLabels, 'Mother_Last_Name', 'Nachname*'),
    labelAhvn13: i18nLabel(store.translationLabels, 'Mother_Ahvn', 'AHV-Nummer*'),
    labelDateOfBirth: i18nLabel(store.translationLabels, 'Mother_Date_Of_Birth', 'Geburtsdatum*'),
    labelStreet: i18nLabel(store.translationLabels, 'Mother_Street', 'Strasse'),
    labelStreetNumber: i18nLabel(store.translationLabels, 'Mother_Street_Number', 'Nr.*'),
    labelZipCode: i18nLabel(store.translationLabels, 'Mother_Zip_Code', 'PLZ'),
    labelTown: i18nLabel(store.translationLabels, 'Mother_Town', 'Ort*'),
    labelCountryIdType: i18nLabel(store.translationLabels, 'Mother_Country_Id_Type', 'Land*'),
    labelProfession: i18nLabel(store.translationLabels, 'Mother_Profession', 'Beruf*'),
    labelWorktimeQuota: i18nLabel(store.translationLabels, 'Mother_Worktike_Quota', 'Arbeitspensum (%)'),
    labelPhoneNumberPrivate: i18nLabel(store.translationLabels, 'Mother_Phone_Number_Private', 'Telefonnummer'),
    labelPensionReceived: i18nLabel(store.translationLabels, 'Mother_Pension_Received', 'Erhält Ihr Mutter Renten?*'),
    labelPensionAmount: i18nLabel(store.translationLabels, 'Mother_Pension_Amount', 'Betrag pro Jahr*'),
    labelAdditionalAssistanceReceived: i18nLabel(
      store.translationLabels,
      'Mother_Additional_Assistance_Received',
      'Erhält Ihr Mutter Ergänzugsleistungen?*',
    ),
    labelAdditionalAssistanceAmount: i18nLabel(
      store.translationLabels,
      'Mother_Additional_Assistance_Amount',
      'Betrag pro Jahr*',
    ),
    labelAlimonyReceived: i18nLabel(
      store.translationLabels,
      'Mother_Alimony_Received',
      'Erhält Ihr Mutter Unterhaltsbeiträge?',
    ),
    labelAlimonyReceivedAmount: i18nLabel(
      store.translationLabels,
      'Mother_Alimony_Received_Amount',
      'Betrag pro Jahr*',
    ),
    labelFirstNameStepFather: i18nLabel(
      store.translationLabels,
      'Mother_First_Name_Step_Father',
      'Vorname Stiefvater*',
    ),
    labelLastNameStepFather: i18nLabel(store.translationLabels, 'Mother_Last_Name_Step_Father', 'Nachname Stiefvater*'),
    labelAhvn13StepFather: i18nLabel(store.translationLabels, 'Mother_Ahvn_Step_Father', 'AHV-Nummer Stiefvater*'),
    labelSave: i18nLabel(store.translationLabels, 'Mother_Save', 'Speichern'),
    helpTextAhvn13: i18nLabel(
      store.translationLabels,
      'Mother_Ahvn_Help_Text',
      'Die 13-stellige Sozialversicherungsnummer ist auf der Versicherungskarte der Krankenkasse oder auf dem Sozialversicherungs-Ausweis ersichtlich. Sollte Ihnen diese Nummer nicht bekannt sein, geben Sie bitte 756.0000.0000.00 ein.',
    ),
    helptextStreetAndStreetNumber: i18nLabel(
      store.translationLabels,
      'Mother_Street_And_Street_Number_Help_Text',
      'Wenn Strasse nicht bekannt, "unbekannt" eingeben.',
    ),
    helptextZipCodeAndTown: i18nLabel(
      store.translationLabels,
      'Mother_Zip_Code_And_Town_Help_Text',
      'Wenn PLZ nicht bekannt, dann "?" eingeben. Wenn Ort nicht bekannt, dann "unbekannt" angeben.',
    ),
    helpTextCountry: i18nLabel(
      store.translationLabels,
      'Mother_Country_Help_Text',
      'Bitte geben Sie den Wohnsitzstaat Ihres Mutters an.',
    ),
    helpTextAhvn13Father: i18nLabel(
      store.translationLabels,
      'Mother_Ahvn_Step_Father_Help_Text',
      'Die 13-stellige Sozialversicherungsnummer ist auf der Versicherungskarte der Krankenkasse oder auf dem Sozialversicherungs-Ausweis ersichtlich. Sollte Ihnen diese Nummer nicht bekannt sein, geben Sie bitte 756.0000.0000.00 ein.',
    ),
    errorLabels: store.translationLabels,
    pifId: Number(pifId),
    countryOptions: store.translationOptions.nationalities
      ? [
          ...store.translationOptions.nationalities.map((option) => {
            return { value: option.id.toString(), name: option.name };
          }),
        ]
      : [],
    toggleButtonsTypes: store.translationOptions['toggle-buttons']
      ? [
          ...store.translationOptions['toggle-buttons'].map((option) => {
            return { value: option.id.toString(), name: option.name };
          }),
        ]
      : [],
  };
  const data: MotherData = store.motherData;
  if (data) {
    props.firstName = data.firstName;
    props.lastName = data.lastName;
    props.ahvn13 = data.ahvNumber;
    props.dateOfBirth = data.dateOfBirth ? new Date(data.dateOfBirth) : undefined;
    props.street = data.address;
    props.streetNumber = data.houseNumber;
    props.zipCode = data.zipCode;
    props.town = data.town;
    props.countryIdType = data.countryId ? data.countryId.toString() : '';
    props.profession = data.profession;
    props.worktimeQuota = data.workTimeQuota ? data.workTimeQuota.toString() : '';
    props.phoneNumberPrivate = data.phoneNumberPrivate;
    props.pensionReceived = data.pensionReceived ? '1' : '2';
    props.pensionAmount = data.pensionAmount ? data.pensionAmount.toString() : '';
    props.additionalAssistanceReceived = data.additionalAssistanceReceived ? '1' : '2';
    props.additionalAssistanceAmount = data.additionalAssistanceAmount
      ? data.additionalAssistanceAmount.toString()
      : '';
    props.alimonyReceived = data.alimonyPaidToPartner ? (data.alimonyPaidToPartner ? '1' : '2') : undefined;
    props.alimonyReceivedAmount = data.alimonyPaidAmount ? data.alimonyPaidAmount.toString() : '';
    props.firstNameStepFather = data.firstNameStepFather;
    props.lastNameStepFather = data.lastNameStepFather;
    props.ahvn13StepFather = data.ahvNbrStepFather;
    props.motherCivilStatus = store.parentsData.motherMaritalStatus === 50;
    props.parentsMarried = store.parentsData.typeOfRelationShip === 20;
    props.parentsSeparated = store.parentsData.typeOfRelationShip === 41 || store.parentsData.typeOfRelationShip === 42;
    props.premiumReductionReceived = extractToggleValue(data.premiumReductionReceived);
    props.premiumReductionAmount = data.premiumReductionAmount ? data.premiumReductionAmount.toString() : '';
  }
  return props;
}

export default withRouter(connect(mapStateToMotherProps)(MotherDataForm));
