import produce from 'immer';
import { MyDataOverviewPageAction, MyDataOverviewPageActionType } from './actions';
import { MyDataOverviewPage } from './types';

export const initialState: MyDataOverviewPage = {};

export function myDataOverviewPageReducer(
  state = initialState,
  action: MyDataOverviewPageActionType,
): MyDataOverviewPage {
  return produce(state, (draft) => {
    switch (action.type) {
      case MyDataOverviewPageAction.GET:
        draft.isLoading = true;
        break;
      case MyDataOverviewPageAction.RESULT:
        draft = action.payload;
        break;
      case MyDataOverviewPageAction.GET_RESULT:
        draft = action.payload;
        draft.isLoading = false;
        break;
    }
    return draft;
  });
}
