import produce from 'immer';
import { HolderDataAction, HolderDataGetResultAction } from '../holder-data/actions';
import { PowerOfAttorneyDataAction, PowerOfAttorneyDataActionType } from './actions';
import { MandatoryPowerOfAttorneyDataStore } from './types';

export const initialState: MandatoryPowerOfAttorneyDataStore = {
  authorities: [],
};

export function mandatoryPowerOfAttorneyDataReducer(
  state = initialState,
  action: PowerOfAttorneyDataActionType | HolderDataGetResultAction,
): MandatoryPowerOfAttorneyDataStore {
  return produce(state, (draft) => {
    switch (action.type) {
      case PowerOfAttorneyDataAction.GET_RESULT:
        draft.authorities = action.payload;
        break;
      case PowerOfAttorneyDataAction.ADD_RESULT:
        const newPOAData = action.payload;
        const authorities = state.authorities.slice();
        authorities.push(newPOAData);
        draft.authorities = authorities;
        break;
      case PowerOfAttorneyDataAction.DELETE_RESULT:
        const authEmail = action.email;
        draft.authorities = state.authorities.filter((auth) => auth.email !== authEmail);
        break;
      case HolderDataAction.GET_RESULT:
        const holderData = action;
        const activeHolder = holderData.payload.find((holder) => holder.active);
        if (activeHolder) {
          draft.dossierId = activeHolder.dossierId;
        }
        break;
      case PowerOfAttorneyDataAction.ADD_ERROR:
        const addItemErrorAction = action;
        draft.error = addItemErrorAction.payload;
        break;
      case PowerOfAttorneyDataAction.DELETE_ERROR:
        const poaDeleteErrorAction = action;
        draft.error = poaDeleteErrorAction.payload;
        break;
      case PowerOfAttorneyDataAction.HIDE_ERROR:
        draft.error = undefined;
        break;
    }
    return draft;
  });
}
