import produce from 'immer';
import { LastDemandDataTakeoverAction, LastDemandDataType } from '../last-demand-data-takeover/actions';
import { ContactDataAction, PersonalDataAction, PersonalDataActionType } from './actions';
import { PersonalData } from './types';
import { exctractNonNullPropertiesFromObject } from './../../utils/object-manipulation';

export const initialState: PersonalData = {};

export const getOnlyPersonalData = (data: PersonalData): PersonalData => {
  return {
    firstName: data.firstName,
    lastName: data.lastName,
    titleId: data.titleId,
    maritalStatus: data.maritalStatus,
    homeTown: data.homeTown,
    existingChildren: data.existingChildren,
    existingSiblings: data.existingSiblings,
    custodyId: data.custodyId,
    ahvNumber: data.ahvNumber,
    nationalityId: data.nationalityId,
    permitCategoryId: data.permitCategoryId,
    inCountrySince: data.inCountrySince,
    dateOfBirth: data.dateOfBirth,
  };
};

export const getOnlyContactData = (data: PersonalData): PersonalData => {
  return {
    livingType: data.livingType,
    address: data.address,
    houseNumber: data.houseNumber,
    zipCode: data.zipCode,
    emailAddress: data.emailAddress,
    town: data.town,
    phoneNumberPrivate: data.phoneNumberPrivate,
  };
};

export function personalDataReducer(
  state = initialState,
  action: PersonalDataActionType | LastDemandDataType,
): PersonalData {
  return produce(state, (draft) => {
    switch (action.type) {
      case PersonalDataAction.CHANGE:
        draft = { ...state, ...getOnlyPersonalData(action.payload) };
        break;
      case PersonalDataAction.RESULT:
        draft = { ...state, ...getOnlyPersonalData(action.payload) };
        break;
      case PersonalDataAction.GET_RESULT:
        draft = { ...state, ...getOnlyPersonalData(action.payload) };
        if (draft.homeTown === null && state.homeTown) {
          draft.homeTown = state.homeTown;
        }
        break;
      case ContactDataAction.CHANGE:
        draft = { ...state, ...getOnlyContactData(action.payload) };
        break;
      case ContactDataAction.RESULT:
        const resultAction = action;
        if (resultAction.payload) {
          draft = { ...state, ...getOnlyContactData(resultAction.payload) };
        }
        break;
      case ContactDataAction.GET_RESULT:
        const result = action;
        if (result.payload) {
          const notNullProps = exctractNonNullPropertiesFromObject(getOnlyContactData(result.payload));
          draft = { ...draft, ...notNullProps };
        }
        break;
      case ContactDataAction.GET_POSTAL_CODES:
        draft = { ...state, ...action.payload };
        break;
      case ContactDataAction.GET_POSTAL_CODES_RESULT:
        const postalCode = action.payload;
        draft = { ...state, zipCode: postalCode.zipCode, town: postalCode.extendedName };
        break;
      case LastDemandDataTakeoverAction.RESULT:
        const lastDemandData = action.payload;
        draft = lastDemandData.pif;
        break;
    }
    return draft;
  });
}
