import React, { ReactElement } from 'react';
import { blue, green, lightGrey } from './../../../../theme';
import ZoomPieChart from './../../pie-chart/ZoomPieChart';
import './ZoomPayoutItem.scss';

export interface ZoomPayoutItemProps {
  name: string;
  status: string;
  labelLegendName: string;
  institution: string;
  isLoan: boolean;
  amount: number;
  totalAmount: number;
  labelAmount: string;
  labelAccount: string;
  accountHolder: string;
  accountAddress: string;
  iban: string;
  transferDate: string;
  labelTransferDate: string;
  labelChangeAccount: string;
  borderRight?: boolean;
}

const ZoomPayoutItem = (props: ZoomPayoutItemProps): ReactElement => {
  const colorStyle = props.isLoan ? 'bg-green' : 'bg-blue';
  const percentage = Math.round((props.amount / props.totalAmount) * 100);
  const borderStyle = props.borderRight ? { borderRight: '1px solid ' + lightGrey.main } : {};
  const filledAreaColor = props.isLoan ? green.main : blue.main;

  return (
    <div className="payout-info-container" style={borderStyle}>
      <div className="payout-info-content">
        <h2>{props.name}</h2>
        <p> {props.institution} </p>
      </div>
      <div className="payout-info-chart-container">
        <div className="payout-info-chart">
          <ZoomPieChart
            size={180}
            strokeWidth={4}
            filledAreaPercentage={percentage}
            filledAreaColor={filledAreaColor}
            restAreaColor={lightGrey.main}
            amountToDisplay={props.totalAmount.toString()}
          ></ZoomPieChart>
        </div>
      </div>
      <div className="payout-info-legend">
        <p>
          <span className={'color-sample ' + colorStyle}></span>
          {props.labelLegendName}
        </p>
        <p>
          <span className="color-sample bg-grey"></span>
          {props.status}
        </p>
      </div>
    </div>
  );
};

export default ZoomPayoutItem;
