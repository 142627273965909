import React, { ChangeEvent, ReactElement, useEffect, useState } from 'react';
import { requiredValidation, emailValidation, postCodeValidation } from './../../utils/validators';
import './ContactDataForm.scss';
import ZoomSelectInput from './../../components/forms/select-input/ZoomSelectInput';
import ZoomMultipleTextInput from './../../components/forms/multiple-text-input/ZoomMultipleTextInput';
import ZoomTextInput from './../../components/forms/text-input/ZoomTextInput';
import { Button } from '@material-ui/core';
import { ContactDataAction, ContactDataActionType, ContactDataChangeAction } from '../../store/personal-data/actions';
import { connect } from 'react-redux';
import { PersonalData } from '../../store/personal-data/types';
import { WIAAStore } from '../../store/types';
import { withRouter } from 'react-router-dom';
import { i18nLabel } from '../../store/i18n/helpers';
import { getDefaultSelectOption } from '../../utils/form-data';

export interface ContactDataFormProps {
  id?: string;
  residencePlace?: string;
  addressStreet?: string;
  addressNumber?: string;
  postCode?: string;
  city?: string;
  country?: string;
  phoneNumber?: string;
  email?: string;
  demandId: number;
  labelResidencePlace?: string;
  labelAddressStreet?: string;
  labelAddressNumber?: string;
  labelPostCode?: string;
  labelCity?: string;
  labelCountry?: string;
  labelPhoneNumber?: string;
  labelEmail?: string;
  labelSave?: string;
  helpTextResidencePLace?: string;
  helpTextCountry?: string;
  errorLabels?: { [key: string]: string };
  dispatch?: (action: ContactDataActionType) => void;
  custodiesOptions?: Array<{ name: string; value: string }>;
  nationalitiesOptions?: Array<{ name: string; value: string }>;
}

interface ContactDataErrors {
  id?: string;
  residencePlace?: string;
  addressStreet?: string;
  addressNumber?: string;
  postCode?: string;
  city?: string;
  country?: string;
  phoneNumber?: string;
  email?: string;
}

export const ContactDataForm = (props: ContactDataFormProps): ReactElement => {
  const [residencePlace, setResidencePlace] = useState(props.residencePlace ? props.residencePlace : '9');
  const [addressStreet, setAddressStreet] = useState(props.addressStreet ? props.addressStreet : '');
  const [addressNumber, setAddressNumber] = useState(props.addressNumber ? props.addressNumber : '');
  const [postCode, setPostCode] = useState(props.postCode ? props.postCode : '');
  const [city, setCity] = useState(props.city ? props.city : '');
  const [country, setCountry] = useState(props.country ? props.country : '8100');
  const [phoneNumber, setPhoneNumber] = useState(props.phoneNumber ? props.phoneNumber : '');
  const [email, setEmail] = useState(props.email ? props.email : '');
  const initialErrors: ContactDataErrors = {};
  const [errors, setErrors] = useState(initialErrors);

  useEffect(() => {
    setAddressStreet(props.addressStreet ? props.addressStreet : '');
    setCity(props.city ? props.city : '');
    setResidencePlace(props.residencePlace ? props.residencePlace : '9');
    setAddressNumber(props.addressNumber ? props.addressNumber : '');
    setPostCode(props.postCode ? props.postCode : '');
    setCountry(props.country ? props.country : '8100');
    setPhoneNumber(props.phoneNumber ? props.phoneNumber : '');
    setEmail(props.email ? props.email : '');
  }, [props.city, props.addressStreet, props.postCode]);

  const triggerOnChange = (newData: PersonalData) => {
    if (props.dispatch) {
      const changeAction: ContactDataChangeAction = {
        type: ContactDataAction.CHANGE,
        payload: getFullContactData(newData),
      };
      props.dispatch(changeAction);
    }
  };

  const getFullContactData = (newData: PersonalData): PersonalData => {
    const oldData = getContactData();
    return { ...oldData, ...newData };
  };

  const onChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    switch (e.target.name) {
      case 'addressStreet':
        setAddressStreet(e.target.value);
        setErrors({
          ...errors,
          addressStreet: requiredValidation(e.target.value, props.errorLabels ? props.errorLabels : {}),
        });
        triggerOnChange({ address: e.target.value });
        break;
      case 'addressNumber':
        setAddressNumber(e.target.value);
        setErrors({
          ...errors,
          addressNumber: requiredValidation(e.target.value, props.errorLabels ? props.errorLabels : {}),
        });
        triggerOnChange({ houseNumber: e.target.value });
        break;
      case 'postCode':
        const fieldValue = e.target.value;

        if (fieldValue !== postCode) {
          setPostCode(fieldValue);
          const postalCodeError = postCodeValidation(fieldValue, props.errorLabels ? props.errorLabels : {});
          setErrors({ ...errors, postCode: postalCodeError });

          if (props.dispatch && !!!postalCodeError) {
            const oldContactData = getContactData();

            props.dispatch({
              type: ContactDataAction.GET_POSTAL_CODES,
              payload: { ...oldContactData, zipCode: fieldValue },
            });
          }
        }
        triggerOnChange({ zipCode: e.target.value });
        break;
      case 'city':
        setCity(e.target.value);
        setErrors({ ...errors, city: requiredValidation(e.target.value, props.errorLabels ? props.errorLabels : {}) });
        triggerOnChange({ town: e.target.value });
        break;
      case 'phoneNumber':
        setPhoneNumber(e.target.value);
        setErrors({
          ...errors,
          phoneNumber: requiredValidation(e.target.value, props.errorLabels ? props.errorLabels : {}),
        });
        triggerOnChange({ phoneNumberPrivate: e.target.value });
        break;
      case 'email':
        setEmail(e.target.value);
        setErrors({ ...errors, email: emailValidation(e.target.value, props.errorLabels ? props.errorLabels : {}) });
        triggerOnChange({ emailAddress: e.target.value });
        break;
    }
  };

  const onMultipleTextFieldChange = (index: number, event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    onChange(event);
  };

  const onResidencePlaceChange = (newValue: string) => {
    setResidencePlace(newValue);
    setErrors({
      ...errors,
      residencePlace: newValue === '9' ? requiredValidation('', props.errorLabels ? props.errorLabels : {}) : '',
    });
    triggerOnChange({ livingType: parseInt(newValue) });
  };

  const onCountryChange = (newValue: string) => {
    setCountry(newValue);
  };

  const getContactData = (): PersonalData => {
    return {
      address: addressStreet,
      houseNumber: addressNumber,
      zipCode: postCode,
      town: city,
      countryId: parseInt(country),
      phoneNumberPrivate: phoneNumber,
      emailAddress: email,
      livingType: parseInt(residencePlace),
    };
  };

  const getFormErrors = (): ContactDataErrors => {
    const errors: ContactDataErrors = {};
    errors.residencePlace =
      residencePlace === '9' ? requiredValidation('', props.errorLabels ? props.errorLabels : {}) : '';
    errors.addressStreet = requiredValidation(addressStreet, props.errorLabels ? props.errorLabels : {});
    errors.addressNumber = requiredValidation(addressNumber, props.errorLabels ? props.errorLabels : {});
    errors.postCode = requiredValidation(postCode, props.errorLabels ? props.errorLabels : {});
    errors.city = requiredValidation(city, props.errorLabels ? props.errorLabels : {});
    errors.country = requiredValidation(country, props.errorLabels ? props.errorLabels : {});
    errors.phoneNumber = requiredValidation(phoneNumber, props.errorLabels ? props.errorLabels : {});
    errors.email = emailValidation(email, props.errorLabels ? props.errorLabels : {});
    return errors;
  };

  const validateForm = (): boolean => {
    const newErrors = getFormErrors();
    setErrors(newErrors);
    const errorsPresent = Object.values(newErrors).some((value) => value !== '');
    return !errorsPresent;
  };

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    if (validateForm() && props.dispatch) {
      props.dispatch({
        type: ContactDataAction.SUBMIT,
        payload: getContactData(),
        demandId: props.demandId,
      });
    }
    event.preventDefault();
  };

  return (
    <>
      <form onSubmit={onSubmit}>
        <div className="form-element-container">
          <ZoomSelectInput
            onChange={onResidencePlaceChange}
            label={props.labelResidencePlace ? props.labelResidencePlace : ''}
            options={props.custodiesOptions ? props.custodiesOptions : []}
            value={residencePlace}
            name="residencePlace"
            helpText={props.helpTextResidencePLace}
            error={!!errors.residencePlace}
            errorMessage={errors.residencePlace}
          ></ZoomSelectInput>
        </div>
        <div className="form-element-container">
          <ZoomMultipleTextInput
            data={[
              {
                label: props.labelAddressStreet ? props.labelAddressStreet : '',
                value: addressStreet,
                gridSize: 9,
                name: 'addressStreet',
                error: !!errors.addressStreet,
                errorMessage: errors.addressStreet,
                onChange: onMultipleTextFieldChange,
              },
              {
                label: props.labelAddressNumber ? props.labelAddressNumber : '',
                value: addressNumber,
                gridSize: 3,
                name: 'addressNumber',
                error: !!errors.addressNumber,
                errorMessage: errors.addressNumber,
                onChange: onMultipleTextFieldChange,
              },
            ]}
          ></ZoomMultipleTextInput>
        </div>
        <div className="form-element-container">
          <ZoomMultipleTextInput
            data={[
              {
                label: props.labelPostCode ? props.labelPostCode : '',
                value: postCode,
                gridSize: 3,
                name: 'postCode',
                error: !!errors.postCode,
                errorMessage: errors.postCode,
                onChange: onMultipleTextFieldChange,
              },
              {
                label: props.labelCity ? props.labelCity : '',
                value: city,
                gridSize: 9,
                name: 'city',
                error: !!errors.city,
                errorMessage: errors.city,
                onChange: onMultipleTextFieldChange,
              },
            ]}
          ></ZoomMultipleTextInput>
        </div>
        <div className="form-element-container">
          <ZoomSelectInput
            label={props.labelCountry ? props.labelCountry : ''}
            options={props.nationalitiesOptions ? props.nationalitiesOptions : []}
            value={country}
            name="country"
            onChange={onCountryChange}
            helpText={props.helpTextCountry}
          ></ZoomSelectInput>
        </div>
        <div className="form-element-container">
          <ZoomTextInput
            label={props.labelPhoneNumber ? props.labelPhoneNumber : ''}
            name="phoneNumber"
            value={phoneNumber}
            onChange={onChange}
            error={!!errors.phoneNumber}
            errorMessage={errors.phoneNumber}
          ></ZoomTextInput>
        </div>
        <div className="form-element-container">
          <ZoomTextInput
            label={props.labelEmail ? props.labelEmail : ''}
            name="email"
            value={email}
            onChange={onChange}
            error={!!errors.email}
            errorMessage={errors.email}
          ></ZoomTextInput>
        </div>
        <div className="form-actions-container">
          <Button color="primary" type="submit" variant="contained" id="submit-contact-data">
            {props.labelSave}
          </Button>
        </div>
      </form>
    </>
  );
};

// Map global store to component parameters
// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export function mapStateToProps(store: WIAAStore, ownProps: any): ContactDataFormProps {
  const { demandId } = ownProps.match.params;
  const props: ContactDataFormProps = {
    labelResidencePlace: i18nLabel(store.translationLabels, 'Contact_User_Residence_Place', 'Wohnort*'),
    labelAddressStreet: i18nLabel(store.translationLabels, 'Contact_User_Address_Street', 'Adresse'),
    labelAddressNumber: i18nLabel(store.translationLabels, 'Contact_User_Address_Number', 'Nr.*'),
    labelPostCode: i18nLabel(store.translationLabels, 'Contact_User_Post_Code', 'PLZ'),
    labelCity: i18nLabel(store.translationLabels, 'Contact_User_City', 'Ort*'),
    labelCountry: i18nLabel(store.translationLabels, 'Contact_User_Country', 'Land*'),
    labelPhoneNumber: i18nLabel(store.translationLabels, 'Contact_User_Phone_Number', 'Telefon*'),
    labelEmail: i18nLabel(store.translationLabels, 'Contact_User_Email', 'E-Mail*'),
    labelSave: i18nLabel(store.translationLabels, 'Contact_User_Save', 'Speichern'),
    errorLabels: store.translationLabels,
    demandId: Number(demandId),
    custodiesOptions: store.translationOptions.livingtypes
      ? [
          { value: '0', name: getDefaultSelectOption(store.translationLabels) },
          ...store.translationOptions.livingtypes.map((option) => {
            return { value: option.id.toString(), name: option.name };
          }),
        ]
      : [],
    nationalitiesOptions: store.translationOptions.nationalities
      ? [
          ...store.translationOptions.nationalities.map((option) => {
            return { value: option.id.toString(), name: option.name };
          }),
        ]
      : [],
  };
  const data: PersonalData = store.personalData;
  if (data) {
    return {
      residencePlace: data.livingType ? data.livingType.toString() : '',
      phoneNumber: data.phoneNumberPrivate,
      city: data.town,
      country: data.countryId ? data.countryId.toString() : '',
      email: data.emailAddress,
      postCode: data.zipCode,
      addressNumber: data.houseNumber,
      addressStreet: data.address,
      ...props,
    };
  }
  return props;
}

// Default export the connected component
export default withRouter(connect(mapStateToProps)(ContactDataForm));
