import {
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  Grid,
  Link,
  Modal,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import React, { ChangeEvent, ReactElement, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';
import { Action } from 'redux';
import ZoomFooter, {
  mapStoreToFooterProps,
  ZoomFooterProps,
} from '../../../components/base-page-components/footer/ZoomFooter';
import ZoomHeader, {
  mapStoreToHeaderProps,
  ZoomHeaderProps,
} from '../../../components/base-page-components/header/ZoomHeader';
import { CustomTitleCell, CustomCell } from '../../../utils/mui-custom-styles';
import ZoomDatePicker from '../../../components/forms/date-picker/ZoomDatePicker';
import ZoomTextInput from '../../../components/forms/text-input/ZoomTextInput';
import { ZoomArrowLeftIcon } from '../../../components/icons/ZoomArrowLeftIcon';
import { i18nLabel } from '../../../store/i18n/helpers';
import { WIAAStore } from '../../../store/types';
import { blue } from '../../../theme';
import { ahvn13validation, requiredValidation } from '../../../utils/validators';
import { rightsTypeOptions, roleTypeOptionsAdminPanel } from '../../../utils/form-data';
import ZoomSelectInput from '../../../components/forms/select-input/ZoomSelectInput';
import produce from 'immer';
import './PersonDetails.scss';
import { RoutingAction, RoutingRedirectToAction } from '../../../store/routing/actions';
import moment from 'moment';
import {
  PersonDetailsDataAction,
  PersonDetailsDataGetAction,
  PersonDetailsDeletePermissionsDataAction,
  PersonDetailsEditPermissionsDataAction,
  PersonDetailsManualDemandActivationAction,
} from '../../../store/admin-portal/person-details/actions';
import { AuditItem, UserData } from '../../../store/admin-portal/person-details/types';

export interface PersonDetailsLabels {
  labelUid?: string;
  labelPortalId?: string;
  labelFirstName?: string;
  labelLastName?: string;
  labelEmail?: string;
  labelAhvn13?: string;
  labelDateOfBirth?: string;
  labelTitle?: string;
  labelId?: string;
  labelOwner?: string;
  labelRole?: string;
  labelPermissionType?: string;
  labelBackArrow?: string;
  labelCancel?: string;
  labelSave?: string;
  labelDelete?: string;
  labelPermissionsTitle?: string;
  labelNewPermission?: string;
  labelActivateDemand?: string;
  labelChangeRoles?: string;
  labelChangeRights?: string;
  labelLastChanged?: string;
  contentLabelDeleteAcknowledgementTitle?: string;
  contentLabelDeleteAcknowledgementParagraph?: string;
  contentLabelChangeRightsAcknowledgementTitle?: string;
  contentLabelChangeRightsAcknowledgementParagraph?: string;
  contentLabelChangeRoleAcknowledgementTitle?: string;
  contentLabelChangeRoleAcknowledgementParagraph?: string;
  contentLabelModalCancel?: string;
  contentLabelAcknowledgementSubmit?: string;
  breadcrumbLabels?: Array<string>;
  errorLabels?: { [key: string]: string };
  headerProps: ZoomHeaderProps;
  footerProps: ZoomFooterProps;
}

export interface Permission {
  dossierOwner?: UserData;
  id?: number;
  userId?: number;
  ownerUserId?: number;
  permissionType?: number;
  role?: number;
  mandatoryAuthorization?: boolean;
  changeRole?: boolean;
  changePermission?: boolean;
  audit?: AuditItem;
}

export interface PersonDetailsProps extends PersonDetailsLabels {
  canActivateDemand?: boolean;
  ownedDossierId?: number;
  portalId: number;
  uId?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  ahvn13?: string;
  dateOfBirth?: Date;
  dossierId: number;
  permissions: Array<Permission>;
  isLoading?: boolean;
  auditList?: Array<AuditItem>;
  dispatch?: (action: Action) => void;
}

interface PersonDetailsErrors {
  uId?: string;
  ahvn13?: string;
  dateOfBirth?: string;
}

export const PersonDetails = (props: PersonDetailsProps): ReactElement => {
  const firstName = props.firstName ? props.firstName : '';
  const lastName = props.lastName ? props.lastName : '';
  const email = props.email ? props.email : '';
  const portalId = props.portalId ? props.portalId : -1;
  const [uId, setUid] = useState(props.uId ? props.uId : '');
  const [ahvn13, setAhvn13] = useState(props.ahvn13 ? props.ahvn13 : '');
  const [dateOfBirth, setDateOfBirth] = useState(props.dateOfBirth ? props.dateOfBirth : undefined);
  const history = useHistory();
  const initialErrors: PersonDetailsErrors = {};
  const [errors, setErrors] = useState(initialErrors);
  const [permissions, setPermissions] = useState(props.permissions ? props.permissions : []);

  const [openChangeRoleAcknowledge, setOpenChangeRoleAcknowledge] = useState(false);
  const [openChangeRightsAcknowledge, setOpenChangeRightsAcknowledge] = useState(false);
  const [openDeleteAcknowlegde, setOpenDeleteAcknowledge] = useState(false);
  const [newRoleValue, setNewRoleValue] = useState('');
  const [newRightValue, setNewRightValue] = useState('');
  const [idCurrentlyEditedPermissionUser, setIdCurrentlyEditedPermissionUser] = useState(-1);
  const [permissionToDeleteId, setPermissionToDeleteId] = useState(-1);
  const [demandCreationResetted, setDemandCreationResetted] = useState(false);

  useEffect(() => {
    if (props.dispatch) {
      const getPersonDetails: PersonDetailsDataGetAction = {
        type: PersonDetailsDataAction.GET,
        payload: props.portalId,
      };
      props.dispatch(getPersonDetails);
    }
  }, []);

  useEffect(() => {
    setUid(props.uId ? props.uId : '');
    setAhvn13(props.ahvn13 ? props.ahvn13 : '');
    setDateOfBirth(props.dateOfBirth ? props.dateOfBirth : undefined);
    setPermissions(props.permissions ? props.permissions : []);
  }, [props]);

  const redirectToNewPermission = (id: number) => {
    const redirectAction: RoutingRedirectToAction = {
      type: RoutingAction.REDIRECT_TO,
      payload: `/admin-portal/user/${id}/new-permission`,
    };

    if (props.dispatch) {
      props.dispatch(redirectAction);
    }
  };

  const manualDemandActivation = () => {
    const manualDemandActivationAction: PersonDetailsManualDemandActivationAction = {
      type: PersonDetailsDataAction.MANUAL_DEMAND_ACTIVATION,
      portalId: props.portalId,
      manualDemandActivation: true,
    };
    if (props.dispatch) {
      props.dispatch(manualDemandActivationAction);
      setDemandCreationResetted(true);
    }
  };

  const onDeleteBtnHandler = (permissionId: number) => {
    setPermissionToDeleteId(permissionId);
    setOpenDeleteAcknowledge(true);
  };

  const onRoleTypeChange = (newValue: string, permissionId: number) => {
    setNewRoleValue(newValue);
    setIdCurrentlyEditedPermissionUser(permissionId);
    setOpenChangeRoleAcknowledge(true);
  };

  const onRightsTypeChange = (newValue: string, permissionId: number) => {
    setNewRightValue(newValue);
    setIdCurrentlyEditedPermissionUser(permissionId);
    setOpenChangeRightsAcknowledge(true);
  };
  const onChangeRoleModalCancelBtnHandler = () => {
    const changedRole: Array<Permission> = produce(permissions, (draft) => {
      draft.forEach((permission) => {
        permission.changeRole = false;
      });
      return draft;
    });
    setPermissions(changedRole);
    setOpenChangeRoleAcknowledge(false);
  };

  const onChangeRoleModalBtnHandler = () => {
    const changedRole: Array<Permission> = produce(permissions, (draft) => {
      const index = draft.findIndex((a) => a.id == idCurrentlyEditedPermissionUser);
      draft[index].role = parseInt(newRoleValue);
      draft[index].changeRole = !draft[index].changeRole;
      if (props.dispatch) {
        const editPermissionData: PersonDetailsEditPermissionsDataAction = {
          type: PersonDetailsDataAction.EDIT_PERMISSIONS_PERSON_DETAILS,
          payload: {
            id: draft[index].id,
            userId: draft[index].userId,
            ownerUserId: draft[index].ownerUserId,
            permissionTypeId: draft[index].permissionType,
            roleId: draft[index].role,
            mandatoryAuthorization: draft[index].mandatoryAuthorization,
          },
        };
        props.dispatch(editPermissionData);
      }
      return draft;
    });
    setPermissions(changedRole);
    setOpenChangeRoleAcknowledge(false);
  };

  const onChangeRightsModalCancelBtnHandler = () => {
    const changedRights: Array<Permission> = produce(permissions, (draft) => {
      draft.forEach((permission) => {
        permission.changePermission = false;
      });
      return draft;
    });
    setPermissions(changedRights);
    setOpenChangeRightsAcknowledge(false);
  };

  const onChangeRightsModalBtnHandler = () => {
    const changedRight: Array<Permission> = produce(permissions, (draft) => {
      const index = draft.findIndex((a) => a.id == idCurrentlyEditedPermissionUser);
      draft[index].permissionType = parseInt(newRightValue);
      draft[index].changePermission = !draft[index].changePermission;
      if (props.dispatch) {
        const editPermissionData: PersonDetailsEditPermissionsDataAction = {
          type: PersonDetailsDataAction.EDIT_PERMISSIONS_PERSON_DETAILS,
          payload: {
            id: draft[index].id,
            userId: draft[index].userId,
            ownerUserId: draft[index].ownerUserId,
            permissionTypeId: draft[index].permissionType,
            roleId: draft[index].role,
            mandatoryAuthorization: draft[index].mandatoryAuthorization,
          },
        };
        props.dispatch(editPermissionData);
      }
      return draft;
    });
    setPermissions(changedRight);
    setOpenChangeRightsAcknowledge(false);
  };

  const onDeleteModalDeleteBtnHandler = () => {
    //epic call will be here
    if (props.dispatch) {
      const deletePermissionData: PersonDetailsDeletePermissionsDataAction = {
        type: PersonDetailsDataAction.DELETE_PERMISSIONS_PERSON_DETAILS,
        payload: permissionToDeleteId,
      };
      props.dispatch(deletePermissionData);
    }
    setPermissionToDeleteId(-1);
    setOpenDeleteAcknowledge(false);
  };

  const onChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    switch (e.target.name) {
      case 'ahvn13':
        setAhvn13(e.target.value);
        setErrors({ ...errors, ahvn13: ahvn13validation(e.target.value, props.errorLabels ? props.errorLabels : {}) });
        break;
      case 'uId':
        setUid(e.target.value);
        setErrors({ ...errors, uId: requiredValidation(e.target.value, props.errorLabels ? props.errorLabels : {}) });
        break;
    }
  };

  const onChangeDate = (newDate: Date | null) => {
    setDateOfBirth(newDate === null ? undefined : newDate);
    setErrors({
      ...errors,
      dateOfBirth: newDate === null ? requiredValidation('', props.errorLabels ? props.errorLabels : {}) : '',
    });
  };

  const getPersonDetailsData = (): UserData => {
    return {
      id: portalId,
      uid: uId,
      firstName: firstName,
      lastName: lastName,
      title: '1',
      email: 'test@mail.bg',
      svn: ahvn13,
      birthDate: moment(dateOfBirth).format('YYYY-MM-DD'),
      active: true,
      initialSetupFinalized: true,
    };
  };

  const getFormErrors = (): PersonDetailsErrors => {
    const errors: PersonDetailsErrors = {};
    errors.ahvn13 = ahvn13validation(ahvn13, props.errorLabels ? props.errorLabels : {});
    errors.dateOfBirth = requiredValidation(
      dateOfBirth ? dateOfBirth.toDateString() : '',
      props.errorLabels ? props.errorLabels : {},
    );
    errors.uId = requiredValidation(uId, props.errorLabels ? props.errorLabels : {});
    return errors;
  };

  const validateForm = (): boolean => {
    const newErrors = getFormErrors();
    setErrors(newErrors);
    const errorsPresent = Object.values(newErrors).some((value) => value !== '');
    return !errorsPresent;
  };

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    if (validateForm() && props.dispatch) {
      const submitAction = {
        type: PersonDetailsDataAction.EDIT_PERSON_DETAILS,
        payload: getPersonDetailsData(),
      };
      props.dispatch(submitAction);
    }
    event.preventDefault();
  };

  const getAuditItemFor = (key: string): AuditItem | undefined => {
    return props.auditList?.find((item) => item.variableName === key);
  };

  const getHelpTextFor = (key: string): string | undefined => {
    const item = getAuditItemFor(key);
    if (item === undefined) {
      return undefined;
    }

    let updatedBy = 'System';
    if (item.updatedByInstance) {
      updatedBy = [
        item.updatedByInstance.firstName,
        item.updatedByInstance.lastName,
        `(${item.updatedByInstance.email})`,
      ].join(' ');
    }

    return `${props.labelLastChanged}: ${moment(item.lastUpdatedDate).format('DD.MM.YYYY')},  ${updatedBy}`;
  };

  const getPermissionLastChangeText = (permission: Permission) => {
    if (!permission.audit) {
      return '';
    }
    const lastChangedOn = moment(permission.audit.lastUpdatedDate).format('DD.MM.YYYY');

    let updatedBy = 'System';
    if (permission.audit.updatedByInstance) {
      updatedBy = [
        permission.audit.updatedByInstance.firstName,
        permission.audit.updatedByInstance.lastName,
        `(${permission.audit.updatedByInstance.email})`,
      ].join(' ');
    }

    return `${lastChangedOn},  ${updatedBy}`;
  };

  return (
    <>
      <ZoomHeader {...props.headerProps} dispatch={props.dispatch} />
      <main className="main">
        {props.isLoading ? (
          <div style={{ textAlign: 'center' }}>
            <CircularProgress />
          </div>
        ) : (
          <>
            <Container>
              <div id="main-page-container">
                <Breadcrumbs separator=">" aria-label="breadcrumb">
                  <Link style={{ fontFamily: 'Proxima Nova Cond' }} color="inherit" href="/admin-portal">
                    <>{props.breadcrumbLabels && props.breadcrumbLabels[0]}</>
                  </Link>
                  <Link style={{ fontFamily: 'Proxima Nova Cond' }} color="inherit">
                    <b>{props.breadcrumbLabels && props.breadcrumbLabels[1]}</b>
                  </Link>
                </Breadcrumbs>
                <div className="main-page-info">
                  <div className="back-button-desktop" onClick={() => history.goBack()}>
                    <ZoomArrowLeftIcon viewBox="0 0 80 80" fontSize="large" htmlColor={blue.main} />
                    {props.labelBackArrow}
                  </div>
                  <h1 className="title-desktop">{props.labelTitle}</h1>
                </div>
                <Card>
                  <CardContent>
                    <form onSubmit={onSubmit}>
                      <div className="form-element-container">
                        <ZoomTextInput
                          label={props.labelUid ? props.labelUid : ''}
                          name="uId"
                          value={uId}
                          helpText={getHelpTextFor('Uid')}
                          onChange={onChange}
                          error={!!errors.uId}
                          errorMessage={errors.uId}
                        />
                      </div>
                      <div className="form-element-container">
                        <ZoomTextInput
                          label={props.labelAhvn13 ? props.labelAhvn13 : ''}
                          value={ahvn13}
                          name="ahvn13"
                          helpText={getHelpTextFor('Svn')}
                          onChange={onChange}
                          error={!!errors.ahvn13}
                          errorMessage={errors.ahvn13 ? errors.ahvn13 : undefined}
                        />
                      </div>
                      <div className="form-element-container">
                        <ZoomTextInput
                          label={props.labelFirstName ? props.labelFirstName : ''}
                          name="firstName"
                          disabled
                          value={firstName}
                        />
                      </div>
                      <div className="form-element-container">
                        <ZoomTextInput
                          label={props.labelLastName ? props.labelLastName : ''}
                          name="lastName"
                          disabled
                          value={lastName}
                        />
                      </div>
                      <div className="form-element-container">
                        <ZoomTextInput
                          label={props.labelEmail ? props.labelEmail : ''}
                          name="email"
                          disabled
                          value={email}
                        />
                      </div>
                      <div className="form-element-container">
                        <ZoomDatePicker
                          label={props.labelDateOfBirth ? props.labelDateOfBirth : ''}
                          onChange={onChangeDate}
                          name="dateOfBirth"
                          helpText={getHelpTextFor('BirthDate')}
                          value={dateOfBirth}
                          error={!!errors.dateOfBirth}
                          errorMessage={errors.dateOfBirth}
                        />
                      </div>
                      <Grid container direction="row" spacing={2} style={{ paddingBottom: '1rem' }}>
                        <Grid item xs={6} sm={6}>
                          <div className="form-actions-container-cancel">
                            <Button
                              color="primary"
                              size="large"
                              type="reset"
                              variant="outlined"
                              id="cancel-edit-user-data"
                              style={{ width: '144px' }}
                              onClick={() => history.goBack()}
                            >
                              {props.labelCancel}
                            </Button>
                          </div>
                        </Grid>
                        <Grid item xs={6} sm={6}>
                          <div className="form-actions-container-save">
                            <Button
                              color="primary"
                              size="large"
                              type="submit"
                              variant="contained"
                              id="save-edit-user-data"
                              style={{ width: '144px' }}
                            >
                              {props.labelSave}
                            </Button>
                          </div>
                        </Grid>
                      </Grid>
                    </form>
                  </CardContent>
                </Card>
                <h1 className="title-desktop" style={{ marginTop: '50px' }}>
                  {props.labelPermissionsTitle}
                </h1>
                <Card>
                  <CardContent>
                    <TableContainer component={Paper}>
                      <Table className="permissions-data-row" aria-label="simple table" component={Paper}>
                        <TableHead>
                          <TableRow>
                            <CustomTitleCell align="left" style={{ width: '20%' }} className="th-cell">
                              {props.labelOwner}
                            </CustomTitleCell>
                            <CustomTitleCell align="left" style={{ width: '20%' }} className="th-cell">
                              {props.labelRole}
                            </CustomTitleCell>
                            <CustomTitleCell align="left" style={{ width: '25%' }} className="th-cell">
                              {props.labelPermissionType}
                            </CustomTitleCell>
                            <CustomTitleCell align="center" style={{ width: '20%' }} className="th-cell">
                              {props.labelLastChanged}
                            </CustomTitleCell>
                            <CustomTitleCell align="left" style={{ width: '15%' }} />
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {permissions.map((permission) => (
                            <TableRow key={permission.id} component={Paper}>
                              <CustomCell align="left" style={{ width: '20%' }}>
                                {permission.dossierOwner && permission.role !== 3
                                  ? `${permission.dossierOwner?.firstName} ${permission.dossierOwner?.lastName} ${permission.dossierOwner?.email}`
                                  : ''}
                              </CustomCell>
                              <CustomCell align="left" style={{ width: '20%' }}>
                                {permission.changeRole ? (
                                  <ZoomSelectInput
                                    hideLabel={true}
                                    label={props.labelChangeRoles ? props.labelChangeRoles : ''}
                                    name="role"
                                    options={roleTypeOptionsAdminPanel}
                                    value={permission.role ? permission.role.toString() : ''}
                                    onChange={(event) => onRoleTypeChange(event, permission.id ? permission.id : -1)}
                                  />
                                ) : (
                                  roleTypeOptionsAdminPanel.find(
                                    (option) => option.value === (permission.role ? permission.role.toString() : ''),
                                  )?.name
                                )}
                              </CustomCell>
                              <CustomCell align="left" style={{ width: '25%' }}>
                                {permission.changePermission ? (
                                  <ZoomSelectInput
                                    hideLabel={true}
                                    label={props.labelChangeRights ? props.labelChangeRights : ''}
                                    name="permissionType"
                                    options={rightsTypeOptions}
                                    value={permission.permissionType ? permission.permissionType.toString() : ''}
                                    onChange={(event) => onRightsTypeChange(event, permission.id ? permission.id : -1)}
                                  />
                                ) : (
                                  rightsTypeOptions.find(
                                    (option) =>
                                      option.value ===
                                      (permission.permissionType ? permission.permissionType.toString() : ''),
                                  )?.name
                                )}
                              </CustomCell>
                              <CustomCell align="left" style={{ width: '20%' }}>
                                {getPermissionLastChangeText(permission)}
                              </CustomCell>
                              <CustomCell align="right" style={{ width: '15%' }}>
                                {permission.role !== 1 && (
                                  <>
                                    <Button
                                      style={{ marginRight: '5px' }}
                                      id="delete-user"
                                      color="primary"
                                      variant="outlined"
                                      onClick={() => onDeleteBtnHandler(permission.id ? permission.id : -1)}
                                    >
                                      {props.labelDelete}
                                    </Button>
                                  </>
                                )}
                              </CustomCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <Grid container direction="row" spacing={2} style={{ paddingBottom: '1rem' }}>
                      <Grid item xs={6} sm={6}>
                        <div className="form-actions-container-cancel">
                          <Button
                            color="primary"
                            size="large"
                            type="submit"
                            variant="contained"
                            id="add-new-permission"
                            style={{ width: '224px' }}
                            onClick={() => redirectToNewPermission(props.portalId ? props.portalId : -1)}
                          >
                            {props.labelNewPermission}
                          </Button>
                        </div>
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        <div className="form-actions-container-save">
                          <Button
                            color="primary"
                            size="large"
                            variant="contained"
                            id="activate-new-demand"
                            disabled={!props.canActivateDemand || demandCreationResetted}
                            style={{ width: '324px' }}
                            onClick={() => manualDemandActivation()}
                          >
                            {props.labelActivateDemand}
                          </Button>
                        </div>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </div>
              <Modal
                open={openDeleteAcknowlegde}
                aria-labelledby="delete-acknowledge"
                aria-describedby="delete-acknowledge-description"
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
              >
                <div className="admin-portal-modal">
                  <h1 id="delete-acknowledge">{props.contentLabelDeleteAcknowledgementTitle}</h1>
                  <div id="delete-acknowledge-description">
                    <p>{props.contentLabelDeleteAcknowledgementParagraph}</p>
                  </div>
                  <div className="admin-portal-modal-actions">
                    <Button
                      color="primary"
                      variant="outlined"
                      fullWidth
                      onClick={() => setOpenDeleteAcknowledge(false)}
                    >
                      {props.contentLabelModalCancel}
                    </Button>
                    <Button
                      color="primary"
                      variant="contained"
                      fullWidth
                      onClick={() => onDeleteModalDeleteBtnHandler()}
                    >
                      {props.contentLabelAcknowledgementSubmit}
                    </Button>
                  </div>
                </div>
              </Modal>
              <Modal
                open={openChangeRoleAcknowledge}
                aria-labelledby="change-role-acknowledge"
                aria-describedby="change-role-acknowledge-description"
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
              >
                <div className="admin-portal-modal">
                  <h1 id="change-role-acknowledge">{props.contentLabelChangeRoleAcknowledgementTitle}</h1>
                  <div id="change-role-acknowledge-description">
                    <p>{props.contentLabelChangeRoleAcknowledgementParagraph}</p>
                  </div>
                  <div className="admin-portal-modal-actions">
                    <Button
                      color="primary"
                      variant="outlined"
                      fullWidth
                      onClick={() => onChangeRoleModalCancelBtnHandler()}
                    >
                      {props.contentLabelModalCancel}
                    </Button>
                    <Button color="primary" variant="contained" fullWidth onClick={() => onChangeRoleModalBtnHandler()}>
                      {props.contentLabelAcknowledgementSubmit}
                    </Button>
                  </div>
                </div>
              </Modal>
              <Modal
                open={openChangeRightsAcknowledge}
                aria-labelledby="change-rights-acknowledge"
                aria-describedby="change-rights-acknowledge-description"
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
              >
                <div className="admin-portal-modal">
                  <h1 id="change-rights-acknowledge">{props.contentLabelChangeRightsAcknowledgementTitle}</h1>
                  <div id="change-rights-acknowledge-description">
                    <p>{props.contentLabelChangeRightsAcknowledgementParagraph}</p>
                  </div>

                  <div className="admin-portal-modal-actions">
                    <Button
                      color="primary"
                      variant="outlined"
                      fullWidth
                      onClick={() => onChangeRightsModalCancelBtnHandler()}
                    >
                      {props.contentLabelModalCancel}
                    </Button>
                    <Button
                      color="primary"
                      variant="contained"
                      fullWidth
                      onClick={() => onChangeRightsModalBtnHandler()}
                    >
                      {props.contentLabelAcknowledgementSubmit}
                    </Button>
                  </div>
                </div>
              </Modal>
            </Container>
          </>
        )}
      </main>
      <ZoomFooter {...props.footerProps} />
    </>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export function mapStateToProps(store: WIAAStore, ownProps: any): PersonDetailsProps {
  const { id } = ownProps.match.params;
  const headerProps: ZoomHeaderProps = mapStoreToHeaderProps(store, id);
  const footerProps: ZoomFooterProps = mapStoreToFooterProps(store);
  return {
    headerProps: { ...headerProps, hideUserMenu: false },
    footerProps,
    labelTitle: i18nLabel(store.translationLabels, 'Admin_Portal_Person_Details_Title', 'Person Details'),
    labelUid: i18nLabel(store.translationLabels, 'Admin_Portal_Person_Details_Uid', 'Uid*'),
    labelPortalId: i18nLabel(store.translationLabels, 'Admin_Portal_Person_Details_Portal_Id', 'Portal Id'),
    labelFirstName: i18nLabel(store.translationLabels, 'Admin_Portal_Person_Details_First_Name', 'Vorname'),
    labelLastName: i18nLabel(store.translationLabels, 'Admin_Portal_Person_Details_Last_Name', 'Nachname'),
    labelEmail: i18nLabel(store.translationLabels, 'Admin_Portal_Person_Details_Email', 'Email'),
    labelAhvn13: i18nLabel(store.translationLabels, 'Admin_Portal_Person_Details_Ahv_Number', 'AHV-Nummer*'),
    labelDateOfBirth: i18nLabel(store.translationLabels, 'Admin_Portal_Person_Details_Date_Of_Birth', 'Geburtstag*'),
    labelBackArrow: i18nLabel(store.translationLabels, 'Admin_Portal_Person_Details_Back_Arrow', 'Zurück'),
    labelCancel: i18nLabel(store.translationLabels, 'Admin_Portal_Person_Details_Cancel', 'Abbrechen'),
    labelSave: i18nLabel(store.translationLabels, 'Admin_Portal_Person_Details_Save', 'Speichern'),
    labelDelete: i18nLabel(store.translationLabels, 'Admin_Portal_Person_Details_Delete', 'Löschen'),
    labelPermissionsTitle: i18nLabel(
      store.translationLabels,
      'Admin_Portal_Person_Details_Permissions_Title',
      'Permissions',
    ),
    labelId: i18nLabel(store.translationLabels, 'Admin_Portal_Person_Details_Permission_Id', 'Id'),
    labelOwner: i18nLabel(store.translationLabels, 'Admin_Portal_Person_Details_Permission_Owner', 'Inhaber'),
    labelRole: i18nLabel(store.translationLabels, 'Admin_Portal_Person_Details_Permission_Role', 'Rolle'),
    labelPermissionType: i18nLabel(
      store.translationLabels,
      'Admin_Portal_Person_Details_Permission_Type',
      'Permission Type',
    ),
    labelChangeRights: i18nLabel(store.translationLabels, 'Admin_Portal_Person_Details_Change_Rights', 'Rechte ändern'),
    labelChangeRoles: i18nLabel(store.translationLabels, 'Admin_Portal_Person_Details_Change_Roles', 'Rolle ändern'),
    labelNewPermission: i18nLabel(store.translationLabels, 'Admin_Portal_Person_Details_New_Permission', 'Neues Recht'),
    labelActivateDemand: i18nLabel(
      store.translationLabels,
      'Admin_Portal_Person_Details_Activate_Demand',
      'Gesuchserfassung aktivieren',
    ),
    contentLabelDeleteAcknowledgementTitle: i18nLabel(
      store.translationLabels,
      'Admin_Portal_Person_Details_Delete_Modal_Title',
      'Permissions löschen',
    ),
    contentLabelDeleteAcknowledgementParagraph: i18nLabel(
      store.translationLabels,
      'Admin_Portal_Person_Details_Delete_Modal_Description',
      'Sind Sie sicher, dass Sie die ausgewählte User löschen möchten?',
    ),
    contentLabelChangeRightsAcknowledgementTitle: i18nLabel(
      store.translationLabels,
      'Admin_Portal_Person_Details_Change_Rights_Modal_Title',
      'Rechte ändern',
    ),
    contentLabelChangeRightsAcknowledgementParagraph: i18nLabel(
      store.translationLabels,
      'Admin_Portal_Person_Details_Change_Rights_Modal_Description',
      'Sind Sie sicher, dass Sie für die ausgewählte User die Rechte ändern möchten?',
    ),
    contentLabelChangeRoleAcknowledgementTitle: i18nLabel(
      store.translationLabels,
      'Admin_Portal_Person_Details_Change_Role_Modal_Title',
      'Rolle ändern',
    ),
    contentLabelChangeRoleAcknowledgementParagraph: i18nLabel(
      store.translationLabels,
      'Admin_Portal_Person_Details_Change_Role_Modal_Description',
      'Sind Sie sicher, dass Sie für die ausgewählte User die Rolle ändern möchten?',
    ),
    contentLabelModalCancel: i18nLabel(
      store.translationLabels,
      'Admin_Portal_Person_Details_Modal_Cancel',
      'Abbrechen',
    ),
    contentLabelAcknowledgementSubmit: i18nLabel(
      store.translationLabels,
      'Admin_Portal_Person_Details_Modal_Save',
      'Bestätigen',
    ),
    labelLastChanged: i18nLabel(
      store.translationLabels,
      'Admin_Portal_Person_Details_Last_Change_Label',
      'Letzte Änderung',
    ),
    breadcrumbLabels: [
      i18nLabel(store.translationLabels, 'Admin_Portal_Person_Details_Breadcrumb_Label_1', 'Admin Übersicht'),
      i18nLabel(store.translationLabels, 'Admin_Portal_Person_Details_Breadcrumb_Label_2', 'Person Details'),
    ],
    errorLabels: store.translationLabels,
    dossierId: -1,
    permissions: store.personDetailsDataStore.permissions.map((permission) => ({
      dossierOwner: permission.dossierOwner,
      id: permission.id,
      userId: permission.userId,
      ownerUserId: permission.ownerUserId,
      permissionType: permission.permissionTypeId,
      role: permission.roleId,
      mandatoryAuthorization: permission.mandatoryAuthorization,
      changeRole: false,
      changePermission: false,
      audit: permission.auditList ? permission.auditList[0] : undefined,
    })),
    canActivateDemand: store.personDetailsDataStore.canActivateDemand,
    ownedDossierId: store.personDetailsDataStore.ownedDossierId,
    isLoading: store.personDetailsDataStore.isLoading,
    uId: store.personDetailsDataStore.user.uid,
    portalId: id,
    firstName: store.personDetailsDataStore.user.firstName,
    lastName: store.personDetailsDataStore.user.lastName,
    email: store.personDetailsDataStore.user.email,
    ahvn13: store.personDetailsDataStore.user.svn,
    dateOfBirth: moment(store.personDetailsDataStore.user.birthDate).toDate(),
    auditList: store.personDetailsDataStore.auditList,
  };
}

export default withRouter(connect(mapStateToProps)(PersonDetails));
