import produce from 'immer';
import { LastDemandDataTakeoverAction, LastDemandDataType } from '../last-demand-data-takeover/actions';
import { ParentsDataAction, ParentsDataActionType } from './actions';
import { ParentsData } from './types';

export const initialState: ParentsData = {};

export function parentsDataReducer(
  state = initialState,
  action: ParentsDataActionType | LastDemandDataType,
): ParentsData {
  return produce(state, (draft) => {
    switch (action.type) {
      case ParentsDataAction.RESULT:
        draft = action.payload;
        break;
      case ParentsDataAction.GET_RESULT:
        draft = action.payload;
        break;
      case LastDemandDataTakeoverAction.RESULT:
        const lastDemandData = action.payload;
        draft = { ...lastDemandData.familyRelation, socialCareReceived: undefined };
        break;
    }
    return draft;
  });
}
