import { Button } from '@material-ui/core';
import React, { ChangeEvent, ReactElement, useEffect, useState } from 'react';
import ZoomCheckboxInput from '../../../../components/forms/checkbox-input/ZoomCheckboxInput';
import ZoomSelectInput from '../../../../components/forms/select-input/ZoomSelectInput';
import ZoomTextInput from '../../../../components/forms/text-input/ZoomTextInput';
import { emailComparisonValidation, emailValidation, requiredValidation } from '../../../../utils/validators';

export interface Attorney {
  id?: number;
  role?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  emailConfirmation?: string;
  right?: string;
  checkboxValue?: boolean;
}

export interface PowerOfAttorneyItemFormProps extends Attorney {
  labelRole?: string;
  labelFirstName?: string;
  labelLastName?: string;
  labelEmail?: string;
  labelEmailConfirmation?: string;
  labelRight?: string;
  helpTextRight?: string;
  labelCheckbox?: string;
  labelSave?: string;
  labelCancel?: string;
  errorLabels?: { [key: string]: string };
  onSave?: (attorney: Attorney) => void;
  onDelete?: () => void;
  rolesTypes?: { name: string; value: string }[];
}

interface PowerOfAttorneyItemDataErrors {
  id?: string;
  role?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  emailConfirmation?: string;
  right?: string;
  checkboxValue?: string;
}

export const PowerOfAttorneyItemForm = (props: PowerOfAttorneyItemFormProps): ReactElement => {
  const [id] = useState(props.id ? props.id : 0);
  const [role, setRole] = useState(props.role ? props.role.toString() : '5');
  const [firstName, setFirstName] = useState(props.firstName ? props.firstName : '');
  const [lastName, setLastName] = useState(props.lastName ? props.lastName : '');
  const [email, setEmail] = useState(props.email ? props.email : '');
  const [emailConfirmation, setEmailConfirmation] = useState(props.emailConfirmation ? props.emailConfirmation : '');
  const [right, setRight] = useState(props.right ? props.right : 'Voller Zugriff');
  const [checkboxValue, setCheckboxValue] = useState(props.checkboxValue ? props.checkboxValue : false);
  const initialErrors: PowerOfAttorneyItemDataErrors = {};
  const [errors, setErrors] = useState(initialErrors);

  useEffect(() => {
    setRole(props.role ? props.role.toString() : '5');
    setFirstName(props.firstName ? props.firstName : '');
    setLastName(props.lastName ? props.lastName : '');
    setEmail(props.email ? props.email : '');
    setEmailConfirmation(props.emailConfirmation ? props.emailConfirmation : '');
    setRight(props.right ? props.right : 'Voller Zugriff');
    setCheckboxValue(props.checkboxValue ? props.checkboxValue : false);
  }, [props]);

  const onChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    switch (e.target.name) {
      case 'firstName':
        setFirstName(e.target.value);
        setErrors({
          ...errors,
          firstName: requiredValidation(e.target.value, props.errorLabels ? props.errorLabels : {}),
        });
        break;
      case 'lastName':
        setLastName(e.target.value);
        setErrors({
          ...errors,
          lastName: requiredValidation(e.target.value, props.errorLabels ? props.errorLabels : {}),
        });
        break;
      case 'email':
        setEmail(e.target.value);
        setErrors({ ...errors, email: emailValidation(e.target.value, props.errorLabels ? props.errorLabels : {}) });
        break;
      case 'emailConfirmation':
        setEmailConfirmation(e.target.value);
        setErrors({
          ...errors,
          emailConfirmation: emailValidation(e.target.value, props.errorLabels ? props.errorLabels : {}),
        });
        break;
    }
  };

  const onRoleTypeChange = (newValue: string) => {
    setRole(newValue);
  };

  const onCheckboxValuechange = (e: ChangeEvent<HTMLInputElement>) => {
    setCheckboxValue(e.target.checked);
    setErrors({
      ...errors,
      checkboxValue: requiredValidation(
        e.target.checked === true ? 'true' : '',
        props.errorLabels ? props.errorLabels : {},
      ),
    });
  };

  const getFormErrors = (): PowerOfAttorneyItemDataErrors => {
    const errors: PowerOfAttorneyItemDataErrors = {};
    errors.role = role === '' ? requiredValidation('', props.errorLabels ? props.errorLabels : {}) : '';
    errors.firstName = requiredValidation(firstName, props.errorLabels ? props.errorLabels : {});
    errors.lastName = requiredValidation(lastName, props.errorLabels ? props.errorLabels : {});
    errors.email = emailValidation(email, props.errorLabels ? props.errorLabels : {});
    errors.emailConfirmation = emailValidation(emailConfirmation, props.errorLabels ? props.errorLabels : {});
    if (errors.email === '' && errors.emailConfirmation === '') {
      errors.email = emailComparisonValidation(email, emailConfirmation, props.errorLabels ? props.errorLabels : {});
      errors.emailConfirmation = emailComparisonValidation(
        email,
        emailConfirmation,
        props.errorLabels ? props.errorLabels : {},
      );
    }
    errors.checkboxValue = !checkboxValue ? requiredValidation('', props.errorLabels ? props.errorLabels : {}) : '';
    return errors;
  };

  const validateForm = (): boolean => {
    const newErrors = getFormErrors();
    setErrors(newErrors);
    const errorsPresent = Object.values(newErrors).some((value) => value !== '');
    return !errorsPresent;
  };

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    if (validateForm()) {
    }
    event.preventDefault();
    const newErrors = getFormErrors();
    setErrors(newErrors);
    const errorsPresent = Object.values(newErrors).some((value) => value !== '');
    if (props.onSave && !errorsPresent) {
      props.onSave({
        id,
        role,
        firstName,
        lastName,
        email,
        emailConfirmation,
        right,
        checkboxValue,
      });
    }
  };

  const onDelete = () => {
    if (props.onDelete) {
      props.onDelete();
    }
  };

  return (
    <>
      <form onSubmit={onSubmit}>
        <div className="form-element-container">
          <ZoomSelectInput
            label={props.labelRole ? props.labelRole : ''}
            name="role"
            options={props.rolesTypes ? props.rolesTypes : []}
            value={role}
            onChange={onRoleTypeChange}
            error={!!errors.role}
            errorMessage={errors.role}
          ></ZoomSelectInput>
        </div>
        <div className="form-element-container">
          <ZoomTextInput
            label={props.labelFirstName ? props.labelFirstName : ''}
            name="firstName"
            value={firstName}
            onChange={onChange}
            error={!!errors.firstName}
            errorMessage={errors.firstName}
          ></ZoomTextInput>
        </div>
        <div className="form-element-container">
          <ZoomTextInput
            label={props.labelLastName ? props.labelLastName : ''}
            name="lastName"
            value={lastName}
            onChange={onChange}
            error={!!errors.lastName}
            errorMessage={errors.lastName}
          ></ZoomTextInput>
        </div>
        <div className="form-element-container">
          <ZoomTextInput
            label={props.labelEmail ? props.labelEmail : ''}
            value={email}
            name="email"
            onChange={onChange}
            error={!!errors.email}
            errorMessage={errors.email}
          ></ZoomTextInput>
        </div>
        <div className="form-element-container">
          <ZoomTextInput
            label={props.labelEmailConfirmation ? props.labelEmailConfirmation : ''}
            value={emailConfirmation}
            name="emailConfirmation"
            onChange={onChange}
            error={!!errors.emailConfirmation}
            errorMessage={errors.emailConfirmation}
          ></ZoomTextInput>
        </div>
        <div className="form-element-container">
          <ZoomTextInput
            label={props.labelRight ? props.labelRight : ''}
            value={right}
            name="right"
            readOnly={true}
          ></ZoomTextInput>
        </div>
        <div className="form-element-container">
          <ZoomCheckboxInput
            label={props.labelCheckbox ? props.labelCheckbox : ''}
            value={checkboxValue}
            name="checkbox"
            onChange={onCheckboxValuechange}
            error={!!errors.checkboxValue}
            errorMessage={errors.checkboxValue}
          ></ZoomCheckboxInput>
        </div>
        <div className="form-actions-container">
          <Button
            data-testid="save-attorney-button"
            id="save-attorney-button"
            color="primary"
            type="submit"
            variant="contained"
            size="small"
            title={props.labelSave}
          >
            {props.labelSave}
          </Button>
          <Button
            data-testid="cancel-attorney-button"
            id="cancel-attorney-button"
            color="primary"
            variant="outlined"
            size="small"
            title={props.labelCancel}
            onClick={() => {
              onDelete();
            }}
          >
            {props.labelCancel}
          </Button>
        </div>
      </form>
    </>
  );
};
