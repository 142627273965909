import { UserData } from './types';

export enum OverviewDataAction {
  GET = 'OVERVIEW_DATA_GET',
  GET_RESULT = 'OVERVIEW_DATA_GET_RESULT',
  DELETE_ITEM = 'OVERVIEW_DELETE',
  DELETE_RESULT = 'OVERVIEW_DELETE_RESULT',
  SEARCH_USERS_GET = 'OVERVIEW_SEARCH_USERS_GET',
  SEARCH_USERS_GET_RESULT = 'OVERVIEW_SEARCH_USERS_GET_RESULT',
}

export interface OverviewDataGetAction {
  type: typeof OverviewDataAction.GET;
  payload: number;
}

export interface OverviewDataGetResultAction {
  type: typeof OverviewDataAction.GET_RESULT;
  payload: UserData[];
}

export interface OverviewDataDeleteItemAction {
  type: typeof OverviewDataAction.DELETE_ITEM;
  payload: number;
}

export interface OverviewDataDeleteResultAction {
  type: typeof OverviewDataAction.DELETE_RESULT;
  payload: number;
}

export interface OverviewDataSearchUsersGetAction {
  type: typeof OverviewDataAction.SEARCH_USERS_GET;
  searchWord: string;
}

export interface OverviewDataSearchUsersGetResultAction {
  type: typeof OverviewDataAction.SEARCH_USERS_GET_RESULT;
  payload: UserData[];
}

export type OverviewActionType =
  | OverviewDataGetAction
  | OverviewDataGetResultAction
  | OverviewDataDeleteItemAction
  | OverviewDataDeleteResultAction
  | OverviewDataSearchUsersGetAction
  | OverviewDataSearchUsersGetResultAction;
