import React, { ReactElement } from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export function ZoomCrossIcon(props: SvgIconProps): ReactElement {
  return (
    <SvgIcon {...props}>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <path d="M0 0H80V80H0z" transform="translate(-556 -257) translate(556 257)" />
            <path
              fill={props.htmlColor ? props.htmlColor : '#262626'}
              fillRule="nonzero"
              d="M43.59 39.8L64 19.36c.715-.791.683-2.004-.073-2.757-.755-.753-1.968-.78-2.757-.063L40.76 37 20.32 16.54c-.794-.68-1.976-.634-2.715.105-.739.739-.785 1.921-.105 2.715L37.93 39.8 17.5 60.23c-.548.495-.779 1.25-.6 1.967.177.716.735 1.276 1.451 1.457.716.18 1.472-.048 1.969-.594l20.44-20.43 20.43 20.43c.796.677 1.979.627 2.715-.114.736-.742.777-1.925.095-2.716L43.59 39.8z"
              transform="translate(-556 -257) translate(556 257)"
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}
