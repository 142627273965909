import { Button, Divider, FormControl, FormHelperText, Grid, makeStyles } from '@material-ui/core';
import React, { ReactElement, useEffect, useState } from 'react';
import { black, red } from './../../../theme';
import { ZoomInfoIcon } from './../../icons/ZoomInfoIcon';
import { ZoomTextInputHelpTooltip } from './../../tooltips/ZoomTextInputHelpTooltip';
import './ZoomToggleInput.scss';

const useStyles = makeStyles({
  labelFonts: {
    fontFamily: 'Proxima Nova Cond',
    fontWeight: 'lighter',
    fontStyle: 'normal',
  },
  primary: {
    color: black.main,
  },
  error: {
    color: red.main,
  },
  root: {
    border: 1,
    borderStyle: 'solid',
    borderColor: black.main,
    padding: 2,
  },
  rootError: {
    border: 1,
    borderStyle: 'solid',
    borderColor: red.main,
    padding: 2,
  },
});

export interface ZoomToggleInputProps {
  title: string;
  label1: string;
  label2: string;
  value1?: string;
  value2?: string;
  value?: string;
  name?: string;
  helpText?: string;
  error?: boolean;
  errorMessage?: string;
  onChange?: (value: string) => void;
}

export default function ZoomToggleInput(props: ZoomToggleInputProps): ReactElement {
  const [value1] = useState(props.value1 ? props.value1 : props.label1);
  const [value2] = useState(props.value2 ? props.value2 : props.label2);
  const [value, setValue] = useState(props.value !== undefined ? props.value : '');
  const style1 = value === value1 ? 'active' : 'inactive';
  const style2 = value === value2 ? 'active' : 'inactive';
  const [error, setError] = useState(props.error ? true : false);
  const [errorMessage, setErrorMessage] = useState(props.errorMessage);
  const classes = useStyles();
  const style = error ? classes.rootError : classes.root;

  useEffect(() => {
    setValue(props.value !== undefined ? props.value : '');
    setError(props.error ? true : false);
    setErrorMessage(props.errorMessage ? props.errorMessage : '');
  }, [props.value, props.error, props.errorMessage]);

  const onChange = (newValue: string) => {
    setValue(newValue);
    if (props.onChange) {
      props.onChange(newValue);
    }
  };

  return (
    <Grid container direction="row" alignItems="stretch" justify="flex-start">
      <input type="hidden" name={props.name} value={value} />
      <Grid item xs={12} sm={6}>
        <div className="label">
          <label>{props.title}</label>
          {props.helpText && (
            <ZoomTextInputHelpTooltip placement="bottom" arrow={true} title={<p>{props.helpText}</p>}>
              <span style={{ width: '24px', height: '24px' }}>
                <ZoomInfoIcon color="primary" viewBox="0 0 80 80"></ZoomInfoIcon>
              </span>
            </ZoomTextInputHelpTooltip>
          )}
        </div>
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControl error={error} className={classes.labelFonts} fullWidth>
          <Grid className={style + ' toggle-container'} container spacing={0} justify="space-between">
            <Grid item xs={6}>
              <Button fullWidth onClick={() => onChange(value1)} className={style1} variant="contained" size="large">
                {props.label1}
              </Button>
            </Grid>
            <Grid item xs={6} container direction="row" wrap="nowrap">
              <Divider orientation="vertical" />
              <Button fullWidth onClick={() => onChange(value2)} className={style2} variant="contained" size="large">
                {props.label2}
              </Button>
            </Grid>
          </Grid>
          <FormHelperText data-testid="error-paragraph">{errorMessage ? errorMessage : null}</FormHelperText>
        </FormControl>
      </Grid>
    </Grid>
  );
}
