import { SiblingData } from './types';

export enum SiblingsDataAction {
  GET = 'SIBLINGS_DATA_GET',
  GET_RESULT = 'SIBLINGS_DATA_GET_RESULT',
  EDIT_ITEM = 'SIBLING_DATA_EDIT',
  EDIT_RESULT = 'SIBLING_DATA_EDIT_RESULT',
  ADD_ITEM = 'SIBLING_ADD',
  ADD_RESULT = 'SIBLING_ADD_RESULT',
  DELETE_ITEM = 'SIBLING_DELETE',
  DELETE_RESULT = 'SIBLING_DELETE_RESULT',
  EMPTY_SIBLINGS_DATA = 'SIBLING_EMPTY',
}

export interface SiblingsDataGetAction {
  type: typeof SiblingsDataAction.GET;
  pifId: number;
}

export interface SiblingsDataGetResultAction {
  type: typeof SiblingsDataAction.GET_RESULT;
  payload: SiblingData[];
}

export interface SiblingsDataEditSiblingAction {
  type: typeof SiblingsDataAction.EDIT_ITEM;
  payload: SiblingData;
  pifId: number;
}

export interface SiblingsDataEditResultAction {
  type: typeof SiblingsDataAction.EDIT_RESULT;
  payload: SiblingData;
}

export interface SiblingsDataAddSiblingAction {
  type: typeof SiblingsDataAction.ADD_ITEM;
  payload: SiblingData;
  pifId: number;
}

export interface SiblingsDataAddResultAction {
  type: typeof SiblingsDataAction.ADD_RESULT;
  payload: SiblingData;
}

export interface SiblingsDataDeleteSiblingAction {
  type: typeof SiblingsDataAction.DELETE_ITEM;
  payload: number;
  pifId: number;
}

export interface SiblingsDataDeleteResultAction {
  type: typeof SiblingsDataAction.DELETE_RESULT;
  payload: number;
}

export type SiblingsDataActionType =
  | SiblingsDataGetAction
  | SiblingsDataGetResultAction
  | SiblingsDataEditSiblingAction
  | SiblingsDataEditResultAction
  | SiblingsDataAddSiblingAction
  | SiblingsDataAddResultAction
  | SiblingsDataDeleteSiblingAction
  | SiblingsDataDeleteResultAction;
