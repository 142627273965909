import {
  Breadcrumbs,
  Button,
  Card,
  CardActions,
  CircularProgress,
  Container,
  Grid,
  Link,
  Typography,
} from '@material-ui/core';
import React, { ReactElement, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ZoomFooter from '../../components/base-page-components/footer/ZoomFooter';
import ZoomHeader from '../../components/base-page-components/header/ZoomHeader';
import { ZoomArrowLeftIcon } from '../../components/icons/ZoomArrowLeftIcon';
import { ZoomCheckCircleIcon } from '../../components/icons/ZoomCheckCircleIcon';
import { ZoomPersonIcon } from '../../components/icons/ZoomPersonIcon';
import { HolderDataAction } from '../../store/holder-data/actions';
import { RoutingAction, RoutingRedirectToAction } from '../../store/routing/actions';
import { black, blue, darkBlue } from './../../theme';
import { mapStateToPropsHolder, ScholarshipHolderProps } from './ScholarshipHolder';
import './ScholarshipHolderMobile.scss';

export const ScholarshipHolderMobile = (props: ScholarshipHolderProps): ReactElement => {
  const scholarshipHoldersData = props.holders ? props.holders : [];
  const history = useHistory();

  const onClick = (index: number) => {
    if (props.dispatch) {
      const redirectAction: RoutingRedirectToAction = {
        type: RoutingAction.REDIRECT_TO,
        payload: '/dashboard/dossier/' + scholarshipHoldersData[index].dossierId,
      };
      props.dispatch(redirectAction);
    }
  };

  useEffect(() => {
    if (props.dispatch) {
      props.dispatch({ type: HolderDataAction.GET });
    }
  }, []);

  return (
    <>
      <ZoomHeader {...props.headerProps} dispatch={props.dispatch}></ZoomHeader>
      <main className="main-mobile">
        {props.isLoading ? (
          <div style={{ textAlign: 'center' }}>
            <CircularProgress></CircularProgress>
          </div>
        ) : (
          <>
            <Container className="pagesection-mobile">
              <Breadcrumbs style={{ marginBottom: '3rem' }} separator=">" aria-label="breadcrumb">
                <Link
                  style={{ fontFamily: 'Proxima Nova Cond' }}
                  color="inherit"
                  href={'/dashboard/dossier/' + props.dossierId}
                >
                  {props.breadcrumbLabels && props.breadcrumbLabels[0]}
                </Link>
                <Link style={{ fontFamily: 'Proxima Nova Cond' }} color="inherit" href="/holder">
                  <b>{props.breadcrumbLabels && props.breadcrumbLabels[1]}</b>
                </Link>
              </Breadcrumbs>
              <div className="back-button-mobile" onClick={() => history.goBack()}>
                <ZoomArrowLeftIcon viewBox="0 0 80 80" fontSize="small" htmlColor={blue.main}></ZoomArrowLeftIcon>
                {props.labelBackArrow}
              </div>
              <div className="top-content">
                <h1 className="title-mobile">{props.labelTitle}</h1>
                <p>{props.labelDescription}</p>
              </div>
              <Grid container spacing={2}>
                {scholarshipHoldersData.map((holder, index) => (
                  <Grid item xs={12} key={index}>
                    <Card className={holder.dossierId === props.dossierId ? 'selected-border' : 'non-selected-border'}>
                      <div className="user-info-card-mobile">
                        <div className="user-name-card-mobile">
                          <Typography
                            className={holder.dossierId === props.dossierId ? 'selected-name' : 'non-selected-name'}
                            gutterBottom
                          >
                            {holder.firstName + ' ' + holder.lastName}
                          </Typography>
                        </div>
                        <div className="user-icon-card-mobile">
                          <ZoomPersonIcon
                            className={
                              holder.dossierId === props.dossierId
                                ? 'person-icon-mobile-selected'
                                : 'person-icon-mobile'
                            }
                            viewBox="0 0 80 80"
                            fontSize="large"
                            htmlColor={holder.dossierId === props.dossierId ? darkBlue.main : black.main}
                          ></ZoomPersonIcon>
                        </div>
                      </div>
                      <CardActions className="card-actions-mobile">
                        {holder.dossierId === props.dossierId ? (
                          <Typography gutterBottom>
                            <div className="card-actions-status-mobile" style={{ color: blue.main }}>
                              <ZoomCheckCircleIcon
                                className="check-icon-mobile"
                                viewBox="0 0 80 80"
                                fontSize="large"
                                htmlColor={blue.main}
                              ></ZoomCheckCircleIcon>
                              {props.labelCurrentChoice2}
                            </div>
                          </Typography>
                        ) : (
                          <Button
                            className="change-choice-btn-mobile"
                            variant="outlined"
                            color="primary"
                            style={{ width: '100%' }}
                            onClick={() => onClick(index)}
                          >
                            {props.labelChangeChoice}
                          </Button>
                        )}
                      </CardActions>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Container>
          </>
        )}
      </main>
      <ZoomFooter {...props.footerProps}></ZoomFooter>
    </>
  );
};

export default connect(mapStateToPropsHolder)(ScholarshipHolderMobile);
