import produce from 'immer';
import { MyDemandPageAction, MyDemandPageActionType } from './actions';
import { MyDemandsDataStore } from './types';

export const initialState: MyDemandsDataStore = {
  myDemands: [],
};

export function myDemandPageReducer(state = initialState, action: MyDemandPageActionType): MyDemandsDataStore {
  return produce(state, (draft) => {
    switch (action.type) {
      case MyDemandPageAction.GET:
        draft.isLoading = true;
        break;
      case MyDemandPageAction.GET_RESULT:
        draft.myDemands = action.payload;
        draft.isLoading = false;
        break;
    }
    return draft;
  });
}
