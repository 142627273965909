import { Action } from 'redux';
import { Epic, ofType } from 'redux-observable';
import { Observable, of } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { catchError, debounceTime, filter, map, mergeMap } from 'rxjs/operators';
import { customAjax } from '../../../utils/ajax-wrapper';
import { handleRequestFailureInEpic } from '../../../utils/error-handling';
import {
  OverviewDataAction,
  OverviewDataGetResultAction,
  OverviewDataDeleteItemAction,
  OverviewDataDeleteResultAction,
  OverviewDataSearchUsersGetAction,
  OverviewDataSearchUsersGetResultAction,
} from './actions';

const loadOverviewData = (payload: AjaxResponse): OverviewDataGetResultAction => {
  return {
    type: OverviewDataAction.GET_RESULT,
    payload: payload.response,
  };
};

export const getOverviewDataEpic: Epic<Action, Action> = (action$: Observable<Action>): Observable<Action> =>
  action$.pipe(
    ofType(OverviewDataAction.GET),
    mergeMap(() => {
      return customAjax({
        subRoute: `/partner/v1/users`,
        withCredentials: true,
        method: 'GET',
      }).pipe(
        map((response) => loadOverviewData(response)),
        catchError((error) => {
          return of(handleRequestFailureInEpic(error));
        }),
      );
    }),
  );

const overviewDataDeleted = (payload: number): OverviewDataDeleteResultAction => {
  return {
    type: OverviewDataAction.DELETE_RESULT,
    payload,
  };
};

export const deleteOverviewDataEpic: Epic<Action, Action> = (action$: Observable<Action>): Observable<Action> =>
  action$.pipe(
    ofType(OverviewDataAction.DELETE_ITEM),
    mergeMap((action) => {
      const userId = (action as OverviewDataDeleteItemAction).payload;
      return customAjax({
        subRoute: `/partner/v1/users/${userId}/dashboard-delete`,
        withCredentials: true,
        method: 'DELETE',
      }).pipe(
        map(() => overviewDataDeleted(userId)),
        catchError((error) => {
          return of(handleRequestFailureInEpic(error));
        }),
      );
    }),
  );

const loadOverviewSearchData = (payload: AjaxResponse): OverviewDataSearchUsersGetResultAction => {
  return {
    type: OverviewDataAction.SEARCH_USERS_GET_RESULT,
    payload: payload.response,
  };
};

export const getOverviewUsersDataEpic: Epic<Action, Action> = (action$: Observable<Action>): Observable<Action> =>
  action$.pipe(
    ofType(OverviewDataAction.SEARCH_USERS_GET),
    debounceTime(300),
    filter((action) => {
      const queryString = (action as OverviewDataSearchUsersGetAction).searchWord;
      return queryString.length > 0;
    }),
    mergeMap((action) => {
      const queryString = (action as OverviewDataSearchUsersGetAction).searchWord;
      return customAjax({
        subRoute: `/partner/v1/users/dashboard-index?queryString=${queryString}`,
        withCredentials: true,
        method: 'GET',
      }).pipe(
        map((response) => loadOverviewSearchData(response)),
        catchError((error) => {
          return of(handleRequestFailureInEpic(error));
        }),
      );
    }),
  );
