import React, { ReactElement } from 'react';
import { connect } from 'react-redux';
import ZoomFooter, {
  mapStoreToFooterProps,
  ZoomFooterProps,
} from '../../components/base-page-components/footer/ZoomFooter';
import ZoomHeader, {
  mapStoreToHeaderProps,
  ZoomHeaderProps,
} from '../../components/base-page-components/header/ZoomHeader';
import { Attorney } from '../../containers/power-of-attorney-form/item/form/PowerOfAttorneyItemForm';
import { HolderDataGetAction } from '../../store/holder-data/actions';
import { i18nLabel } from '../../store/i18n/helpers';
import { PowerOfAttorneyDataActionType } from '../../store/mandatory-power-of-attorney-data/actions';
import { MandatoryPowerOfAttorneyDataStore } from '../../store/mandatory-power-of-attorney-data/types';
import { WIAAStore } from '../../store/types';
import {
  PowerOfAttorneyFormProps,
  PowerOfAttorneyForm,
} from './../../containers/power-of-attorney-form/PowerOfAttorneyForm';

export interface PowerOfAttorneyProps {
  formProps: PowerOfAttorneyFormProps;
  headerProps: ZoomHeaderProps;
  footerProps: ZoomFooterProps;
  error?: string;
  dispatch?: (action: PowerOfAttorneyDataActionType | HolderDataGetAction) => void;
}

export const PowerOfAttorney = (props: PowerOfAttorneyProps): ReactElement => {
  const formProps = { ...props.formProps, dispatch: props.dispatch };
  return (
    <>
      <ZoomHeader {...props.headerProps}></ZoomHeader>
      <PowerOfAttorneyForm {...formProps}></PowerOfAttorneyForm>
      <ZoomFooter {...props.footerProps}></ZoomFooter>
    </>
  );
};

export function mapStateToProps(store: WIAAStore): PowerOfAttorneyProps {
  const props: PowerOfAttorneyFormProps = {
    labelTitle: i18nLabel(store.translationLabels, 'Power_Of_Attorney_Title', 'Bevollmächtige hinzufügen'),
    labelDescription: i18nLabel(
      store.translationLabels,
      'Power_Of_Attorney_Description',
      'Da Sie unter 18 Jahre sind, müssen Sie mindestens ein Elternteil oder einen gesetzlichen Vormund als bevollmächtigte Person hinzufügen, welche auf ihre Anträge zugreifen kann. Die Person erhält eine Einladung zur Bevollmächtigung per E-Mail.',
    ),
    labelRole: i18nLabel(store.translationLabels, 'Power_Of_Attorney_Title_Role', 'Rolle*'),
    labelFirstName: i18nLabel(store.translationLabels, 'Power_Of_Attorney_First_Name', 'Vorname*'),
    labelLastName: i18nLabel(store.translationLabels, 'Power_Of_Attorney_Last_Name', 'Nachname*'),
    labelEmail: i18nLabel(store.translationLabels, 'Power_Of_Attorney_Email', 'E-mail*'),
    labelEmailConfirmation: i18nLabel(
      store.translationLabels,
      'Power_Of_Attorney_Email_Confirmation',
      'E-mail bestätigen*',
    ),
    labelRight: i18nLabel(store.translationLabels, 'Power_Of_Attorney_Right', 'Rechte'),
    helpTextRight: i18nLabel(
      store.translationLabels,
      'Power_Of_Attorney_Right_Help_Text',
      'Dieser Zugriff ermöglicht die Berechtigung auf alle Funktionalitäten sowie Informationen auf Ihrem Portal.',
    ),
    labelCheckbox: i18nLabel(
      store.translationLabels,
      'Power_Of_Attorney_Checkbox',
      'Ich nehme zur Kenntnis, dass die oben angegebene E-Mail dieselbe E-Mail Adresse ist, mit welcher sich die Bevollmächtigte Person auch über Mein Konto (https://login.ag.ch/sls-ng) anmeldet oder registrieren wird.',
    ),
    labelNoEntries: i18nLabel(store.translationLabels, 'Power_Of_Attorney_No_Entries', 'Keine Einträge'),
    labelAddAdditionalPositions: i18nLabel(
      store.translationLabels,
      'Power_Of_Attorney_Add_Additional_Positions',
      'Weitere Position hinzufügen',
    ),
    labelSave: i18nLabel(store.translationLabels, 'Power_Of_Attorney_Save', 'Speichern'),
    labelCancel: i18nLabel(store.translationLabels, 'Power_Of_Attorney_Cancel', 'Löschen'),
    labelNext: i18nLabel(store.translationLabels, 'Power_Of_Attorney_Next', 'Jetzt losgehen'),
    errorLabels: store.translationLabels,
    piaAttorneys: [],
    attorneysBeingEdited: [],
    attorneysAvailable: false,
    error: store.mandatoryPowerOfAttorneyData.error,
    rolesTypes: store.translationOptions['authorities-roles-types']
      ? [
          ...store.translationOptions['authorities-roles-types'].map((option) => {
            return { value: option.id.toString(), name: option.name };
          }),
        ]
      : [],
  };

  const data: MandatoryPowerOfAttorneyDataStore = store.mandatoryPowerOfAttorneyData;
  if (data && data.authorities) {
    props.piaAttorneys = Object.values(data.authorities).map(
      (authority) =>
        (({
          role: authority.roleId,
          firstName: authority.firstName,
          lastName: authority.lastName,
          email: authority.email,
          emailConfirmation: authority.email,
          right: 'Voller Zugriff',
          checkboxValue: false,
        } as unknown) as Attorney),
    );
  }
  const headerProps: ZoomHeaderProps = mapStoreToHeaderProps(store);
  const footerProps: ZoomFooterProps = mapStoreToFooterProps(store);

  props.attorneysAvailable = props.piaAttorneys.length > 0;
  props.dossierId = data.dossierId;
  return {
    formProps: props,
    headerProps: headerProps,
    footerProps: footerProps,
  };
}

export default connect(mapStateToProps)(PowerOfAttorney);
