import { Action } from 'redux';
import { concat, Observable, of } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { Epic, ofType } from 'redux-observable';
import { catchError, debounceTime, map, mergeMap } from 'rxjs/operators';

import {
  PowerOfAttorneyDataAction,
  PowerOfAttorneyDataGetResultAction,
  PowerOfAttorneyDataAddItemAction,
  PowerOfAttorneyDataAddResultAction,
  PowerOfAttorneyDataDeleteResultAction,
  PowerOfAttorneyDataDeleteItemAction,
  PowerOfAttorneyDataActionType,
} from './actions';
import { handleRequestFailureInEpic } from '../../utils/error-handling';
import { customAjax } from '../../utils/ajax-wrapper';
import { Holder } from '../../pages/scholarship-holder/ScholarshipHolder';
import { RoutingAction, RoutingActionType } from './../routing/actions';
import { ErrorHandlingActionType } from '../error-handling/actions';

const loadPowerOfAttorneyData = (payload: AjaxResponse): PowerOfAttorneyDataGetResultAction => {
  return {
    type: PowerOfAttorneyDataAction.GET_RESULT,
    payload: payload.response,
  };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const processAddPoaError = (error: any): PowerOfAttorneyDataActionType | ErrorHandlingActionType => {
  if (error.status == 400) {
    return {
      type: PowerOfAttorneyDataAction.ADD_ERROR,
      payload: 'MandatoryPowerOfAttorney_Conflict_ErrorMessage',
    };
  }

  return handleRequestFailureInEpic(error);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const processDeletePoaError = (error: any): PowerOfAttorneyDataActionType | ErrorHandlingActionType => {
  if (error.status == 400) {
    return {
      type: PowerOfAttorneyDataAction.DELETE_ERROR,
      payload: 'MandatoryPowerOfAttorney_PoaCannotBeDeleted_ErrorMessage',
    };
  }
  return handleRequestFailureInEpic(error);
};

export const getMandatoryPowerOfAttorneyDataEpic: Epic<Action, Action> = (
  action$: Observable<Action>,
): Observable<Action> =>
  action$.pipe(
    ofType(PowerOfAttorneyDataAction.GET),
    mergeMap(() => {
      return customAjax({
        subRoute: `/partner/v1/authorities`,
        withCredentials: true,
        method: 'GET',
      }).pipe(
        map((response) => loadPowerOfAttorneyData(response)),
        catchError((error) => {
          return of(handleRequestFailureInEpic(error));
        }),
      );
    }),
  );

const powerOfAttorneyDataAdded = (payload: AjaxResponse): PowerOfAttorneyDataAddResultAction => {
  return {
    type: PowerOfAttorneyDataAction.ADD_RESULT,
    payload: { ...payload.response },
  };
};

export const addMandatoryPowerOfAttorneyDataEpic: Epic<Action, Action> = (
  action$: Observable<Action>,
): Observable<Action> =>
  action$.pipe(
    ofType(PowerOfAttorneyDataAction.ADD_ITEM),
    mergeMap((action) => {
      const item = (action as PowerOfAttorneyDataAddItemAction).payload;

      return customAjax({
        subRoute: `/partner/v1/mandatory-authorities`,
        headers: {
          'Content-Type': 'application/json',
        },
        withCredentials: true,
        method: 'POST',
        body: JSON.stringify(item),
      }).pipe(
        map((response) => powerOfAttorneyDataAdded(response)),
        catchError((error) => {
          return of(processAddPoaError(error));
        }),
      );
    }),
  );

const powerOfAttorneyDataDeleted = (payload: string): PowerOfAttorneyDataDeleteResultAction => {
  return {
    type: PowerOfAttorneyDataAction.DELETE_RESULT,
    email: payload,
  };
};

export const deleteMandatoryPowerOfAttorneyDataEpic: Epic<Action, Action> = (
  action$: Observable<Action>,
): Observable<Action> =>
  action$.pipe(
    ofType(PowerOfAttorneyDataAction.DELETE_ITEM),
    mergeMap((action) => {
      const email = (action as PowerOfAttorneyDataDeleteItemAction).email;

      return customAjax({
        subRoute: `/partner/v1/authorities?email=${email}`,
        withCredentials: true,
        method: 'DELETE',
      }).pipe(
        map(() => powerOfAttorneyDataDeleted(email)),
        catchError((error) => {
          return of(processDeletePoaError(error));
        }),
      );
    }),
  );

const redirectToOwnDossier = (holderData: Array<Holder>): RoutingActionType => {
  const ownData = holderData.find((holder) => holder.roleId === 1);
  if (ownData?.dossierId) {
    return {
      type: RoutingAction.REDIRECT_TO,
      payload: '/dashboard/dossier/' + ownData.dossierId,
    };
  }

  return {
    type: RoutingAction.ERROR,
  };
};

export const getDossierProfilesAndRedirectToDashboardEpic: Epic<Action, Action> = (
  action$: Observable<Action>,
): Observable<Action> =>
  action$.pipe(
    ofType(PowerOfAttorneyDataAction.REDIRECT_TO_DASHBOARD),
    mergeMap(() => {
      //return concat()
      const finalizeObservable = customAjax({
        subRoute: `/partner/v1/account/finalize-initial-setup`,
        withCredentials: true,
        method: 'POST',
      });

      const getDossierProfiles = customAjax({
        subRoute: `/partner/v1/dossier-profiles`,
        method: 'GET',
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        },
      });

      return concat(finalizeObservable, getDossierProfiles).pipe(
        map((response, index) => {
          if (index === 0) {
            return { type: 'VOID' };
          }
          return redirectToOwnDossier(response.response as Array<Holder>);
        }),
      );
    }),
  );

export const mandotoryPoaTriggerErrorHideEpic: Epic<Action, Action> = (
  action$: Observable<Action>,
): Observable<Action> =>
  action$.pipe(
    ofType(PowerOfAttorneyDataAction.ADD_ERROR, PowerOfAttorneyDataAction.DELETE_ERROR),
    debounceTime(5000),
    map(() => {
      return { type: PowerOfAttorneyDataAction.HIDE_ERROR };
    }),
  );
