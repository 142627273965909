import { Button, Checkbox, FormControlLabel, Grid, TextField } from '@material-ui/core';
import produce from 'immer';
import React, { ChangeEvent, ReactElement, useEffect, useState } from 'react';
import { Action } from 'redux';
import { RoutingAction, RoutingRedirectToAction } from '../../../store/routing/actions';
import { ZoomArrowDownIcon } from '../../icons/ZoomArrowDownIcon';
import { ZoomArrowUpIcon } from '../../icons/ZoomArrowUpIcon';
import { ZoomPersonIcon } from '../../icons/ZoomPersonIcon';
import { Holder } from '../../../pages/scholarship-holder/ScholarshipHolder';
import './HolderDropDown.scss';

export interface HolderDropDownProps {
  holders: Array<Holder>;
  activeHolder: Holder;
  labelActiveHolder: string;
  labelGoToDashboard: string;
  labelGoToData: string;
  labelGoToEducations: string;
  labelGoToMessages: string;
  labelSearchPlaceholder: string;
  labelCheckboxOnlyUsersWithMessages: string;
  dispatch?: (action: Action) => void;
}

export const HolderDropDown = (props: HolderDropDownProps): ReactElement => {
  const baseWidth = 350;
  const [isOpen, setIsOpen] = useState(false);
  const [y, setY] = useState(0);
  const [x, setX] = useState(0);
  const [width, setWidth] = useState(baseWidth);
  const [openedHolders, setOpenedHolders] = useState([] as Array<number>);
  const [query, setQuery] = useState('');
  const [onlyHoldersWithNewMessages, setOnlyHoldersWithNewMessages] = useState(false);

  const showSearchInput = props.holders.length > 1;

  let iconToShow = <ZoomArrowDownIcon viewBox="0 0 80 80" />;
  if (isOpen) {
    iconToShow = <ZoomArrowUpIcon viewBox="0 0 80 80" />;
  }

  const toggleOpenClose = () => {
    setIsOpen(!isOpen);
    resizeInfoWindow();
  };

  const resizeInfoWindow = () => {
    const button = document.getElementById('holder-selection-btn');
    if (button) {
      let width = baseWidth;
      if (window.innerWidth < 600) {
        width = button.clientWidth;
      }
      setY(button.offsetTop + button.offsetHeight);
      setX(button.offsetLeft);
      setWidth(width);
    }
  };

  useEffect(() => {
    window.onresize = resizeInfoWindow;
  }, []);

  const onQueryChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setQuery(e.target.value);
  };

  const onCheckOnlyHoldersWithNewMessages = () => {
    setOnlyHoldersWithNewMessages(!onlyHoldersWithNewMessages);
  };

  const toggleHolderInfoState = (holder: Holder): void => {
    const indexInArray = openedHolders.findIndex((openedDossierId) => holder.dossierId === openedDossierId);
    const newOpenedArrayState = produce(openedHolders, (draft) => {
      if (indexInArray === -1) {
        draft.push(holder.dossierId);
      } else {
        draft.splice(indexInArray, 1);
      }
      return draft;
    });
    setOpenedHolders(newOpenedArrayState);
  };

  const checkInclude = (query: string, fieldValue?: string): boolean => {
    if (!fieldValue) {
      return false;
    }
    return fieldValue.toLocaleLowerCase().includes(query.toLowerCase());
  };

  const filterHolderByTerm = ({ email, firstName, lastName, svn }: Holder, query: string): boolean => {
    if (query.length === 0) {
      return true;
    }
    return (
      checkInclude(query, email) ||
      checkInclude(query, firstName) ||
      checkInclude(query, lastName) ||
      checkInclude(query, svn)
    );
  };

  const filterHolderByMultipleTerms = (holder: Holder, queryTerms: Array<string>): boolean => {
    return queryTerms.map((query) => filterHolderByTerm(holder, query)).reduce((prev, current) => prev && current);
  };

  const navigateTo = (url: string) => {
    if (props.dispatch) {
      const navigateToAction: RoutingRedirectToAction = { type: RoutingAction.REDIRECT_TO, payload: url };
      props.dispatch(navigateToAction);
    }
  };

  const getNavButtons = (holder: Holder): ReactElement => {
    return (
      <Grid container className="holder-controls">
        <Grid item xs={6}>
          <Button
            variant="outlined"
            fullWidth
            color="primary"
            size="small"
            onClick={() => navigateTo(`/dashboard/dossier/${holder.dossierId}`)}
          >
            {props.labelGoToDashboard}
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            variant="outlined"
            fullWidth
            color="primary"
            size="small"
            onClick={() => navigateTo(`/my-data/dossier-intranet/mydata/${holder.dossierId}`)}
          >
            {props.labelGoToData}
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            variant="outlined"
            fullWidth
            color="primary"
            size="small"
            onClick={() => navigateTo(`/dossier-intranet/myformation/${holder.dossierId}`)}
          >
            {props.labelGoToEducations}
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            variant="outlined"
            fullWidth
            color="primary"
            size="small"
            onClick={() => navigateTo(`/messages/${holder.dossierId}`)}
          >
            {props.labelGoToMessages}
          </Button>
        </Grid>
      </Grid>
    );
  };

  const holderToReactElement = (holder: Holder): ReactElement => {
    let activeClassName = 'active-holder';
    if (holder.dossierId !== props.activeHolder.dossierId) {
      activeClassName = '';
    }

    return !showSearchInput ? (
      <React.Fragment key={holder.svn}>{getNavButtons(holder)}</React.Fragment>
    ) : (
      <React.Fragment key={holder.svn}>
        <Button
          variant="text"
          fullWidth
          style={{ justifyContent: 'flex-start', alignItems: 'center' }}
          onClick={() => {
            toggleHolderInfoState(holder);
          }}
        >
          {holder.unreadNotifications && <span className="badge-holder" />}
          {showSearchInput && <ZoomPersonIcon viewBox="0 0 80 80" fontSize="large" htmlColor="#007ab8" />}
          {showSearchInput && (
            <div className="holder-content">
              <div className={activeClassName}>
                {holder.firstName} {holder.lastName}
              </div>
              <div className="holder-additional-info" style={{ maxWidth: width - 100 }}>
                <small className={activeClassName}>
                  {holder.svn} {holder.email}
                </small>
              </div>
            </div>
          )}
          {openedHolders.includes(holder.dossierId) && (
            <ZoomArrowUpIcon viewBox="0 0 80 80" fontSize="large" htmlColor="#007ab8" />
          )}
          {!openedHolders.includes(holder.dossierId) && (
            <ZoomArrowDownIcon viewBox="0 0 80 80" fontSize="large" htmlColor="#007ab8" />
          )}
        </Button>
        {openedHolders.includes(holder.dossierId) && getNavButtons(holder)}
      </React.Fragment>
    );
  };

  const filteredHolders = props.holders
    .filter((holder) => {
      return holder.dossierId !== props.activeHolder.dossierId;
    })
    .filter((holder) => {
      if (onlyHoldersWithNewMessages) {
        return holder.unreadNotifications;
      }
      return true;
    })
    .filter((holder) => {
      if (query.length > 0) {
        return filterHolderByMultipleTerms(holder, query.split(' '));
      }
      return true;
    })
    .map(holderToReactElement);

  const ActiveHolderProps = (props: HolderDropDownProps): ReactElement => {
    return (
      <div className="holder-nav-div">
        {props.labelActiveHolder}{' '}
        <b>
          {props.activeHolder.firstName} {props.activeHolder.lastName}
        </b>
      </div>
    );
  };

  /***
   * Return scaled person icon
   * for holder selection drop down
   * not using startIcon / endIcon properties
   * they seem a bit fidgety
   */
  const HolderSelectionPersonIcon = (): ReactElement => {
    return (
      <ZoomPersonIcon
        viewBox="0 0 70 70"
        htmlColor="#007ab8"
        style={{
          transform: 'scale(1.5)',
        }}
      />
    );
  };

  /***
   * returning the holder selection button
   * Be careful here: selection button width
   * must match dropdown width.
   * please check width {{ width: '352px' }}
   */
  const HolderSelectionButton = (holder: Holder): ReactElement => {
    return window.innerWidth < 600 ? (
      <Button
        variant="outlined"
        color="primary"
        id="holder-selection-btn"
        onClick={toggleOpenClose}
        fullWidth={true}
        style={{ display: 'flex', justifyContent: 'flex-start' }}
      >
        <div style={{ marginLeft: '8px' }}>
          {holder.unreadNotifications && <span className="badge-holder-selection" />}
          <HolderSelectionPersonIcon />
        </div>
        <div style={{ marginLeft: '13px' }}>
          <ActiveHolderProps {...props} />
        </div>
        {iconToShow}
      </Button>
    ) : (
      <Button
        variant="outlined"
        color="primary"
        id="holder-selection-btn"
        onClick={toggleOpenClose}
        style={{ minWidth: '352px', display: 'flex', justifyContent: 'flex-start' }}
        fullWidth={false}
      >
        <div style={{ marginLeft: '8px' }}>
          {holder.unreadNotifications && <span className="badge-holder-selection" />}
          <HolderSelectionPersonIcon />
        </div>
        <div style={{ marginLeft: '13px' }}>
          <ActiveHolderProps {...props} />
        </div>
        {iconToShow}
      </Button>
    );
  };

  return (
    <div className="holder-selection">
      <HolderSelectionButton
        dossierId={props.activeHolder.dossierId}
        unreadNotifications={props.activeHolder.unreadNotifications}
      />
      {isOpen && (
        <div
          className={showSearchInput ? 'overlay-menu' : 'overlay-menu-one-holder'}
          id="holder-overlay-menu"
          style={{ top: y + 1, left: x, width: width }}
        >
          {showSearchInput && (
            <div className="search-field">
              <TextField
                fullWidth
                variant="outlined"
                value={query}
                size="small"
                placeholder={props.labelSearchPlaceholder}
                onChange={onQueryChange}
              />
              <FormControlLabel
                id="control-only-users-with-new-messages"
                control={<Checkbox checked={onlyHoldersWithNewMessages} onChange={onCheckOnlyHoldersWithNewMessages} />}
                label={props.labelCheckboxOnlyUsersWithMessages}
              />
            </div>
          )}
          {[holderToReactElement(props.activeHolder), ...filteredHolders]}
        </div>
      )}
    </div>
  );
};
