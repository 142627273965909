import { connect } from 'react-redux';
import { DashboardProps, Dashboard } from './Dashboard';
import { WIAAStore } from '../../store/types';
import { i18nLabel } from '../../store/i18n/helpers';
import { withRouter } from 'react-router-dom';
import { Education, ZoomUserInfoProps } from '../../components/base-page-components/user/user-info/ZoomUserInfo';
import { ZoomDebtInfoProps } from '../../components/base-page-components/debt/ZoomDebtInfo';
import { ZoomPayoutInfoProps } from '../../components/base-page-components/payout/payout-info/ZoomPayoutInfo';
import { mapStoreToHeaderProps, ZoomHeaderProps } from '../../components/base-page-components/header/ZoomHeader';
import { mapStoreToFooterProps, ZoomFooterProps } from '../../components/base-page-components/footer/ZoomFooter';
import moment from 'moment';
import { formatUnreadNotifications } from '../../utils/unread-notifications';
import { mapStoreToHolderDropDownProps } from '../../utils/mappers';

// Map global store to component parameters
// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export function mapDashboardStateToProps(store: WIAAStore, urlProps: any): DashboardProps {
  const { dossierId } = urlProps.match.params;
  const headerProps: ZoomHeaderProps = mapStoreToHeaderProps(store, dossierId);
  const footerProps: ZoomFooterProps = mapStoreToFooterProps(store);
  const canCreateNewDemand = store.userMyData.canCreateNewDemand;
  const streamId = store.userMyData.canCreateNewDemand?.latestDemandSummaryDocument?.streamId;
  const demandStatus = store.userMyData.canCreateNewDemand?.latestDemandSummaryDocument?.demandStatus;
  const personalData = store.myDataOverview?.personalData ? store.myDataOverview.personalData : {};
  const addressData = store.myDataOverview?.addressData ? store.myDataOverview.addressData : {};
  const contactData = store.myDataOverview?.contactData ? store.myDataOverview.contactData : {};
  const country = store.translationOptions.nationalities?.find(
    (c) => c.name === (addressData.countryIdType ? addressData.countryIdType.toString() : '-1'),
  );
  const currentUserInfo: ZoomUserInfoProps = {
    ...store.dashboardData.userInfo,
    firstName: personalData.firstName ? personalData.firstName : '',
    lastName: personalData.lastName ? personalData?.lastName : '',
    ahv13n: personalData.ahvNo ? personalData.ahvNo : '',
    postalCode: addressData.zipCode ? addressData.zipCode : '',
    street: addressData.street ? addressData.street : '',
    streetNumber: addressData.houseNumber ? addressData.houseNumber : '',
    city: addressData.town ? addressData.town : '',
    country: country ? country.name : '',
    dateOfBirth: personalData?.dateOfBirth
      ? moment(personalData.dateOfBirth).add(12, 'hours').format('DD.MM.YYYY')
      : '',
    emailAddress: contactData.emailAddress ? contactData.emailAddress : '',
    phoneNumber: contactData.phoneNumberPrivate ? contactData.phoneNumberPrivate : '',
    dossierId: dossierId,
  };

  const currentEducation = store.userMyEducationData.formations?.find(
    (education) => education.currentFormation === true,
  );

  const currentEducationFormated: Education = {
    name: currentEducation ? (currentEducation?.formationArt ? currentEducation.formationArt : '') : '',
    institution: currentEducation
      ? currentEducation?.formationInstitution
        ? currentEducation.formationInstitution
        : ''
      : '',
    duration: currentEducation
      ? currentEducation?.formationStartDate && currentEducation?.formationEndDate
        ? currentEducation.formationStartDate + ' - ' + currentEducation.formationEndDate
        : ''
      : '',
  };

  const actualUserAttorneys = store.authoritiesData.authorities;

  const userInfo: ZoomUserInfoProps = {
    ...currentUserInfo,
    education: currentEducation ? [currentEducationFormated] : [],
    powerOfAttorneys: [...actualUserAttorneys],
    labelEditData: i18nLabel(store.translationLabels, 'Dashboard_User_Info_Edit_My_Data', 'Meine Daten bearbeiten'),
    labelEditEducation: i18nLabel(
      store.translationLabels,
      'Dashboard_User_Info_Edit_Education',
      'Ausbildungsstand melden',
    ),
    labelEditPowerOfAttorney: i18nLabel(
      store.translationLabels,
      'Dashboard_User_Info_Edit_PoA',
      'Vollmachten verwalten',
    ),
    labelMyData: i18nLabel(store.translationLabels, 'Dashboard_User_Info_My_Data', 'Meine Daten'),
    labelMyEducation: i18nLabel(store.translationLabels, 'Dashboard_User_Info_My_Education', 'Meine Ausbildung'),
    labelMyPowerOfAttorney: i18nLabel(store.translationLabels, 'Dashboard_User_Info_PoA', 'Meine Vollmachten'),
    labelNoEducations: i18nLabel(
      store.translationLabels,
      'Dashboard_User_Info_No_Educations',
      'Keine Ausbildung erfasst',
    ),
    labelNoMyData: i18nLabel(
      store.translationLabels,
      'Dashboard_User_Info_No_My_Data',
      'Daten werden nach dem ersten Stipendienantrag angezeigt',
    ),
    labelChooseEducationState: i18nLabel(
      store.translationLabels,
      'Dashboard_User_Info_Education_Default_State',
      'Ausbildungsstand melden',
    ),
    labelEducationFinishedState: i18nLabel(
      store.translationLabels,
      'Dashboard_User_Info_Education_Finished',
      'Ausbildung abgeschlossen',
    ),
    labelEducationChangedState: i18nLabel(
      store.translationLabels,
      'Dashboard_User_Info_Education_Changed',
      'Ausbildung gewechselt',
    ),
    labelEducationInterruptedState: i18nLabel(
      store.translationLabels,
      'Dashboard_User_Info_Education_Interrupted',
      'Ausbildung unterbrochen',
    ),
    labelEducationCanceledState: i18nLabel(
      store.translationLabels,
      'Dashboard_User_Info_Education_Canceled',
      'Ausbildung abgebrochen',
    ),
    labelAllEducations: i18nLabel(store.translationLabels, 'Dashboard_User_Info_All_Educations', 'Alle Ausbildungen'),
  };

  const debtInfo: ZoomDebtInfoProps = {
    ...store.dashboardData.debtInfo,
    title: i18nLabel(store.translationLabels, 'Dashboard_Dept_Info_Title', 'Meine Schulden beim Kanton Aargau'),
    totalAmount: store.myLoanData.totalCharged ? store.myLoanData.totalCharged : 0,
    debtAmount: store.myLoanData.balance ? store.myLoanData.balance : 0,
    amount: store.myLoanData.totalRepayed ? store.myLoanData.totalRepayed : 0,
  };

  let payoutInfo: ZoomPayoutInfoProps = {
    ...store.dashboardData.payoutInfo,
    payoutLoan: undefined,
    payoutScholarship: undefined,
  };
  const sortingArray = [...store.myDemandsData.myDemands];
  const sortedByRecentDate = sortingArray.sort((a, b) => {
    const aId = a.detail.id ? a.detail.id : 0;
    const bId = b.detail.id ? b.detail.id : 0;
    return bId - aId;
  });

  const payoutInfoStoredData = sortedByRecentDate.find((myDemandData) => myDemandData.detail?.decisionDate);
  if (payoutInfoStoredData) {
    payoutInfo = {
      payoutScholarship: {
        name:
          i18nLabel(store.translationLabels, 'Dashboard_Payout_Info_Label_Scholarship', 'Stipendium') +
          ' ' +
          (payoutInfoStoredData.detail.demandYear ? payoutInfoStoredData.detail.demandYear : ''),
        labelLegendName: i18nLabel(
          store.translationLabels,
          'Dashboard_Payout_Info_Scholarship_Legend_Paid_Out',
          'Stipendium ausbezahlt',
        ),
        status:
          payoutInfoStoredData.scholarshipTotal.toPay === 0
            ? i18nLabel(
                store.translationLabels,
                'Dashboard_Payout_Info_Scholarship_Legend_Remaining_Paid_Out',
                'ausbezahlt',
              )
            : i18nLabel(store.translationLabels, 'Dashboard_Payout_Info_Scholarship_Legend_Remaining_Open', 'offen'),
        institution: payoutInfoStoredData.detail.formationInstitute
          ? payoutInfoStoredData.detail.formationInstitute
          : '',
        accountAddress: '1000 Teststadt',
        accountHolder: payoutInfoStoredData.paymentDetails[0] ? payoutInfoStoredData.paymentDetails[0].recipient : '',
        labelAmount: 'Betrag',
        labelAccount: 'Zielkonto',
        labelTransferDate: 'Überweisung',
        labelChangeAccount: 'Konto ändern',
        amount: payoutInfoStoredData.scholarshipTotal.paid,
        iban: 'IBAN 11234558495849',
        isLoan: false,
        totalAmount: payoutInfoStoredData.scholarshipTotal.total,
        transferDate: payoutInfoStoredData.detail.decisionDate
          ? moment(payoutInfoStoredData.detail.decisionDate).format('DD.MM.YYYY').toString()
          : '',
      },
      payoutLoan: {
        name:
          i18nLabel(store.translationLabels, 'Dashboard_Payout_Info_Label_Credit', 'Darlehen') +
          ' ' +
          (payoutInfoStoredData.detail.demandYear ? payoutInfoStoredData.detail.demandYear.toString() : ''),
        labelLegendName: i18nLabel(
          store.translationLabels,
          'Dashboard_Payout_Info_Credit_Legend_Paid_Out',
          'Darlehen ausbezahlt',
        ),
        status:
          payoutInfoStoredData.loanTotal.toPay === 0
            ? i18nLabel(store.translationLabels, 'Dashboard_Payout_Info_Credit_Legend_Remaining_Paid_Out', 'ausbezahlt')
            : i18nLabel(store.translationLabels, 'Dashboard_Payout_Info_Credit_Legend_Remaining_Open', 'offen'),
        institution: payoutInfoStoredData.detail.formationInstitute
          ? payoutInfoStoredData.detail.formationInstitute
          : '',
        accountAddress: '1000 Teststadt',
        accountHolder: payoutInfoStoredData.paymentDetails[0] ? payoutInfoStoredData.paymentDetails[0].recipient : '',
        labelAmount: 'Betrag',
        labelAccount: 'Zielkonto',
        labelTransferDate: 'Überweisung',
        labelChangeAccount: 'Konto ändern',
        amount: payoutInfoStoredData.loanTotal.paid,
        iban: 'IBAN 11234558495849',
        isLoan: true,
        totalAmount: payoutInfoStoredData.loanTotal.total,
        transferDate: payoutInfoStoredData.detail.decisionDate
          ? moment(payoutInfoStoredData.detail.decisionDate).format('DD.MM.YYYY').toString()
          : '',
      },
      title: i18nLabel(
        store.translationLabels,
        'Dashboard_Payout_Info_Title',
        'Laufenden Anträge für Stipendien und Darlehen',
      ),
      labelDetails: i18nLabel(store.translationLabels, 'Dashboard_Payout_Info_Label_Details', 'Details'),
    };
  }

  return {
    ...store.dashboardData,
    headerProps,
    footerProps,
    canCreateNewDemand:
      canCreateNewDemand?.canCreateNewDemand !== undefined ? canCreateNewDemand.canCreateNewDemand : true,
    demandYear: canCreateNewDemand?.demandYear ? canCreateNewDemand.demandYear : '',
    streamId,
    demandStatus,
    debtInfo,
    userInfo,
    payoutInfo,
    holderDropDownProps: mapStoreToHolderDropDownProps(store, parseInt(dossierId)),
    notifications: formatUnreadNotifications(
      store.notificationData.notifications.filter((notification) => notification.isNew),
    ),
    title: i18nLabel(store.translationLabels, 'Dashboard_Title', 'Übersicht'),
    labelBackArrow: i18nLabel(store.translationLabels, 'Dashboard_Back_Arrow', 'zurück'),
    labelCurrentScholarhipInfo: i18nLabel(
      store.translationLabels,
      'Dashboard_Scholarship_Current_Demand',
      'Aktueller Stipendienantrag',
    ),
    labelNewScholarship: i18nLabel(
      store.translationLabels,
      'Dashboard_Scholarship_New_Demand',
      'Neuer Stipendienantrag',
    ),
    labelDownloadDemandSummary: i18nLabel(
      store.translationLabels,
      'Dashboard_Download_Demand_Summary',
      'PDF-Dokument herunterladen',
    ),
    labelDemandSuccessfullyUploadedNotYetImported: i18nLabel(
      store.translationLabels,
      'Dashboard_Demand_Successfully_Uploaded_Not_Yet_Imported',
      'Ihr Gesuch wurde erfolgreich eingereicht',
    ),
    labelDemandSuccessfullyUploaded: i18nLabel(
      store.translationLabels,
      'Dashboard_Demand_Successfully_Uploaded',
      'Ihr Gesuch für das Ausbildungsjahr',
    ),
    labelDemandSuccessfullyUploadedSuffix: i18nLabel(
      store.translationLabels,
      'Dashboard_Demand_Successfully_Uploaded_Suffix',
      'wird in den nächsten 2 Monaten bearbeitet',
    ),
    labelAllScholarships: i18nLabel(
      store.translationLabels,
      'Dashboard_Label_All_Scholarships',
      'Alle Stipendienanträge',
    ),
    labelAllPayouts: i18nLabel(
      store.translationLabels,
      'Dashboard_Label_All_Payments',
      'Eingereichte Anträge und Auszahlungen',
    ),
    labelAllContracts: i18nLabel(store.translationLabels, 'Dashboard_Label_All_Contracts', 'Details zu den Darlehen'),
    labelContinueDemandEdition: i18nLabel(
      store.translationLabels,
      'Dashboard_Scholarship_Continue_Fillin',
      'Weiter ausfüllen',
    ),
    labelDebtInfoTitle: i18nLabel(
      store.translationLabels,
      'Dashboard_Debt_Info_Title',
      'Meine Schulden beim Kanton Aargau',
    ),
    labelDebtInfoSubtitle: i18nLabel(store.translationLabels, 'Dashboard_Debt_Info_Subtitle', 'Offene Darlehensschuld'),
    labelDebtInfoLegendRepayment: i18nLabel(
      store.translationLabels,
      'Dashboard_Debt_Info_Legend_Repayment',
      'Darlehen zurückbezahlt',
    ),
    infoText: i18nLabel(
      store.translationLabels,
      'Dashboard_Debt_Info_Info_Text',
      'Für die Steuererklärung ist die Saldoliste per Ende Jahr ca. ab 21 Januar zum Download bereit.',
    ),
    labelDebtInfoLegendStatus: i18nLabel(store.translationLabels, 'Dashboard_Debt_Info_Legend_Status', 'offen'),
    labelDebtInfoTotalAmount: i18nLabel(store.translationLabels, 'Dashboard_Debt_Info_Total_Amount', 'Total'),
    labelDemandDetails: i18nLabel(store.translationLabels, 'Dashboard_Scholarship_Details', 'Details'),
    labelDebt: i18nLabel(store.translationLabels, 'Dashboard_Debt', 'ausbezahlt'),
    labelMarkAsRead: i18nLabel(store.translationLabels, 'Notifications_Box_Mark_As_Read', 'Schliessen'),
    labelGoToAction: i18nLabel(store.translationLabels, 'Notifications_Box_Go_To_Action', 'Zum Upload'),
    dossierId: Number(dossierId),
    isLoading:
      store.userData.isLoading &&
      store.userMyData.isLoading &&
      store.userMyEducationData.isLoading &&
      store.holderData.isLoading &&
      store.myDataOverview.isLoading &&
      store.authoritiesData.isLoading &&
      store.myDemandsData.isLoading &&
      store.myLoanData.isLoading,
  };
}

// Default export the connected component
export default withRouter(connect(mapDashboardStateToProps)(Dashboard));
