import { Button, Chip, createStyles, LinearProgress, Theme, withStyles } from '@material-ui/core';
import React, { ReactElement, useState } from 'react';
import {
  UserDataAction,
  UserDataActionType,
  UserDataLaunchCreateNewDemandAction,
} from '../../../../store/user-data/actions';
import { useHistory } from 'react-router-dom';
import './ZoomScholarshipItem.scss';
import { DefaultDemand } from '../../../../utils/default-values';

export interface ZoomScholarshipItemProps {
  name: string;
  institution: string;
  status: string;
  completeness: number;
  active: boolean;
  tags: Array<string>;
  labelContinueFillin: string;
  labelDetails: string;
  labelCompleted: string;
  dossierId: number;
  demandId: number;
  pifId: number;
  dispatch?: (action: UserDataActionType) => void;
}

const ScholarshipLinearProgress = withStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 15,
      borderRadius: 3,
    },
    colorPrimary: {
      backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    },
    bar: {
      borderRadius: 5,
      backgroundColor: theme.palette.primary.main,
    },
  }),
)(LinearProgress);

export function ZoomScholarshipItem(props: ZoomScholarshipItemProps): ReactElement {
  const [tags] = useState(props.tags ? props.tags : []);
  const tagsToDisplay = tags.map((tag) => (
    <Chip className="scholarship-info-tag" key={tag} variant="outlined" label={tag}></Chip>
  ));
  const [demandIsReset, setDemandIsReset] = useState(false);
  const history = useHistory();
  const resetActiveDemand = () => {
    if (props.dispatch) {
      const createNewDemandAction = {
        type: UserDataAction.LAUNCH_CREATE_NEW_DEMAND,
        dossierId: props.dossierId,
        newDemand: DefaultDemand,
      } as UserDataLaunchCreateNewDemandAction;
      props.dispatch(createNewDemandAction);
      setDemandIsReset(true);
    }
  };

  const goToActiveDemand = () => {
    if (props.dispatch) {
      history.push({
        pathname: `/scholarship/${props.dossierId}/demand/${props.demandId}/pif/${props.pifId}`,
      });
    }
  };

  return (
    <div className="scholarship-info-content">
      <h2>{props.name}</h2>
      <p> {props.institution} </p>
      {props.completeness !== 100 && (
        <ScholarshipLinearProgress variant="determinate" value={props.completeness}></ScholarshipLinearProgress>
      )}
      {props.completeness !== 100 && (
        <p>
          {props.completeness}% {props.labelCompleted}
        </p>
      )}
      <div className="scholarship-info-controls clearfix">
        <div className="scholarship-info-tags">{tagsToDisplay}</div>
        <div className="scholarship-info-btns">
          {props.active && (
            <>
              <p className="scholarship-info-btn-block">
                <Button
                  id="reset-active-demand"
                  className="scholarship-info-btn"
                  disabled={demandIsReset}
                  variant="outlined"
                  color="primary"
                  onClick={resetActiveDemand}
                >
                  Daten zurücksetzen
                </Button>
              </p>
              <p className="scholarship-info-btn-block">
                <Button className="scholarship-info-btn" variant="contained" color="primary" onClick={goToActiveDemand}>
                  {props.labelContinueFillin}
                </Button>
              </p>
            </>
          )}
          {props.active === false && (
            <p className="scholarship-info-btn-block">
              <Button className="scholarship-info-btn" variant="outlined" color="primary">
                {props.labelDetails}
              </Button>
            </p>
          )}
        </div>
      </div>
    </div>
  );
}
