import { CvItemData } from './types';

export enum CVDataAction {
  GET = 'CV_DATA_GET',
  GET_RESULT = 'CV_DATA_GET_RESULT',
  EDIT_ITEM = 'CV_ITEM_DATA_EDIT',
  EDIT_RESULT = 'CV_ITEM_DATA_EDIT_RESULT',
  ADD_ITEM = 'CV_ITEM_ADD',
  ADD_RESULT = 'CV_ITEM_ADD_RESULT',
  DELETE_ITEM = 'CV_ITEM_DELETE',
  DELETE_RESULT = 'CV_ITEM_DELETE_RESULT',
  EMPTY_CV = 'CV_DATA_EMPTY',
}

export interface CVDataGetCVAction {
  type: typeof CVDataAction.GET;
  demandId: number;
}

export interface CVDataGetCVResultAction {
  type: typeof CVDataAction.GET_RESULT;
  payload: CvItemData[];
}

export interface CVDataEditItemAction {
  type: typeof CVDataAction.EDIT_ITEM;
  payload: CvItemData;
  demandId: number;
}

export interface CVDataEditResultAction {
  type: typeof CVDataAction.EDIT_RESULT;
  payload: CvItemData;
}

export interface CVDataAddItemAction {
  type: typeof CVDataAction.ADD_ITEM;
  payload: CvItemData;
  demandId: number;
}

export interface CVDataAddResultAction {
  type: typeof CVDataAction.ADD_RESULT;
  payload: CvItemData;
}

export interface CVDataDeleteItemAction {
  type: typeof CVDataAction.DELETE_ITEM;
  payload: number;
  demandId: number;
}

export interface CVDataDeleteResultAction {
  type: typeof CVDataAction.DELETE_RESULT;
  payload: number;
}

export type CVDataActionType =
  | CVDataGetCVAction
  | CVDataGetCVResultAction
  | CVDataEditItemAction
  | CVDataEditResultAction
  | CVDataAddItemAction
  | CVDataAddResultAction
  | CVDataDeleteItemAction
  | CVDataDeleteResultAction;
