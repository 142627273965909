import { Button } from '@material-ui/core';
import React, { ChangeEvent, ReactElement, useState } from 'react';
import { NotificationTypes } from '../../store/notifications/actions';
import { CaseCreateDto } from '../../store/notifications/types';
import { ZoomCaseFileUpload, ZoomCaseFileUploadProps } from '../forms/file-upload/ZoomCaseFileUpload';
import { ZoomFile, ZoomFileInProgress } from './../forms/file-upload/ZoomFileUpload';

export interface ReplyWithFileProps {
  message: string;
  typeId: number;
  labelSendMessage: string;
  labelDragDocument: string;
  labelOr: string;
  labelChooseDocument: string;
  labelSuccess: string;
  files: Array<ZoomFile>;
  filesInProgress: Array<ZoomFileInProgress>;
  dossierId: number;
  caseId?: number;
  stepId?: number;
  uploadError?: string;
  uploadSuccessful?: boolean;
  dispatch?: (action: NotificationTypes) => void;
  reply?: (message: CaseCreateDto) => void;
}

export default function ReplyWithFile(props: ReplyWithFileProps): ReactElement {
  const [message, setMessage] = useState(props.message ? props.message : '');
  const onMessageChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(e.target.value);
  };

  const reply = () => {
    setMessage('');

    if (props.reply) {
      props.reply({
        body: message,
        subject: 'NOT USED',
        caseId: props.caseId,
        typeId: props.typeId,
        stepId: props.stepId,
      });
    }
  };

  const caseFileUploadProps: ZoomCaseFileUploadProps = {
    ...props,
  };

  return (
    <div className="reply-container clearfix">
      <textarea id="reply-field" name="reply-field" onChange={onMessageChange} value={message}></textarea>
      <ZoomCaseFileUpload {...caseFileUploadProps}></ZoomCaseFileUpload>
      <p className="button-line">
        <Button variant="contained" color="primary" onClick={reply}>
          {props.labelSendMessage}
        </Button>
      </p>
    </div>
  );
}
