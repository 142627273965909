import { PermissionData, UserData } from './types';

export enum NewPermissionDataAction {
  SEARCH_USERS_GET = 'SEARCH_USERS_DATA_GET',
  SEARCH_USERS_GET_RESULT = 'SEARCH_USERS_DATA_GET_RESULT',
  ADD_ITEM = 'NEW_PERMISSION_DATA_ADD_ITEM',
  ADD_RESULT = 'NEW_PERMISSION_DATA_ADD_RESULT',
}

export interface NewPermissionSearchUsersGetAction {
  type: typeof NewPermissionDataAction.SEARCH_USERS_GET;
  currentUserId?: number;
  searchWord: string;
}

export interface NewPermissionSearchUsersGetResultAction {
  type: typeof NewPermissionDataAction.SEARCH_USERS_GET_RESULT;
  payload: UserData[];
}

export interface NewPermissionAddItemAction {
  type: typeof NewPermissionDataAction.ADD_ITEM;
  payload: PermissionData;
}

export interface NewPermissionAddResultAction {
  type: typeof NewPermissionDataAction.ADD_RESULT;
  payload: PermissionData;
}

export type NewPermissionActionType =
  | NewPermissionSearchUsersGetAction
  | NewPermissionSearchUsersGetResultAction
  | NewPermissionAddItemAction
  | NewPermissionAddResultAction;
