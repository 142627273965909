import produce from 'immer';
import { TranslationLabelsAction, TranslationLabelsActionType } from './actions';
import { OptionTranslation } from './types';
export const initialLabels: { [key: string]: string } = {};
export const initialOptions: { [key: string]: Array<OptionTranslation> } = {};

export const i18nReducer = (state = initialLabels, action: TranslationLabelsActionType): { [key: string]: string } => {
  return produce(state, (draft: { [key: string]: string }) => {
    if (action.type == TranslationLabelsAction.GET_ALL_RESULT) {
      const translationResult = action;
      draft = translationResult.payload.labels
        .map((value) => ({ [value.key]: value.translation }))
        .reduce((prev, curr) => ({ ...prev, ...curr }), { initial: 'INITIAL_LABEL' });
    }
    return draft;
  });
};

export const i18nOptionReducer = (
  state = initialOptions,
  action: TranslationLabelsActionType,
): { [key: string]: Array<OptionTranslation> } => {
  return produce(state, (draft: { [key: string]: Array<OptionTranslation> }) => {
    if (action.type == TranslationLabelsAction.GET_ALL_RESULT) {
      draft = action.payload.dropDowns;
    }
    return draft;
  });
};
