import { EducationChangeAction, EducationDataActionType } from '../../store/education-data/actions';
import { DegreeProgram, Institution, InstitutionType } from '../../store/education-data/types';

export interface EducationDataFormProps {
  vocationalBaccalaureate?: string;
  id?: string;
  inSwitzerland?: boolean;
  educationType?: string;
  educationTypeId?: number;
  educationFacilityName?: string;
  facilityId?: number;
  degreeId?: number;
  isEducationChangeForm: boolean;
  educationFacilityLocation?: string;
  educationDegreeDesignation?: string;
  educationDurationStart?: Date;
  educationDurationEnd?: Date;
  educationWorkload?: string;
  educationYearRepeat?: string;
  educationChanged?: string;
  educationOld?: string;
  educationNew?: string;
  educationAdditionalOccupation?: string;
  educationCredits?: string;
  educationDegree?: string;
  demandId: number;
  degreeProgram?: number;
  labelDegreeProgramId: string;
  labelEducationType: string;
  labelEducationFacilityName: string;
  labelEducationFacilityLocation: string;
  labelEducationDegreeDesignation: string;
  labelEducationDuration: string;
  labelEducationWorkload: string;
  labelEducationYearRepeat: string;
  labelEducationChanged: string;
  labelEducationOld: string;
  labelEducationNew: string;
  labelEducationAdditionalOccupation: string;
  labelEducationCredits: string;
  labelEducationDegree: string;
  labelInSwitzerland: string;
  labelVocationalBaccalaureate: string;
  labelSave: string;
  helpTextEducationType?: string;
  helpTextEducationFacilityLocation?: string;
  helpTextEducationDuration?: string;
  helpTextEducationYearRepeat?: string;
  helpTextEducationChanged?: string;
  helpTextEducationDegree?: string;
  educationWorkloadTypes?: { name: string; value: string }[];
  toggleButtonsTypes?: { name: string; value: string }[];
  //formationType?: { name: string; value: string }[];
  degreePrograms?: { name: string; value: string }[];
  availableInstitutionTypes?: InstitutionType[];
  availableInstitutions?: Institution[];
  availableDegreePrograms?: DegreeProgram[];
  inSwitzerlandTypes?: { name: string; value: string }[];
  title: string;
  errorLabels?: { [key: string]: string };
  dispatch?: (action: EducationDataActionType) => void;
  // Used for EducationChanged page - triggers EducationDataChange event
  submitChangeAction?: EducationChangeAction;
  labelEducationChangeReasonRequired?: string;
  labelCancel?: string;
  labelSaveChangeEducation?: string;
}

export interface EducationDataErrors {
  id?: string;
  educationType?: string;
  educationFacilityName?: string;
  educationFacilityLocation?: string;
  educationDegreeDesignation?: string;
  educationDurationStart?: string;
  educationDurationEnd?: string;
  educationWorkload?: string;
  educationYearRepeat?: string;
  educationChanged?: string;
  educationOld?: string;
  educationNew?: string;
  educationAdditionalOccupation?: string;
  educationCredits?: string;
  educationDegree?: string;
  educationTrainingFacilityType?: string;
  inSwitzerland?: string;
  degreeProgram?: string;
  educationTypeId?: string;
  facilityId?: string;
  degreeProgramId?: string;
  educationChangeReason?: string;
  vocationalBaccalaureate?: string;
}

export const educationDegreeDesignationForTypes = [17, 8, 9, 11, 15, 16, 26, 81, 82, 83, 99];
export const educationAdditionalOccupationForTypes = [8, 9, 11, 15, 16, 26, 81, 82, 83, 28, 92, 91, 99];
export const educationCreditsForTypes = [8, 9, 11, 15, 16, 26, 81, 82, 83];
export const educationVocationalBaccalaureate = [91, 94, 95];
