import moment from 'moment';

type CustomType = Date | null | undefined;

export const setDateToMiddleOfMonth = (date: CustomType): Date | undefined => {
  if (!date) {
    return undefined;
  }
  const dateMoment = moment(date);
  return dateMoment.set('D', 15).toDate();
};

export const setTimeToMiddleOfDay = (date: CustomType): Date | undefined => {
  if (!date) {
    return undefined;
  }
  const dateMoment = moment(date);
  return dateMoment.set('hour', 12).toDate();
};

export const isPersonUnder18 = (birthday: CustomType): boolean => {
  if (birthday === null || birthday === undefined) {
    return false;
  }

  const birthDateMoment = moment(birthday);
  return moment().subtract(18, 'years').isBefore(birthDateMoment);
};

export const setTimeToUserLocalTime = (date: Date): string => {
  const timeOffsetInMS = date.getTimezoneOffset() * 60000;
  if (timeOffsetInMS < 0) {
    return moment(date).subtract(timeOffsetInMS, 'milliseconds').format('DD.MM.YYYY HH:mm');
  }
  return moment(date).add(timeOffsetInMS, 'milliseconds').format('DD.MM.YYYY HH:mm');
};
