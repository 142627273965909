import produce from 'immer';
import moment from 'moment';
import { LastDemandDataTakeoverAction, LastDemandDataType } from '../last-demand-data-takeover/actions';
import { CVDataActionType, CVDataAction } from './actions';
import { CVDataStore, CvItemData } from './types';

export const initialState: CVDataStore = {
  items: [],
};

const orderCvItemsByStartDateAsc = (a: CvItemData, b: CvItemData): number => {
  // Usage of moment because sometimes activityStartDate is a string and not a DATE
  // depends how the server returns the values
  const aTime = a.activityStartDate ? moment(a.activityStartDate).valueOf() : 0;
  const bTime = b.activityStartDate ? moment(b.activityStartDate).valueOf() : 0;
  return aTime - bTime;
};

export function cvDataReducer(state = initialState, action: CVDataActionType | LastDemandDataType): CVDataStore {
  return produce(state, (draft) => {
    switch (action.type) {
      case CVDataAction.GET_RESULT:
        draft.items = action.payload.sort(orderCvItemsByStartDateAsc);
        break;
      case CVDataAction.EDIT_RESULT:
        const editedItem = action.payload;
        draft.items = state.items.map((item) => {
          if (item.id === editedItem.id) {
            return editedItem;
          }
          return item;
        });
        break;
      case CVDataAction.ADD_RESULT:
        const newItem = action.payload;
        const items = state.items.slice();
        items.push(newItem);
        draft.items = items.sort(orderCvItemsByStartDateAsc);
        break;
      case CVDataAction.DELETE_RESULT:
        const itemId = action.payload;
        draft.items = state.items.filter((item) => item.id !== itemId);
        break;
      case LastDemandDataTakeoverAction.RESULT:
        const lastDemandData = action.payload;
        draft.itemsFromPreviousDemand = lastDemandData.cvElements;
        break;
      default:
        break;
    }
    return draft;
  });
}
