import produce from 'immer';
import { AuthoritiesActionType, AuthoritiesDataAction } from './actions';
import { AuthoritiesDataStore } from './types';

export const initialState: AuthoritiesDataStore = {
  authorities: [],
};

export function authoritiesDataReducer(state = initialState, action: AuthoritiesActionType): AuthoritiesDataStore {
  return produce(state, (draft) => {
    switch (action.type) {
      case AuthoritiesDataAction.GET:
        draft.isLoading = true;
        break;
      case AuthoritiesDataAction.GET_RESULT:
        draft.authorities = action.payload;
        draft.isLoading = false;
        break;
      case AuthoritiesDataAction.EDIT_RESULT:
        const editedAuthorityData = action.payload;
        draft.authorities = state.authorities.map((authority) => {
          if (authority.permissionId === editedAuthorityData.permissionId) {
            return editedAuthorityData;
          }
          return authority;
        });
        break;
      case AuthoritiesDataAction.ADD_RESULT:
        const newAuthorityData = action.payload;
        const authorities = state.authorities.slice();
        authorities.push(newAuthorityData);
        draft.authorities = authorities;
        break;
      case AuthoritiesDataAction.DELETE_RESULT:
        const email = action.payload;
        draft.authorities = state.authorities.filter((authority) => authority.email !== email);
        break;
    }
    return draft;
  });
}
