import { EditAddressDataPage } from './types';
import { PostalCode } from '../contact-data/types';

export enum EditAddressDataPageAction {
  GET = 'EDIT_ADDRESS_DATA_PAGE_DATA_GET',
  GET_RESULT = 'EDIT_ADDRESS_DATA_PAGE_DATA_GET_RESULT',
  RESULT = 'EDIT_ADDRESS_DATA_PAGE_DATA_RESULT',
  NON_EXISTENT_EDIT_ADDRESS_DATA_PAGE = 'NON_EXISTENT_EDIT_ADDRESS_DATA_PAGE',
  CHANGE = 'EDIT_ADDRESS_DATA_PAGE_DATA_CHANGE',
  SUBMIT = 'EDIT_ADDRESS_DATA_PAGE_DATA_SUBMIT',
  GET_POSTAL_CODES = 'FATHER_DATA_GET_POSTAL_CODES',
  GET_POSTAL_CODES_RESULT = 'FATHER_DATA_GET_POSTAL_CODES_RESULT',
  GET_POSTAL_CODES_ERROR = 'FATHER_DATA_GET_POSTAL_CODES_ERROR',
}

export interface EditAddressDataPageGetAction {
  type: typeof EditAddressDataPageAction.GET;
  dossierId: number;
}

export interface EditAddressDataPageGetResultAction {
  type: typeof EditAddressDataPageAction.GET_RESULT;
  payload: EditAddressDataPage;
}

export interface EditAddressDataPageResultAction {
  type: typeof EditAddressDataPageAction.RESULT;
  payload: EditAddressDataPage;
}

export interface EditAddressDataPageChangeAction {
  type: typeof EditAddressDataPageAction.CHANGE;
  payload: string;
  changeField: string;
}

export interface EditAddressDataPageSubmitAction {
  type: typeof EditAddressDataPageAction.SUBMIT;
  payload: EditAddressDataPage;
  dossierId: number;
}

export interface EditAddressDataPageGetPostalCodesAction {
  type: typeof EditAddressDataPageAction.GET_POSTAL_CODES;
  payload: EditAddressDataPage;
}

export interface EditAddressDataPageGetPostalCodesResultAction {
  type: typeof EditAddressDataPageAction.GET_POSTAL_CODES_RESULT;
  payload: PostalCode;
}

export interface EditAddressDataPageGetPostalCodesErrorAction {
  type: typeof EditAddressDataPageAction.GET_POSTAL_CODES_ERROR;
  payload: string;
}

export type EditAddressDataPageActionType =
  | EditAddressDataPageGetAction
  | EditAddressDataPageGetResultAction
  | EditAddressDataPageResultAction
  | EditAddressDataPageChangeAction
  | EditAddressDataPageSubmitAction
  | EditAddressDataPageGetPostalCodesAction
  | EditAddressDataPageGetPostalCodesResultAction
  | EditAddressDataPageGetPostalCodesErrorAction;
