import { ScholarshipData } from './types';

export enum ScholarshipDataAction {
  GET = 'SCHOLARSHIP_DATA_GET',
  GET_RESULT = 'SCHOLARSHIP_DATA_GET_RESULT',
  CHANGE = 'SCHOLARSHIP_DATA_CHANGE',
  SUBMIT = 'SCHOLARSHIP_DATA_SUBMIT',
  RESULT = 'SCHOLARSHIP_DATA_RESULT',
  ADD = 'SCHOLARSHIP_DATA_ADD',
  ADD_RESULT = 'SCHOLARSHIP_DATA_ADD_RESULT',
  NON_EXISTENT_SCOLARSHIP_INFO = 'NON_EXISTENT_SCOLARSHIP_INFO',
}

export interface ScholarshipDataGetAction {
  type: typeof ScholarshipDataAction.GET;
  payload: ScholarshipData;
  dossierId: number;
}

export interface ScholarshipDataGetResultAction {
  type: typeof ScholarshipDataAction.GET_RESULT;
  payload: ScholarshipData;
}

export interface ScholarshipDataChangeAction {
  type: typeof ScholarshipDataAction.CHANGE;
  payload: string;
  changeField: string;
}

export interface ScholarshipDataSubmitAction {
  type: typeof ScholarshipDataAction.SUBMIT;
  payload: ScholarshipData;
  dossierId: number;
}

export interface ScholarshipDataResultAction {
  type: typeof ScholarshipDataAction.RESULT;
  payload: ScholarshipData;
}

export interface ScholarshipDataAddAction {
  type: typeof ScholarshipDataAction.ADD;
  payload: ScholarshipData;
  dossierId: number;
}

export interface ScholarshipDataAddResultAction {
  type: typeof ScholarshipDataAction.ADD_RESULT;
  payload: ScholarshipData;
}

export type ScholarshipDataActionType =
  | ScholarshipDataGetAction
  | ScholarshipDataGetResultAction
  | ScholarshipDataChangeAction
  | ScholarshipDataSubmitAction
  | ScholarshipDataResultAction
  | ScholarshipDataAddAction
  | ScholarshipDataAddResultAction;
