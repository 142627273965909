import { HolderDataAction, HolderDataActionType } from './actions';
import { HolderStore } from './types';
import { produce } from 'immer';

export const initialState: HolderStore = {
  holders: [],
};

export const holderDataReducer = (state = initialState, action: HolderDataActionType): HolderStore => {
  return produce(state, (draft) => {
    switch (action.type) {
      case HolderDataAction.GET:
        draft.isLoading = true;
        break;
      case HolderDataAction.GET_RESULT:
        draft.holders = action.payload;
        draft.isLoading = false;
        break;
    }
    return draft;
  });
};
