import produce from 'immer';
import { ErrorHandlingAction, ErrorHandlingActionType } from './actions';
import { ErrorData } from './types';

export const initialState: ErrorData = {};

export function errorHandlingReducer(state = initialState, action: ErrorHandlingActionType): ErrorData {
  return produce(state, (draft) => {
    switch (action.type) {
      case ErrorHandlingAction.RAISE_ERROR:
        const msg = action.payload;
        const newState = { statusCode: undefined, message: msg.message };
        draft = newState;
        break;
      case ErrorHandlingAction.RAISE_REQUEST_ERROR:
        draft = action.payload;
        break;
    }
    return draft;
  });
}
