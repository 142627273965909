import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { AjaxResponse } from 'rxjs/ajax';
import { Epic, ofType } from 'redux-observable';
import {
  ContactDataAction,
  ContactDataGetAction,
  ContactDataGetPostalCodesAction,
  ContactDataGetPostalCodesErrorAction,
  ContactDataGetPostalCodesResultAction,
  ContactDataGetResultAction,
  ContactDataSubmitAction,
} from '../personal-data/actions';
import { Action } from 'redux';
import { createPatch } from 'rfc6902';
import { PersonalData } from '../personal-data/types';
import { PostalCode } from './types';
import { customAjax } from '../../utils/ajax-wrapper';
import { handleRequestFailureInEpic } from '../../utils/error-handling';

const getSubmitResult = (payload: AjaxResponse) => ({ type: ContactDataAction.RESULT, payload: payload.response });

export const sendContactDataEpic: Epic<Action, Action> = (action$: Observable<Action>): Observable<Action> =>
  action$.pipe(
    ofType(ContactDataAction.SUBMIT),
    mergeMap((action) => {
      const contactDataAction: ContactDataSubmitAction = action as ContactDataSubmitAction;
      const demandId = contactDataAction.demandId;

      return customAjax({
        subRoute: `/dossier/v1/demands/${demandId}/person-in-formation`,
        withCredentials: true,
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json-patch+json',
        },
        body: JSON.stringify(
          createPatch(
            {
              zipCode: '',
              address: '',
              houseNumber: '',
              town: '',
              phoneNumberPrivate: '',
              countryId: 0,
              emailAddress: '',
              livingType: 0,
            },
            {
              zipCode: contactDataAction.payload.zipCode,
              address: contactDataAction.payload.address,
              houseNumber: contactDataAction.payload.houseNumber,
              town: contactDataAction.payload.town,
              phoneNumberPrivate: contactDataAction.payload.phoneNumberPrivate,
              countryId: contactDataAction.payload.countryId,
              emailAddress: contactDataAction.payload.emailAddress,
              livingType: contactDataAction.payload.livingType,
            },
          ),
        ),
      }).pipe(
        map(
          (response) => getSubmitResult(response),
          catchError((error) => {
            return of(handleRequestFailureInEpic(error));
          }),
        ),
      );
    }),
  );

const setData = (payload: AjaxResponse): ContactDataGetResultAction => {
  return {
    type: ContactDataAction.GET_RESULT,
    payload: payload.response as PersonalData,
  };
};

export const getContactDataEpic: Epic<Action, Action> = (action$: Observable<Action>): Observable<Action> =>
  action$.pipe(
    ofType(ContactDataAction.GET),
    mergeMap((action) => {
      const demandId = (action as ContactDataGetAction).demandId;
      return customAjax({
        subRoute: `/dossier/v1/demands/${demandId}/person-in-formation`,
        withCredentials: true,
        method: 'GET',
      }).pipe(
        map(
          (response) => setData(response),
          catchError((error) => {
            return of(handleRequestFailureInEpic(error));
          }),
        ),
      );
    }),
  );

const setPostalCodeData = (payload: AjaxResponse): ContactDataGetPostalCodesResultAction => {
  return {
    type: ContactDataAction.GET_POSTAL_CODES_RESULT,
    payload: payload.response[0] as PostalCode,
  };
};

export const getPostalCodeDataEpic: Epic<Action, Action> = (action$: Observable<Action>): Observable<Action> =>
  action$.pipe(
    ofType(ContactDataAction.GET_POSTAL_CODES),
    mergeMap((action) => {
      const contactData = (action as ContactDataGetPostalCodesAction).payload;

      return customAjax({
        subRoute: `/master-data/v1/${contactData.zipCode}/locality`,
        withCredentials: false,
        method: 'GET',
        headers: {
          'Accept-Language': 'de-de',
        },
      }).pipe(
        map((response) => setPostalCodeData(response)),
        catchError(() => {
          const errorAction: ContactDataGetPostalCodesErrorAction = {
            type: ContactDataAction.GET_POSTAL_CODES_ERROR,
            payload: 'No zipcode found for ' + contactData.zipCode,
          };
          return of(errorAction);
        }),
      );
    }),
  );
