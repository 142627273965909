import { ZoomFile, ZoomFileInProgress } from '../../components/forms/file-upload/ZoomFileUpload';
import { CaseCreateDto, CaseDto } from './types';

export enum NotificationAction {
  SEND_TEXT_MESSAGE = 'NOTIFICATION_ACTION_SEND_TEXT_MESSAGE',
  SEND_TEXT_MESSAGE_RESULT = 'NOTIFICATION_ACTION_SEND_TEXT_MESSAGE_RESULT',
  SEND_TEXT_MESSAGE_ERROR = 'NOTIFICATION_ACTION_SEND_TEXT_MESSAGE_ERROR',
  SEND_TEXT_MESSAGE_ANSWER = 'NOTIFICATION_ACTION_SEND_TEXT_MESSAGE_ANSWER',
  SEND_TEXT_MESSAGE_ANSWER_RESULT = 'NOTIFICATION_ACTION_SEND_TEXT_MESSAGE_ANSWER_RESULT',
  SEND_TEXT_MESSAGE_ANSWER_ERROR = 'NOTIFICATION_ACTION_SEND_TEXT_MESSAGE_ANSWER_ERROR',
  GET_MESSAGES = 'NOTIFICATION_ACTION_GET_MESSAGES',
  GET_SINGLE_MESSAGE = 'NOTIFICATION_GET_SINGLE_MESSAGE',
  GET_MESSAGES_RESULT = 'NOTIFICATION_ACTION_GET_MESSAGES_RESULT',
  GET_MESSAGES_ERROR = 'NOTIFICATION_ACTION_GET_MESSAGES_ERROR',
  UPLOAD = 'NOTIFICATION_DOCUMENTS_ACTION_UPLOAD',
  PROGRESS = 'NOTIFICATION_DOCUMENTS_ACTION_PROGRESS',
  UPLOAD_READY = 'NOTIFICATION_DOCUMENTS_ACTION_UPLOAD_READY',
  UPLOAD_ERROR = 'NOTIFICATION_DOCUMENTS_ACTION_UPLOAD_ERROR',
  GET_RESULT = 'NOTIFICATION_DOCUMENTS_ACTION_GET_RESULT',
  SHOW_SUCCESS = 'NOTIFICATION_DOCUMENTS_ACTION_SHOW_SUCCESS',
  SHOW_ERROR = 'NOTIFICATION_DOCUMENTS_ACTION_SHOW_ERROR',
  RESET_STATUS = 'NOTIFICATION_DOCUMENTS_ACTION_RESET_STATUS',
  DELETE_FILE = 'NOTIFICATION_DOCUMENTS_ACTION_DELETE_FILE',
  DELETE_FILE_SUCCESS = 'NOTIFICATION_DOCUMENTS_ACTION_DELETE_FILE_SUCCESS',
  DELETE_FILE_ERROR = 'NOTIFICATION_DOCUMENTS_ACTION_DELETE_FILE_ERROR',
  MARK_AS_READ = 'NOTIFICATION_MARK_AS_READ',
  MARK_ALL_AS_READ = 'NOTIFICATION_MARK_ALL_AS_READ',
  MARK_AS_READ_RESULT = 'NOTIFICATION_MARK_AS_READ_RESULT',
  MARK_ALL_AS_READ_RESULT = 'NOTIFICATION_MARK_ALL_AS_READ_RESULT',
}

export interface NotificationSendTextMessageAction {
  type: typeof NotificationAction.SEND_TEXT_MESSAGE;
  payload: CaseCreateDto;
  dossierId: number;
}

export interface NotificationSendTextMessageResultAction {
  type: typeof NotificationAction.SEND_TEXT_MESSAGE_RESULT;
  payload: CaseDto;
}

export interface NotificationSendTextMessageErrorAction {
  type: typeof NotificationAction.SEND_TEXT_MESSAGE_ERROR;
  payload: string;
}

export interface NotificationSendTextMessageAnswerAction {
  type: typeof NotificationAction.SEND_TEXT_MESSAGE_ANSWER;
  payload: CaseCreateDto;
  dossierId: number;
}

export interface NotificationSendTextMessageAnswerResultAction {
  type: typeof NotificationAction.SEND_TEXT_MESSAGE_ANSWER_RESULT;
  payload: CaseDto;
}

export interface NotificationSendTextMessageAnswerErrorAction {
  type: typeof NotificationAction.SEND_TEXT_MESSAGE_ANSWER_ERROR;
  payload: string;
}

export interface NotificationGetMessagesAction {
  type: typeof NotificationAction.GET_MESSAGES;
  dossierId: number;
}

export interface NotificationGetSingleMessageAction {
  type: typeof NotificationAction.GET_SINGLE_MESSAGE;
  dossierId: number;
  caseId: number;
}

export interface NotificationGetMessagesResultAction {
  type: typeof NotificationAction.GET_MESSAGES_RESULT;
  payload: Array<CaseDto>;
}

export interface NotificationGetMessagesErrorAction {
  type: typeof NotificationAction.GET_MESSAGES_ERROR;
  payload: string;
}

export interface NotificationDocumentsUploadAction {
  type: typeof NotificationAction.UPLOAD;
  payload: ZoomFile;
  dossierId: number;
  caseId?: number;
  typeId: number;
}

export interface NotificationDocumentsProgressAction {
  type: typeof NotificationAction.PROGRESS;
  payload: ZoomFileInProgress;
  caseId?: number;
}

export interface NotificationDocumentsUploadReadyAction {
  type: typeof NotificationAction.UPLOAD_READY;
  payload: ZoomFile;
  caseId?: number;
  typeId: number;
}

export interface NotificationDocumentsUploadErrorAction {
  type: typeof NotificationAction.UPLOAD_ERROR;
  payload: { typeId: number; error: string };
  caseId?: number;
}

export interface NotificationDocumentsShowSuccessAction {
  type: typeof NotificationAction.SHOW_SUCCESS;
  payload: number;
  caseId?: number;
}

export interface NotificationDocumentsShowErrorAction {
  type: typeof NotificationAction.SHOW_ERROR;
  payload: { typeId: number; error: string };
  caseId?: number;
}

export interface NotificationDocumentsResetStatusAction {
  type: typeof NotificationAction.RESET_STATUS;
  payload: number;
  caseId?: number;
}

export interface NotificationDocumentsActionDeleteFile {
  type: typeof NotificationAction.DELETE_FILE;
  payload: ZoomFile;
  dossierId: number;
  caseId: number;
}

export interface NotificationDocumentsActionDeleteFileSuccess {
  type: typeof NotificationAction.DELETE_FILE_SUCCESS;
  payload: string;
}

export interface NotificationDocumentsActionDeleteFileError {
  type: typeof NotificationAction.DELETE_FILE_ERROR;
  payload: string;
  error: string;
}

export interface NotificationMarkAsReadAction {
  type: typeof NotificationAction.MARK_AS_READ;
  dossierId: number;
  clientEventIds: Array<number>;
  caseId: number;
}

export interface NotificationMarkAsReadResultAction {
  type: typeof NotificationAction.MARK_AS_READ_RESULT;
  payload: CaseDto;
}

export interface NotificationMarkAllAsReadAction {
  type: typeof NotificationAction.MARK_ALL_AS_READ;
  dossierId: number;
}

export interface NotificationMarkAllAsReadResultAction {
  type: typeof NotificationAction.MARK_ALL_AS_READ_RESULT;
}

export type NotificationTypes =
  | NotificationSendTextMessageAction
  | NotificationSendTextMessageResultAction
  | NotificationSendTextMessageErrorAction
  | NotificationSendTextMessageAnswerAction
  | NotificationSendTextMessageAnswerResultAction
  | NotificationSendTextMessageAnswerErrorAction
  | NotificationGetMessagesAction
  | NotificationGetMessagesResultAction
  | NotificationGetMessagesErrorAction
  | NotificationDocumentsActionDeleteFileError
  | NotificationDocumentsActionDeleteFileSuccess
  | NotificationDocumentsActionDeleteFile
  | NotificationDocumentsResetStatusAction
  | NotificationDocumentsShowErrorAction
  | NotificationDocumentsShowSuccessAction
  | NotificationDocumentsUploadErrorAction
  | NotificationDocumentsUploadReadyAction
  | NotificationDocumentsProgressAction
  | NotificationDocumentsUploadAction
  | NotificationMarkAsReadAction
  | NotificationMarkAsReadResultAction
  | NotificationMarkAllAsReadAction
  | NotificationMarkAllAsReadResultAction
  | NotificationGetSingleMessageAction;
