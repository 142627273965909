import React, { ReactElement } from 'react';
import { trailingNumbersFormat } from '../../../utils/formaters';

export interface ZoomPieChartProps {
  filledAreaPercentage: number;
  filledAreaColor: string;
  restAreaColor: string;
  size: number;
  strokeWidth: number;
  amountToDisplay: string;
  isRepayment?: boolean;
  labelTotalAmount?: string;
}

const ZoomPieChart = (props: ZoomPieChartProps): ReactElement => {
  const strokeDasharray = `${props.filledAreaPercentage} ${100 - props.filledAreaPercentage}`;
  return (
    <div style={{ width: props.size, height: props.size, position: 'relative' }}>
      <svg viewBox="0 0 42 42" className="donut">
        <circle className="donut-hole" cx="21" cy="21" r="15.91549430918954" fill="#fff"></circle>
        <circle
          className="donut-ring"
          cx="21"
          cy="21"
          r="15.91549430918954"
          fill="transparent"
          strokeWidth={props.strokeWidth}
        ></circle>
        <circle
          className="donut-segment"
          cx="21"
          cy="21"
          r="15.91549430918954"
          fill="transparent"
          stroke={props.restAreaColor}
          strokeWidth={props.strokeWidth}
        ></circle>
        <circle
          className="donut-segment"
          cx="21"
          cy="21"
          r="15.91549430918954"
          fill="transparent"
          stroke={props.filledAreaColor}
          strokeWidth={props.strokeWidth}
          strokeDasharray={strokeDasharray}
          strokeDashoffset="75"
        ></circle>
      </svg>
      <div
        style={{
          margin: 0,
          textAlign: 'center',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          fontSize: '24px',
          fontWeight: 600,
        }}
      >
        <div style={{ fontSize: 18 }}>
          {props.isRepayment && (
            <>
              <span style={{ fontSize: 24 }}>{props.labelTotalAmount}</span>
              <br />
            </>
          )}{' '}
          CHF
        </div>
        <div>{trailingNumbersFormat(props.amountToDisplay)}</div>
      </div>
    </div>
  );
};

export default ZoomPieChart;
