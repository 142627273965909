import { Action } from 'redux';
import { Epic, ofType } from 'redux-observable';
import { Observable, of } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { customAjax } from '../../utils/ajax-wrapper';
import { handleRequestFailureInEpic } from '../../utils/error-handling';
import {
  MyContractsAction,
  MyContractsAllContractsGetAction,
  MyContractsAllContractsGetResultAction,
  MyContractsSingleContractGetAction,
  MyContractsSingleContractGetResultAction,
} from './actions';

const addAllMyContractsData = (payload: AjaxResponse): MyContractsAllContractsGetResultAction => {
  return {
    type: MyContractsAction.GET_ALL_CONTRACTS_RESULT,
    payload: payload.response,
  };
};

const addSingleMyContractsData = (payload: AjaxResponse): MyContractsSingleContractGetResultAction => {
  return {
    type: MyContractsAction.GET_SINGLE_CONTRACT_RESULT,
    payload: payload.response,
  };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getMyContractsDataOnError = (error: any): any => {
  if (error.status === 404) {
    return { type: MyContractsAction.NON_EXISTENT_MY_CONTRACTS_DATA };
  }
  return handleRequestFailureInEpic(error);
};

export const getAllMyContractsDataEpic: Epic<Action, Action> = (action$: Observable<Action>): Observable<Action> =>
  action$.pipe(
    ofType(MyContractsAction.GET_ALL_CONTRACTS),
    mergeMap((action) => {
      const dossierId = (action as MyContractsAllContractsGetAction).payload;
      return customAjax({
        subRoute: `/dossier-intranet/v1/myloan/${dossierId}/contracts`,
        withCredentials: true,
        method: 'GET',
        headers: {
          'Accept-Language': 'de-CH',
        },
      }).pipe(
        map((response) => addAllMyContractsData(response)),
        catchError((error) => {
          return of(getMyContractsDataOnError(error));
        }),
      );
    }),
  );

export const getSingleMyContractsDataEpic: Epic<Action, Action> = (action$: Observable<Action>): Observable<Action> =>
  action$.pipe(
    ofType(MyContractsAction.GET_SINGLE_CONTRACT),
    mergeMap((action) => {
      const dossierId = (action as MyContractsSingleContractGetAction).dossierId;
      const contractId = (action as MyContractsSingleContractGetAction).contractId;
      return customAjax({
        subRoute: `/dossier-intranet/v1/myloan/${dossierId}/contracts/${contractId}`,
        withCredentials: true,
        method: 'GET',
        headers: {
          'Accept-Language': 'de-CH',
        },
      }).pipe(
        map((response) => addSingleMyContractsData(response)),
        catchError((error) => {
          return of(getMyContractsDataOnError(error));
        }),
      );
    }),
  );
