import produce from 'immer';
import { LastDemandDataTakeoverAction, LastDemandDataType } from '../last-demand-data-takeover/actions';
import { SiblingsDataAction, SiblingsDataActionType } from './actions';
import { SiblingsDataStore } from './types';

export const initialState: SiblingsDataStore = {
  siblings: [],
};

export function siblingsDataReducer(
  state = initialState,
  action: SiblingsDataActionType | LastDemandDataType,
): SiblingsDataStore {
  return produce(state, (draft) => {
    switch (action.type) {
      case SiblingsDataAction.GET_RESULT:
        draft.siblings = action.payload;
        break;
      case SiblingsDataAction.EDIT_RESULT:
        const editedSiblingData = action.payload;
        draft.siblings = state.siblings.map((sibling) => {
          if (sibling.id === editedSiblingData.id) {
            return editedSiblingData;
          }
          return sibling;
        });
        break;
      case SiblingsDataAction.ADD_RESULT:
        const newSiblingData = action.payload;
        const siblings = state.siblings.slice();
        siblings.push(newSiblingData);
        draft.siblings = siblings;
        break;
      case SiblingsDataAction.DELETE_RESULT:
        const siblingId = action.payload;
        draft.siblings = state.siblings.filter((sibling) => sibling.id !== siblingId);
        break;
      case LastDemandDataTakeoverAction.RESULT:
        const lastDemandData = action.payload;
        draft.siblingsFromPreviousDemand = lastDemandData.siblings;
        break;
      default:
        break;
    }
    return draft;
  });
}
