import { Tooltip, withStyles } from '@material-ui/core';
import { black, white, lightGrey } from './../../theme';

export const ZoomTextInputHelpTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: lightGrey.main,
    color: black.main,
    fontFamily: 'Proxima Nova Cond',
    fontSize: 13,
    maxWidth: 300,
  },
  arrow: {
    marginTop: 20,
    fontSize: 20,
    color: white.main,
    '&::before': {
      backgroundColor: lightGrey.main,
    },
  },
}))(Tooltip);
