import { Button } from '@material-ui/core';
import { AlertTitle } from '@material-ui/lab';
import Alert from '@material-ui/lab/Alert';
import React, { ChangeEvent, ReactElement, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ZoomSelectInput from '../../components/forms/select-input/ZoomSelectInput';
import ZoomTextInput from '../../components/forms/text-input/ZoomTextInput';
import ZoomToggleInput from '../../components/forms/toggle-input/ZoomToggleInput';
import {
  PayoutDataAction,
  PayoutDataActionType,
  PayoutDataGetParentDataAction,
  PayoutDataGetPersonalDataAction,
  PayoutDataResetAction,
} from '../../store/payout-data/actions';
import { PayoutData } from '../../store/payout-data/types';
import { WIAAStore } from '../../store/types';
import { ibanValidation, maxValueValidation, postCodeValidation, requiredValidation } from '../../utils/validators';
import { getDefaultSelectOption } from './../../utils/form-data';
import './PayoutDataForm.scss';
import { i18nLabel } from './../../store/i18n/helpers';
import { ibanFormat } from '../../utils/formaters';

export interface PayoutDataFormProps {
  id?: string;
  accountHolder?: string;
  iban?: string;
  firstName?: string;
  lastName?: string;
  street?: string;
  postCode?: string;
  city?: string;
  loanRequest?: string;
  loanAmountDesired?: string;
  demandId: number;
  pifId: number;
  labelAccountHolder?: string;
  labelIban?: string;
  labelFirstName?: string;
  labelLastName?: string;
  labelStreet?: string;
  labelPostCode?: string;
  labelCity?: string;
  labelLoanRequest?: string;
  labelLoanAmountDesired?: string;
  labelSave?: string;
  helpTextAccountHolder?: string;
  helpTextIban?: string;
  helpTextLoanAmountDesired?: string;
  noteText?: string;
  destinationAddressRoleType?: { name: string; value: string }[];
  toggleButtonsTypes?: { name: string; value: string }[];
  errorLabels?: { [key: string]: string };
  dispatch?: (action: PayoutDataActionType) => void;
}

interface PayoutDataErrors {
  id?: string;
  accountHolder?: string;
  iban?: string;
  lastName?: string;
  street?: string;
  postCode?: string;
  city?: string;
  loanRequest?: string;
  loanAmountDesired?: string;
}

export const PayoutDataForm = (props: PayoutDataFormProps): ReactElement => {
  const [accountHolder, setAccountHolder] = useState(props.accountHolder ? props.accountHolder : '0');
  const [iban, setIban] = useState(props.iban ? props.iban : '');
  const [firstName, setFirstName] = useState(props.firstName ? props.firstName : '');
  const [lastName, setLastName] = useState(props.lastName ? props.lastName : '');
  const [street, setStreet] = useState(props.street ? props.street : '');
  const [postCode, setPostCode] = useState(props.postCode ? props.postCode : '');
  const [city, setCity] = useState(props.city ? props.city : '');
  const [loanRequest, setLoanRequest] = useState(props.loanRequest ? props.loanRequest : '2');
  const [loanAmountDesired, setLoanAmountDesired] = useState(props.loanAmountDesired ? props.loanAmountDesired : '0');
  const initialErrors: PayoutDataErrors = {};
  const [errors, setErrors] = useState(initialErrors);

  useEffect(() => {
    setAccountHolder(props.accountHolder ? props.accountHolder : '0');
    setIban(props.iban ? props.iban.replace('CH', '') : '');
    setFirstName(props.firstName ? props.firstName : '');
    setLastName(props.lastName ? props.lastName : '');
    setStreet(props.street ? props.street : '');
    setPostCode(props.postCode ? props.postCode : '');
    setCity(props.city ? props.city : '');
    setLoanRequest(props.loanRequest ? props.loanRequest : '2');
    setLoanAmountDesired(props.loanAmountDesired ? props.loanAmountDesired : '0');
  }, [props, props.postCode]);

  const onChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    switch (e.target.name) {
      case 'iban':
        setIban(e.target.value);
        setErrors({
          ...errors,
          iban: ibanValidation(e.target.value, props.errorLabels ? props.errorLabels : {}),
        });
        break;
      case 'firstName':
        setFirstName(e.target.value);
        break;
      case 'lastName':
        setLastName(e.target.value);
        setErrors({
          ...errors,
          lastName: requiredValidation(e.target.value, props.errorLabels ? props.errorLabels : {}),
        });
        break;
      case 'street':
        setStreet(e.target.value);
        setErrors({
          ...errors,
          street: requiredValidation(e.target.value, props.errorLabels ? props.errorLabels : {}),
        });
        break;
      case 'postCode':
        const fieldValue = e.target.value;

        if (fieldValue !== postCode) {
          setPostCode(fieldValue);
          const postalCodeError = postCodeValidation(fieldValue, props.errorLabels ? props.errorLabels : {});
          setErrors({ ...errors, postCode: postalCodeError });

          if (props.dispatch && !!!postalCodeError) {
            const oldPayoutData = getPayoutData();

            props.dispatch({
              type: PayoutDataAction.GET_POSTAL_CODES,
              payload: { ...oldPayoutData, zipCode: fieldValue },
            });
          }
        }
        break;
      case 'city':
        setCity(e.target.value);
        setErrors({ ...errors, city: requiredValidation(e.target.value, props.errorLabels ? props.errorLabels : {}) });
        break;
      case 'loanAmountDesired':
        setLoanAmountDesired(e.target.value);
        setErrors({
          ...errors,
          loanAmountDesired: maxValueValidation(e.target.value, 9999999, props.errorLabels ? props.errorLabels : {}),
        });
        break;
    }
  };

  const onAccountHolderChange = (newValue: string) => {
    if (newValue !== accountHolder && props.dispatch) {
      if (newValue === '7' || newValue === '8') {
        const payoutDataGetParentAction: PayoutDataGetParentDataAction = {
          type: PayoutDataAction.GET_PARENT_DATA,
          payload: newValue === '8' ? 'mother' : 'father',
          pifId: props.pifId,
        };
        props.dispatch(payoutDataGetParentAction);
      }
      if (newValue === '1') {
        const personalDataGetAction: PayoutDataGetPersonalDataAction = {
          type: PayoutDataAction.GET_PERSONAL_DATA,
          payload: props.demandId,
        };
        props.dispatch(personalDataGetAction);
      }
      if (newValue === '99') {
        const resetAction: PayoutDataResetAction = {
          type: PayoutDataAction.RESET,
        };
        props.dispatch(resetAction);
      }
    }

    setAccountHolder(newValue);
    setErrors({
      ...errors,
      accountHolder: newValue === '0' ? requiredValidation('', props.errorLabels ? props.errorLabels : {}) : '',
    });
  };

  const onChangeLoanRequest = (newValue: string) => {
    setLoanRequest(newValue);
    setErrors({
      ...errors,
      loanRequest: requiredValidation(newValue, props.errorLabels ? props.errorLabels : {}),
    });
  };

  const getPayoutData = (): PayoutData => {
    const streetAndHouseNumber = street.split(' ');
    let streetOnly = street;
    let houseNumber = 'оhne Nummer';
    if (streetAndHouseNumber.length > 1) {
      streetOnly = streetAndHouseNumber[0];
      streetAndHouseNumber.splice(0, 1);
      houseNumber = streetAndHouseNumber.join(' ');
    }
    return {
      destinationAddressRoleId: accountHolder ? parseInt(accountHolder) : undefined,
      firstName: firstName,
      lastName: lastName,
      street: streetOnly,
      houseNumber: houseNumber,
      zipCode: postCode,
      accountNumber: 'CH' + iban,
      accountType: 1,
      town: city,
      loanAmountDesired: loanAmountDesired && !isNaN(parseInt(loanAmountDesired)) ? parseInt(loanAmountDesired) : 0,
      loanRequested: loanRequest === '1',
    };
  };

  const getFormErrors = (): PayoutDataErrors => {
    const errors: PayoutDataErrors = {};
    errors.accountHolder =
      accountHolder === '0' ? requiredValidation('', props.errorLabels ? props.errorLabels : {}) : '';
    errors.iban = ibanValidation(iban, props.errorLabels ? props.errorLabels : {});
    errors.lastName = requiredValidation(lastName, props.errorLabels ? props.errorLabels : {});
    errors.street = requiredValidation(street, props.errorLabels ? props.errorLabels : {});
    errors.postCode = postCodeValidation(postCode, props.errorLabels ? props.errorLabels : {});
    errors.city = requiredValidation(city, props.errorLabels ? props.errorLabels : {});

    if (loanRequest === '1') {
      errors.loanAmountDesired = maxValueValidation(
        loanAmountDesired,
        9999999,
        props.errorLabels ? props.errorLabels : {},
      );
    }
    return errors;
  };

  const validateForm = (): boolean => {
    const newErrors = getFormErrors();
    setErrors(newErrors);
    const errorsPresent = Object.values(newErrors).some((value) => value !== '');
    return !errorsPresent;
  };

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    if (validateForm() && props.dispatch) {
      props.dispatch({
        type: PayoutDataAction.SUBMIT,
        payload: getPayoutData(),
        demandId: props.demandId,
      });
    }
    event.preventDefault();
  };

  return (
    <form onSubmit={onSubmit}>
      <div className="form-element-container">
        <ZoomSelectInput
          label={props.labelAccountHolder ? props.labelAccountHolder : ''}
          options={props.destinationAddressRoleType ? props.destinationAddressRoleType : []}
          value={accountHolder}
          error={!!errors.accountHolder}
          errorMessage={errors.accountHolder}
          name="accountHolder"
          onChange={onAccountHolderChange}
          helpText={props.helpTextAccountHolder ? props.helpTextAccountHolder : ''}
        ></ZoomSelectInput>
      </div>
      <div className="form-element-container">
        <ZoomTextInput
          label={props.labelIban ? props.labelIban : ''}
          name="iban"
          value={iban}
          onChange={onChange}
          error={!!errors.iban}
          errorMessage={errors.iban}
          isIBAN={true}
          helpText={props.helpTextIban ? props.helpTextIban : ''}
        ></ZoomTextInput>
      </div>
      <div className="form-element-container">
        <ZoomTextInput
          label={props.labelFirstName ? props.labelFirstName : ''}
          name="firstName"
          value={firstName}
          onChange={onChange}
        ></ZoomTextInput>
      </div>

      <div className="form-element-container">
        <ZoomTextInput
          label={props.labelLastName ? props.labelLastName : ''}
          name="lastName"
          value={lastName}
          onChange={onChange}
          error={!!errors.lastName}
          errorMessage={errors.lastName}
        ></ZoomTextInput>
      </div>
      <div className="form-element-container">
        <ZoomTextInput
          label={props.labelStreet ? props.labelStreet : ''}
          name="street"
          value={street}
          onChange={onChange}
          error={!!errors.street}
          errorMessage={errors.street}
        ></ZoomTextInput>
      </div>
      <div className="form-element-container">
        <ZoomTextInput
          label={props.labelPostCode ? props.labelPostCode : ''}
          name="postCode"
          value={postCode}
          onChange={onChange}
          error={!!errors.postCode}
          errorMessage={errors.postCode ? errors.postCode : undefined}
        ></ZoomTextInput>
      </div>
      <div className="form-element-container">
        <ZoomTextInput
          label={props.labelCity ? props.labelCity : ''}
          name="city"
          value={city}
          onChange={onChange}
          error={!!errors.city}
          errorMessage={errors.city}
        ></ZoomTextInput>
      </div>
      <div className="form-element-container">
        <ZoomToggleInput
          value={loanRequest}
          title={props.labelLoanRequest ? props.labelLoanRequest : ''}
          label1={props.toggleButtonsTypes ? props.toggleButtonsTypes[0].name : ''}
          value1={props.toggleButtonsTypes ? props.toggleButtonsTypes[0].value.toString() : ''}
          label2={props.toggleButtonsTypes ? props.toggleButtonsTypes[1].name : ''}
          value2={props.toggleButtonsTypes ? props.toggleButtonsTypes[1].value.toString() : ''}
          onChange={onChangeLoanRequest}
        ></ZoomToggleInput>
      </div>
      {loanRequest === '1' && (
        <div className="form-element-container">
          <ZoomTextInput
            label={props.labelLoanAmountDesired ? props.labelLoanAmountDesired : ''}
            name="loanAmountDesired"
            value={loanAmountDesired}
            onChange={onChange}
            error={!!errors.loanAmountDesired}
            errorMessage={errors.loanAmountDesired}
            helpText={props.helpTextLoanAmountDesired ? props.helpTextLoanAmountDesired : ''}
          ></ZoomTextInput>
        </div>
      )}
      {loanRequest === '1' && (
        <div className="form-element-container">
          <Alert severity="info">
            <AlertTitle>Hinweis:</AlertTitle>
            {props.noteText}
          </Alert>
        </div>
      )}
      <div className="form-actions-container">
        <Button color="primary" type="submit" variant="contained" id="submit-payout-data">
          {props.labelSave}
        </Button>
      </div>
    </form>
  );
};

// Map global store to component parameters
// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export function mapStateToProps(store: WIAAStore, ownProps: any): PayoutDataFormProps {
  const { demandId, pifId } = ownProps.match.params;
  const props: PayoutDataFormProps = {
    labelAccountHolder: i18nLabel(
      store.translationLabels,
      'Payout_User_Account_Holder',
      'Bitte wählen Sie den Kontoinhaber bzw. die Kontoinhaberin des Kontos auf welche Sie den Ausbildungsbeitrag ausbezahlt wünschen.*',
    ),
    labelIban: i18nLabel(store.translationLabels, 'Payout_User_Iban', 'Kontonummer (IBAN)*'),
    labelFirstName: i18nLabel(store.translationLabels, 'Payout_User_First_Name', 'Vorname'),
    labelLastName: i18nLabel(store.translationLabels, 'Payout_User_Last_Name', 'Nachname*'),
    labelStreet: i18nLabel(store.translationLabels, 'Payout_User_Street', 'Strasse/Nr.*'),
    labelPostCode: i18nLabel(store.translationLabels, 'Payout_User_Post_Code', 'PLZ*'),
    labelCity: i18nLabel(store.translationLabels, 'Payout_User_City', 'Ort*'),
    labelLoanRequest: i18nLabel(
      store.translationLabels,
      'Payout_User_Loan_Request',
      'Falls ich kein oder ein ungenügendes Stipendium erhalte, beantrage ich ein Darlehen.*',
    ),
    labelLoanAmountDesired: i18nLabel(
      store.translationLabels,
      'Payout_User_Loan_Amount',
      'Gewünschter Darlehensbetrag pro Jahr*',
    ),
    labelSave: i18nLabel(store.translationLabels, 'Payout_User_Save', 'Speichern'),
    helpTextAccountHolder: i18nLabel(
      store.translationLabels,
      'Payout_User_Account_Holder_Help_Text',
      'Bitte wählen Sie den Kontoinhaber bzw. die Kontoinhaberin.',
    ),
    helpTextIban: i18nLabel(store.translationLabels, 'Payout_User_Iban_Help_Text', 'Bsp.: CH00 0000 0000 0000 0000 0'),
    helpTextLoanAmountDesired: i18nLabel(
      store.translationLabels,
      'Payout_User_Loan_Amount_Help_Text',
      'Es besteht kein Anspruch auf Darlehen.',
    ),
    noteText: i18nLabel(
      store.translationLabels,
      'Payout_User_Note_Text',
      'Darlehen sind zinsfrei und müssen innert zehn Jahren nach Abschluss oder Abbruch einer Ausbildung zurückbezahlt werden. Weitere Angaben zu den Darlehensbedingungen finden Sie im StipG (§10 Abs. 2 und §17) und in der StipV §13 und §35)',
    ),
    errorLabels: store.translationLabels,
    demandId: Number(demandId),
    pifId: Number(pifId),
    destinationAddressRoleType: store.translationOptions['destination-address-roles']
      ? [
          { value: '0', name: getDefaultSelectOption(store.translationLabels) },
          ...store.translationOptions['destination-address-roles'].map((option) => {
            return { value: option.id.toString(), name: option.name };
          }),
        ]
      : [],
    toggleButtonsTypes: store.translationOptions['toggle-buttons']
      ? [
          ...store.translationOptions['toggle-buttons'].map((option) => {
            return { value: option.id.toString(), name: option.name };
          }),
        ]
      : [],
  };
  const data = store.payoutData;
  let streetAndHouseNumber = data.street ? data.street : '';
  if (data.houseNumber) {
    streetAndHouseNumber += ` ${data.houseNumber}`;
  }
  if (data) {
    props.accountHolder = data.destinationAddressRoleId ? data.destinationAddressRoleId.toString() : '';
    props.iban = ibanFormat(data.accountNumber ? data.accountNumber : '');
    props.firstName = data.firstName;
    props.lastName = data.lastName;
    props.street = streetAndHouseNumber;
    props.postCode = data.zipCode;
    props.city = data.town;
    props.loanRequest = data.loanRequested ? '1' : '2';
    props.loanAmountDesired = data.loanAmountDesired ? data.loanAmountDesired.toString() : '';
  }
  return props;
}

// default export the connected component
export default withRouter(connect(mapStateToProps)(PayoutDataForm));
