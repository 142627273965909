import React, { ReactElement } from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import { black } from './../../theme';

export function ZoomCheckIcon(props: SvgIconProps): ReactElement {
  const color = props.htmlColor ? props.htmlColor : black.main;
  return (
    <SvgIcon {...props}>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <path d="M0 0H80V80H0z" transform="translate(-56 -157) translate(56 157)" />
            <path
              fill={color}
              fillRule="nonzero"
              d="M30.65 57.48L16.53 45.13 19.16 42.12 30.57 52.1 61.23 23.7 63.95 26.64z"
              transform="translate(-56 -157) translate(56 157)"
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}
