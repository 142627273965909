import { Button, Grid } from '@material-ui/core';
import React, { ReactElement } from 'react';
import {
  NotificationAction,
  NotificationMarkAsReadAction,
  NotificationTypes,
} from '../../../store/notifications/actions';
import { RoutingAction, RoutingActionType, RoutingRedirectToAction } from '../../../store/routing/actions';
import { darkOrange, green, red, white } from '../../../theme';
import { ZoomCheckCircleIcon } from '../../icons/ZoomCheckCircleIcon';
import { ZoomWarningIcon } from '../../icons/ZoomWarningIcon';
import './ZoomNotification.scss';

export interface ZoomNotificationProps {
  id?: number;
  labelText?: string;
  labelButtonText?: string;
  labelGoToActionText?: string;
  isError?: boolean;
  isWarning?: boolean;
  isSuccess?: boolean;
  dossierId?: number;
  clientEventIds?: Array<number>;
  dispatch?: (action: NotificationTypes | RoutingActionType) => void;
}

const getNotificationColor = (props: ZoomNotificationProps): string => {
  if (props.isSuccess) {
    return green.main;
  }

  if (props.isError) {
    return red.main;
  }
  return darkOrange.main;
};

const ZoomNotification = (props: ZoomNotificationProps): ReactElement => {
  const backgroundColor = getNotificationColor(props);

  const markAsRead = () => {
    if (props.dispatch) {
      const markNotificationAsRead = {
        type: NotificationAction.MARK_AS_READ,
        dossierId: props.dossierId,
        caseId: props.id,
        clientEventIds: props.clientEventIds,
      } as NotificationMarkAsReadAction;
      props.dispatch(markNotificationAsRead);
    }
  };

  const goToAction = () => {
    if (props.dispatch) {
      const redirectAction: RoutingRedirectToAction = {
        type: RoutingAction.REDIRECT_TO,
        payload: `/messages/${props.dossierId}/${props.id}`,
      };
      props.dispatch(redirectAction);
    }
  };

  return (
    <>
      <Grid
        className="notification-container"
        style={{ backgroundColor: backgroundColor, border: '1px solid white' }}
        container
        direction="row"
        alignItems="stretch"
        justify="flex-start"
      >
        <Grid className="icon-wrapper" item xs={12} sm={9}>
          <div className="notification-icon-text">
            {props.isSuccess ? (
              <ZoomCheckCircleIcon viewBox="0 0 80 80" fontSize="large" htmlColor={white.main}></ZoomCheckCircleIcon>
            ) : (
              <ZoomWarningIcon viewBox="0 0 80 80" fontSize="large" htmlColor={white.main}></ZoomWarningIcon>
            )}
            {props.labelText}
          </div>
        </Grid>
        <Grid className="btn-wrapper" item xs={12} sm={3}>
          <div className="notification-btn">
            <Button
              variant="outlined"
              size="large"
              style={{ color: white.main, borderColor: white.main }}
              onClick={props.isError ? goToAction : markAsRead}
            >
              {props.isError ? props.labelGoToActionText : props.labelButtonText}
            </Button>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default ZoomNotification;
