import React, { ReactElement } from 'react';
import { buildIcon, ZoomIcon } from '../../icons/icons';

export interface CircularProgressWithIconProps {
  filledAreaPercentage: number;
  filledAreaColor: string;
  filledAreaBackgroundColor?: string;
  backgroundColor?: string;
  size: number;
  strokeWidth: number;
  backgroundStrokeWidth: number;
  iconSize?: number;
  icon?: ZoomIcon;
  iconColor?: string;
}

export const CircularProgressWithIcon = (props: CircularProgressWithIconProps): ReactElement => {
  const strokeDasharray = `${props.filledAreaPercentage} ${100 - props.filledAreaPercentage}`;
  const iconSizeScale = props.iconSize ? props.iconSize / 24 : (props.size * 0.75) / 24;
  const iconColor = props.iconColor ? props.iconColor : props.filledAreaColor;
  const backgroundColor = props.backgroundColor ? props.backgroundColor : 'transparent';
  return (
    <div style={{ width: props.size, height: props.size, position: 'relative' }}>
      <svg viewBox="0 0 42 42" className="donut">
        <circle className="donut-hole" cx="21" cy="21" r="15.91549430918954" fill={backgroundColor}></circle>
        <circle
          className="donut-ring"
          cx="21"
          cy="21"
          r="15.91549430918954"
          fill="transparent"
          strokeWidth={props.strokeWidth}
        ></circle>
        <circle
          className="donut-segment"
          cx="21"
          cy="21"
          r="15.91549430918954"
          fill="transparent"
          stroke={props.filledAreaBackgroundColor}
          strokeWidth={props.backgroundStrokeWidth}
        ></circle>
        <circle
          className="donut-segment"
          cx="21"
          cy="21"
          r="15.91549430918954"
          fill="transparent"
          stroke={props.filledAreaColor}
          strokeWidth={props.strokeWidth}
          strokeDasharray={strokeDasharray}
          strokeDashoffset="75"
          transform="rotate(180, 21, 21)"
        ></circle>
      </svg>
      <div
        style={{
          margin: 0,
          textAlign: 'center',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -45%)',
          fontSize: '24px',
          fontWeight: 600,
        }}
      >
        {props.icon && buildIcon(props.icon, iconColor, iconSizeScale)}
      </div>
    </div>
  );
};
