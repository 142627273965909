import produce from 'immer';
import { LastDemandDataTakeoverAction, LastDemandDataType } from '../last-demand-data-takeover/actions';
import { ChildrenDataAction, ChildrenDataActionType } from './actions';
import { ChildrenDataStore } from './types';

export const initialState: ChildrenDataStore = {
  children: [],
};

export function childrenDataReducer(
  state = initialState,
  action: ChildrenDataActionType | LastDemandDataType,
): ChildrenDataStore {
  return produce(state, (draft) => {
    switch (action.type) {
      case ChildrenDataAction.GET_RESULT:
        draft.children = action.payload;
        break;
      case ChildrenDataAction.EDIT_RESULT:
        const editedChildData = action.payload;
        draft.children = state.children.map((child) => {
          if (child.id === editedChildData.id) {
            return editedChildData;
          }
          return child;
        });
        break;
      case ChildrenDataAction.ADD_RESULT:
        const newChildData = action.payload;
        const children = state.children.slice();
        children.push(newChildData);
        draft.children = children;
        break;
      case ChildrenDataAction.DELETE_RESULT:
        const childId = action.payload;
        draft.children = state.children.filter((child) => child.id !== childId);
        break;
      case LastDemandDataTakeoverAction.RESULT:
        const lastDemandData = action.payload;
        draft.childrenDataFromPreviousYear = lastDemandData.children;
        break;
      default:
        break;
    }
    return draft;
  });
}
