import Button from '@material-ui/core/Button';
import produce from 'immer';
import React, { ReactElement, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  ChildrenDataAction,
  ChildrenDataActionType,
  ChildrenDataAddChildAction,
  ChildrenDataDeleteChildAction,
  ChildrenDataEditChildAction,
} from '../../store/children-data/actions';
import { ChildrenDataStore, ChildData } from '../../store/children-data/types';
import { i18nLabel } from '../../store/i18n/helpers';
import { WIAAStore } from '../../store/types';
import './ChildrenForm.scss';
import { Child, ChildItemForm } from './item/form/ChildItemForm';
import { ChildItemInfo } from './item/info/ChildItemInfo';
import { setTimeToMiddleOfDay } from '../../utils/date-manipulation';
import { ZoomStyledMenu, ZoomStyledMenuItem } from '../../utils/zoom-styled-menu';
import { Grid, ListItemText, useMediaQuery } from '@material-ui/core';
import { ZoomArrowDownIcon } from '../../components/icons/ZoomArrowDownIcon';
import { blue, lightGrey } from '../../theme';
import { extractToggleValue } from '../../utils/object-manipulation';

export interface ChildrenFormProps {
  labelFirstName?: string;
  labelLastName?: string;
  labelDateOfBirth?: string;
  labelLivingType?: string;
  labelPostMandatoryFormation?: string;
  labelFormationType?: string;
  labelAlimonyReceivedYesNo?: string;
  labelAlimonyReceivedAmount?: string;
  labelAlimonyPayedYesNo?: string;
  labelAlimonyPayedAmount?: string;
  labelNoEntries: string;
  labelAddAdditionalPositions: string;
  labelSave: string;
  labelCancel: string;
  piaChildren: Array<Child>;
  childrenBeingEdited: Array<number>;
  pifId: number;
  errorLabels?: { [key: string]: string };
  labelTakeoverDataFromPreviousYear?: string;
  piaChildrenFromPreviousDemand?: Array<ChildData>;
  toggleButtonsTypes?: { name: string; value: string }[];
  dispatch?: (action: ChildrenDataActionType) => void;
}

const childData2child = (child: ChildData): Child => {
  return {
    id: child.id,
    firstName: child.firstName,
    lastName: child.lastName,
    dateOfBirth: child.dateOfBirth,
    livingType: extractToggleValue(child.livingType),
    postMandatoryFormation: extractToggleValue(child.postMandatoryFormation),
    formationType: child.formationType,
    alimonyReceivedYesNo: extractToggleValue(child.alimonyReceived),
    alimonyReceivedAmount: child.alimonyReceivedAmount ? child.alimonyReceivedAmount.toString() : '',
    alimonyPayedYesNo: extractToggleValue(child.alimonyPayed),
    alimonyPayedAmount: child.alimonyPayedAmount ? child.alimonyPayedAmount.toString() : '',
  };
};

export const ChildrenForm = (props: ChildrenFormProps): ReactElement => {
  const [piaChildren, setPiaChildren] = useState(props.piaChildren ? props.piaChildren : []);
  const [childrenBeingEdited, setChildrenBeingEdited] = useState(
    props.childrenBeingEdited ? props.childrenBeingEdited : [],
  );
  const dataFromPreviousDemand = props.piaChildrenFromPreviousDemand ? props.piaChildrenFromPreviousDemand : [];
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const isSmallDevice = useMediaQuery('(max-width: 600px)');
  const [disableControls, setDisableControls] = useState(false);

  useEffect(() => {
    setPiaChildren(props.piaChildren ? props.piaChildren : []);
  }, [props.piaChildren]);

  const getStateWithoutItem = (childrenEdited: number[], indexToRemove: number): number[] => {
    return produce(childrenEdited, (draft) => {
      const indexToDelete = draft.findIndex((i) => i === indexToRemove);
      if (indexToDelete > -1) {
        draft.splice(indexToDelete, 1);
      }
    });
  };

  const onSave = (index: number, child: Child) => {
    if (props.dispatch) {
      const childrenDataAction: ChildrenDataAddChildAction | ChildrenDataEditChildAction = {
        type: !child.id || child.id === 0 ? ChildrenDataAction.ADD_ITEM : ChildrenDataAction.EDIT_ITEM,
        payload: {
          id: child.id,
          firstName: child.firstName,
          lastName: child.lastName,
          dateOfBirth: setTimeToMiddleOfDay(child.dateOfBirth),
          livingType: child.livingType == '1' ? true : false,
          postMandatoryFormation: child.postMandatoryFormation == '1' ? true : false,
          formationType: child.formationType,
          alimonyReceived: child.alimonyReceivedYesNo == '1' ? true : false,
          alimonyReceivedAmount: child.alimonyReceivedAmount ? Number(child.alimonyReceivedAmount) : 0,
          alimonyPayed: child.alimonyPayedYesNo == '1' ? true : false,
          alimonyPayedAmount: child.alimonyPayedAmount ? Number(child.alimonyPayedAmount) : 0,
        } as ChildData,
        pifId: props.pifId,
      };
      setDisableControls(false);
      props.dispatch(childrenDataAction);
    }
    setChildrenBeingEdited(getStateWithoutItem(childrenBeingEdited, index));
  };

  const onEdit = (index: number) => {
    const nextStateBeingEdited = produce(childrenBeingEdited, (draft) => {
      draft.push(index);
    });
    setChildrenBeingEdited(nextStateBeingEdited);
    setDisableControls(true);
  };

  const onDelete = (index: number) => {
    const childItemId = piaChildren[index].id;

    const nextStateChildren = produce(piaChildren, (draft) => {
      draft.splice(index, 1);
    });
    setPiaChildren(nextStateChildren);
    setChildrenBeingEdited(getStateWithoutItem(childrenBeingEdited, index));

    if (childItemId && childItemId > 0 && props.dispatch) {
      const deleteChildData: ChildrenDataDeleteChildAction = {
        type: ChildrenDataAction.DELETE_ITEM,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        payload: childItemId,
        pifId: props.pifId,
      };
      props.dispatch(deleteChildData);
    }
    setDisableControls(false);
  };

  const addNewChild = (childData: ChildData) => {
    const nextState = produce(piaChildren, (draft) => {
      draft.push(childData2child(childData));
    });
    setPiaChildren(nextState);
    const nextStateBeingEdited = produce(childrenBeingEdited, (draft) => {
      draft.push(nextState.length - 1);
    });
    setChildrenBeingEdited(nextStateBeingEdited);
    setDisableControls(true);
  };

  const toRender = piaChildren.map((child, index) => {
    if (childrenBeingEdited.includes(index)) {
      const params = { ...props, ...child };
      return (
        <div className="child-form-wrapper" key={index} id={'edit-child-' + index}>
          <ChildItemForm
            {...params}
            onSave={(childToSave) => {
              onSave(index, childToSave);
            }}
            onDelete={() => {
              onDelete(index);
            }}
          ></ChildItemForm>
        </div>
      );
    }

    const firstName = child.firstName ? child.firstName : '';
    const lastName = child.lastName ? child.lastName : '';
    const dateOfBirth = child.dateOfBirth ? child.dateOfBirth : undefined;
    return (
      <ChildItemInfo
        firstName={firstName}
        lastName={lastName}
        dateOfBirth={dateOfBirth}
        id={index}
        key={index}
        onEdit={() => {
          onEdit(index);
        }}
        onDelete={() => {
          onDelete(index);
        }}
        disableControls={disableControls}
      ></ChildItemInfo>
    );
  });

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const piaChildrenDataFromPreviousDemand = dataFromPreviousDemand.map((childData, index) => {
    return (
      <ZoomStyledMenuItem key={index}>
        <a
          data-testid={'previous-data-' + index}
          style={{ display: 'inline-flex', width: '100%' }}
          onClick={() => {
            const count = piaChildren.length;
            addNewChild(childData);
            handleClose();
            setTimeout(() => {
              document.getElementById('edit-child-' + count)?.scrollIntoView();
              window.scrollBy(0, -100);
            }, 200);
          }}
        >
          <ListItemText primary={childData.firstName + ' ' + childData.lastName} />
        </a>
      </ZoomStyledMenuItem>
    );
  });

  return (
    <>
      <div>{toRender}</div>
      {piaChildren.length === 0 && <div>{props.labelNoEntries}</div>}
      <Grid container>
        <Grid item xs={12} sm={6}>
          {dataFromPreviousDemand.length > 0 && (
            <div style={{ display: 'flex', justifyContent: 'flex-start', marginTop: 10 }}>
              <Button
                aria-controls="customized-menu"
                aria-haspopup="true"
                color="primary"
                type="reset"
                variant="outlined"
                fullWidth={isSmallDevice}
                id="data-takeover-button"
                onClick={handleClick}
                disabled={disableControls}
              >
                {props.labelTakeoverDataFromPreviousYear}
                <ZoomArrowDownIcon
                  viewBox="0 0 80 80"
                  htmlColor={disableControls ? lightGrey.main : blue.main}
                ></ZoomArrowDownIcon>
              </Button>
              <ZoomStyledMenu
                id="customized-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                {piaChildrenDataFromPreviousDemand}
              </ZoomStyledMenu>
            </div>
          )}
        </Grid>
        <Grid item xs={12} sm={6}>
          <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 10 }}>
            <Button
              variant="outlined"
              color="primary"
              id="add-child-button"
              fullWidth={isSmallDevice}
              onClick={() => addNewChild({})}
              disabled={disableControls}
            >
              {props.labelAddAdditionalPositions}
            </Button>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export function mapStateToProps(store: WIAAStore, ownProps: any): ChildrenFormProps {
  const { pifId } = ownProps.match.params;
  const props: ChildrenFormProps = {
    labelFirstName: i18nLabel(store.translationLabels, 'Children_First_Name', 'Vorname*'),
    labelLastName: i18nLabel(store.translationLabels, 'Children_Last_Name', 'Nachname*'),
    labelDateOfBirth: i18nLabel(store.translationLabels, 'Children_Date_Of_Birth', 'Geburtsdatum*'),
    labelLivingType: i18nLabel(store.translationLabels, 'Children_Living_Type', 'Wohnt bei Person in Ausbildung?*'),
    labelPostMandatoryFormation: i18nLabel(
      store.translationLabels,
      'Children_Post_Mandatory_Formation',
      'Ist Ihr Kind in nachobligatorischer Ausbildung?*',
    ),
    labelFormationType: i18nLabel(store.translationLabels, 'Children_Formation_Type', 'Art der Ausbildung*'),
    labelAlimonyReceivedYesNo: i18nLabel(
      store.translationLabels,
      'Children_Alimony_Received_Yes_No',
      'Ich erhalte Alimente für dieses Kind?*',
    ),
    labelAlimonyReceivedAmount: i18nLabel(
      store.translationLabels,
      'Children_Alimony_Received_Amount',
      'Betrag pro Jahr*',
    ),
    labelAlimonyPayedYesNo: i18nLabel(
      store.translationLabels,
      'Children_Alimony_Payed_Yes_No',
      'Ich bezahle Alimente für dieses Kind?*',
    ),
    labelAlimonyPayedAmount: i18nLabel(store.translationLabels, 'Children_Alimony_Payed_Amount', 'Betrag pro Jahr*'),
    labelNoEntries: i18nLabel(store.translationLabels, 'Children_No_Entries', 'Keine Einträge'),
    labelAddAdditionalPositions: i18nLabel(
      store.translationLabels,
      'Children_Add_Additional_Positions',
      'Weitere Position hinzufügen',
    ),
    labelSave: i18nLabel(store.translationLabels, 'Children_Save', 'Speichern'),
    labelCancel: i18nLabel(store.translationLabels, 'Children_Cancel', 'Löschen'),
    errorLabels: store.translationLabels,
    labelTakeoverDataFromPreviousYear: i18nLabel(
      store.translationLabels,
      'Children_Takeover_Data',
      'Daten aus Vorjahr übernehmen',
    ),
    piaChildren: [],
    piaChildrenFromPreviousDemand: store.childrenData.childrenDataFromPreviousYear,
    pifId: Number(pifId),
    childrenBeingEdited: [],
    toggleButtonsTypes: store.translationOptions['toggle-buttons']
      ? [
          ...store.translationOptions['toggle-buttons'].map((option) => {
            return { value: option.id.toString(), name: option.name };
          }),
        ]
      : [],
  };

  const data: ChildrenDataStore = store.childrenData;
  if (data && data.children) {
    props.piaChildren = Object.values(data.children).map(childData2child);
  }

  return props;
}

export default withRouter(connect(mapStateToProps)(ChildrenForm));
