import { DemandProgress } from './types';

export enum DemandOverviewAction {
  CLOSE_TAB = 'DEMAND_OVERVIEW_CLOSE_TAB',
  LOAD_TAB_MOBILE = 'DEMAND_OVERVIEW_LOAD_TAB_MOBILE',
  COMPLETE_DEMAND = 'DEMAND_COMPLETE',
  COMPLETE_DEMAND_SUCCESS = 'DEMAND_COMPLETE_SUCCES',
  COMPLETE_DEMAND_ERROR = 'COMPLETE_DEMAND_ERROR',
  CLEAR_DEMAND_ERROR = 'CLEAR_DEMAND_ERROR',
  REQUEST_PROGRESS = 'DEMAND_OVERVIEW_REQUEST_PROGRESS',
  PROGRESS_RESULT = 'DEMAND_OVERVIEW_PROFRESS_RESULT',
  COMPONENT_OPENED = 'DEMAND_OVERVIEW_COMPONENT_OPENED',
}

export interface DemandOverviewActionCloseTab {
  type: typeof DemandOverviewAction.CLOSE_TAB;
  payload: string;
}

export interface DemandOverviewActionLoadTabMobile {
  type: string;
  payload: string;
}

export interface CompleteDemandAction {
  type: typeof DemandOverviewAction.COMPLETE_DEMAND;
  payload: { dossierId: number; demandId: number };
}

export interface CompleteDemandSuccessAction {
  type: typeof DemandOverviewAction.COMPLETE_DEMAND_SUCCESS;
  payload: { dossierId: number; demandId: number };
}

export interface CompleteDemandErrorAction {
  type: typeof DemandOverviewAction.COMPLETE_DEMAND_ERROR;
  payload: { dossierId: number; demandId: number; error: string };
}

export interface ClearDemandErrorAction {
  type: typeof DemandOverviewAction.CLEAR_DEMAND_ERROR;
}

export interface DemandOverviewActionRequestProgress {
  type: typeof DemandOverviewAction.REQUEST_PROGRESS;
  payload: number;
}

export interface DemandOverviewActionProgressResult {
  type: typeof DemandOverviewAction.PROGRESS_RESULT;
  payload: DemandProgress;
}

export interface DemandOverviewActionComponentOpened {
  type: typeof DemandOverviewAction.COMPONENT_OPENED;
}

export type DemandOverviewActionType =
  | DemandOverviewActionCloseTab
  | DemandOverviewActionLoadTabMobile
  | CompleteDemandAction
  | CompleteDemandSuccessAction
  | CompleteDemandErrorAction
  | ClearDemandErrorAction
  | DemandOverviewActionRequestProgress
  | DemandOverviewActionProgressResult
  | DemandOverviewActionComponentOpened;
