import { UserDemandData } from '../../pages/dashboard/Dashboard';
import { CanCreateNewDemandResult, DemandData, UserData } from './types';

export enum UserDataAction {
  GET = 'USER_DATA_GET',
  GET_RESULT = 'USER_DATA_GET_RESULT',
  GET_ERROR = 'USER_DATA_GET_ERROR',
  GET_ACTIVE_DEMAND = 'GET_ACTIVE_DEMAND',
  CREATE_NEW_DEMAND = 'CREATE_NEW_DEMAND',
  LAUNCH_CREATE_NEW_DEMAND = 'LAUNCH_CREATE_NEW_DEMAND',
  SET_NEW_DEMAND = 'SET_NEW_DEMAND',
  SET_ACTIVE_DEMAND = 'SET_ACTIVE_DEMAND',
  SET_DEMAND_AS_ACTIVE = 'SET_DEMAND_AS_ACTIVE',
  GET_USER_EDUCATION_DATA = 'USER_EDUCATION_DATA_GET',
  GET_RESULT_USER_EDUCATION_DATA = 'USER_EDUCATION_DATA_GET_RESULT',
  NON_EXISTENT_USER_EDUCATION_DATA = 'NON_EXISTENT_USER_EDUCATION_DATA',
  GET_USER_MY_DATA = 'USER_MY_DATA_GET',
  GET_RESULT_USER_MY_DATA = 'USER_MY_DATA_GET_RESULT',
  NON_EXISTENT_USER_MY_DATA = 'NON_EXISTENT_USER_MY_DATA',
  FINALIZE_ACCOUNT = 'USER_DATA_FINALIZE_ACCOUNT',
  FINALIZE_ACCOUNT_SUCCESS = 'USER_DATA_FINALIZE_ACCOUNT_SUCCESS',
  FINALIZE_ACCOUNT_ERROR = 'USER_DATA_FINALIZE_ACCOUNT_ERROR',
  CAN_CREATE_NEW_DEMAND = 'USER_DATA_CAN_CREATE_NEW_DEMAND',
  CAN_CREATE_NEW_DEMAND_RESULT = 'USER_DATA_CAN_CREATE_NEW_DEMAND_RESULT',
  SET_NOTIFICATION_SETTINGS = 'SET_NOTIFICATION_SETTINGS',
  SET_NOTIFICATION_SETTINGS_RESULT = 'SET_NOTIFICATION_SETTINGS_RESULT',
}

export interface UserDataGetAction {
  type: typeof UserDataAction.GET;
}

export interface UserDataGetResultAction {
  type: typeof UserDataAction.GET_RESULT;
  payload: UserData;
}

export interface UserDataGetErrorAction {
  type: typeof UserDataAction.GET_ERROR;
  payload: string;
}

export interface UserDataGetActiveDemandAction {
  type: typeof UserDataAction.GET_ACTIVE_DEMAND;
  dossierId: number;
}

export interface UserDataLaunchCreateNewDemandAction {
  type: typeof UserDataAction.LAUNCH_CREATE_NEW_DEMAND;
  newDemand: DemandData;
  dossierId: number;
}

export interface UserDataCreateNewDemandAction {
  type: typeof UserDataAction.CREATE_NEW_DEMAND;
  newDemand: DemandData;
  dossierId: number;
}

export interface UserDataSetActiveDemandAction {
  type: typeof UserDataAction.SET_ACTIVE_DEMAND;
  demandId: number;
  dossierId: number;
  payload: number;
}

export interface UserDataSetNewDemandAction {
  type: typeof UserDataAction.SET_NEW_DEMAND;
  dossierId: number;
  demandId: number;
  payload: UserDemandData;
}

export interface UserDataSetDemandAsActiveAction {
  type: typeof UserDataAction.SET_DEMAND_AS_ACTIVE;
  demandId: number;
}

export interface UserMyEducationDataGetAction {
  type: typeof UserDataAction.GET_USER_EDUCATION_DATA;
  payload: number;
}

export interface UserMyEducationDataGetResultAction {
  type: typeof UserDataAction.GET_RESULT_USER_EDUCATION_DATA;
  payload: UserData;
}

export interface UserMyDataGetAction {
  type: typeof UserDataAction.GET_USER_MY_DATA;
  payload: number;
}

export interface UserMyDataGetResultAction {
  type: typeof UserDataAction.GET_RESULT_USER_MY_DATA;
  payload: UserData;
}

export interface FinalizeUserAccountAction {
  type: typeof UserDataAction.FINALIZE_ACCOUNT;
  payload: number;
}

export interface FinalizeUserAccountSuccessAction {
  type: typeof UserDataAction.FINALIZE_ACCOUNT_SUCCESS;
  payload: number;
}

export interface FinalizeUserAccountErrorAction {
  type: typeof UserDataAction.FINALIZE_ACCOUNT_ERROR;
  payload: string;
}

export interface CanCreateNewDemandAction {
  type: typeof UserDataAction.CAN_CREATE_NEW_DEMAND;
  payload: number;
}

export interface CanCreateNewDemandResultAction {
  type: typeof UserDataAction.CAN_CREATE_NEW_DEMAND_RESULT;
  payload: CanCreateNewDemandResult;
}

export interface UserDataSetNotificationSettingsAction {
  type: typeof UserDataAction.SET_NOTIFICATION_SETTINGS;
  payload: boolean;
}

export interface UserDataSetNotificationSettingsResultAction {
  type: typeof UserDataAction.SET_NOTIFICATION_SETTINGS_RESULT;
  payload: boolean;
}

export type UserDataActionType =
  | UserDataGetActiveDemandAction
  | UserDataLaunchCreateNewDemandAction
  | UserDataCreateNewDemandAction
  | UserDataSetNewDemandAction
  | UserDataSetActiveDemandAction
  | UserDataSetDemandAsActiveAction
  | UserDataGetAction
  | UserDataGetResultAction
  | UserDataGetErrorAction
  | UserMyEducationDataGetAction
  | UserMyEducationDataGetResultAction
  | UserMyDataGetAction
  | UserMyDataGetResultAction
  | FinalizeUserAccountAction
  | CanCreateNewDemandAction
  | CanCreateNewDemandResultAction
  | UserDataSetNotificationSettingsAction
  | UserDataSetNotificationSettingsResultAction;
