import produce from 'immer';
import { NotificationCaseType } from '../../utils/form-data';
import { NotificationAction, NotificationTypes } from './actions';
import { CaseDto, ChatConversation, Notification, NotificationsAndMessagesStore } from './types';

const notification1: Notification = {
  id: 1,
  title: 'Das Darlehen wurde überwiesen',
  message: 'Lorem ipsum dolor sit amet.',
  sendDate: new Date(),
  isNew: true,
};
const notification2: Notification = {
  id: 2,
  title: 'Ab sofort können Sie einen Stipendienantrag einreichen. So einfach geht’s.',
  message: 'Lorem ipsum dolor sit amet.',
  sendDate: new Date(),
  isNew: false,
};

export const initialState: Array<Notification> = [notification1, notification2];

const initialStore: NotificationsAndMessagesStore = {
  notifications: [],
  freeTextMessages: [],
  uploadedFiles: [
    {
      name: 'Payment Address Change Files',
      typeId: NotificationCaseType.PAYMENTACCOUNTCHANGE,
      documents: [],
      inProgress: [],
    },
    {
      name: 'New Topic',
      typeId: NotificationCaseType.NEW_CHAT,
      documents: [],
      inProgress: [],
    },
    {
      name: 'Education Completed Files',
      typeId: NotificationCaseType.FORMATIONFINALISATIONCHECK,
      documents: [],
      inProgress: [],
    },
    {
      name: 'Education Canceled Files',
      typeId: NotificationCaseType.FORMATIONABANDONED,
      documents: [],
      inProgress: [],
    },
    {
      name: 'Forbearance',
      typeId: NotificationCaseType.FORBEARANCE,
      documents: [],
      inProgress: [],
    },
  ],
};

const isCaseDtoNew = (caseDto: CaseDto): boolean => {
  return caseDto.clientEvents.find((clientEvent) => clientEvent.read === false) !== undefined;
};

export const notificationReducer = (state = initialStore, action: NotificationTypes): NotificationsAndMessagesStore => {
  return produce(state, (draft) => {
    let freeChatMessageFileUploadIndex = -1;
    let caseIdIndex = -1;
    let typeIndex = -1;
    switch (action.type) {
      case NotificationAction.GET_MESSAGES:
        draft.isLoading = true;
        break;
      case NotificationAction.GET_MESSAGES_RESULT:
        draft.isLoading = false;
        const notifications: Array<Notification> = action.payload
          .filter((dto) => dto.caseStep.caseTypeId !== NotificationCaseType.CHAT)
          .sort((a: CaseDto, b: CaseDto) => {
            return b.id - a.id;
          })
          .map((caseDto) => {
            const clientEventsLength = caseDto.clientEvents.length;
            const title = caseDto.clientEvents[clientEventsLength - 1]?.caseStep?.notification;
            return {
              id: caseDto.id ? caseDto.id : 1,
              title: title ? title : '-',
              message: '',
              isNew: isCaseDtoNew(caseDto),
              sendDate: new Date(caseDto.initDate),
              clientEvents: caseDto.clientEvents,
            };
          });
        draft.notifications = notifications;

        const conversations: Array<ChatConversation> = action.payload
          // .filter((dto) => dto.caseStep.caseTypeId === NotificationCaseType.CHAT)
          .sort((a: CaseDto, b: CaseDto) => {
            return new Date(b.initDate).getTime() - new Date(a.initDate).getTime();
          })
          .map((caseDto) => {
            const clientEventsLength = caseDto.clientEvents.length;
            let title = caseDto.clientEvents[clientEventsLength - 1]?.caseStep?.notification;
            if (!title) {
              title = caseDto.title;
            }
            return {
              title: caseDto.caseStepId === 1010 || caseDto.caseStepId === 1020 ? caseDto.title : title,
              caseId: caseDto.id ? caseDto.id : 0,
              messages: caseDto.clientEvents,
              date: new Date(caseDto.initDate),
              typeId: caseDto.caseStep?.caseTypeId ? caseDto.caseStep.caseTypeId : 0,
              stepId: caseDto.caseStepId,
              answerFromPortalAllowed: caseDto.answerFromPortalAllowed,
            };
          });
        draft.freeTextMessages = conversations;
        break;
      case NotificationAction.SEND_TEXT_MESSAGE_RESULT:
        const caseDto = action.payload;
        draft.freeTextMessages = [
          {
            title: caseDto.title ? caseDto.title : '',
            caseId: caseDto.id ? caseDto.id : 0,
            messages: caseDto.clientEvents,
            date: new Date(caseDto.initDate),
            typeId: caseDto.caseStep?.caseTypeId ? caseDto.caseStep.caseTypeId : 0,
            stepId: caseDto.caseStepId,
            answerFromPortalAllowed: caseDto.answerFromPortalAllowed,
          },
          ...draft.freeTextMessages,
        ];
        const indexToReset = draft.uploadedFiles.findIndex(
          (docType) => docType.typeId === NotificationCaseType.NEW_CHAT,
        );
        if (indexToReset > -1) {
          draft.uploadedFiles[indexToReset].documents = [];
          draft.uploadedFiles[indexToReset].caseId = undefined;
        }
        break;
      case NotificationAction.SEND_TEXT_MESSAGE_ANSWER_RESULT:
        const answerCaseDto = action.payload;
        //draft.freeTextMessages.findIndex((case: CaseAnswerDto) => answerCaseDto.caseId === case.id);
        const indexToReplace = draft.freeTextMessages.findIndex((conversation) => {
          return conversation.caseId === answerCaseDto.id;
        });
        if (indexToReplace > -1) {
          draft.freeTextMessages[indexToReplace] = {
            title: answerCaseDto.title ? answerCaseDto.title : '',
            caseId: answerCaseDto.id ? answerCaseDto.id : 0,
            messages: answerCaseDto.clientEvents,
            date: new Date(answerCaseDto.initDate),
            typeId: answerCaseDto.caseStep?.caseTypeId ? answerCaseDto.caseStep.caseTypeId : 0,
            stepId: answerCaseDto.caseStepId,
            answerFromPortalAllowed: answerCaseDto.answerFromPortalAllowed,
          };
        }

        // FREE DOCUMENTS FOR INDEX
        freeChatMessageFileUploadIndex = draft.uploadedFiles.findIndex(
          (docType) => docType.caseId === answerCaseDto.id,
        );
        if (freeChatMessageFileUploadIndex > -1) {
          draft.uploadedFiles[freeChatMessageFileUploadIndex].documents = [];
        }
        break;
      case NotificationAction.UPLOAD:
        const uploadAction = action;
        if (uploadAction.caseId) {
          caseIdIndex = draft.uploadedFiles.findIndex((docType) => docType.caseId === uploadAction.caseId);
          if (caseIdIndex > -1) {
            draft.uploadedFiles[caseIdIndex].inProgress.push({
              name: uploadAction.payload.fileName,
              progress: 0,
              typeId: uploadAction.typeId,
            });
            break;
          }
        }
        typeIndex = draft.uploadedFiles.findIndex((docType) => uploadAction.typeId === docType.typeId);
        if (typeIndex > -1) {
          draft.uploadedFiles[typeIndex].inProgress.push({
            name: uploadAction.payload.fileName,
            progress: 0,
            typeId: uploadAction.typeId,
          });
          break;
        }

        if (uploadAction.caseId) {
          draft.uploadedFiles.push({
            name: 'CHAT MESSAGE',
            typeId: NotificationCaseType.CHAT,
            caseId: uploadAction.caseId,
            inProgress: [
              {
                name: uploadAction.payload.fileName,
                progress: 0,
                typeId: uploadAction.typeId,
              },
            ],
            documents: [],
          });
        }

        break;
      case NotificationAction.UPLOAD_READY:
        const uploadReadyAction = action;
        caseIdIndex = draft.uploadedFiles.findIndex((docType) => docType.caseId === uploadReadyAction.caseId);
        if (caseIdIndex > -1) {
          draft.uploadedFiles[caseIdIndex].inProgress = [];
          draft.uploadedFiles[caseIdIndex].documents.push(uploadReadyAction.payload);
          break;
        }
        typeIndex = draft.uploadedFiles.findIndex((docType) => uploadReadyAction.typeId === docType.typeId);
        if (typeIndex > -1) {
          draft.uploadedFiles[typeIndex].inProgress = [];
          draft.uploadedFiles[typeIndex].documents.push(uploadReadyAction.payload);
          draft.uploadedFiles[typeIndex].caseId = uploadReadyAction.caseId;
          break;
        }
        break;
      case NotificationAction.PROGRESS:
        const progressAction = action;
        if (progressAction.caseId) {
          caseIdIndex = draft.uploadedFiles.findIndex((docType) => docType.caseId === progressAction.caseId);
          if (caseIdIndex > -1) {
            draft.uploadedFiles[caseIdIndex].inProgress[0] = progressAction.payload;
            break;
          }
        }
        typeIndex = draft.uploadedFiles.findIndex((docType) => progressAction.payload.typeId === docType.typeId);
        if (typeIndex > -1) {
          draft.uploadedFiles[typeIndex].inProgress[0] = progressAction.payload;
          break;
        }
        break;

      case NotificationAction.SHOW_SUCCESS:
        const showSuccessAction = action;
        caseIdIndex = draft.uploadedFiles.findIndex((docType) => docType.caseId === showSuccessAction.caseId);
        if (caseIdIndex > -1) {
          draft.uploadedFiles[caseIdIndex].uploadSuccess = true;
          break;
        }
        typeIndex = draft.uploadedFiles.findIndex((docType) => showSuccessAction.payload === docType.typeId);
        if (typeIndex > -1) {
          draft.uploadedFiles[typeIndex].uploadSuccess = true;
          break;
        }
        break;
      case NotificationAction.UPLOAD_ERROR:
        const errorAction = action;
        caseIdIndex = draft.uploadedFiles.findIndex((docType) => docType.caseId === errorAction.caseId);
        if (caseIdIndex > -1) {
          draft.uploadedFiles[caseIdIndex].inProgress = [];
          draft.uploadedFiles[caseIdIndex].uploadError = errorAction.payload.error;
          break;
        }
        typeIndex = draft.uploadedFiles.findIndex((docType) => errorAction.payload.typeId === docType.typeId);
        if (typeIndex > -1) {
          draft.uploadedFiles[typeIndex].inProgress = [];
          draft.uploadedFiles[typeIndex].uploadError = errorAction.payload.error;
          break;
        }
        break;
      case NotificationAction.RESET_STATUS:
        const resetStatusAction = action;
        if (resetStatusAction.caseId) {
          caseIdIndex = draft.uploadedFiles.findIndex((docType) => docType.caseId === resetStatusAction.caseId);
          if (caseIdIndex > -1) {
            draft.uploadedFiles[caseIdIndex].inProgress = [];
            draft.uploadedFiles[caseIdIndex].uploadError = undefined;
            draft.uploadedFiles[caseIdIndex].uploadSuccess = false;
            break;
          }
        }

        typeIndex = draft.uploadedFiles.findIndex((docType) => resetStatusAction.payload === docType.typeId);
        if (typeIndex > -1) {
          draft.uploadedFiles[typeIndex].inProgress = [];
          draft.uploadedFiles[typeIndex].uploadError = undefined;
          draft.uploadedFiles[typeIndex].uploadSuccess = false;
          break;
        }
        break;
      case NotificationAction.MARK_AS_READ:
        const markAsReadAction = action;
        const caseNotificationId = markAsReadAction.caseId;
        const notificationReadIndex = draft.notifications.findIndex((notification) => {
          return notification.id === caseNotificationId;
        });
        const messageReadIndex = draft.freeTextMessages.findIndex((message) => {
          return message.caseId === caseNotificationId;
        });
        if (notificationReadIndex > -1) {
          draft.notifications[notificationReadIndex] = {
            ...draft.notifications[notificationReadIndex],
            isNew: false,
          };
        }
        if (messageReadIndex > -1) {
          const messageRead = draft.freeTextMessages[messageReadIndex];
          messageRead.messages.forEach((msg) => {
            msg.read = true;
          });
          draft.freeTextMessages[messageReadIndex] = {
            ...messageRead,
          };
        }
        break;
      case NotificationAction.MARK_ALL_AS_READ:
        draft.notifications.forEach((notification) => {
          notification.isNew = false;
        });
        draft.freeTextMessages.forEach((message) => {
          message.messages.forEach((msg) => {
            msg.read = true;
          });
        });
        break;
      case NotificationAction.DELETE_FILE_SUCCESS:
        const deleteFileSuccessAction = action;
        let docTypeIndex = -1;
        let fileIndex = -1;
        draft.uploadedFiles.forEach((docType, freeChatIndex) => {
          const indexToDelete = docType.documents.findIndex(
            (file) => file.streamId === deleteFileSuccessAction.payload,
          );
          if (indexToDelete > -1) {
            docTypeIndex = freeChatIndex;
            fileIndex = indexToDelete;
          }
        });
        if (fileIndex > -1) {
          draft.uploadedFiles[docTypeIndex].documents.splice(fileIndex, 1);
          if (
            draft.uploadedFiles[docTypeIndex].documents.length === 0 &&
            draft.freeTextMessages.find(
              (conversation) => conversation.caseId === draft.uploadedFiles[docTypeIndex].caseId,
            ) === undefined
          ) {
            draft.uploadedFiles[docTypeIndex].caseId = undefined;
          }
        }
        break;
      default:
        break;
    }
    return draft;
  });
};
