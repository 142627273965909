import { Button, TextField } from '@material-ui/core';
import React, { ChangeEvent, ReactElement, useState } from 'react';
import { requiredValidation } from '../../utils/validators';
import { ZoomCaseFileUpload, ZoomCaseFileUploadProps } from '../forms/file-upload/ZoomCaseFileUpload';
import { ReplyWithFileProps } from './ReplyWithFile';
import './Message.scss';
import { NotificationCaseType } from '../../utils/form-data';
import { NotificationTypes } from '../../store/notifications/actions';

export interface NewConversationProps extends ReplyWithFileProps {
  labelNewConversationTitle: string;
  labelNewConversationMessage: string;
  newConversationTitle?: string;
  newConversationMessage?: string;
  newConversationActive?: boolean;
  errorLabels?: { [key: string]: string };
  caseId?: number;
  onSend?: (title: string, message: string, caseId?: number) => void;
  dispatch?: (action: NotificationTypes) => void;
}

export interface NewConversationErrors {
  title?: string;
  message?: string;
}

export const NewConversation = (props: NewConversationProps): ReactElement => {
  const [newConversationMessage, setNewConversationMessage] = useState(
    props.newConversationMessage ? props.newConversationMessage : '',
  );
  const [newConversationTitle, setNewConversationTitle] = useState(
    props.newConversationTitle ? props.newConversationTitle : '',
  );

  const initialErrors: NewConversationErrors = {};
  const [errors, setErrors] = useState(initialErrors);

  const onNewConversationTitleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setNewConversationTitle(event.target.value);
    setErrors({ ...errors, title: requiredValidation(event.target.value, props.errorLabels ? props.errorLabels : {}) });
  };

  const onNewConversationMessageChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setNewConversationMessage(event.target.value);
    setErrors({
      ...errors,
      message: requiredValidation(event.target.value, props.errorLabels ? props.errorLabels : {}),
    });
  };

  const validate = () => {
    const newErrors: NewConversationErrors = {
      title: requiredValidation(newConversationTitle, props.errorLabels ? props.errorLabels : {}),
      message: requiredValidation(newConversationMessage, props.errorLabels ? props.errorLabels : {}),
    };
    setErrors(newErrors);
    const errorsPresent = Object.values(newErrors).some((value) => value !== '');
    return !errorsPresent;
  };

  const sendData = () => {
    if (validate() && props.onSend) {
      props.onSend(newConversationTitle, newConversationMessage);
    }
  };

  const classNameTextArea = errors.message ? 'textarea-error' : '';

  const newFileUploadProps: ZoomCaseFileUploadProps = {
    caseId: props.caseId,
    typeId: -NotificationCaseType.CHAT, // negative type id used for new topic messages
    labelChooseDocument: props.labelChooseDocument,
    labelDragDocument: props.labelDragDocument,
    labelOr: props.labelOr,
    labelSuccess: props.labelSuccess,
    dossierId: props.dossierId,
    files: props.files,
    filesInProgress: props.filesInProgress,
    uploadError: props.uploadError,
    uploadSuccessful: props.uploadSuccessful,
    dispatch: props.dispatch,
  };
  return (
    <div className="conversation-container opened">
      <span className="new-conversation-label">{props.labelNewConversationTitle}</span>
      <TextField
        className="new-conversation-title-input"
        variant="outlined"
        name="conversation-title"
        value={newConversationTitle}
        onChange={onNewConversationTitleChange}
        error={!!errors.title}
        helperText={errors.title}
        fullWidth
        size="small"
      ></TextField>
      <p id="new-conversation-message-label" className="new-conversation-label">
        {props.labelNewConversationMessage}
      </p>
      <div className="reply-container clearfix">
        <textarea
          className={classNameTextArea}
          id="reply-field"
          name="reply-field"
          onChange={onNewConversationMessageChange}
          value={newConversationMessage}
        ></textarea>
        {errors.message && <p className="message-error">{errors.message}</p>}
        <ZoomCaseFileUpload {...newFileUploadProps}></ZoomCaseFileUpload>
        <p className="button-line">
          <Button variant="contained" color="primary" id="send-new-conversation" onClick={sendData}>
            {props.labelSendMessage}
          </Button>
        </p>
      </div>
    </div>
  );
};
