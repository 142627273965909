import React, { ReactElement } from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import { blue } from './../../theme';

export function ZoomArrowLeftIcon(props: SvgIconProps): ReactElement {
  const color = props.htmlColor ? props.htmlColor : blue.main;
  return (
    <SvgIcon {...props}>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <path d="M0 0H80V80H0z" transform="translate(-456 -57) translate(456 57)" />
            <path
              fill={color}
              fillRule="nonzero"
              d="M51.62 64.45c-.532.003-1.043-.206-1.42-.58L28.33 42c-.376-.375-.587-.884-.587-1.415 0-.53.211-1.04.587-1.415L50.2 17.28c.799-.65 1.96-.587 2.684.145.723.732.774 1.893.116 2.685L32.57 40.58 53 61c.376.375.587.884.587 1.415 0 .53-.211 1.04-.587 1.415-.361.38-.857.602-1.38.62z"
              transform="translate(-456 -57) translate(456 57)"
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}
