import DateFnsUtils from '@date-io/date-fns';
import { Grid } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import 'date-fns';
import deLocale from 'date-fns/locale/de';
import enLocale from 'date-fns/locale/en-US';
import frLocale from 'date-fns/locale/fr';
import itLocale from 'date-fns/locale/it';
import React, { ReactElement, useEffect, useState } from 'react';
import { ZoomInfoIcon } from './../../icons/ZoomInfoIcon';
import { ZoomTextInputHelpTooltip } from './../../tooltips/ZoomTextInputHelpTooltip';
import './ZoomDatePicker.scss';

export interface ZoomDatePickerProps {
  label: string;
  value?: Date | null;
  name?: string;
  min?: number;
  max?: number;
  step?: number;
  readonly?: boolean;
  disabled?: boolean;
  error?: boolean;
  errorMessage?: string;
  invalidDateMessage?: string;
  locale?: 'de' | 'en' | 'fr' | 'it';
  helpText?: string;
  formatMonthYearOnly?: boolean;
  onChange?: (newDate: Date | null) => void;
}

const localeMap = {
  en: enLocale,
  de: deLocale,
  fr: frLocale,
  it: itLocale,
};

const ZoomDatePicker = (props: ZoomDatePickerProps): ReactElement => {
  const [value, setValue] = useState(props.value ? props.value : null);
  const [locale] = useState(props.locale ? props.locale : 'de');
  const [error, setError] = useState(props.error ? true : false);
  const [errorMessage, setErrorMessage] = useState(props.errorMessage ? props.errorMessage : '');

  useEffect(() => {
    setValue(props.value ? props.value : null);
    setError(props.error ? true : false);
    setErrorMessage(props.errorMessage ? props.errorMessage : '');
  }, [props.value, props.error, props.errorMessage]);

  const onChange = (date: Date | null): void => {
    setValue(date);
    if (props.onChange) {
      props.onChange(date);
    }
  };

  return (
    <Grid container direction="row" alignItems="stretch" justify="flex-start">
      <Grid item xs={12} sm={6}>
        <div className="label">
          <label>{props.label}</label>
          {props.helpText && (
            <ZoomTextInputHelpTooltip placement="bottom" arrow={true} title={<p>{props.helpText}</p>}>
              <span style={{ width: '24px', height: '24px' }}>
                <ZoomInfoIcon color="primary" viewBox="0 0 80 80"></ZoomInfoIcon>
              </span>
            </ZoomTextInputHelpTooltip>
          )}
        </div>
      </Grid>
      <Grid item xs={12} sm={6}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap[locale]}>
          {props.formatMonthYearOnly ? (
            <KeyboardDatePicker
              className="zoom-datepicker"
              fullWidth
              variant="inline"
              inputVariant="outlined"
              views={['year', 'month']}
              format="MM.yyyy"
              autoOk={true}
              margin="none"
              value={value}
              name={props.name}
              error={error}
              disabled={props.disabled}
              InputProps={{ readOnly: props.readonly }}
              readOnly={props.readonly}
              helperText={errorMessage}
              size="small"
              invalidDateMessage={props.invalidDateMessage}
              onChange={onChange}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          ) : (
            <KeyboardDatePicker
              className="zoom-datepicker"
              fullWidth
              variant="inline"
              inputVariant="outlined"
              format="dd.MM.yyyy"
              autoOk={true}
              margin="none"
              value={value}
              name={props.name}
              error={error}
              disabled={props.disabled}
              InputProps={{ readOnly: props.readonly }}
              readOnly={props.readonly}
              helperText={errorMessage}
              size="small"
              invalidDateMessage={props.invalidDateMessage}
              onChange={onChange}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          )}
        </MuiPickersUtilsProvider>
      </Grid>
    </Grid>
  );
};

export default ZoomDatePicker;
