import { Action } from 'redux';
import { Observable, of } from 'rxjs';
import { Epic, ofType } from 'redux-observable';
import { mergeMap } from 'rxjs/operators';
import { RoutingAction, RoutingRedirectToAction } from './actions';

export const redirectEpic: Epic<Action, Action> = (action$: Observable<Action>): Observable<Action> =>
  action$.pipe(
    ofType(RoutingAction.REDIRECT_TO),
    mergeMap((action) => {
      const routeToAction = action as RoutingRedirectToAction;
      window.location.href = routeToAction.payload;
      return of({ type: 'VOID_ACTION' });
    }),
  );
