import { Breadcrumbs, Button, Card, CardContent, Container, Grid, Link } from '@material-ui/core';
import React, { ChangeEvent, ReactElement, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';
import { Action } from 'redux';
import ZoomFooter, {
  mapStoreToFooterProps,
  ZoomFooterProps,
} from '../../components/base-page-components/footer/ZoomFooter';
import ZoomHeader, {
  mapStoreToHeaderProps,
  ZoomHeaderProps,
} from '../../components/base-page-components/header/ZoomHeader';
import ZoomDatePicker from '../../components/forms/date-picker/ZoomDatePicker';
import ZoomSelectInput from '../../components/forms/select-input/ZoomSelectInput';
import ZoomTextInput from '../../components/forms/text-input/ZoomTextInput';
import { ZoomArrowLeftIcon } from '../../components/icons/ZoomArrowLeftIcon';
import { i18nLabel } from '../../store/i18n/helpers';
import { WIAAStore } from '../../store/types';
import { UserDataAction, UserDataGetAction } from '../../store/user-data/actions';
import { blue } from '../../theme';
import { NotificationCaseType, periodOptions } from '../../utils/form-data';
import { dateInFutureValidation, requiredValidation } from '../../utils/validators';
import {
  RepaymentConditionsChangeAction,
  RepaymentConditionsDataAction,
} from '../../store/repayment-conditions/actions';
import { setTimeToMiddleOfDay } from '../../utils/date-manipulation';

export interface RepaymentConditionsProps {
  dossierId: number;
  startDate?: Date;
  period?: string;
  reason?: string;
  labelTitle?: string;
  labelDescription?: string;
  labelStartDate?: string;
  labelPeriod?: string;
  labelReason?: string;
  labelCancel?: string;
  labelSave?: string;
  labelBackArrow?: string;
  breadcrumbLabels?: Array<string>;
  headerProps: ZoomHeaderProps;
  footerProps: ZoomFooterProps;
  foreignKeyId: number;
  errorLabels?: { [key: string]: string };
  dispatch?: (action: Action) => void;
}

interface RepaymentConditionsDataErrors {
  startDate?: string;
  period?: string;
  reason?: string;
}

export const RepaymentConditions = (props: RepaymentConditionsProps): ReactElement => {
  const [startDate, setStartDate] = useState(props.startDate ? props.startDate : null);
  const [period, setPeriod] = useState(props.period ? props.period : '0');
  const [reason, setReason] = useState(props.reason ? props.reason : '');
  const history = useHistory();
  const initialErrors: RepaymentConditionsDataErrors = {};
  const [errors, setErrors] = useState(initialErrors);

  useEffect(() => {
    if (props.dispatch) {
      const getUserData: UserDataGetAction = { type: UserDataAction.GET };
      props.dispatch(getUserData);
    }
  }, []);

  const onStartDateChange = (newDate: Date | null) => {
    setStartDate(newDate);
    setErrors({
      ...errors,
      startDate: getStartDateErrors(newDate),
    });
  };

  const onPeriodChange = (newValue: string) => {
    setPeriod(newValue);
    setErrors({
      ...errors,
      period: newValue === '0' ? requiredValidation('', props.errorLabels ? props.errorLabels : {}) : '',
    });
  };

  const onReasonChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setReason(e.target.value);
    setErrors({
      ...errors,
      reason: requiredValidation(e.target.value, props.errorLabels ? props.errorLabels : {}),
    });
  };

  const getStartDateErrors = (startDate: Date | null): string => {
    if (startDate === null) {
      return requiredValidation('', props.errorLabels ? props.errorLabels : {});
    }
    return dateInFutureValidation(startDate, props.errorLabels ? props.errorLabels : {});
  };

  const getFormErrors = (): RepaymentConditionsDataErrors => {
    const errors: RepaymentConditionsDataErrors = {};
    errors.period = period === '0' ? requiredValidation('', props.errorLabels ? props.errorLabels : {}) : '';
    errors.startDate = getStartDateErrors(startDate);
    errors.reason = requiredValidation(reason, props.errorLabels ? props.errorLabels : {});
    return errors;
  };

  const validateForm = (): boolean => {
    const newErrors = getFormErrors();
    setErrors(newErrors);
    const errorsPresent = Object.values(newErrors).some((value) => value !== '');
    return !errorsPresent;
  };

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    if (validateForm()) {
      if (props.dispatch) {
        const changedAction: RepaymentConditionsChangeAction = {
          type: RepaymentConditionsDataAction.REPAYMENT_CONDITIONS_CHANGE,
          payload: {
            typeId: NotificationCaseType.REPAYMENTCONDITIONS,
            subject: 'CHANGE REQUEST',
            body: JSON.stringify({
              startDate: setTimeToMiddleOfDay(startDate),
              period,
              reason,
            }),
            foreignKeyId: props.foreignKeyId,
          },
          dossierId: props.dossierId,
        };
        props.dispatch(changedAction);
      }
    }
    event.preventDefault();
  };

  return (
    <>
      <ZoomHeader {...props.headerProps} />
      <main className="main">
        <Container>
          <div id="main-page-container">
            <Breadcrumbs separator=">" aria-label="breadcrumb">
              <Link
                style={{ fontFamily: 'Proxima Nova Cond' }}
                color="inherit"
                href={'/dashboard/dossier/' + props.dossierId}
              >
                {props.breadcrumbLabels && props.breadcrumbLabels[0]}
              </Link>
              <Link
                style={{ fontFamily: 'Proxima Nova Cond' }}
                color="inherit"
                href={'/my-contracts/' + props.dossierId}
              >
                {props.breadcrumbLabels && props.breadcrumbLabels[1]}
              </Link>
              <Link style={{ fontFamily: 'Proxima Nova Cond' }} color="inherit">
                <b>{props.breadcrumbLabels && props.breadcrumbLabels[2]}</b>
              </Link>
            </Breadcrumbs>
            <div className="main-page-info">
              <div className="back-button-desktop" onClick={() => history.goBack()}>
                <ZoomArrowLeftIcon viewBox="0 0 80 80" fontSize="large" htmlColor={blue.main}></ZoomArrowLeftIcon>
                {props.labelBackArrow}
              </div>
              <h1 className="title-desktop">{props.labelTitle}</h1>
              <p
                dangerouslySetInnerHTML={{
                  __html: props.labelDescription ? props.labelDescription : '',
                }}
              ></p>
            </div>
            <Card>
              <CardContent>
                <form onSubmit={onSubmit}>
                  <div className="form-element-container">
                    <ZoomDatePicker
                      label={props.labelStartDate ? props.labelStartDate : ''}
                      onChange={onStartDateChange}
                      name="startDate"
                      value={startDate}
                      error={!!errors.startDate}
                      errorMessage={errors.startDate}
                    ></ZoomDatePicker>
                  </div>
                  <div className="form-element-container">
                    <ZoomSelectInput
                      label={props.labelPeriod ? props.labelPeriod : ''}
                      options={periodOptions}
                      value={period}
                      name="period"
                      onChange={onPeriodChange}
                      error={!!errors.period}
                      errorMessage={errors.period}
                    ></ZoomSelectInput>
                  </div>
                  <div className="form-element-container">
                    <ZoomTextInput
                      label={props.labelReason ? props.labelReason : ''}
                      name="reason"
                      value={reason}
                      onChange={onReasonChange}
                      error={!!errors.reason}
                      errorMessage={errors.reason}
                    />
                  </div>
                  <Grid container direction="row" spacing={2} style={{ paddingBottom: '1rem' }}>
                    <Grid item xs={6} sm={6}>
                      <div className="form-actions-container-cancel">
                        <Button
                          color="primary"
                          size="large"
                          type="reset"
                          variant="outlined"
                          id="cancel-repayment-conditions-data"
                          style={{ width: '144px' }}
                          onClick={() => history.goBack()}
                        >
                          {props.labelCancel}
                        </Button>
                      </div>
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      <div className="form-actions-container-save">
                        <Button
                          color="primary"
                          size="large"
                          type="submit"
                          variant="contained"
                          id="save-repayment-conditions-data"
                          style={{ width: '144px' }}
                        >
                          {props.labelSave}
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                </form>
              </CardContent>
            </Card>
          </div>
        </Container>
      </main>
      <ZoomFooter {...props.footerProps} />
    </>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export function mapStateToProps(store: WIAAStore, ownProps: any): RepaymentConditionsProps {
  const { dossierId, foreignKeyId } = ownProps.match.params;
  const headerProps: ZoomHeaderProps = mapStoreToHeaderProps(store, dossierId);
  const footerProps: ZoomFooterProps = mapStoreToFooterProps(store);

  return {
    headerProps,
    footerProps,
    labelTitle: i18nLabel(store.translationLabels, 'Repayment_Conditions_Data_Title', 'Rückzahlungskonditionen'),
    labelDescription: i18nLabel(
      store.translationLabels,
      'Repayment_Conditions_Data_Description',
      'Sollten Sie die Rechnung nicht aufs Mal begleichen können, haben Sie die Möglichkeit einer monatlichen Ratenzahlung. Bitte machen Sie uns einen konkreten Zahlungsvorschlag. Erfolgt eine Zahlung nicht termingerecht, wird der verbleibende Restbetrag sofort fällig. Die Rückzahlungsvereinbarung verliert ihre Gültigkeit. Die Restschuld muss wieder in jährlichen Raten beglichen werden. Für allfällige Fragen stehen wir Ihnen zur Verfügung.',
    ),
    labelStartDate: i18nLabel(store.translationLabels, 'Repayment_Conditions_Data_Start_Date', 'Startdatum*'),
    labelPeriod: i18nLabel(store.translationLabels, 'Repayment_Conditions_Data_Period', 'Anzahl Raten*'),
    labelReason: i18nLabel(store.translationLabels, 'Repayment_Conditions_Data_Reason', 'Begründung*'),
    labelCancel: i18nLabel(store.translationLabels, 'Repayment_Conditions_Data_Cancel', 'Abbrechen'),
    labelSave: i18nLabel(store.translationLabels, 'Repayment_Conditions_Data_Save', 'Speichern'),
    labelBackArrow: i18nLabel(store.translationLabels, 'Repayment_Conditions_Back_Arrow', 'Zurück'),
    breadcrumbLabels: [
      i18nLabel(store.translationLabels, 'Repayment_Conditions_Breadcrumb_Label_1', 'Übersicht'),
      i18nLabel(store.translationLabels, 'Repayment_Conditions_Breadcrumb_Label_2', 'Darlehensverträge'),
      i18nLabel(store.translationLabels, 'Repayment_Conditions_Breadcrumb_Label_3', 'Rückzahlungskonditionen'),
    ],
    errorLabels: store.translationLabels,
    dossierId: dossierId,
    foreignKeyId: foreignKeyId,
  };
}

export default withRouter(connect(mapStateToProps)(RepaymentConditions));
