import { Action } from 'redux';
import { Observable, of } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { Epic, ofType } from 'redux-observable';
import { catchError, map, mergeMap } from 'rxjs/operators';

import {
  ChildrenDataAction,
  ChildrenDataEditChildAction,
  ChildrenDataGetResultAction,
  ChildrenDataEditResultAction,
  ChildrenDataAddChildAction,
  ChildrenDataAddResultAction,
  ChildrenDataDeleteChildAction,
  ChildrenDataDeleteResultAction,
  ChildrenDataGetAction,
} from './actions';
import { handleRequestFailureInEpic } from '../../utils/error-handling';
import { customAjax } from '../../utils/ajax-wrapper';

const loadChildrenData = (payload: AjaxResponse): ChildrenDataGetResultAction => {
  return {
    type: ChildrenDataAction.GET_RESULT,
    payload: payload.response,
  };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const loadChildrenOnError = (error: any): any => {
  if (error.status == 404) {
    return {
      type: ChildrenDataAction.EMPTY_CHILDREN_DATA,
    };
  }

  return handleRequestFailureInEpic(error);
};

export const getChildrenDataEpic: Epic<Action, Action> = (action$: Observable<Action>): Observable<Action> =>
  action$.pipe(
    ofType(ChildrenDataAction.GET),
    mergeMap((action) => {
      const pifId = (action as ChildrenDataGetAction).pifId;

      return customAjax({
        subRoute: `/dossier/v1/persons-in-formation/${pifId}/children`,
        withCredentials: true,
        method: 'GET',
      }).pipe(
        map((response) => loadChildrenData(response)),
        catchError((error) => {
          return of(loadChildrenOnError(error));
        }),
      );
    }),
  );

const childDataAdded = (payload: AjaxResponse): ChildrenDataAddResultAction => {
  return {
    type: ChildrenDataAction.ADD_RESULT,
    payload: { ...payload.response },
  };
};

export const addChildDataEpic: Epic<Action, Action> = (action$: Observable<Action>): Observable<Action> =>
  action$.pipe(
    ofType(ChildrenDataAction.ADD_ITEM),
    mergeMap((action) => {
      const item = (action as ChildrenDataAddChildAction).payload;
      const pifId = (action as ChildrenDataAddChildAction).pifId;

      return customAjax({
        subRoute: `/dossier/v1/persons-in-formation/${pifId}/children`,
        withCredentials: true,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(item),
      }).pipe(
        map((response) => childDataAdded(response)),
        catchError((error) => {
          return of(handleRequestFailureInEpic(error));
        }),
      );
    }),
  );

const childDataEdited = (payload: AjaxResponse): ChildrenDataEditResultAction => {
  return {
    type: ChildrenDataAction.EDIT_RESULT,
    payload: { ...payload.response },
  };
};

export const editChildDataEpic: Epic<Action, Action> = (action$: Observable<Action>): Observable<Action> =>
  action$.pipe(
    ofType(ChildrenDataAction.EDIT_ITEM),
    mergeMap((action) => {
      const item = (action as ChildrenDataEditChildAction).payload;
      const pifId = (action as ChildrenDataEditChildAction).pifId;

      return customAjax({
        subRoute: `/dossier/v1/persons-in-formation/${pifId}/children/${item.id}`,
        withCredentials: true,
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(item),
      }).pipe(
        map((response) => childDataEdited(response)),
        catchError((error) => {
          return of(handleRequestFailureInEpic(error));
        }),
      );
    }),
  );

const childDataDeleted = (payload: number): ChildrenDataDeleteResultAction => {
  return {
    type: ChildrenDataAction.DELETE_RESULT,
    payload,
  };
};

export const deleteChildDataEpic: Epic<Action, Action> = (action$: Observable<Action>): Observable<Action> =>
  action$.pipe(
    ofType(ChildrenDataAction.DELETE_ITEM),
    mergeMap((action) => {
      const itemId = (action as ChildrenDataDeleteChildAction).payload;
      const pifId = (action as ChildrenDataDeleteChildAction).pifId;

      return customAjax({
        subRoute: `/dossier/v1/persons-in-formation/${pifId}/children/${itemId}`,
        withCredentials: true,
        method: 'DELETE',
      }).pipe(
        map(() => childDataDeleted(itemId)),
        catchError((error) => {
          return of(handleRequestFailureInEpic(error));
        }),
      );
    }),
  );
