import { OutgoingData } from './types';

export enum OutgoingDataAction {
  GET = 'OUTGOING_DATA_GET',
  GET_RESULT = 'OUTGOING_DATA_GET_RESULT',
  CHANGE = 'OUTGOING_DATA_CHANGE',
  SUBMIT = 'OUTGOING_DATA_SUBMIT',
  RESULT = 'OUTGOING_DATA_RESULT',
  NON_EXISTENT_OUTGOING = 'NON_EXISTENT_OUTGOING_DATA',
}

export interface OutgoingDataGetAction {
  type: typeof OutgoingDataAction.GET;
  payload: OutgoingData;
  demandId: number;
}

export interface OutgoingDataGetResultAction {
  type: typeof OutgoingDataAction.GET_RESULT;
  payload: OutgoingData;
}

export interface OutgoingDataChangeAction {
  type: typeof OutgoingDataAction.CHANGE;
  payload: string;
  changeField: string;
}

export interface OutgoingDataSubmitAction {
  type: typeof OutgoingDataAction.SUBMIT;
  payload: OutgoingData;
  demandId: number;
}

export interface OutgoingDataResultAction {
  type: typeof OutgoingDataAction.RESULT;
  payload: OutgoingData;
}

export type OutgoingDataActionType =
  | OutgoingDataGetAction
  | OutgoingDataGetResultAction
  | OutgoingDataChangeAction
  | OutgoingDataSubmitAction
  | OutgoingDataResultAction;
