import IconButton from '@material-ui/core/IconButton';
import { NavigateNext } from '@material-ui/icons';
import Edit from '@material-ui/icons/Edit';
import React, { ReactElement } from 'react';
import './SiblingItemInfo.scss';

export interface SiblingItemInfoProps {
  id: number;
  firstName?: string;
  lastName?: string;
  dateOfBirth?: Date;
  error?: boolean;
  onDelete?: (id: number) => void;
  onEdit?: (id: number) => void;
  disableControls?: boolean;
}

export const SiblingItemInfo = (props: SiblingItemInfoProps): ReactElement => {
  const onDelete = () => {
    if (props.onDelete) {
      props.onDelete(props.id);
    }
  };

  const onEdit = () => {
    if (props.onEdit) {
      props.onEdit(props.id);
    }
  };

  return (
    <>
      <div className="sibling-info">
        <div>{props.firstName}</div>
        <div>{props.lastName}</div>
        <div>{props.dateOfBirth ? new Date(props.dateOfBirth).toLocaleDateString() : ''}</div>
        <div className="sibling-info-edit-container">
          <IconButton
            onClick={onEdit}
            color="primary"
            size="small"
            component="span"
            disabled={props.disableControls ? props.disableControls : false}
          >
            <Edit />
          </IconButton>
        </div>
      </div>
      {props.disableControls ? (
        !props.disableControls
      ) : (
        <div className="sibling-info-delete-container">
          <NavigateNext />
          <a onClick={onDelete}>Angaben löschen</a>
        </div>
      )}
    </>
  );
};
