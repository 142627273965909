import { MandatoryPowerOfAttorneyData } from './types';

export enum PowerOfAttorneyDataAction {
  GET = 'POWER_OF_ATTORNEY_DATA_GET',
  GET_RESULT = 'POWER_OF_ATTORNEY_DATA_GET_RESULT',
  ADD_ITEM = 'POWER_OF_ATTORNEY_ADD',
  ADD_RESULT = 'POWER_OF_ATTORNEY_ADD_RESULT',
  ADD_ERROR = 'POWER_OF_ATTORNEY_ADD_ERROR',
  HIDE_ERROR = 'POWER_OF_ATTORNEY_HIDE_ERROR',
  DELETE_ITEM = 'POWER_OF_ATTORNEY_DELETE',
  DELETE_RESULT = 'POWER_OF_ATTORNEY_DELETE_RESULT',
  DELETE_ERROR = 'POWER_OF_ATTORNEY_DELETE_ERROR',
  EMPTY_POWER_OF_ATTORNEY_DATA = 'POWER_OF_ATTORNEY_EMPTY',
  REDIRECT_TO_DASHBOARD = 'POWER_OF_ATTORNEY_REDIRECT_TO_DASHBOARD',
}

export interface PowerOfAttorneyDataGetAction {
  type: typeof PowerOfAttorneyDataAction.GET;
}

export interface PowerOfAttorneyDataGetResultAction {
  type: typeof PowerOfAttorneyDataAction.GET_RESULT;
  payload: MandatoryPowerOfAttorneyData[];
}

export interface PowerOfAttorneyDataAddItemAction {
  type: typeof PowerOfAttorneyDataAction.ADD_ITEM;
  payload: MandatoryPowerOfAttorneyData;
}

export interface PowerOfAttorneyDataAddItemErrorAction {
  type: typeof PowerOfAttorneyDataAction.ADD_ERROR;
  payload: string;
}

export interface PowerOfAttorneyDataAddResultAction {
  type: typeof PowerOfAttorneyDataAction.ADD_RESULT;
  payload: MandatoryPowerOfAttorneyData;
}

export interface PowerOfAttorneyDataDeleteItemAction {
  type: typeof PowerOfAttorneyDataAction.DELETE_ITEM;
  email: string;
}

export interface PowerOfAttorneyDataDeleteResultAction {
  type: typeof PowerOfAttorneyDataAction.DELETE_RESULT;
  email: string;
}

export interface PowerOfAttorneyDataDeleteErrorAction {
  type: typeof PowerOfAttorneyDataAction.DELETE_ERROR;
  payload: string;
}

export interface PowerOfAttorneyHideErrorAction {
  type: typeof PowerOfAttorneyDataAction.HIDE_ERROR;
}

export interface PowerOfAttorneyDataRedirectToDashboardAction {
  type: typeof PowerOfAttorneyDataAction.REDIRECT_TO_DASHBOARD;
}

export type PowerOfAttorneyDataActionType =
  | PowerOfAttorneyDataGetAction
  | PowerOfAttorneyDataGetResultAction
  | PowerOfAttorneyDataAddItemAction
  | PowerOfAttorneyDataAddResultAction
  | PowerOfAttorneyDataDeleteItemAction
  | PowerOfAttorneyDataDeleteResultAction
  | PowerOfAttorneyDataRedirectToDashboardAction
  | PowerOfAttorneyDataDeleteErrorAction
  | PowerOfAttorneyDataAddItemErrorAction
  | PowerOfAttorneyHideErrorAction;
