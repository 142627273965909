import { DocumentsAction, DocumentsActionType } from './actions';
import { DocumentsStore } from './types';
import { produce } from 'immer';

export const initialState: DocumentsStore = {
  roles: [],
};

export const getFileByStreamId = (
  store: DocumentsStore,
  streamId: string,
): { roleIndex: number; typeIndex: number; fileIndex: number } | undefined => {
  for (let i = 0; i < store.roles.length; i++) {
    const role = store.roles[i];
    for (let j = 0; j < role.documentTypes.length; j++) {
      const docType = role.documentTypes[j];
      for (let k = 0; k < docType.documents.length; k++) {
        const zoomFile = docType.documents[k];
        if (zoomFile.streamId === streamId) {
          return { roleIndex: i, typeIndex: j, fileIndex: k };
        }
      }
    }
  }
};

export function documentsReducer(state = initialState, action: DocumentsActionType): DocumentsStore {
  return produce(state, (draft) => {
    switch (action.type) {
      case DocumentsAction.UPLOAD:
        const uploadAction = action;
        const file = uploadAction.payload;
        if (file.typeId) {
          let roleIndex = -1;
          let documentTypeIndex = -1;
          for (let i = 0; i < draft.roles.length; i++) {
            for (let j = 0; j < draft.roles[i].documentTypes.length; j++) {
              const type = draft.roles[i].documentTypes[j];
              if (type.typeId === file.typeId) {
                roleIndex = i;
                documentTypeIndex = j;
                break;
              }
            }
          }

          if (roleIndex > -1 && documentTypeIndex > -1) {
            if (draft.roles[roleIndex].documentTypes[documentTypeIndex].inProgress === undefined) {
              draft.roles[roleIndex].documentTypes[documentTypeIndex].inProgress = [];
            }
            draft.roles[roleIndex].documentTypes[documentTypeIndex].inProgress.push({
              name: file.fileName,
              progress: 0,
              typeId: file.typeId,
            });
          }
        }
        break;
      case DocumentsAction.PROGRESS:
        const progressAction = action;
        const index = 0;

        let roleIndex = -1;
        let documentTypeIndex = -1;
        for (let i = 0; i < draft.roles.length; i++) {
          for (let j = 0; j < draft.roles[i].documentTypes.length; j++) {
            const type = draft.roles[i].documentTypes[j];
            if (type.typeId === progressAction.payload.typeId) {
              roleIndex = i;
              documentTypeIndex = j;
              break;
            }
          }
        }

        if (roleIndex > -1 && documentTypeIndex > -1) {
          draft.roles[roleIndex].documentTypes[documentTypeIndex].inProgress[index] = {
            name: progressAction.payload.name,
            progress: progressAction.payload.progress,
            typeId: progressAction.payload.typeId,
          };
        }
        break;
      case DocumentsAction.UPLOAD_READY:
        const uploadReadyAction = action;
        if (uploadReadyAction.payload.typeId) {
          let roleIndex = -1;
          let documentTypeIndex = -1;
          for (let i = 0; i < draft.roles.length; i++) {
            for (let j = 0; j < draft.roles[i].documentTypes.length; j++) {
              const type = draft.roles[i].documentTypes[j];
              if (type.typeId === uploadReadyAction.payload.typeId) {
                roleIndex = i;
                documentTypeIndex = j;
                break;
              }
            }
          }

          if (roleIndex > -1 && documentTypeIndex > -1) {
            draft.roles[roleIndex].documentTypes[documentTypeIndex].inProgress = [];
            draft.roles[roleIndex].documentTypes[documentTypeIndex].documents.push(uploadReadyAction.payload);
          }
        }
        break;
      case DocumentsAction.SHOW_SUCCESS:
        const showSuccessAction = action;
        if (showSuccessAction.payload > 0) {
          let roleIndex = -1;
          let documentTypeIndex = -1;
          for (let i = 0; i < draft.roles.length; i++) {
            for (let j = 0; j < draft.roles[i].documentTypes.length; j++) {
              const type = draft.roles[i].documentTypes[j];
              if (type.typeId === showSuccessAction.payload) {
                roleIndex = i;
                documentTypeIndex = j;
                break;
              }
            }
          }

          if (roleIndex > -1 && documentTypeIndex > -1) {
            draft.roles[roleIndex].documentTypes[documentTypeIndex].uploadSuccess = true;
          }
        }
        break;
      case DocumentsAction.UPLOAD_ERROR:
        const uploadErrorAction = action;
        if (uploadErrorAction.payload.typeId > 0) {
          let roleIndex = -1;
          let documentTypeIndex = -1;
          for (let i = 0; i < draft.roles.length; i++) {
            for (let j = 0; j < draft.roles[i].documentTypes.length; j++) {
              const type = draft.roles[i].documentTypes[j];
              if (type.typeId === uploadErrorAction.payload.typeId) {
                roleIndex = i;
                documentTypeIndex = j;
                break;
              }
            }
          }

          if (roleIndex > -1 && documentTypeIndex > -1) {
            draft.roles[roleIndex].documentTypes[documentTypeIndex].inProgress = [];
          }
        }
        break;
      case DocumentsAction.SHOW_ERROR:
        const showErrorAction = action;
        if (showErrorAction.payload.typeId > 0) {
          let roleIndex = -1;
          let documentTypeIndex = -1;
          for (let i = 0; i < draft.roles.length; i++) {
            for (let j = 0; j < draft.roles[i].documentTypes.length; j++) {
              const type = draft.roles[i].documentTypes[j];
              if (type.typeId === showErrorAction.payload.typeId) {
                roleIndex = i;
                documentTypeIndex = j;
                break;
              }
            }
          }

          if (roleIndex > -1 && documentTypeIndex > -1) {
            draft.roles[roleIndex].documentTypes[documentTypeIndex].uploadError = showErrorAction.payload.error;
          }
        }
        break;
      case DocumentsAction.RESET_STATUS:
        const resetStatusAction = action;
        if (resetStatusAction.payload > 0) {
          let roleIndex = -1;
          let documentTypeIndex = -1;
          for (let i = 0; i < draft.roles.length; i++) {
            for (let j = 0; j < draft.roles[i].documentTypes.length; j++) {
              const type = draft.roles[i].documentTypes[j];
              if (type.typeId === resetStatusAction.payload) {
                roleIndex = i;
                documentTypeIndex = j;
                break;
              }
            }
          }

          if (roleIndex > -1 && documentTypeIndex > -1) {
            draft.roles[roleIndex].documentTypes[documentTypeIndex].uploadError = undefined;
            draft.roles[roleIndex].documentTypes[documentTypeIndex].uploadSuccess = false;
          }
        }
        break;
      case DocumentsAction.GET_ROLES_RESULT:
        const rolesResultAction = action;
        draft.roles = rolesResultAction.payload;
        break;

      case DocumentsAction.DELETE_FILE_SUCCESS:
        const deleteFileSuccessAction = action;
        const fileIndicesToDelete = getFileByStreamId(draft, deleteFileSuccessAction.payload);
        if (fileIndicesToDelete) {
          draft.roles[fileIndicesToDelete.roleIndex].documentTypes[fileIndicesToDelete.typeIndex].documents.splice(
            fileIndicesToDelete.fileIndex,
            1,
          );
        }
        break;
    }
    return draft;
  });
}
