import React, { ReactElement } from 'react';
import ZoomPayoutItem, { ZoomPayoutItemProps } from '../payout-item/ZoomPayoutItem';
import { ZoomPayoutIcon } from './../../../icons/ZoomPayoutIcon';
import './ZoomPayoutInfo.scss';

export interface ZoomPayoutInfoProps {
  payoutLoan?: ZoomPayoutItemProps;
  payoutScholarship?: ZoomPayoutItemProps;
  labelDetails: string;
  title: string;
}

const ZoomPayoutInfo = (props: ZoomPayoutInfoProps): ReactElement => {
  const items: Array<ZoomPayoutItemProps> = [];

  if (props.payoutScholarship) {
    items.push(props.payoutScholarship);
  }

  if (props.payoutLoan) {
    items.push(props.payoutLoan);
  }

  const count = items.length;

  const itemElements = items.map((payout, index) => (
    <ZoomPayoutItem
      borderRight={count > 1 && index === 0}
      {...payout}
      key={index + '-' + payout.name + '-' + payout.institution + '-' + payout.amount}
    ></ZoomPayoutItem>
  ));

  return (
    <>
      <div id="payout-info-header">
        <div>
          <ZoomPayoutIcon id="payout-icon" viewBox="0 0 80 80"></ZoomPayoutIcon>
        </div>
        <div id="payout-info-title">{props.title}</div>
      </div>
      <div id="payout-info-elements">{itemElements}</div>
    </>
  );
};

export default ZoomPayoutInfo;
