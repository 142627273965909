import { ZoomDocumentsRole } from '../../containers/upload-document-group-form/UploadDocumentGroupForm';
import { ZoomFile, ZoomFileInProgress } from './../../components/forms/file-upload/ZoomFileUpload';

export enum DocumentsAction {
  UPLOAD = 'DOCUMENTS_ACTION_UPLOAD',
  PROGRESS = 'DOCUMENTS_ACTION_PROGRESS',
  UPLOAD_READY = 'DOCUMENTS_ACTION_UPLOAD_READY',
  UPLOAD_ERROR = 'DOCUMENTS_ACTION_UPLOAD_ERROR',
  GET_RESULT = 'DOCUMENTS_ACTION_GET_RESULT',
  SHOW_SUCCESS = 'DOCUMENTS_ACTION_SHOW_SUCCESS',
  SHOW_ERROR = 'DOCUMENTS_ACTION_SHOW_ERROR',
  RESET_STATUS = 'DOCUMENTS_ACTION_RESET_STATUS',
  GET_ROLES = 'DOCUMENTS_ACTION_GET_ROLES',
  GET_ROLES_RESULT = 'DOCUMENTS_ACTION_GET_ROLES_RESULT',
  DELETE_FILE = 'DOCUMENTS_ACTION_DELETE_FILE',
  DELETE_FILE_SUCCESS = 'DOCUMENTS_ACTION_DELETE_FILE_SUCCESS',
  DELETE_FILE_ERROR = 'DOCUMENTS_ACTION_DELETE_FILE_ERROR',
}

export interface DocumentsUploadAction {
  type: typeof DocumentsAction.UPLOAD;
  payload: ZoomFile;
  demandId: number;
}

export interface DocumentsProgressAction {
  type: typeof DocumentsAction.PROGRESS;
  payload: ZoomFileInProgress;
}

export interface DocumentsUploadReadyAction {
  type: typeof DocumentsAction.UPLOAD_READY;
  payload: ZoomFile;
}

export interface DocumentsUploadErrorAction {
  type: typeof DocumentsAction.UPLOAD_ERROR;
  payload: { typeId: number; error: string };
}

export interface DocumentsShowSuccessAction {
  type: typeof DocumentsAction.SHOW_SUCCESS;
  payload: number;
}

export interface DocumentsShowErrorAction {
  type: typeof DocumentsAction.SHOW_ERROR;
  payload: { typeId: number; error: string };
}

export interface DocumentsResetStatusAction {
  type: typeof DocumentsAction.RESET_STATUS;
  payload: number;
}

export interface DocumentsActionGetRoles {
  type: typeof DocumentsAction.GET_ROLES;
  demandId: number;
}

export interface DocumentsActionGetRolesResult {
  type: typeof DocumentsAction.GET_ROLES_RESULT;
  payload: Array<ZoomDocumentsRole>;
}

export interface DocumentsActionDeleteFile {
  type: typeof DocumentsAction.DELETE_FILE;
  payload: ZoomFile;
  demandId: number;
}

export interface DocumentsActionDeleteFileSuccess {
  type: typeof DocumentsAction.DELETE_FILE_SUCCESS;
  payload: string;
}

export interface DocumentsActionDeleteFileError {
  type: typeof DocumentsAction.DELETE_FILE_ERROR;
  payload: string;
  error: string;
}

export type DocumentsActionType =
  | DocumentsUploadAction
  | DocumentsUploadReadyAction
  | DocumentsUploadErrorAction
  | DocumentsProgressAction
  | DocumentsShowSuccessAction
  | DocumentsShowErrorAction
  | DocumentsResetStatusAction
  | DocumentsActionGetRoles
  | DocumentsActionGetRolesResult
  | DocumentsActionDeleteFile
  | DocumentsActionDeleteFileSuccess
  | DocumentsActionDeleteFileError;
