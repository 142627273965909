import { Breadcrumbs, Container, Link } from '@material-ui/core';
import React, { ReactElement } from 'react';
import { connect } from 'react-redux';
import { useHistory, withRouter } from 'react-router';
import { Action } from 'redux';
import ZoomToggleInput from '../../components/forms/toggle-input/ZoomToggleInput';
import { ZoomArrowLeftIcon } from '../../components/icons/ZoomArrowLeftIcon';
import { i18nLabel } from '../../store/i18n/helpers';
import { WIAAStore } from '../../store/types';
import { UserDataAction, UserDataSetNotificationSettingsAction } from '../../store/user-data/actions';
import { blue } from '../../theme';
import ZoomFooter, {
  mapStoreToFooterProps,
  ZoomFooterProps,
} from './../../components/base-page-components/footer/ZoomFooter';
import ZoomHeader, {
  mapStoreToHeaderProps,
  ZoomHeaderProps,
} from './../../components/base-page-components/header/ZoomHeader';

export interface SettingsProps {
  headerProps: ZoomHeaderProps;
  footerProps: ZoomFooterProps;
  breadcrumbLabels: Array<string>;
  labelBackArrow: string;
  labelTitle: string;
  labelNotificationRecipients: string;
  labelAllPoas: string;
  labelOnlyMe: string;
  valueAllPoas: string;
  valueOnlyMe: string;
  dossierId: number;
  getNotifications?: boolean;
  dispatch?: (action: Action) => void;
}

export const Settings = (props: SettingsProps): ReactElement => {
  const history = useHistory();

  const onChange = (newValue: string) => {
    if (props.dispatch) {
      const setSettingsAction: UserDataSetNotificationSettingsAction = {
        type: UserDataAction.SET_NOTIFICATION_SETTINGS,
        payload: newValue === 'true',
      };
      props.dispatch(setSettingsAction);
    }
  };

  return (
    <>
      <ZoomHeader {...props.headerProps} dispatch={props.dispatch}></ZoomHeader>
      <main className="main">
        <Container>
          <div id="main-page-container">
            <div className="main-content-limited">
              <Breadcrumbs separator=">" aria-label="breadcrumb">
                <Link
                  style={{ fontFamily: 'Proxima Nova Cond' }}
                  color="inherit"
                  href={'/dashboard/dossier/' + props.dossierId}
                >
                  <b>{props.breadcrumbLabels && props.breadcrumbLabels[0]}</b>
                </Link>
                <Link style={{ fontFamily: 'Proxima Nova Cond' }} color="inherit">
                  {props.breadcrumbLabels && props.breadcrumbLabels[1]}
                </Link>
              </Breadcrumbs>
              <div className="back-button-desktop" onClick={() => history.goBack()}>
                <ZoomArrowLeftIcon viewBox="0 0 80 80" fontSize="large" htmlColor={blue.main}></ZoomArrowLeftIcon>
                <span className="back-button">{props.labelBackArrow}</span>
              </div>
              <h1>{props.labelTitle}</h1>
            </div>
            <div>
              <ZoomToggleInput
                title={props.labelNotificationRecipients}
                label1={props.labelAllPoas}
                label2={props.labelOnlyMe}
                value1={props.valueAllPoas}
                value2={props.valueOnlyMe}
                value={props.getNotifications !== undefined ? props.getNotifications.toString() : undefined}
                onChange={onChange}
              ></ZoomToggleInput>
            </div>
          </div>
        </Container>
      </main>
      <ZoomFooter {...props.footerProps}></ZoomFooter>
    </>
  );
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
export function mapStateToSettingsProps(store: WIAAStore, urlParams: any): SettingsProps {
  const { dossierId } = urlParams.match.params;
  const headerProps: ZoomHeaderProps = mapStoreToHeaderProps(store, dossierId);
  const footerProps: ZoomFooterProps = mapStoreToFooterProps(store);

  return {
    headerProps: headerProps,
    footerProps: footerProps,
    dossierId: Number(dossierId),
    labelBackArrow: i18nLabel(store.translationLabels, 'Settings_Label_Back', 'zurück'),
    labelAllPoas: i18nLabel(store.translationLabels, 'Settings_Label_All_Poas', 'Benachrichtigung bei Vertretung'),
    labelOnlyMe: i18nLabel(store.translationLabels, 'Settings_Label_Only_Me', 'Nur ich'),
    labelTitle: i18nLabel(store.translationLabels, 'Settings_Title', 'Einstellungen'),
    labelNotificationRecipients: i18nLabel(
      store.translationLabels,
      'Settings_Label_Notification_Recipients',
      'Benachrichtigungen per Email/SMS erhalten',
    ),
    breadcrumbLabels: [
      i18nLabel(store.translationLabels, 'Settings_Breadcrumb_One', 'Übersicht'),
      i18nLabel(store.translationLabels, 'Settings_Breadcrumb_Two', 'Einstellungen'),
    ],
    valueAllPoas: 'true',
    valueOnlyMe: 'false',
    getNotifications: store.userData.getNotifications,
  };
}

export default withRouter(connect(mapStateToSettingsProps)(Settings));
