import { Observable, of } from 'rxjs';
import { Epic, ofType } from 'redux-observable';
import { TranslationLabelsAction, TranslationLabelsGetAllResultAction } from './actions';

import { Action } from 'redux';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { AjaxResponse } from 'rxjs/ajax';
import { customAjax } from '../../utils/ajax-wrapper';

const loadLabels = (payload: AjaxResponse): TranslationLabelsGetAllResultAction => {
  return {
    type: TranslationLabelsAction.GET_ALL_RESULT,
    payload: payload.response,
  };
};

const loadDefaultOnError = (): TranslationLabelsGetAllResultAction => {
  return {
    type: TranslationLabelsAction.GET_ALL_RESULT,
    payload: { labels: [{ key: 'DUMMY_LABEL_ON_ERROR', translation: 'NO LABELS AVAILABLE!' }], dropDowns: {} },
  };
};

export const getAllTranslationLabelsEpic: Epic<Action, Action> = (action$: Observable<Action>): Observable<Action> =>
  action$.pipe(
    ofType(TranslationLabelsAction.GET_ALL),
    mergeMap(() => {
      return customAjax({
        subRoute: `/master-data/v1/compound-batch/all-labels-and-options`,
        withCredentials: false,
        method: 'GET',
        headers: {
          'Accept-Language': 'de-CH',
        },
      }).pipe(
        map((response) => loadLabels(response)),
        catchError(() => {
          return of(loadDefaultOnError());
        }),
      );
    }),
  );
