import React, { ReactElement } from 'react';
import { blue } from './../../theme';
import { ZoomArrowDownIcon } from './ZoomArrowDownIcon';
import { ZoomArrowLeftIcon } from './ZoomArrowLeftIcon';
import { ZoomArrowRightIcon } from './ZoomArrowRightIcon';
import { ZoomArrowUpIcon } from './ZoomArrowUpIcon';
import { ZoomAttachmentIcon } from './ZoomAttachmentIcon';
import { ZoomCheckCircleIcon } from './ZoomCheckCircleIcon';
import { ZoomCheckIcon } from './ZoomCheckIcon';
import { ZoomChildIcon } from './ZoomChildIcon';
import { ZoomCrossIcon } from './ZoomCrossIcon';
import { ZoomDebtIcon } from './ZoomDebtIcon';
import { ZoomEmailIcon } from './ZoomEmailIcon';
import { ZoomGroupIcon } from './ZoomGroupIcon';
import { ZoomInfoIcon } from './ZoomInfoIcon';
import { ZoomKantonAargauLogoIcon } from './ZoomKantonAargauLogoIcon';
import { ZoomNotificationIcon } from './ZoomNotificationIcon';
import { ZoomParentIcon } from './ZoomParentIcon';
import { ZoomPartnerIcon } from './ZoomPartnerIcon';
import { ZoomPayoutIcon } from './ZoomPayoutIcon';
import { ZoomPeopleIcon } from './ZoomPeopleIcon';
import { ZoomPersonIcon } from './ZoomPersonIcon';
import { ZoomPlusIcon } from './ZoomPlusIcon';
import { ZoomQuestionIcon } from './ZoomQuestionIcon';
import { ZoomReceiptIcon } from './ZoomReceiptIcon';
import { ZoomScholarshipIcon } from './ZoomScholarshipIcon';
import { ZoomUploadIcon } from './ZoomUploadIcon';
import { ZoomUserIcon } from './ZoomUserIcon';
import { ZoomWarningIcon } from './ZoomWarningIcon';
import { ZoomPencilIcon } from './ZoomPencilIcon';

export enum ZoomIcon {
  ArrowDown,
  ArrowLeft,
  ArrowRight,
  ArrowUp,
  Attachment,
  CheckCircle,
  Check,
  Child,
  Cross,
  Debt,
  Email,
  Group,
  Info,
  KantonAargauLogo,
  Notification,
  Parent,
  Partner,
  Payout,
  People,
  Person,
  Plus,
  Question,
  Receipt,
  Scholarship,
  Upload,
  User,
  Warning,
  Pencil,
}

export const buildIcon = (icon: ZoomIcon, color?: string, scale?: number): ReactElement => {
  const htmlColor = color ? color : blue.main;
  const scaleFactor = scale ? scale : 1;
  const standardViewBox = '0 0 80 80';
  const props = { htmlColor: htmlColor, transform: `scale(${scaleFactor})`, viewBox: standardViewBox };
  switch (icon) {
    case ZoomIcon.ArrowDown:
      return <ZoomArrowDownIcon {...props}></ZoomArrowDownIcon>;
    case ZoomIcon.ArrowLeft:
      return <ZoomArrowLeftIcon {...props}></ZoomArrowLeftIcon>;
    case ZoomIcon.ArrowRight:
      return <ZoomArrowRightIcon {...props}></ZoomArrowRightIcon>;
    case ZoomIcon.ArrowUp:
      return <ZoomArrowUpIcon {...props}></ZoomArrowUpIcon>;
    case ZoomIcon.Attachment:
      return <ZoomAttachmentIcon {...props}></ZoomAttachmentIcon>;
    case ZoomIcon.CheckCircle:
      return <ZoomCheckCircleIcon {...props}></ZoomCheckCircleIcon>;
    case ZoomIcon.Check:
      return <ZoomCheckIcon {...props}></ZoomCheckIcon>;
    case ZoomIcon.Child:
      return <ZoomChildIcon {...props}></ZoomChildIcon>;
    case ZoomIcon.Cross:
      return <ZoomCrossIcon {...props}></ZoomCrossIcon>;
    case ZoomIcon.Debt:
      return <ZoomDebtIcon {...props}></ZoomDebtIcon>;
    case ZoomIcon.Email:
      return <ZoomEmailIcon {...props}></ZoomEmailIcon>;
    case ZoomIcon.Group:
      return <ZoomGroupIcon {...props}></ZoomGroupIcon>;
    case ZoomIcon.Info:
      return <ZoomInfoIcon {...props}></ZoomInfoIcon>;
    case ZoomIcon.KantonAargauLogo:
      return <ZoomKantonAargauLogoIcon {...props}></ZoomKantonAargauLogoIcon>;
    case ZoomIcon.Notification:
      return <ZoomNotificationIcon {...props}></ZoomNotificationIcon>;
    case ZoomIcon.Parent:
      return <ZoomParentIcon {...props}></ZoomParentIcon>;
    case ZoomIcon.Partner:
      return <ZoomPartnerIcon {...props}></ZoomPartnerIcon>;
    case ZoomIcon.Payout:
      return <ZoomPayoutIcon {...props}></ZoomPayoutIcon>;
    case ZoomIcon.People:
      return <ZoomPeopleIcon {...props}></ZoomPeopleIcon>;
    case ZoomIcon.Person:
      return <ZoomPersonIcon {...props}></ZoomPersonIcon>;
    case ZoomIcon.Plus:
      return <ZoomPlusIcon {...props}></ZoomPlusIcon>;
    case ZoomIcon.Question:
      return <ZoomQuestionIcon {...props}></ZoomQuestionIcon>;
    case ZoomIcon.Receipt:
      return <ZoomReceiptIcon {...props}></ZoomReceiptIcon>;
    case ZoomIcon.Scholarship:
      return <ZoomScholarshipIcon {...props}></ZoomScholarshipIcon>;
    case ZoomIcon.Upload:
      return <ZoomUploadIcon {...props}></ZoomUploadIcon>;
    case ZoomIcon.User:
      return <ZoomUserIcon {...props}></ZoomUserIcon>;
    case ZoomIcon.Warning:
      return <ZoomWarningIcon {...props}></ZoomWarningIcon>;
    case ZoomIcon.Pencil:
      return <ZoomPencilIcon {...props}></ZoomPencilIcon>;
    default:
      return <ZoomQuestionIcon {...props}></ZoomQuestionIcon>;
  }
};
