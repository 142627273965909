import { Observable, of } from 'rxjs';
import { Epic, ofType } from 'redux-observable';
import {
  OutgoingDataGetResultAction,
  OutgoingDataSubmitAction,
  OutgoingDataResultAction,
  OutgoingDataAction,
  OutgoingDataGetAction,
} from './actions';

import { Action } from 'redux';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { AjaxResponse } from 'rxjs/ajax';
import { handleRequestFailureInEpic } from '../../utils/error-handling';
import { customAjax } from '../../utils/ajax-wrapper';

const loadOutgoing = (payload: AjaxResponse): OutgoingDataGetResultAction => {
  return {
    type: OutgoingDataAction.GET_RESULT,
    payload: { ...payload.response },
  };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const loadOutgoingOnError = (error: any): any => {
  if (error.status == 404) {
    return {
      type: OutgoingDataAction.NON_EXISTENT_OUTGOING,
    };
  }

  return handleRequestFailureInEpic(error);
};

const getSubmitResult = (payload: AjaxResponse): OutgoingDataResultAction => {
  return {
    type: OutgoingDataAction.RESULT,
    payload: { ...payload.response },
  };
};

export const getOutgoingDataEpic: Epic<Action, Action> = (action$: Observable<Action>): Observable<Action> =>
  action$.pipe(
    ofType(OutgoingDataAction.GET),
    mergeMap((action) => {
      const demandId = (action as OutgoingDataGetAction).demandId;

      return customAjax({
        subRoute: `/dossier/v1/demands/${demandId}/outgoings`,
        withCredentials: true,
        method: 'GET',
      }).pipe(
        map((response) => loadOutgoing(response)),
        catchError((error) => {
          return of(loadOutgoingOnError(error));
        }),
      );
    }),
  );

export const editOutgoingDataEpic: Epic<Action, Action> = (action$: Observable<Action>): Observable<Action> =>
  action$.pipe(
    ofType(OutgoingDataAction.SUBMIT),
    mergeMap((action) => {
      const outgoingData = (action as OutgoingDataSubmitAction).payload;
      const demandId = (action as OutgoingDataSubmitAction).demandId;

      return customAjax({
        subRoute: `/dossier/v1/demands/${demandId}/outgoings`,
        withCredentials: true,
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(outgoingData),
      }).pipe(
        map((response) => getSubmitResult(response)),
        catchError((error) => {
          return of(handleRequestFailureInEpic(error));
        }),
      );
    }),
  );
