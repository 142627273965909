export enum RoutingAction {
  REDIRECT_TO = 'ROUTING_ACTION_REDIRECT_TO',
  ERROR = 'ROUTING_ERROR',
}

export interface RoutingRedirectToAction {
  type: typeof RoutingAction.REDIRECT_TO;
  payload: string;
}

export interface RoutingErrorAction {
  type: typeof RoutingAction.ERROR;
}

export type RoutingActionType = RoutingRedirectToAction | RoutingErrorAction;
