import { Breadcrumbs, Button, Card, CardContent, CircularProgress, Container, Grid, Link } from '@material-ui/core';
import moment from 'moment';
import React, { ChangeEvent, ReactElement, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';
import { Action } from 'redux';
import ZoomFooter, {
  mapStoreToFooterProps,
  ZoomFooterProps,
} from '../../components/base-page-components/footer/ZoomFooter';
import ZoomHeader, {
  mapStoreToHeaderProps,
  ZoomHeaderProps,
} from '../../components/base-page-components/header/ZoomHeader';
import ZoomDatePicker from '../../components/forms/date-picker/ZoomDatePicker';
import ZoomMultipleTextInput from '../../components/forms/multiple-text-input/ZoomMultipleTextInput';
import ZoomSelectInput from '../../components/forms/select-input/ZoomSelectInput';
import { ZoomArrowLeftIcon } from '../../components/icons/ZoomArrowLeftIcon';
import { i18nLabel } from '../../store/i18n/helpers';
import { EditAddressDataPageAction, EditAddressDataPageGetAction } from '../../store/my-data-address/actions';
import { EditAddressDataPage } from '../../store/my-data-address/types';
import { WIAAStore } from '../../store/types';
import { setTimeToMiddleOfDay } from '../../utils/date-manipulation';
import { postCodeValidation, requiredValidation } from '../../utils/validators';
import { blue } from './../../theme';
import './EditAddressData.scss';

export interface EditAddressDataProps {
  labelTitle?: string;
  street?: string;
  streetNumber?: string;
  postCode?: string;
  city?: string;
  country?: string;
  validFrom?: Date;
  labelStreet?: string;
  labelStreetNumber?: string;
  labelPostCode?: string;
  labelCity?: string;
  labelCountry?: string;
  labelValidFrom?: string;
  helpTextCountry?: string;
  labelBackArrow?: string;
  labelCancel?: string;
  labelSave?: string;
  breadcrumbLabels?: Array<string>;
  errorLabels?: { [key: string]: string };
  headerProps: ZoomHeaderProps;
  footerProps: ZoomFooterProps;
  dossierId: number;
  countryOptions?: { name: string; value: string }[];
  isLoading?: boolean;
  dispatch?: (action: Action) => void;
}

interface EditAddressDataErrors {
  street?: string;
  streetNumber?: string;
  postCode?: string;
  city?: string;
  country?: string;
  validFrom?: string;
}

export const EditAddressData = (props: EditAddressDataProps): ReactElement => {
  const [street, setStreet] = useState(props.street ? props.street : '');
  const [streetNumber, setStreetNumber] = useState(props.streetNumber ? props.streetNumber : '');
  const [postCode, setPostCode] = useState(props.postCode ? props.postCode : '');
  const [city, setCity] = useState(props.city ? props.city : '');
  const [country, setCountry] = useState(props.country ? props.country : '8100');
  const [validFrom, setValidFrom] = useState(props.validFrom ? props.validFrom : undefined);
  const history = useHistory();
  const initialErrors: EditAddressDataErrors = {};
  const [errors, setErrors] = useState(initialErrors);

  useEffect(() => {
    if (props.dispatch) {
      const getUserEditAddressData: EditAddressDataPageGetAction = {
        type: EditAddressDataPageAction.GET,
        dossierId: props.dossierId,
      };
      props.dispatch(getUserEditAddressData);
    }
  }, []);

  useEffect(() => {
    setStreet(props.street ? props.street : '');
    setStreetNumber(props.streetNumber ? props.streetNumber : '');
    setPostCode(props.postCode ? props.postCode : '');
    setCity(props.city ? props.city : '');
    setCountry(props.country ? props.country : '8100');
    setValidFrom(props.validFrom ? props.validFrom : undefined);
  }, [props]);

  const onChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    switch (e.target.name) {
      case 'street':
        setStreet(e.target.value);
        setErrors({
          ...errors,
          street: requiredValidation(e.target.value, props.errorLabels ? props.errorLabels : {}),
        });
        break;
      case 'streetNumber':
        setStreetNumber(e.target.value);
        setErrors({
          ...errors,
          streetNumber: requiredValidation(e.target.value, props.errorLabels ? props.errorLabels : {}),
        });
        break;
      case 'postCode':
        const fieldValue = e.target.value;
        if (fieldValue !== postCode) {
          setPostCode(fieldValue);
          const postalCodeError = postCodeValidation(fieldValue, props.errorLabels ? props.errorLabels : {});
          setErrors({ ...errors, postCode: postalCodeError });
          if (props.dispatch && !!!postalCodeError) {
            const oldAddressData = getEditAddressData();
            const postalCodesAction = {
              type: EditAddressDataPageAction.GET_POSTAL_CODES,
              payload: { ...oldAddressData, zipCode: fieldValue },
            };
            props.dispatch(postalCodesAction);
          }
        }
        break;
      case 'city':
        setCity(e.target.value);
        setErrors({ ...errors, city: requiredValidation(e.target.value, props.errorLabels ? props.errorLabels : {}) });
        break;
    }
  };

  const onMultipleTextFieldChange = (index: number, event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    onChange(event);
  };

  const onCountryChange = (newValue: string) => {
    setCountry(newValue);
  };

  const onChangeDate = (newDate: Date | null) => {
    if (null === newDate) {
      setValidFrom(undefined);
    } else {
      setValidFrom(newDate);
    }
    setErrors({
      ...errors,
      validFrom: newDate === null ? requiredValidation('', props.errorLabels ? props.errorLabels : {}) : '',
    });
  };

  const getEditAddressData = (): EditAddressDataPage => {
    return {
      street: street,
      houseNumber: streetNumber,
      zipCode: postCode,
      town: city,
      countryIdType: parseInt(country),
      validFrom: setTimeToMiddleOfDay(validFrom),
    };
  };

  const getFormErrors = (): EditAddressDataErrors => {
    const errors: EditAddressDataErrors = {};
    errors.street = requiredValidation(street, props.errorLabels ? props.errorLabels : {});
    errors.streetNumber = requiredValidation(streetNumber, props.errorLabels ? props.errorLabels : {});
    errors.postCode = requiredValidation(postCode, props.errorLabels ? props.errorLabels : {});
    errors.city = requiredValidation(city, props.errorLabels ? props.errorLabels : {});
    errors.country = requiredValidation(country, props.errorLabels ? props.errorLabels : {});
    errors.validFrom = requiredValidation(
      validFrom ? moment(validFrom).format('DD.MM.YYYY') : '',
      props.errorLabels ? props.errorLabels : {},
    );
    return errors;
  };

  const validateForm = (): boolean => {
    const newErrors = getFormErrors();
    setErrors(newErrors);
    const errorsPresent = Object.values(newErrors).some((value) => value !== '');
    return !errorsPresent;
  };

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (validateForm() && props.dispatch) {
      const submitAction = {
        type: EditAddressDataPageAction.SUBMIT,
        payload: getEditAddressData(),
        dossierId: props.dossierId,
      };
      props.dispatch(submitAction);
    }
  };

  return (
    <>
      <ZoomHeader {...props.headerProps} dispatch={props.dispatch}></ZoomHeader>
      <main className="main">
        {props.isLoading ? (
          <div style={{ textAlign: 'center' }}>
            <CircularProgress></CircularProgress>
          </div>
        ) : (
          <>
            <Container>
              <div id="main-page-container">
                <Breadcrumbs separator=">" aria-label="breadcrumb">
                  <Link
                    style={{ fontFamily: 'Proxima Nova Cond' }}
                    color="inherit"
                    href={'/dashboard/dossier/' + props.dossierId}
                  >
                    <>{props.breadcrumbLabels && props.breadcrumbLabels[0]}</>
                  </Link>
                  <Link
                    style={{ fontFamily: 'Proxima Nova Cond' }}
                    color="inherit"
                    href={'/my-data/dossier-intranet/mydata/' + props.dossierId}
                  >
                    <>{props.breadcrumbLabels && props.breadcrumbLabels[1]}</>
                  </Link>
                  <Link style={{ fontFamily: 'Proxima Nova Cond' }} color="inherit">
                    <b>{props.breadcrumbLabels && props.breadcrumbLabels[2]}</b>
                  </Link>
                </Breadcrumbs>
                <div className="main-page-info">
                  <div className="back-button-desktop" onClick={() => history.goBack()}>
                    <ZoomArrowLeftIcon viewBox="0 0 80 80" fontSize="large" htmlColor={blue.main}></ZoomArrowLeftIcon>
                    {props.labelBackArrow}
                  </div>
                  <h1 className="title-desktop">{props.labelTitle}</h1>
                </div>
                <Card>
                  <CardContent>
                    <form onSubmit={onSubmit}>
                      <div className="form-element-container">
                        <ZoomMultipleTextInput
                          data={[
                            {
                              label: props.labelStreet ? props.labelStreet : '',
                              value: street,
                              gridSize: 9,
                              name: 'street',
                              error: !!errors.street,
                              errorMessage: errors.street,
                              onChange: onMultipleTextFieldChange,
                            },
                            {
                              label: props.labelStreetNumber ? props.labelStreetNumber : '',
                              value: streetNumber,
                              gridSize: 3,
                              name: 'streetNumber',
                              error: !!errors.streetNumber,
                              errorMessage: errors.streetNumber,
                              onChange: onMultipleTextFieldChange,
                            },
                          ]}
                        ></ZoomMultipleTextInput>
                      </div>
                      <div className="form-element-container">
                        <ZoomMultipleTextInput
                          data={[
                            {
                              label: props.labelPostCode ? props.labelPostCode : '',
                              value: postCode,
                              gridSize: 3,
                              name: 'postCode',
                              error: !!errors.postCode,
                              errorMessage: errors.postCode,
                              onChange: onMultipleTextFieldChange,
                            },
                            {
                              label: props.labelCity ? props.labelCity : '',
                              value: city,
                              gridSize: 9,
                              name: 'city',
                              error: !!errors.city,
                              errorMessage: errors.city,
                              onChange: onMultipleTextFieldChange,
                            },
                          ]}
                        ></ZoomMultipleTextInput>
                      </div>
                      <div className="form-element-container">
                        <ZoomSelectInput
                          label={props.labelCountry ? props.labelCountry : ''}
                          options={props.countryOptions ? props.countryOptions : []}
                          value={country}
                          name="country"
                          onChange={onCountryChange}
                          helpText={props.helpTextCountry}
                        ></ZoomSelectInput>
                      </div>
                      <div className="form-element-container">
                        <ZoomDatePicker
                          label={props.labelValidFrom ? props.labelValidFrom : ''}
                          onChange={onChangeDate}
                          name="validFrom"
                          value={validFrom}
                          error={!!errors.validFrom}
                          errorMessage={errors.validFrom}
                        ></ZoomDatePicker>
                      </div>
                      <Grid container direction="row" spacing={2} style={{ paddingBottom: '1rem' }}>
                        <Grid item xs={6} sm={6}>
                          <div className="form-actions-container-cancel">
                            <Button
                              color="primary"
                              size="large"
                              type="reset"
                              variant="outlined"
                              id="cancel-edit-address-data"
                              style={{ width: '144px' }}
                              onClick={() => history.goBack()}
                            >
                              {props.labelCancel}
                            </Button>
                          </div>
                        </Grid>
                        <Grid item xs={6} sm={6}>
                          <div className="form-actions-container-save">
                            <Button
                              color="primary"
                              size="large"
                              type="submit"
                              variant="contained"
                              id="save-edit-address-data"
                              style={{ width: '144px' }}
                            >
                              {props.labelSave}
                            </Button>
                          </div>
                        </Grid>
                      </Grid>
                    </form>
                  </CardContent>
                </Card>
              </div>
            </Container>
          </>
        )}
      </main>
      <ZoomFooter {...props.footerProps}></ZoomFooter>
    </>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export function mapStateToProps(store: WIAAStore, ownProps: any): EditAddressDataProps {
  const { dossierId } = ownProps.match.params;
  const headerProps: ZoomHeaderProps = mapStoreToHeaderProps(store, dossierId);
  const footerProps: ZoomFooterProps = mapStoreToFooterProps(store);

  return {
    headerProps,
    footerProps,
    labelTitle: i18nLabel(store.translationLabels, 'Edit_Address_Data_Title', 'Kontaktdaten bearbeiten'),
    labelStreet: i18nLabel(store.translationLabels, 'Edit_Address_Data_Street', 'Adresse'),
    labelStreetNumber: i18nLabel(store.translationLabels, 'Edit_Address_Data_Street_Number', 'Nr.*'),
    labelPostCode: i18nLabel(store.translationLabels, 'Edit_Address_Data_Post_Code', 'PLZ'),
    labelCity: i18nLabel(store.translationLabels, 'Edit_Address_Data_City', 'Ort*'),
    labelCountry: i18nLabel(store.translationLabels, 'Edit_Address_Data_Country', 'Land*'),
    labelValidFrom: i18nLabel(store.translationLabels, 'Edit_Address_Data_Valid_From', 'Gültig ab*'),
    labelBackArrow: i18nLabel(store.translationLabels, 'Edit_Address_Data_Back_Arrow', 'Zurück'),
    labelCancel: i18nLabel(store.translationLabels, 'Edit_Address_Data_Cancel', 'Abbrechen'),
    labelSave: i18nLabel(store.translationLabels, 'Edit_Address_Data_Save', 'Speichern'),
    helpTextCountry: i18nLabel(
      store.translationLabels,
      'Edit_Address_Data_Help_Text_Country',
      'Wohnsitzstaat der Person in Ausbildung',
    ),
    breadcrumbLabels: [
      i18nLabel(store.translationLabels, 'Edit_Address_Data_Breadcrumb_Label_1', 'Übersicht'),
      i18nLabel(store.translationLabels, 'Edit_Address_Data_Breadcrumb_Label_2', 'Meine Daten'),
      i18nLabel(store.translationLabels, 'Edit_Address_Data_Breadcrumb_Label_3', 'Kontaktdaten bearbeiten'),
    ],
    errorLabels: store.translationLabels,
    dossierId: dossierId,
    street: store.editAddressDataPage.street,
    streetNumber: store.editAddressDataPage.houseNumber,
    postCode: store.editAddressDataPage.zipCode ? store.editAddressDataPage.zipCode.toString() : '',
    city: store.editAddressDataPage.town,
    country: store.editAddressDataPage.countryIdType ? store.editAddressDataPage.countryIdType.toString() : '',
    validFrom: store.editAddressDataPage.validFrom,
    isLoading: store.editAddressDataPage.isLoading,
    countryOptions: store.translationOptions.nationalities
      ? [
          ...store.translationOptions.nationalities.map((option) => {
            return { value: option.id.toString(), name: option.name };
          }),
        ]
      : [],
  };
}

export default withRouter(connect(mapStateToProps)(EditAddressData));
