import { ErrorData } from './types';

export enum ErrorHandlingAction {
  RAISE_ERROR = 'RAISE_ERROR',
  RAISE_REQUEST_ERROR = 'RAISE_REQUEST_ERROR',
}

export interface ErrorHandlingRaiseErrorAction {
  type: typeof ErrorHandlingAction.RAISE_ERROR;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload: any;
}

export interface ErrorHandlingRaiseRequestErrorAction {
  type: typeof ErrorHandlingAction.RAISE_REQUEST_ERROR;
  payload: ErrorData;
}

export type ErrorHandlingActionType = ErrorHandlingRaiseErrorAction | ErrorHandlingRaiseRequestErrorAction;
