import {
  Breadcrumbs,
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Container,
  Grid,
  Link,
  Typography,
} from '@material-ui/core';
import React, { ReactElement, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Action } from 'redux';
import ZoomFooter, {
  mapStoreToFooterProps,
  ZoomFooterProps,
} from '../../components/base-page-components/footer/ZoomFooter';
import ZoomHeader, {
  mapStoreToHeaderProps,
  ZoomHeaderProps,
} from '../../components/base-page-components/header/ZoomHeader';
import { ZoomArrowLeftIcon } from '../../components/icons/ZoomArrowLeftIcon';
import { ZoomPersonIcon } from '../../components/icons/ZoomPersonIcon';
import { i18nLabel } from '../../store/i18n/helpers';
import { RoutingAction, RoutingRedirectToAction } from '../../store/routing/actions';
import { WIAAStore } from '../../store/types';
import { HolderDataAction } from './../../store/holder-data/actions';
import { black, blue, darkBlue } from './../../theme';
import './ScholarshipHolder.scss';

export interface Holder {
  dossierId: number;
  firstName?: string;
  lastName?: string;
  svn?: string;
  active?: boolean;
  roleId?: number;
  birthDate?: Date;
  email?: string;
  unreadNotifications?: boolean;
}

export interface ScholarshipHolderProps extends Holder {
  labelTitle?: string;
  labelDescription?: string;
  labelCurrentChoice?: string;
  labelCurrentChoice2?: string;
  labelChangeChoice?: string;
  labelBackArrow?: string;
  breadcrumbLabels?: Array<string>;
  holders?: Array<Holder>;
  headerProps: ZoomHeaderProps;
  footerProps: ZoomFooterProps;
  isLoading?: boolean;
  dispatch?: (action: Action) => void;
}

export const ScholarshipHolder = (props: ScholarshipHolderProps): ReactElement => {
  const scholarshipHoldersData = props.holders ? props.holders : [];
  const history = useHistory();
  const onClick = (index: number) => {
    if (props.dispatch) {
      const redirectAction: RoutingRedirectToAction = {
        type: RoutingAction.REDIRECT_TO,
        payload: '/dashboard/dossier/' + scholarshipHoldersData[index].dossierId,
      };
      props.dispatch(redirectAction);
    }
  };

  useEffect(() => {
    if (props.dispatch) {
      props.dispatch({ type: HolderDataAction.GET });
    }
  }, []);

  return (
    <>
      <ZoomHeader {...props.headerProps} dispatch={props.dispatch}></ZoomHeader>
      <main className="main">
        {props.isLoading ? (
          <div style={{ textAlign: 'center' }}>
            <CircularProgress></CircularProgress>
          </div>
        ) : (
          <>
            <Container>
              <div id="main-page-container">
                <Breadcrumbs separator=">" aria-label="breadcrumb">
                  <Link
                    style={{ fontFamily: 'Proxima Nova Cond' }}
                    color="inherit"
                    href={'/dashboard/dossier/' + props.dossierId}
                  >
                    {props.breadcrumbLabels && props.breadcrumbLabels[0]}
                  </Link>
                  <Link style={{ fontFamily: 'Proxima Nova Cond' }} color="inherit" href="/holder">
                    <b>{props.breadcrumbLabels && props.breadcrumbLabels[1]}</b>
                  </Link>
                </Breadcrumbs>
                <div className="main-page-info">
                  <div className="back-button-desktop" onClick={() => history.goBack()}>
                    <ZoomArrowLeftIcon viewBox="0 0 80 80" fontSize="large" htmlColor={blue.main}></ZoomArrowLeftIcon>
                    {props.labelBackArrow}
                  </div>
                  <div className="top-content">
                    <h1 className="title-desktop">{props.labelTitle}</h1>
                    <p>{props.labelDescription}</p>
                  </div>
                </div>
                <Grid container spacing={2} style={{ paddingBottom: '1rem' }}>
                  {scholarshipHoldersData.map((holder, index) => (
                    <Grid item sm={4} md={3} key={index}>
                      <Card
                        className={holder.dossierId === props.dossierId ? 'selected-border' : 'non-selected-border'}
                      >
                        <CardContent className="card-content-desktop">
                          <Typography
                            className={holder.dossierId === props.dossierId ? 'selected-name' : 'non-selected-name'}
                            gutterBottom
                          >
                            {holder.firstName + ' ' + holder.lastName}
                          </Typography>
                          <ZoomPersonIcon
                            className={
                              holder.dossierId === props.dossierId ? 'person-icon-desktop-selected' : 'person-icon'
                            }
                            viewBox="0 0 80 80"
                            fontSize="large"
                            htmlColor={holder.dossierId === props.dossierId ? darkBlue.main : black.main}
                          ></ZoomPersonIcon>
                        </CardContent>
                        <CardActions className="card-actions-desktop">
                          {holder.dossierId === props.dossierId ? (
                            <Typography className="active-btn-desktop" gutterBottom>
                              <b>
                                <i>{props.labelCurrentChoice}</i>
                              </b>
                            </Typography>
                          ) : (
                            <Button
                              className="change-choice-btn-mobile"
                              variant="outlined"
                              color="primary"
                              style={{ width: '80%' }}
                              onClick={() => onClick(index)}
                            >
                              {props.labelChangeChoice}
                            </Button>
                          )}
                        </CardActions>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              </div>
            </Container>
          </>
        )}
      </main>
      <ZoomFooter {...props.footerProps}></ZoomFooter>
    </>
  );
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
export function mapStateToPropsHolder(store: WIAAStore, urlParams: any): ScholarshipHolderProps {
  const { dossierId } = urlParams.match.params;
  const headerProps: ZoomHeaderProps = mapStoreToHeaderProps(store, dossierId);
  const footerProps: ZoomFooterProps = mapStoreToFooterProps(store);

  return {
    headerProps: headerProps,
    footerProps: footerProps,
    labelTitle: i18nLabel(store.translationLabels, 'Scholarship_Holder_Title', 'Stipendiat wählen'),
    labelDescription: i18nLabel(
      store.translationLabels,
      'Scholarship_Holder_Description',
      'Sie besitzen Vollmacht auf folgende Stipendiaten:',
    ),
    labelCurrentChoice: i18nLabel(store.translationLabels, 'Scholarship_Holder_Current_Choice', 'Aktuell gewählt'),
    labelCurrentChoice2: i18nLabel(store.translationLabels, 'Scholarship_Holder_Current_Choice_2', 'aktiv'),
    labelChangeChoice: i18nLabel(store.translationLabels, 'Scholarship_Holder_Change_Choice', 'Wechseln'),
    labelBackArrow: i18nLabel(store.translationLabels, 'Scholarship_Holder_Back_Arrow', 'Zurück'),
    breadcrumbLabels: [
      i18nLabel(store.translationLabels, 'Scholarship_Holder_Breadcrumb_Label_1', 'Übersicht'),
      i18nLabel(store.translationLabels, 'Scholarship_Holder_Breadcrumb_Label_2', 'Stipendiat wählen'),
    ],
    holders: store.holderData.holders,
    dossierId: Number(dossierId),
    isLoading: store.holderData.isLoading,
  };
}

export default connect(mapStateToPropsHolder)(ScholarshipHolder);
