/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, useEffect, useState } from 'react';
import { black, blue, red } from '../../../theme';
import { ZoomUploadIcon } from '../../icons/ZoomUploadIcon';
import './ZoomFileUpload.scss';
import { ZoomCrossIcon } from '../../icons/ZoomCrossIcon';
import { ZoomCheckIcon } from '../../icons/ZoomCheckIcon';
import IconButton from '@material-ui/core/IconButton';
import LinearProgress from '@material-ui/core/LinearProgress';
import {
  NotificationAction,
  NotificationDocumentsActionDeleteFile,
  NotificationDocumentsUploadAction,
  NotificationTypes,
} from '../../../store/notifications/actions';
import { ZoomFile, ZoomFileInProgress } from './ZoomFileUpload';

export interface ZoomCaseFileUploadProps {
  caseId?: number;
  typeId: number;
  labelDragDocument: string;
  labelOr: string;
  labelChooseDocument: string;
  labelSuccess: string;
  files: Array<ZoomFile>;
  filesInProgress: Array<ZoomFileInProgress>;
  dossierId: number;
  uploadSuccessful?: boolean;
  uploadError?: string;
  isNewTopic?: boolean;
  dispatch?: (action: NotificationTypes) => void;
}

export const ZoomCaseFileUpload = (props: ZoomCaseFileUploadProps): ReactElement => {
  const [isDropAreaActive, setIsDropAreaActive] = useState(false);
  const [files, setFiles] = useState(props.files ? props.files : []);
  const filesInProgress = props.filesInProgress ? props.filesInProgress : [];

  useEffect(() => {
    setFiles(props.files ? props.files : []);
  }, [props.files]);

  const onDeleteFile = (f: ZoomFile, d: number) => {
    if (props.dispatch) {
      const deleteFileAction: NotificationDocumentsActionDeleteFile = {
        type: NotificationAction.DELETE_FILE,
        payload: f,
        dossierId: d,
        caseId: props.caseId ? props.caseId : 0,
      };
      props.dispatch(deleteFileAction);
    }
  };

  const getFileHtml = (f: ZoomFile, d: number) => {
    const downloadLink = `${process.env.REACT_APP_API_GATEWAY_URL}/dossier-intranet/v1/documents/${props.dossierId}?streamId=${f.streamId}&caseId=${props.caseId}`;
    return (
      <div key={f.id + f.streamId} className="single-file-container">
        <IconButton onClick={() => onDeleteFile(f, d)}>
          <ZoomCrossIcon viewBox="0 0 80 80" htmlColor={black.main} fontSize="small" style={{ fontSize: '0.8rem' }} />
        </IconButton>
        <a href={downloadLink} download className="zoom-download-file">
          {f.fileName}
        </a>
      </div>
    );
  };

  const getFileInProgressHtml = (f: ZoomFileInProgress) => {
    return (
      <div key={f.name}>
        <LinearProgress variant="determinate" value={f.progress} />
        <p className="file-in-upload">{f.name}</p>
      </div>
    );
  };

  const onDropFile = (event: any) => {
    setIsDropAreaActive(false);

    if (event.dataTransfer.files) {
      addFiles(event.dataTransfer.files);
    }
    event.preventDefault();
  };

  const onDragOver = (event: any) => {
    setIsDropAreaActive(true);
    event.preventDefault();
  };

  const onDragEnter = () => {
    setIsDropAreaActive(true);
  };

  const onDragLeave = () => {
    setIsDropAreaActive(false);
  };

  const onDragEnd = (event: any) => {
    setIsDropAreaActive(false);
    event.preventDefault();
  };

  const addFiles = (fileList: FileList) => {
    /* start upload for only one file */
    if (fileList.length === 0) {
      return;
    }
    const originalFile = fileList[0];
    const { size, type, name } = originalFile;
    const zoomFile = {
      fileName: name,
      size,
      type,
      id: `ID-${name}`,
      originalFile,
      typeId: props.typeId,
    };
    if (props.dispatch) {
      const uploadAction: NotificationDocumentsUploadAction = {
        type: NotificationAction.UPLOAD,
        payload: zoomFile,
        dossierId: props.dossierId,
        caseId: props.caseId,
        typeId: props.typeId,
      };
      props.dispatch(uploadAction);
    }
  };

  const dropAreaClassName = isDropAreaActive ? 'zoom-input-file-container on' : 'zoom-input-file-container';

  const filesToRender = files.map((f) => getFileHtml(f, props.dossierId));

  const onFileChange = (event: any) => {
    const fileInput = event.target as HTMLInputElement;
    if (fileInput.files) {
      addFiles(fileInput.files);
    }
  };

  const filesInProgressToRender = filesInProgress.map((f) => getFileInProgressHtml(f));

  const getUploadForm = () => {
    return (
      <>
        <input
          type="file"
          className="zoom-input-file"
          name={'type' + props.typeId}
          id={'type' + props.typeId}
          onChange={onFileChange}
        />
        <label htmlFor={'type' + props.typeId}>
          <div
            className={dropAreaClassName}
            onDrop={onDropFile}
            onDragLeave={onDragLeave}
            onDragEnter={onDragEnter}
            onDragOver={onDragOver}
            onDragEnd={onDragEnd}
          >
            <ZoomUploadIcon viewBox="0 0 80 80" htmlColor={blue.main} fontSize="large" style={{ fontSize: '4em' }} />
            <div className="zoom-input-file-text">
              <p style={{ fontWeight: 'bolder' }}>{props.labelDragDocument}</p>
              <p>{props.labelOr}</p>
              <p className="upload-link">{props.labelChooseDocument}</p>
            </div>
          </div>
        </label>
      </>
    );
  };

  const getUploadSuccess = () => {
    return (
      <div className="zoom-upload-success-container">
        <ZoomCheckIcon viewBox="0 0 80 80" htmlColor={blue.main} fontSize="large" />
        <div className="zoom-input-file-text">
          <p>{props.labelSuccess}</p>
        </div>
      </div>
    );
  };

  const getUploadError = () => {
    return (
      <div className="zoom-upload-success-container">
        <ZoomCrossIcon viewBox="0 0 80 80" htmlColor={red.main} fontSize="large" />
        <div className="zoom-input-file-text">
          <p style={{ color: red.main }}>{props.uploadError}</p>
        </div>
      </div>
    );
  };

  const getFilesBeingUploaded = () => {
    return (
      <div className="zoom-uploading-file-container">
        <div className="files-in-progress-container">{filesInProgressToRender}</div>
      </div>
    );
  };

  const getMainContent = () => {
    if (props.uploadSuccessful) {
      return getUploadSuccess();
    }
    if (props.uploadError) {
      return getUploadError();
    }
    if (filesInProgressToRender.length > 0) {
      return getFilesBeingUploaded();
    }
    return getUploadForm();
  };

  const mainContent = getMainContent();

  return (
    <div>
      {mainContent}
      <div className="files-container">{filesToRender}</div>
    </div>
  );
};
