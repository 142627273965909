import { Breadcrumbs, CircularProgress, Container, Grid, Link } from '@material-ui/core';
import moment from 'moment';
import React, { ReactElement, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Action } from 'redux';
import { HolderDropDownProps } from '../../components/base-page-components/holder-dropdown/HolderDropDown';
import { HolderDataAction, HolderDataGetAction } from '../../store/holder-data/actions';
import { i18nLabel } from '../../store/i18n/helpers';
import {
  NotificationAction,
  NotificationGetSingleMessageAction,
  NotificationMarkAsReadAction,
} from '../../store/notifications/actions';
import { WIAAStore } from '../../store/types';
import { NotificationCaseType } from '../../utils/form-data';
import { mapStoreToHolderDropDownProps } from '../../utils/mappers';
import ZoomFooter, {
  mapStoreToFooterProps,
  ZoomFooterProps,
} from './../../components/base-page-components/footer/ZoomFooter';
import ZoomHeader, {
  mapStoreToHeaderProps,
  ZoomHeaderProps,
} from './../../components/base-page-components/header/ZoomHeader';
import Conversation, { ConversationProps } from './../../components/messages/Conversation';
import { NavigationItems } from '../../components/base-page-components/navigation/Navigation';
import { ERROR_EVENT_ID } from '../../utils/unread-notifications';

export interface SingleConversationProps {
  newMessageLabel: string;
  conversation?: ConversationProps;
  headerProps: ZoomHeaderProps;
  footerProps: ZoomFooterProps;
  breadcrumbLabels: Array<string>;
  labelBackArrow: string;
  supportNumberLabel: string;
  replyLabel: string;
  labelSendMessage: string;
  labelDragDocument: string;
  labelOr: string;
  labelChooseDocument: string;
  labelSuccess: string;
  dossierId: number;
  caseId: number;
  holderDropDownProps: HolderDropDownProps;
  isLoading?: boolean;
  dispatch?: (action: Action) => void;
}

export const SingleConversation = (props: SingleConversationProps): ReactElement => {
  useEffect(() => {
    if (props.dispatch) {
      const getCase: NotificationGetSingleMessageAction = {
        type: NotificationAction.GET_SINGLE_MESSAGE,
        dossierId: props.dossierId,
        caseId: props.caseId,
      };
      props.dispatch(getCase);
    }
  }, []);

  useEffect(() => {
    if (props.dispatch && props.conversation) {
      const messagesToMarkAsRead = props.conversation.messages
        .filter((message) => message.eventTypeId !== ERROR_EVENT_ID)
        .map((message) => message.clientEventId);
      const markAsReadAction: NotificationMarkAsReadAction = {
        type: NotificationAction.MARK_AS_READ,
        dossierId: props.dossierId,
        caseId: props.caseId,
        clientEventIds: messagesToMarkAsRead,
      };
      if (markAsReadAction.clientEventIds.length > 0) {
        props.dispatch(markAsReadAction);
      }
    }
  }, [props.conversation?.caseId]);

  useEffect(() => {
    if (props.dispatch && props.dossierId) {
      const getHolderData: HolderDataGetAction = {
        type: HolderDataAction.GET,
      };
      props.dispatch(getHolderData);
    }
  }, []);

  return (
    <>
      <ZoomHeader {...props.headerProps} dispatch={props.dispatch} />
      <main className="main">
        {props.isLoading ? (
          <div style={{ textAlign: 'center' }}>
            <CircularProgress />
          </div>
        ) : (
          <>
            <Container>
              <div id="main-page-container">
                <div className="main-content-limited">
                  <Breadcrumbs separator=">" aria-label="breadcrumb">
                    <Link
                      style={{ fontFamily: 'Proxima Nova Cond' }}
                      color="inherit"
                      href={'/dashboard/dossier/' + props.dossierId}
                    >
                      <b>{props.breadcrumbLabels && props.breadcrumbLabels[0]}</b>
                    </Link>
                    <Link
                      style={{ fontFamily: 'Proxima Nova Cond' }}
                      color="inherit"
                      href={'/messages/' + props.dossierId}
                    >
                      {props.breadcrumbLabels && props.breadcrumbLabels[1]}
                    </Link>
                    <Link style={{ fontFamily: 'Proxima Nova Cond' }} color="inherit">
                      {props.breadcrumbLabels && props.breadcrumbLabels[2]}
                    </Link>
                  </Breadcrumbs>
                  <NavigationItems
                    labelBackArrow={props.labelBackArrow}
                    holderDropDownProps={props.holderDropDownProps}
                    dispatch={props.dispatch}
                    mainPageTopInfo={false}
                  />
                </div>
                <div className="main-content-limited">
                  {props.conversation && <Conversation {...{ ...props.conversation, dispatch: props.dispatch }} />}

                  {props.conversation === undefined && (
                    <Grid container justify="center">
                      <CircularProgress />
                    </Grid>
                  )}
                </div>
              </div>
            </Container>
          </>
        )}
      </main>
      <ZoomFooter {...props.footerProps} />
    </>
  );
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
export const mapStateToSingleConversationProps = (store: WIAAStore, urlProps: any): SingleConversationProps => {
  const { dossierId, caseId } = urlProps.match.params;
  const headerProps: ZoomHeaderProps = mapStoreToHeaderProps(store, dossierId);
  const footerProps: ZoomFooterProps = mapStoreToFooterProps(store);
  const conversation = store.notificationData.freeTextMessages.find(
    (chatConversation) => chatConversation.caseId == caseId,
  );

  const labelsOnly: SingleConversationProps = {
    dossierId: parseInt(dossierId),
    caseId: parseInt(caseId),
    footerProps,
    headerProps,
    holderDropDownProps: mapStoreToHolderDropDownProps(store, parseInt(dossierId)),
    breadcrumbLabels: ['Übersicht', 'Mitteilungen', conversation ? conversation.title : ''],
    labelChooseDocument: i18nLabel(store.translationLabels, 'Documents_Choose_Document', 'Datei auswählen'),
    labelOr: i18nLabel(store.translationLabels, 'Documents_Or_Label', 'oder'),
    labelDragDocument: i18nLabel(store.translationLabels, 'Documents_Drag_Document', 'Dokument hierhin ziehen'),
    labelSuccess: i18nLabel(store.translationLabels, 'Documents_Success', 'Dokument erfolgreich hochgeladen'),
    labelBackArrow: i18nLabel(store.translationLabels, 'Conversations_Back_Arrow', 'zurück'),
    newMessageLabel: i18nLabel(store.translationLabels, 'Conversations_New_Message', 'Neue Nachricht'),
    supportNumberLabel: i18nLabel(store.translationLabels, 'Converstaions_Support_Number', 'Support-Nr.:'),
    labelSendMessage: i18nLabel(store.translationLabels, 'Conversations_Send_Message', 'Senden'),
    replyLabel: i18nLabel(store.translationLabels, 'Conversations_Reply', 'Antwort'),
    isLoading: store.notificationData.isLoading,
  };

  if (conversation === undefined) {
    return labelsOnly;
  }

  let docType = store.notificationData.uploadedFiles.find((docType) => docType.caseId === parseInt(caseId));

  if (!docType && conversation.typeId === NotificationCaseType.FORMATIONFINALISATIONCHECK) {
    docType = store.notificationData.uploadedFiles.find(
      (doc) => doc.typeId === NotificationCaseType.FORMATIONFINALISATIONCHECK,
    );
  }

  const messages = conversation.messages.map((clientEvent) => {
    return {
      caseId: conversation.caseId,
      date: moment(clientEvent.date).format('DD.MM.YYYY'),
      text: clientEvent.body ? clientEvent.body : '',
      inbox: !clientEvent.portalUser,
      dossierId,
      clientEventId: clientEvent.id ? clientEvent.id : -1,
      eventTypeId: clientEvent.caseStep?.eventType?.id,
      files: clientEvent.documents.map((clientEventDocumentDto) => {
        return {
          streamId: clientEventDocumentDto.streamId,
          fileName: clientEventDocumentDto.fileName,
          id: 'UNKNOWN',
          type: 'UNKNWON',
          size: 0,
          clientEventId: clientEventDocumentDto.clientEventId,
        };
      }),
    };
  });

  const conversationProps: ConversationProps = {
    ...conversation,
    isOpened: true,
    supportNumber: conversation.caseId.toString(),
    date: moment(conversation.date).format('DD.MM.YYYY'),
    messages: messages,
    typeId: conversation.typeId,
    stepId: conversation.stepId,
    replyProps: {
      message: '',
      typeId: 1000,
      stepId: conversation.stepId,
      files: docType ? docType.documents : [],
      filesInProgress: docType ? docType.inProgress : [],
      uploadError: docType?.uploadError,
      uploadSuccessful: docType?.uploadSuccess,
      ...labelsOnly,
    },
    ...labelsOnly,
  };

  return { ...labelsOnly, conversation: conversationProps };
};

export default withRouter(connect(mapStateToSingleConversationProps)(SingleConversation));
