import { Button } from '@material-ui/core';
import React, { ChangeEvent, ReactElement, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ZoomDatePicker from '../../components/forms/date-picker/ZoomDatePicker';
import ZoomTextInput from '../../components/forms/text-input/ZoomTextInput';
import ZoomToggleInput from '../../components/forms/toggle-input/ZoomToggleInput';
import { i18nLabel } from '../../store/i18n/helpers';
import { PartnerDataAction, PartnerDataActionType } from '../../store/partner-data/actions';
import { PartnerData } from '../../store/partner-data/types';
import { WIAAStore } from '../../store/types';
import { setTimeToMiddleOfDay } from '../../utils/date-manipulation';
import { ahvn13validation, requiredValidation } from '../../utils/validators';
import { maxValueValidation } from './../../utils/validators';
import './PartnerDataForm.scss';

export interface PartnerDataFormProps {
  firstName?: string;
  lastName?: string;
  ahvn13?: string;
  dateOfBirth?: Date;
  inFormation?: string;
  formationType?: string;
  formationFullTime?: string;
  inWorkActivity?: string;
  worktimeQuota?: string;
  salaryNetPartner?: string;
  workExpensesPartner?: string;
  pensionReceived?: string;
  pensionAmount?: string;
  additionalAssistanceReceived?: string;
  additionalAssistanceAmount?: string;
  fortunePartner?: string;
  pifId: number;
  labelFirstName?: string;
  labelLastName?: string;
  labelAhvn13?: string;
  labelDateOfBirth?: string;
  labelInFormation?: string;
  labelFormationType?: string;
  labelFormationFullTime?: string;
  labelInWorkActivity?: string;
  labelWorktimeQuota?: string;
  labelSalaryNetPartner?: string;
  labelWorkExpensesPartner?: string;
  labelPensionReceived?: string;
  labelPensionAmount?: string;
  labelAdditionalAssistanceReceived?: string;
  labelAdditionalAssistanceAmount?: string;
  labelFortunePartner?: string;
  labelSave?: string;
  helpTextAhvn13?: string;
  helpTextAdditionalAssistanceAmount?: string;
  educationWorkloadTypes?: { name: string; value: string }[];
  toggleButtonsTypes?: { name: string; value: string }[];
  errorLabels?: { [key: string]: string };
  dispatch?: (action: PartnerDataActionType) => void;
}

interface PartnerDataErrors {
  id?: string;
  firstName?: string;
  lastName?: string;
  ahvn13?: string;
  dateOfBirth?: string;
  inFormation?: string;
  formationType?: string;
  formationFullTime?: string;
  inWorkActivity?: string;
  worktimeQuota?: string;
  salaryNetPartner?: string;
  workExpensesPartner?: string;
  pensionReceived?: string;
  pensionAmount?: string;
  additionalAssistanceReceived?: string;
  additionalAssistanceAmount?: string;
  fortunePartner?: string;
}

export const PartnerDataForm = (props: PartnerDataFormProps): ReactElement => {
  const [firstName, setFirstName] = useState(props.firstName ? props.firstName : '');
  const [lastName, setLastName] = useState(props.lastName ? props.lastName : '');
  const [ahvn13, setAhvn13] = useState(props.ahvn13 ? props.ahvn13 : '');
  const [dateOfBirth, setDateOfBirth] = useState(props.dateOfBirth ? props.dateOfBirth : undefined);
  const [inFormation, setInFormation] = useState(props.inFormation ? props.inFormation : '2');
  const [formationType, setFormationType] = useState(props.formationType ? props.formationType : '');
  const [formationFullTime, setFormationFullTime] = useState(props.formationFullTime ? props.formationFullTime : '0');
  const [inWorkActivity, setInWorkActivity] = useState(props.inWorkActivity ? props.inWorkActivity : '1');
  const [worktimeQuota, setWorktimeQuota] = useState(props.worktimeQuota ? props.worktimeQuota : '');
  const [salaryNetPartner, setSalaryNetPartner] = useState(props.salaryNetPartner ? props.salaryNetPartner : '');
  const [workExpensesPartner, setWorkExpensesPartner] = useState(
    props.workExpensesPartner ? props.workExpensesPartner : '',
  );
  const [pensionReceived, setPensionReceived] = useState(props.pensionReceived ? props.pensionReceived : '2');
  const [pensionAmount, setPensionAmount] = useState(props.pensionAmount ? props.pensionAmount : '');
  const [additionalAssistanceReceived, setAdditionalAssistanceReceived] = useState(
    props.additionalAssistanceReceived ? props.additionalAssistanceReceived : '2',
  );
  const [additionalAssistanceAmount, setAdditionalAssistanceAmount] = useState(
    props.additionalAssistanceAmount ? props.additionalAssistanceAmount : '',
  );
  const [fortunePartner, setFortunePartner] = useState(props.fortunePartner ? props.fortunePartner : '');
  const initialErrors: PartnerDataErrors = {};
  const [errors, setErrors] = useState(initialErrors);

  useEffect(() => {
    setFirstName(props.firstName ? props.firstName : '');
    setLastName(props.lastName ? props.lastName : '');
    setAhvn13(props.ahvn13 ? props.ahvn13 : '');
    setDateOfBirth(props.dateOfBirth ? props.dateOfBirth : undefined);
    setInFormation(props.inFormation ? props.inFormation : '2');
    setFormationType(props.formationType ? props.formationType : '');
    setFormationFullTime(props.formationFullTime ? props.formationFullTime : '0');
    setInWorkActivity(props.inWorkActivity ? props.inWorkActivity : '1');
    setWorktimeQuota(props.worktimeQuota ? props.worktimeQuota : '');
    setSalaryNetPartner(props.salaryNetPartner ? props.salaryNetPartner : '');
    setWorkExpensesPartner(props.workExpensesPartner ? props.workExpensesPartner : '');
    setPensionReceived(props.pensionReceived ? props.pensionReceived : '2');
    setPensionAmount(props.pensionAmount ? props.pensionAmount : '');
    setAdditionalAssistanceReceived(props.additionalAssistanceReceived ? props.additionalAssistanceReceived : '2');
    setAdditionalAssistanceAmount(props.additionalAssistanceAmount ? props.additionalAssistanceAmount : '');
    setFortunePartner(props.fortunePartner ? props.fortunePartner : '');
  }, [props]);

  const onChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    switch (e.target.name) {
      case 'firstName':
        setFirstName(e.target.value);
        setErrors({
          ...errors,
          firstName: requiredValidation(e.target.value, props.errorLabels ? props.errorLabels : {}),
        });
        break;
      case 'lastName':
        setLastName(e.target.value);
        setErrors({
          ...errors,
          lastName: requiredValidation(e.target.value, props.errorLabels ? props.errorLabels : {}),
        });
        break;
      case 'ahvn13':
        setAhvn13(e.target.value);
        setErrors({ ...errors, ahvn13: ahvn13validation(e.target.value, props.errorLabels ? props.errorLabels : {}) });
        break;
      case 'formationType':
        setFormationType(e.target.value);
        setErrors({
          ...errors,
          formationType: requiredValidation(e.target.value, props.errorLabels ? props.errorLabels : {}),
        });
        break;
      case 'worktimeQuota':
        setWorktimeQuota(e.target.value);
        setErrors({
          ...errors,
          worktimeQuota: maxValueValidation(e.target.value, 100, props.errorLabels ? props.errorLabels : {}),
        });
        break;
      case 'salaryNetPartner':
        setSalaryNetPartner(e.target.value);
        setErrors({
          ...errors,
          salaryNetPartner: maxValueValidation(e.target.value, 9999999, props.errorLabels ? props.errorLabels : {}),
        });
        break;
      case 'workExpensesPartner':
        setWorkExpensesPartner(e.target.value);
        setErrors({
          ...errors,
          workExpensesPartner: maxValueValidation(e.target.value, 9999999, props.errorLabels ? props.errorLabels : {}),
        });
        break;
      case 'pensionAmount':
        setPensionAmount(e.target.value);
        setErrors({
          ...errors,
          pensionAmount: maxValueValidation(e.target.value, 9999999, props.errorLabels ? props.errorLabels : {}),
        });
        break;
      case 'additionalAssistanceAmount':
        setAdditionalAssistanceAmount(e.target.value);
        setErrors({
          ...errors,
          additionalAssistanceAmount: maxValueValidation(
            e.target.value,
            9999999,
            props.errorLabels ? props.errorLabels : {},
          ),
        });
        break;
      case 'fortunePartner':
        setFortunePartner(e.target.value);
        setErrors({
          ...errors,
          fortunePartner: maxValueValidation(e.target.value, 9999999, props.errorLabels ? props.errorLabels : {}),
        });
        break;
    }
  };

  const onChangeDate = (newDate: Date | null) => {
    if (null === newDate) {
      setDateOfBirth(undefined);
    } else {
      setDateOfBirth(newDate);
    }
    setErrors({
      ...errors,
      dateOfBirth: newDate === null ? requiredValidation('', props.errorLabels ? props.errorLabels : {}) : '',
    });
  };

  const onClickInFormation = (newValue: string) => {
    setInFormation(newValue);
    setErrors({
      ...errors,
      inFormation: requiredValidation(newValue, props.errorLabels ? props.errorLabels : {}),
    });
  };

  const onClickFormationFullTime = (newValue: string) => {
    setFormationFullTime(newValue);
    setErrors({
      ...errors,
      formationFullTime: requiredValidation(newValue, props.errorLabels ? props.errorLabels : {}),
    });
  };

  const onClickInWorkActivity = (newValue: string) => {
    setInWorkActivity(newValue);
    setErrors({
      ...errors,
      inWorkActivity: requiredValidation(newValue, props.errorLabels ? props.errorLabels : {}),
    });
  };
  const onClickPensionReceived = (newValue: string) => {
    setPensionReceived(newValue);
    setErrors({
      ...errors,
      pensionReceived: requiredValidation(newValue, props.errorLabels ? props.errorLabels : {}),
    });
  };

  const onClickAdditionalAssistanceReceived = (newValue: string) => {
    setAdditionalAssistanceReceived(newValue);
    setErrors({
      ...errors,
      additionalAssistanceReceived: requiredValidation(newValue, props.errorLabels ? props.errorLabels : {}),
    });
  };

  const normalizedDateOfBirth = setTimeToMiddleOfDay(dateOfBirth);
  const getPartnerData = (): PartnerData => {
    return {
      firstName: firstName,
      lastName: lastName,
      ahvNumber: ahvn13,
      dateOfBirth: normalizedDateOfBirth ? normalizedDateOfBirth.toISOString() : '',
      inFormation: inFormation === '1' ? true : false,
      formationType: formationType ? formationType : '',
      formationFullTime: formationFullTime,
      inWorkActivity: inWorkActivity === '1' ? true : false,
      workTimeQuota: worktimeQuota ? Number(worktimeQuota) : undefined,
      salaryNetPartner: salaryNetPartner ? Number(salaryNetPartner) : undefined,
      workExpensePartner: workExpensesPartner ? Number(workExpensesPartner) : undefined,
      pensionReceived: pensionReceived === '1' ? true : false,
      pensionAmount: pensionAmount ? parseInt(pensionAmount) : undefined,
      additionalAssistanceReceived: additionalAssistanceReceived === '1' ? true : false,
      additionalAssistanceAmount: additionalAssistanceAmount ? Number(additionalAssistanceAmount) : undefined,
      //titleId: 0, //missing in FE
      fortunePartner: fortunePartner ? Number(fortunePartner) : undefined,
    };
  };

  const getFormErrors = (): PartnerDataErrors => {
    const errors: PartnerDataErrors = {};
    errors.firstName = requiredValidation(firstName, props.errorLabels ? props.errorLabels : {});
    errors.lastName = requiredValidation(lastName, props.errorLabels ? props.errorLabels : {});
    errors.ahvn13 = ahvn13validation(ahvn13, props.errorLabels ? props.errorLabels : {});
    errors.dateOfBirth = requiredValidation(
      dateOfBirth ? dateOfBirth.toDateString() : '',
      props.errorLabels ? props.errorLabels : {},
    );
    errors.inFormation = requiredValidation(inFormation, props.errorLabels ? props.errorLabels : {});
    if (inFormation === '1') {
      errors.formationType = requiredValidation(formationType, props.errorLabels ? props.errorLabels : {});
      errors.formationFullTime = requiredValidation(formationFullTime, props.errorLabels ? props.errorLabels : {});
    }
    errors.inWorkActivity = requiredValidation(inWorkActivity, props.errorLabels ? props.errorLabels : {});
    if (inWorkActivity === '1') {
      errors.worktimeQuota = maxValueValidation(worktimeQuota, 100, props.errorLabels ? props.errorLabels : {});
      errors.salaryNetPartner = maxValueValidation(
        salaryNetPartner,
        9999999,
        props.errorLabels ? props.errorLabels : {},
      );
      errors.workExpensesPartner = maxValueValidation(
        salaryNetPartner,
        9999999,
        props.errorLabels ? props.errorLabels : {},
      );
    }
    errors.pensionReceived = requiredValidation(pensionReceived, props.errorLabels ? props.errorLabels : {});
    if (pensionReceived === '1') {
      errors.pensionAmount = maxValueValidation(pensionAmount, 9999999, props.errorLabels ? props.errorLabels : {});
    }
    errors.additionalAssistanceReceived = requiredValidation(
      additionalAssistanceReceived,
      props.errorLabels ? props.errorLabels : {},
    );
    if (additionalAssistanceReceived === '1') {
      errors.additionalAssistanceAmount = maxValueValidation(
        additionalAssistanceAmount,
        9999999,
        props.errorLabels ? props.errorLabels : {},
      );
    }
    errors.fortunePartner = requiredValidation(fortunePartner, props.errorLabels ? props.errorLabels : {});
    return errors;
  };

  const validateForm = (): boolean => {
    const newErrors = getFormErrors();
    setErrors(newErrors);
    const errorsPresent = Object.values(newErrors).some((value) => value !== '');
    return !errorsPresent;
  };

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    if (validateForm() && props.dispatch) {
      props.dispatch({
        type: PartnerDataAction.SUBMIT,
        payload: getPartnerData(),
        pifId: props.pifId,
      });
    }
    event.preventDefault();
  };

  return (
    <>
      <form onSubmit={onSubmit}>
        <div className="form-element-container">
          <ZoomTextInput
            label={props.labelFirstName ? props.labelFirstName : ''}
            name="firstName"
            value={firstName}
            onChange={onChange}
            error={!!errors.firstName}
            errorMessage={errors.firstName}
          ></ZoomTextInput>
        </div>
        <div className="form-element-container">
          <ZoomTextInput
            label={props.labelLastName ? props.labelLastName : ''}
            name="lastName"
            value={lastName}
            onChange={onChange}
            error={!!errors.lastName}
            errorMessage={errors.lastName}
          ></ZoomTextInput>
        </div>
        <div className="form-element-container">
          <ZoomTextInput
            label={props.labelAhvn13 ? props.labelAhvn13 : ''}
            value={ahvn13}
            name="ahvn13"
            onChange={onChange}
            helpText={props.helpTextAhvn13}
            error={!!errors.ahvn13}
            errorMessage={errors.ahvn13}
          ></ZoomTextInput>
        </div>
        <div className="form-element-container">
          <ZoomDatePicker
            label={props.labelDateOfBirth ? props.labelDateOfBirth : ''}
            onChange={onChangeDate}
            name="dateOfBirth"
            value={dateOfBirth}
            error={!!errors.dateOfBirth}
            errorMessage={errors.dateOfBirth}
          ></ZoomDatePicker>
        </div>
        <div className="form-element-container">
          <ZoomToggleInput
            value={inFormation}
            title={props.labelInFormation ? props.labelInFormation : ''}
            label1={props.toggleButtonsTypes ? props.toggleButtonsTypes[0].name : ''}
            value1={props.toggleButtonsTypes ? props.toggleButtonsTypes[0].value.toString() : ''}
            label2={props.toggleButtonsTypes ? props.toggleButtonsTypes[1].name : ''}
            value2={props.toggleButtonsTypes ? props.toggleButtonsTypes[1].value.toString() : ''}
            onChange={onClickInFormation}
          ></ZoomToggleInput>
        </div>
        {inFormation === '1' && (
          <div className="form-element-container">
            <ZoomTextInput
              label={props.labelFormationType ? props.labelFormationType : ''}
              value={formationType}
              name="formationType"
              onChange={onChange}
              error={!!errors.formationType}
              errorMessage={errors.formationType}
            ></ZoomTextInput>
          </div>
        )}
        {inFormation === '1' && (
          <div className="form-element-container">
            <ZoomToggleInput
              value={formationFullTime}
              title={props.labelFormationFullTime ? props.labelFormationFullTime : ''}
              label1={props.educationWorkloadTypes ? props.educationWorkloadTypes[0].name : ''}
              value1={props.educationWorkloadTypes ? props.educationWorkloadTypes[0].value.toString() : ''}
              label2={props.educationWorkloadTypes ? props.educationWorkloadTypes[1].name : ''}
              value2={props.educationWorkloadTypes ? props.educationWorkloadTypes[1].value.toString() : ''}
              onChange={onClickFormationFullTime}
            ></ZoomToggleInput>
          </div>
        )}
        <div className="form-element-container">
          <ZoomToggleInput
            value={inWorkActivity}
            title={props.labelInWorkActivity ? props.labelInWorkActivity : ''}
            label1={props.toggleButtonsTypes ? props.toggleButtonsTypes[0].name : ''}
            value1={props.toggleButtonsTypes ? props.toggleButtonsTypes[0].value.toString() : ''}
            label2={props.toggleButtonsTypes ? props.toggleButtonsTypes[1].name : ''}
            value2={props.toggleButtonsTypes ? props.toggleButtonsTypes[1].value.toString() : ''}
            onChange={onClickInWorkActivity}
          ></ZoomToggleInput>
        </div>
        {inWorkActivity === '1' && (
          <div className="form-element-container">
            <ZoomTextInput
              label={props.labelWorktimeQuota ? props.labelWorktimeQuota : ''}
              name="worktimeQuota"
              value={worktimeQuota}
              onChange={onChange}
              error={!!errors.worktimeQuota}
              errorMessage={errors.worktimeQuota}
            ></ZoomTextInput>
          </div>
        )}
        {inWorkActivity === '1' && (
          <div className="form-element-container">
            <ZoomTextInput
              label={props.labelSalaryNetPartner ? props.labelSalaryNetPartner : ''}
              name="salaryNetPartner"
              value={salaryNetPartner}
              onChange={onChange}
              error={!!errors.salaryNetPartner}
              errorMessage={errors.salaryNetPartner}
            ></ZoomTextInput>
          </div>
        )}
        {inWorkActivity === '1' && (
          <div className="form-element-container">
            <ZoomTextInput
              label={props.labelWorkExpensesPartner ? props.labelWorkExpensesPartner : ''}
              name="workExpensesPartner"
              value={workExpensesPartner}
              onChange={onChange}
              error={!!errors.workExpensesPartner}
              errorMessage={errors.workExpensesPartner}
            ></ZoomTextInput>
          </div>
        )}
        <div className="form-element-container">
          <ZoomToggleInput
            value={pensionReceived}
            title={props.labelPensionReceived ? props.labelPensionReceived : ''}
            label1={props.toggleButtonsTypes ? props.toggleButtonsTypes[0].name : ''}
            value1={props.toggleButtonsTypes ? props.toggleButtonsTypes[0].value.toString() : ''}
            label2={props.toggleButtonsTypes ? props.toggleButtonsTypes[1].name : ''}
            value2={props.toggleButtonsTypes ? props.toggleButtonsTypes[1].value.toString() : ''}
            onChange={onClickPensionReceived}
          ></ZoomToggleInput>
        </div>
        {pensionReceived === '1' && (
          <div className="form-element-container">
            <ZoomTextInput
              label={props.labelPensionAmount ? props.labelPensionAmount : ''}
              name="pensionAmount"
              value={pensionAmount}
              onChange={onChange}
              error={!!errors.pensionAmount}
              errorMessage={errors.pensionAmount}
            ></ZoomTextInput>
          </div>
        )}
        <div className="form-element-container">
          <ZoomToggleInput
            value={additionalAssistanceReceived}
            title={props.labelAdditionalAssistanceReceived ? props.labelAdditionalAssistanceReceived : ''}
            label1={props.toggleButtonsTypes ? props.toggleButtonsTypes[0].name : ''}
            value1={props.toggleButtonsTypes ? props.toggleButtonsTypes[0].value.toString() : ''}
            label2={props.toggleButtonsTypes ? props.toggleButtonsTypes[1].name : ''}
            value2={props.toggleButtonsTypes ? props.toggleButtonsTypes[1].value.toString() : ''}
            onChange={onClickAdditionalAssistanceReceived}
          ></ZoomToggleInput>
        </div>
        {additionalAssistanceReceived === '1' && (
          <div className="form-element-container">
            <ZoomTextInput
              label={props.labelAdditionalAssistanceAmount ? props.labelAdditionalAssistanceAmount : ''}
              name="additionalAssistanceAmount"
              value={additionalAssistanceAmount}
              onChange={onChange}
              helpText={props.helpTextAdditionalAssistanceAmount}
              error={!!errors.additionalAssistanceAmount}
              errorMessage={errors.additionalAssistanceAmount}
            ></ZoomTextInput>
          </div>
        )}
        <div className="form-element-container">
          <ZoomTextInput
            label={props.labelFortunePartner ? props.labelFortunePartner : ''}
            name="fortunePartner"
            value={fortunePartner}
            onChange={onChange}
            error={!!errors.fortunePartner}
            errorMessage={errors.fortunePartner}
          ></ZoomTextInput>
        </div>
        <div className="form-actions-container">
          <Button color="primary" type="submit" variant="contained" id="submit-partner-data">
            {props.labelSave}
          </Button>
        </div>
      </form>
    </>
  );
};

// Map global store to component parameters
// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export function mapStateToProps(store: WIAAStore, ownProps: any): PartnerDataFormProps {
  const { pifId } = ownProps.match.params;
  const props: PartnerDataFormProps = {
    labelFirstName: i18nLabel(store.translationLabels, 'Partner_First_Name', 'Vorname*'),
    labelLastName: i18nLabel(store.translationLabels, 'Partner_Last_Name', 'Nachname*'),
    labelAhvn13: i18nLabel(store.translationLabels, 'Partner_Ahvn', 'AHV-Nummer*'),
    labelDateOfBirth: i18nLabel(store.translationLabels, 'Partner_Date_Of_Birth', 'Geburtsdatum*'),
    labelInFormation: i18nLabel(
      store.translationLabels,
      'Partner_In_Formation',
      'Absolviert Ihr/e Partner/in eine Ausbildung?*',
    ),
    labelFormationType: i18nLabel(store.translationLabels, 'Partner_Formation_Type', 'Art der Ausbildung*'),
    labelFormationFullTime: i18nLabel(
      store.translationLabels,
      'Partner_Formation_Full_Time',
      'Zu welchem Pensum macht Ihr/e Partner/in die Ausbildung?*',
    ),
    labelInWorkActivity: i18nLabel(
      store.translationLabels,
      'Partner_In_Work_Activity',
      'Ist Ihr/e Partner/in erwerbstätig?*',
    ),
    labelWorktimeQuota: i18nLabel(store.translationLabels, 'Partner_Worktime_Quota', 'Arbeitspensum (%)*'),
    labelSalaryNetPartner: i18nLabel(
      store.translationLabels,
      'Partner_Salary_Net_Partner',
      'Nettolohn Partner/in pro Jahr*',
    ),
    labelWorkExpensesPartner: i18nLabel(
      store.translationLabels,
      'Partner_Work_Expenses_Partner',
      'Berufsauslagen Partner/in pro Jahr*',
    ),
    labelPensionReceived: i18nLabel(
      store.translationLabels,
      'Partner_Pension_Received',
      'Erhält Ihr/e Partner/in Renten?*',
    ),
    labelPensionAmount: i18nLabel(store.translationLabels, 'Partner_Pension_Amount', 'Betrag pro Jahr*'),
    labelAdditionalAssistanceReceived: i18nLabel(
      store.translationLabels,
      'Partner_Additional_Assistance_Received',
      'Erhält Ihr/e Partner/in Ergänzungsleistungen?*',
    ),
    labelAdditionalAssistanceAmount: i18nLabel(
      store.translationLabels,
      'Partner_Additional_Assistance_Amount',
      'Betrag pro Jahr*',
    ),
    labelFortunePartner: i18nLabel(store.translationLabels, 'Partner_Fortune_Partner', 'Vermögen Partner/in*'),
    labelSave: i18nLabel(store.translationLabels, 'Partner_Save', 'Speichern'),
    helpTextAhvn13: i18nLabel(
      store.translationLabels,
      'Partner_Ahvn_Help_Text',
      'Die 13-stellige AHV-Nr. ist auf der Versicherungskarte der Krankenkasse oder auf dem Sozialversicherungs-Ausweis ersichtlich. Sollte Ihnen diese Nummer nicht bekannt sein, geben Sie bitte 756.0000.0000.00 ein.',
    ),
    helpTextAdditionalAssistanceAmount: i18nLabel(
      store.translationLabels,
      'Partner_Additional_Assistance_Amount_Help_Text',
      'Ergänzungsleistungen werden in der Regel nur zur IV-Rente oder zur AHV-Rente ausgerichtet.',
    ),
    errorLabels: store.translationLabels,
    pifId: Number(pifId),
    toggleButtonsTypes: store.translationOptions['toggle-buttons']
      ? [
          ...store.translationOptions['toggle-buttons'].map((option) => {
            return { value: option.id.toString(), name: option.name };
          }),
        ]
      : [],
    educationWorkloadTypes: store.translationOptions['education-workloads']
      ? [
          ...store.translationOptions['education-workloads'].map((option) => {
            return { value: option.id.toString(), name: option.name };
          }),
        ]
      : [],
  };

  const data: PartnerData = store.partnerData;
  if (data) {
    props.firstName = data.firstName;
    props.lastName = data.lastName;
    props.ahvn13 = data.ahvNumber;
    props.dateOfBirth = data.dateOfBirth ? new Date(data.dateOfBirth) : undefined;
    props.inFormation = data.inFormation ? '1' : '2';
    props.formationType = data.formationType ? data.formationType : '';
    props.formationFullTime = data.formationFullTime;
    props.inWorkActivity = data.inWorkActivity ? '1' : '2';
    props.worktimeQuota = data.workTimeQuota ? data.workTimeQuota.toString() : '';
    props.salaryNetPartner = data.salaryNetPartner ? data.salaryNetPartner.toString() : '';
    props.workExpensesPartner = data.workExpensePartner ? data.workExpensePartner.toString() : '';
    props.pensionReceived = data.pensionReceived ? '1' : '2';
    props.pensionAmount = data.pensionAmount ? data.pensionAmount.toString() : '';
    props.additionalAssistanceReceived = data.additionalAssistanceReceived ? '1' : '2';
    props.additionalAssistanceAmount = data.additionalAssistanceAmount
      ? data.additionalAssistanceAmount.toString()
      : '';
    props.fortunePartner = data.fortunePartner ? data.fortunePartner.toString() : '';
  }
  return props;
}

export default withRouter(connect(mapStateToProps)(PartnerDataForm));
