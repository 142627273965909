import { Breadcrumbs, Button, CircularProgress, Container, Grid, Link } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import moment from 'moment';
import React, { ReactElement, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Action } from 'redux';
import ZoomFooter, {
  mapStoreToFooterProps,
  ZoomFooterProps,
} from '../../components/base-page-components/footer/ZoomFooter';
import ZoomHeader, {
  mapStoreToHeaderProps,
  ZoomHeaderProps,
} from '../../components/base-page-components/header/ZoomHeader';
import { HolderDropDownProps } from '../../components/base-page-components/holder-dropdown/HolderDropDown';
import ZoomNotification, {
  ZoomNotificationProps,
} from '../../components/base-page-components/notification/ZoomNotification';
import { HolderDataAction, HolderDataGetAction } from '../../store/holder-data/actions';
import { i18nLabel } from '../../store/i18n/helpers';
import { MyDataOverviewPageAction, MyDataOverviewPageGetAction } from '../../store/my-data/actions';
import { RoutingAction, RoutingRedirectToAction } from '../../store/routing/actions';
import { WIAAStore } from '../../store/types';
import { ahvn13Format, ibanFormat } from '../../utils/formaters';
import { mapStoreToHolderDropDownProps } from '../../utils/mappers';
import { formatUnreadNotifications } from '../../utils/unread-notifications';
import './MyDataOverview.scss';
import { NavigationItems } from '../../components/base-page-components/navigation/Navigation';

export interface MyDataPersonalDataProps {
  titlePersonalData?: string;
  mrMrsTypePersonalData?: string;
  firstNamePersonalData?: string;
  lastNamePersonalData?: string;
  ahvn13PersonalData?: string;
  dateOfBirthPersonalData?: Date;
  maritalStatusPersonalData?: string;
  nationalityPersonalData?: string;
  cityPersonalData?: string;
  notifications: Array<ZoomNotificationProps>;
  labelMrMrsTypePersonalData?: string;
  labelFirstNamePersonalData?: string;
  labelLastNamePersonalData?: string;
  labelAhvn13PersonalData?: string;
  labelDateOfBirthPersonalData?: string;
  labelMaritalStatusPersonalData?: string;
  labelNationalityPersonalData?: string;
  labelCityPersonalData?: string;
  countryOptions?: { name: string; value: string }[];
  pifMaritalStatuses?: { name: string; value: string }[];
  mrMrsTypes?: { name: string; value: string }[];
  holderDropDownProps: HolderDropDownProps;
}

export interface MyDataContactDataProps {
  titleContactData?: string;
  phoneNumberContanctData?: string;
  emailContanctData?: string;
  labelPhoneNumberContactData?: string;
  labelEmailContanctData?: string;
}

export interface MyDataAddressDataProps {
  titleAddressData?: string;
  streetAndNumberAddressData?: string;
  postCodeAddressData?: string;
  cityAddressData?: string;
  countryAddressData?: string;
  labelStreetAndNumberAddressData?: string;
  labelPostCodeAndCityAddressData?: string;
  labelCountryAddressData?: string;
}

export interface MyDataPaymentDataProps {
  titlePaymentData?: string;
  accountHolderPaymentData?: string;
  ibanPaymentData?: string;
  addressPaymentData?: string;
  labelAddressPaymentData?: string;
  labelAccountHolderPaymentData?: string;
  labelIbanPaymentData?: string;
}

export interface MyDataOverviewProps
  extends MyDataPersonalDataProps,
    MyDataContactDataProps,
    MyDataAddressDataProps,
    MyDataPaymentDataProps {
  labelTitle?: string;
  labelDescription?: string;
  labelEdit?: string;
  labelBackArrow?: string;
  breadcrumbLabels?: Array<string>;
  headerProps: ZoomHeaderProps;
  footerProps: ZoomFooterProps;
  errorLabels?: { [key: string]: string };
  dossierId: number;
  labelMarkAsRead?: string;
  labelGoToAction?: string;
  isLoading?: boolean;
  dispatch?: (action: Action) => void;
}

export const MyDataOverview = (props: MyDataOverviewProps): ReactElement => {
  const mrMrsTypePersonalData = props.mrMrsTypePersonalData ? props.mrMrsTypePersonalData : '';
  const firstNamePersonalData = props.firstNamePersonalData ? props.firstNamePersonalData : '';
  const lastNamePersonalData = props.lastNamePersonalData ? props.lastNamePersonalData : '';
  const ahvn13PersonalData = props.ahvn13PersonalData ? props.ahvn13PersonalData : '';
  const dateOfBirthPersonalData = props.dateOfBirthPersonalData ? props.dateOfBirthPersonalData : undefined;
  const maritalStatusPersonalData = props.maritalStatusPersonalData ? props.maritalStatusPersonalData : '0';
  const nationalityPersonalData = props.nationalityPersonalData ? props.nationalityPersonalData : '8100';
  const cityPersonalData = props.cityPersonalData ? props.cityPersonalData : '';
  const phoneNumberContanctData = props.phoneNumberContanctData ? props.phoneNumberContanctData : '';
  const emailContanctData = props.emailContanctData ? props.emailContanctData : '';
  const streetAndNumberAddressData = props.streetAndNumberAddressData ? props.streetAndNumberAddressData : '';
  const postCodeAddressData = props.postCodeAddressData ? props.postCodeAddressData : '';
  const cityAddressData = props.cityAddressData ? props.cityAddressData : '';
  const countryAddressData = props.countryAddressData ? props.countryAddressData : '8100';
  const ibanPaymentData = props.ibanPaymentData ? props.ibanPaymentData : '';
  const addressPaymentData = props.addressPaymentData ? props.addressPaymentData : '';

  useEffect(() => {
    if (props.dispatch) {
      const getUserMyDataOverview: MyDataOverviewPageGetAction = {
        type: MyDataOverviewPageAction.GET,
        payload: props.dossierId,
      };
      props.dispatch(getUserMyDataOverview);
    }
  }, []);

  useEffect(() => {
    if (props.dispatch && props.dossierId) {
      const getHolderData: HolderDataGetAction = {
        type: HolderDataAction.GET,
      };
      props.dispatch(getHolderData);
    }
  }, []);

  const redirectToEditPersonalData = () => {
    const redirectAction: RoutingRedirectToAction = {
      type: RoutingAction.REDIRECT_TO,
      payload: '/edit-personal-data/dossier-intranet/mydata/' + props.dossierId,
    };

    if (props.dispatch) {
      props.dispatch(redirectAction);
    }
  };

  const redirectToEditContactData = () => {
    const redirectAction: RoutingRedirectToAction = {
      type: RoutingAction.REDIRECT_TO,
      payload: '/edit-contact-data/dossier-intranet/mydata/' + props.dossierId,
    };

    if (props.dispatch) {
      props.dispatch(redirectAction);
    }
  };

  const redirectToEditAddressData = () => {
    const redirectAction: RoutingRedirectToAction = {
      type: RoutingAction.REDIRECT_TO,
      payload: '/edit-address-data/dossier-intranet/mydata/' + props.dossierId,
    };

    if (props.dispatch) {
      props.dispatch(redirectAction);
    }
  };

  const redirectToEditPaymentData = () => {
    const redirectAction: RoutingRedirectToAction = {
      type: RoutingAction.REDIRECT_TO,
      payload: '/edit-payment-data/dossier-intranet/mydata/' + props.dossierId,
    };

    if (props.dispatch) {
      props.dispatch(redirectAction);
    }
  };

  const notificationElements = props.notifications.map((notification, index) => (
    <ZoomNotification
      id={notification.id}
      labelText={notification.labelText}
      labelButtonText={props.labelMarkAsRead}
      key={index}
      dossierId={props.dossierId}
      clientEventIds={notification.clientEventIds}
      isSuccess={notification.isSuccess}
      isWarning={notification.isWarning}
      isError={notification.isError}
      dispatch={props.dispatch}
      labelGoToActionText={props.labelGoToAction}
    />
  ));

  return (
    <>
      <ZoomHeader {...props.headerProps} dispatch={props.dispatch} />
      <main className="main">
        {props.isLoading ? (
          <div style={{ textAlign: 'center' }}>
            <CircularProgress />
          </div>
        ) : (
          <>
            <Container disableGutters={window.innerWidth <= 960}>{notificationElements}</Container>
            <Container>
              <div id="main-page-container">
                <Breadcrumbs separator=">" aria-label="breadcrumb">
                  <Link
                    style={{ fontFamily: 'Proxima Nova Cond' }}
                    color="inherit"
                    href={'/dashboard/dossier/' + props.dossierId}
                  >
                    <b>{props.breadcrumbLabels && props.breadcrumbLabels[0]}</b>
                  </Link>
                  <Link style={{ fontFamily: 'Proxima Nova Cond' }} color="inherit">
                    <>{props.breadcrumbLabels && props.breadcrumbLabels[1]}</>
                  </Link>
                </Breadcrumbs>
                <NavigationItems
                  labelBackArrow={props.labelBackArrow}
                  holderDropDownProps={props.holderDropDownProps}
                  dispatch={props.dispatch}
                  title={props.labelTitle}
                  mainPageTopInfo={false}
                />
                <p>{props.labelDescription}</p>
                <Card className="info-card">
                  <CardContent>
                    <h2>{props.titlePersonalData}</h2>
                    <div className="form-element-container info-card-row">
                      <Grid container direction="row" alignItems="stretch" justify="flex-start">
                        <Grid item xs={12} sm={6}>
                          <div className="label-bottom-border">
                            <label>{props.labelMrMrsTypePersonalData ? props.labelMrMrsTypePersonalData : ''}</label>
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <div className="label-bottom-border">
                            {props.mrMrsTypes
                              ? props.mrMrsTypes.find((option) => option.value === mrMrsTypePersonalData)?.name
                              : ''}
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                    <div className="form-element-container info-card-row">
                      <Grid container direction="row" alignItems="stretch" justify="flex-start">
                        <Grid item xs={12} sm={6}>
                          <div className="label-bottom-border">
                            <label>{props.labelFirstNamePersonalData ? props.labelFirstNamePersonalData : ''}</label>
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <div className="label-bottom-border">
                            <label>{firstNamePersonalData}</label>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                    <div className="form-element-container info-card-row">
                      <Grid container direction="row" alignItems="stretch" justify="flex-start">
                        <Grid item xs={12} sm={6}>
                          <div className="label-bottom-border">
                            <label>{props.labelLastNamePersonalData ? props.labelLastNamePersonalData : ''}</label>
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <div className="label-bottom-border">
                            <label>{lastNamePersonalData}</label>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                    <div className="form-element-container info-card-row">
                      <Grid container direction="row" alignItems="stretch" justify="flex-start">
                        <Grid item xs={12} sm={6}>
                          <div className="label-bottom-border">
                            <label>{props.labelAhvn13PersonalData ? props.labelAhvn13PersonalData : ''}</label>
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <div className="label-bottom-border">
                            <label>{ahvn13Format(ahvn13PersonalData)}</label>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                    <div className="form-element-container info-card-row">
                      <Grid container direction="row" alignItems="stretch" justify="flex-start">
                        <Grid item xs={12} sm={6}>
                          <div className="label-bottom-border">
                            <label>
                              {props.labelDateOfBirthPersonalData ? props.labelDateOfBirthPersonalData : ''}
                            </label>
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <div className="label-bottom-border">
                            <label>{moment(dateOfBirthPersonalData).format('DD.MM.YYYY')}</label>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                    <div className="form-element-container info-card-row">
                      <Grid container direction="row" alignItems="stretch" justify="flex-start">
                        <Grid item xs={12} sm={6}>
                          <div className="label-bottom-border">
                            <label>
                              {props.labelMaritalStatusPersonalData ? props.labelMaritalStatusPersonalData : ''}
                            </label>
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <div className="label-bottom-border">
                            <label>
                              {props.pifMaritalStatuses
                                ? props.pifMaritalStatuses.find((option) => option.value === maritalStatusPersonalData)
                                    ?.name
                                : ''}
                            </label>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                    <div className="form-element-container info-card-row">
                      <Grid container direction="row" alignItems="stretch" justify="flex-start">
                        <Grid item xs={12} sm={6}>
                          <div className="label-bottom-border">
                            <label>
                              {props.labelNationalityPersonalData ? props.labelNationalityPersonalData : ''}
                            </label>
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <div className="label-bottom-border">
                            <label>
                              {props.countryOptions
                                ? props.countryOptions.find((option) => option.value === nationalityPersonalData)?.name
                                : ''}
                            </label>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                    <div className="form-element-container info-card-row">
                      <Grid container direction="row" alignItems="stretch" justify="flex-start">
                        <Grid item xs={12} sm={6}>
                          <div className="label-bottom-border">
                            <label>{props.labelCityPersonalData ? props.labelCityPersonalData : ''}</label>
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <div className="label-bottom-border">
                            <label>{cityPersonalData}</label>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  </CardContent>
                  <CardActions className="form-actions-container">
                    <Button
                      id="edit-personal-data"
                      color="primary"
                      variant="contained"
                      onClick={redirectToEditPersonalData}
                    >
                      {props.labelEdit}
                    </Button>
                  </CardActions>
                </Card>
                <Card className="info-card">
                  <CardContent>
                    <h2>{props.titleContactData}</h2>
                    <div className="form-element-container info-card-row">
                      <Grid container direction="row" alignItems="stretch" justify="flex-start">
                        <Grid item xs={12} sm={6}>
                          <div className="label-bottom-border">
                            <label>{props.labelPhoneNumberContactData ? props.labelPhoneNumberContactData : ''}</label>
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <div className="label-bottom-border">
                            <label>{phoneNumberContanctData}</label>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                    <div className="form-element-container info-card-row">
                      <Grid container direction="row" alignItems="stretch" justify="flex-start">
                        <Grid item xs={12} sm={6}>
                          <div className="label-bottom-border">
                            <label>{props.labelEmailContanctData ? props.labelEmailContanctData : ''}</label>
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <div className="label-bottom-border">
                            <label>{emailContanctData}</label>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  </CardContent>
                  <CardActions className="form-actions-container">
                    <Button
                      id="edit-contact-data"
                      color="primary"
                      variant="contained"
                      onClick={redirectToEditContactData}
                    >
                      {props.labelEdit}
                    </Button>
                  </CardActions>
                </Card>
                <Card className="info-card">
                  <CardContent>
                    <h2>{props.titleAddressData}</h2>
                    <div className="form-element-container info-card-row">
                      <Grid container direction="row" alignItems="stretch" justify="flex-start">
                        <Grid item xs={12} sm={6}>
                          <div className="label-bottom-border">
                            <label>
                              {props.labelStreetAndNumberAddressData ? props.labelStreetAndNumberAddressData : ''}
                            </label>
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <div className="label-bottom-border">
                            <label>{streetAndNumberAddressData}</label>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                    <div className="form-element-container info-card-row">
                      <Grid container direction="row" alignItems="stretch" justify="flex-start">
                        <Grid item xs={12} sm={6}>
                          <div className="label-bottom-border">
                            <label>
                              {props.labelPostCodeAndCityAddressData ? props.labelPostCodeAndCityAddressData : ''}
                            </label>
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <div className="label-bottom-border">
                            <label>{postCodeAddressData + ' ' + cityAddressData}</label>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                    <div className="form-element-container info-card-row">
                      <Grid container direction="row" alignItems="stretch" justify="flex-start">
                        <Grid item xs={12} sm={6}>
                          <div className="label-bottom-border">
                            <label>{props.labelCountryAddressData ? props.labelCountryAddressData : ''}</label>
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <div className="label-bottom-border">
                            <label>
                              {props.countryOptions
                                ? props.countryOptions.find((option) => option.value === countryAddressData)?.name
                                : ''}
                            </label>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  </CardContent>
                  <CardActions className="form-actions-container">
                    <Button
                      id="edit-address-data"
                      color="primary"
                      variant="contained"
                      onClick={redirectToEditAddressData}
                    >
                      {props.labelEdit}
                    </Button>
                  </CardActions>
                </Card>
                <Card className="info-card">
                  <CardContent>
                    <h2>{props.titlePaymentData}</h2>
                    <div className="form-element-container info-card-row">
                      <Grid container direction="row" alignItems="stretch" justify="flex-start">
                        <Grid item xs={12} sm={6}>
                          <div className="label-bottom-border">
                            <label>{props.labelIbanPaymentData ? props.labelIbanPaymentData : ''}</label>
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <div className="label-bottom-border">
                            <label>{ibanFormat(ibanPaymentData)}</label>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                    <div className="form-element-container info-card-row">
                      <Grid container direction="row" alignItems="stretch" justify="flex-start">
                        <Grid item xs={12} sm={6}>
                          <div className="label-bottom-border">
                            <label>{props.labelAddressPaymentData ? props.labelAddressPaymentData : ''}</label>
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <div className="label-bottom-border">
                            <label>{addressPaymentData}</label>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  </CardContent>
                  <CardActions className="form-actions-container">
                    <Button
                      id="edit-payment-data"
                      color="primary"
                      variant="contained"
                      onClick={redirectToEditPaymentData}
                    >
                      {props.labelEdit}
                    </Button>
                  </CardActions>
                </Card>
              </div>
            </Container>
          </>
        )}
      </main>
      <ZoomFooter {...props.footerProps} />
    </>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export function mapStateToProps(store: WIAAStore, ownProps: any): MyDataOverviewProps {
  const { dossierId } = ownProps.match.params;
  const headerProps: ZoomHeaderProps = mapStoreToHeaderProps(store, dossierId);
  const footerProps: ZoomFooterProps = mapStoreToFooterProps(store);

  return {
    headerProps,
    footerProps,
    holderDropDownProps: mapStoreToHolderDropDownProps(store, parseInt(dossierId)),
    notifications: formatUnreadNotifications(
      store.notificationData.notifications.filter((notification) => notification.isNew),
    ),
    labelTitle: i18nLabel(store.translationLabels, 'My_Data_Overview_Title', 'Meine Daten'),
    labelDescription: i18nLabel(
      store.translationLabels,
      'My_Data_Overview_Title_Description',
      'Diese Angaben werden in kommende Stipendienanträge übernommen und müssen aktuell sein!',
    ),
    labelEdit: i18nLabel(store.translationLabels, 'My_Data_Overview_Edit_Button', 'Bearbeiten'),
    labelMrMrsTypePersonalData: i18nLabel(
      store.translationLabels,
      'My_Data_Overview_Mr_Mrs_Type_Personal_Data',
      'Anrede',
    ),
    labelFirstNamePersonalData: i18nLabel(
      store.translationLabels,
      'My_Data_Overview_First_Name_Personal_Data',
      'Vorname',
    ),
    labelLastNamePersonalData: i18nLabel(
      store.translationLabels,
      'My_Data_Overview_Last_Name_Personal_Data',
      'Nachname',
    ),
    labelAhvn13PersonalData: i18nLabel(store.translationLabels, 'My_Data_Overview_Ahvn13_Personal_Data', 'AHV-Nummer'),
    labelDateOfBirthPersonalData: i18nLabel(
      store.translationLabels,
      'My_Data_Overview_Date_Of_Birth_Personal_Data',
      'Geburtstag',
    ),
    labelMaritalStatusPersonalData: i18nLabel(
      store.translationLabels,
      'My_Data_Overview_Marital_Status_Personal_Data',
      'Zivilstand',
    ),
    labelNationalityPersonalData: i18nLabel(
      store.translationLabels,
      'My_Data_Overview_Nationality_Personal_Data',
      'Nationalität',
    ),
    labelCityPersonalData: i18nLabel(store.translationLabels, 'My_Data_Overview_City_Personal_Data', 'Heimatort'),
    labelPhoneNumberContactData: i18nLabel(
      store.translationLabels,
      'My_Data_Overview_Phone_Number_Contact_Data',
      'Telefon',
    ),
    labelEmailContanctData: i18nLabel(store.translationLabels, 'My_Data_Overview_Email_Contact_Data', 'E-Mail'),
    labelStreetAndNumberAddressData: i18nLabel(
      store.translationLabels,
      'My_Data_Overview_Street_And_Number_Address_Data',
      'Strasse',
    ),
    labelPostCodeAndCityAddressData: i18nLabel(
      store.translationLabels,
      'My_Data_Overview_Post_Code_And_City_Address_Data',
      'PLZ/Ort',
    ),
    labelCountryAddressData: i18nLabel(store.translationLabels, 'My_Data_Overview_Country_Address_Data', 'Land'),
    labelAddressPaymentData: i18nLabel(store.translationLabels, 'My_Data_Overview_Address_Payment_Data', 'Adresse'),
    labelAccountHolderPaymentData: i18nLabel(
      store.translationLabels,
      'My_Data_Overview_Account_Holder_Payment_Data',
      'Kontoinhaber',
    ),
    labelIbanPaymentData: i18nLabel(store.translationLabels, 'My_Data_Overview_Iban_Payment_Data', 'IBAN'),
    titlePersonalData: i18nLabel(store.translationLabels, 'My_Data_Overview_Title_Personal_Data', 'Personalien'),
    titleContactData: i18nLabel(store.translationLabels, 'My_Data_Overview_Title_Contact_Data', 'Kontakt'),
    titleAddressData: i18nLabel(store.translationLabels, 'My_Data_Overview_Title_Address_Data', 'Adresse'),
    titlePaymentData: i18nLabel(store.translationLabels, 'My_Data_Overview_Title_Payment_Data', 'Auszahlungsadresse'),
    labelBackArrow: i18nLabel(store.translationLabels, 'My_Data_Overview_Back_Arrow', 'Zurück'),
    breadcrumbLabels: [
      i18nLabel(store.translationLabels, 'My_Data_Overview_Breadcrumb_Label_1', 'Übersicht'),
      i18nLabel(store.translationLabels, 'My_Data_Overview_Breadcrumb_Label_2', 'Meine Daten'),
    ],
    errorLabels: store.translationLabels,
    dossierId: dossierId,
    ahvn13PersonalData: store.myDataOverview.personalData ? store.myDataOverview.personalData.ahvNo : '',
    mrMrsTypePersonalData: store.myDataOverview.personalData
      ? store.myDataOverview.personalData.mrMrsType?.toString()
      : '',
    firstNamePersonalData: store.myDataOverview.personalData ? store.myDataOverview.personalData.firstName : '',
    lastNamePersonalData: store.myDataOverview.personalData ? store.myDataOverview.personalData.lastName : '',
    dateOfBirthPersonalData: store.myDataOverview.personalData
      ? store.myDataOverview.personalData.dateOfBirth
      : undefined,
    maritalStatusPersonalData: store.myDataOverview.personalData
      ? store.myDataOverview.personalData.maritalStatus?.toString()
      : '',
    nationalityPersonalData: store.myDataOverview.personalData
      ? store.myDataOverview.personalData.nationalityId?.toString()
      : '',
    cityPersonalData: store.myDataOverview.personalData ? store.myDataOverview.personalData.originName : '',
    phoneNumberContanctData: store.myDataOverview.contactData
      ? store.myDataOverview.contactData.phoneNumberPrivate
      : '',
    emailContanctData: store.myDataOverview.contactData ? store.myDataOverview.contactData.emailAddress : '',
    streetAndNumberAddressData: store.myDataOverview.addressData
      ? store.myDataOverview.addressData.street + ' ' + store.myDataOverview.addressData.houseNumber
      : '',
    postCodeAddressData: store.myDataOverview.addressData ? store.myDataOverview.addressData.zipCode?.toString() : '',
    cityAddressData: store.myDataOverview.addressData ? store.myDataOverview.addressData.town : '',
    countryAddressData: store.myDataOverview.addressData
      ? store.myDataOverview.addressData.countryIdType?.toString()
      : '',
    accountHolderPaymentData: store.myDataOverview.paymentData
      ? store.myDataOverview.paymentData.destinationAddressRoleId?.toString()
      : '',
    ibanPaymentData: store.myDataOverview.paymentData ? store.myDataOverview.paymentData.accountNumber : '',
    addressPaymentData: store.myDataOverview.paymentData
      ? store.myDataOverview.paymentData.street +
        ', ' +
        store.myDataOverview.paymentData.zipCode +
        ' ' +
        store.myDataOverview.paymentData.town
      : ' ',
    labelMarkAsRead: i18nLabel(store.translationLabels, 'Notifications_Box_Mark_As_Read', 'Schliessen'),
    labelGoToAction: i18nLabel(store.translationLabels, 'Notifications_Box_Go_To_Action', 'Zum Upload'),
    isLoading: store.myDataOverview.isLoading,
    countryOptions: store.translationOptions.nationalities
      ? [
          ...store.translationOptions.nationalities.map((option) => {
            return { value: option.id.toString(), name: option.name };
          }),
        ]
      : [],
    mrMrsTypes: store.translationOptions.titles
      ? [
          ...store.translationOptions.titles.map((option) => {
            return { value: option.id.toString(), name: option.name };
          }),
        ]
      : [],
    pifMaritalStatuses: store.translationOptions['piF-marital-statuses']
      ? [
          ...store.translationOptions['piF-marital-statuses'].map((option) => {
            return { value: option.id.toString(), name: option.name };
          }),
        ]
      : [],
  };
}

export default withRouter(connect(mapStateToProps)(MyDataOverview));
