import { Action } from 'redux';
import { Observable, of } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { Epic, ofType } from 'redux-observable';
import { catchError, map, mergeMap } from 'rxjs/operators';

import {
  SiblingsDataAction,
  SiblingsDataEditSiblingAction,
  SiblingsDataGetResultAction,
  SiblingsDataEditResultAction,
  SiblingsDataAddSiblingAction,
  SiblingsDataAddResultAction,
  SiblingsDataDeleteSiblingAction,
  SiblingsDataDeleteResultAction,
  SiblingsDataGetAction,
} from './actions';
import { handleRequestFailureInEpic } from '../../utils/error-handling';
import { customAjax } from '../../utils/ajax-wrapper';

const loadSiblingsData = (payload: AjaxResponse): SiblingsDataGetResultAction => {
  return {
    type: SiblingsDataAction.GET_RESULT,
    payload: payload.response,
  };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const loadSiblingsOnError = (error: any): any => {
  if (error.status == 404) {
    return {
      type: SiblingsDataAction.EMPTY_SIBLINGS_DATA,
    };
  }

  return handleRequestFailureInEpic(error);
};

export const getSiblingsDataEpic: Epic<Action, Action> = (action$: Observable<Action>): Observable<Action> =>
  action$.pipe(
    ofType(SiblingsDataAction.GET),
    mergeMap((action) => {
      const pifId: number = (action as SiblingsDataGetAction).pifId;

      return customAjax({
        subRoute: `/dossier/v1/persons-in-formation/${pifId}/siblings`,
        withCredentials: true,
        method: 'GET',
      }).pipe(
        map((response) => loadSiblingsData(response)),
        catchError((error) => {
          return of(loadSiblingsOnError(error));
        }),
      );
    }),
  );

const siblingDataAdded = (payload: AjaxResponse): SiblingsDataAddResultAction => {
  return {
    type: SiblingsDataAction.ADD_RESULT,
    payload: { ...payload.response },
  };
};

export const addSiblingDataEpic: Epic<Action, Action> = (action$: Observable<Action>): Observable<Action> =>
  action$.pipe(
    ofType(SiblingsDataAction.ADD_ITEM),
    mergeMap((action) => {
      const pifId = (action as SiblingsDataAddSiblingAction).pifId;
      const item = (action as SiblingsDataAddSiblingAction).payload;

      return customAjax({
        subRoute: `/dossier/v1/persons-in-formation/${pifId}/siblings`,
        withCredentials: true,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(item),
      }).pipe(
        map((response) => siblingDataAdded(response)),
        catchError((error) => {
          return of(handleRequestFailureInEpic(error));
        }),
      );
    }),
  );

const siblingDataEdited = (payload: AjaxResponse): SiblingsDataEditResultAction => {
  return {
    type: SiblingsDataAction.EDIT_RESULT,
    payload: { ...payload.response },
  };
};

export const editSiblingDataEpic: Epic<Action, Action> = (action$: Observable<Action>): Observable<Action> =>
  action$.pipe(
    ofType(SiblingsDataAction.EDIT_ITEM),
    mergeMap((action) => {
      const pifId = (action as SiblingsDataEditSiblingAction).pifId;
      const item = (action as SiblingsDataEditSiblingAction).payload;

      return customAjax({
        subRoute: `/dossier/v1/persons-in-formation/${pifId}/siblings/${item.id}`,
        withCredentials: true,
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(item),
      }).pipe(
        map((response) => siblingDataEdited(response)),
        catchError((error) => {
          return of(handleRequestFailureInEpic(error));
        }),
      );
    }),
  );

const siblingDataDeleted = (payload: number): SiblingsDataDeleteResultAction => {
  return {
    type: SiblingsDataAction.DELETE_RESULT,
    payload,
  };
};

export const deleteSiblingDataEpic: Epic<Action, Action> = (action$: Observable<Action>): Observable<Action> =>
  action$.pipe(
    ofType(SiblingsDataAction.DELETE_ITEM),
    mergeMap((action) => {
      const pifId = (action as SiblingsDataDeleteSiblingAction).pifId;
      const itemId = (action as SiblingsDataDeleteSiblingAction).payload;

      return customAjax({
        subRoute: `/dossier/v1/persons-in-formation/${pifId}/siblings/${itemId}`,
        withCredentials: true,
        method: 'DELETE',
      }).pipe(
        map(() => siblingDataDeleted(itemId)),
        catchError((error) => {
          return of(handleRequestFailureInEpic(error));
        }),
      );
    }),
  );
