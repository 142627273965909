import React, { ReactElement } from 'react';
import { Grid } from '@material-ui/core';
import { ZoomArrowLeftIcon } from '../../icons/ZoomArrowLeftIcon';
import { blue } from '../../../theme';
import { useHistory } from 'react-router-dom';
import { HolderDropDown, HolderDropDownProps } from '../holder-dropdown/HolderDropDown';
import { Action } from 'redux';

export interface NavigationProps {
  title?: string;
  labelBackArrow?: string;
  dispatch?: (action: Action) => void;
  holderDropDownProps: HolderDropDownProps;
  mainPageTopInfo: boolean;
}

interface BackButtonProps {
  labelBackArrow?: string;
}

interface HolderDropDownDivProps {
  dispatch?: (action: Action) => void;
  holderDropDownProps: HolderDropDownProps;
}

/***
 * Returning back button div with text
 * @param props
 * @constructor
 */
const BackButton = (props: BackButtonProps): ReactElement => {
  const history = useHistory();
  return (
    <Grid container>
      <Grid item xs={12} sm={6}>
        <div className="back-button-desktop" onClick={() => history.goBack()}>
          <ZoomArrowLeftIcon viewBox="0 0 80 80" fontSize="large" htmlColor={blue.main} />
          <div className="nav-div">{props.labelBackArrow}</div>
        </div>
      </Grid>
    </Grid>
  );
};

/***
 * Returning drop down div (holder selection)
 * @param props
 * @constructor
 */
const HolderDropDownDiv = (props: HolderDropDownDivProps): ReactElement => {
  const holderPropertiesWithDispatch: HolderDropDownProps = { ...props.holderDropDownProps, dispatch: props.dispatch };
  return (
    <Grid container style={{ marginTop: '9px', marginBottom: '9px' }}>
      <Grid item xs={12} sm={6}>
        <HolderDropDown {...holderPropertiesWithDispatch} />
      </Grid>
    </Grid>
  );
};

/***
 * Returning navigation items:
 * - Back Button
 * - Holder dropdown
 * - Title (if needed)
 * @param props
 * @constructor
 */
export const NavigationItems = (props: NavigationProps): ReactElement => {
  return props.mainPageTopInfo ? (
    <div className="main-page-top-info">
      <BackButton labelBackArrow={props.labelBackArrow} />
      <HolderDropDownDiv holderDropDownProps={props.holderDropDownProps} dispatch={props.dispatch} />
      {props.title && <h1 style={{ marginTop: '1.5rem' }}>{props.title}</h1>}
    </div>
  ) : (
    <div>
      <BackButton labelBackArrow={props.labelBackArrow} />
      <HolderDropDownDiv holderDropDownProps={props.holderDropDownProps} dispatch={props.dispatch} />
      {props.title && <h1 style={{ marginTop: '1.5rem' }}>{props.title}</h1>}
    </div>
  );
};
