import { LastDemandData } from './types';

export enum LastDemandDataTakeoverAction {
  GET = 'LAST_DEMAND_DATA_TAKEOVER_GET',
  RESULT = 'LAST_DEMAND_DATA_TAKEOVER_RESULT',
  ERROR = 'LAST_DEMAND_DATA_TAKEOVER_ERROR',
}

export interface LastDemandDataTakeoverGetAction {
  type: typeof LastDemandDataTakeoverAction.GET;
  payload: number;
}

export interface LastDemandDataTakeoverResultAction {
  type: typeof LastDemandDataTakeoverAction.RESULT;
  payload: LastDemandData;
}

export interface LastDemandDataTakeoverErrorAction {
  type: typeof LastDemandDataTakeoverAction.ERROR;
  payload: string;
}

export type LastDemandDataType =
  | LastDemandDataTakeoverGetAction
  | LastDemandDataTakeoverResultAction
  | LastDemandDataTakeoverErrorAction;
