import { IncomingData } from './types';

export enum IncomingDataAction {
  GET = 'INCOMING_DATA_GET',
  GET_RESULT = 'INCOMING_DATA_GET_RESULT',
  CHANGE = 'INCOMING_DATA_CHANGE',
  SUBMIT = 'INCOMING_DATA_SUBMIT',
  RESULT = 'INCOMING_DATA_RESULT',
  NON_EXISTENT_INCOMING = 'NON_EXISTENT_INCOMING_DATA',
}

export interface IncomingDataGetAction {
  type: typeof IncomingDataAction.GET;
  payload: IncomingData;
  demandId: number;
}

export interface IncomingDataGetResultAction {
  type: typeof IncomingDataAction.GET_RESULT;
  payload: IncomingData;
}

export interface IncomingDataChangeAction {
  type: typeof IncomingDataAction.CHANGE;
  payload: string;
  changeField: string;
}

export interface IncomingDataSubmitAction {
  type: typeof IncomingDataAction.SUBMIT;
  payload: IncomingData;
  demandId: number;
}

export interface IncomingDataResultAction {
  type: typeof IncomingDataAction.RESULT;
  payload: IncomingData;
}

export type IncomingDataActionType =
  | IncomingDataGetAction
  | IncomingDataGetResultAction
  | IncomingDataChangeAction
  | IncomingDataSubmitAction
  | IncomingDataResultAction;
