import { AuditItem, PermissionData, UserData } from './types';

export enum PersonDetailsDataAction {
  GET = 'PERSON_DETAILS_DATA_GET',
  GET_RESULT = 'PERSON_DETAILS_DATA_GET_RESULT',
  EDIT_PERSON_DETAILS = 'PERSON_DETAILS_DATA_EDIT',
  EDIT_RESULT_PERSON_DETAILS = 'PERSON_DETAILS_DATA_EDIT_RESULT',
  EDIT_PERMISSIONS_PERSON_DETAILS = 'PERSON_DETAILS_PERMISSIONS_DATA_EDIT',
  EDIT_RESULT_PERMISSIONS_PERSON_DETAILS = 'PERSON_DETAILS_PERMISSIONS_DATA_EDIT_RESULT',
  DELETE_PERMISSIONS_PERSON_DETAILS = 'PERSON_DETAILS_PERMISSIONS_DATA_DELETE',
  DELETE_RESULT_PERMISSIONS_PERSON_DETAILS = 'PERSON_DETAILS_PERMISSIONS_DATA_DELETE',
  MANUAL_DEMAND_ACTIVATION = 'PERSON_DETAILS_MANUAL_DEMAND_ACTIVATION',
  MANUAL_DEMAND_ACTIVATION_RESULT = 'PERSON_DETAILS_MANUAL_DEMAND_ACTIVATION_RESULT',
}

export interface PersonDetailsDataGetAction {
  type: typeof PersonDetailsDataAction.GET;
  payload: number;
}

export interface PersonDetailsDataGetResultAction {
  type: typeof PersonDetailsDataAction.GET_RESULT;
  user: UserData;
  permissions: PermissionData[];
  canActivateDemand: boolean;
  ownedDossierId: number;
  auditList?: Array<AuditItem>;
}

export interface PersonDetailsDataEditPersonDetailsAction {
  type: typeof PersonDetailsDataAction.EDIT_PERSON_DETAILS;
  payload: UserData;
}

export interface PersonDetailsDataEditResultPersonDetailsAction {
  type: typeof PersonDetailsDataAction.EDIT_RESULT_PERSON_DETAILS;
  payload: UserData;
}

export interface PersonDetailsEditPermissionsDataAction {
  type: typeof PersonDetailsDataAction.EDIT_PERMISSIONS_PERSON_DETAILS;
  payload: PermissionData;
}

export interface PersonDetailsEditResultPermissionsDataAction {
  type: typeof PersonDetailsDataAction.EDIT_RESULT_PERMISSIONS_PERSON_DETAILS;
  payload: PermissionData;
}

export interface PersonDetailsDeletePermissionsDataAction {
  type: typeof PersonDetailsDataAction.DELETE_PERMISSIONS_PERSON_DETAILS;
  payload: number;
}

export interface PersonDetailsDeleteResultPermissionsDataAction {
  type: typeof PersonDetailsDataAction.DELETE_RESULT_PERMISSIONS_PERSON_DETAILS;
  payload: number;
}

export interface PersonDetailsManualDemandActivationAction {
  type: typeof PersonDetailsDataAction.MANUAL_DEMAND_ACTIVATION;
  portalId: number;
  manualDemandActivation: boolean;
}

export interface PersonDetailsManualDemandActivationResultAction {
  type: typeof PersonDetailsDataAction.MANUAL_DEMAND_ACTIVATION_RESULT;
  payload: boolean;
}

export type PersonDetailsActionType =
  | PersonDetailsDataGetAction
  | PersonDetailsDataGetResultAction
  | PersonDetailsDataEditPersonDetailsAction
  | PersonDetailsDataEditResultPersonDetailsAction
  | PersonDetailsEditPermissionsDataAction
  | PersonDetailsEditResultPermissionsDataAction
  | PersonDetailsDeletePermissionsDataAction
  | PersonDetailsDeleteResultPermissionsDataAction
  | PersonDetailsManualDemandActivationAction
  | PersonDetailsManualDemandActivationResultAction;
