import { Button, Container } from '@material-ui/core';
import React, { ReactElement } from 'react';
import { connect } from 'react-redux';
import ZoomFooter, {
  mapStoreToFooterProps,
  ZoomFooterProps,
} from '../../components/base-page-components/footer/ZoomFooter';
import ZoomHeader, {
  mapStoreToHeaderProps,
  ZoomHeaderProps,
} from '../../components/base-page-components/header/ZoomHeader';
import { i18nLabel } from '../../store/i18n/helpers';
import { WIAAStore } from '../../store/types';

export interface IdentificationErrorProps {
  headerProps: ZoomHeaderProps;
  footerProps: ZoomFooterProps;
  labelIdentificationErrorMessage: string;
  labelBackToHomepage: string;
}

export const IdentificationError = (props: IdentificationErrorProps): ReactElement => {
  return (
    <>
      <ZoomHeader
        appName="Stipendienportal"
        userName="Max Mustermann"
        ahvn="756-4352-9990-13"
        hideUserMenu={true}
        labelMyData="Meine Daten"
        labelMyEducation="Meine Ausbildungen"
        labelMyScholarship="Meine Stipendienanträge"
        labelPayout="Auszahlungen"
        labelRefund="Rückzahlungen"
        labelPowerOfAttorney="Vollmachten"
        labelScholarshipHolder="Stipendiat wählen"
        labelLogout="Abmelden"
      />
      <main className="main">
        <Container>
          <div id="main-page-container">
            <h2>{props.labelIdentificationErrorMessage}</h2>
            <Button
              href="/"
              color="primary"
              size="large"
              variant="contained"
              id="back-to-homepage"
              style={{ marginTop: '50px' }}
            >
              {props.labelBackToHomepage}
            </Button>
          </div>
        </Container>
      </main>
      <ZoomFooter {...props.footerProps}></ZoomFooter>
    </>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function mapStateToProps(store: WIAAStore): IdentificationErrorProps {
  const headerProps: ZoomHeaderProps = mapStoreToHeaderProps(store);
  const footerProps: ZoomFooterProps = mapStoreToFooterProps(store);

  return {
    headerProps: headerProps,
    footerProps: footerProps,
    labelIdentificationErrorMessage: i18nLabel(
      store.translationLabels,
      'Identification_Error_Identification_Error_Message',
      'Ihre E-Mail-Adresse wurde für ein altes Konto verwendet, bitte setzen Sie sich mit der Sektion Stipendien in Kontakt.',
    ),
    labelBackToHomepage: i18nLabel(
      store.translationLabels,
      'Identification_Error_Back_To_Homepage',
      'Zurück zur Startseite',
    ),
  };
}

export default connect(mapStateToProps)(IdentificationError);
