import { ParentsData } from './types';

export enum ParentsDataAction {
  GET = 'PARENTS_DATA_GET',
  GET_RESULT = 'PARENTS_DATA_GET_RESULT',
  CHANGE = 'PARENTS_DATA_CHANGE',
  SUBMIT = 'PARENTS_DATA_SUBMIT',
  RESULT = 'PARENTS_DATA_RESULT',
  NON_EXISTENT_PARENTS = 'NON_EXISTENT_PARENTS_DATA',
}

export interface ParentsDataGetAction {
  type: typeof ParentsDataAction.GET;
  payload: ParentsData;
  pifId: number;
}

export interface ParentsDataGetResultAction {
  type: typeof ParentsDataAction.GET_RESULT;
  payload: ParentsData;
}

export interface ParentsDataChangeAction {
  type: typeof ParentsDataAction.CHANGE;
  payload: string;
  changeField: string;
}

export interface ParentsDataSubmitAction {
  type: typeof ParentsDataAction.SUBMIT;
  payload: ParentsData;
  pifId: number;
}

export interface ParentsDataResultAction {
  type: typeof ParentsDataAction.RESULT;
  payload: ParentsData;
}

export type ParentsDataActionType =
  | ParentsDataGetAction
  | ParentsDataGetResultAction
  | ParentsDataChangeAction
  | ParentsDataSubmitAction
  | ParentsDataResultAction;
