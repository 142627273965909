import {
  Breadcrumbs,
  Button,
  CircularProgress,
  Container,
  Grid,
  Link,
  Modal,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import React, { ReactElement, useEffect, useState } from 'react';
import ZoomHeader, {
  mapStoreToHeaderProps,
  ZoomHeaderProps,
} from '../../../components/base-page-components/header/ZoomHeader';
import { CustomTitleCell, CustomCell, CustomSearchBar } from '../../../utils/mui-custom-styles';
import ZoomFooter, {
  mapStoreToFooterProps,
  ZoomFooterProps,
} from '../../../components/base-page-components/footer/ZoomFooter';
import { ZoomArrowLeftIcon } from '../../../components/icons/ZoomArrowLeftIcon';
import { blue } from '../../../theme';
import { Action } from 'redux';
import { WIAAStore } from '../../../store/types';
import { i18nLabel } from '../../../store/i18n/helpers';
import { useHistory, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import './Overview.scss';
import {
  OverviewDataAction,
  OverviewDataDeleteItemAction,
  OverviewDataSearchUsersGetAction,
} from '../../../store/admin-portal/overview/actions';
import { UserData } from '../../../store/admin-portal/overview/types';
import { RoutingAction, RoutingRedirectToAction } from '../../../store/routing/actions';
import { UserDataAction, UserDataGetAction } from '../../../store/user-data/actions';
import {
  PersonDetailsDataAction,
  PersonDetailsDataGetAction,
} from '../../../store/admin-portal/person-details/actions';

export interface Users {
  id: number;
  uid: string;
  firstName: string;
  lastName: string;
  email: string;
  svn: string;
  birthDate: Date;
  active: boolean;
  initialSetupFinalized: boolean;
  title: string;
}

export interface OverviewProps {
  labelId?: string;
  labelUid?: string;
  labelEmail?: string;
  labelSvn?: string;
  labelFirstName?: string;
  labelLastName?: string;
  labelBirthDate?: string;
  labelShow?: string;
  labelDelete?: string;
  labelSearch?: string;
  labelNoEntries?: string;
  labelNoEntriesFound?: string;
  pageTitle: string;
  users: Array<UserData>;
  headerProps: ZoomHeaderProps;
  footerProps: ZoomFooterProps;
  breadcrumbLabels: Array<string>;
  labelBackArrow: string;
  contentLabelDeleteAcknowledgementTitle?: string;
  contentLabelDeleteAcknowledgementParagraph?: string;
  contentLabelModalCancel?: string;
  contentLabelAcknowledgementSubmit?: string;
  dossierId: number;
  isLoading?: boolean;
  dispatch?: (action: Action) => void;
}

export const Overview = (props: OverviewProps): ReactElement => {
  const [searched, setSearched] = useState('');
  const [openDeleteAcknowlegde, setOpenDeleteAcknowledge] = useState(false);
  const [userIdToDelete, setUserIdToDelete] = useState(-1);
  const [users, setUsers] = useState(props.users ? props.users : []);
  const history = useHistory();

  const redirectToPersonDetails = (id: number) => {
    const redirectAction: RoutingRedirectToAction = {
      type: RoutingAction.REDIRECT_TO,
      payload: `/admin-portal/user/${id}/details`,
    };

    if (props.dispatch) {
      props.dispatch(redirectAction);
    }
  };

  useEffect(() => {
    setUsers(props.users ? props.users : []);
  }, [props]);

  useEffect(() => {
    if (props.dispatch) {
      const getUserMyData: UserDataGetAction = {
        type: UserDataAction.GET,
      };
      props.dispatch(getUserMyData);
    }
  }, []);

  useEffect(() => {
    if (props.dispatch) {
      const getPersonDetails: PersonDetailsDataGetAction = {
        type: PersonDetailsDataAction.GET,
        payload: -100,
      };
      props.dispatch(getPersonDetails);
    }
  }, []);

  const requestSearch = (searchedVal: string) => {
    if (searchedVal === '') {
      setSearched('');
      setUsers([]);
    } else {
      setSearched(searchedVal);
      if (props.dispatch) {
        const getUsersAction: OverviewDataSearchUsersGetAction = {
          type: OverviewDataAction.SEARCH_USERS_GET,
          searchWord: searchedVal,
        };
        props.dispatch(getUsersAction);
      }
      setUsers(props.users);
    }
  };

  const onDeleteModalDeleteBtnHandler = () => {
    if (props.dispatch) {
      const deleteUserData: OverviewDataDeleteItemAction = {
        type: OverviewDataAction.DELETE_ITEM,
        payload: userIdToDelete,
      };
      props.dispatch(deleteUserData);
    }
    setOpenDeleteAcknowledge(false);
  };

  const cancelSearch = () => {
    setSearched('');
    setUsers([]);
  };

  const onDelete = (userId: number) => {
    setUserIdToDelete(userId);
    setOpenDeleteAcknowledge(true);
  };

  return (
    <>
      <ZoomHeader {...props.headerProps} dispatch={props.dispatch} />
      <main className="main">
        <>
          <Container>
            <div id="main-page-container">
              <Breadcrumbs separator=">" aria-label="breadcrumb">
                <Link style={{ fontFamily: 'Proxima Nova Cond' }} color="inherit">
                  {props.breadcrumbLabels && props.breadcrumbLabels[0]}
                </Link>
              </Breadcrumbs>
              <Grid container direction="row" alignItems="stretch" justify="flex-start">
                <Grid item xs={12} sm={6}>
                  <div className="back-button-desktop" onClick={() => history.goBack()}>
                    <ZoomArrowLeftIcon viewBox="0 0 80 80" fontSize="large" htmlColor={blue.main} />
                    <span className="back-button">{props.labelBackArrow}</span>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className="search-bar">
                    <CustomSearchBar
                      placeholder={props.labelSearch}
                      value={searched}
                      onChange={(searchVal) => requestSearch(searchVal)}
                      onCancelSearch={() => cancelSearch()}
                    />
                  </div>
                </Grid>
              </Grid>
              <h1>{props.pageTitle}</h1>
              <div>
                {props.isLoading ? (
                  <div style={{ textAlign: 'center' }}>
                    <CircularProgress />
                  </div>
                ) : searched === '' && users.length === 0 ? (
                  <p className="no-user-entries">{props.labelNoEntries}</p>
                ) : searched !== '' && users.length === 0 ? (
                  <p className="no-user-entries">{props.labelNoEntriesFound}</p>
                ) : (
                  <TableContainer component={Paper}>
                    <Table className="results-data-row" aria-label="simple table" component={Paper}>
                      <TableHead>
                        <TableRow>
                          <CustomTitleCell style={{ width: '20%' }} align="left" className="th-cell">
                            {props.labelSvn}
                          </CustomTitleCell>
                          <CustomTitleCell style={{ width: '20%' }} align="left" className="th-cell">
                            {props.labelEmail}
                          </CustomTitleCell>
                          <CustomTitleCell style={{ width: '10%' }} align="left" className="th-cell">
                            {props.labelUid}
                          </CustomTitleCell>
                          <CustomTitleCell style={{ width: '20%' }} align="left" className="th-cell">
                            {props.labelFirstName}
                          </CustomTitleCell>
                          <CustomTitleCell style={{ width: '20%' }} align="left" className="th-cell">
                            {props.labelLastName}
                          </CustomTitleCell>
                          <CustomTitleCell align="right" style={{ width: '5%' }} />
                          <CustomTitleCell align="right" style={{ width: '5%' }} />
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {users.map((user) => (
                          <TableRow key={user.svn} component={Paper}>
                            <CustomCell align="left" style={{ width: '20%' }} className="td-cell">
                              {user.svn}
                            </CustomCell>
                            <CustomCell align="left" style={{ width: '20%' }} className="td-cell">
                              {user.email}
                            </CustomCell>
                            <CustomCell align="left" style={{ width: '10%' }} className="td-cell">
                              {user.uid}
                            </CustomCell>
                            <CustomCell align="left" style={{ width: '20%' }} className="td-cell">
                              {user.firstName}
                            </CustomCell>
                            <CustomCell align="left" style={{ width: '20%' }} className="td-cell">
                              {user.lastName}
                            </CustomCell>
                            <CustomCell align="right" style={{ width: '5%' }}>
                              <Button
                                id="user-details"
                                color="primary"
                                variant="outlined"
                                onClick={() => redirectToPersonDetails(user.id)}
                              >
                                {props.labelShow}
                              </Button>
                            </CustomCell>
                            <CustomCell align="center" style={{ width: '5%' }}>
                              <Button
                                id="delete-user"
                                color="primary"
                                variant="outlined"
                                onClick={() => onDelete(user.id)}
                              >
                                {props.labelDelete}
                              </Button>
                            </CustomCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
              </div>
            </div>
            <Modal
              open={openDeleteAcknowlegde}
              aria-labelledby="delete-acknowledge"
              aria-describedby="delete-acknowledge-description"
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
              <div className="admin-portal-modal">
                <h1 id="delete-acknowledge">{props.contentLabelDeleteAcknowledgementTitle}</h1>
                <div id="delete-acknowledge-description">
                  <p>{props.contentLabelDeleteAcknowledgementParagraph}</p>
                </div>
                <div className="admin-portal-modal-actions">
                  <Button color="primary" variant="outlined" fullWidth onClick={() => setOpenDeleteAcknowledge(false)}>
                    {props.contentLabelModalCancel}
                  </Button>
                  <Button color="primary" variant="contained" fullWidth onClick={() => onDeleteModalDeleteBtnHandler()}>
                    {props.contentLabelAcknowledgementSubmit}
                  </Button>
                </div>
              </div>
            </Modal>
          </Container>
        </>
      </main>
      <ZoomFooter {...props.footerProps} />
    </>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export const mapStateToProps = (store: WIAAStore, urlProps: any): OverviewProps => {
  const { dossierId } = urlProps.match.params;
  const headerProps: ZoomHeaderProps = mapStoreToHeaderProps(store, dossierId);
  const footerProps: ZoomFooterProps = mapStoreToFooterProps(store);

  return {
    headerProps: { ...headerProps, hideUserMenu: false },
    footerProps: footerProps,
    dossierId: dossierId,
    pageTitle: i18nLabel(store.translationLabels, 'Admin_Portal_Title', 'Admin Übersicht'),
    labelBackArrow: i18nLabel(store.translationLabels, 'Admin_Portal_Back_Arrow', 'Zurück'),
    labelId: i18nLabel(store.translationLabels, 'Admin_Portal_Label_Id', 'Id'),
    labelUid: i18nLabel(store.translationLabels, 'Admin_Portal_Label_Uid', 'Uid'),
    labelEmail: i18nLabel(store.translationLabels, 'Admin_Portal_Label_Email', 'Email'),
    labelSvn: i18nLabel(store.translationLabels, 'Admin_Portal_Label_Svn', 'Svn'),
    labelFirstName: i18nLabel(store.translationLabels, 'Admin_Portal_Label_First_Name', 'Vorname'),
    labelLastName: i18nLabel(store.translationLabels, 'Admin_Portal_Label_Last_Name', 'Nachname'),
    labelBirthDate: i18nLabel(store.translationLabels, 'Admin_Portal_Label_Birth_Date', 'Geburtsdatum'),
    labelShow: i18nLabel(store.translationLabels, 'Admin_Portal_Label_Show', 'Details'),
    labelDelete: i18nLabel(store.translationLabels, 'Admin_Portal_Label_Delete', 'Löschen'),
    labelSearch: i18nLabel(store.translationLabels, 'Admin_Portal_Label_Search', 'Suchen'),
    labelNoEntries: i18nLabel(
      store.translationLabels,
      'Admin_Portal_Label_No_Entries',
      'Bitte nutzen Sie das Suchfeld um Nutzer zu finden (Die Suche kann beim Nachname, E-Mail oder AHV-Nummer gemacht werden).',
    ),
    labelNoEntriesFound: i18nLabel(
      store.translationLabels,
      'Admin_Portal_Label_No_Entries_Found',
      'Es wurden keine Einträge für ihre Suche gefunden.',
    ),
    breadcrumbLabels: [i18nLabel(store.translationLabels, 'Admin_Portal_Breadcrumb_Label_1', 'Admin Übersicht')],
    contentLabelDeleteAcknowledgementTitle: i18nLabel(
      store.translationLabels,
      'Admin_Portal_Label_Delete_Title',
      'Benutzer löschen',
    ),
    contentLabelDeleteAcknowledgementParagraph: i18nLabel(
      store.translationLabels,
      'Admin_Portal_Label_Delete_Description',
      'Sind Sie sicher, dass Sie die ausgewählte Benutzer löschen möchten?',
    ),
    contentLabelModalCancel: i18nLabel(store.translationLabels, 'Admin_Portal_Label_Cancel', 'Abbrechen'),
    contentLabelAcknowledgementSubmit: i18nLabel(store.translationLabels, 'Admin_Portal_Label_Delete', 'Bestätigen'),
    isLoading: store.overviewDataStore.isLoading,
    users: store.overviewDataStore.users,
  };
};

export default withRouter(connect(mapStateToProps)(Overview));
