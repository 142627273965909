import React, { ReactElement } from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import { black } from './../../theme';

export function ZoomPencilIcon(props: SvgIconProps): ReactElement {
  const color = props.htmlColor ? props.htmlColor : black.main;
  return (
    <SvgIcon {...props}>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <path d="M0 0H80V80H0z" transform="translate(-456 -257) translate(456 257)" />
            <path
              fill={color}
              fillRule="nonzero"
              d="M63.9 25.2l-8.13-8.12c-.375-.376-.884-.587-1.415-.587-.53 0-1.04.211-1.415.587L22.42 47.6c-.05 0-.09.11-.14.17-.032.032-.06.07-.08.11-.093.122-.167.257-.22.4l-5.3 13.51c-.292.742-.115 1.587.45 2.15.375.372.882.58 1.41.58.257.002.511-.046.75-.14L32.7 59c.138-.057.27-.131.39-.22l.11-.09.16-.13L63.9 28c.374-.377.583-.888.58-1.42-.007-.518-.215-1.012-.58-1.38zm-15.31 1.89l5.3 5.29L32 54.31 26.66 49l21.93-21.91zm-26.5 31.85l2.49-6.35 3.82 3.82-6.31 2.53zm34.62-29.39l-5.29-5.29 2.94-2.94 5.29 5.29-2.94 2.94z"
              transform="translate(-456 -257) translate(456 257)"
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}
