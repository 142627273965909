import { Breadcrumbs, Button, CircularProgress, Container, Grid, Link, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { ReactElement, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';
import { Action } from 'redux';
import ZoomFooter, {
  mapStoreToFooterProps,
  ZoomFooterProps,
} from '../../../components/base-page-components/footer/ZoomFooter';
import ZoomHeader, {
  mapStoreToHeaderProps,
  ZoomHeaderProps,
} from '../../../components/base-page-components/header/ZoomHeader';
import ZoomSelectInput from '../../../components/forms/select-input/ZoomSelectInput';
import { ZoomArrowLeftIcon } from '../../../components/icons/ZoomArrowLeftIcon';
import { i18nLabel } from '../../../store/i18n/helpers';
import { WIAAStore } from '../../../store/types';
import { blue } from '../../../theme';
import { rightsTypeOptions, rightsTypeOptionsAdmin, roleTypeOptionsNewPermission } from '../../../utils/form-data';
import { requiredValidation } from '../../../utils/validators';
import {
  NewPermissionSearchUsersGetAction,
  NewPermissionDataAction,
} from '../../../store/admin-portal/new-permission/actions';
import { PermissionData, UserData } from '../../../store/admin-portal/new-permission/types';

export interface NewPermissionProps {
  labelTitle?: string;
  labelOwner?: string;
  labelSearchUser?: string;
  labelRole?: string;
  labelPermissionType?: string;
  labelSave?: string;
  labelCancel?: string;
  labelBackArrow?: string;
  owner?: string;
  role?: string;
  permissionType?: string;
  userOptions?: Array<UserData>;
  headerProps: ZoomHeaderProps;
  footerProps: ZoomFooterProps;
  breadcrumbLabels?: Array<string>;
  errorLabels?: { [key: string]: string };
  userId: number;
  isLoading?: boolean;
  dispatch?: (action: Action) => void;
}

interface PersonDetailsErrors {
  owner?: string;
}

export const NewPermission = (props: NewPermissionProps): ReactElement => {
  const adminRoleId = '8';
  const [owner, setOwner] = useState(props.owner ? props.owner : '');
  const [role, setRole] = useState(props.role ? props.role : '4');
  const [permissionType, setPermissionType] = useState(props.permissionType ? props.permissionType : '1');
  const history = useHistory();
  const initialErrors: PersonDetailsErrors = {};
  const [errors, setErrors] = useState(initialErrors);
  const [inputVal, setInputVal] = useState<UserData | null>(null);

  const onRoleChange = (newValue: string) => {
    setRole(newValue);
    if (newValue === adminRoleId) {
      setPermissionType('5');
      setOwner('');
      setInputVal(null);
    } else {
      if (permissionType === '5') {
        setPermissionType('1');
      }
    }
  };

  const onPermissionTypeChange = (newValue: string) => {
    setPermissionType(newValue);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onInputChangeHandler = (e: any) => {
    if (props.dispatch) {
      const getUsersAction: NewPermissionSearchUsersGetAction = {
        type: NewPermissionDataAction.SEARCH_USERS_GET,
        searchWord: e.target.value,
        currentUserId: props.userId,
      };
      props.dispatch(getUsersAction);
    }
  };

  const getNewPermissionData = (): PermissionData => {
    return {
      userId: props.userId,
      ownerUserId: parseInt(owner),
      permissionTypeId: parseInt(permissionType),
      roleId: parseInt(role),
    };
  };

  const getFormErrors = (): PersonDetailsErrors => {
    const errors: PersonDetailsErrors = {};
    errors.owner =
      role === adminRoleId ? '' : requiredValidation(owner.toString(), props.errorLabels ? props.errorLabels : {});
    return errors;
  };

  const validateForm = (): boolean => {
    const newErrors = getFormErrors();
    setErrors(newErrors);
    const errorsPresent = Object.values(newErrors).some((value) => value !== '');
    return !errorsPresent;
  };

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    if (validateForm() && props.dispatch) {
      const submitAction = {
        type: NewPermissionDataAction.ADD_ITEM,
        payload: getNewPermissionData(),
      };
      props.dispatch(submitAction);
    }
    event.preventDefault();
  };

  let rightsOptions = rightsTypeOptions;
  let disabledAutocomplete = false;
  if (role === adminRoleId) {
    rightsOptions = rightsTypeOptionsAdmin;
    disabledAutocomplete = true;
  }

  return (
    <>
      <ZoomHeader {...props.headerProps} dispatch={props.dispatch} />
      <main className="main">
        {props.isLoading ? (
          <div style={{ textAlign: 'center' }}>
            <CircularProgress />
          </div>
        ) : (
          <>
            <Container>
              <div id="main-page-container">
                <Breadcrumbs separator=">" aria-label="breadcrumb">
                  <Link style={{ fontFamily: 'Proxima Nova Cond' }} color="inherit" href={'/admin-portal'}>
                    <>{props.breadcrumbLabels && props.breadcrumbLabels[0]}</>
                  </Link>
                  <Link
                    style={{ fontFamily: 'Proxima Nova Cond' }}
                    color="inherit"
                    href={`/admin-portal/user/${props.userId}/details`}
                  >
                    <>{props.breadcrumbLabels && props.breadcrumbLabels[1]}</>
                  </Link>
                  <Link style={{ fontFamily: 'Proxima Nova Cond' }} color="inherit">
                    <b>{props.breadcrumbLabels && props.breadcrumbLabels[2]}</b>
                  </Link>
                </Breadcrumbs>
                <div className="main-page-info">
                  <div className="back-button-desktop" onClick={() => history.goBack()}>
                    <ZoomArrowLeftIcon viewBox="0 0 80 80" fontSize="large" htmlColor={blue.main} />
                    {props.labelBackArrow}
                  </div>
                  <h1 className="title-desktop">{props.labelTitle}</h1>
                  <form onSubmit={onSubmit}>
                    <div className="form-element-container">
                      <Grid container direction="row" alignItems="stretch" justify="flex-start">
                        <Grid item xs={12} sm={6}>
                          <div className="label">
                            <label>{props.labelOwner}</label>
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <div>
                            <Autocomplete
                              id="find-user-autocomplete"
                              disabled={disabledAutocomplete}
                              value={inputVal}
                              disablePortal
                              autoComplete
                              filterOptions={(x) => x}
                              options={props.userOptions ? props.userOptions : []}
                              getOptionLabel={(option) =>
                                typeof option === 'string'
                                  ? option
                                  : `${option.svn} ${option.firstName} ${option.lastName} ${option.email}`
                              }
                              onChange={(event, newInputValue) => {
                                setInputVal(newInputValue);
                                setOwner(newInputValue?.id ? newInputValue.id.toString() : '');
                                setErrors({
                                  ...errors,
                                  owner: requiredValidation(
                                    newInputValue?.id ? newInputValue.id.toString() : '',
                                    props.errorLabels ? props.errorLabels : {},
                                  ),
                                });
                              }}
                              onInputChange={(event, newInputValue, reason) => {
                                if (reason !== 'input') {
                                  return;
                                }
                                return onInputChangeHandler(event);
                              }}
                              renderInput={(params) => (
                                <TextField
                                  placeholder={props.labelSearchUser}
                                  variant="outlined"
                                  {...params}
                                  error={!!errors.owner}
                                  helperText={errors.owner}
                                />
                              )}
                            />
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                    <div className="form-element-container">
                      <ZoomSelectInput
                        label={props.labelRole ? props.labelRole : ''}
                        options={roleTypeOptionsNewPermission}
                        value={role}
                        name="role"
                        onChange={onRoleChange}
                      />
                    </div>
                    <div className="form-element-container">
                      <ZoomSelectInput
                        label={props.labelPermissionType ? props.labelPermissionType : ''}
                        options={rightsOptions}
                        value={permissionType}
                        name="permissionType"
                        onChange={onPermissionTypeChange}
                      />
                    </div>
                    <Grid container direction="row" spacing={2} style={{ paddingBottom: '1rem' }}>
                      <Grid item xs={6} sm={6}>
                        <div className="form-actions-container-cancel">
                          <Button
                            color="primary"
                            size="large"
                            type="reset"
                            variant="outlined"
                            id="cancel-add-new-permission-data"
                            style={{ width: '144px' }}
                            onClick={() => history.goBack()}
                          >
                            {props.labelCancel}
                          </Button>
                        </div>
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        <div className="form-actions-container-save">
                          <Button
                            color="primary"
                            size="large"
                            type="submit"
                            variant="contained"
                            id="save-new-permission-data"
                            style={{ width: '144px' }}
                          >
                            {props.labelSave}
                          </Button>
                        </div>
                      </Grid>
                    </Grid>
                  </form>
                </div>
              </div>
            </Container>
          </>
        )}
      </main>
      <ZoomFooter {...props.footerProps} />
    </>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export function mapStateToProps(store: WIAAStore, ownProps: any): NewPermissionProps {
  const { userId } = ownProps.match.params;
  const headerProps: ZoomHeaderProps = mapStoreToHeaderProps(store, userId);
  const footerProps: ZoomFooterProps = mapStoreToFooterProps(store);

  return {
    headerProps: { ...headerProps, hideUserMenu: false },
    footerProps,
    labelTitle: i18nLabel(store.translationLabels, 'Admin_Portal_New_Permission_Title', 'Person Details'),
    labelOwner: i18nLabel(store.translationLabels, 'Admin_Portal_New_Permission_Permission_Owner', 'Inhaber'),
    labelRole: i18nLabel(store.translationLabels, 'Admin_Portal_New_Permission_Permission_Role', 'Rolle'),
    labelPermissionType: i18nLabel(
      store.translationLabels,
      'Admin_Portal_New_Permission_Permission_Type',
      'Permission Type',
    ),
    labelSearchUser: i18nLabel(store.translationLabels, 'Admin_Portal_New_Permission_Search', 'Suchen'),
    labelBackArrow: i18nLabel(store.translationLabels, 'Admin_Portal_New_Permission_Back_Arrow', 'Zurück'),
    labelCancel: i18nLabel(store.translationLabels, 'Admin_Portal_New_Permission_Cancel', 'Abbrechen'),
    labelSave: i18nLabel(store.translationLabels, 'Admin_Portal_New_Permission_Save', 'Speichern'),
    breadcrumbLabels: [
      i18nLabel(store.translationLabels, 'Admin_Portal_New_Permission_Breadcrumb_Label_1', 'Admin Übersicht'),
      i18nLabel(store.translationLabels, 'Admin_Portal_New_Permission_Breadcrumb_Label_2', 'Person Details'),
      i18nLabel(store.translationLabels, 'Admin_Portal_New_Permission_Breadcrumb_Label_3', 'Neues Recht erstellen'),
    ],
    errorLabels: store.translationLabels,
    userId: parseInt(userId),
    isLoading: store.editPersonalDataPage.isLoading,
    userOptions: store.newPermissionDataStore.users,
  };
}

export default withRouter(connect(mapStateToProps)(NewPermission));
