import React, { ReactElement } from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import { blue } from './../../theme';

export function ZoomArrowRightIcon(props: SvgIconProps): ReactElement {
  const color = props.htmlColor ? props.htmlColor : blue.main;
  return (
    <SvgIcon {...props}>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <path d="M0 0H80V80H0z" transform="translate(-556 -57) translate(556 57)" />
            <path
              fill={color}
              fillRule="nonzero"
              d="M29.74 64.45c-.824.014-1.572-.48-1.884-1.243-.312-.763-.124-1.64.474-2.207l20.46-20.42-20.46-20.47c-.548-.495-.779-1.25-.6-1.967.177-.716.735-1.276 1.451-1.457.716-.18 1.472.048 1.969.594L53 39.16c.379.376.592.887.592 1.42 0 .533-.213 1.044-.592 1.42L31.15 63.87c-.375.372-.882.58-1.41.58z"
              transform="translate(-556 -57) translate(556 57)"
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}
