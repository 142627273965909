import { Breadcrumbs, Button, Card, CardContent, CircularProgress, Container, Grid, Link } from '@material-ui/core';
import React, { ChangeEvent, ReactElement, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';
import { Action } from 'redux';
import ZoomFooter, {
  mapStoreToFooterProps,
  ZoomFooterProps,
} from '../../components/base-page-components/footer/ZoomFooter';
import ZoomHeader, {
  mapStoreToHeaderProps,
  ZoomHeaderProps,
} from '../../components/base-page-components/header/ZoomHeader';
import ZoomTextInput from '../../components/forms/text-input/ZoomTextInput';
import { ZoomArrowLeftIcon } from '../../components/icons/ZoomArrowLeftIcon';
import { EducationDataAction, EducationInterruptAction } from '../../store/education-data/actions';
import { i18nLabel } from '../../store/i18n/helpers';
import { WIAAStore } from '../../store/types';
import { UserDataAction, UserMyEducationDataGetAction } from '../../store/user-data/actions';
import { blue } from '../../theme';
import { NotificationCaseType } from '../../utils/form-data';
import { requiredValidation } from '../../utils/validators';
import './EducationInterrupted.scss';

export interface EducationInterruptedLabels {
  labelTitle?: string;
  labelDescription?: string;
  labelActualEducationSubTitle?: string;
  labelEducationType?: string;
  labelEducationFacilityName?: string;
  labelEducationFacilityLocation?: string;
  labelDuration?: string;
  labelEducationInterruptedReason?: string;
  breadcrumbLabels?: Array<string>;
  headerProps: ZoomHeaderProps;
  footerProps: ZoomFooterProps;
  labelBackArrow?: string;
  labelCancel?: string;
  labelSave?: string;
  errorLabels?: { [key: string]: string };
}

export interface EducationInterruptedProps extends EducationInterruptedLabels {
  dossierId: number;
  educationType?: string;
  educationFacilityName?: string;
  educationFacilityLocation?: string;
  educationDurationStart?: string;
  educationDurationEnd?: string;
  educationInterruptedReason?: string;
  educationForeignKeyId?: number;
  isLoading?: boolean;
  dispatch?: (action: Action) => void;
}

interface EducationInterruptedErrors {
  educationInterruptedReason?: string;
}

export const EducationInterrupted = (props: EducationInterruptedProps): ReactElement => {
  const educationType = props.educationType ? props.educationType : '0';
  const educationFacilityName = props.educationFacilityName ? props.educationFacilityName : '';
  const educationFacilityLocation = props.educationFacilityLocation ? props.educationFacilityLocation : '';
  const educationDurationStart = props.educationDurationStart ? props.educationDurationStart : undefined;
  const educationDurationEnd = props.educationDurationEnd ? props.educationDurationEnd : undefined;
  const [educationInterruptedReason, setEducationInterruptedReason] = useState(
    props.educationInterruptedReason ? props.educationInterruptedReason : '',
  );
  const initialErrors: EducationInterruptedErrors = {};
  const [errors, setErrors] = useState(initialErrors);
  const history = useHistory();

  useEffect(() => {
    if (props.dispatch) {
      const getUserMyEducationData: UserMyEducationDataGetAction = {
        type: UserDataAction.GET_USER_EDUCATION_DATA,
        payload: props.dossierId,
      };
      props.dispatch(getUserMyEducationData);
    }
  }, []);

  useEffect(() => {
    setEducationInterruptedReason(props.educationInterruptedReason ? props.educationInterruptedReason : '');
  }, [props]);

  const onChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setEducationInterruptedReason(e.target.value);
    setErrors({
      ...errors,
      educationInterruptedReason: requiredValidation(e.target.value, props.errorLabels ? props.errorLabels : {}),
    });
  };

  const getFormErrors = (): EducationInterruptedErrors => {
    const errors: EducationInterruptedErrors = {};
    errors.educationInterruptedReason = requiredValidation(
      educationInterruptedReason,
      props.errorLabels ? props.errorLabels : {},
    );
    return errors;
  };

  const validateForm = (): boolean => {
    const newErrors = getFormErrors();
    setErrors(newErrors);
    const errorsPresent = Object.values(newErrors).some((value) => value !== '');
    return !errorsPresent;
  };

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    if (validateForm()) {
      //history.goBack();
      if (props.dispatch) {
        const interruptedAction: EducationInterruptAction = {
          type: EducationDataAction.INTERRUPT,
          payload: {
            typeId: NotificationCaseType.FORMATIONINTERRUPTION,
            subject: 'INTERRUPT REQUEST',
            body: educationInterruptedReason,
            foreignKeyId: props.educationForeignKeyId,
          },
          dossierId: props.dossierId,
        };
        props.dispatch(interruptedAction);
      }
    }
    event.preventDefault();
  };

  return (
    <>
      <ZoomHeader {...props.headerProps} dispatch={props.dispatch} />
      <main className="main">
        {props.isLoading ? (
          <div style={{ textAlign: 'center' }}>
            <CircularProgress></CircularProgress>
          </div>
        ) : (
          <>
            <Container>
              <div id="main-page-container">
                <Breadcrumbs separator=">" aria-label="breadcrumb">
                  <Link
                    style={{ fontFamily: 'Proxima Nova Cond' }}
                    color="inherit"
                    href={'/dashboard/dossier/' + props.dossierId}
                  >
                    {props.breadcrumbLabels && props.breadcrumbLabels[0]}
                  </Link>
                  <Link
                    style={{ fontFamily: 'Proxima Nova Cond' }}
                    color="inherit"
                    href={'/dossier-intranet/myformation/' + props.dossierId}
                  >
                    {props.breadcrumbLabels && props.breadcrumbLabels[1]}
                  </Link>
                  <Link style={{ fontFamily: 'Proxima Nova Cond' }} color="inherit">
                    <b>{props.breadcrumbLabels && props.breadcrumbLabels[2]}</b>
                  </Link>
                </Breadcrumbs>
                <div className="main-page-info">
                  <div className="back-button-desktop" onClick={() => history.goBack()}>
                    <ZoomArrowLeftIcon viewBox="0 0 80 80" fontSize="large" htmlColor={blue.main} />
                    {props.labelBackArrow}
                  </div>
                  <h1 className="title-desktop">{props.labelTitle}</h1>
                  <p>{props.labelDescription}</p>
                </div>
                <Card>
                  <CardContent>
                    <form onSubmit={onSubmit}>
                      <div className="label-element-container">
                        <h1>{props.labelActualEducationSubTitle}</h1>
                      </div>
                      <div className="label-element-container">
                        <Grid container direction="row" alignItems="stretch" justify="flex-start">
                          <Grid item xs={12} sm={6}>
                            <div className="label-bottom-border">
                              <label>{props.labelEducationType ? props.labelEducationType : ''}</label>
                            </div>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <div className="label-bottom-border">
                              <label>{educationType}</label>
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                      <div className="label-element-container">
                        <Grid container direction="row" alignItems="stretch" justify="flex-start">
                          <Grid item xs={12} sm={6}>
                            <div className="label-bottom-border">
                              <label>{props.labelEducationFacilityName ? props.labelEducationFacilityName : ''}</label>
                            </div>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <div className="label-bottom-border">
                              <label>{educationFacilityName}</label>
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                      <div className="label-element-container">
                        <Grid container direction="row" alignItems="stretch" justify="flex-start">
                          <Grid item xs={12} sm={6}>
                            <div className="label-bottom-border">
                              <label>
                                {props.labelEducationFacilityLocation ? props.labelEducationFacilityLocation : ''}
                              </label>
                            </div>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <div className="label-bottom-border">
                              <label>{educationFacilityLocation}</label>
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                      <div className="label-element-container">
                        <Grid container direction="row" alignItems="stretch" justify="flex-start">
                          <Grid item xs={12} sm={6}>
                            <div className="label-bottom-border">
                              <label>{props.labelDuration ? props.labelDuration : ''}</label>
                            </div>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <div className="label-bottom-border">
                              <label>{`${educationDurationStart} - ${educationDurationEnd}`}</label>
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                      <div className="label-element-container interrupted-education-reason">
                        <ZoomTextInput
                          label={props.labelEducationInterruptedReason ? props.labelEducationInterruptedReason : ''}
                          name="educationInterruptedReason"
                          value={educationInterruptedReason}
                          multiline
                          onChange={onChange}
                          error={!!errors.educationInterruptedReason}
                          errorMessage={errors.educationInterruptedReason}
                        />
                      </div>
                      <Grid
                        className="label-element-container"
                        container
                        direction="row"
                        spacing={2}
                        style={{ paddingBottom: '1rem' }}
                      >
                        <Grid item xs={6} sm={6}>
                          <div className="form-actions-container-cancel">
                            <Button
                              color="primary"
                              size="large"
                              type="reset"
                              variant="outlined"
                              id="cancel-interrupted-education-data"
                              style={{ width: '144px' }}
                              onClick={() => history.goBack()}
                            >
                              {props.labelCancel}
                            </Button>
                          </div>
                        </Grid>
                        <Grid item xs={6} sm={6}>
                          <div className="form-actions-container-save">
                            <Button
                              color="primary"
                              size="large"
                              type="submit"
                              variant="contained"
                              id="save-interrupted-education-data"
                              style={{ width: '144px' }}
                            >
                              {props.labelSave}
                            </Button>
                          </div>
                        </Grid>
                      </Grid>
                    </form>
                  </CardContent>
                </Card>
              </div>
            </Container>
          </>
        )}
      </main>
      <ZoomFooter {...props.footerProps} />
    </>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export function mapStateToProps(store: WIAAStore, urlProps: any): EducationInterruptedProps {
  const { dossierId } = urlProps.match.params;
  const headerProps: ZoomHeaderProps = mapStoreToHeaderProps(store, dossierId);
  const footerProps: ZoomFooterProps = mapStoreToFooterProps(store);

  const currentFormation = store.userMyEducationData.formations?.find((f) => f.currentFormation);
  return {
    dossierId,
    headerProps,
    footerProps,
    labelTitle: i18nLabel(store.translationLabels, 'Education_Interrupted_Data_Title', 'Ausbildung unterbrochen'),
    labelDescription: i18nLabel(
      store.translationLabels,
      'Education_Interrupted_Data_Description',
      'Falls Sie Ihre Ausbildung unterbrochen haben, bitten wir Sie, uns den Grund und das Datum ab Beginn bis Ende des Unterbruches bekannt zu geben.',
    ),
    labelActualEducationSubTitle: i18nLabel(
      store.translationLabels,
      'Education_Interrupted_Data_Sub_Title',
      'Aktuelle Ausbildung',
    ),
    labelEducationType: i18nLabel(store.translationLabels, 'Education_Interrupted_Data_Type', 'Art der Ausbildung'),
    labelEducationFacilityName: i18nLabel(
      store.translationLabels,
      'Education_Interrupted_Data_Facility_Name',
      'Name der Ausbildungsstätte',
    ),
    labelEducationFacilityLocation: i18nLabel(
      store.translationLabels,
      'Education_Interrupted_Data_Facility_Location',
      'Ort der Ausbildungsstätte',
    ),
    labelDuration: i18nLabel(store.translationLabels, 'Education_Interrupted_Data_Duration', 'Ausbildungsdauer'),
    labelEducationInterruptedReason: i18nLabel(
      store.translationLabels,
      'Education_Interrupted_Data_Reason',
      'Grund für den Unterbruch*',
    ),
    labelBackArrow: i18nLabel(store.translationLabels, 'Education_Interrupted_Data_Back_Arrow', 'Zurück'),
    labelCancel: i18nLabel(store.translationLabels, 'Education_Interrupted_Data_Cancel', 'Abbrechen'),
    labelSave: i18nLabel(store.translationLabels, 'Education_Interrupted_Data_Save', 'Absenden'),
    breadcrumbLabels: [
      i18nLabel(store.translationLabels, 'Education_Interrupted_Data_Breadcrumb_Label_1', 'Übersicht'),
      i18nLabel(store.translationLabels, 'Education_Interrupted_Data_Breadcrumb_Label_2', 'Meine Ausbildung'),
      i18nLabel(store.translationLabels, 'Education_Interrupted_Data_Breadcrumb_Label_3', 'Ausbildung unterbrochen'),
    ],
    errorLabels: store.translationLabels,
    educationType: currentFormation?.formationArt ? currentFormation.formationArt : '',
    educationDurationStart: currentFormation?.formationStartDate ? currentFormation.formationStartDate : undefined,
    educationDurationEnd: currentFormation?.formationEndDate ? currentFormation.formationEndDate : undefined,
    educationFacilityName: currentFormation?.formationInstitution,
    educationFacilityLocation: currentFormation?.formationLocation,
    educationForeignKeyId: currentFormation?.formationDbKey,
    isLoading: store.userMyEducationData.isLoading,
  };
}

export default withRouter(connect(mapStateToProps)(EducationInterrupted));
