import { Observable } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { Epic, ofType } from 'redux-observable';

import { DemoAction, DemoSetDescriptionWithTimeoutAction, DemoSetTitleWithTimeoutAction } from './actions';
import { Action } from 'redux';

//
// Simple demo epic that triggers title change after a timeout of 1000 milliseconds
// All async operations shall be done with the help of epics
//
export const demoSetTitleTimeoutEpic: Epic<Action, Action> = (action$: Observable<Action>): Observable<Action> =>
  action$.pipe(
    ofType(DemoAction.SET_TITLE_TIMEOUT),
    debounceTime(1000),
    map((action) => {
      const origin = action as DemoSetTitleWithTimeoutAction;
      return { type: DemoAction.SET_TITLE, payload: origin.payload };
    }),
  );

export const demoSetDescriptionTimeoutEpic: Epic<Action, Action> = (action$: Observable<Action>): Observable<Action> =>
  action$.pipe(
    ofType(DemoAction.SET_DESCRIPTION_TIMEOUT),
    debounceTime(1000),
    map((action) => {
      const origin = action as DemoSetDescriptionWithTimeoutAction;
      return { type: DemoAction.SET_DESCRIPTION, payload: origin.payload };
    }),
  );
