import produce from 'immer';
import {
  PersonDetailsDataAction,
  PersonDetailsActionType,
  PersonDetailsDataGetResultAction,
  PersonDetailsDataEditResultPersonDetailsAction,
  PersonDetailsDeleteResultPermissionsDataAction,
  PersonDetailsEditResultPermissionsDataAction,
  PersonDetailsManualDemandActivationResultAction,
} from './actions';
import { PersonDetailsDataStore } from './types';

export const initialState: PersonDetailsDataStore = {
  user: {},
  permissions: [],
};

export function personDetailsDataReducer(
  state = initialState,
  action: PersonDetailsActionType,
): PersonDetailsDataStore {
  return produce(state, (draft) => {
    switch (action.type) {
      case PersonDetailsDataAction.GET:
        draft.isLoading = true;
        break;
      case PersonDetailsDataAction.GET_RESULT:
        draft.user = (action as PersonDetailsDataGetResultAction).user;
        draft.permissions = (action as PersonDetailsDataGetResultAction).permissions;
        draft.canActivateDemand = (action as PersonDetailsDataGetResultAction).canActivateDemand;
        draft.ownedDossierId = (action as PersonDetailsDataGetResultAction).ownedDossierId;
        draft.isLoading = false;
        draft.auditList = (action as PersonDetailsDataGetResultAction).auditList;
        break;
      case PersonDetailsDataAction.EDIT_RESULT_PERSON_DETAILS:
        const editedPersonDetailsData = (action as PersonDetailsDataEditResultPersonDetailsAction).payload;
        draft.user = editedPersonDetailsData;
        break;
      case PersonDetailsDataAction.EDIT_RESULT_PERMISSIONS_PERSON_DETAILS:
        const editedPermissionData = (action as PersonDetailsEditResultPermissionsDataAction).payload;
        draft.permissions = state.permissions.map((permission) => {
          if (permission.id === editedPermissionData.id) {
            return editedPermissionData;
          }
          return permission;
        });
        break;
      case PersonDetailsDataAction.DELETE_RESULT_PERMISSIONS_PERSON_DETAILS:
        const id = (action as PersonDetailsDeleteResultPermissionsDataAction).payload;
        draft.permissions = state.permissions.filter((permission) => permission.id !== id);
        break;
      case PersonDetailsDataAction.MANUAL_DEMAND_ACTIVATION_RESULT:
        const manualDemandActivation = (action as PersonDetailsManualDemandActivationResultAction).payload;
        draft.canActivateDemand = manualDemandActivation;
        break;
    }
    return draft;
  });
}
