import { CircularProgress } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import { NavigateNext } from '@material-ui/icons';
import React, { ReactElement, useEffect } from 'react';
import { Action } from 'redux';
import ZoomDebtInfo, { ZoomDebtInfoProps } from '../../components/base-page-components/debt/ZoomDebtInfo';
import ZoomFooter, { ZoomFooterProps } from '../../components/base-page-components/footer/ZoomFooter';
import ZoomHeader, { ZoomHeaderProps } from '../../components/base-page-components/header/ZoomHeader';
import ZoomNotification, {
  ZoomNotificationProps,
} from '../../components/base-page-components/notification/ZoomNotification';
import ZoomPayoutInfo, {
  ZoomPayoutInfoProps,
} from '../../components/base-page-components/payout/payout-info/ZoomPayoutInfo';
import { ZoomScholarshipInfo } from '../../components/base-page-components/scholarship/scholarship-info/ZoomScholarshipInfo';
import { ZoomScholarshipItemProps } from '../../components/base-page-components/scholarship/scholarship-item/ZoomScholarshipItem';
import ZoomUserInfo, { ZoomUserInfoProps } from '../../components/base-page-components/user/user-info/ZoomUserInfo';
import { AuthoritiesDataAction, AuthoritiesDataGetAction } from '../../store/authorities/actions';
import { HolderDataAction, HolderDataGetAction } from '../../store/holder-data/actions';
import { MyDataOverviewPageAction, MyDataOverviewPageGetAction } from '../../store/my-data/actions';
import { MyDemandPageAction, MyDemandPageGetAction } from '../../store/my-demand/actions';
import { MyLoanAction, MyLoanGetAction } from '../../store/my-loan/actions';
import { CanCreateNewDemandAction, UserDataAction, UserDataGetActiveDemandAction } from '../../store/user-data/actions';
import { UserMyDataGetAction, UserMyEducationDataGetAction } from '../../store/user-data/actions';
import { HolderDropDownProps } from '../../components/base-page-components/holder-dropdown/HolderDropDown';
import './Dashboard.scss';
import { NavigationItems } from '../../components/base-page-components/navigation/Navigation';

export interface DashboardMessage {
  type: string;
  title: string;
  action?: string;
}

export interface UserDemandData {
  isActive: boolean;
  pifId: number;
  id: number;
  requestPeriodStartDate?: Date;
  requestPeriodEndDate?: Date;
}

export interface DashboardProps {
  title: string;
  labelAllScholarships: string;
  labelAllPayouts: string;
  labelAllContracts: string;
  labelCurrentScholarhipInfo: string;
  labelNewScholarship: string;
  labelDownloadDemandSummary: string;
  labelContinueDemandEdition: string;
  labelDemandSuccessfullyUploadedNotYetImported: string;
  labelDemandSuccessfullyUploaded: string;
  labelDemandSuccessfullyUploadedSuffix: string;
  demandYear: string;
  labelDemandDetails: string;
  labelBackArrow: string;
  labelDebt?: string;
  debtStatus?: string;
  messages?: Array<DashboardMessage>;
  userInfo: ZoomUserInfoProps;
  debtInfo?: ZoomDebtInfoProps;
  payoutInfo: ZoomPayoutInfoProps;
  dossierId: number;
  scholarships: Array<ZoomScholarshipItemProps>;
  headerProps: ZoomHeaderProps;
  footerProps: ZoomFooterProps;
  notifications: Array<ZoomNotificationProps>;
  canCreateNewDemand: boolean;
  streamId?: string;
  demandStatus?: number;
  labelGoToAction?: string;
  labelMarkAsRead?: string;
  labelDebtInfoTitle: string;
  labelDebtInfoSubtitle: string;
  labelDebtInfoLegendRepayment: string;
  labelDebtInfoLegendStatus: string;
  labelDebtInfoTotalAmount: string;
  holderDropDownProps: HolderDropDownProps;
  infoText?: string;
  isLoading?: boolean;
  dispatch?: (action: Action) => void;
}

export const Dashboard = (props: DashboardProps): ReactElement => {
  const scholarshipsWithCorrectLabels = props.scholarships.map((s) => {
    return { ...s, labelContinueFillin: props.labelContinueDemandEdition, labelDetails: props.labelDemandDetails };
  });

  useEffect(() => {
    if (props.dispatch) {
      const getUserMyData: UserMyDataGetAction = {
        type: UserDataAction.GET_USER_MY_DATA,
        payload: props.dossierId,
      };
      props.dispatch(getUserMyData);
    }
  }, []);

  useEffect(() => {
    if (props.dispatch && props.dossierId) {
      const getActiveDemand: UserDataGetActiveDemandAction = {
        type: UserDataAction.GET_ACTIVE_DEMAND,
        dossierId: props.dossierId,
      };
      props.dispatch(getActiveDemand);
    }
  }, []);

  useEffect(() => {
    if (props.dispatch && props.dossierId) {
      const getHolderData: HolderDataGetAction = {
        type: HolderDataAction.GET,
      };
      props.dispatch(getHolderData);
    }
  }, []);

  useEffect(() => {
    if (props.dispatch) {
      const getUserMyEducationData: UserMyEducationDataGetAction = {
        type: UserDataAction.GET_USER_EDUCATION_DATA,
        payload: props.dossierId,
      };
      props.dispatch(getUserMyEducationData);
    }
  }, []);

  useEffect(() => {
    if (props.dispatch) {
      const getMyData: MyDataOverviewPageGetAction = {
        type: MyDataOverviewPageAction.GET,
        payload: props.dossierId,
      };
      props.dispatch(getMyData);
    }
  }, []);

  useEffect(() => {
    if (props.dispatch) {
      const getMyPowerOfAttorneys: AuthoritiesDataGetAction = {
        type: AuthoritiesDataAction.GET,
        dossierId: props.dossierId,
      };
      props.dispatch(getMyPowerOfAttorneys);
    }
  }, []);

  useEffect(() => {
    if (props.dispatch) {
      const getUserMyDemand: MyDemandPageGetAction = {
        type: MyDemandPageAction.GET,
        payload: props.dossierId,
      };
      props.dispatch(getUserMyDemand);
    }
  }, []);

  useEffect(() => {
    if (props.dispatch) {
      const canUserCreateNewDemand: CanCreateNewDemandAction = {
        type: UserDataAction.CAN_CREATE_NEW_DEMAND,
        payload: props.dossierId,
      };
      props.dispatch(canUserCreateNewDemand);
    }
  }, []);

  useEffect(() => {
    if (props.dispatch) {
      const getUserMyLoan: MyLoanGetAction = {
        type: MyLoanAction.GET,
        payload: props.dossierId,
      };
      props.dispatch(getUserMyLoan);
    }
  }, []);

  if (props.dossierId === undefined) {
    return <></>;
  }

  const notificationElements = props.notifications.map((notification, index) => (
    <ZoomNotification
      id={notification.id}
      labelText={notification.labelText}
      labelButtonText={props.labelMarkAsRead}
      labelGoToActionText={props.labelGoToAction}
      key={index}
      dossierId={props.dossierId}
      clientEventIds={notification.clientEventIds}
      isSuccess={notification.isSuccess}
      isWarning={notification.isWarning}
      isError={notification.isError}
      dispatch={props.dispatch}
    />
  ));
  return (
    <>
      <ZoomHeader {...props.headerProps} dispatch={props.dispatch} />
      <main className="main" id="main">
        {props.isLoading ? (
          <div style={{ textAlign: 'center' }}>
            <CircularProgress />
          </div>
        ) : (
          <>
            <Container disableGutters={window.innerWidth <= 960}>{notificationElements}</Container>
            <Container>
              <div id="main-page-container">
                <NavigationItems
                  title={props.title}
                  labelBackArrow={props.labelBackArrow}
                  holderDropDownProps={props.holderDropDownProps}
                  dispatch={props.dispatch}
                  mainPageTopInfo={true}
                />
                <div className="heading-wrapper clearfix">
                  <div className="main-content-limited">
                    <div className="dashboard-info" id="scholarship-info">
                      <ZoomScholarshipInfo
                        scholarships={scholarshipsWithCorrectLabels}
                        dossierId={props.dossierId}
                        title={props.labelCurrentScholarhipInfo}
                        labelNewScholarship={props.labelNewScholarship}
                        labelDetails={props.labelDemandDetails}
                        labelContinueFillin={props.labelContinueDemandEdition}
                        labelDownloadDemandSummary={props.labelDownloadDemandSummary}
                        labelDemandSuccessfullyUploadedNotYetImported={
                          props.labelDemandSuccessfullyUploadedNotYetImported
                        }
                        labelDemandSuccessfullyUploaded={props.labelDemandSuccessfullyUploaded}
                        labelDemandSuccessfullyUploadedSuffix={props.labelDemandSuccessfullyUploadedSuffix}
                        demandYear={props.demandYear}
                        canCreateNewDemand={props.canCreateNewDemand}
                        streamId={props.streamId}
                        demandStatus={props.demandStatus}
                        dispatch={props.dispatch}
                      />
                    </div>
                    <div className="dashboard-links">
                      <NavigateNext />
                      <a href={'/my-data/dossier-intranet/mydemand/' + props.dossierId}>{props.labelAllPayouts}</a>
                    </div>
                    {props.payoutInfo.payoutLoan && props.payoutInfo.payoutScholarship && (
                      <>
                        <div className="dashboard-info" id="finances-info">
                          <ZoomPayoutInfo {...props.payoutInfo} />
                        </div>

                        <div className="dashboard-links">
                          <NavigateNext />
                          <a href={'/my-data/dossier-intranet/mydemand/' + props.dossierId}>{props.labelAllPayouts}</a>
                        </div>
                      </>
                    )}
                    {props.debtInfo && props.debtInfo.totalAmount > 0 && (
                      <>
                        <div className="dashboard-info" id="debt-info">
                          <ZoomDebtInfo
                            {...props.debtInfo}
                            title={props.labelDebtInfoTitle}
                            subtitle={props.labelDebtInfoSubtitle}
                            labelLegendRepayment={props.labelDebtInfoLegendRepayment}
                            labelLegendStatus={props.labelDebtInfoLegendStatus}
                            labelTotalAmount={props.labelDebtInfoTotalAmount}
                            infoText={props.infoText}
                          />
                        </div>
                        <div className="dashboard-links">
                          <NavigateNext />
                          <a href={`/my-contracts/${props.dossierId}`}>{props.labelAllContracts}</a>
                        </div>
                      </>
                    )}
                  </div>
                  <div className="user-info">
                    <ZoomUserInfo {...props.userInfo} />
                  </div>
                </div>
              </div>
            </Container>
          </>
        )}
      </main>
      <ZoomFooter {...props.footerProps} />
    </>
  );
};
