import React, { ChangeEvent, FormEvent, ReactElement, useState } from 'react';
import { Button, TextField } from '@material-ui/core';
import { Action } from 'redux';
import './NotesForm.scss';
import { NotesDataAction, NotesDataSubmitAction } from '../../store/notes-data/actions';
import { i18nLabel } from '../../store/i18n/helpers';
import { WIAAStore } from '../../store/types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

export interface NotesFormProps {
  notes?: string;
  labelSave: string;
  labelPlaceholder: string;
  demandId: number;
  dispatch?: (action: Action) => void;
}

export const NotesForm = (props: NotesFormProps): ReactElement => {
  const [notes, setNotes] = useState(props.notes ? props.notes : '');

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    if (props.dispatch) {
      const submitAction: NotesDataSubmitAction = {
        type: NotesDataAction.SUBMIT,
        payload: { comment: notes },
        demandId: props.demandId,
      };
      props.dispatch(submitAction);
    }
    e.preventDefault();
  };

  const onChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setNotes(e.target.value);
  };

  return (
    <form onSubmit={onSubmit} id="notes-form">
      <div className="form-element-container">
        <TextField
          onChange={onChange}
          fullWidth
          id="notes-textarea"
          value={notes}
          variant="outlined"
          name="notes"
          placeholder={props.labelPlaceholder}
          multiline
        />
      </div>
      <div className="form-actions-container">
        <Button color="primary" type="submit" variant="contained" id="submit-notes-data">
          {props.labelSave}
        </Button>
      </div>
    </form>
  );
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
export const mapStateToNotesProps = (store: WIAAStore, ownProps: any): NotesFormProps => {
  const { demandId } = ownProps.match.params;
  const props: NotesFormProps = {
    demandId,
    labelSave: i18nLabel(store.translationLabels, 'Notes_Save', 'Speichern'),
    labelPlaceholder: i18nLabel(store.translationLabels, 'Notes_Placeholder', 'Bemerkungen'),
    notes: store.notesData.notes,
  };
  return props;
};

// Default export the connected component
export default withRouter(connect(mapStateToNotesProps)(NotesForm));
