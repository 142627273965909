import { PostalCode } from '../contact-data/types';
import { FatherData } from './types';

export enum FatherDataAction {
  GET = 'FATHER_DATA_GET',
  GET_RESULT = 'FATHER_DATA_GET_RESULT',
  CHANGE = 'FATHER_DATA_CHANGE',
  SUBMIT = 'FATHER_DATA_SUBMIT',
  RESULT = 'FATHER_DATA_RESULT',
  NON_EXISTENT_FATHER = 'NON_EXISTENT_FATHER',
  GET_POSTAL_CODES = 'FATHER_DATA_GET_POSTAL_CODES',
  GET_POSTAL_CODES_RESULT = 'FATHER_DATA_GET_POSTAL_CODES_RESULT',
  GET_POSTAL_CODES_ERROR = 'FATHER_DATA_GET_POSTAL_CODES_ERROR',
}

export interface FatherDataGetAction {
  type: typeof FatherDataAction.GET;
  payload: FatherData;
  pifId: number;
}

export interface FatherDataGetResultAction {
  type: typeof FatherDataAction.GET_RESULT;
  payload: FatherData;
}

export interface FatherDataChangeAction {
  type: typeof FatherDataAction.CHANGE;
  payload: string;
  changeField: string;
}

export interface FatherDataSubmitAction {
  type: typeof FatherDataAction.SUBMIT;
  payload: FatherData;
  pifId: number;
}

export interface FatherDataResultAction {
  type: typeof FatherDataAction.RESULT;
  payload: FatherData;
}

export interface FatherDataGetPostalCodesAction {
  type: typeof FatherDataAction.GET_POSTAL_CODES;
  payload: FatherData;
}

export interface FatherDataGetPostalCodesResultAction {
  type: typeof FatherDataAction.GET_POSTAL_CODES_RESULT;
  payload: PostalCode;
}

export interface FatherDataGetPostalCodesErrorAction {
  type: typeof FatherDataAction.GET_POSTAL_CODES_ERROR;
  payload: string;
}

export type FatherDataActionType =
  | FatherDataGetAction
  | FatherDataGetResultAction
  | FatherDataChangeAction
  | FatherDataSubmitAction
  | FatherDataResultAction
  | FatherDataGetPostalCodesAction
  | FatherDataGetPostalCodesResultAction
  | FatherDataGetPostalCodesErrorAction;
