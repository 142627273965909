import { Action } from 'redux';
import { Epic, ofType } from 'redux-observable';
import { Observable, of } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { customAjax } from '../../utils/ajax-wrapper';
import { handleRequestFailureInEpic } from '../../utils/error-handling';
import { MyLoanAction, MyLoanGetAction, MyLoanGetResultAction } from './actions';

const addMyLoanData = (payload: AjaxResponse): MyLoanGetResultAction => {
  return {
    type: MyLoanAction.GET_RESULT,
    payload: payload.response,
  };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getMyLoanDataOnError = (error: any): any => {
  if (error.status === 404) {
    return { type: MyLoanAction.NON_EXISTENT_MY_LOAN_DATA };
  }
  return handleRequestFailureInEpic(error);
};

export const getMyLoanDataEpic: Epic<Action, Action> = (action$: Observable<Action>): Observable<Action> =>
  action$.pipe(
    ofType(MyLoanAction.GET),
    mergeMap((action) => {
      const dossierId = (action as MyLoanGetAction).payload;
      return customAjax({
        subRoute: `/dossier-intranet/v1/myloan/${dossierId}/dashboard`,
        withCredentials: true,
        method: 'GET',
        headers: {
          'Accept-Language': 'de-CH',
        },
      }).pipe(
        map((response) => addMyLoanData(response)),
        catchError((error) => {
          return of(getMyLoanDataOnError(error));
        }),
      );
    }),
  );
