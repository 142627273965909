import produce from 'immer';
import { EditContactDataPageAction, EditContactDataPageActionType } from './actions';
import { EditContactDataPage } from './types';

export const initialState: EditContactDataPage = {};

export function editContactDataPageReducer(
  state = initialState,
  action: EditContactDataPageActionType,
): EditContactDataPage {
  return produce(state, (draft) => {
    switch (action.type) {
      case EditContactDataPageAction.GET:
        draft.isLoading = true;
        break;
      case EditContactDataPageAction.RESULT:
        draft = action.payload;
        break;
      case EditContactDataPageAction.GET_RESULT:
        draft = action.payload;
        draft.isLoading = false;
        break;
    }
    return draft;
  });
}
