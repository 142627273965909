import React, { ReactElement } from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import { black } from '../../theme';

export function ZoomNotificationIcon(props: SvgIconProps): ReactElement {
  const color = props.htmlColor ? props.htmlColor : black.main;
  return (
    <SvgIcon {...props}>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <path d="M0 0H80V80H0z" transform="translate(-56 -357) translate(56 357)" />
            <path
              fill={color}
              fillRule="nonzero"
              d="M40.6 16.48c-13.255 0-24 10.745-24 24s10.745 24 24 24 24-10.745 24-24-10.745-24-24-24zm0 44c-11.046 0-20-8.954-20-20s8.954-20 20-20 20 8.954 20 20c.008 5.31-2.096 10.404-5.847 14.162C51 58.399 45.91 60.51 40.6 60.51v-.03z"
              transform="translate(-56 -357) translate(56 357)"
            />
            <path
              fill={color}
              fillRule="nonzero"
              d="M51.29 37c-.011-2.737-1.168-5.345-3.19-7.19-2.041-1.886-4.72-2.929-7.5-2.92-5.9 0-10.69 4.54-10.69 10.11v5.83h-2v4H53.3v-4h-2L51.29 37zm-17.38 5.79V37c0-3.37 3-6.11 6.69-6.11 1.766-.007 3.47.654 4.77 1.85 1.208 1.089 1.904 2.634 1.92 4.26v5.83l-13.38-.04zM40.6 50.53c-.773 0-1.4-.627-1.4-1.4h-3c0 2.43 1.97 4.4 4.4 4.4 2.43 0 4.4-1.97 4.4-4.4h-3c0 .773-.627 1.4-1.4 1.4z"
              transform="translate(-56 -357) translate(56 357)"
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}
