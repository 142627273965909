import { Button } from '@material-ui/core';
import React, { ReactElement } from 'react';
import {
  UserDataAction,
  UserDataActionType,
  UserDataLaunchCreateNewDemandAction,
} from '../../../../store/user-data/actions';
import { darkBlue } from '../../../../theme';
import { DefaultDemand } from '../../../../utils/default-values';
import { ZoomScholarshipIcon } from '../../../icons/ZoomScholarshipIcon';
import { ZoomScholarshipItem, ZoomScholarshipItemProps } from '../scholarship-item/ZoomScholarshipItem';
import './ZoomScholarshipInfo.scss';

export interface ZoomScholarshipInfoProps {
  scholarships: Array<ZoomScholarshipItemProps>;
  title: string;
  labelNewScholarship: string;
  labelDownloadDemandSummary: string;
  labelContinueFillin: string;
  labelDetails: string;
  labelDemandSuccessfullyUploadedNotYetImported: string;
  labelDemandSuccessfullyUploaded: string;
  labelDemandSuccessfullyUploadedSuffix: string;
  demandYear: string;
  canCreateNewDemand: boolean;
  dossierId: number;
  streamId?: string;
  demandStatus?: number;
  dispatch?: (action: UserDataActionType) => void;
}

export const ZoomScholarshipInfo = (props: ZoomScholarshipInfoProps): ReactElement => {
  const items = props.scholarships.map((scholarshipItem: ZoomScholarshipItemProps) => {
    const combinedPropsrops = { ...scholarshipItem, ...props };
    return <ZoomScholarshipItem {...combinedPropsrops} key={scholarshipItem.name} />;
  });
  const downloadLink = `${process.env.REACT_APP_API_GATEWAY_URL}/dossier-intranet/v1/documents/${props.dossierId}/demand-summary?streamId=${props.streamId}`;
  const demandNotImportedStatus = 8;

  const openNewDemand = () => {
    if (props.dispatch) {
      const createNewDemandAction = {
        type: UserDataAction.LAUNCH_CREATE_NEW_DEMAND,
        dossierId: props.dossierId,
        newDemand: DefaultDemand,
      } as UserDataLaunchCreateNewDemandAction;
      props.dispatch(createNewDemandAction);
    }
  };

  return (
    <>
      <div id="scholarship-info-header">
        <div>
          <ZoomScholarshipIcon id="scholarship-icon" htmlColor={darkBlue.main} viewBox="0 0 80 80" fontSize="large" />
        </div>
        <div id="scholarship-info-title">{props.title}</div>
      </div>
      {items}
      <div className="scholarship-info-content">
        <div className="scholarship-info-controls clearfix">
          {!props.canCreateNewDemand &&
            props.demandYear &&
            props.demandStatus &&
            props.demandStatus > demandNotImportedStatus && (
              <p>
                {props.labelDemandSuccessfullyUploaded} {props.demandYear} {props.labelDemandSuccessfullyUploadedSuffix}
              </p>
            )}
          {!props.canCreateNewDemand &&
            props.demandYear &&
            (!props.demandStatus || props.demandStatus <= demandNotImportedStatus) && (
              <p>{props.labelDemandSuccessfullyUploadedNotYetImported}</p>
            )}
          <div className="scholarship-info-btns">
            {items.length === 0 && props.canCreateNewDemand && (
              <p className="scholarship-info-btn-block">
                <Button
                  id="open-new-demand"
                  className="scholarship-info-btn"
                  variant="contained"
                  color="primary"
                  onClick={openNewDemand}
                >
                  {props.labelNewScholarship}
                </Button>
              </p>
            )}
            {!props.canCreateNewDemand && props.demandYear && (
              <>
                <p className="scholarship-info-btn-block">
                  <Button
                    id="download-demand-summary-pdf"
                    className="scholarship-info-btn"
                    variant="contained"
                    color="primary"
                    disabled={!props.streamId}
                    download
                    href={downloadLink}
                  >
                    {props.labelDownloadDemandSummary}
                  </Button>
                </p>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
