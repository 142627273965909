import { ajax, AjaxResponse } from 'rxjs/ajax';
import { Observable, Subscriber } from 'rxjs';

/***
 * Custom Ajax object for cors
 * ajax queries
 */
export interface CustomAjaxObject {
  subRoute: string;
  method: string;
  withCredentials: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  body?: any;
  headers?: Record<string, string | boolean | number>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  progressSubscriber?: Subscriber<any>;
}

/***
 * Simple ajax queries handler
 * for cors
 * @param customAjaxObject
 */
export const customAjax = (customAjaxObject: CustomAjaxObject): Observable<AjaxResponse> => {
  const { withCredentials, method, body, progressSubscriber, headers } = customAjaxObject;
  const url = `${process.env.REACT_APP_API_GATEWAY_URL}${customAjaxObject.subRoute}`;
  return ajax({
    url,
    crossDomain: false,
    withCredentials,
    method,
    headers,
    body,
    progressSubscriber,
  });
};
