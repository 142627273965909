import React, { ReactElement, useEffect, useState } from 'react';
import './ZoomProgressBar.scss';

export interface ZoomProgressBarProps {
  labelFinished: string;
  progressPercentage?: number;
  id?: string;
}

export const ZoomProgressBar = (props: ZoomProgressBarProps): ReactElement => {
  const [progressPercentage, setProgressPercentage] = useState(props.progressPercentage ? props.progressPercentage : 0);
  const [style, setStyle] = useState({});

  setTimeout(() => {
    const newStyle = {
      opacity: 1,
      width: `${props.progressPercentage}%`,
    };
    setStyle(newStyle);
  }, 200);

  useEffect(() => {
    setProgressPercentage(props.progressPercentage ? props.progressPercentage : 0);
  }, [props]);

  return (
    <>
      <div className="progress" id={props.id}>
        {progressPercentage === 0 ? '0%' : ''}
        <div className="progress-done" style={style}>
          {progressPercentage === 100 ? props.labelFinished : progressPercentage.toFixed(0) + '%'}
        </div>
      </div>
    </>
  );
};
