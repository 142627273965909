import React, { ReactElement } from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import { black } from './../../theme';

export function ZoomPersonIcon(props: SvgIconProps): ReactElement {
  const color = props.htmlColor ? props.htmlColor : black.main;
  return (
    <SvgIcon {...props}>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <path d="M0 0H80V80H0z" transform="translate(-948 -157) translate(948 157)" />
            <path
              fill={color}
              fillRule="nonzero"
              d="M40.63 64.32c-6.95.017-13.626-2.706-18.58-7.58-.53-.53-.83-1.25-.83-2v-7c.011-4.556 3.704-8.245 8.26-8.25h4.77c1.09 0 2.08.638 2.53 1.63l3.72 8.18 3.71-8.17c.453-.99 1.44-1.627 2.53-1.63h4.77c4.558 0 8.254 3.692 8.26 8.25V55c-.002.756-.312 1.479-.86 2-4.921 4.706-11.47 7.329-18.28 7.32zM29.48 41.5c-3.453 0-6.254 2.797-6.26 6.25v7c-.002.212.085.415.24.56 9.434 9.246 24.496 9.36 34.07.26.153-.146.24-.349.24-.56v-7.26c-.006-3.453-2.807-6.25-6.26-6.25h-4.77c-.325 0-.618.198-.74.5l-5.5 12.14L35 42c-.128-.278-.404-.457-.71-.46l-4.81-.04zM40.6 36.34c-3.997.004-7.603-2.4-9.135-6.092-1.532-3.692-.69-7.943 2.135-10.77 2.825-2.828 7.075-3.676 10.769-2.147 3.693 1.529 6.101 5.132 6.101 9.129-.005 5.45-4.42 9.869-9.87 9.88zm0-17.75c-3.189-.004-6.066 1.914-7.288 4.86-1.222 2.945-.549 6.337 1.706 8.592 2.255 2.255 5.647 2.928 8.592 1.706 2.946-1.222 4.864-4.099 4.86-7.288-.006-4.344-3.526-7.864-7.87-7.87z"
              transform="translate(-948 -157) translate(948 157)"
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}
