import { DemandData } from '../store/user-data/types';
import moment from 'moment';

export const DefaultDemand: DemandData = {
  requestPeriodStartDate: moment({
    year: moment().year(),
    month: 7,
    day: 1,
    hours: 12,
  }).toDate(),
  requestPeriodEndDate: moment({
    year: moment().year() + 1,
    month: 6,
    day: 31,
    hours: 12,
  }).toDate(),
  requestInitialType: 1,
  completedEducation: false,
  financiallyIndependent2Years: false,
  employedFinanciallyIndependent6Years: false,
  citizenInCanton2Years: false,
};
