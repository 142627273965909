import { PostalCode } from '../contact-data/types';
import { MotherData } from './types';

export enum MotherDataAction {
  GET = 'MOTHER_DATA_GET',
  GET_RESULT = 'MOTHER_DATA_GET_RESULT',
  CHANGE = 'MOTHER_DATA_CHANGE',
  SUBMIT = 'MOTHER_DATA_SUBMIT',
  RESULT = 'MOTHER_DATA_RESULT',
  NON_EXISTENT_MOTHER = 'NON_EXISTENT_MOTHER',
  GET_POSTAL_CODES = 'MOTHER_DATA_GET_POSTAL_CODES',
  GET_POSTAL_CODES_RESULT = 'MOTHER_DATA_GET_POSTAL_CODES_RESULT',
  GET_POSTAL_CODES_ERROR = 'MOTHER_DATA_GET_POSTAL_CODES_ERROR',
}

export interface MotherDataGetAction {
  type: typeof MotherDataAction.GET;
  payload: MotherData;
  pifId: number;
}

export interface MotherDataGetResultAction {
  type: typeof MotherDataAction.GET_RESULT;
  payload: MotherData;
}

export interface MotherDataChangeAction {
  type: typeof MotherDataAction.CHANGE;
  payload: string;
  changeField: string;
}

export interface MotherDataSubmitAction {
  type: typeof MotherDataAction.SUBMIT;
  payload: MotherData;
  pifId: number;
}

export interface MotherDataResultAction {
  type: typeof MotherDataAction.RESULT;
  payload: MotherData;
}

export interface MotherDataGetPostalCodesAction {
  type: typeof MotherDataAction.GET_POSTAL_CODES;
  payload: MotherData;
}

export interface MotherDataGetPostalCodesResultAction {
  type: typeof MotherDataAction.GET_POSTAL_CODES_RESULT;
  payload: PostalCode;
}

export interface MotherDataGetPostalCodesErrorAction {
  type: typeof MotherDataAction.GET_POSTAL_CODES_ERROR;
  payload: string;
}

export type MotherDataActionType =
  | MotherDataGetAction
  | MotherDataGetResultAction
  | MotherDataChangeAction
  | MotherDataSubmitAction
  | MotherDataResultAction
  | MotherDataGetPostalCodesAction
  | MotherDataGetPostalCodesResultAction
  | MotherDataGetPostalCodesErrorAction;
