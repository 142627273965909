import { Button } from '@material-ui/core';
import React, { ChangeEvent, ReactElement, useEffect, useState } from 'react';
import ZoomDatePicker from '../../../../components/forms/date-picker/ZoomDatePicker';
import ZoomTextInput from '../../../../components/forms/text-input/ZoomTextInput';
import ZoomToggleInput from '../../../../components/forms/toggle-input/ZoomToggleInput';
import { integerValidation, maxValueValidation, requiredValidation } from '../../../../utils/validators';

export interface Child {
  id?: number;
  firstName?: string;
  lastName?: string;
  dateOfBirth?: Date;
  livingType?: string;
  postMandatoryFormation?: string | null;
  formationType?: string;
  alimonyReceivedYesNo?: string;
  alimonyReceivedAmount?: string;
  alimonyPayedYesNo?: string | null;
  alimonyPayedAmount?: string | null;
}

export interface ChildItemFormProps extends Child {
  labelFirstName?: string;
  labelLastName?: string;
  labelDateOfBirth?: string;
  labelLivingType?: string;
  labelPostMandatoryFormation?: string;
  labelFormationType?: string;
  labelAlimonyReceivedYesNo?: string;
  labelAlimonyReceivedAmount?: string;
  labelAlimonyPayedYesNo?: string;
  labelAlimonyPayedAmount?: string;
  labelSave?: string;
  labelCancel?: string;
  toggleButtonsTypes?: { name: string; value: string }[];
  errorLabels?: { [key: string]: string };
  onSave?: (child: Child) => void;
  onDelete?: () => void;
}

interface ChildItemDataErrors {
  id?: string;
  firstName?: string;
  lastName?: string;
  dateOfBirth?: string;
  livingType?: string;
  postMandatoryFormation?: string;
  formationType?: string;
  alimonyReceivedYesNo?: string;
  alimonyReceivedAmount?: string;
  alimonyPayedYesNo?: string;
  alimonyPayedAmount?: string;
}

export const ChildItemForm = (props: ChildItemFormProps): ReactElement => {
  const [id] = useState(props.id ? props.id : 0);
  const [firstName, setFirstName] = useState(props.firstName ? props.firstName : '');
  const [lastName, setLastName] = useState(props.lastName ? props.lastName : '');
  const [dateOfBirth, setDateOfBirth] = useState(props.dateOfBirth ? props.dateOfBirth : undefined);
  const [livingType, setLivingType] = useState(props.livingType ? props.livingType : '1');
  const [postMandatoryFormation, setPostMandatoryFormation] = useState(
    props.postMandatoryFormation ? props.postMandatoryFormation : '',
  );
  const [formationType, setFormationType] = useState(props.formationType ? props.formationType : '');
  const [alimonyReceivedYesNo, setAlimonyReceivedYesNo] = useState(
    props.alimonyReceivedYesNo ? props.alimonyReceivedYesNo : '',
  );
  const [alimonyReceivedAmount, setAlimonyReceivedAmount] = useState(
    props.alimonyReceivedAmount ? props.alimonyReceivedAmount : '',
  );
  const [alimonyPayedYesNo, setAlimonyPayedYesNo] = useState(props.alimonyPayedYesNo ? props.alimonyPayedYesNo : '');
  const [alimonyPayedAmount, setAlimonyPayedAmount] = useState(
    props.alimonyPayedAmount ? props.alimonyPayedAmount : '',
  );
  const initialErrors: ChildItemDataErrors = {};
  const [errors, setErrors] = useState(initialErrors);

  useEffect(() => {
    setFirstName(props.firstName ? props.firstName : '');
    setLastName(props.lastName ? props.lastName : '');
    setDateOfBirth(props.dateOfBirth ? props.dateOfBirth : undefined);
    setLivingType(props.livingType ? props.livingType : '1');
    setPostMandatoryFormation(props.postMandatoryFormation ? props.postMandatoryFormation : '');
    setFormationType(props.formationType ? props.formationType : '');
    setAlimonyReceivedYesNo(props.alimonyReceivedYesNo ? props.alimonyReceivedYesNo : '');
    setAlimonyReceivedAmount(props.alimonyReceivedAmount ? props.alimonyReceivedAmount : '');
    setAlimonyPayedYesNo(props.alimonyPayedYesNo ? props.alimonyPayedYesNo : '');
    setAlimonyPayedAmount(props.alimonyPayedAmount ? props.alimonyPayedAmount : '');
  }, [props]);

  const onChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    switch (e.target.name) {
      case 'firstName':
        setFirstName(e.target.value);
        setErrors({
          ...errors,
          firstName: requiredValidation(e.target.value, props.errorLabels),
        });
        break;
      case 'lastName':
        setLastName(e.target.value);
        setErrors({
          ...errors,
          lastName: requiredValidation(e.target.value, props.errorLabels),
        });
        break;
      case 'formationType':
        setFormationType(e.target.value);
        setErrors({
          ...errors,
          formationType: requiredValidation(e.target.value, props.errorLabels),
        });
        break;
      case 'alimonyReceivedAmount':
        setAlimonyReceivedAmount(e.target.value);
        setErrors({
          ...errors,
          alimonyReceivedAmount: maxValueValidation(e.target.value, 9999999, props.errorLabels),
        });
        break;
      case 'alimonyPayedAmount':
        setAlimonyPayedAmount(e.target.value);
        setErrors({
          ...errors,
          alimonyPayedAmount: maxValueValidation(e.target.value, 9999999, props.errorLabels),
        });
        break;
    }
  };

  const onChangeDate = (newDate: Date | null) => {
    if (null === newDate) {
      setDateOfBirth(undefined);
    } else {
      setDateOfBirth(newDate);
    }
    setErrors({
      ...errors,
      dateOfBirth: newDate === null ? requiredValidation('', props.errorLabels) : '',
    });
  };

  const onClickLivingType = (newValue: string) => {
    setLivingType(newValue);
    setErrors({
      ...errors,
      livingType: requiredValidation(newValue, props.errorLabels),
    });
  };

  const onClickPostMandatoryFormation = (newValue: string) => {
    setPostMandatoryFormation(newValue);
    setErrors({
      ...errors,
      postMandatoryFormation: requiredValidation(newValue, props.errorLabels),
    });
  };

  const onClickAlimonyReceivedYesNo = (newValue: string) => {
    setAlimonyReceivedYesNo(newValue);
    setErrors({
      ...errors,
      alimonyReceivedYesNo: requiredValidation(newValue, props.errorLabels),
    });
  };

  const onClickAlimonyPayedYesNo = (newValue: string) => {
    setAlimonyPayedYesNo(newValue);
    setErrors({
      ...errors,
      alimonyPayedYesNo: requiredValidation(newValue, props.errorLabels),
    });
  };

  const getFormErrors = (): ChildItemDataErrors => {
    const itemDataErrors: ChildItemDataErrors = {};
    itemDataErrors.firstName = requiredValidation(firstName, props.errorLabels);
    itemDataErrors.lastName = requiredValidation(lastName, props.errorLabels);
    itemDataErrors.dateOfBirth = requiredValidation(
      dateOfBirth ? new Date(dateOfBirth).toDateString() : '',
      props.errorLabels,
    );
    itemDataErrors.postMandatoryFormation = requiredValidation(postMandatoryFormation, props.errorLabels);
    itemDataErrors.alimonyReceivedYesNo = requiredValidation(alimonyReceivedYesNo, props.errorLabels);
    itemDataErrors.alimonyPayedYesNo = requiredValidation(alimonyPayedYesNo, props.errorLabels);
    if (postMandatoryFormation === '1') {
      itemDataErrors.formationType = requiredValidation(formationType, props.errorLabels);
    }
    if (alimonyReceivedYesNo === '1') {
      itemDataErrors.alimonyReceivedAmount = integerValidation(alimonyReceivedAmount, props.errorLabels);
    }
    if (alimonyPayedYesNo === '1') {
      itemDataErrors.alimonyPayedAmount = integerValidation(alimonyPayedAmount, props.errorLabels);
    }
    return itemDataErrors;
  };

  const validateForm = (): boolean => {
    const newErrors = getFormErrors();
    setErrors(newErrors);
    const errorsPresent = Object.values(newErrors).some((value) => value !== '');
    return !errorsPresent;
  };

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    validateForm();
    event.preventDefault();
    const newErrors = getFormErrors();
    setErrors(newErrors);
    const errorsPresent = Object.values(newErrors).some((value) => value !== '');
    if (props.onSave && !errorsPresent) {
      props.onSave({
        id,
        firstName,
        lastName,
        dateOfBirth,
        livingType,
        postMandatoryFormation,
        formationType,
        alimonyReceivedYesNo,
        alimonyReceivedAmount,
        alimonyPayedYesNo,
        alimonyPayedAmount,
      });
    }
  };

  const onDelete = () => {
    if (props.onDelete) {
      props.onDelete();
    }
  };

  return (
    <>
      <form onSubmit={onSubmit}>
        <div className="form-element-container">
          <ZoomTextInput
            label={props.labelFirstName ? props.labelFirstName : ''}
            name="firstName"
            value={firstName}
            onChange={onChange}
            error={!!errors.firstName}
            errorMessage={errors.firstName}
          ></ZoomTextInput>
        </div>
        <div className="form-element-container">
          <ZoomTextInput
            label={props.labelLastName ? props.labelLastName : ''}
            name="lastName"
            value={lastName}
            onChange={onChange}
            error={!!errors.lastName}
            errorMessage={errors.lastName}
          ></ZoomTextInput>
        </div>
        <div className="form-element-container">
          <ZoomDatePicker
            label={props.labelDateOfBirth ? props.labelDateOfBirth : ''}
            name="dateOfBirth"
            value={dateOfBirth}
            onChange={onChangeDate}
            error={!!errors.dateOfBirth}
            errorMessage={errors.dateOfBirth}
          ></ZoomDatePicker>
        </div>
        <div className="form-element-container">
          <ZoomToggleInput
            name="livingType"
            value={livingType}
            title={props.labelLivingType ? props.labelLivingType : ''}
            label1={props.toggleButtonsTypes ? props.toggleButtonsTypes[0].name : ''}
            value1={props.toggleButtonsTypes ? props.toggleButtonsTypes[0].value.toString() : ''}
            label2={props.toggleButtonsTypes ? props.toggleButtonsTypes[1].name : ''}
            value2={props.toggleButtonsTypes ? props.toggleButtonsTypes[1].value.toString() : ''}
            onChange={onClickLivingType}
          ></ZoomToggleInput>
        </div>
        <div className="form-element-container">
          <ZoomToggleInput
            name="postMandatoryFormation"
            value={postMandatoryFormation}
            title={props.labelPostMandatoryFormation ? props.labelPostMandatoryFormation : ''}
            label1={props.toggleButtonsTypes ? props.toggleButtonsTypes[0].name : ''}
            value1={props.toggleButtonsTypes ? props.toggleButtonsTypes[0].value.toString() : ''}
            label2={props.toggleButtonsTypes ? props.toggleButtonsTypes[1].name : ''}
            value2={props.toggleButtonsTypes ? props.toggleButtonsTypes[1].value.toString() : ''}
            onChange={onClickPostMandatoryFormation}
            error={!!errors.postMandatoryFormation}
            errorMessage={errors.postMandatoryFormation}
          ></ZoomToggleInput>
        </div>
        {postMandatoryFormation === '1' && (
          <div className="form-element-container">
            <ZoomTextInput
              label={props.labelFormationType ? props.labelFormationType : ''}
              value={formationType}
              name="formationType"
              onChange={onChange}
              error={!!errors.formationType}
              errorMessage={errors.formationType}
            ></ZoomTextInput>
          </div>
        )}
        <div className="form-element-container">
          <ZoomToggleInput
            name="alimonyReceivedYesNo"
            value={alimonyReceivedYesNo}
            title={props.labelAlimonyReceivedYesNo ? props.labelAlimonyReceivedYesNo : ''}
            label1={props.toggleButtonsTypes ? props.toggleButtonsTypes[0].name : ''}
            value1={props.toggleButtonsTypes ? props.toggleButtonsTypes[0].value.toString() : ''}
            label2={props.toggleButtonsTypes ? props.toggleButtonsTypes[1].name : ''}
            value2={props.toggleButtonsTypes ? props.toggleButtonsTypes[1].value.toString() : ''}
            onChange={onClickAlimonyReceivedYesNo}
            error={!!errors.alimonyReceivedYesNo}
            errorMessage={errors.alimonyReceivedYesNo}
          ></ZoomToggleInput>
        </div>
        {alimonyReceivedYesNo === '1' && (
          <div className="form-element-container">
            <ZoomTextInput
              label={props.labelAlimonyReceivedAmount ? props.labelAlimonyReceivedAmount : ''}
              value={alimonyReceivedAmount}
              name="alimonyReceivedAmount"
              onChange={onChange}
              error={!!errors.alimonyReceivedAmount}
              errorMessage={errors.alimonyReceivedAmount}
            ></ZoomTextInput>
          </div>
        )}
        <div className="form-element-container">
          <ZoomToggleInput
            name="alimonyPayedYesNo"
            value={alimonyPayedYesNo}
            title={props.labelAlimonyPayedYesNo ? props.labelAlimonyPayedYesNo : ''}
            label1={props.toggleButtonsTypes ? props.toggleButtonsTypes[0].name : ''}
            value1={props.toggleButtonsTypes ? props.toggleButtonsTypes[0].value.toString() : ''}
            label2={props.toggleButtonsTypes ? props.toggleButtonsTypes[1].name : ''}
            value2={props.toggleButtonsTypes ? props.toggleButtonsTypes[1].value.toString() : ''}
            onChange={onClickAlimonyPayedYesNo}
            error={!!errors.alimonyPayedYesNo}
            errorMessage={errors.alimonyPayedYesNo}
          ></ZoomToggleInput>
        </div>
        {alimonyPayedYesNo === '1' && (
          <div className="form-element-container">
            <ZoomTextInput
              label={props.labelAlimonyPayedAmount ? props.labelAlimonyPayedAmount : ''}
              value={alimonyPayedAmount}
              name="alimonyPayedAmount"
              onChange={onChange}
              error={!!errors.alimonyPayedAmount}
              errorMessage={errors.alimonyPayedAmount}
            ></ZoomTextInput>
          </div>
        )}
        <div className="form-actions-container">
          <Button
            data-testid="save-child-button"
            id="save-child-button"
            color="primary"
            type="submit"
            variant="contained"
            size="small"
            title={props.labelSave}
          >
            {props.labelSave}
          </Button>
          <Button
            data-testid="cancel-child-button"
            color="primary"
            variant="outlined"
            size="small"
            title={props.labelCancel}
            onClick={() => {
              onDelete();
            }}
          >
            {props.labelCancel}
          </Button>
        </div>
      </form>
    </>
  );
};
