import { Observable, of } from 'rxjs';
import { Epic, ofType } from 'redux-observable';
import {
  ScholarshipDataAction,
  ScholarshipDataGetResultAction,
  ScholarshipDataSubmitAction,
  ScholarshipDataResultAction,
  ScholarshipDataAddAction,
  ScholarshipDataAddResultAction,
  ScholarshipDataGetAction,
} from './actions';
import { handleRequestFailureInEpic } from '../../utils/error-handling';

import { Action } from 'redux';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { AjaxResponse } from 'rxjs/ajax';
import { customAjax } from '../../utils/ajax-wrapper';

const loadScholarshipInfo = (payload: AjaxResponse): ScholarshipDataGetResultAction => {
  return {
    type: ScholarshipDataAction.GET_RESULT,
    payload: { ...payload.response },
  };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const loadScholarshipInfoOnError = (error: any): any => {
  if (error.status == 404) {
    return {
      type: ScholarshipDataAction.NON_EXISTENT_SCOLARSHIP_INFO,
    };
  }

  return handleRequestFailureInEpic(error);
};

export const getScholarshipInfoDataEpic: Epic<Action, Action> = (action$: Observable<Action>): Observable<Action> =>
  action$.pipe(
    ofType(ScholarshipDataAction.GET),
    mergeMap((action) => {
      const dossierId = (action as ScholarshipDataGetAction).dossierId;

      return customAjax({
        subRoute: `/dossier/v1/dossiers/${dossierId}/active-demand`,
        withCredentials: true,
        method: 'GET',
      }).pipe(
        map((response) => loadScholarshipInfo(response)),
        catchError((error) => {
          return of(loadScholarshipInfoOnError(error));
        }),
      );
    }),
  );

const getSubmitResult = (payload: AjaxResponse): ScholarshipDataResultAction => {
  return {
    type: ScholarshipDataAction.RESULT,
    payload: { ...payload.response },
  };
};

export const editScholarshipDataEpic: Epic<Action, Action> = (action$: Observable<Action>): Observable<Action> =>
  action$.pipe(
    ofType(ScholarshipDataAction.SUBMIT),
    mergeMap((action) => {
      const scholarshipData = (action as ScholarshipDataSubmitAction).payload;
      const dossierId = (action as ScholarshipDataSubmitAction).dossierId;

      return customAjax({
        subRoute: `/dossier/v1/dossiers/${dossierId}/demands/${scholarshipData.id}`,
        withCredentials: true,
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(scholarshipData),
      }).pipe(
        map((response) => getSubmitResult(response)),
        catchError((error) => {
          return of(handleRequestFailureInEpic(error));
        }),
      );
    }),
  );

const scholarshipDataAdded = (payload: AjaxResponse): ScholarshipDataAddResultAction => {
  return {
    type: ScholarshipDataAction.ADD_RESULT,
    payload: { ...payload.response },
  };
};

export const addScholarshipDataEpic: Epic<Action, Action> = (action$: Observable<Action>): Observable<Action> =>
  action$.pipe(
    ofType(ScholarshipDataAction.ADD),
    mergeMap((action) => {
      //const scholarshipData = (action as ScholarshipDataAddAction).payload;
      const dossierId = (action as ScholarshipDataAddAction).dossierId;

      return customAjax({
        subRoute: `/dossier/v1/dossiers/${dossierId}/demands/dashboard-create`,
        withCredentials: true,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        //body: JSON.stringify(scholarshipData),
      }).pipe(
        map((response) => scholarshipDataAdded(response)),
        catchError((error) => {
          return of(handleRequestFailureInEpic(error));
        }),
      );
    }),
  );
