import { MyDemandData } from './types';

export enum MyDemandPageAction {
  GET = 'MY_DEMAND_PAGE_GET',
  GET_RESULT = 'MY_DEMAND_PAGE_GET_RESULT',
  RESULT = 'MY_DEMAND_PAGE_RESULT',
  NON_EXISTENT_MY_DEMAND_PAGE = 'NON_EXISTENT_MY_DEMAND_PAGE',
}

export interface MyDemandPageGetAction {
  type: typeof MyDemandPageAction.GET;
  payload: number;
}

export interface MyDemandPageGetResultAction {
  type: typeof MyDemandPageAction.GET_RESULT;
  payload: MyDemandData[];
}

export type MyDemandPageActionType = MyDemandPageGetAction | MyDemandPageGetResultAction;
