import Alert from '@material-ui/lab/Alert';
import React, { ReactElement } from 'react';
import { green, lightGrey } from '../../../theme';
import { trailingNumbersFormat } from '../../../utils/formaters';
import { ZoomDebtIcon } from '../../icons/ZoomDebtIcon';
import ZoomPieChart from '../pie-chart/ZoomPieChart';
import './ZoomDebtInfo.scss';

export interface ZoomDebtInfoProps {
  title: string;
  subtitle: string;
  debtAmount: number;
  amount: number;
  totalAmount: number;
  labelLegendRepayment: string;
  labelLegendStatus: string;
  labelTotalAmount: string;
  infoText?: string;
}

const ZoomDebtInfo = (props: ZoomDebtInfoProps): ReactElement => {
  const percentage = Math.round((props.amount / props.totalAmount) * 100);

  return (
    <>
      <div id="debt-info-header">
        <div>
          <ZoomDebtIcon id="debt-icon" color="primary" viewBox="0 0 80 80"></ZoomDebtIcon>
        </div>
        <div id="debt-info-title">{props.title}</div>
      </div>
      <div className="dept-info-container">
        <div className="dept-pie-chart-container">
          <h2>{props.subtitle}</h2>
          <p>CHF {trailingNumbersFormat(props.debtAmount.toFixed(2))}</p>
        </div>
        <div className="dept-pie-chart-container">
          <div className="debt-info-chart-container">
            <ZoomPieChart
              size={180}
              strokeWidth={4}
              filledAreaPercentage={percentage}
              filledAreaColor={green.main}
              restAreaColor={lightGrey.main}
              isRepayment={true}
              labelTotalAmount={props.labelTotalAmount}
              amountToDisplay={props.totalAmount.toString()}
            ></ZoomPieChart>
          </div>
          <div className="repayment-info-legend">
            <p>
              <span className={'color-sample bg-green'}></span>
              {props.labelLegendRepayment}
            </p>
            <p>
              <span className="color-sample bg-grey"></span>
              {props.labelLegendStatus}
            </p>
          </div>
        </div>
        <div className="info-text-container">
          <Alert severity="info">{props.infoText}</Alert>
        </div>
      </div>
    </>
  );
};

export default ZoomDebtInfo;
