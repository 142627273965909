import { Breadcrumbs, Button, Card, CardContent, CircularProgress, Container, Grid, Link } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import React, { ReactElement, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';
import { Action } from 'redux';
import ZoomFooter, {
  mapStoreToFooterProps,
  ZoomFooterProps,
} from '../../components/base-page-components/footer/ZoomFooter';
import ZoomHeader, {
  mapStoreToHeaderProps,
  ZoomHeaderProps,
} from '../../components/base-page-components/header/ZoomHeader';
import { ZoomCaseFileUpload, ZoomCaseFileUploadProps } from '../../components/forms/file-upload/ZoomCaseFileUpload';
import { ZoomFile, ZoomFileInProgress } from '../../components/forms/file-upload/ZoomFileUpload';
import { ZoomArrowLeftIcon } from '../../components/icons/ZoomArrowLeftIcon';
import { ZoomPdfIcon } from '../../components/icons/ZoomPdfIcon';
import { ForbearanceAction, ForbearanceSubmitAction } from '../../store/forbearance/actions';
import { i18nLabel } from '../../store/i18n/helpers';
import { WIAAStore } from '../../store/types';
import { blue, red } from '../../theme';
import { NotificationCaseType } from '../../utils/form-data';
import './ForbearanceRequest.scss';

export interface ForbearanceRequestProps {
  headerProps: ZoomHeaderProps;
  footerProps: ZoomFooterProps;
  breadcrumbLabels?: Array<string>;
  labelBackArrow?: string;
  labelTitle?: string;
  labelInfoText?: string;
  labelCancel?: string;
  labelSave?: string;
  labelDownloadDocument?: string;
  isLoading?: boolean;
  dossierId: number;
  contractId?: number;
  typeId: number;
  files: Array<ZoomFile>;
  filesInProgress: Array<ZoomFileInProgress>;
  uploadSuccessful?: boolean;
  uploadError?: string;
  caseId?: number;
  labelDocumentType?: string;
  labelDragDocument: string;
  labelOr: string;
  labelChooseDocument: string;
  labelSuccess: string;
  errorLabels?: { [key: string]: string };
  labelErrorNoFiles?: string;
  dispatch?: (action: Action) => void;
}

interface ForbearanceRequestErrors {
  files?: string;
}

export const ForbearanceRequest = (props: ForbearanceRequestProps): ReactElement => {
  const initialErrors: ForbearanceRequestErrors = {};
  const [errors, setErrors] = useState(initialErrors);
  const history = useHistory();
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (isLoaded) {
      validateForm();
    }
  }, [props.files]);

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  const zoomCaseFileUploadProps: ZoomCaseFileUploadProps = {
    caseId: props.caseId,
    typeId: props.typeId,
    labelDragDocument: props.labelDragDocument,
    labelOr: props.labelOr,
    labelChooseDocument: props.labelChooseDocument,
    labelSuccess: props.labelSuccess,
    files: props.files,
    filesInProgress: props.filesInProgress,
    dossierId: props.dossierId,
    uploadError: props.uploadError,
    uploadSuccessful: props.uploadSuccessful,
    dispatch: props.dispatch,
  };

  const getFormErrors = (): ForbearanceRequestErrors => {
    const errors: ForbearanceRequestErrors = {};
    errors.files = props.files.length === 0 ? props.labelErrorNoFiles : '';
    return errors;
  };

  const validateForm = (): boolean => {
    const newErrors = getFormErrors();
    setErrors(newErrors);
    const errorsPresent = Object.values(newErrors).some((value) => value !== '');
    return !errorsPresent;
  };

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    if (validateForm() && props.dispatch) {
      const submitAction: ForbearanceSubmitAction = {
        type: ForbearanceAction.SUBMIT,
        payload: {
          typeId: NotificationCaseType.FORBEARANCE,
          caseId: props.caseId,
          foreignKeyId: props.contractId,
        },
        dossierId: props.dossierId,
      };
      props.dispatch(submitAction);
    }
    event.preventDefault();
  };

  return (
    <>
      <ZoomHeader {...props.headerProps} dispatch={props.dispatch} />
      <main className="main">
        {props.isLoading ? (
          <div style={{ textAlign: 'center' }}>
            <CircularProgress></CircularProgress>
          </div>
        ) : (
          <>
            <Container>
              <div id="main-page-container">
                <Breadcrumbs separator=">" aria-label="breadcrumb">
                  <Link
                    style={{ fontFamily: 'Proxima Nova Cond' }}
                    color="inherit"
                    href={'/dashboard/dossier/' + props.dossierId}
                  >
                    {props.breadcrumbLabels && props.breadcrumbLabels[0]}
                  </Link>
                  <Link
                    style={{ fontFamily: 'Proxima Nova Cond' }}
                    color="inherit"
                    href={'/my-contracts/' + props.dossierId}
                  >
                    {props.breadcrumbLabels && props.breadcrumbLabels[1]}
                  </Link>
                  <Link style={{ fontFamily: 'Proxima Nova Cond' }} color="inherit">
                    <b>{props.breadcrumbLabels && props.breadcrumbLabels[2]}</b>
                  </Link>
                </Breadcrumbs>
                <div className="main-page-info">
                  <div className="back-button-desktop" onClick={() => history.goBack()}>
                    <ZoomArrowLeftIcon viewBox="0 0 80 80" fontSize="large" htmlColor={blue.main} />
                    {props.labelBackArrow}
                  </div>
                </div>
                <Card style={{ marginTop: '20px' }}>
                  <CardContent>
                    <form onSubmit={onSubmit}>
                      <div className="form-container">
                        <h1 className="title-desktop">{props.labelTitle}</h1>
                      </div>
                      <div className="element-container">
                        <Alert severity="info">
                          <span
                            dangerouslySetInnerHTML={{
                              __html: props.labelInfoText ? props.labelInfoText : '',
                            }}
                          ></span>
                        </Alert>
                      </div>
                      <div className="element-container">
                        <Button
                          aria-controls="customized-menu"
                          aria-haspopup="true"
                          color="primary"
                          type="reset"
                          variant="outlined"
                          download
                          href="/pdf/Stundungsgesuchsformular.pdf"
                        >
                          <ZoomPdfIcon viewBox="0 0 80 80" fontSize="large" htmlColor={blue.main}></ZoomPdfIcon>

                          {props.labelDownloadDocument}
                        </Button>
                      </div>
                      <div className="element-container">
                        {errors.files !== undefined && errors.files !== '' && (
                          <div style={{ marginBottom: '10px' }}>
                            <label className="document-title-label" style={{ color: red.main }}>
                              {props.labelDocumentType}
                            </label>
                          </div>
                        )}
                        {errors.files === undefined && (
                          <div style={{ marginBottom: '10px' }}>
                            <label className="document-title-label">{props.labelDocumentType}</label>
                          </div>
                        )}
                        <div className="form-element-container">
                          <ZoomCaseFileUpload {...zoomCaseFileUploadProps} />
                        </div>
                        {errors.files !== undefined && errors.files !== '' && (
                          <div style={{ marginTop: '10px' }}>
                            <label className="document-error-label" style={{ color: red.main }}>
                              {props.labelErrorNoFiles}
                            </label>
                          </div>
                        )}
                      </div>
                      <Grid
                        className="element-container"
                        container
                        direction="row"
                        spacing={2}
                        style={{ paddingBottom: '1rem' }}
                      >
                        <Grid item xs={6} sm={6}>
                          <div className="form-actions-container-cancel">
                            <Button
                              color="primary"
                              size="large"
                              type="reset"
                              variant="outlined"
                              id="cancel-forbearance-request-data"
                              style={{ width: '144px' }}
                              onClick={() => history.goBack()}
                            >
                              {props.labelCancel}
                            </Button>
                          </div>
                        </Grid>
                        <Grid item xs={6} sm={6}>
                          <div className="form-actions-container-save">
                            <Button
                              color="primary"
                              size="large"
                              type="submit"
                              variant="contained"
                              id="save-forbearance-request-data"
                              style={{ width: '144px' }}
                            >
                              {props.labelSave}
                            </Button>
                          </div>
                        </Grid>
                      </Grid>
                    </form>
                  </CardContent>
                </Card>
              </div>
            </Container>
          </>
        )}
      </main>
      <ZoomFooter {...props.footerProps} />
    </>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export function mapStateToProps(store: WIAAStore, urlProps: any): ForbearanceRequestProps {
  const { dossierId, contractId } = urlProps.match.params;
  const headerProps: ZoomHeaderProps = mapStoreToHeaderProps(store, dossierId);
  const footerProps: ZoomFooterProps = mapStoreToFooterProps(store);
  const docType = store.notificationData.uploadedFiles.find(
    (docType) => docType.typeId === NotificationCaseType.FORBEARANCE,
  );
  return {
    dossierId,
    contractId,
    headerProps,
    footerProps,
    labelTitle: i18nLabel(store.translationLabels, 'Forbearance_Request_Data_Title', 'Stundungsgesuch'),
    labelInfoText: i18nLabel(
      store.translationLabels,
      'Forbearance_Request_Data_Info_Text',
      'Aus wichtigen Gründen kann die Rückzahlung ganz oder teilweise aufgeschoben oder erlassen werden. Nach dem klaren Wortlaut der Bestimmung besteht kein Anspruch auf Erlass oder Stundung. Das Formular für ein Stundungs- oder Erlassgesuch finden Sie unten zum Download. Damit wir Ihr Gesuch beurteilen können, müssen Sie beiliegendes Gesuchformular ausdrucken, vollständig ausfüllen, unterzeichnen, zusammen mit den notwendigen Belegen einscannen und über das Upload-Feld weiter unten hochladen. Dies muss innerhalb der Rückzahlfrist erfolgen. Zur Beurteilung des Gesuches gehen wir grundsätzlich vom betreibungsrechtlichen Existenzminimum aus und setzen diesem zumutbare/effektive Einnahmen gegenüber. Sollte diese Rechnung einen Fehlbetrag ergeben, wird die Darlehensschuld in der Regel längstens für 1 Jahr gestundet. Diese Stundung kann nach Ablauf bei Vorliegen eines entsprechenden Grundes verlängert werden. ',
    ),
    labelBackArrow: i18nLabel(store.translationLabels, 'Forbearance_Request_Data_Back_Arrow', 'Zurück'),
    labelCancel: i18nLabel(store.translationLabels, 'Forbearance_Request_Data_Cancel', 'Abbrechen'),
    labelSave: i18nLabel(store.translationLabels, 'Forbearance_Request_Data_Save', 'Absenden'),
    labelDownloadDocument: i18nLabel(
      store.translationLabels,
      'Forbearance_Request_Data_Document_Download',
      'Formular Stundungsgesuch (PDF Download)',
    ),
    breadcrumbLabels: [
      i18nLabel(store.translationLabels, 'Forbearance_Request_Data_Breadcrumb_Label_1', 'Übersicht'),
      i18nLabel(store.translationLabels, 'Forbearance_Request_Data_Breadcrumb_Label_2', 'Alle Darlehensverträge'),
      i18nLabel(store.translationLabels, 'Forbearance_Request_Data_Breadcrumb_Label_3', 'Stundungsgesuch'),
    ],
    errorLabels: store.translationLabels,
    labelErrorNoFiles: i18nLabel(
      store.translationLabels,
      'Forbearance_Request_Data_No_Document_Added',
      'Bitte laden Sie mindestens eine Datei hoch',
    ),
    labelDocumentType: i18nLabel(store.translationLabels, 'Forbearance_Request_Data_Document_Type', 'Stundungsgesuch*'),
    labelChooseDocument: i18nLabel(
      store.translationLabels,
      'Forbearance_Request_Data_Choose_Document',
      'Datei auswählen',
    ),
    labelOr: i18nLabel(store.translationLabels, 'Forbearance_Request_Data_Or', 'oder'),
    labelDragDocument: i18nLabel(
      store.translationLabels,
      'Forbearance_Request_Data_Drag_Document',
      'Dokument hierhin ziehen',
    ),
    labelSuccess: i18nLabel(store.translationLabels, 'Forbearance_Request_Data_Success', 'Dokument hochgeladen'),
    typeId: NotificationCaseType.FORBEARANCE,
    files: docType?.documents ? docType.documents : [],
    filesInProgress: docType?.inProgress ? docType.inProgress : [],
    isLoading: false,
    uploadError: i18nLabel(
      store.translationLabels,
      docType?.uploadError ? docType?.uploadError : '',
      docType?.uploadError ? docType?.uploadError : '',
    ),
    uploadSuccessful: docType?.uploadSuccess,
    caseId: docType?.caseId,
  };
}

export default withRouter(connect(mapStateToProps)(ForbearanceRequest));
