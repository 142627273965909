import { Breadcrumbs, Button, Card, CardContent, CircularProgress, Container, Grid, Link } from '@material-ui/core';
import React, { ReactElement, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';
import { Action } from 'redux';
import ZoomFooter, {
  mapStoreToFooterProps,
  ZoomFooterProps,
} from '../../components/base-page-components/footer/ZoomFooter';
import ZoomHeader, {
  mapStoreToHeaderProps,
  ZoomHeaderProps,
} from '../../components/base-page-components/header/ZoomHeader';
import { ZoomCaseFileUpload, ZoomCaseFileUploadProps } from '../../components/forms/file-upload/ZoomCaseFileUpload';
import { ZoomFile, ZoomFileInProgress } from '../../components/forms/file-upload/ZoomFileUpload';
import { ZoomArrowLeftIcon } from '../../components/icons/ZoomArrowLeftIcon';
import { EducationCompletedSubmitAction, EducationDataAction } from '../../store/education-data/actions';
import { i18nLabel } from '../../store/i18n/helpers';
import { CaseCreateDto } from '../../store/notifications/types';
import { WIAAStore } from '../../store/types';
import { UserDataAction, UserMyEducationDataGetAction } from '../../store/user-data/actions';
import { blue, red } from '../../theme';
import { NotificationCaseType } from '../../utils/form-data';
import './EducationCompleted.scss';

export interface EducationCompletedLabels {
  labelTitle?: string;
  labelDescription?: string;
  labelActualEducationSubTitle?: string;
  labelEducationType?: string;
  labelEducationFacilityName?: string;
  labelEducationFacilityLocation?: string;
  labelDuration?: string;
  labelDocumentType?: string;
  breadcrumbLabels?: Array<string>;
  headerProps: ZoomHeaderProps;
  footerProps: ZoomFooterProps;
  labelBackArrow?: string;
  labelCancel?: string;
  labelSave?: string;
  labelDragDocument: string;
  labelOr: string;
  labelChooseDocument: string;
  labelSuccess: string;
  errorLabels?: { [key: string]: string };
  labelErrorNoFiles?: string;
}

export interface EducationCompletedProps extends EducationCompletedLabels {
  educationType?: string;
  educationFacilityName?: string;
  educationFacilityLocation?: string;
  educationDurationStart?: string;
  educationDurationEnd?: string;
  educationForeignKeyId?: number;
  demandId: number;
  typeId: number;
  files: Array<ZoomFile>;
  filesInProgress: Array<ZoomFileInProgress>;
  uploadSuccessful?: boolean;
  uploadError?: string;
  dossierId: number;
  caseId?: number;
  isLoading?: boolean;
  dispatch?: (action: Action) => void;
}

interface EducationCompletedErrors {
  files?: string;
}

export const EducationCompleted = (props: EducationCompletedProps): ReactElement => {
  const educationType = props.educationType ? props.educationType : '0';
  const educationFacilityName = props.educationFacilityName ? props.educationFacilityName : '';
  const educationFacilityLocation = props.educationFacilityLocation ? props.educationFacilityLocation : '';
  const educationDurationStart = props.educationDurationStart ? props.educationDurationStart : undefined;
  const educationDurationEnd = props.educationDurationEnd ? props.educationDurationEnd : undefined;
  const initialErrors: EducationCompletedErrors = {};
  const [errors, setErrors] = useState(initialErrors);
  const history = useHistory();
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (isLoaded) {
      validateForm();
    }
  }, [props.files]);

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  useEffect(() => {
    if (props.dispatch) {
      const getUserMyEducationData: UserMyEducationDataGetAction = {
        type: UserDataAction.GET_USER_EDUCATION_DATA,
        payload: props.dossierId,
      };
      props.dispatch(getUserMyEducationData);
    }
  }, []);

  const getEducationCompletedData = (): CaseCreateDto => {
    return {
      typeId: NotificationCaseType.FORMATIONFINALISATIONCHECK,
      caseId: props.caseId,
      foreignKeyId: props.educationForeignKeyId,
    };
  };

  const getFormErrors = (): EducationCompletedErrors => {
    const errors: EducationCompletedErrors = {};
    errors.files = props.files.length === 0 ? props.labelErrorNoFiles : '';
    return errors;
  };

  const validateForm = (): boolean => {
    const newErrors = getFormErrors();
    setErrors(newErrors);
    const errorsPresent = Object.values(newErrors).some((value) => value !== '');
    return !errorsPresent;
  };

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    if (validateForm() && props.dispatch) {
      const submitAction: EducationCompletedSubmitAction = {
        type: EducationDataAction.COMPLETE,
        payload: getEducationCompletedData(),
        dossierId: props.dossierId,
      };
      props.dispatch(submitAction);
    }
    event.preventDefault();
  };

  const zoomCaseFileUploadProps: ZoomCaseFileUploadProps = {
    caseId: props.caseId,
    typeId: props.typeId,
    labelDragDocument: props.labelDragDocument,
    labelOr: props.labelOr,
    labelChooseDocument: props.labelChooseDocument,
    labelSuccess: props.labelSuccess,
    files: props.files,
    filesInProgress: props.filesInProgress,
    dossierId: props.dossierId,
    uploadError: props.uploadError,
    uploadSuccessful: props.uploadSuccessful,
    dispatch: props.dispatch,
  };

  return (
    <>
      <ZoomHeader {...props.headerProps} dispatch={props.dispatch} />
      <main className="main">
        {props.isLoading ? (
          <div style={{ textAlign: 'center' }}>
            <CircularProgress></CircularProgress>
          </div>
        ) : (
          <>
            <Container>
              <div id="main-page-container">
                <Breadcrumbs separator=">" aria-label="breadcrumb">
                  <Link
                    style={{ fontFamily: 'Proxima Nova Cond' }}
                    color="inherit"
                    href={'/dashboard/dossier/' + props.dossierId}
                  >
                    {props.breadcrumbLabels && props.breadcrumbLabels[0]}
                  </Link>
                  <Link
                    style={{ fontFamily: 'Proxima Nova Cond' }}
                    color="inherit"
                    href={'/dossier-intranet/myformation/' + props.dossierId}
                  >
                    {props.breadcrumbLabels && props.breadcrumbLabels[1]}
                  </Link>
                  <Link style={{ fontFamily: 'Proxima Nova Cond' }} color="inherit">
                    <b>{props.breadcrumbLabels && props.breadcrumbLabels[2]}</b>
                  </Link>
                </Breadcrumbs>
                <div className="main-page-info">
                  <div className="back-button-desktop" onClick={() => history.goBack()}>
                    <ZoomArrowLeftIcon viewBox="0 0 80 80" fontSize="large" htmlColor={blue.main} />
                    {props.labelBackArrow}
                  </div>
                  <h1 className="title-desktop">{props.labelTitle}</h1>
                  <p>{props.labelDescription}</p>
                </div>
                <Card>
                  <CardContent>
                    <form onSubmit={onSubmit}>
                      <div className="label-element-container">
                        <h1>{props.labelActualEducationSubTitle}</h1>
                      </div>
                      <div className="label-element-container">
                        <Grid container direction="row" alignItems="stretch" justify="flex-start">
                          <Grid item xs={12} sm={6}>
                            <div className="label-bottom-border">
                              <label>{props.labelEducationType ? props.labelEducationType : ''}</label>
                            </div>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <div className="label-bottom-border">
                              <label>{educationType}</label>
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                      <div className="label-element-container">
                        <Grid container direction="row" alignItems="stretch" justify="flex-start">
                          <Grid item xs={12} sm={6}>
                            <div className="label-bottom-border">
                              <label>{props.labelEducationFacilityName ? props.labelEducationFacilityName : ''}</label>
                            </div>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <div className="label-bottom-border">
                              <label>{educationFacilityName}</label>
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                      <div className="label-element-container">
                        <Grid container direction="row" alignItems="stretch" justify="flex-start">
                          <Grid item xs={12} sm={6}>
                            <div className="label-bottom-border">
                              <label>
                                {props.labelEducationFacilityLocation ? props.labelEducationFacilityLocation : ''}
                              </label>
                            </div>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <div className="label-bottom-border">
                              <label>{educationFacilityLocation}</label>
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                      <div className="label-element-container">
                        <Grid container direction="row" alignItems="stretch" justify="flex-start">
                          <Grid item xs={12} sm={6}>
                            <div className="label-bottom-border">
                              <label>{props.labelDuration ? props.labelDuration : ''}</label>
                            </div>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <div className="label-bottom-border">
                              <label>{`${educationDurationStart} - ${educationDurationEnd}`}</label>
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                      <div className="label-element-container">
                        {errors.files !== undefined && errors.files !== '' && (
                          <div style={{ marginBottom: '10px' }}>
                            <label className="document-title-label" style={{ color: red.main }}>
                              {props.labelDocumentType}
                            </label>
                          </div>
                        )}
                        {errors.files === undefined && (
                          <div style={{ marginBottom: '10px' }}>
                            <label className="document-title-label">{props.labelDocumentType}</label>
                          </div>
                        )}
                        <div className="form-element-container">
                          <ZoomCaseFileUpload {...zoomCaseFileUploadProps} />
                        </div>{' '}
                        {errors.files !== undefined && errors.files !== '' && (
                          <div style={{ marginTop: '10px' }}>
                            <label className="document-error-label" style={{ color: red.main }}>
                              {props.labelErrorNoFiles}
                            </label>
                          </div>
                        )}
                      </div>
                      <Grid
                        className="label-element-container"
                        container
                        direction="row"
                        spacing={2}
                        style={{ paddingBottom: '1rem' }}
                      >
                        <Grid item xs={6} sm={6}>
                          <div className="form-actions-container-cancel">
                            <Button
                              color="primary"
                              size="large"
                              type="reset"
                              variant="outlined"
                              id="cancel-completed-education-data"
                              style={{ width: '144px' }}
                              onClick={() => history.goBack()}
                            >
                              {props.labelCancel}
                            </Button>
                          </div>
                        </Grid>
                        <Grid item xs={6} sm={6}>
                          <div className="form-actions-container-save">
                            <Button
                              color="primary"
                              size="large"
                              type="submit"
                              variant="contained"
                              id="save-completed-education-data"
                              style={{ width: '144px' }}
                            >
                              {props.labelSave}
                            </Button>
                          </div>
                        </Grid>
                      </Grid>
                    </form>
                  </CardContent>
                </Card>
              </div>
            </Container>
          </>
        )}
      </main>
      <ZoomFooter {...props.footerProps} />
    </>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export function mapStateToProps(store: WIAAStore, urlProps: any): EducationCompletedProps {
  const { dossierId } = urlProps.match.params;
  const headerProps: ZoomHeaderProps = mapStoreToHeaderProps(store, dossierId);
  const footerProps: ZoomFooterProps = mapStoreToFooterProps(store);
  const currentFormation = store.userMyEducationData.formations?.find((f) => f.currentFormation);

  const docType = store.notificationData.uploadedFiles.find(
    (doc) => doc.typeId === NotificationCaseType.FORMATIONFINALISATIONCHECK,
  );

  return {
    headerProps,
    footerProps,
    labelTitle: i18nLabel(store.translationLabels, 'Education_Completed_Data_Title', 'Ausbildung abgeschlossen'),
    labelDescription: i18nLabel(
      store.translationLabels,
      'Education_Completed_Data_Description',
      'Herzliche Gratulation zu Ihrem Ausbildungsabschluss. Bitte laden Sie Ihr Abschlussdokument hier hoch. Besten Dank.',
    ),
    labelActualEducationSubTitle: i18nLabel(
      store.translationLabels,
      'Education_Completed_Data_Sub_Title',
      'Aktuelle Ausbildung',
    ),
    labelEducationType: i18nLabel(store.translationLabels, 'Education_Completed_Data_Type', 'Art der Ausbildung'),
    labelEducationFacilityName: i18nLabel(
      store.translationLabels,
      'Education_Completed_Data_Facility_Name',
      'Name der Ausbildungsstätte',
    ),
    labelEducationFacilityLocation: i18nLabel(
      store.translationLabels,
      'Education_Completed_Data_Facility_Location',
      'Ort der Ausbildungsstätte',
    ),
    labelDuration: i18nLabel(store.translationLabels, 'Education_Completed_Data_Duration', 'Ausbildungsdauer'),
    labelErrorNoFiles: i18nLabel(
      store.translationLabels,
      'Education_Completed_Data_No_Document_Added',
      'Bitte laden Sie mindestens eine Datei hoch',
    ),
    labelDocumentType: i18nLabel(store.translationLabels, 'Education_Completed_Data_Document_Type', 'Diplom*'),
    labelBackArrow: i18nLabel(store.translationLabels, 'Education_Completed_Data_Back_Arrow', 'Zurück'),
    labelCancel: i18nLabel(store.translationLabels, 'Education_Completed_Data_Cancel', 'Abbrechen'),
    labelSave: i18nLabel(store.translationLabels, 'Education_Completed_Data_Save', 'Absenden'),
    breadcrumbLabels: [
      i18nLabel(store.translationLabels, 'Education_Completed_Data_Breadcrumb_Label_1', 'Übersicht'),
      i18nLabel(store.translationLabels, 'Education_Completed_Data_Breadcrumb_Label_2', 'Meine Ausbildung'),
      i18nLabel(store.translationLabels, 'Education_Completed_Data_Breadcrumb_Label_3', 'Ausbildung abgeschlossen'),
    ],
    errorLabels: store.translationLabels,

    labelChooseDocument: i18nLabel(
      store.translationLabels,
      'Education_Completed_Data_Choose_Document',
      'Datei auswählen',
    ),
    labelOr: i18nLabel(store.translationLabels, 'Education_Completed_Data_Or', 'oder'),
    labelDragDocument: i18nLabel(
      store.translationLabels,
      'Education_Completed_Data_Drag_Document',
      'Dokument hierhin ziehen',
    ),
    labelSuccess: i18nLabel(store.translationLabels, 'Education_Completed_Data_Success', 'Dokument hochgeladen'),
    demandId: 1,
    typeId: NotificationCaseType.FORMATIONFINALISATIONCHECK,
    files: docType?.documents ? docType.documents : [],
    filesInProgress: docType?.inProgress ? docType.inProgress : [],
    dossierId: dossierId,
    uploadError: i18nLabel(
      store.translationLabels,
      docType?.uploadError ? docType.uploadError : '',
      docType?.uploadError ? docType.uploadError : '',
    ),
    uploadSuccessful: docType?.uploadSuccess,
    caseId: docType?.caseId,
    educationType: currentFormation?.formationArt ? currentFormation.formationArt : '',
    educationFacilityName: currentFormation?.formationInstitution,
    educationFacilityLocation: currentFormation?.formationLocation,
    educationDurationStart: currentFormation?.formationStartDate ? currentFormation.formationStartDate : undefined,
    educationDurationEnd: currentFormation?.formationEndDate ? currentFormation.formationEndDate : undefined,
    educationForeignKeyId: currentFormation?.formationDbKey,
    isLoading: store.userMyEducationData.isLoading,
  };
}

export default withRouter(connect(mapStateToProps)(EducationCompleted));
