import React, { ReactElement } from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import { black } from './../../theme';

export function ZoomKantonAargauLogoIcon(props: SvgIconProps): ReactElement {
  const color = props.htmlColor ? props.htmlColor : black.main;

  return (
    <SvgIcon {...props}>
      <title>Kanton Aargau Logo</title>
      <path
        className="header__logo-wave-1"
        fill={color}
        fillRule="nonzero"
        d="M8 15.5c3.1 0 4.7 1.1 6.1 2 1.4.9 2.6 1.7 5.1 1.7 2.5 0 3.7-.8 5.1-1.7 1.4-1 3-2 6.1-2 3.1 0 4.7 1.1 6.1 2 1.4.9 2.6 1.7 5.1 1.7s3.7-.8 5.1-1.7c1.4-1 3-2 6.1-2s4.7 1.1 6.1 2c1.4.9 2.6 1.7 5.1 1.7 5 0 7.5-3.8 7.5-3.8V2.5S69.6 6.2 64 6.2s-5.6-3.7-11.2-3.7-5.6 3.7-11.2 3.7S36 2.5 30.4 2.5s-5.6 3.7-11.2 3.7S13.6 2.5 8 2.5.5 6.2.5 6.2v12.3c1.2-1.2 3.5-3 7.5-3"
      ></path>
      <path
        className="header__logo-wave-2"
        fill={color}
        fillRule="nonzero"
        d="M8 30.4c3.1 0 4.7 1.1 6.1 2 1.4.9 2.6 1.7 5.1 1.7 2.5 0 3.7-.8 5.1-1.7 1.4-.9 3-2 6.1-2 3.1 0 4.7 1.1 6.1 2 1.4.9 2.6 1.7 5.1 1.7s3.7-.8 5.1-1.7c1.4-.9 3-2 6.1-2s4.7 1.1 6.1 2c1.4.9 2.6 1.7 5.1 1.7 5.1 0 7.5-3.8 7.5-3.8V18.1c-1.1 1.3-3.4 3.1-7.5 3.1-3.1 0-4.7-1.1-6.1-2-1.4-.9-2.6-1.7-5.1-1.7s-3.7.8-5.1 1.7c-1.4.9-3 2-6.1 2-3.1 0-4.7-1.1-6.1-2-1.4-.9-2.6-1.7-5.1-1.7-2.5 0-3.7.8-5.1 1.7-1.4.9-3 2-6.1 2-3.1 0-4.7-1.1-6.1-2-1.4-.9-2.6-1.7-5.1-1.7-5.2 0-7.5 3.8-7.5 3.8v12.2C1.7 32.2 4 30.4 8 30.4"
      ></path>
      <path
        className="header__logo-wave-3"
        fill={color}
        fillRule="nonzero"
        d="M64 36.1c-3.1 0-4.7-1.1-6.1-2-1.4-.9-2.6-1.7-5.1-1.7-2.5 0-3.7.8-5.1 1.7-1.4 1-3 2-6.1 2-3.1 0-4.7-1.1-6.1-2-1.4-.9-2.5-1.7-5.1-1.7-2.5 0-3.7.8-5.1 1.7-1.4 1-3 2-6.1 2-3.1 0-4.7-1.1-6.1-2-1.4-.9-2.6-1.7-5.1-1.7-5 0-7.5 3.8-7.5 3.8v12.9s1.9-3.7 7.5-3.7 5.6 3.7 11.2 3.7 5.6-3.7 11.2-3.7 5.6 3.7 11.2 3.7 5.6-3.7 11.2-3.7 5.6 3.7 11.2 3.7 7.5-3.7 7.5-3.7V33c-1.2 1.3-3.5 3.1-7.5 3.1"
      ></path>
      <path
        className="header__logo-star1"
        fill={color}
        fillRule="nonzero"
        d="M92.2 0l-3.5 10.6H77.5l9 6.6L83 27.8l9.1-6.6 9.1 6.6-3.5-10.7 9-6.6H95.6"
      ></path>
      <path
        className="header__logo-star2"
        fill={color}
        fillRule="nonzero"
        d="M113.4 35.1l-3.5-10.6-3.5 10.6H95.2l9 6.6-3.5 10.6 9.1-6.6 9.1 6.6-3.5-10.6 9-6.6"
      ></path>
      <path
        className="header__logo-star3"
        fill={color}
        fillRule="nonzero"
        d="M127.7 0l-3.5 10.6H113l9 6.6-3.5 10.6 9.1-6.6 9.1 6.6-3.5-10.7 9-6.6H131"
      ></path>
      <path
        className="header__logo-l-k header__kanton__text"
        fill={color}
        fillRule="nonzero"
        d="M7.6 60.3l-5.5 5.5v-5.5H.6v11.2h1.5v-3.9l1.8-1.8 4 5.6h1.9l-4.9-6.6 4.7-4.5"
      ></path>
      <path
        className="header__logo-l-a header__kanton__text"
        fill={color}
        fillRule="nonzero"
        d="M15.2 63.6c.3-.7.5-1.4.6-2.1.2.6.4 1.4.8 2.3l1.2 3.1H14l1.2-3.3m-2.9 7.8l1.2-3.4h4.7l1.3 3.4h1.7l-4.6-11.2H15l-4.3 11.2h1.6z"
      ></path>
      <path
        className="header__logo-l-n header__kanton__text"
        fill={color}
        fillRule="nonzero"
        d="M23.1 71.5V60.3h1.5l5.9 8.8v-8.8h1.4v11.2h-1.5l-5.9-8.8v8.8"
      ></path>
      <path
        className="header__logo-l-t header__kanton__text"
        fill={color}
        fillRule="nonzero"
        d="M37.1 71.4v-9.8h-3.7v-1.3h8.8v1.3h-3.7v9.8"
      ></path>
      <path
        className="header__logo-l-o header__kanton__text"
        fill={color}
        fillRule="nonzero"
        d="M45.4 62.4c.7-.7 1.6-1.1 2.7-1.1.7 0 1.4.2 2 .6.6.4 1 .9 1.3 1.6.3.7.5 1.5.5 2.4 0 1.4-.4 2.5-1.1 3.3-.7.8-1.6 1.2-2.7 1.2s-2-.4-2.7-1.2c-.7-.8-1.1-1.8-1.1-3.1 0-1.8.3-3 1.1-3.7m-2 6.3c.4.9 1 1.6 1.9 2.1.8.5 1.8.7 2.8.7 1 0 1.9-.2 2.7-.7.8-.4 1.5-1.1 1.9-2 .5-.9.7-1.9.7-3s-.2-2.1-.6-3c-.4-.9-1.1-1.6-1.9-2-.8-.5-1.7-.7-2.8-.7-1.6 0-2.8.5-3.8 1.6-1 1-1.5 2.5-1.5 4.3-.1.9.2 1.8.6 2.7"
      ></path>
      <path
        className="header__logo-l-n header__kanton__text"
        fill={color}
        fillRule="nonzero"
        d="M55.4 71.4V60.3h1.5l5.9 8.8v-8.8h1.4v11.2h-1.5l-5.9-8.8v8.8"
      ></path>
      <path
        className="header__logo-l-a header__kanton__text"
        fill={color}
        fillRule="nonzero"
        d="M75.9 63.6c.3-.7.5-1.4.6-2.1.2.6.4 1.4.8 2.3l1.2 3.1h-3.8l1.2-3.3M73 71.4l1.2-3.4h4.7l1.3 3.4h1.7l-4.6-11.2h-1.6l-4.3 11.2H73zm14.3-7.8c.3-.7.5-1.4.6-2.1.2.6.4 1.4.8 2.3l1.1 3.1H86l1.3-3.3m-2.8 7.8l1.2-3.4h4.7l1.3 3.4h1.7l-4.6-11.2h-1.6l-4.3 11.2h1.6z"
      ></path>
      <path
        className="header__logo-l-r header__kanton__text"
        fill={color}
        fillRule="nonzero"
        d="M96 61.5h3.5c.8 0 1.4.2 1.8.5.4.3.6.8.6 1.3 0 .4-.1.7-.3 1-.2.3-.5.5-.9.7-.4.1-.9.2-1.6.2H96v-3.7m0 9.9v-5h1.7c.4 0 .7 0 .8.1.2.1.4.2.7.3.2.1.5.4.8.7.3.4.6.9 1.1 1.5l1.5 2.3h1.8l-1.9-3c-.4-.6-.8-1.1-1.2-1.5-.2-.2-.5-.4-.9-.6 1.1-.1 1.9-.5 2.4-1 .5-.6.8-1.2.8-2 0-.6-.2-1.2-.5-1.7s-.7-.9-1.2-1.1c-.5-.2-1.3-.3-2.3-.3h-4.9v11.2l1.3.1z"
      ></path>
      <path
        className="header__logo-l-g header__kanton__text"
        fill={color}
        fillRule="nonzero"
        d="M110.8 67v-1.3h4.7v4.1c-.7.6-1.5 1-2.2 1.3-.8.3-1.5.4-2.4.4-1.1 0-2.1-.2-3-.7-.9-.5-1.6-1.1-2-2-.5-.9-.7-1.9-.7-2.9 0-1.1.2-2.1.7-3 .4-.9 1.1-1.6 1.9-2.1.8-.5 1.8-.7 2.9-.7.8 0 1.5.1 2.2.4.6.3 1.1.6 1.5 1.1.4.5.6 1.1.8 1.8l-1.3.4c-.2-.6-.4-1-.6-1.3-.2-.3-.6-.6-1.1-.8-.5-.2-1-.3-1.5-.3-.7 0-1.2.1-1.7.3-.5.2-.9.5-1.2.8-.3.3-.5.7-.7 1.1-.3.7-.4 1.4-.4 2.2 0 1 .2 1.8.5 2.5s.8 1.2 1.5 1.5c.6.3 1.3.5 2.1.5.6 0 1.3-.1 1.9-.4.6-.3 1.1-.5 1.4-.8V67h-3.3"
      ></path>
      <path
        className="header__logo-l-a header__kanton__text"
        fill={color}
        fillRule="nonzero"
        d="M121 63.6c.3-.7.5-1.4.6-2.1.2.6.4 1.4.8 2.3l1.1 3.1h-3.8l1.3-3.3m-2.8 7.8l1.2-3.4h4.7l1.3 3.4h1.7l-4.6-11.2h-1.6l-4.3 11.2h1.6z"
      ></path>
      <path
        className="header__logo-l-u header__kanton__text"
        fill={color}
        fillRule="nonzero"
        d="M135.2 60.3h1.5v6.4c0 1.1-.1 2-.4 2.6-.3.7-.7 1.2-1.4 1.6-.7.4-1.5.6-2.6.6-1 0-1.9-.2-2.6-.5-.7-.4-1.1-.9-1.4-1.5-.3-.7-.4-1.6-.4-2.8v-6.4h1.5v6.4c0 1 .1 1.7.3 2.1.2.5.5.8.9 1.1.4.2 1 .4 1.6.4 1.1 0 1.8-.2 2.3-.7.5-.5.7-1.4.7-2.8v-6.5"
      ></path>
    </SvgIcon>
  );
}
