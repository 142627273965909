import IconButton from '@material-ui/core/IconButton';
import { NavigateNext } from '@material-ui/icons';
import Edit from '@material-ui/icons/Edit';
import React, { ReactElement } from 'react';
import './CVItemInfo.scss';

export interface CVItemInfoProps {
  id: number;
  duration?: string;
  type?: string;
  kanton?: string;
  error?: boolean;
  onDelete?: (id: number) => void;
  onEdit?: (id: number) => void;
  disableControls?: boolean;
}

export const CVItemInfo = (props: CVItemInfoProps): ReactElement => {
  const onDelete = () => {
    if (props.onDelete) {
      props.onDelete(props.id);
    }
  };

  const onEdit = () => {
    if (props.onEdit) {
      props.onEdit(props.id);
    }
  };

  return (
    <>
      <div className="education-info">
        <div>{props.duration}</div>
        <div>{props.type}</div>
        <div>{props.kanton}</div>
        <div>
          <IconButton
            className="cv-education-info-edit-container"
            onClick={onEdit}
            color="primary"
            size="small"
            component="span"
            disabled={props.disableControls ? props.disableControls : false}
          >
            <Edit />
          </IconButton>
        </div>
      </div>
      {props.disableControls ? (
        !props.disableControls
      ) : (
        <div className="cv-education-info-delete-container">
          <NavigateNext />
          <a onClick={onDelete}>Angaben löschen</a>
        </div>
      )}
    </>
  );
};
