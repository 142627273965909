import produce from 'immer';
import { LastDemandDataTakeoverAction, LastDemandDataType } from '../last-demand-data-takeover/actions';
import { PartnerDataAction, PartnerDataActionType } from './actions';
import { PartnerData } from './types';

export const initialState: PartnerData = {};

export function partnerDataReducer(
  state = initialState,
  action: PartnerDataActionType | LastDemandDataType,
): PartnerData {
  return produce(state, (draft) => {
    switch (action.type) {
      case PartnerDataAction.GET:
        break;
      case PartnerDataAction.RESULT:
        draft = action.payload;
        break;
      case PartnerDataAction.GET_RESULT:
        const result = action;
        if (result.payload) {
          draft = action.payload;
        }
        break;
      case LastDemandDataTakeoverAction.RESULT:
        const lastDemandData = action.payload;
        draft = lastDemandData.lifePartner;
        break;
    }
    return draft;
  });
}
