import { CaseCreateDto } from '../notifications/types';
import { EditPayoutAddressDataPage } from './types';

export enum EditPayoutAddressDataPageAction {
  GET = 'EDIT_PAYOUT_ADDRESS_DATA_PAGE_DATA_GET',
  GET_RESULT = 'EDIT_PAYOUT_ADDRESS_DATA_PAGE_DATA_GET_RESULT',
  RESULT = 'EDIT_PAYOUT_ADDRESS_DATA_PAGE_DATA_RESULT',
  NON_EXISTENT_EDIT_PAYOUT_ADDRESS_DATA_PAGE = 'NON_EXISTENT_EDIT_PAYOUT_ADDRESS_DATA_PAGE',
  CHANGE = 'EDIT_PAYOUT_ADDRESS_DATA_PAGE_DATA_CHANGE',
  SUBMIT = 'EDIT_PAYOUT_ADDRESS_DATA_PAGE_DATA_SUBMIT',
}

export interface EditPayoutAddressDataPageGetAction {
  type: typeof EditPayoutAddressDataPageAction.GET;
  payload: number;
}

export interface EditPayoutAddressDataPageGetResultAction {
  type: typeof EditPayoutAddressDataPageAction.GET_RESULT;
  payload: EditPayoutAddressDataPage;
}

export interface EditPayoutAddressDataPageResultAction {
  type: typeof EditPayoutAddressDataPageAction.RESULT;
  payload: EditPayoutAddressDataPage;
}

export interface EditPayoutAddressDataPageChangeAction {
  type: typeof EditPayoutAddressDataPageAction.CHANGE;
  payload: number;
  changeField: string;
}

export interface EditPayoutAddressDataPageSubmitAction {
  type: typeof EditPayoutAddressDataPageAction.SUBMIT;
  payload: CaseCreateDto;
  dossierId: number;
}

export type EditPayoutAddressDataPageActionType =
  | EditPayoutAddressDataPageGetAction
  | EditPayoutAddressDataPageGetResultAction
  | EditPayoutAddressDataPageResultAction
  | EditPayoutAddressDataPageChangeAction
  | EditPayoutAddressDataPageSubmitAction;
