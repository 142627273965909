import { Breadcrumbs, Button, Card, CardContent, CircularProgress, Container, Grid, Link } from '@material-ui/core';
import React, { ChangeEvent, ReactElement, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';
import { Action } from 'redux';
import ZoomFooter, {
  mapStoreToFooterProps,
  ZoomFooterProps,
} from '../../components/base-page-components/footer/ZoomFooter';
import ZoomHeader, {
  mapStoreToHeaderProps,
  ZoomHeaderProps,
} from '../../components/base-page-components/header/ZoomHeader';
import { ZoomCaseFileUpload, ZoomCaseFileUploadProps } from '../../components/forms/file-upload/ZoomCaseFileUpload';
import { ZoomFile, ZoomFileInProgress } from '../../components/forms/file-upload/ZoomFileUpload';
import ZoomSelectInput from '../../components/forms/select-input/ZoomSelectInput';
import ZoomTextInput from '../../components/forms/text-input/ZoomTextInput';
import { ZoomArrowLeftIcon } from '../../components/icons/ZoomArrowLeftIcon';
import { i18nLabel } from '../../store/i18n/helpers';
import {
  EditPayoutAddressDataPageAction,
  EditPayoutAddressDataPageGetAction,
  EditPayoutAddressDataPageSubmitAction,
} from '../../store/my-data-payout-address/actions';
import { CaseCreateDto } from '../../store/notifications/types';
import { WIAAStore } from '../../store/types';
import { blue } from '../../theme';
import { getDefaultSelectOption, NotificationCaseType } from '../../utils/form-data';
import { ibanValidation, requiredValidation } from '../../utils/validators';
import './EditPayoutAddressData.scss';

export interface EditPayoutAddressDataLabels {
  labelTitle?: string;
  labelAccountHolder?: string;
  labelIban?: string;
  labelAddress?: string;
  helpTextAccountHolder?: string;
  helpTextIban?: string;
  labelDocumentType?: string;
  labelBackArrow?: string;
  labelCancel?: string;
  labelSave?: string;
  breadcrumbLabels?: Array<string>;
  errorLabels?: { [key: string]: string };
  labelDragDocument: string;
  labelOr: string;
  labelChooseDocument: string;
  labelSuccess: string;
  headerProps: ZoomHeaderProps;
  footerProps: ZoomFooterProps;
}

export interface EditPayoutAddressDataProps extends EditPayoutAddressDataLabels {
  demandId: number;
  accountHolder?: string;
  iban?: string;
  address?: string;
  typeId: number;
  files: Array<ZoomFile>;
  filesInProgress: Array<ZoomFileInProgress>;
  uploadSuccessful?: boolean;
  uploadError?: string;
  dossierId: number;
  caseId?: number;
  isLoading?: boolean;
  dispatch?: (action: Action) => void;
  paymentDestinationAddressRoleTypes?: { name: string; value: string }[];
}

interface EditPayoutAddressDataErrors {
  accountHolder?: string;
  iban?: string;
  address?: string;
}

export const EditPayoutAddressData = (props: EditPayoutAddressDataProps): ReactElement => {
  const [accountHolder, setAccountHolder] = useState(props.accountHolder ? props.accountHolder : '0');
  const [iban, setIban] = useState(props.iban ? props.iban : '');
  const [address, setAddress] = useState(props.address ? props.address : '');
  const history = useHistory();
  const initialErrors: EditPayoutAddressDataErrors = {};
  const [errors, setErrors] = useState(initialErrors);

  useEffect(() => {
    if (props.dispatch) {
      const getUserEditPayoutAddressData: EditPayoutAddressDataPageGetAction = {
        type: EditPayoutAddressDataPageAction.GET,
        payload: props.dossierId,
      };
      props.dispatch(getUserEditPayoutAddressData);
    }
  }, []);

  useEffect(() => {
    setAccountHolder(props.accountHolder ? props.accountHolder : '0');
    setIban(props.iban ? props.iban : '');
    setAddress(props.address ? props.address : '');
  }, [props]);

  const onChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    switch (e.target.name) {
      case 'iban':
        setIban(e.target.value);
        setErrors({
          ...errors,
          iban: ibanValidation(e.target.value, props.errorLabels ? props.errorLabels : {}),
        });
        break;
      case 'address':
        setAddress(e.target.value);
        setErrors({
          ...errors,
          address: requiredValidation(e.target.value, props.errorLabels ? props.errorLabels : {}),
        });
    }
  };

  const onAccountHolderChange = (newValue: string) => {
    setAccountHolder(newValue);
    setErrors({
      ...errors,
      accountHolder: newValue === '0' ? requiredValidation('', props.errorLabels ? props.errorLabels : {}) : '',
    });
  };

  const getEditPayoutAddressData = (): CaseCreateDto => {
    return {
      typeId: NotificationCaseType.PAYMENTACCOUNTCHANGE,
      caseId: props.caseId,
      body: JSON.stringify({ iban, address, accountHolder }),
    };
  };

  const getFormErrors = (): EditPayoutAddressDataErrors => {
    const errors: EditPayoutAddressDataErrors = {};
    errors.accountHolder =
      accountHolder === '0' ? requiredValidation('', props.errorLabels ? props.errorLabels : {}) : '';
    errors.iban = ibanValidation(iban, props.errorLabels ? props.errorLabels : {});
    errors.address = requiredValidation(address, props.errorLabels ? props.errorLabels : {});
    return errors;
  };

  const validateForm = (): boolean => {
    const newErrors = getFormErrors();
    setErrors(newErrors);
    const errorsPresent = Object.values(newErrors).some((value) => value !== '');
    return !errorsPresent;
  };

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    if (validateForm() && props.dispatch) {
      const submitAction: EditPayoutAddressDataPageSubmitAction = {
        type: EditPayoutAddressDataPageAction.SUBMIT,
        payload: getEditPayoutAddressData(),
        dossierId: props.dossierId,
      };
      props.dispatch(submitAction);
    }
    event.preventDefault();
  };

  const zoomCaseFileUploadProps: ZoomCaseFileUploadProps = {
    caseId: props.caseId,
    typeId: props.typeId,
    labelDragDocument: props.labelDragDocument,
    labelOr: props.labelOr,
    labelChooseDocument: props.labelChooseDocument,
    labelSuccess: props.labelSuccess,
    files: props.files,
    filesInProgress: props.filesInProgress,
    dossierId: props.dossierId,
    uploadError: props.uploadError,
    uploadSuccessful: props.uploadSuccessful,
    dispatch: props.dispatch,
  };

  return (
    <>
      <ZoomHeader {...props.headerProps} dispatch={props.dispatch}></ZoomHeader>
      <main className="main">
        {props.isLoading ? (
          <div style={{ textAlign: 'center' }}>
            <CircularProgress></CircularProgress>
          </div>
        ) : (
          <>
            <Container>
              <div id="main-page-container">
                <Breadcrumbs separator=">" aria-label="breadcrumb">
                  <Link
                    style={{ fontFamily: 'Proxima Nova Cond' }}
                    color="inherit"
                    href={'/dashboard/dossier/' + props.dossierId}
                  >
                    <>{props.breadcrumbLabels && props.breadcrumbLabels[0]}</>
                  </Link>
                  <Link
                    style={{ fontFamily: 'Proxima Nova Cond' }}
                    color="inherit"
                    href={'/my-data/dossier-intranet/mydata/' + props.dossierId}
                  >
                    <>{props.breadcrumbLabels && props.breadcrumbLabels[1]}</>
                  </Link>
                  <Link style={{ fontFamily: 'Proxima Nova Cond' }} color="inherit">
                    <b>{props.breadcrumbLabels && props.breadcrumbLabels[2]}</b>
                  </Link>
                </Breadcrumbs>
                <div className="main-page-info">
                  <div className="back-button-desktop" onClick={() => history.goBack()}>
                    <ZoomArrowLeftIcon viewBox="0 0 80 80" fontSize="large" htmlColor={blue.main}></ZoomArrowLeftIcon>
                    {props.labelBackArrow}
                  </div>
                  <h1 className="title-desktop">{props.labelTitle}</h1>
                </div>
                <Card>
                  <CardContent>
                    <form onSubmit={onSubmit}>
                      <div className="form-element-container">
                        <ZoomSelectInput
                          label={props.labelAccountHolder ? props.labelAccountHolder : ''}
                          options={
                            props.paymentDestinationAddressRoleTypes ? props.paymentDestinationAddressRoleTypes : []
                          }
                          value={accountHolder}
                          error={!!errors.accountHolder}
                          errorMessage={errors.accountHolder}
                          name="accountHolder"
                          onChange={onAccountHolderChange}
                          helpText={props.helpTextAccountHolder ? props.helpTextAccountHolder : ''}
                        ></ZoomSelectInput>
                      </div>
                      <div className="form-element-container">
                        <ZoomTextInput
                          label={props.labelIban ? props.labelIban : ''}
                          name="iban"
                          value={iban}
                          onChange={onChange}
                          error={!!errors.iban}
                          errorMessage={errors.iban}
                          isIBAN={true}
                          helpText={props.helpTextIban ? props.helpTextIban : ''}
                        ></ZoomTextInput>
                      </div>
                      <div className="form-element-container">
                        <ZoomTextInput
                          label={props.labelAddress ? props.labelAddress : ''}
                          name="address"
                          value={address}
                          multiline
                          onChange={onChange}
                          error={!!errors.address}
                          errorMessage={errors.address}
                        ></ZoomTextInput>
                      </div>
                      <div className="form-element-container">
                        <div style={{ marginBottom: '10px' }}>
                          <label className="document-title-label">{props.labelDocumentType}</label>
                        </div>
                        <ZoomCaseFileUpload {...zoomCaseFileUploadProps}></ZoomCaseFileUpload>
                      </div>
                      <Grid container direction="row" spacing={2} style={{ paddingBottom: '1rem' }}>
                        <Grid item xs={6} sm={6}>
                          <div className="form-actions-container-cancel">
                            <Button
                              color="primary"
                              size="large"
                              type="reset"
                              variant="outlined"
                              id="cancel-edit-payout-address-data"
                              style={{ width: '144px' }}
                              onClick={() => history.goBack()}
                            >
                              {props.labelCancel}
                            </Button>
                          </div>
                        </Grid>
                        <Grid item xs={6} sm={6}>
                          <div className="form-actions-container-save">
                            <Button
                              color="primary"
                              size="large"
                              type="submit"
                              variant="contained"
                              id="save-edit-payout-address-data"
                              style={{ width: '144px' }}
                            >
                              {props.labelSave}
                            </Button>
                          </div>
                        </Grid>
                      </Grid>
                    </form>
                  </CardContent>
                </Card>
              </div>
            </Container>
          </>
        )}
      </main>
      <ZoomFooter {...props.footerProps}></ZoomFooter>
    </>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export function mapStateToProps(store: WIAAStore, ownProps: any): EditPayoutAddressDataProps {
  const { dossierId } = ownProps.match.params;
  const headerProps: ZoomHeaderProps = mapStoreToHeaderProps(store, dossierId);
  const footerProps: ZoomFooterProps = mapStoreToFooterProps(store);
  const accountHolderId = store.editPayoutAddressDataPage.destinationAddressRoleId;
  const iban = store.editPayoutAddressDataPage.accountNumber;
  const ibanWithoutCH = iban ? (iban.substring(0, 2) === 'CH' ? iban.substring(2) : iban) : '';

  const docType = store.notificationData.uploadedFiles.find(
    (doc) => doc.typeId === NotificationCaseType.PAYMENTACCOUNTCHANGE,
  );

  return {
    headerProps,
    footerProps,
    labelTitle: i18nLabel(
      store.translationLabels,
      'Edit_Payout_Address_Data_Title',
      'Änderung der Auszahlungsadresse beantragen',
    ),
    labelAccountHolder: i18nLabel(
      store.translationLabels,
      'Edit_Payout_Address_Data_Account_Holder',
      'Bitte wählen Sie den Kontoinhaber bzw. die Kontoinhaberin des Kontos auf welche Sie den Ausbildungsbeitrag ausbezahlt wünschen.*',
    ),
    labelIban: i18nLabel(store.translationLabels, 'Edit_Payout_Address_Data_Iban', 'Kontonummer (IBAN)*'),
    labelAddress: i18nLabel(store.translationLabels, 'Edit_Payout_Address_Data_Address', 'Empfängeradresse*'),
    labelDocumentType: i18nLabel(
      store.translationLabels,
      'Edit_Payout_Address_Data_Document_Type',
      'Abtretungsdokument, wenn Auszahlung an das Sozialamt erfolgt',
    ),
    helpTextAccountHolder: i18nLabel(
      store.translationLabels,
      'Edit_Payout_Address_Data_Help_Text_Account_Holder',
      'Bitte wählen Sie den Kontoinhaber bzw. die Kontoinhaberin.',
    ),
    helpTextIban: i18nLabel(
      store.translationLabels,
      'Edit_Payout_Address_Data_Help_Text_Iban',
      'Bsp.: CH00 0000 0000 0000 0000 0',
    ),
    labelChooseDocument: i18nLabel(
      store.translationLabels,
      'Edit_Payout_Address_Data_Choose_Document',
      'Datei auswählen',
    ),
    labelOr: i18nLabel(store.translationLabels, 'Edit_Payout_Address_Data_Or', 'oder'),
    labelDragDocument: i18nLabel(
      store.translationLabels,
      'Edit_Payout_Address_Data_Drag_Document',
      'Dokument hierhin ziehen',
    ),
    labelSuccess: i18nLabel(store.translationLabels, 'Edit_Payout_Address_Data_Success', 'Dokument hochgeladen'),
    labelBackArrow: i18nLabel(store.translationLabels, 'Edit_Payout_Address_Data_Back_Arrow', 'Zurück'),
    labelCancel: i18nLabel(store.translationLabels, 'Edit_Payout_Address_Data_Cancel', 'Abbrechen'),
    labelSave: i18nLabel(store.translationLabels, 'Edit_Payout_Address_Data_Save', 'Speichern'),
    breadcrumbLabels: [
      i18nLabel(store.translationLabels, 'Edit_Payout_Address_Data_Breadcrumb_Label_1', 'Übersicht'),
      i18nLabel(store.translationLabels, 'Edit_Payout_Address_Data_Breadcrumb_Label_2', 'Meine Daten'),
      i18nLabel(
        store.translationLabels,
        'Edit_Payout_Address_Data_Breadcrumb_Label_3',
        'Auszahlungsadresse bearbeiten',
      ),
    ],
    paymentDestinationAddressRoleTypes: store.translationOptions['payment-destination-address-roles']
      ? [
          { value: '0', name: getDefaultSelectOption(store.translationLabels) },
          ...store.translationOptions['payment-destination-address-roles'].map((option) => {
            return { value: option.id.toString(), name: option.name };
          }),
        ]
      : [],
    errorLabels: store.translationLabels,
    demandId: 1,
    accountHolder: accountHolderId
      ? store.translationOptions['payment-destination-address-role'].find((option) => option.id == accountHolderId)
          ?.name
      : '',
    iban: ibanWithoutCH,
    address:
      store.editPayoutAddressDataPage.street +
      ', ' +
      // store.editPayoutAddressDataPage.houseNumber +
      ' ' +
      store.editPayoutAddressDataPage.zipCode +
      ' ' +
      store.editPayoutAddressDataPage.town,
    typeId: NotificationCaseType.PAYMENTACCOUNTCHANGE,
    files: docType?.documents ? docType.documents : [],
    filesInProgress: docType?.inProgress ? docType.inProgress : [],
    dossierId: dossierId,
    uploadError: i18nLabel(
      store.translationLabels,
      docType?.uploadError ? docType.uploadError : '',
      docType?.uploadError ? docType.uploadError : '',
    ),
    uploadSuccessful: docType?.uploadSuccess,
    caseId: docType?.caseId,
    isLoading: store.editPayoutAddressDataPage.isLoading,
  };
}

export default withRouter(connect(mapStateToProps)(EditPayoutAddressData));
