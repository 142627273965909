import { Action } from 'redux';
import { Epic, ofType } from 'redux-observable';
import { createPatch } from 'rfc6902';
import { Observable, of } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { customAjax } from '../../utils/ajax-wrapper';
import { handleRequestFailureInEpic, redirectToDataUnavailableOr } from '../../utils/error-handling';
import { RoutingAction, RoutingActionType } from '../routing/actions';
import {
  EditPersonalDataPageAction,
  EditPersonalDataPageGetAction,
  EditPersonalDataPageGetResultAction,
  EditPersonalDataPageSubmitAction,
} from './actions';

const addEditPersonalDataPage = (payload: AjaxResponse): EditPersonalDataPageGetResultAction => {
  return {
    type: EditPersonalDataPageAction.GET_RESULT,
    payload: payload.response.personalData,
  };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getEditPersonalDataPageOnError = (error: any, dossierId: number): any => {
  if (error.status === 404) {
    return redirectToDataUnavailableOr(
      error,
      {
        type: EditPersonalDataPageAction.NON_EXISTENT_EDIT_PERSONAL_DATA_PAGE,
      },
      dossierId,
    );
  }
  return handleRequestFailureInEpic(error);
};

const getSubmitResult = (payload: AjaxResponse, dossierId: number): RoutingActionType => {
  return {
    type: RoutingAction.REDIRECT_TO,
    payload: `/my-data/dossier-intranet/mydata/${dossierId}`,
  };
};

export const getEditPersonalDataPageEpic: Epic<Action, Action> = (action$: Observable<Action>): Observable<Action> =>
  action$.pipe(
    ofType(EditPersonalDataPageAction.GET),
    mergeMap((action) => {
      const dossierId = (action as EditPersonalDataPageGetAction).dossierId;
      return customAjax({
        subRoute: `/dossier-intranet/v1/mydata/${dossierId}`,
        withCredentials: true,
        method: 'GET',
      }).pipe(
        map((response) => addEditPersonalDataPage(response)),
        catchError((error) => {
          return of(getEditPersonalDataPageOnError(error, dossierId));
        }),
      );
    }),
  );

export const sendEditPersonalDataPageEpic: Epic<Action, Action> = (action$: Observable<Action>): Observable<Action> =>
  action$.pipe(
    ofType(EditPersonalDataPageAction.SUBMIT),
    mergeMap((action) => {
      const outgoingDataAction: EditPersonalDataPageSubmitAction = action as EditPersonalDataPageSubmitAction;
      const dossierId = outgoingDataAction.dossierId;

      return customAjax({
        subRoute: `/dossier-intranet/v1/mydata/${dossierId}`,
        withCredentials: true,
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json-patch+json',
        },
        body: JSON.stringify(
          createPatch(
            {
              personalData: {
                //ahvNo: '',
                mrMrsType: 0,
                //firstName: '',
                lastName: '',
                //dateOfBirth: new Date(),
                maritalStatus: 0,
                nationalityId: 0,
                originName: '',
              },
            },
            {
              personalData: {
                //ahvNo: outgoingDataAction.payload.ahvNo,
                mrMrsType: outgoingDataAction.payload.mrMrsType,
                //firstName: outgoingDataAction.payload.firstName,
                lastName: outgoingDataAction.payload.lastName,
                //dateOfBirth: outgoingDataAction.payload.dateOfBirth,
                maritalStatus: outgoingDataAction.payload.maritalStatus,
                nationalityId: outgoingDataAction.payload.nationalityId,
                originName: outgoingDataAction.payload.originName,
              },
            },
          ),
        ),
      }).pipe(
        map(
          (response) => getSubmitResult(response, dossierId),
          catchError((error) => {
            return of(handleRequestFailureInEpic(error));
          }),
        ),
      );
    }),
    catchError((error) => {
      return of(handleRequestFailureInEpic(error));
    }),
  );
