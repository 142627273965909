import { Action } from 'redux';
import { Epic, ofType } from 'redux-observable';
import { Observable, of } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { customAjax } from '../../utils/ajax-wrapper';
import { handleRequestFailureInEpic } from '../../utils/error-handling';
import { PostalCode } from '../contact-data/types';
import {
  GuardianDataAction,
  GuardianDataGetPostalCodesAction,
  GuardianDataGetPostalCodesErrorAction,
  GuardianDataGetPostalCodesResultAction,
  GuardianDataGetResultAction,
  GuardianDataResultAction,
  GuardianDataRetrieveAction,
  GuardianDataSubmitAction,
} from './actions';

const loadGuardianData = (payload: AjaxResponse): GuardianDataGetResultAction => {
  return {
    type: GuardianDataAction.GET_RESULT,
    payload: { ...payload.response },
  };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const loadGuardianDataOnError = (error: any): any => {
  if (error.status == 404) {
    return {
      type: GuardianDataAction.NON_EXISTENT_GUARDIAN,
    };
  }

  return handleRequestFailureInEpic(error);
};

const getSubmitResult = (payload: AjaxResponse): GuardianDataResultAction => {
  return {
    type: GuardianDataAction.RESULT,
    payload: { ...payload.response },
  };
};

const setPostalCodeData = (payload: AjaxResponse): GuardianDataGetPostalCodesResultAction => {
  return {
    type: GuardianDataAction.GET_VORMUND_POSTAL_CODES_RESULT,
    payload: payload.response[0] as PostalCode,
  };
};

export const getGuardianDataEpic: Epic<Action, Action> = (action$: Observable<Action>): Observable<Action> =>
  action$.pipe(
    ofType(GuardianDataAction.GET),
    mergeMap((action) => {
      const pifId = (action as GuardianDataRetrieveAction).pifId;
      return customAjax({
        subRoute: `/dossier/v1/persons-in-formation/${pifId}/guardianship`,
        withCredentials: true,
        method: 'GET',
      }).pipe(
        map((response) => loadGuardianData(response)),
        catchError((error) => {
          return of(loadGuardianDataOnError(error));
        }),
      );
    }),
  );

export const editGuardianDataEpic: Epic<Action, Action> = (action$: Observable<Action>): Observable<Action> =>
  action$.pipe(
    ofType(GuardianDataAction.SUBMIT),
    mergeMap((action) => {
      const guardianData = (action as GuardianDataSubmitAction).payload;
      const pifId = (action as GuardianDataSubmitAction).pifId;

      return customAjax({
        subRoute: `/dossier/v1/persons-in-formation/${pifId}/guardianship`,
        withCredentials: true,
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(guardianData),
      }).pipe(
        map((response) => getSubmitResult(response)),
        catchError((error) => {
          return of(handleRequestFailureInEpic(error));
        }),
      );
    }),
  );

export const getGuardianPostalCodeDataEpic: Epic<Action, Action> = (action$: Observable<Action>): Observable<Action> =>
  action$.pipe(
    ofType(GuardianDataAction.GET_VORMUND_POSTAL_CODES),
    mergeMap((action) => {
      const payoutData = (action as GuardianDataGetPostalCodesAction).payload;

      return customAjax({
        subRoute: `/master-data/v1/${payoutData.zipCode}/locality`,
        withCredentials: false,
        method: 'GET',
        headers: {
          'Accept-Language': 'de-de',
        },
      }).pipe(
        map((response) => setPostalCodeData(response)),
        catchError(() => {
          const errorAction: GuardianDataGetPostalCodesErrorAction = {
            type: GuardianDataAction.GET_VORMUND_POSTAL_CODES_ERROR,
            payload: 'No city found for ' + payoutData.zipCode,
          };
          return of(errorAction);
        }),
      );
    }),
  );
