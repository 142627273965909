import { Action } from 'redux';
import { Epic, ofType } from 'redux-observable';
import { Observable, of } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { customAjax } from '../../utils/ajax-wrapper';
import { handleRequestFailureInEpic } from '../../utils/error-handling';
import {
  AuthoritiesDataAction,
  AuthoritiesDataAddAuthorityAction,
  AuthoritiesDataAddResultAction,
  AuthoritiesDataDeleteAuthorityAction,
  AuthoritiesDataDeleteResultAction,
  AuthoritiesDataEditAuthorityAction,
  AuthoritiesDataEditResultAction,
  AuthoritiesDataGetAction,
  AuthoritiesDataGetResultAction,
} from './actions';
import { createPatch } from 'rfc6902';

const loadAuthoritiesData = (payload: AjaxResponse): AuthoritiesDataGetResultAction => {
  return {
    type: AuthoritiesDataAction.GET_RESULT,
    payload: payload.response,
  };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const loadAuthorityrenOnError = (error: any): any => {
  if (error.status == 404) {
    return {
      type: AuthoritiesDataAction.EMPTY_AUTHORITIES_DATA,
    };
  }

  return handleRequestFailureInEpic(error);
};

export const getAuthoritiesDataEpic: Epic<Action, Action> = (action$: Observable<Action>): Observable<Action> =>
  action$.pipe(
    ofType(AuthoritiesDataAction.GET),
    mergeMap((action) => {
      const getAuthoritiesGetAction = action as AuthoritiesDataGetAction;
      return customAjax({
        subRoute: `/partner/v1/authorities?dossierId=${getAuthoritiesGetAction.dossierId}`,
        withCredentials: true,
        method: 'GET',
      }).pipe(
        map((response) => loadAuthoritiesData(response)),
        catchError((error) => {
          return of(loadAuthorityrenOnError(error));
        }),
      );
    }),
  );

const authorityDataAdded = (payload: AjaxResponse): AuthoritiesDataAddResultAction => {
  return {
    type: AuthoritiesDataAction.ADD_RESULT,
    payload: { ...payload.response },
  };
};

export const addAuthorityDataEpic: Epic<Action, Action> = (action$: Observable<Action>): Observable<Action> =>
  action$.pipe(
    ofType(AuthoritiesDataAction.ADD_ITEM),
    mergeMap((action) => {
      const item = (action as AuthoritiesDataAddAuthorityAction).payload;
      const dossierId = (action as AuthoritiesDataAddAuthorityAction).dossierId;
      return customAjax({
        subRoute: `/partner/v1/authorities?dossierId=${dossierId}`,
        withCredentials: true,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(item),
      }).pipe(
        map((response) => authorityDataAdded(response)),
        catchError((error) => {
          return of(handleRequestFailureInEpic(error));
        }),
      );
    }),
  );

const authorityDataEdited = (payload: AjaxResponse): AuthoritiesDataEditResultAction => {
  return {
    type: AuthoritiesDataAction.EDIT_RESULT,
    payload: { ...payload.response },
  };
};

export const editAuthorityDataEpic: Epic<Action, Action> = (action$: Observable<Action>): Observable<Action> =>
  action$.pipe(
    ofType(AuthoritiesDataAction.EDIT_ITEM),
    mergeMap((action) => {
      const item = (action as AuthoritiesDataEditAuthorityAction).payload;
      return customAjax({
        subRoute: `/partner/v1/authorities?authorityId=${item.permissionId}&permissionType=${item.permissionTypeId}`,
        withCredentials: true,
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(createPatch({}, item)),
      }).pipe(
        map((response) => authorityDataEdited(response)),
        catchError((error) => {
          return of(handleRequestFailureInEpic(error));
        }),
      );
    }),
  );

const authorityDataDeleted = (payload: string): AuthoritiesDataDeleteResultAction => {
  return {
    type: AuthoritiesDataAction.DELETE_RESULT,
    payload,
  };
};

export const deleteAuthorityDataEpic: Epic<Action, Action> = (action$: Observable<Action>): Observable<Action> =>
  action$.pipe(
    ofType(AuthoritiesDataAction.DELETE_ITEM),
    mergeMap((action) => {
      const email = (action as AuthoritiesDataDeleteAuthorityAction).payload;
      const dossierId = (action as AuthoritiesDataDeleteAuthorityAction).dossierId;
      return customAjax({
        subRoute: `/partner/v1/authorities?email=${email}&dossierId=${dossierId}`,
        withCredentials: true,
        method: 'DELETE',
      }).pipe(
        map(() => authorityDataDeleted(email)),
        catchError((error) => {
          return of(handleRequestFailureInEpic(error));
        }),
      );
    }),
  );
