import { Action } from 'redux';
import { Epic, ofType } from 'redux-observable';
import { Observable, of } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { customAjax } from '../../utils/ajax-wrapper';
import { handleRequestFailureInEpic, redirectToDataUnavailableOr } from '../../utils/error-handling';
import { RoutingAction, RoutingActionType } from '../routing/actions';
import {
  EditPayoutAddressDataPageAction,
  EditPayoutAddressDataPageGetAction,
  EditPayoutAddressDataPageGetResultAction,
  EditPayoutAddressDataPageSubmitAction,
} from './actions';

const addEditPayoutAddressDataPage = (payload: AjaxResponse): EditPayoutAddressDataPageGetResultAction => {
  return {
    type: EditPayoutAddressDataPageAction.GET_RESULT,
    payload: { ...payload.response.paymentData },
  };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getEditPayoutAddressDataPageOnError = (error: any, dossierId: number): any => {
  if (error.status === 404) {
    return redirectToDataUnavailableOr(
      error,
      {
        type: EditPayoutAddressDataPageAction.NON_EXISTENT_EDIT_PAYOUT_ADDRESS_DATA_PAGE,
      },
      dossierId,
    );
  }
  return handleRequestFailureInEpic(error);
};

const getSubmitResult = (payload: AjaxResponse, dossierId: number): RoutingActionType => {
  return {
    type: RoutingAction.REDIRECT_TO,
    payload: `/my-data/dossier-intranet/mydata/${dossierId}`,
  };
};

export const getEditPayoutAddressDataPageEpic: Epic<Action, Action> = (
  action$: Observable<Action>,
): Observable<Action> =>
  action$.pipe(
    ofType(EditPayoutAddressDataPageAction.GET),
    mergeMap((action) => {
      const dossierId = (action as EditPayoutAddressDataPageGetAction).payload;
      return customAjax({
        subRoute: `/dossier-intranet/v1/mydata/${dossierId}`,
        withCredentials: true,
        method: 'GET',
      }).pipe(
        map((response) => addEditPayoutAddressDataPage(response)),
        catchError((error) => {
          return of(getEditPayoutAddressDataPageOnError(error, dossierId));
        }),
      );
    }),
  );

export const sendEditPayoutAddressDataPageEpic: Epic<Action, Action> = (
  action$: Observable<Action>,
): Observable<Action> =>
  action$.pipe(
    ofType(EditPayoutAddressDataPageAction.SUBMIT),
    mergeMap((action) => {
      const outgoingDataAction: EditPayoutAddressDataPageSubmitAction = action as EditPayoutAddressDataPageSubmitAction;
      const dossierId = outgoingDataAction.dossierId;
      return customAjax({
        subRoute: `/dossier-intranet/v1/cases/${dossierId}`,
        withCredentials: true,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept-Language': 'de-CH',
        },
        body: outgoingDataAction.payload,
      }).pipe(
        map(
          (response) => getSubmitResult(response, dossierId),
          catchError((error) => {
            return of(handleRequestFailureInEpic(error));
          }),
        ),
      );
    }),
    catchError((error) => {
      return of(handleRequestFailureInEpic(error));
    }),
  );
