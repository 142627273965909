import { WIAAStore } from './../store/types';
import { i18nLabel } from '../store/i18n/helpers';
import { HolderDropDownProps } from '../components/base-page-components/holder-dropdown/HolderDropDown';

export const mapStoreToHolderDropDownProps = (store: WIAAStore, dossierId: number): HolderDropDownProps => {
  let activeHolder = store.holderData.holders.find((h) => h.dossierId === dossierId);
  if (activeHolder === undefined) {
    activeHolder = {
      dossierId: -1,
    };
  }

  return {
    labelActiveHolder: i18nLabel(store.translationLabels, 'Stipendiat_Active_Label', 'Stipendiat:'),
    holders: store.holderData.holders,
    activeHolder: activeHolder,
    labelGoToMessages: i18nLabel(store.translationLabels, 'Stipendiat_Zu_Nachrichten', 'Nachrichten'),
    labelGoToDashboard: i18nLabel(store.translationLabels, 'Stipendiat_Zu_Dashboard', 'Übersicht'),
    labelGoToEducations: i18nLabel(store.translationLabels, 'Stipendiat_Zu_Educations', 'Ausbildungen'),
    labelGoToData: i18nLabel(store.translationLabels, 'Stipendiat_Zu_Daten', 'Daten'),
    labelSearchPlaceholder: i18nLabel(
      store.translationLabels,
      'Stipendiat_Search_Placeholder',
      'Filterbegriff eingeben',
    ),
    labelCheckboxOnlyUsersWithMessages: i18nLabel(
      store.translationLabels,
      'Stipendiat_Checkbox_Only_Users_With_Messages',
      'Nur Benutzer mit neuen Nachrichten anzeigen',
    ),
  };
};
