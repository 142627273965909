import React, { ReactElement } from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import { black } from './../../theme';

export function ZoomCheckCircleIcon(props: SvgIconProps): ReactElement {
  const color = props.htmlColor ? props.htmlColor : black.main;
  return (
    <SvgIcon {...props}>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <path d="M0 0H80V80H0z" transform="translate(-356 -157) translate(356 157)" />
            <path
              fill={color}
              fillRule="nonzero"
              d="M33.09 37.58L30.27 40.41 40.88 51.02 63.71 28.19 60.88 25.36 40.88 45.37z"
              transform="translate(-356 -157) translate(356 157)"
            />
            <path
              fill={color}
              fillRule="nonzero"
              d="M63.92 34.44c-.252-.93-.56-1.846-.92-2.74l-3.15 3.15c.06.2.12.4.17.59 2.546 9.785-2.57 19.939-11.948 23.717-9.378 3.778-20.103.005-25.051-8.812-4.948-8.817-2.58-19.938 5.53-25.974 8.112-6.036 19.443-5.112 26.469 2.159.31.32.6.64.88 1l2.83-2.83c-.27-.31-.54-.62-.83-.92-8.025-8.282-20.795-9.714-30.456-3.416-9.66 6.298-13.503 18.56-9.164 29.244 4.34 10.685 15.643 16.796 26.96 14.575 11.316-2.22 19.471-12.15 19.45-23.683 0-2.044-.258-4.08-.77-6.06z"
              transform="translate(-356 -157) translate(356 157)"
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}
