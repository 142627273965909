import { Breadcrumbs, Button, CircularProgress, Container, Link } from '@material-ui/core';
import moment from 'moment';
import React, { ReactElement, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Action } from 'redux';
import { ZoomFile, ZoomFileInProgress } from '../../components/forms/file-upload/ZoomFileUpload';
import { i18nLabel } from '../../store/i18n/helpers';
import { WIAAStore } from '../../store/types';
import { NotificationCaseType } from '../../utils/form-data';
import ZoomFooter, {
  mapStoreToFooterProps,
  ZoomFooterProps,
} from './../../components/base-page-components/footer/ZoomFooter';
import ZoomHeader, {
  mapStoreToHeaderProps,
  ZoomHeaderProps,
} from './../../components/base-page-components/header/ZoomHeader';
import Conversation, { ConversationProps } from './../../components/messages/Conversation';
import { NewConversation, NewConversationProps } from '../../components/messages/NewConversation';
import { NotificationAction, NotificationSendTextMessageAction } from '../../store/notifications/actions';
import './Conversations.scss';
import { HolderDropDownProps } from '../../components/base-page-components/holder-dropdown/HolderDropDown';
import { HolderDataAction, HolderDataGetAction } from '../../store/holder-data/actions';
import { mapStoreToHolderDropDownProps } from '../../utils/mappers';
import { NavigationItems } from '../../components/base-page-components/navigation/Navigation';

export interface ConversationsProps {
  pageTitle: string;
  newMessageLabel: string;
  conversations: Array<ConversationProps>;
  headerProps: ZoomHeaderProps;
  footerProps: ZoomFooterProps;
  breadcrumbLabels: Array<string>;
  labelBackArrow: string;
  supportNumberLabel: string;
  replyLabel: string;
  labelSendMessage: string;
  labelDragDocument: string;
  labelOr: string;
  labelChooseDocument: string;
  labelSuccess: string;
  labelNewConversationTitle: string;
  labelNewConversationMessage: string;
  holderDropDownProps: HolderDropDownProps;
  newConversationTitle?: string;
  newConversationMessage?: string;
  newConversationActive?: boolean;
  newConverstationFiles?: Array<ZoomFile>;
  newConversationFilesInProgress?: Array<ZoomFileInProgress>;
  newConversationUploadError?: string;
  newConversationUploadSuccess?: boolean;
  dossierId: number;
  newConversationCaseId?: number;
  isLoading?: boolean;
  dispatch?: (action: Action) => void;
}

export const Conversations = (props: ConversationsProps): ReactElement => {
  const conversations = props.conversations.map((conversation, index) => {
    const conversationProps: ConversationProps = { ...conversation, dispatch: props.dispatch };
    return <Conversation key={index} {...conversationProps} />;
  });

  const [newConversationActive, setNewConversationActive] = useState(
    props.newConversationActive ? props.newConversationActive : false,
  );

  const showNewConversationScreen = () => {
    setNewConversationActive(true);
  };

  useEffect(() => {
    if (props.dispatch && props.dossierId) {
      const getHolderData: HolderDataGetAction = {
        type: HolderDataAction.GET,
      };
      props.dispatch(getHolderData);
    }
  }, []);

  const newMessageProps: NewConversationProps = {
    ...props,
    caseId: props.newConversationCaseId,
    dispatch: props.dispatch,
    files: props.newConverstationFiles ? props.newConverstationFiles : [],
    filesInProgress: props.newConversationFilesInProgress ? props.newConversationFilesInProgress : [],
    uploadError: props.newConversationUploadError,
    uploadSuccessful: props.newConversationUploadSuccess,
    typeId: 1000,
    message: 'NOT USED PROPERTY',
    onSend: (title: string, message: string) => {
      setNewConversationActive(false);
      if (props.dispatch) {
        const sendNewMessageAction: NotificationSendTextMessageAction = {
          type: NotificationAction.SEND_TEXT_MESSAGE,
          payload: {
            typeId: NotificationCaseType.CHAT,
            subject: title,
            body: message,
            caseId: props.newConversationCaseId,
          },
          dossierId: props.dossierId,
        };
        props.dispatch(sendNewMessageAction);
      }
    },
  };

  return (
    <>
      <ZoomHeader {...props.headerProps} dispatch={props.dispatch} />
      <main className="main">
        {props.isLoading ? (
          <div style={{ textAlign: 'center' }}>
            <CircularProgress />
          </div>
        ) : (
          <>
            <Container>
              <div id="main-page-container">
                <div className="main-content-limited">
                  <Breadcrumbs separator=">" aria-label="breadcrumb">
                    <Link
                      style={{ fontFamily: 'Proxima Nova Cond' }}
                      color="inherit"
                      href={`/dashboard/dossier/${props.dossierId}`}
                    >
                      <b>{props.breadcrumbLabels && props.breadcrumbLabels[0]}</b>
                    </Link>
                    <Link style={{ fontFamily: 'Proxima Nova Cond' }} color="inherit">
                      {props.breadcrumbLabels && props.breadcrumbLabels[1]}
                    </Link>
                  </Breadcrumbs>
                  <NavigationItems
                    labelBackArrow={props.labelBackArrow}
                    holderDropDownProps={props.holderDropDownProps}
                    dispatch={props.dispatch}
                    mainPageTopInfo={false}
                  />
                  {!newConversationActive && (
                    <div className="heading-wrapper clearfix">
                      <h1 className="title-desktop">{props.pageTitle}</h1>
                      <p className="righted-on-desktop">
                        <Button
                          id="show-new-conversation-button"
                          variant="outlined"
                          color="primary"
                          size="large"
                          onClick={showNewConversationScreen}
                        >
                          {props.newMessageLabel}
                        </Button>
                      </p>
                    </div>
                  )}

                  {newConversationActive && (
                    <>
                      <div className="heading-wrapper clearfix">
                        <h1 className="title-desktop">{props.newMessageLabel}</h1>
                        <p className="righted-on-desktop">
                          <Button disabled={true} variant="outlined" color="primary" size="large">
                            {props.newMessageLabel}
                          </Button>
                        </p>
                      </div>

                      <NewConversation {...newMessageProps} />

                      <div className="heading-wrapper clearfix">
                        <h1 className="title-desktop">{props.pageTitle}</h1>
                      </div>
                    </>
                  )}
                </div>

                <div className="main-content-limited">
                  <div>{conversations}</div>
                </div>
              </div>
            </Container>
          </>
        )}
      </main>
      <ZoomFooter {...props.footerProps} />
    </>
  );
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
export const mapStateToConversationsProps = (store: WIAAStore, urlProps: any): ConversationsProps => {
  const { dossierId } = urlProps.match.params;
  const headerProps: ZoomHeaderProps = mapStoreToHeaderProps(store, dossierId);
  const footerProps: ZoomFooterProps = mapStoreToFooterProps(store);

  const labelsOnly: ConversationsProps = {
    holderDropDownProps: mapStoreToHolderDropDownProps(store, parseInt(dossierId)),
    labelChooseDocument: i18nLabel(store.translationLabels, 'Documents_Choose_Document', 'Datei auswählen'),
    labelOr: i18nLabel(store.translationLabels, 'Documents_Or_Label', 'oder'),
    labelDragDocument: i18nLabel(store.translationLabels, 'Documents_Drag_Document', 'Dokument hierhin ziehen'),
    labelSuccess: i18nLabel(store.translationLabels, 'Documents_Success', 'Dokument erfolgreich hochgeladen'),
    headerProps: headerProps,
    footerProps: footerProps,
    labelBackArrow: i18nLabel(store.translationLabels, 'Conversations_Back_Arrow', 'zurück'),
    pageTitle: i18nLabel(store.translationLabels, 'Conversations_Title', 'Nachrichten'),
    newMessageLabel: i18nLabel(store.translationLabels, 'Conversations_New_Message', 'Neue Nachricht'),
    breadcrumbLabels: [
      i18nLabel(store.translationLabels, 'Conversations_Breadcrumb_Dashboard', 'Übersicht'),
      i18nLabel(store.translationLabels, 'Conversations_Breadcrumb_ConversationsPage', 'Mitteilungen'),
    ],
    supportNumberLabel: i18nLabel(store.translationLabels, 'Converstaions_Support_Number', 'Support-Nr.:'),
    labelSendMessage: i18nLabel(store.translationLabels, 'Conversations_Send_Message', 'Senden'),
    replyLabel: i18nLabel(store.translationLabels, 'Conversations_Reply', 'Antwort'),
    labelNewConversationTitle: i18nLabel(store.translationLabels, 'Conversations_New_Conversation_Title', 'Betreff'),
    labelNewConversationMessage: i18nLabel(
      store.translationLabels,
      'Conversations_New_Conversation_Message',
      'Mitteilung',
    ),
    conversations: [],
    dossierId,
    isLoading: store.notificationData.isLoading,
  };

  const conversationsWithLabels: Array<ConversationProps> = store.notificationData.freeTextMessages.map(
    (conversation) => {
      const messages = conversation.messages.map((clientEvent) => {
        return {
          caseId: conversation.caseId,
          date: moment(clientEvent.date).format('DD.MM.YYYY'),
          text: clientEvent.body ? clientEvent.body : '',
          inbox: !clientEvent.portalUser,
          dossierId,
          clientEventId: clientEvent.id ? clientEvent.id : -1,
          eventTypeId: clientEvent.caseStep?.eventType?.id,
          files: clientEvent.documents.map((clientEventDocumentDto) => {
            return {
              streamId: clientEventDocumentDto.streamId,
              fileName: clientEventDocumentDto.fileName,
              id: 'UNKNOWN',
              type: 'UNKNWON',
              size: 0,
              clientEventId: clientEventDocumentDto.clientEventId,
            };
          }),
        };
      });
      const docType = store.notificationData.uploadedFiles.find((docType) => docType.caseId === conversation.caseId);
      return {
        caseId: conversation.caseId,
        title: conversation.title,
        stepId: conversation.stepId,
        typeId: conversation.typeId,
        answerFromPortalAllowed: conversation.answerFromPortalAllowed,
        messages: messages,
        replyProps: {
          message: '',
          typeId: conversation.typeId,
          caseId: conversation.caseId,
          stepId: conversation.stepId,
          files: docType ? docType.documents : [],
          filesInProgress: docType ? docType.inProgress : [],
          demandId: 0,
          uploadError: i18nLabel(
            store.translationLabels,
            docType?.uploadError ? docType?.uploadError : '',
            docType?.uploadError ? docType?.uploadError : '',
          ),
          uploadSuccessful: docType?.uploadSuccess,
          ...labelsOnly,
        },
        date: moment(conversation.date).format('DD.MM.YYYY'),
        hasNewMessages: conversation.messages.some((msg) => !msg.read),
        isOpened: false,
        hasAttachments: conversation.messages.some((msg) => msg.documents.length > 0),
        supportNumber: conversation.caseId.toString(),
        ...labelsOnly,
      };
    },
  );

  const newTopicDocType = store.notificationData.uploadedFiles.find(
    (docType) => docType.typeId === NotificationCaseType.NEW_CHAT,
  );

  return {
    ...labelsOnly,
    conversations: conversationsWithLabels,
    newConversationCaseId: newTopicDocType ? newTopicDocType.caseId : undefined,
    newConversationFilesInProgress: newTopicDocType ? newTopicDocType.inProgress : [],
    newConverstationFiles: newTopicDocType ? newTopicDocType.documents : [],
    newConversationUploadError: i18nLabel(
      store.translationLabels,
      newTopicDocType?.uploadError ? newTopicDocType.uploadError : '',
      newTopicDocType?.uploadError ? newTopicDocType.uploadError : '',
    ),
    newConversationUploadSuccess: newTopicDocType?.uploadSuccess,
  };
};

export default withRouter(connect(mapStateToConversationsProps)(Conversations));
