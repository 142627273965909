import { Button } from '@material-ui/core';
import React, { ChangeEvent, ReactElement, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ZoomRadioInput from '../../components/forms/radio-input/ZoomRadioInput';
import ZoomTextInput from '../../components/forms/text-input/ZoomTextInput';
import { i18nLabel } from '../../store/i18n/helpers';
import { IncomingDataAction, IncomingDataActionType } from '../../store/incoming-data/actions';
import { IncomingData } from '../../store/incoming-data/types';
import { WIAAStore } from '../../store/types';
import { maxValueValidation, minMaxValueValidation, requiredValidation } from '../../utils/validators';
import { extractToggleValue, getAge } from '../../utils/object-manipulation';
import ZoomToggleInput from '../../components/forms/toggle-input/ZoomToggleInput';

export interface IncomingDataFormProps {
  labelPremiumReductionReceived: string;
  labelPremiumReductionAmount: string;
  premiumReductionReceived?: string;
  premiumReductionAmount?: string;
  socialCareReceived?: string;
  salaryNetPif?: string;
  pensionAmount?: string;
  additionalAssistance?: string;
  additionalRevenu?: string;
  fortunePif?: string;
  contributionsOthers?: string;
  alimonyReceivedPartner?: string;
  labelSocialCareReceived?: string;
  labelSalaryNetPif?: string;
  labelPensionAmount?: string;
  labelAdditionalAssistance?: string;
  labelAdditionalRevenu?: string;
  labelFortunePif?: string;
  labelContributionsOthers?: string;
  labelAlimonyReceivedPartner?: string;
  labelSave?: string;
  age?: number;
  helpTextSalaryNetPif?: string;
  helpTextFortunePif?: string;
  helpTextContributionsOthers?: string;
  personalDataCivilStatus?: boolean;
  demandId: number;
  toggleButtonsTypes?: { name: string; value: string }[];
  errorLabels?: { [key: string]: string };
  dispatch?: (action: IncomingDataActionType) => void;
}

interface IncomingDataError {
  id?: string;
  socialCareReceived?: string;
  salaryNetPif?: string;
  pensionAmount?: string;
  additionalAssistance?: string;
  additionalRevenu?: string;
  fortunePif?: string;
  contributionsOthers?: string;
  alimonyReceivedPartner?: string;
  premiumReductionReceived?: string;
  premiumReductionAmount?: string;
}

export const IncomingDataForm = (props: IncomingDataFormProps): ReactElement => {
  const [socialCareReceived, setSocialCareReceived] = useState(props.socialCareReceived ?? '0');
  const [salaryNetPif, setSalaryNetPif] = useState(props.salaryNetPif ? props.salaryNetPif : '0');
  const [pensionAmount, setPensionAmount] = useState(props.pensionAmount ? props.pensionAmount : '0');
  const [additionalAssistance, setAdditionalAssistance] = useState(
    props.additionalAssistance ? props.additionalAssistance : '0',
  );
  const [additionalRevenu, setAdditionalRevenu] = useState(props.additionalRevenu ? props.additionalRevenu : '0');
  const [fortunePif, setFortunePif] = useState(props.fortunePif ? props.fortunePif : '0');
  const [contributionsOthers, setContributionsOthers] = useState(
    props.contributionsOthers ? props.contributionsOthers : '0',
  );
  const [alimonyReceivedPartner, setAlimonyReceivedPartner] = useState(
    props.alimonyReceivedPartner ? props.alimonyReceivedPartner : '0',
  );
  const [premiumReductionReceived, setPremiumReductionReceived] = useState(props.premiumReductionReceived ?? '');
  const [premiumReductionAmount, setPremiumReductionAmount] = useState(props.premiumReductionAmount ?? '0');
  const [age, setAge] = useState(props.age ?? undefined);
  const initialErrors: IncomingDataError = {};
  const [errors, setErrors] = useState(initialErrors);

  useEffect(() => {
    setSocialCareReceived(props.socialCareReceived ? props.socialCareReceived : '2');
    setSalaryNetPif(props.salaryNetPif ? props.salaryNetPif : '0');
    setPensionAmount(props.pensionAmount ? props.pensionAmount : '0');
    setAdditionalAssistance(props.additionalAssistance ? props.additionalAssistance : '0');
    setAdditionalRevenu(props.additionalRevenu ? props.additionalRevenu : '0');
    setFortunePif(props.fortunePif ? props.fortunePif : '0');
    setContributionsOthers(props.contributionsOthers ? props.contributionsOthers : '0');
    setAlimonyReceivedPartner(props.alimonyReceivedPartner ? props.alimonyReceivedPartner : '0');
    setPremiumReductionReceived(props.premiumReductionReceived ?? '');
    setPremiumReductionAmount(props.premiumReductionAmount ?? '0');
    setAge(props.age ?? undefined);
  }, [props]);

  const onChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    switch (e.target.name) {
      case 'salaryNetPif':
        setSalaryNetPif(e.target.value);
        setErrors({
          ...errors,
          salaryNetPif: maxValueValidation(e.target.value, 9999999, props.errorLabels ? props.errorLabels : {}),
        });
        break;
      case 'pensionAmount':
        setPensionAmount(e.target.value);
        setErrors({
          ...errors,
          pensionAmount: maxValueValidation(e.target.value, 9999999, props.errorLabels ? props.errorLabels : {}),
        });
        break;
      case 'additionalAssistance':
        setAdditionalAssistance(e.target.value);
        setErrors({
          ...errors,
          additionalAssistance: maxValueValidation(e.target.value, 9999999, props.errorLabels ? props.errorLabels : {}),
        });
        break;
      case 'additionalRevenu':
        setAdditionalRevenu(e.target.value);
        setErrors({
          ...errors,
          additionalRevenu: maxValueValidation(e.target.value, 9999999, props.errorLabels ? props.errorLabels : {}),
        });
        break;
      case 'fortunePif':
        setFortunePif(e.target.value);
        setErrors({
          ...errors,
          fortunePif: maxValueValidation(e.target.value, 9999999, props.errorLabels ? props.errorLabels : {}),
        });
        break;
      case 'contributionsOthers':
        setContributionsOthers(e.target.value);
        setErrors({
          ...errors,
          contributionsOthers: maxValueValidation(e.target.value, 9999999, props.errorLabels ? props.errorLabels : {}),
        });
        break;
      case 'alimonyReceivedPartner':
        setAlimonyReceivedPartner(e.target.value);
        setErrors({
          ...errors,
          alimonyReceivedPartner: maxValueValidation(
            e.target.value,
            9999999,
            props.errorLabels ? props.errorLabels : {},
          ),
        });
        break;
    }
  };

  const onChangeSocialCareReceivedType = (newValue: string) => {
    setSocialCareReceived(newValue);
    setErrors({
      ...errors,
      socialCareReceived: requiredValidation(newValue, props.errorLabels ? props.errorLabels : {}),
    });
  };

  const getIncomingData = (): IncomingData => {
    return {
      socialCareReceived: socialCareReceived === '1',
      salaryNetPif: salaryNetPif ? parseInt(salaryNetPif) : undefined,
      pensionAmount: pensionAmount ? parseInt(pensionAmount) : undefined,
      additionalAssistance: additionalAssistance ? parseInt(additionalAssistance) : undefined,
      additionalRevenue: additionalRevenu ? parseInt(additionalRevenu) : undefined,
      fortunePif: fortunePif ? parseInt(fortunePif) : undefined,
      contributionsOthers: contributionsOthers ? parseInt(contributionsOthers) : undefined,
      alimonyReceivedPartner: alimonyReceivedPartner ? parseInt(alimonyReceivedPartner) : undefined,
      premiumReductionReceived: isPremiumReductionVisible() ? premiumReductionReceived === '1' : undefined,
      premiumReductionAmount: isPremiumReductionAmountVisible() ? Number(premiumReductionAmount) : undefined,
    };
  };

  const getFormErrors = (): IncomingDataError => {
    const errors: IncomingDataError = {};
    errors.socialCareReceived = requiredValidation(socialCareReceived, props.errorLabels ? props.errorLabels : {});
    errors.salaryNetPif = maxValueValidation(salaryNetPif, 9999999, props.errorLabels ? props.errorLabels : {});
    errors.pensionAmount = maxValueValidation(pensionAmount, 9999999, props.errorLabels ? props.errorLabels : {});
    if (parseInt(pensionAmount) > 0) {
      errors.additionalAssistance = maxValueValidation(
        additionalAssistance,
        9999999,
        props.errorLabels ? props.errorLabels : {},
      );
    }
    errors.additionalRevenu = maxValueValidation(additionalRevenu, 9999999, props.errorLabels ? props.errorLabels : {});
    errors.fortunePif = maxValueValidation(fortunePif, 9999999, props.errorLabels ? props.errorLabels : {});
    errors.contributionsOthers = maxValueValidation(
      contributionsOthers,
      9999999,
      props.errorLabels ? props.errorLabels : {},
    );
    if (props.personalDataCivilStatus) {
      errors.alimonyReceivedPartner = maxValueValidation(
        alimonyReceivedPartner,
        9999999,
        props.errorLabels ? props.errorLabels : {},
      );
    }
    return errors;
  };

  /**
   * Premium reduction is only visible
   * when age over 25 and, if less than 25 and cumulated income > 24'000 CHF
   */
  const isPremiumReductionVisible = () => {
    if (age && age > 25) {
      return true;
    }

    const v1 = additionalRevenu ? Number(additionalRevenu) : 0;
    const v2 = salaryNetPif ? Number(salaryNetPif) : 0;
    const v3 = pensionAmount ? Number(pensionAmount) : 0;
    const v4 = additionalAssistance ? Number(additionalAssistance) : 0;

    return v1 + v2 + v3 + v4 > 24000;
  };

  const isPremiumReductionAmountVisible = () => {
    return isPremiumReductionVisible() && premiumReductionReceived === '1';
  };

  const onPremiumReductionReceivedChange = (newValue: string) => {
    setPremiumReductionReceived(newValue);
    setErrors({
      ...errors,
      premiumReductionReceived: isPremiumReductionVisible()
        ? requiredValidation(newValue, props.errorLabels ?? {})
        : '',
    });
  };

  const onPremiumReductionAmountChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const newValue = event.target.value;
    setPremiumReductionAmount(newValue);
    setErrors({
      ...errors,
      premiumReductionAmount: isPremiumReductionAmountVisible()
        ? minMaxValueValidation(newValue, 10, 9999999, props.errorLabels ? props.errorLabels : {})
        : '',
    });
  };

  const validateForm = (): boolean => {
    const newErrors = getFormErrors();
    setErrors(newErrors);
    const errorsPresent = Object.values(newErrors).some((value) => value !== '');
    return !errorsPresent;
  };

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    if (validateForm() && props.dispatch) {
      props.dispatch({
        type: IncomingDataAction.SUBMIT,
        payload: getIncomingData(),
        demandId: props.demandId,
      });
    }
    event.preventDefault();
  };

  return (
    <>
      <form onSubmit={onSubmit}>
        <div className="form-element-container">
          <ZoomRadioInput
            value={socialCareReceived}
            error={!!errors.socialCareReceived}
            errorMessage={errors.socialCareReceived}
            title={props.labelSocialCareReceived ? props.labelSocialCareReceived : ''}
            label1={props.toggleButtonsTypes ? props.toggleButtonsTypes[0].name : ''}
            value1={props.toggleButtonsTypes ? props.toggleButtonsTypes[0].value.toString() : ''}
            label2={props.toggleButtonsTypes ? props.toggleButtonsTypes[1].name : ''}
            value2={props.toggleButtonsTypes ? props.toggleButtonsTypes[1].value.toString() : ''}
            onChange={onChangeSocialCareReceivedType}
          ></ZoomRadioInput>
        </div>
        <div className="form-element-container">
          <ZoomTextInput
            label={props.labelSalaryNetPif ? props.labelSalaryNetPif : ''}
            name="salaryNetPif"
            value={salaryNetPif}
            onChange={onChange}
            error={!!errors.salaryNetPif}
            errorMessage={errors.salaryNetPif}
            helpText={props.helpTextSalaryNetPif ? props.helpTextSalaryNetPif : ''}
          ></ZoomTextInput>
        </div>
        <div className="form-element-container">
          <ZoomTextInput
            label={props.labelPensionAmount ? props.labelPensionAmount : ''}
            name="pensionAmount"
            value={pensionAmount}
            onChange={onChange}
            error={!!errors.pensionAmount}
            errorMessage={errors.pensionAmount}
          ></ZoomTextInput>
        </div>
        {parseInt(pensionAmount) > 0 && (
          <div className="form-element-container">
            <ZoomTextInput
              label={props.labelAdditionalAssistance ? props.labelAdditionalAssistance : ''}
              name="additionalAssistance"
              value={additionalAssistance}
              onChange={onChange}
              error={!!errors.additionalAssistance}
              errorMessage={errors.additionalAssistance}
            ></ZoomTextInput>
          </div>
        )}
        <div className="form-element-container">
          <ZoomTextInput
            label={props.labelAdditionalRevenu ? props.labelAdditionalRevenu : ''}
            name="additionalRevenu"
            value={additionalRevenu}
            onChange={onChange}
            error={!!errors.additionalRevenu}
            errorMessage={errors.additionalRevenu}
          ></ZoomTextInput>
        </div>
        <div className="form-element-container">
          <ZoomTextInput
            label={props.labelFortunePif ? props.labelFortunePif : ''}
            name="fortunePif"
            value={fortunePif}
            onChange={onChange}
            error={!!errors.fortunePif}
            errorMessage={errors.fortunePif}
            helpText={props.helpTextFortunePif ? props.helpTextFortunePif : ''}
          ></ZoomTextInput>
        </div>
        <div className="form-element-container">
          <ZoomTextInput
            label={props.labelContributionsOthers ? props.labelContributionsOthers : ''}
            name="contributionsOthers"
            value={contributionsOthers}
            onChange={onChange}
            error={!!errors.contributionsOthers}
            errorMessage={errors.contributionsOthers}
            helpText={props.helpTextContributionsOthers ? props.helpTextContributionsOthers : ''}
          ></ZoomTextInput>
        </div>
        {props.personalDataCivilStatus && (
          <div className="form-element-container">
            <ZoomTextInput
              label={props.labelAlimonyReceivedPartner ? props.labelAlimonyReceivedPartner : ''}
              name="alimonyReceivedPartner"
              value={alimonyReceivedPartner}
              onChange={onChange}
              error={!!errors.alimonyReceivedPartner}
              errorMessage={errors.alimonyReceivedPartner}
            ></ZoomTextInput>
          </div>
        )}
        {isPremiumReductionVisible() && (
          <div className="form-element-container">
            <ZoomToggleInput
              name="premiumReductionReceived"
              title={props.labelPremiumReductionReceived}
              value={props.premiumReductionReceived}
              label1={props.toggleButtonsTypes ? props.toggleButtonsTypes[0].name : ''}
              value1={props.toggleButtonsTypes ? props.toggleButtonsTypes[0].value.toString() : ''}
              label2={props.toggleButtonsTypes ? props.toggleButtonsTypes[1].name : ''}
              value2={props.toggleButtonsTypes ? props.toggleButtonsTypes[1].value.toString() : ''}
              onChange={onPremiumReductionReceivedChange}
            ></ZoomToggleInput>
          </div>
        )}

        {isPremiumReductionAmountVisible() && (
          <div className="form-element-container">
            <ZoomTextInput
              name="premiumReductionAmount"
              label={props.labelPremiumReductionAmount}
              onChange={onPremiumReductionAmountChange}
              value={premiumReductionAmount}
              error={!!errors.premiumReductionAmount}
              errorMessage={errors.premiumReductionAmount}
            ></ZoomTextInput>
          </div>
        )}
        <div className="form-actions-container">
          <Button color="primary" type="submit" variant="contained" id="submit-incoming-data">
            {props.labelSave}
          </Button>
        </div>
      </form>
    </>
  );
};

// Map global store to component parameters
// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export function mapStateToProps(store: WIAAStore, ownProps: any): IncomingDataFormProps {
  const { demandId } = ownProps.match.params;
  const props: IncomingDataFormProps = {
    labelPremiumReductionReceived: i18nLabel(
      store.translationLabels,
      'Incoming_Premium_Reduction_Received',
      'Erhalten Sie Prämienverbillingungen?*',
    ),
    labelPremiumReductionAmount: i18nLabel(
      store.translationLabels,
      'Incoming_Premium_Reduction_Amount',
      'Betrag pro Jahr Fr.*',
    ),
    labelSocialCareReceived: i18nLabel(
      store.translationLabels,
      'Incoming_Social_Care_Received',
      'Beziehen Sie Sozialhilfe?*',
    ),
    labelSalaryNetPif: i18nLabel(store.translationLabels, 'Incoming_Salary_Net_Pif', 'Nettolohn pro Jahr Fr.*'),
    labelPensionAmount: i18nLabel(
      store.translationLabels,
      'Incoming_Pension_Amount',
      'Renten (AHV/IV/PK) pro Jahr Fr.*',
    ),
    labelAdditionalAssistance: i18nLabel(
      store.translationLabels,
      'Incoming_Additional_Assistance',
      'Ergänzungsleistungen pro Jahr Fr.*',
    ),
    labelAdditionalRevenu: i18nLabel(
      store.translationLabels,
      'Incoming_Additional_Revenu',
      'Weitere Einkünfte wie EO, ALV etc. pro Jahr Fr.*',
    ),
    labelFortunePif: i18nLabel(
      store.translationLabels,
      'Incoming_Fortune_Pif',
      'Eigenes Vermögen* bei Gesuchseinreichung Fr.*',
    ),
    labelContributionsOthers: i18nLabel(
      store.translationLabels,
      'Incoming_Label_Contributions_Others',
      'Beiträge von Privaten, Gemeinden, Stiftungen (ohne Beiträge der Eltern) pro Jahr Fr.*',
    ),
    labelAlimonyReceivedPartner: i18nLabel(
      store.translationLabels,
      'Incoming_Alimony_Received_Partner',
      'Unterhaltsbeiträge vom ehemaligen Partner / von der ehemaligen Partnerin für Person in Ausbildung pro Jahr Fr.*',
    ),
    labelSave: i18nLabel(store.translationLabels, 'Incoming_Save', 'Speichern'),
    helpTextSalaryNetPif: i18nLabel(
      store.translationLabels,
      'Incoming_Salary_Net_Pif_Help_Text',
      'Der Nettolohn berechnen wir wie folgt: Bei 13 Monatslöhnen = 12 mal Bruttomonatslohn Bei 12 Monatslöhnen = 11 mal Bruttomonatslohn',
    ),
    helpTextFortunePif: i18nLabel(
      store.translationLabels,
      'Incoming_Fortune_Pif_Help_Text',
      'Sämtliche Vermögenswerte (z.B. Sparguthaben, Wertschriften, Immobilien, Bargeldbestand etc.) abzüglich Schulden; Kontostand bei Gesuchseinreichung.',
    ),
    helpTextContributionsOthers: i18nLabel(
      store.translationLabels,
      'Incoming_Contributions_Others_Help_Text',
      'Gemäss §19 Abs1 StipV sind Leistungen und Zuwendungen Dritter, namentlich von Privatpersonen, Gemeinden und Stiftungen, die nach Beginn einer Beitragsperiode zugesprochen werden, unverzüglich zu deklarieren und werden in der laufenden Beitragsperiode berücksichtigt. Haben Sie solche Einkünfte im letzten Ausbildungsjahr erzielt?',
    ),
    toggleButtonsTypes: store.translationOptions['toggle-buttons']
      ? [
          ...store.translationOptions['toggle-buttons'].map((option) => {
            return { value: option.id.toString(), name: option.name };
          }),
        ]
      : [],
    errorLabels: store.translationLabels,
    personalDataCivilStatus: true,
    demandId: Number(demandId),
  };
  const data = store.incomingData;
  if (data) {
    props.socialCareReceived = data.socialCareReceived ? '1' : '2';
    props.salaryNetPif = data.salaryNetPif ? data.salaryNetPif.toString() : '';
    props.pensionAmount = data.pensionAmount ? data.pensionAmount.toString() : '';
    props.additionalAssistance = data.additionalAssistance ? data.additionalAssistance.toString() : '';
    props.additionalRevenu = data.additionalRevenue ? data.additionalRevenue.toString() : '';
    props.fortunePif = data.fortunePif ? data.fortunePif.toString() : '';
    props.contributionsOthers = data.contributionsOthers ? data.contributionsOthers.toString() : '';
    props.alimonyReceivedPartner = data.alimonyReceivedPartner ? data.alimonyReceivedPartner.toString() : '';
    props.personalDataCivilStatus = store.personalData.maritalStatus === 41 || store.personalData.maritalStatus === 42;
    props.age = getAge(store.personalData.dateOfBirth);
    props.premiumReductionReceived = extractToggleValue(data.premiumReductionReceived);
    props.premiumReductionAmount = data.premiumReductionAmount ? data.premiumReductionAmount.toString() : '';
  }
  return props;
}

// default export the connected component
export default withRouter(connect(mapStateToProps)(IncomingDataForm));
