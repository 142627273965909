import {
  Breadcrumbs,
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Container,
  Grid,
  Link,
  Modal,
} from '@material-ui/core';
import produce from 'immer';
import React, { ReactElement, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Action } from 'redux';
import ZoomSelectInput from '../../components/forms/select-input/ZoomSelectInput';
import {
  AuthoritiesDataAction,
  AuthoritiesDataAddAuthorityAction,
  AuthoritiesDataDeleteAuthorityAction,
  AuthoritiesDataEditAuthorityAction,
  AuthoritiesDataGetAction,
} from '../../store/authorities/actions';
import { AuthoritiesDataStore } from '../../store/authorities/types';
import { i18nLabel } from '../../store/i18n/helpers';
import { WIAAStore } from '../../store/types';
import { isPersonUnder18 } from '../../utils/date-manipulation';
import ZoomFooter, {
  mapStoreToFooterProps,
  ZoomFooterProps,
} from './../../components/base-page-components/footer/ZoomFooter';
import ZoomHeader, {
  mapStoreToHeaderProps,
  ZoomHeaderProps,
} from './../../components/base-page-components/header/ZoomHeader';
import { PowerOfAttorneyOverviewItemForm } from '../../containers/power-of-attorney-overview-item-form/PowerOfAttorneyOverviewItemForm';
import './MyPowerOfAttorneysOverview.scss';
import { NavigationItems } from '../../components/base-page-components/navigation/Navigation';
import { mapStoreToHolderDropDownProps } from '../../utils/mappers';
import { HolderDropDownProps } from '../../components/base-page-components/holder-dropdown/HolderDropDown';
import { HolderDataAction, HolderDataGetAction } from '../../store/holder-data/actions';

export interface MyPowerOfAttorneysOverviewLabels {
  labelTitle?: string;
  labelDescription?: string;
  labelAddNewAttorney?: string;
  labelDelete?: string;
  labelChangeRights?: string;
  labelRole?: string;
  labelFirstName?: string;
  labelLastName?: string;
  labelEmailAddress?: string;
  labelEmailAddressConfirmation?: string;
  labelRights?: string;
  labelSave?: string;
  labelCancel?: string;
  headerProps: ZoomHeaderProps;
  footerProps: ZoomFooterProps;
  breadcrumbLabels?: Array<string>;
  labelBackArrow?: string;
  errorLabels?: { [key: string]: string };
  contentLabelAcknowledgementTitle?: string;
  contentLabelChangeRightsAcknowledgementTitle?: string;
  contentLabelAcknowledgementParagraph?: string;
  contentLabelChangeRightsAcknowledgementParagraph?: string;
  contentLabelModalCancel?: string;
  contentLabelAcknowledgementSubmit?: string;
  piaUnder18?: boolean;
  labelPiaUnder18: string;
}

export interface AttorneyProps {
  id?: number;
  role?: string;
  firstName?: string;
  lastName?: string;
  emailAddress?: string;
  rights?: string;
  changeRights?: boolean;
}

export interface MyPowerOfAttorneysOverviewProps extends MyPowerOfAttorneysOverviewLabels {
  dossierId: number;
  attorneys: Array<AttorneyProps>;
  attorneysBeingEdited: Array<number>;
  newAttorneyBeingCreated: boolean;
  isLoading?: boolean;
  dispatch?: (action: Action) => void;
  rolesTypes?: { name: string; value: string }[];
  rightsTypes?: { name: string; value: string }[];
  holderDropDownProps: HolderDropDownProps;
}

export const MyPowerOfAttorneysOverview = (props: MyPowerOfAttorneysOverviewProps): ReactElement => {
  const [attorneys, setAttorneys] = useState(props.attorneys ? props.attorneys : []);
  const [newAttorneyBeingCreated, setNewAttorneyBeingCreated] = useState(
    props.newAttorneyBeingCreated ? props.newAttorneyBeingCreated : false,
  );
  const [openAcknowlegde, setOpenAcknowledge] = useState(false);
  const [openChangeRightsAcknowledge, setOpenChangeRightsAcknowledge] = useState(false);
  const [editingAttorney, setEditingAttorney] = useState(false);
  const [attorneyBeingEdited, setAttorneyBeingEdited] = useState(
    props.attorneysBeingEdited ? props.attorneysBeingEdited : [],
  );
  const [attorneyIndexToDelete, setAttorneyIndexToDelete] = useState(-1);
  const [newValue, setNewValue] = useState('');
  const [idCurrentlyEditedAttorney, setIdCurrentlyEditedAttorney] = useState(-1);

  useEffect(() => {
    if (props.dispatch) {
      const getAuthoritiesData: AuthoritiesDataGetAction = {
        type: AuthoritiesDataAction.GET,
        dossierId: props.dossierId,
      };
      props.dispatch(getAuthoritiesData);
    }
  }, []);

  useEffect(() => {
    setAttorneys(props.attorneys ? props.attorneys : []);
  }, [props.attorneys]);

  useEffect(() => {
    if (props.dispatch && props.dossierId) {
      const getHolderData: HolderDataGetAction = {
        type: HolderDataAction.GET,
      };
      props.dispatch(getHolderData);
    }
  }, []);

  const onSave = (index: number, attorney: AttorneyProps) => {
    if (props.dispatch) {
      const authoritiesDataAction: AuthoritiesDataAddAuthorityAction = {
        type: AuthoritiesDataAction.ADD_ITEM,
        payload: {
          permissionTypeId: Number(attorney.rights),
          roleId: Number(attorney.role),
          firstName: attorney.firstName,
          lastName: attorney.lastName,
          email: attorney.emailAddress,
          changeRights: false,
        } as AttorneyProps,
        dossierId: props.dossierId,
      };

      props.dispatch(authoritiesDataAction);
      setNewAttorneyBeingCreated(false);
    }

    const nextStateBeingEdited = produce(attorneyBeingEdited, (draft) => {
      const indexToDelete = draft.findIndex((i) => i === index);
      if (indexToDelete > -1) {
        draft.splice(indexToDelete, 1);
      }
    });
    setAttorneyBeingEdited(nextStateBeingEdited);
  };

  const onCancel = () => {
    setNewAttorneyBeingCreated(false);
    const nextStateAttornies = produce(attorneys, (draft) => {
      draft.splice(attorneys.length - 1, 1);
    });
    setAttorneys(nextStateAttornies);
  };

  const onDelete = (index: number) => {
    setOpenAcknowledge(true);
    setAttorneyIndexToDelete(index);
  };

  const onDeleteModalButtonHandler = () => {
    const attorneyItemEmail = attorneys[attorneyIndexToDelete].emailAddress;
    const nextStateAttornies = produce(attorneys, (draft) => {
      draft.splice(attorneyIndexToDelete, 1);
    });
    setAttorneys(nextStateAttornies);

    const nextStateBeingEdited = produce(attorneyBeingEdited, (draft) => {
      const indexToDelete = draft.findIndex((i) => i === attorneyIndexToDelete);
      if (indexToDelete > -1) {
        draft.splice(indexToDelete, 1);
      }
    });
    setAttorneyBeingEdited(nextStateBeingEdited);

    if (attorneyItemEmail && attorneyItemEmail !== '' && props.dispatch) {
      const deleteAttorneyData: AuthoritiesDataDeleteAuthorityAction = {
        type: AuthoritiesDataAction.DELETE_ITEM,
        payload: attorneyItemEmail,
        dossierId: props.dossierId,
      };
      props.dispatch(deleteAttorneyData);
    }
    setOpenAcknowledge(false);
  };

  const addNewAttorney = () => {
    const nextState = produce(attorneys, (draft) => {
      draft.push({});
    });
    setAttorneys(nextState);
    const nextStateBeingEdited = produce(attorneyBeingEdited, (draft) => {
      draft.push(nextState.length - 1);
    });
    setAttorneyBeingEdited(nextStateBeingEdited);
  };

  const onClickCreateNewAttorney = (flag: boolean) => {
    setNewAttorneyBeingCreated(flag);
    const cancelRightsChange: AttorneyProps[] = produce(attorneys, (draft) => {
      draft.forEach((current) => {
        current.changeRights = false;
      });
      return draft;
    });
    setAttorneys(cancelRightsChange);
    addNewAttorney();
  };

  const onChangeRights = (index: number) => {
    const changedRights: AttorneyProps[] = produce(attorneys, (draft) => {
      draft[index].changeRights = !draft[index].changeRights;
      return draft;
    });
    setAttorneys(changedRights);
    setEditingAttorney(!editingAttorney);
  };

  const onRightsTypeChange = (newValue: string, attorneyId: number) => {
    setNewValue(newValue);
    setIdCurrentlyEditedAttorney(attorneyId);
    setOpenChangeRightsAcknowledge(true);
  };

  const onChangeRightsModalCancelButtonHandler = () => {
    const changedRights: AttorneyProps[] = produce(attorneys, (draft) => {
      draft.forEach((att) => {
        att.changeRights = false;
      });
      return draft;
    });
    setAttorneys(changedRights);
    setEditingAttorney(!editingAttorney);
    setOpenChangeRightsAcknowledge(false);
    setEditingAttorney(!editingAttorney);
    setNewValue('');
    setIdCurrentlyEditedAttorney(-1);
  };

  const onChangeRightsModalButtonHandler = () => {
    const changedRights: AttorneyProps[] = produce(attorneys, (draft) => {
      const index = draft.findIndex((a) => a.id == idCurrentlyEditedAttorney);
      draft[index].rights = newValue;
      draft[index].changeRights = !draft[index].changeRights;
      if (props.dispatch) {
        const editAttorneyData: AuthoritiesDataEditAuthorityAction = {
          type: AuthoritiesDataAction.EDIT_ITEM,
          payload: {
            permissionId: draft[index].id,
            permissionTypeId: Number(draft[index].rights),
            roleId: Number(draft[index].role),
            firstName: draft[index].firstName,
            lastName: draft[index].lastName,
            email: draft[index].emailAddress,
            changeRights: false,
          } as AttorneyProps,
        };
        props.dispatch(editAttorneyData);
      }
      return draft;
    });
    setAttorneys(changedRights);
    setEditingAttorney(!editingAttorney);
    setOpenChangeRightsAcknowledge(false);
  };

  const attorneysToRender = attorneys.map((attorney, index) => {
    return (
      <Card className="info-card" key={index}>
        <CardContent>
          <div className="form-element-container info-card-row" style={{ borderTop: 'none' }}>
            <Grid container direction="row" alignItems="stretch" justify="flex-start">
              <Grid item xs={12} sm={6}>
                <div className="label-bottom-border">
                  <label>{props.labelRole ? props.labelRole : ''}</label>
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div className="label-bottom-border">
                  <label>
                    {props.rolesTypes ? props.rolesTypes.find((option) => option.value == attorney.role)?.name : ''}
                  </label>
                </div>
              </Grid>
            </Grid>
          </div>
          <div className="form-element-container info-card-row">
            <Grid container direction="row" alignItems="stretch" justify="flex-start">
              <Grid item xs={12} sm={6}>
                <div className="label-bottom-border">
                  <label>{props.labelFirstName ? props.labelFirstName : ''}</label>
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div className="label-bottom-border">
                  <label>{attorney.firstName}</label>
                </div>
              </Grid>
            </Grid>
          </div>
          <div className="form-element-container info-card-row">
            <Grid container direction="row" alignItems="stretch" justify="flex-start">
              <Grid item xs={12} sm={6}>
                <div className="label-bottom-border">
                  <label>{props.labelLastName ? props.labelLastName : ''}</label>
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div className="label-bottom-border">
                  <label>{attorney.lastName}</label>
                </div>
              </Grid>
            </Grid>
          </div>
          <div className="form-element-container info-card-row">
            <Grid container direction="row" alignItems="stretch" justify="flex-start">
              <Grid item xs={12} sm={6}>
                <div className="label-bottom-border">
                  <label>{props.labelEmailAddress ? props.labelEmailAddress : ''}</label>
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div className="label-bottom-border">
                  <label>{attorney.emailAddress}</label>
                </div>
              </Grid>
            </Grid>
          </div>
          <div className="form-element-container info-card-row">
            <Grid container direction="row" alignItems="stretch" justify="flex-start">
              {attorney.changeRights === true ? (
                <Grid item xs={12} className="change-rights-grid-form">
                  <ZoomSelectInput
                    label={props.labelRights ? props.labelRights : ''}
                    name="rights"
                    options={props.rightsTypes ? props.rightsTypes.slice(1) : []}
                    value={attorney.rights}
                    onChange={(event) => onRightsTypeChange(event, attorney.id ? attorney.id : -1)}
                  />
                </Grid>
              ) : (
                <>
                  <Grid item xs={12} sm={6}>
                    <div className="label-bottom-border">
                      <label>{props.labelRights ? props.labelRights : ''}</label>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <div className="label-bottom-border">
                      <label>
                        {props.rightsTypes
                          ? props.rightsTypes.find((option) => option.value == attorney.rights)?.name
                          : ''}
                      </label>
                    </div>
                  </Grid>
                </>
              )}
            </Grid>
          </div>
        </CardContent>
        <CardActions className="form-actions-container">
          {Number(attorney.rights) != 1 && (
            <>
              <Button id="delete-attorney" color="primary" variant="outlined" onClick={() => onDelete(index)}>
                {props.labelDelete}
              </Button>
              <Button id="edit-attorney" color="primary" variant="outlined" onClick={() => onChangeRights(index)}>
                {props.labelChangeRights}
              </Button>
            </>
          )}
        </CardActions>
      </Card>
    );
  });

  return (
    <>
      <ZoomHeader {...props.headerProps} dispatch={props.dispatch} />
      <main className="main">
        {props.isLoading ? (
          <div style={{ textAlign: 'center' }}>
            <CircularProgress />
          </div>
        ) : (
          <>
            <Container>
              <div id="main-page-container">
                <div>
                  <Breadcrumbs separator=">" aria-label="breadcrumb">
                    <Link
                      style={{ fontFamily: 'Proxima Nova Cond' }}
                      color="inherit"
                      href={'/dashboard/dossier/' + props.dossierId}
                    >
                      <b>{props.breadcrumbLabels && props.breadcrumbLabels[0]}</b>
                    </Link>
                    <Link style={{ fontFamily: 'Proxima Nova Cond' }} color="inherit">
                      {props.breadcrumbLabels && props.breadcrumbLabels[1]}
                    </Link>
                  </Breadcrumbs>
                  <NavigationItems
                    title={props.labelTitle}
                    labelBackArrow={props.labelBackArrow}
                    holderDropDownProps={props.holderDropDownProps}
                    dispatch={props.dispatch}
                    mainPageTopInfo={true}
                  />
                </div>
                <p
                  id="ul-list"
                  dangerouslySetInnerHTML={{
                    __html: props.labelDescription ? props.labelDescription : '',
                  }}
                />
                {props.piaUnder18 && (
                  <h3 className="poa-error" id="pia-under-18-warning">
                    {props.labelPiaUnder18}
                  </h3>
                )}
                {!newAttorneyBeingCreated && !props.piaUnder18 && (
                  <Button
                    id="add-attorney"
                    color="primary"
                    variant="contained"
                    onClick={() => onClickCreateNewAttorney(true)}
                  >
                    {props.labelAddNewAttorney}
                  </Button>
                )}
                {newAttorneyBeingCreated && (
                  <div className="attorney-form-wrapper">
                    <PowerOfAttorneyOverviewItemForm
                      {...props}
                      onCancel={onCancel}
                      onSave={(attorney) => {
                        onSave(attorneys.length - 1, attorney);
                      }}
                    />
                  </div>
                )}
                {!newAttorneyBeingCreated && attorneysToRender}
              </div>

              <Modal
                open={openAcknowlegde}
                aria-labelledby="power-of-attorney-delete-acknowledge"
                aria-describedby="power-of-attorney-delete-acknowledge-description"
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
              >
                <div className="power-of-attorney-modal">
                  <h1 id="power-of-attorney-delete-acknowledge">{props.contentLabelAcknowledgementTitle}</h1>
                  <div id="power-of-attorney-delete-acknowledge-description">
                    <p>{props.contentLabelAcknowledgementParagraph}</p>
                  </div>

                  <div className="power-of-attorney-modal-actions">
                    <Button color="primary" variant="outlined" fullWidth onClick={() => setOpenAcknowledge(false)}>
                      {props.contentLabelModalCancel}
                    </Button>
                    <Button color="primary" variant="contained" fullWidth onClick={() => onDeleteModalButtonHandler()}>
                      {props.contentLabelAcknowledgementSubmit}
                    </Button>
                  </div>
                </div>
              </Modal>
              <Modal
                open={openChangeRightsAcknowledge}
                aria-labelledby="power-of-attorney-change-rights-acknowledge"
                aria-describedby="power-of-attorney-change-rights-acknowledge-description"
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
              >
                <div className="power-of-attorney-modal">
                  <h1 id="power-of-attorney-change-rights-acknowledge">
                    {props.contentLabelChangeRightsAcknowledgementTitle}
                  </h1>
                  <div id="power-of-attorney-change-rights-acknowledge-description">
                    <p>{props.contentLabelChangeRightsAcknowledgementParagraph}</p>
                  </div>

                  <div className="power-of-attorney-modal-actions">
                    <Button
                      color="primary"
                      variant="outlined"
                      fullWidth
                      onClick={() => onChangeRightsModalCancelButtonHandler()}
                    >
                      {props.contentLabelModalCancel}
                    </Button>
                    <Button
                      color="primary"
                      variant="contained"
                      fullWidth
                      onClick={() => onChangeRightsModalButtonHandler()}
                    >
                      {props.contentLabelAcknowledgementSubmit}
                    </Button>
                  </div>
                </div>
              </Modal>
            </Container>
          </>
        )}
      </main>
      <ZoomFooter {...props.footerProps} />
    </>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export function mapStateToProps(store: WIAAStore, ownProps: any): MyPowerOfAttorneysOverviewProps {
  const { dossierId } = ownProps.match.params;

  const props: MyPowerOfAttorneysOverviewProps = {
    labelTitle: i18nLabel(store.translationLabels, 'My_Power_Of_Attorney_Overview_Title', 'Vollmachten'),
    labelDescription: i18nLabel(
      store.translationLabels,
      'My_Power_Of_Attorney_Overview_Description',
      'Hier können Sie weitere bevollmächtigte Personen hinzufügen, deren Rechte ändern oder löschen. Es gibt drei unterschiedliche Rechte:<br/><br/>Eingeschränkter Zugriff<br/><ul><li>Antrag erfassen, aber keine Antragseinreichung.</li><li>Ausbildungsstand melden</li><li>Mitteilungen senden und lesen</li><li>Informationen per SMS/E-Mail erhalten</li><li>Stipendienantrag und Stipendienentscheid (ohne Berechnungsblatt) lesen.</li></ul><br/>Recht Zugriff nur auf Entscheid<ul><li>Stipendienantrag und Stipendienentscheid (ohne Berechnungsblatt) lesen.</li></ul><br/>Berechtigungen des Typs "Voller Zugriff" (inklusive Antragseinreichung) können nur über die Sektion Stipendien beantragt oder geändert werden. Verwenden Sie bitte die Mitteilungsfunktion, um entsprechende Anliegen vorzubringen.',
    ),
    labelRole: i18nLabel(store.translationLabels, 'My_Power_Of_Attorney_Overview_Title_Role', 'Rolle*'),
    labelFirstName: i18nLabel(store.translationLabels, 'My_Power_Of_Attorney_Overview_First_Name', 'Vorname*'),
    labelLastName: i18nLabel(store.translationLabels, 'My_Power_Of_Attorney_Overview_Last_Name', 'Nachname*'),
    labelEmailAddress: i18nLabel(store.translationLabels, 'My_Power_Of_Attorney_Overview_Email', 'E-mail*'),
    labelEmailAddressConfirmation: i18nLabel(
      store.translationLabels,
      'My_Power_Of_Attorney_Overview_Email_Confirmation',
      'E-mail bestätigen*',
    ),
    labelRights: i18nLabel(store.translationLabels, 'My_Power_Of_Attorney_Overview_Rights', 'Rechte*'),
    labelSave: i18nLabel(store.translationLabels, 'My_Power_Of_Attorney_Overview_Save', 'Speichern'),
    labelCancel: i18nLabel(store.translationLabels, 'My_Power_Of_Attorney_Overview_Cancel', 'Abbrechen'),
    errorLabels: store.translationLabels,
    labelAddNewAttorney: i18nLabel(
      store.translationLabels,
      'My_Power_Of_Attorney_Overview_Add_New_Attorney',
      'Neue Vollmachten hinzufügen',
    ),
    labelDelete: i18nLabel(store.translationLabels, 'My_Power_Of_Attorney_Overview_Delete_Attorney', 'Löschen'),
    labelChangeRights: i18nLabel(
      store.translationLabels,
      'My_Power_Of_Attorney_Overview_Change_Rights',
      'Rechte ändern',
    ),
    breadcrumbLabels: [
      i18nLabel(store.translationLabels, 'My_Power_Of_Attorney_Overview_Breadcrumb_1', 'Übersicht'),
      i18nLabel(store.translationLabels, 'My_Power_Of_Attorney_Overview_Breadcrumb_2', 'Vollmachten'),
    ],
    labelBackArrow: i18nLabel(store.translationLabels, 'My_Power_Of_Attorney_Overview_Go_Back', 'Zurück'),
    contentLabelAcknowledgementTitle: i18nLabel(
      store.translationLabels,
      'My_Power_Of_Attorney_Overview_Delete_Message_Title',
      'Vollmacht löschen',
    ),
    contentLabelChangeRightsAcknowledgementTitle: i18nLabel(
      store.translationLabels,
      'My_Power_Of_Attorney_Overview_Change_Rights_Message_Title',
      'Rechte ändern',
    ),
    contentLabelAcknowledgementParagraph: i18nLabel(
      store.translationLabels,
      'My_Power_Of_Attorney_Overview_Delete_Message_Text',
      'Sind Sie sicher, dass Sie die ausgewählte Vollmacht löschen möchten?',
    ),
    contentLabelChangeRightsAcknowledgementParagraph: i18nLabel(
      store.translationLabels,
      'My_Power_Of_Attorney_Overview_Change_Rights_Message_Text',
      'Sind Sie sicher, dass Sie für die ausgewählte Vollmacht die Rechte ändern möchten?',
    ),
    contentLabelModalCancel: i18nLabel(
      store.translationLabels,
      'My_Power_Of_Attorney_Overview_Delete_Message_Cancel',
      'Abbrechen',
    ),
    contentLabelAcknowledgementSubmit: i18nLabel(
      store.translationLabels,
      'My_Power_Of_Attorney_Overview_Delete_Message_Confirm',
      'Bestätigen',
    ),
    labelPiaUnder18: i18nLabel(
      store.translationLabels,
      'My_Power_Of_Attorney_Pia_Under_18_Message',
      'Sie können keine weiteren Personen bevollmächtigen. Die Anpassung muss von der momentan bevollmächtigten Person vorgenommen werden.',
    ),
    piaUnder18: isPersonUnder18(store.userData.birthDate),
    dossierId: dossierId,
    holderDropDownProps: mapStoreToHolderDropDownProps(store, parseInt(dossierId)),
    attorneys: [],
    attorneysBeingEdited: [],
    newAttorneyBeingCreated: false,
    headerProps: mapStoreToHeaderProps(store, dossierId),
    footerProps: mapStoreToFooterProps(store),
    isLoading: store.authoritiesData.isLoading,
    rightsTypes: store.translationOptions['rights-types']
      ? [
          ...store.translationOptions['rights-types'].map((option) => {
            return { value: option.id.toString(), name: option.name };
          }),
        ]
      : [],
    rolesTypes: store.translationOptions['authorities-roles-types']
      ? [
          ...store.translationOptions['authorities-roles-types'].map((option) => {
            return { value: option.id.toString(), name: option.name };
          }),
        ]
      : [],
  };

  const data: AuthoritiesDataStore = store.authoritiesData;
  if (data && data.authorities) {
    props.attorneys = Object.values(data.authorities).map(
      (authority) =>
        ({
          id: authority.permissionId,
          role: authority.roleId,
          firstName: authority.firstName,
          lastName: authority.lastName,
          emailAddress: authority.email,
          rights: authority.permissionTypeId,
          changeRights: false,
        } as AttorneyProps),
    );
  }

  return props;
}

export default withRouter(connect(mapStateToProps)(MyPowerOfAttorneysOverview));
