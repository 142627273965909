import produce from 'immer';
import { GuardianDataAction, GuardianDataActionType } from './actions';
import { GuardianData } from './types';

export const initialState: GuardianData = {};

export function guardianDataReducer(state = initialState, action: GuardianDataActionType): GuardianData {
  return produce(state, (draft) => {
    switch (action.type) {
      case GuardianDataAction.RESULT:
        draft = action.payload;
        break;
      case GuardianDataAction.GET_RESULT:
        draft = action.payload;
        break;
      case GuardianDataAction.GET_VORMUND_POSTAL_CODES:
        draft = action.payload;
        break;
      case GuardianDataAction.GET_VORMUND_POSTAL_CODES_RESULT:
        const postalCode = action.payload;
        draft = { ...state, zipCode: postalCode.zipCode, town: postalCode.extendedName };
        break;
    }
    return draft;
  });
}
