import { EditContactDataPage } from './types';

export enum EditContactDataPageAction {
  GET = 'EDIT_CONTACT_DATA_PAGE_DATA_GET',
  GET_RESULT = 'EDIT_CONTACT_DATA_PAGE_DATA_GET_RESULT',
  RESULT = 'EDIT_CONTACT_DATA_PAGE_DATA_RESULT',
  NON_EXISTENT_EDIT_CONTACT_DATA_PAGE = 'NON_EXISTENT_EDIT_CONTACT_DATA_PAGE',
  CHANGE = 'EDIT_CONTACT_DATA_PAGE_DATA_CHANGE',
  SUBMIT = 'EDIT_CONTACT_DATA_PAGE_DATA_SUBMIT',
}

export interface EditContactDataPageGetAction {
  type: typeof EditContactDataPageAction.GET;
  payload: number;
}

export interface EditContactDataPageGetResultAction {
  type: typeof EditContactDataPageAction.GET_RESULT;
  payload: EditContactDataPage;
}

export interface EditContactDataPageResultAction {
  type: typeof EditContactDataPageAction.RESULT;
  payload: EditContactDataPage;
}

export interface EditContactDataPageChangeAction {
  type: typeof EditContactDataPageAction.CHANGE;
  payload: number;
  changeField: string;
}

export interface EditContactDataPageSubmitAction {
  type: typeof EditContactDataPageAction.SUBMIT;
  payload: EditContactDataPage;
  dossierId: number;
}

export type EditContactDataPageActionType =
  | EditContactDataPageGetAction
  | EditContactDataPageGetResultAction
  | EditContactDataPageResultAction
  | EditContactDataPageChangeAction
  | EditContactDataPageSubmitAction;
