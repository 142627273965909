import produce from 'immer';
import { LastDemandDataTakeoverAction, LastDemandDataType } from '../last-demand-data-takeover/actions';
import { FatherDataAction, FatherDataActionType } from './actions';
import { FatherData } from './types';

export const initialState: FatherData = {};

export function fatherDataReducer(state = initialState, action: FatherDataActionType | LastDemandDataType): FatherData {
  return produce(state, (draft) => {
    switch (action.type) {
      case FatherDataAction.RESULT:
        draft = action.payload;
        break;
      case FatherDataAction.GET_RESULT:
        draft = action.payload;
        break;
      case FatherDataAction.GET_POSTAL_CODES:
        draft = action.payload;
        break;
      case FatherDataAction.GET_POSTAL_CODES_RESULT:
        const postalCode = action.payload;
        draft = { ...state, zipCode: postalCode.zipCode, town: postalCode.extendedName };
        break;
      case LastDemandDataTakeoverAction.RESULT:
        const lastDemandData = action.payload;
        draft = { ...lastDemandData.father };
        break;
    }
    return draft;
  });
}
