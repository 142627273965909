import React, { ReactElement } from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import { blue } from './../../theme';

export function ZoomArrowUpIcon(props: SvgIconProps): ReactElement {
  const color = props.htmlColor ? props.htmlColor : blue.main;
  return (
    <SvgIcon {...props}>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <path d="M0 0H80V80H0z" transform="translate(-256 -57) translate(256 57)" />
            <path
              fill={color}
              fillRule="nonzero"
              d="M62.4 53c-.522.003-1.024-.198-1.4-.56L40.52 32 20.06 52.44c-.781.781-2.049.781-2.83 0-.781-.781-.781-2.049 0-2.83l21.88-21.88c.375-.376.884-.587 1.415-.587.53 0 1.04.211 1.415.587l21.88 21.88c.556.575.716 1.425.407 2.163-.31.737-1.028 1.22-1.827 1.227z"
              transform="translate(-256 -57) translate(256 57)"
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}
