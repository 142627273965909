import { Button } from '@material-ui/core';
import React, { ChangeEvent, ReactElement, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import ZoomSelectInput from '../../components/forms/select-input/ZoomSelectInput';
import ZoomTextInput from '../../components/forms/text-input/ZoomTextInput';
import { i18nLabel } from '../../store/i18n/helpers';
import { WIAAStore } from '../../store/types';
import { emailComparisonValidation, emailValidation, requiredValidation } from '../../utils/validators';

export interface Attorney {
  id?: number;
  role?: string;
  firstName?: string;
  lastName?: string;
  emailAddress?: string;
  emailAddressConfirmation?: string;
  rights?: string;
}

export interface PowerOfAttorneyOverviewItemFormProps extends Attorney {
  labelRole?: string;
  labelFirstName?: string;
  labelLastName?: string;
  labelEmailAddress?: string;
  labelEmailAddressConfirmation?: string;
  labelRights?: string;
  labelSave?: string;
  labelCancel?: string;
  errorLabels?: { [key: string]: string };
  rolesTypes?: { name: string; value: string }[];
  rightsTypes?: { name: string; value: string }[];
  onSave?: (attorney: Attorney) => void;
  onCancel?: () => void;
}

interface PowerOfAttorneyOverviewItemFormDataErrors {
  id?: string;
  role?: string;
  firstName?: string;
  lastName?: string;
  emailAddress?: string;
  emailAddressConfirmation?: string;
  rights?: string;
}

export const PowerOfAttorneyOverviewItemForm = (props: PowerOfAttorneyOverviewItemFormProps): ReactElement => {
  const [id] = useState(props.id ? props.id : 0);
  const [role, setRole] = useState(props.role ? props.role.toString() : '5');
  const [firstName, setFirstName] = useState(props.firstName ? props.firstName : '');
  const [lastName, setLastName] = useState(props.lastName ? props.lastName : '');
  const [emailAddress, setEmailAddress] = useState(props.emailAddress ? props.emailAddress : '');
  const [emailAddressConfirmation, setEmailAddressConfirmation] = useState(
    props.emailAddressConfirmation ? props.emailAddressConfirmation : '',
  );
  const [rights, setRights] = useState(props.rights ? props.rights : '2');
  const initialErrors: PowerOfAttorneyOverviewItemFormDataErrors = {};
  const [errors, setErrors] = useState(initialErrors);

  useEffect(() => {
    setRole(props.role ? props.role.toString() : '5');
    setFirstName(props.firstName ? props.firstName : '');
    setLastName(props.lastName ? props.lastName : '');
    setEmailAddress(props.emailAddress ? props.emailAddress : '');
    setEmailAddressConfirmation(props.emailAddressConfirmation ? props.emailAddressConfirmation : '');
    setRights(props.rights ? props.rights : '2');
  }, [props]);

  const onChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    switch (e.target.name) {
      case 'firstName':
        setFirstName(e.target.value);
        setErrors({
          ...errors,
          firstName: requiredValidation(e.target.value, props.errorLabels ? props.errorLabels : {}),
        });
        break;
      case 'lastName':
        setLastName(e.target.value);
        setErrors({
          ...errors,
          lastName: requiredValidation(e.target.value, props.errorLabels ? props.errorLabels : {}),
        });
        break;
      case 'emailAddress':
        setEmailAddress(e.target.value);
        setErrors({
          ...errors,
          emailAddress: emailValidation(e.target.value, props.errorLabels ? props.errorLabels : {}),
        });
        break;
      case 'emailAddressConfirmation':
        setEmailAddressConfirmation(e.target.value);
        setErrors({
          ...errors,
          emailAddressConfirmation: emailValidation(e.target.value, props.errorLabels ? props.errorLabels : {}),
        });
        break;
    }
  };

  const onRoleTypeChange = (newValue: string) => {
    setRole(newValue);
  };

  const onRightsTypeChange = (newValue: string) => {
    setRights(newValue);
  };

  const getFormErrors = (): PowerOfAttorneyOverviewItemFormDataErrors => {
    const errors: PowerOfAttorneyOverviewItemFormDataErrors = {};
    errors.role = role === '' ? requiredValidation('', props.errorLabels ? props.errorLabels : {}) : '';
    errors.firstName = requiredValidation(firstName, props.errorLabels ? props.errorLabels : {});
    errors.lastName = requiredValidation(lastName, props.errorLabels ? props.errorLabels : {});
    errors.emailAddress = emailValidation(emailAddress, props.errorLabels ? props.errorLabels : {});
    errors.emailAddressConfirmation = emailValidation(
      emailAddressConfirmation,
      props.errorLabels ? props.errorLabels : {},
    );
    if (errors.emailAddress === '' && errors.emailAddressConfirmation === '') {
      errors.emailAddress = emailComparisonValidation(
        emailAddress,
        emailAddressConfirmation,
        props.errorLabels ? props.errorLabels : {},
      );
      errors.emailAddressConfirmation = emailComparisonValidation(
        emailAddress,
        emailAddressConfirmation,
        props.errorLabels ? props.errorLabels : {},
      );
    }
    return errors;
  };

  const validateForm = (): boolean => {
    const newErrors = getFormErrors();
    setErrors(newErrors);
    const errorsPresent = Object.values(newErrors).some((value) => value !== '');
    return !errorsPresent;
  };

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    if (validateForm()) {
    }
    event.preventDefault();
    const newErrors = getFormErrors();
    setErrors(newErrors);
    const errorsPresent = Object.values(newErrors).some((value) => value !== '');
    if (props.onSave && !errorsPresent) {
      props.onSave({
        id,
        role,
        firstName,
        lastName,
        emailAddress,
        emailAddressConfirmation,
        rights,
      });
    }
  };

  const onCancel = () => {
    if (props.onCancel) {
      props.onCancel();
    }
  };

  return (
    <>
      <form onSubmit={onSubmit}>
        <div className="form-element-container">
          <ZoomSelectInput
            label={props.labelRole ? props.labelRole : ''}
            name="role"
            options={props.rolesTypes ? props.rolesTypes : []}
            value={role}
            onChange={onRoleTypeChange}
            error={!!errors.role}
            errorMessage={errors.role}
          ></ZoomSelectInput>
        </div>
        <div className="form-element-container">
          <ZoomTextInput
            label={props.labelFirstName ? props.labelFirstName : ''}
            name="firstName"
            value={firstName}
            onChange={onChange}
            error={!!errors.firstName}
            errorMessage={errors.firstName}
          ></ZoomTextInput>
        </div>
        <div className="form-element-container">
          <ZoomTextInput
            label={props.labelLastName ? props.labelLastName : ''}
            name="lastName"
            value={lastName}
            onChange={onChange}
            error={!!errors.lastName}
            errorMessage={errors.lastName}
          ></ZoomTextInput>
        </div>
        <div className="form-element-container">
          <ZoomTextInput
            label={props.labelEmailAddress ? props.labelEmailAddress : ''}
            value={emailAddress}
            name="emailAddress"
            onChange={onChange}
            error={!!errors.emailAddress}
            errorMessage={errors.emailAddress}
          ></ZoomTextInput>
        </div>
        <div className="form-element-container">
          <ZoomTextInput
            label={props.labelEmailAddressConfirmation ? props.labelEmailAddressConfirmation : ''}
            value={emailAddressConfirmation}
            name="emailAddressConfirmation"
            onChange={onChange}
            error={!!errors.emailAddressConfirmation}
            errorMessage={errors.emailAddressConfirmation}
          ></ZoomTextInput>
        </div>
        <div className="form-element-container">
          <ZoomSelectInput
            label={props.labelRights ? props.labelRights : ''}
            name="rights"
            options={props.rightsTypes ? props.rightsTypes.slice(1) : []}
            value={rights}
            onChange={onRightsTypeChange}
          ></ZoomSelectInput>
        </div>
        <div className="form-actions-container">
          <Button
            data-testid="save-attorney-button"
            id="save-attorney-button"
            color="primary"
            type="submit"
            variant="contained"
            size="small"
            title={props.labelSave}
          >
            {props.labelSave}
          </Button>
          <Button
            data-testid="cancel-attorney-button"
            id="cancel-attorney-button"
            color="primary"
            variant="outlined"
            size="small"
            title={props.labelCancel}
            onClick={() => {
              onCancel();
            }}
          >
            {props.labelCancel}
          </Button>
        </div>
      </form>
    </>
  );
};

export function mapStateToProps(store: WIAAStore): PowerOfAttorneyOverviewItemFormProps {
  return {
    labelRole: i18nLabel(store.translationLabels, 'Power_Of_Attorney_Overview_Title_Role', 'Rolle*'),
    labelFirstName: i18nLabel(store.translationLabels, 'Power_Of_Attorney_Overview_First_Name', 'Vorname*'),
    labelLastName: i18nLabel(store.translationLabels, 'Power_Of_Attorney_Overview_Last_Name', 'Nachname*'),
    labelEmailAddress: i18nLabel(store.translationLabels, 'Power_Of_Attorney_Overview_Email', 'E-mail*'),
    labelEmailAddressConfirmation: i18nLabel(
      store.translationLabels,
      'Power_Of_Attorney_Overview_Email_Confirmation',
      'E-mail bestätigen*',
    ),
    labelRights: i18nLabel(store.translationLabels, 'Power_Of_Attorney_Overview_Right', 'Rechte*'),
    labelSave: i18nLabel(store.translationLabels, 'Power_Of_Attorney_Overview_Save', 'Speichern'),
    labelCancel: i18nLabel(store.translationLabels, 'Power_Of_Attorney_Overview_Cancel', 'Löschen'),
    errorLabels: store.translationLabels,
    rightsTypes: store.translationOptions['rights-types']
      ? [
          ...store.translationOptions['rights-types'].map((option) => {
            return { value: option.id.toString(), name: option.name };
          }),
        ]
      : [],
    rolesTypes: store.translationOptions['authorities-roles-types']
      ? [
          ...store.translationOptions['authorities-roles-types'].map((option) => {
            return { value: option.id.toString(), name: option.name };
          }),
        ]
      : [],
  };
}

export default connect(mapStateToProps)(PowerOfAttorneyOverviewItemForm);
