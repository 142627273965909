import { Holder } from '../../pages/scholarship-holder/ScholarshipHolder';

export enum HolderDataAction {
  GET = 'HOLDER_DATA_GET',
  GET_RESULT = 'HOLDER_DATA_GET_RESULT',
  GET_ERROR = 'HOLDER_DATA_GET_ERROR',
}

export interface HolderDataGetAction {
  type: typeof HolderDataAction.GET;
}

export interface HolderDataGetResultAction {
  type: typeof HolderDataAction.GET_RESULT;
  payload: Array<Holder>;
}

export interface HolderDataGetErrorAction {
  type: typeof HolderDataAction.GET_ERROR;
  payload: string;
}

export type HolderDataActionType = HolderDataGetAction | HolderDataGetResultAction | HolderDataGetErrorAction;
