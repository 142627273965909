import { i18nLabel } from '../store/i18n/helpers';

export const getDefaultSelectOption = (store: { [key: string]: string }): string => {
  return i18nLabel(store, 'DEFAULT_SELECT_OPTION', 'DEMO Bitte wählen');
};

export const maritalStatusOptions = [
  { name: 'ledig', value: '10' },
  { name: 'verheiratet', value: '20' },
  { name: 'Konkubinat', value: '21' },
  { name: 'getrennt', value: '41' },
  { name: 'geschieden', value: '42' },
  { name: 'verwitwet', value: '30' },
  { name: 'wiederverheiratet', value: '50' },
];

export const mrMrsTypes = [
  { value: '1', name: 'Herr' },
  { value: '2', name: 'Frau' },
];

export const careSituationTypeOptions = [
  { value: '9', name: 'Bitte wählen' },
  { value: '1', name: 'gemeinsam' },
  { value: '2', name: 'nur Vater' },
  { value: '3', name: 'nur Mutter' },
  { value: '4', name: 'Vormund/Beistand' },
];

export const residencePermitTypes = [
  { value: '0', name: 'Bitte wählen' },
  { value: '3', name: 'C-Ausweis' },
  { value: '2', name: 'B-Ausweis' },
  { value: '5', name: 'F-Ausweis' },
  { value: '8', name: 'N-Ausweis' },
  { value: '91', name: 'Anerkannter Flüchtling' },
];

export const countryOptions = [
  { value: '8100', name: 'Schweiz' },
  { value: '8201', name: 'Albanien' },
  { value: '8202', name: 'Andorra' },
  { value: '8204', name: 'Belgien' },
  { value: '8205', name: 'Bulgarien' },
  { value: '8206', name: 'Dänemark' },
  { value: '8207', name: 'Deutschland' },
  { value: '8210', name: 'Färöer' },
  { value: '8211', name: 'Finnland' },
  { value: '8212', name: 'Frankreich' },
  { value: '8213', name: 'Gibraltar' },
  { value: '8214', name: 'Griechenland' },
  { value: '8215', name: 'Vereinigtes Königreich' },
  { value: '8216', name: 'Irland' },
  { value: '8217', name: 'Island' },
  { value: '8218', name: 'Italien' },
  { value: '8222', name: 'Liechtenstein' },
  { value: '8223', name: 'Luxemburg' },
  { value: '8224', name: 'Malta' },
  { value: '8225', name: 'Insel Man' },
  { value: '8226', name: 'Monaco' },
  { value: '8227', name: 'Niederlande' },
  { value: '8228', name: 'Norwegen' },
  { value: '8229', name: 'Österreich' },
  { value: '8230', name: 'Polen' },
  { value: '8231', name: 'Portugal' },
  { value: '8232', name: 'Rumänien' },
  { value: '8233', name: 'San Marino' },
  { value: '8234', name: 'Schweden' },
  { value: '8236', name: 'Spanien' },
  { value: '8239', name: 'Türkei' },
  { value: '8240', name: 'Ungarn' },
  { value: '8241', name: 'Vatikanstadt' },
  { value: '8242', name: 'Zypern' },
  { value: '8243', name: 'Slowakei' },
  { value: '8244', name: 'Tschechische Republik' },
  { value: '8248', name: 'Serbien' },
  { value: '8250', name: 'Kroatien' },
  { value: '8251', name: 'Slowenien' },
  { value: '8252', name: 'Bosnien und Herzegowina' },
  { value: '8253', name: 'Zentralserbien' },
  { value: '8254', name: 'Montenegro' },
  { value: '8255', name: 'Mazedonien' },
  { value: '8256', name: 'Kosovo' },
  { value: '8257', name: 'Provinz Wojwodina' },
  { value: '8260', name: 'Estland' },
  { value: '8261', name: 'Lettland' },
  { value: '8262', name: 'Litauen' },
  { value: '8263', name: 'Moldova' },
  { value: '8264', name: 'Russland' },
  { value: '8265', name: 'Ukraine' },
  { value: '8266', name: 'Belarus' },
  { value: '8270', name: 'Alderney' },
  { value: '8271', name: 'Jersey' },
  { value: '8272', name: 'Guernsey' },
  { value: '8273', name: 'Svalbard und Jan Mayen' },
  { value: '8274', name: 'Alandinseln' },
  { value: '8301', name: 'Äquatorialguinea' },
  { value: '8302', name: 'Äthiopien' },
  { value: '8303', name: 'Dschibuti' },
  { value: '8304', name: 'Algerien' },
  { value: '8305', name: 'Angola' },
  { value: '8307', name: 'Botsuana' },
  { value: '8308', name: 'Burundi' },
  { value: '8309', name: 'Benin' },
  { value: '8310', name: "Côte d'Ivoire" },
  { value: '8311', name: 'Gabun' },
  { value: '8312', name: 'Gambia' },
  { value: '8313', name: 'Ghana' },
  { value: '8314', name: 'Guinea-Bissau' },
  { value: '8315', name: 'Guinea' },
  { value: '8317', name: 'Kamerun' },
  { value: '8318', name: 'Kanarische Inseln' },
  { value: '8319', name: 'Kap Verde' },
  { value: '8320', name: 'Kenia' },
  { value: '8321', name: 'Komoren' },
  { value: '8322', name: 'Kongo (Brazzaville)' },
  { value: '8323', name: 'Kongo (Kinshasa)' },
  { value: '8324', name: 'Lesotho' },
  { value: '8325', name: 'Liberia' },
  { value: '8326', name: 'Libyen' },
  { value: '8327', name: 'Madagaskar' },
  { value: '8329', name: 'Malawi' },
  { value: '8330', name: 'Mali' },
  { value: '8331', name: 'Marokko' },
  { value: '8332', name: 'Mauretanien' },
  { value: '8333', name: 'Mauritius' },
  { value: '8334', name: 'Mosambik' },
  { value: '8335', name: 'Niger' },
  { value: '8336', name: 'Nigeria' },
  { value: '8337', name: 'Burkina Faso' },
  { value: '8339', name: 'Reunion' },
  { value: '8340', name: 'Simbabwe' },
  { value: '8341', name: 'Ruanda' },
  { value: '8343', name: 'Sambia' },
  { value: '8344', name: 'São Tomé und Príncipe' },
  { value: '8345', name: 'Senegal' },
  { value: '8346', name: 'Seychellen' },
  { value: '8347', name: 'Sierra Leone' },
  { value: '8348', name: 'Somalia' },
  { value: '8349', name: 'Südafrika' },
  { value: '8350', name: 'Sudan' },
  { value: '8351', name: 'Namibia' },
  { value: '8352', name: 'Swasiland' },
  { value: '8353', name: 'Tansania' },
  { value: '8354', name: 'Togo' },
  { value: '8356', name: 'Tschad' },
  { value: '8357', name: 'Tunesien' },
  { value: '8358', name: 'Uganda' },
  { value: '8359', name: 'Ägypten' },
  { value: '8360', name: 'Zentralafrikanische Republik' },
  { value: '8361', name: 'Mayotte' },
  { value: '8362', name: 'Eritrea' },
  { value: '8371', name: 'Britische Territorien im Indischen Ozean' },
  { value: '8372', name: 'Westsahara' },
  { value: '8373', name: 'St. Helena' },
  { value: '8374', name: 'Ascension' },
  { value: '8375', name: 'Tristan da Cunha' },
  { value: '8381', name: 'Spanisch-Nordafrika' },
  { value: '8401', name: 'Argentinien' },
  { value: '8402', name: 'Bahamas' },
  { value: '8403', name: 'Barbados' },
  { value: '8404', name: 'Bermuda' },
  { value: '8405', name: 'Bolivien' },
  { value: '8406', name: 'Brasilien' },
  { value: '8407', name: 'Chile' },
  { value: '8408', name: 'Costa Rica' },
  { value: '8409', name: 'Dominikanische Republik' },
  { value: '8410', name: 'Ecuador' },
  { value: '8411', name: 'El Salvador' },
  { value: '8412', name: 'Falklandinseln' },
  { value: '8413', name: 'Grönland' },
  { value: '8414', name: 'Guadeloupe' },
  { value: '8415', name: 'Guatemala' },
  { value: '8416', name: 'Französisch-Guayana' },
  { value: '8417', name: 'Guyana' },
  { value: '8418', name: 'Haiti' },
  { value: '8419', name: 'Belize' },
  { value: '8420', name: 'Honduras' },
  { value: '8421', name: 'Jamaika' },
  { value: '8423', name: 'Kanada' },
  { value: '8424', name: 'Kolumbien' },
  { value: '8425', name: 'Kuba' },
  { value: '8426', name: 'Martinique' },
  { value: '8427', name: 'Mexiko' },
  { value: '8429', name: 'Nicaragua' },
  { value: '8430', name: 'Panama' },
  { value: '8431', name: 'Paraguay' },
  { value: '8432', name: 'Peru' },
  { value: '8433', name: 'Puerto Rico' },
  { value: '8434', name: 'St. Pierre und Miquelon' },
  { value: '8435', name: 'Suriname' },
  { value: '8436', name: 'Trinidad und Tobago' },
  { value: '8437', name: 'Uruguay' },
  { value: '8438', name: 'Venezuela' },
  { value: '8439', name: 'Vereinigte Staaten' },
  { value: '8440', name: 'Dominica' },
  { value: '8441', name: 'Grenada' },
  { value: '8442', name: 'Antigua und Barbuda' },
  { value: '8443', name: 'St. Lucia' },
  { value: '8444', name: 'St. Vincent und die Grenadinen' },
  { value: '8445', name: 'St. Kitts und Nevis' },
  { value: '8446', name: 'Anguilla' },
  { value: '8448', name: 'Saint-Martin (Frankreich)' },
  { value: '8449', name: 'Saint-Barthélemy' },
  { value: '8472', name: 'Jungferninseln (USA)' },
  { value: '8473', name: 'Kaimaninseln' },
  { value: '8474', name: 'Turks- und Caicosinseln' },
  { value: '8475', name: 'Montserrat' },
  { value: '8476', name: 'Jungferninseln (UK)' },
  { value: '8481', name: 'Niederländische Antillen' },
  { value: '8482', name: 'Aruba' },
  { value: '8483', name: 'Südgeorgien und Südliche Sandwichinseln' },
  { value: '8501', name: 'Afghanistan' },
  { value: '8502', name: 'Bahrain' },
  { value: '8503', name: 'Bhutan' },
  { value: '8504', name: 'Brunei Darussalam' },
  { value: '8505', name: 'Myanmar' },
  { value: '8506', name: 'Sri Lanka' },
  { value: '8507', name: 'Taiwan (Chinesisches Taipei)' },
  { value: '8508', name: 'China' },
  { value: '8509', name: 'Hongkong' },
  { value: '8510', name: 'Indien' },
  { value: '8511', name: 'Indonesien' },
  { value: '8512', name: 'Irak' },
  { value: '8513', name: 'Iran' },
  { value: '8514', name: 'Israel' },
  { value: '8515', name: 'Japan' },
  { value: '8516', name: 'Jemen' },
  { value: '8517', name: 'Jordanien' },
  { value: '8518', name: 'Kambodscha' },
  { value: '8519', name: 'Katar' },
  { value: '8521', name: 'Kuwait' },
  { value: '8522', name: 'Laos' },
  { value: '8523', name: 'Libanon' },
  { value: '8524', name: 'Macao' },
  { value: '8525', name: 'Malaysia' },
  { value: '8526', name: 'Malediven' },
  { value: '8527', name: 'Oman' },
  { value: '8528', name: 'Mongolei' },
  { value: '8529', name: 'Nepal' },
  { value: '8530', name: 'Korea (Nord-)' },
  { value: '8532', name: 'Vereinigte Arabische Emirate' },
  { value: '8533', name: 'Pakistan' },
  { value: '8534', name: 'Philippinen' },
  { value: '8535', name: 'Saudi-Arabien' },
  { value: '8537', name: 'Singapur' },
  { value: '8539', name: 'Korea (Süd-)' },
  { value: '8541', name: 'Syrien' },
  { value: '8542', name: 'Thailand' },
  { value: '8543', name: 'Tibet' },
  { value: '8545', name: 'Vietnam' },
  { value: '8546', name: 'Bangladesch' },
  { value: '8547', name: 'Timor-Leste' },
  { value: '8550', name: 'Palästina' },
  { value: '8560', name: 'Armenien' },
  { value: '8561', name: 'Aserbaidschan' },
  { value: '8562', name: 'Georgien' },
  { value: '8563', name: 'Kasachstan' },
  { value: '8564', name: 'Kirgisistan' },
  { value: '8565', name: 'Tadschikistan' },
  { value: '8566', name: 'Turkmenistan' },
  { value: '8567', name: 'Usbekistan' },
  { value: '8571', name: 'Abchasien' },
  { value: '8572', name: 'Karatschai-Tscherkessien' },
  { value: '8573', name: 'Kabardino-Balkarien' },
  { value: '8574', name: 'Nordossetien-Alanien' },
  { value: '8575', name: 'Südossetien' },
  { value: '8576', name: 'Inguschetien' },
  { value: '8577', name: 'Tschetschenien' },
  { value: '8578', name: 'Dagestan' },
  { value: '8579', name: 'Adscharien' },
  { value: '8601', name: 'Australien' },
  { value: '8602', name: 'Fidschi-Inseln' },
  { value: '8604', name: 'Nauru' },
  { value: '8605', name: 'Vanuatu' },
  { value: '8606', name: 'Neukaledonien' },
  { value: '8607', name: 'Neuseeland' },
  { value: '8608', name: 'Papua-Neuguinea' },
  { value: '8610', name: 'Tonga' },
  { value: '8611', name: 'Wallis und Futuna' },
  { value: '8612', name: 'Samoa' },
  { value: '8614', name: 'Salomoninseln' },
  { value: '8615', name: 'Tuvalu' },
  { value: '8616', name: 'Kiribati' },
  { value: '8617', name: 'Marshallinseln' },
  { value: '8618', name: 'Mikronesien' },
  { value: '8619', name: 'Palau' },
  { value: '8621', name: 'Amerikanisch-Samoa' },
  { value: '8630', name: 'Nördliche Marianen' },
  { value: '8632', name: 'Guam' },
  { value: '8633', name: 'Johnstoninsel' },
  { value: '8635', name: 'Midwayinseln' },
  { value: '8636', name: 'Wakeinsel' },
  { value: '8637', name: 'Kleinere amerikanische Überseeinseln' },
  { value: '8652', name: 'Kokosinseln' },
  { value: '8653', name: 'Heard und McDonaldinseln' },
  { value: '8654', name: 'Norfolkinsel' },
  { value: '8655', name: 'Weihnachtsinsel' },
  { value: '8671', name: 'Französisch-Polynesien' },
  { value: '8682', name: 'Cookinseln' },
  { value: '8683', name: 'Niue' },
  { value: '8684', name: 'Tokelau' },
  { value: '8685', name: 'Pitcairninseln' },
  { value: '8701', name: 'Antarktis' },
  { value: '8702', name: 'Bouvetinsel' },
  { value: '8703', name: 'Französische Süd- und Antarktisgebiete' },
  { value: '8998', name: 'Staatenlos' },
  { value: '8999', name: 'Nationalität unbekannt' },
];

export const livingTypeType = [
  { value: '9', name: 'Bitte wählen' },
  { value: '1', name: 'Eltern' },
  { value: '2', name: 'Vater' },
  { value: '3', name: 'Mutter' },
  { value: '4', name: 'Eigener Haushalt' },
  { value: '6', name: 'Wochenaufenthalt' },
];

export const educationType = [
  { value: '0', name: 'Bitte wählen' },
  { value: '119', name: 'Volksschule' },
  { value: '114', name: 'Maturitätsschule' },
  { value: '102', name: 'Fachmittelschule/Fachmatur' },
  { value: '121', name: 'Wirtschaftsmittelschule' },
  { value: '122', name: 'Informatikmittelschule' },
  { value: '123', name: 'Private Vollzeitberufsschule' },
  { value: '124', name: 'Berufslehre' },
  { value: '101', name: 'Berufsmaturität nach Berufslehre' },
  { value: '117', name: 'Höhere Fachschule' },
  { value: '125', name: 'Berufsprüfung / Höhere Fachprüfung' },
  { value: '108', name: 'Fachhochschule' },
  { value: '109', name: 'Universität' },
  { value: '126', name: 'ETH' },
  { value: '1101', name: 'Erwerbstätigkeit' },
  { value: '1103', name: 'Militär-/Zivildienst' },
  { value: '1104', name: 'Arbeitslos (beim RAV registriert)' },
  { value: '1102', name: 'andere / keine Tätigkeit' },
  { value: '128', name: 'Vorkurs' },
  { value: '129', name: 'Praktikum' },
  { value: '127', name: 'Brückenjahr' },
];

export const kantons = [
  { value: 'AG', name: 'Aargau' },
  { value: 'AI', name: 'Appenzell Innerrhoden' },
  { value: 'AR', name: 'Appenzell Ausserrhoden' },
  { value: 'BE', name: 'Bern' },
  { value: 'BL', name: 'Basel-Landschaft' },
  { value: 'BS', name: 'Basel-Stadt' },
  { value: 'FR', name: 'Freiburg' },
  { value: 'GE', name: 'Genf' },
  { value: 'GL', name: 'Glarus' },
  { value: 'GR', name: 'Graubünden' },
  { value: 'JU', name: 'Jura' },
  { value: 'LU', name: 'Luzern' },
  { value: 'NE', name: 'Neuenburg' },
  { value: 'NW', name: 'Nidwalden' },
  { value: 'OW', name: 'Obwalden' },
  { value: 'SG', name: 'St. Gallen' },
  { value: 'SH', name: 'Schaffhausen' },
  { value: 'SO', name: 'Solothurn' },
  { value: 'SZ', name: 'Schwyz' },
  { value: 'TG', name: 'Thurgau' },
  { value: 'TI', name: 'Tessin' },
  { value: 'UR', name: 'Uri' },
  { value: 'VD', name: 'Waadt' },
  { value: 'VS', name: 'Wallis' },
  { value: 'ZG', name: 'Zug' },
  { value: 'ZH', name: 'Zürich' },
  { value: 'ZZ', name: 'Ausland' },
];

export const degreeProgramType = [
  { value: '0', name: 'Bitte wählen' },
  { value: '1', name: 'Bachelor' },
  { value: '2', name: 'Master' },
  { value: '3', name: 'Doktorat' },
  { value: '5', name: 'MAS/DAS/CAS' },
];

export const formationType = [
  { value: '0', name: 'Bitte wählen' },
  { value: '24', name: 'Brückenangebot' },
  { value: '95', name: 'Berufslehre' },
  { value: '23', name: 'Private Vollzeitberufsschule' },
  { value: '94', name: 'Matura' },
  { value: '93', name: 'Fachmittelschule / Fachmaturität' },
  { value: '21', name: 'Wirtschaftsmittelschule' },
  { value: '22', name: 'Informatikmittelschule' },
  { value: '92', name: 'Passarelle' },
  { value: '91', name: 'Berufsmatura nach Lehre' },
  { value: '28', name: 'Vorkurs' },
  { value: '17', name: 'Höhere Fachschule' },
  { value: '8', name: 'Fachhochschule' },
  { value: '9', name: 'Universität' },
  { value: '26', name: 'ETH' },
  { value: '99', name: 'Andere Ausbildung' },
];

export const educationWorkloadTypes = [
  { value: '1', name: 'Vollzeit' },
  { value: '0', name: 'Teilzeit' },
];

export const toggleButtonYesNoTypes = [
  { value: '1', name: 'Ja' },
  { value: '2', name: 'Nein' },
];

export const destinationAddressRoleType = [
  { value: '0', name: 'Bitte wählen' },
  { value: '1', name: 'Person in Ausbildung' },
  { value: '7', name: 'Vater' },
  { value: '8', name: 'Mutter' },
  { value: '99', name: 'Andere Person' },
];

export const destinationAddressRoleTypeEditPaymentAddress = [
  { value: '0', name: 'Bitte wählen' },
  { value: '1', name: 'Person in Ausbildung' },
  { value: '7', name: 'Vater' },
  { value: '8', name: 'Mutter' },
  { value: '99', name: 'Sozialamt/Institution' },
];

export const requestInitialType = [
  { value: '0', name: 'Bitte wählen' },
  { value: '1', name: 'Erstgesuch' },
  { value: '2', name: 'Folgegesuch' },
];

export const degreeOfRelationType = [
  { value: '0', name: 'Bitte wählen' },
  { value: '1', name: 'Vollgeschwister' },
  { value: '2', name: 'Halbgeschwister (gleiche Mutter)' },
  { value: '3', name: 'Halbgeschwister (gleicher Vater)' },
  { value: '4', name: 'Stiefgeschwister (Kind der Stiefmutter)' },
  { value: '5', name: 'Stiefgeschwister (Kind des Stiefvaters)' },
];

export const typeOfRelationshipType = [
  { value: '20', name: 'zusammen verheiratet' },
  { value: '21', name: 'in Konkubinat' },
  { value: '10', name: 'nie zusammen verheiratet' },
  { value: '41', name: 'getrennt' },
  { value: '42', name: 'geschieden' },
  { value: '30', name: 'ein Elternteil verstorben' },
  { value: '60', name: 'beide Elternteile verstorben' },
];

export const obhutSituationTypeOptions = [
  { value: '0', name: 'Bitte wählen' },
  { value: '2', name: 'Mutter' },
  { value: '1', name: 'Vater' },
];

export const alimonyTypeOptions = [
  { value: '0', name: 'Bitte wählen' },
  { value: '1', name: 'nein' },
  { value: '2', name: 'Vater leistet Alimeten für die Person in Ausbildung' },
  { value: '3', name: 'Mutter leistet Alimeten für die Person in Ausbildung' },
  { value: '4', name: 'beide leisten Alimente' },
  { value: '5', name: 'Alimente für die Person in Ausbildung werden bevorschusst' },
];

export const socialCareReceivedOptions = [
  { value: '0', name: 'Bitte wählen' },
  { value: '1', name: 'nein' },
  { value: '2', name: 'ja, beide' },
  { value: '3', name: 'ja, nur Mutter' },
  { value: '4', name: 'ja, nur Vater' },
];

export const parentMaritalStatusOptions = [
  { name: 'Bitte wählen', value: '0' },
  { name: 'ledig', value: '10' },
  { name: 'verheiratet', value: '20' },
  { name: 'Konkubinat', value: '21' },
  { name: 'getrennt', value: '41' },
  { name: 'geschieden', value: '42' },
  { name: 'verwitwet', value: '30' },
  { name: 'wiederverheiratet', value: '50' },
  { name: 'gestorben', value: '70' },
];

export const roleTypeOptions = [
  { name: 'Mutter', value: '5' },
  { name: 'Vater', value: '4' },
  { name: 'Vormund/Beistand', value: '7' },
];

export const instalmentState = ['', 'Offen', 'Beleg vorhanden', 'Ausbezahlt'];

export const demandDecisionState = ['', 'Positiv', 'Negativ', 'zurückgewiesen'];

export const rightsTypeOptions = [
  { name: 'Voller Zugriff', value: '1' },
  { name: 'Eingeschränkter Zugriff', value: '2' },
  { name: 'Zugriff nur auf Entscheid', value: '3' },
];

export const rightsTypeOptionsAdmin = [{ name: 'Admin Zugriff', value: '5' }];

export enum NotificationCaseType {
  NEW_CHAT = -1000,
  CHAT = 1000,
  PAYMENTACCOUNTCHANGE = 1110,
  REQUESTFLOWINFO = 1210,
  REQUESTINCOMPLETE = 1220,
  REQUESTNEEDSADDITONALINFOS = 1230,
  FORMATIONINTERRUPTION = 1310,
  FORMATIONABANDONED = 1320,
  FORMATIONCHANGE = 1330,
  FORMATIONFINALISATIONCHECK = 1340,
  PAYMENTVOUCHERUPLOAD = 1410,
  FORBEARANCE = 2120,
  REPAYMENTCONDITIONS = 2110,
}

export enum CaseStepTypes {
  CHAT = 1,
}

export const creditType = [
  { name: 'Stipendium', value: '1000' },
  { name: 'Darlehen', value: '2000' },
];

export const periodOptions = [
  { name: 'Bitte wählen', value: '0' },
  { name: '1', value: '1' },
  { name: '2', value: '2' },
  { name: '3', value: '3' },
  { name: '4', value: '4' },
  { name: '5', value: '5' },
  { name: '6', value: '6' },
  { name: '7', value: '7' },
  { name: '8', value: '8' },
  { name: '9', value: '9' },
  { name: '10', value: '10' },
  { name: '11', value: '11' },
  { name: '12', value: '12' },
];

export const contractPaymentPeriodOptions = [
  {
    value: '1',
    name: '1-monatlich',
  },
  {
    value: '2',
    name: '2-monatlich',
  },
  {
    value: '3',
    name: 'quartalweise',
  },
  {
    value: '4',
    name: 'halbjährlich',
  },
  {
    value: '5',
    name: 'jährlich',
  },
  {
    value: '6',
    name: 'keine Zahlung',
  },
  {
    value: '7',
    name: 'standard',
  },
];

export const contractTypes = [
  {
    value: '1',
    name: 'Gesetz 1989',
  },
  {
    value: '2007',
    name: 'Gesetz 2007',
  },
  {
    value: '2014',
    name: 'Verordnung 2014',
  },
  {
    value: '2018',
    name: 'Verordnung 2018',
  },
  {
    value: '2020',
    name: 'KK-Prämie 2020',
  },
];

export const roleTypeOptionsAdminPanel = [
  { name: 'Person in Ausbildung', value: '1' },
  { name: 'Admin', value: '8' },
  { name: 'Vater', value: '4' },
  { name: 'Mutter', value: '5' },
  { name: 'Vormund/Beistand', value: '7' },
];

// dublication because Person in Ausbildung shall not be visible when creating new permission
export const roleTypeOptionsNewPermission = [
  { name: 'Vater', value: '4' },
  { name: 'Mutter', value: '5' },
  { name: 'Vormund/Beistand', value: '7' },
  { name: 'Admin', value: '8' },
];

export const inSwitzerlandTypeOptions = [
  { name: 'In der Schweiz', value: 'true' },
  { name: 'Im Ausland', value: 'false' },
];
