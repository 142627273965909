import React, { ReactElement } from 'react';
import { NavigationOrder, NavigationTab } from '../../pages/demand-overview/types';
import { CVDataAction } from '../../store/cv-data/actions';
import { DocumentsAction } from '../../store/documents/actions';
import { IncomingDataAction } from '../../store/incoming-data/actions';
import { OutgoingDataAction } from '../../store/outgoing-data/actions';
import { ParentsDataAction } from '../../store/parents-data/actions';
import { PayoutDataAction } from '../../store/payout-data/actions';
import { ContactDataAction, PersonalDataAction } from '../../store/personal-data/actions';
import { darkBlue, grey, white, blue } from '../../theme';
import ConnectedChildrenDataForm from './../../containers/child/ChildrenForm';
import ConnectedContactDataForm from './../../containers/contact-form/ContactDataForm';
import ConnectedCVDataForm from './../../containers/cv/CVForm';
import ConnectedDocumentsForm from './../../containers/documents-form/DocumentsForm';
import ConnectedEducationForm from './../../containers/education-form/ConnectedEducationDataForm';
import ConnectedFatherDataForm from './../../containers/father-form/FatherDataForm';
import ConnectedIncomingDataForm from './../../containers/incoming-form/IncomingDataForm';
import ConnectedMotherDataForm from './../../containers/mother-form/MotherDataForm';
import ConnectedOutgoingDataForm from './../../containers/outgoing-form/OutgoingDataForm';
import ConnectedParentsDataForm from './../../containers/parents-form/ParentsDataForm';
import ConnectedPartnerDataForm from './../../containers/partner-form/PartnerDataForm';
import ConnectedPayoutForm from './../../containers/payout-form/PayoutDataForm';
import ConnectedPersonalDataForm from './../../containers/personal-data-form/PersonalDataForm';
import ConnectedScholarshipInfoDataForm from './../../containers/scholarship-info-form/ScholarshipInfoForm';
import ConnectedSiblingsDataForm from './../../containers/sibling/SiblingsForm';
import ConnectedGuardianDataForm from './../../containers/guardian-form/GuardianDataForm';
import ConnectedNotesForm from './../../containers/notes-form/NotesForm';
import { ZoomIcon } from './../../components/icons/icons';
import {
  CircularProgressWithIcon,
  CircularProgressWithIconProps,
} from './../../components/base-page-components/circular-progress-with-icon/CircularProgressWithIcon';

const scholarshipTab: NavigationTab = {
  tab: 'scholarship',
  forms: [
    {
      form: 'education',
      label: 'Ausbildung',
      component: <ConnectedEducationForm></ConnectedEducationForm>,
      //loadAction: { type: EducationDataAction.GET },
    },
    {
      form: 'request',
      label: 'Gesuch',
      component: <ConnectedScholarshipInfoDataForm></ConnectedScholarshipInfoDataForm>,
      //loadAction: { type: ScholarshipDataAction.GET },
    },
  ],
  title: 'Angaben zum Stipendium',
};

const piaTab: NavigationTab = {
  tab: 'pia',
  forms: [
    {
      form: 'personalData',
      label: 'Personalien',
      loadAction: { type: PersonalDataAction.GET },
      component: <ConnectedPersonalDataForm></ConnectedPersonalDataForm>,
    },
    {
      form: 'contactData',
      label: 'Kontaktdaten',
      loadAction: { type: ContactDataAction.GET },
      component: <ConnectedContactDataForm></ConnectedContactDataForm>,
    },
    {
      form: 'cv',
      label: 'Lebenslauf',
      loadAction: { type: CVDataAction.GET },
      component: <ConnectedCVDataForm></ConnectedCVDataForm>,
    },
    {
      form: 'income',
      label: 'Einnahmen',
      loadAction: { type: IncomingDataAction.GET },
      component: <ConnectedIncomingDataForm></ConnectedIncomingDataForm>,
    },
    {
      form: 'expenses',
      label: 'Ausgaben',
      loadAction: { type: OutgoingDataAction.GET },
      component: <ConnectedOutgoingDataForm></ConnectedOutgoingDataForm>,
    },
  ],
  title: 'Angaben zum Antragssteller',
};

const parentsTab: NavigationTab = {
  tab: 'parents',
  forms: [
    {
      form: 'parents',
      label: 'Angaben zu Eltern',
      loadAction: { type: ParentsDataAction.GET },
      component: <ConnectedParentsDataForm></ConnectedParentsDataForm>,
    },
    {
      form: 'father',
      label: 'Angaben Vater',
      component: <ConnectedFatherDataForm></ConnectedFatherDataForm>,
      hidden: true,
    },
    {
      form: 'mother',
      label: 'Angaben Mutter',
      component: <ConnectedMotherDataForm></ConnectedMotherDataForm>,
      hidden: true,
    },
    {
      form: 'guardian',
      label: 'Angaben Vormund/Beistand',
      component: <ConnectedGuardianDataForm></ConnectedGuardianDataForm>,
      hidden: true,
    },
  ],
  title: 'Angaben zu den Eltern',
};

const partnerTab: NavigationTab = {
  tab: 'partner',
  forms: [
    {
      form: 'partner',
      label: 'Angaben zum Partner',
      component: <ConnectedPartnerDataForm></ConnectedPartnerDataForm>,
    },
  ],
  title: 'Angaben zum Partner',
  hidden: true,
};

const childrenTab: NavigationTab = {
  tab: 'children',
  forms: [
    {
      form: 'children',
      label: 'Angaben zu den Kindern',
      component: <ConnectedChildrenDataForm></ConnectedChildrenDataForm>,
    },
  ],
  title: 'Angaben zu den Kindern',
  hidden: true,
};

const siblingsTab: NavigationTab = {
  tab: 'siblings',
  forms: [
    {
      form: 'siblings',
      label: 'Angaben zu den Geschwistern',
      component: <ConnectedSiblingsDataForm></ConnectedSiblingsDataForm>,
    },
  ],
  title: 'Angaben zu den Geschwistern',
  hidden: true,
};

const payoutTab: NavigationTab = {
  tab: 'payout',
  forms: [
    {
      form: 'payout',
      label: 'Angaben zur Auszahlung',
      loadAction: { type: PayoutDataAction.GET },
      component: <ConnectedPayoutForm></ConnectedPayoutForm>,
    },
  ],
  title: 'Angaben zur Auszahlung',
};

const documentsTab: NavigationTab = {
  tab: 'documents',
  forms: [
    {
      form: 'documents',
      label: 'Einzureichende Dokumente',
      loadAction: { type: DocumentsAction.GET_ROLES },
      component: <ConnectedDocumentsForm></ConnectedDocumentsForm>,
    },
  ],
  title: 'Einzureichende Dokumente',
};

const notesTab: NavigationTab = {
  tab: 'notes',
  forms: [
    {
      form: 'notes',
      label: 'Bemerkungen',
      loadAction: { type: DocumentsAction.GET_ROLES },
      component: <ConnectedNotesForm></ConnectedNotesForm>,
    },
  ],
  title: 'Einzureichende Dokumente',
};

export const orderedRegisterTabs: NavigationOrder = {
  tabs: [scholarshipTab, piaTab, parentsTab, partnerTab, childrenTab, siblingsTab, payoutTab, documentsTab, notesTab],
};

export const getCategoryIcon = (
  tab: string,
  active: boolean,
  desktop: boolean,
  progress: number,
): ReactElement | null => {
  let color = active ? grey.main : white.main;
  if (desktop) {
    color = darkBlue.main;
  }

  let icon = ZoomIcon.Check;
  if (progress < 100) {
    switch (tab) {
      case 'scholarship':
        icon = ZoomIcon.Scholarship;
        break;
      case 'pia':
        icon = ZoomIcon.Person;
        break;
      case 'parents':
        icon = ZoomIcon.Parent;
        break;
      case 'partner':
        icon = ZoomIcon.Partner;
        break;
      case 'children':
        icon = ZoomIcon.Child;
        break;
      case 'siblings':
        icon = ZoomIcon.People;
        break;
      case 'documents':
        icon = ZoomIcon.Receipt;
        break;
      case 'payout':
        icon = ZoomIcon.Debt;
        break;
      case 'notes':
        icon = ZoomIcon.Pencil;
        break;
      default:
        return <></>;
    }
  }
  const props: CircularProgressWithIconProps = {
    filledAreaPercentage: progress,
    icon,
    size: 40,
    filledAreaColor: color,
    filledAreaBackgroundColor: desktop ? blue.main : grey.main,
    strokeWidth: 3,
    backgroundStrokeWidth: 1,
  };

  return <CircularProgressWithIcon {...props}></CircularProgressWithIcon>;
};
