import { AuthorityData } from './types';

export enum AuthoritiesDataAction {
  GET = 'AUTHORITIES_DATA_GET',
  GET_RESULT = 'AUTHORITIES_DATA_GET_RESULT',
  EDIT_ITEM = 'AUTHORITY_EDIT',
  EDIT_RESULT = 'AUTHORITY_EDIT_RESULT',
  ADD_ITEM = 'AUTHORITY_ADD',
  ADD_RESULT = 'AUTHORITY_ADD_RESULT',
  DELETE_ITEM = 'AUTHORITY_DELETE',
  DELETE_RESULT = 'AUTHORITY_DELETE_RESULT',
  EMPTY_AUTHORITIES_DATA = 'AUTHORITY_EMPTY',
}

export interface AuthoritiesDataGetAction {
  type: typeof AuthoritiesDataAction.GET;
  dossierId: number;
}

export interface AuthoritiesDataGetResultAction {
  type: typeof AuthoritiesDataAction.GET_RESULT;
  payload: AuthorityData[];
}

export interface AuthoritiesDataEditAuthorityAction {
  type: typeof AuthoritiesDataAction.EDIT_ITEM;
  payload: AuthorityData;
}

export interface AuthoritiesDataEditResultAction {
  type: typeof AuthoritiesDataAction.EDIT_RESULT;
  payload: AuthorityData;
}

export interface AuthoritiesDataAddAuthorityAction {
  type: typeof AuthoritiesDataAction.ADD_ITEM;
  payload: AuthorityData;
  dossierId: number;
}

export interface AuthoritiesDataAddResultAction {
  type: typeof AuthoritiesDataAction.ADD_RESULT;
  payload: AuthorityData;
}

export interface AuthoritiesDataDeleteAuthorityAction {
  type: typeof AuthoritiesDataAction.DELETE_ITEM;
  payload: string;
  dossierId: number;
}

export interface AuthoritiesDataDeleteResultAction {
  type: typeof AuthoritiesDataAction.DELETE_RESULT;
  payload: string;
}

export type AuthoritiesActionType =
  | AuthoritiesDataGetAction
  | AuthoritiesDataGetResultAction
  | AuthoritiesDataEditAuthorityAction
  | AuthoritiesDataEditResultAction
  | AuthoritiesDataAddAuthorityAction
  | AuthoritiesDataAddResultAction
  | AuthoritiesDataDeleteAuthorityAction
  | AuthoritiesDataDeleteResultAction;
