import { connect } from 'react-redux';
import { App, AppProps } from './App';
import { i18nLabel } from './store/i18n/helpers';
import { WIAAStore } from './store/types';

// Map global store to component parameters
function mapStateToProps(store: WIAAStore): AppProps {
  document.title = i18nLabel(store.translationLabels, 'Header_Application_Name', 'Stipendienportal');
  return {
    labelsLoaded: Object.keys(store.translationLabels).length > 0,
  };
}

// Default export the connected component
export default connect(mapStateToProps)(App);
