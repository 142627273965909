import produce from 'immer';
import {
  OverviewDataAction,
  OverviewActionType,
  OverviewDataGetResultAction,
  OverviewDataDeleteResultAction,
  OverviewDataSearchUsersGetResultAction,
} from './actions';
import { OverviewDataStore } from './types';

export const initialState: OverviewDataStore = {
  users: [],
};

export function overviewDataReducer(state = initialState, action: OverviewActionType): OverviewDataStore {
  return produce(state, (draft) => {
    switch (action.type) {
      case OverviewDataAction.GET:
        draft.isLoading = true;
        break;
      case OverviewDataAction.GET_RESULT:
        draft.users = (action as OverviewDataGetResultAction).payload;
        draft.isLoading = false;
        break;
      case OverviewDataAction.DELETE_RESULT:
        const id = (action as OverviewDataDeleteResultAction).payload;
        draft.users = state.users.filter((user) => user.id !== id);
        break;
      case OverviewDataAction.SEARCH_USERS_GET:
        draft.isLoading = true;
        break;
      case OverviewDataAction.SEARCH_USERS_GET_RESULT:
        draft.users = (action as OverviewDataSearchUsersGetResultAction).payload;
        draft.isLoading = false;
        break;
    }
    return draft;
  });
}
