import { Container, Grid } from '@material-ui/core';
import React, { ReactElement } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ZoomFooter, {
  mapStoreToFooterProps,
  ZoomFooterProps,
} from '../../components/base-page-components/footer/ZoomFooter';
import { i18nLabel } from '../../store/i18n/helpers';
import { WIAAStore } from '../../store/types';
import { blue } from '../../theme';
import ZoomHeader, {
  mapStoreToHeaderProps,
  ZoomHeaderProps,
} from './../../components/base-page-components/header/ZoomHeader';
import { ZoomArrowLeftIcon } from './../../components/icons/ZoomArrowLeftIcon';
import './DataProtection.scss';

export interface DataProtectionProps {
  labelTitle?: string;
  labelDescriptionParagraph1?: string;
  labelDescriptionParagraph2?: string;
  labelDescriptionParagraph3?: string;
  labelSubTitle1?: string;
  labelSubTitle1SubTitle1?: string;
  labelSubTitle1SubTitle1Paragraph1?: string;
  labelSubTitle1SubTitle1Paragraph2?: string;
  labelSubTitle1SubTitle1Paragraph3?: string;
  labelSubTitle1SubTitle1Paragraph4?: string;
  labelSubTitle1SubTitle1Paragraph5?: string;
  labelSubTitle1SubTitle1Paragraph6?: string;
  labelSubTitle1SubTitle1Paragraph7?: string;
  labelSubTitle1SubTitle2?: string;
  labelSubTitle1SubTitle2Paragraph1?: string;
  labelSubTitle1SubTitle2Paragraph2?: string;
  labelSubTitle1SubTitle3?: string;
  labelSubTitle1SubTitle3Paragraph1?: string;
  labelSubTitle1SubTitle4?: string;
  labelSubTitle1SubTitle4Paragraph1?: string;
  labelSubTitle1SubTitle4Paragraph2?: string;
  labelSubTitle1SubTitle5?: string;
  labelSubTitle1SubTitle5Paragraph1?: string;
  labelSubTitle1SubTitle6?: string;
  labelSubTitle1SubTitle6Paragraph1?: string;
  labelSubTitle1SubTitle6Paragraph2?: string;
  labelSubTitle1SubTitle6Paragraph3?: string;
  labelSubTitle1SubTitle6Paragraph4?: string;
  labelSubTitle1SubTitle6Paragraph5?: string;
  labelSubTitle1SubTitle6Paragraph6?: string;
  labelSubTitle1SubTitle6Paragraph7?: string;
  labelSubTitle1SubTitle6Paragraph8?: string;
  labelSubTitle1SubTitle6Paragraph9?: string;
  labelSubTitle2?: string;
  labelSubTitle2DescriptionParagraph1?: string;
  labelSubTitle2DescriptionParagraph2?: string;
  labelSubTitle2DescriptionParagraph3?: string;
  labelSubTitle2SubTitle1?: string;
  labelSubTitle2SubTitle1Paragraph1?: string;
  labelSubTitle2SubTitle1Paragraph2?: string;
  labelSubTitle2SubTitle1Paragraph3?: string;
  labelSubTitle2SubTitle1Paragraph4?: string;
  labelSubTitle2SubTitle1Paragraph5?: string;
  labelSubTitle2SubTitle1Paragraph6?: string;
  labelSubTitle2SubTitle1Paragraph7?: string;
  labelSubTitle3?: string;
  labelSubTitle3SubTitle1?: string;
  labelSubTitle3SubTitle1Paragraph1?: string;
  labelSubTitle3SubTitle2?: string;
  labelSubTitle3SubTitle2Paragraph1?: string;
  labelSubTitle3SubTitle2Paragraph2?: string;
  labelSubTitle4?: string;
  labelSubTitle4SubTitle1?: string;
  labelSubTitle4SubTitle1Paragraph1?: string;
  labelSubTitle4SubTitle2?: string;
  labelSubTitle4SubTitle2Paragraph1?: string;
  labelSubTitle4SubTitle2Paragraph2?: string;
  labelSubTitle5?: string;
  labelSubTitle5SubTitle1?: string;
  labelSubTitle5SubTitle1Paragraph1?: string;
  labelSubTitle5SubTitle2?: string;
  labelSubTitle5SubTitle2Paragraph1?: string;
  labelSubTitle6?: string;
  labelSubTitle6Paragraph1?: string;
  labelSubTitle6Paragraph2?: string;
  labelSubTitle6Paragraph3?: string;
  labelSubTitle6Paragraph4?: string;
  labelSubTitle6Paragraph5?: string;
  labelSubTitle6Paragraph6?: string;
  labelSubTitle6Paragraph7?: string;
  labelSubTitle6Paragraph8?: string;
  labelBackArrow?: string;
  footerProps: ZoomFooterProps;
  headerProps: ZoomHeaderProps;
}

export const DataProtection = (props: DataProtectionProps): ReactElement => {
  const history = useHistory();

  return (
    <>
      <ZoomHeader
        appName="Stipendienportal"
        userName="Max Mustermann"
        ahvn="756-4352-9990-13"
        hideUserMenu={true}
        labelMyData="Meine Daten"
        labelMyEducation="Meine Ausbildungen"
        labelMyScholarship="Meine Stipendienanträge"
        labelPayout="Auszahlungen"
        labelRefund="Rückzahlungen"
        labelPowerOfAttorney="Vollmachten"
        labelScholarshipHolder="Stipendiat wählen"
        labelLogout="Abmelden"
      />
      <main className="main main-data-protection">
        <Container>
          <div id="main-page-container">
            <div>
              <div className="back-button-desktop" onClick={() => history.goBack()}>
                <ZoomArrowLeftIcon viewBox="0 0 80 80" fontSize="large" htmlColor={blue.main}></ZoomArrowLeftIcon>
                <span className="back-button">{props.labelBackArrow}</span>
              </div>
            </div>
            <h1>{props.labelTitle}</h1>
            <p>{props.labelDescriptionParagraph1}</p>
            <p>{props.labelDescriptionParagraph2}</p>
            <p>{props.labelDescriptionParagraph3}</p>
            <Grid container direction="row" alignItems="stretch" justify="flex-start" spacing={3}>
              <Grid item xs={12}>
                <h2>{props.labelSubTitle1}</h2>
                <h3>{props.labelSubTitle1SubTitle1}</h3>
                <p>{props.labelSubTitle1SubTitle1Paragraph1}</p>
                <ul>
                  <li>{props.labelSubTitle1SubTitle1Paragraph2}</li>
                  <li>{props.labelSubTitle1SubTitle1Paragraph3}</li>
                  <li>{props.labelSubTitle1SubTitle1Paragraph4}</li>
                  <li>{props.labelSubTitle1SubTitle1Paragraph5}</li>
                  <li>{props.labelSubTitle1SubTitle1Paragraph6}</li>
                </ul>
                <p>{props.labelSubTitle1SubTitle1Paragraph7}</p>
                <h3>{props.labelSubTitle1SubTitle2}</h3>
                <p>{props.labelSubTitle1SubTitle2Paragraph1}</p>
                <p>{props.labelSubTitle1SubTitle2Paragraph2}</p>
                <h3>{props.labelSubTitle1SubTitle3}</h3>
                <p>{props.labelSubTitle1SubTitle3Paragraph1}</p>
                <h3>{props.labelSubTitle1SubTitle4}</h3>
                <p>{props.labelSubTitle1SubTitle4Paragraph1}</p>
                <p>{props.labelSubTitle1SubTitle4Paragraph2}</p>
                <h3>{props.labelSubTitle1SubTitle5}</h3>
                <p>{props.labelSubTitle1SubTitle5Paragraph1}</p>
                <h3>{props.labelSubTitle1SubTitle6}</h3>
                <ul>
                  <li>{props.labelSubTitle1SubTitle6Paragraph1}</li>
                  <li>{props.labelSubTitle1SubTitle6Paragraph2}</li>
                  <li>{props.labelSubTitle1SubTitle6Paragraph3}</li>
                  <li>{props.labelSubTitle1SubTitle6Paragraph4}</li>
                  <li>{props.labelSubTitle1SubTitle6Paragraph5}</li>
                  <li>{props.labelSubTitle1SubTitle6Paragraph6}</li>
                  <li>{props.labelSubTitle1SubTitle6Paragraph7}</li>
                  <li>{props.labelSubTitle1SubTitle6Paragraph8}</li>
                </ul>
                <p>{props.labelSubTitle1SubTitle6Paragraph9}</p>
              </Grid>
              <Grid item xs={12}>
                <h2>{props.labelSubTitle2}</h2>
                <p>{props.labelSubTitle2DescriptionParagraph1}</p>
                <p>{props.labelSubTitle2DescriptionParagraph2}</p>
                <p>{props.labelSubTitle2DescriptionParagraph3}</p>
                <h3>{props.labelSubTitle2SubTitle1}</h3>
                <p>{props.labelSubTitle2SubTitle1Paragraph1}</p>
                <ul>
                  <li>{props.labelSubTitle2SubTitle1Paragraph2}</li>
                  <li>{props.labelSubTitle2SubTitle1Paragraph3}</li>
                  <li>{props.labelSubTitle2SubTitle1Paragraph4}</li>
                  <li>{props.labelSubTitle2SubTitle1Paragraph5}</li>
                  <li>{props.labelSubTitle2SubTitle1Paragraph6}</li>
                </ul>
                <p>{props.labelSubTitle2SubTitle1Paragraph7}</p>
              </Grid>
              <Grid item xs={12}>
                <h2>{props.labelSubTitle3}</h2>
                <h3>{props.labelSubTitle3SubTitle1}</h3>
                <p>{props.labelSubTitle3SubTitle1Paragraph1}</p>
                <h3>{props.labelSubTitle3SubTitle2}</h3>
                <p>{props.labelSubTitle3SubTitle2Paragraph1}</p>
                <p>{props.labelSubTitle3SubTitle2Paragraph2}</p>
              </Grid>
              <Grid item xs={12}>
                <h2>{props.labelSubTitle4}</h2>
                <h3>{props.labelSubTitle4SubTitle1}</h3>
                <p>{props.labelSubTitle4SubTitle1Paragraph1}</p>
                <h3>{props.labelSubTitle4SubTitle2}</h3>
                <p>{props.labelSubTitle4SubTitle2Paragraph1}</p>
                <p>{props.labelSubTitle4SubTitle2Paragraph2}</p>
              </Grid>
              <Grid item xs={12}>
                <h2>{props.labelSubTitle5}</h2>
                <h3>{props.labelSubTitle5SubTitle1}</h3>
                <p>{props.labelSubTitle5SubTitle1Paragraph1}</p>
                <h3>{props.labelSubTitle5SubTitle2}</h3>
                <p>{props.labelSubTitle5SubTitle2Paragraph1}</p>
              </Grid>
              <Grid item xs={12}>
                <h2>{props.labelSubTitle6}</h2>
                <p
                  dangerouslySetInnerHTML={{
                    __html: props.labelSubTitle6Paragraph1 ? props.labelSubTitle6Paragraph1 : '',
                  }}
                ></p>
                <p
                  dangerouslySetInnerHTML={{
                    __html: props.labelSubTitle6Paragraph2 ? props.labelSubTitle6Paragraph2 : '',
                  }}
                ></p>
                <p
                  dangerouslySetInnerHTML={{
                    __html: props.labelSubTitle6Paragraph3 ? props.labelSubTitle6Paragraph3 : '',
                  }}
                ></p>
                <p
                  dangerouslySetInnerHTML={{
                    __html: props.labelSubTitle6Paragraph4 ? props.labelSubTitle6Paragraph4 : '',
                  }}
                ></p>
                <p
                  dangerouslySetInnerHTML={{
                    __html: props.labelSubTitle6Paragraph5 ? props.labelSubTitle6Paragraph5 : '',
                  }}
                ></p>
                <p
                  dangerouslySetInnerHTML={{
                    __html: props.labelSubTitle6Paragraph6 ? props.labelSubTitle6Paragraph6 : '',
                  }}
                ></p>
                <p
                  dangerouslySetInnerHTML={{
                    __html: props.labelSubTitle6Paragraph7 ? props.labelSubTitle6Paragraph7 : '',
                  }}
                ></p>
                <p
                  dangerouslySetInnerHTML={{
                    __html: props.labelSubTitle6Paragraph8 ? props.labelSubTitle6Paragraph8 : '',
                  }}
                ></p>
              </Grid>
            </Grid>
          </div>
        </Container>
      </main>
      <ZoomFooter {...props.footerProps}></ZoomFooter>
    </>
  );
};

export function mapStateToProps(store: WIAAStore): DataProtectionProps {
  return {
    labelBackArrow: i18nLabel(store.translationLabels, 'Data_Protection_Back', 'Zurück'),
    labelTitle: i18nLabel(store.translationLabels, 'Data_Protection_Title', 'Datenschutzerklärung Stipendienportal'),
    labelDescriptionParagraph1: i18nLabel(
      store.translationLabels,
      'Data_Protection_Description_Paragraph_1',
      'Wir nehmen den Schutz Ihrer persönlichen Daten sehr ernst.',
    ),
    labelDescriptionParagraph2: i18nLabel(
      store.translationLabels,
      'Data_Protection_Description_Paragraph_2',
      'Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit Ihren personenbezogenen Daten passiert, wenn Sie unsere Website besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie persönlich identifiziert werden können. Wir behandeln Ihre personenbezogenen Daten vertraulich und entsprechend der gesetzlichen Datenschutzvorschriften sowie dieser Datenschutzerklärung.',
    ),
    labelDescriptionParagraph3: i18nLabel(
      store.translationLabels,
      'Data_Protection_Description_Paragraph_3',
      'Ausführliche Informationen zum Thema Datenschutz entnehmen Sie unserer unter diesem Text aufgeführten Datenschutzerklärung.',
    ),
    labelSubTitle1: i18nLabel(
      store.translationLabels,
      'Data_Protection_Sub_Title_1',
      'Datenerfassung im Stipendienportal',
    ),
    labelSubTitle1SubTitle1: i18nLabel(
      store.translationLabels,
      'Data_Protection_Sub_Title_1_Sub_Title_1',
      'Welche Daten erfassen wir?',
    ),
    labelSubTitle1SubTitle1Paragraph1: i18nLabel(
      store.translationLabels,
      'Data_Protection_Sub_Title_1_Sub_Title_1_Paragraph_1',
      'Im Stipendienportal erfassen Sie alle notwendigen Angaben, die nach den gesetzlichen Grundlagen zur Bearbeitung ihrer Gesuche um Ausrichtung von Ausbildungsbeiträgen (Stipendien und Darlehen) notwendig sind. Es handelt sich dabei um folgende Kategorien von Daten:',
    ),
    labelSubTitle1SubTitle1Paragraph2: i18nLabel(
      store.translationLabels,
      'Data_Protection_Sub_Title_1_Sub_Title_1_Paragraph_2',
      'Personalien',
    ),
    labelSubTitle1SubTitle1Paragraph3: i18nLabel(
      store.translationLabels,
      'Data_Protection_Sub_Title_1_Sub_Title_1_Paragraph_3',
      'AHV-Nummer',
    ),
    labelSubTitle1SubTitle1Paragraph4: i18nLabel(
      store.translationLabels,
      'Data_Protection_Sub_Title_1_Sub_Title_1_Paragraph_4',
      'Angaben, um die Gesuchsberechtigung zu prüfen (etwa Nationalität)',
    ),
    labelSubTitle1SubTitle1Paragraph5: i18nLabel(
      store.translationLabels,
      'Data_Protection_Sub_Title_1_Sub_Title_1_Paragraph_5',
      'Angaben zu Ihren finanziellen Verhältnissen (u.a. Einkommen, Vermögen, Lebenshaltungskosten) sowie Ihrer Familie, inklusive Steuerveranlagungen',
    ),
    labelSubTitle1SubTitle1Paragraph6: i18nLabel(
      store.translationLabels,
      'Data_Protection_Sub_Title_1_Sub_Title_1_Paragraph_6',
      'Angaben zur Ausbildung',
    ),
    labelSubTitle1SubTitle1Paragraph7: i18nLabel(
      store.translationLabels,
      'Data_Protection_Sub_Title_1_Sub_Title_1_Paragraph_7',
      'Zudem werden Daten erhoben, um die Bewirtschaftung und Auszahlung von Ausbildungsbeiträgen zu ermöglichen, namentlich Angaben zu Ihrem Bankkonto.',
    ),
    labelSubTitle1SubTitle2: i18nLabel(
      store.translationLabels,
      'Data_Protection_Sub_Title_1_Sub_Title_2',
      'Wie erfassen wir Ihre Daten?',
    ),
    labelSubTitle1SubTitle2Paragraph1: i18nLabel(
      store.translationLabels,
      'Data_Protection_Sub_Title_1_Sub_Title_2_Paragraph_1',
      'Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese mitteilen. Hierbei kann es sich z.B. um Daten handeln, die Sie im Stipendienportal selber eingeben.',
    ),
    labelSubTitle1SubTitle2Paragraph2: i18nLabel(
      store.translationLabels,
      'Data_Protection_Sub_Title_1_Sub_Title_2_Paragraph_2',
      'Andere Daten werden automatisch beim Besuch des Stipendienportals durch unsere IT-Systeme erfasst. Das sind vor allem technische Daten (z.B. Internetbrowser, Betriebssystem oder Uhrzeit des Seitenaufrufs). Die Erfassung dieser Daten erfolgt automatisch, sobald Sie unsere Website betreten.',
    ),
    labelSubTitle1SubTitle3: i18nLabel(
      store.translationLabels,
      'Data_Protection_Sub_Title_1_Sub_Title_3',
      'Wofür nutzen wir Ihre Daten?',
    ),
    labelSubTitle1SubTitle3Paragraph1: i18nLabel(
      store.translationLabels,
      'Data_Protection_Sub_Title_1_Sub_Title_3_Paragraph_1',
      'Ein Teil der Daten wird erhoben, um Ihre Gesuche für Ausbildungsbeiträge (Stipendien und Darlehen) sowie deren Auszahlung und weitere Bewirtschaftung zur Verfügung stellen zu können oder um eine fehlerfreie Bereitstellung des Stipendienportals zu gewährleisten.',
    ),
    labelSubTitle1SubTitle4: i18nLabel(
      store.translationLabels,
      'Data_Protection_Sub_Title_1_Sub_Title_4',
      'Welche Verschlüsselungen werden verwendet?',
    ),
    labelSubTitle1SubTitle4Paragraph1: i18nLabel(
      store.translationLabels,
      'Data_Protection_Sub_Title_1_Sub_Title_4_Paragraph_1',
      'Diese Website nutzt aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher Inhalte, die Sie an uns als Portalbetreiber senden, eine SSL-bzw. TLS-Verschlüsselung. Eine verschlüsselte Verbindung erkennen Sie daran, dass die Adresszeile des Browsers von “http://” auf “https://” wechselt und an dem Schloss-Symbol in Ihrer Browserzeile.',
    ),
    labelSubTitle1SubTitle4Paragraph2: i18nLabel(
      store.translationLabels,
      'Data_Protection_Sub_Title_1_Sub_Title_4_Paragraph_2',
      'Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die Daten, die Sie an uns übermitteln, nicht von Dritten mitgelesen werden.',
    ),
    labelSubTitle1SubTitle5: i18nLabel(
      store.translationLabels,
      'Data_Protection_Sub_Title_1_Sub_Title_5',
      'Was für Risiken gibt es?',
    ),
    labelSubTitle1SubTitle5Paragraph1: i18nLabel(
      store.translationLabels,
      'Data_Protection_Sub_Title_1_Sub_Title_5_Paragraph_1',
      'Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich.',
    ),
    labelSubTitle1SubTitle6: i18nLabel(
      store.translationLabels,
      'Data_Protection_Sub_Title_1_Sub_Title_6',
      'Welches ist die Verantwortliche Stelle für diese Website?',
    ),
    labelSubTitle1SubTitle6Paragraph1: i18nLabel(
      store.translationLabels,
      'Data_Protection_Sub_Title_1_Sub_Title_6_Paragraph_1',
      'Die verantwortliche Stelle für die Datenbearbeitung auf dieser Website ist:',
    ),
    labelSubTitle1SubTitle6Paragraph2: i18nLabel(
      store.translationLabels,
      'Data_Protection_Sub_Title_1_Sub_Title_6_Paragraph_2',
      'Departement Bildung, Kultur und Sport',
    ),
    labelSubTitle1SubTitle6Paragraph3: i18nLabel(
      store.translationLabels,
      'Data_Protection_Sub_Title_1_Sub_Title_6_Paragraph_3',
      'Abteilung Hochschulen und Sport',
    ),
    labelSubTitle1SubTitle6Paragraph4: i18nLabel(
      store.translationLabels,
      'Data_Protection_Sub_Title_1_Sub_Title_6_Paragraph_4',
      'Sektion Stipendien',
    ),
    labelSubTitle1SubTitle6Paragraph5: i18nLabel(
      store.translationLabels,
      'Data_Protection_Sub_Title_1_Sub_Title_6_Paragraph_5',
      'Bachstrasse 15',
    ),
    labelSubTitle1SubTitle6Paragraph6: i18nLabel(
      store.translationLabels,
      'Data_Protection_Sub_Title_1_Sub_Title_6_Paragraph_6',
      '5001 Aarau',
    ),
    labelSubTitle1SubTitle6Paragraph7: i18nLabel(
      store.translationLabels,
      'Data_Protection_Sub_Title_1_Sub_Title_6_Paragraph_7',
      'Telefon: +41 62 835 52 52',
    ),
    labelSubTitle1SubTitle6Paragraph8: i18nLabel(
      store.translationLabels,
      'Data_Protection_Sub_Title_1_Sub_Title_6_Paragraph_8',
      'E-Mail: stipendien@ag.ch',
    ),
    labelSubTitle1SubTitle6Paragraph9: i18nLabel(
      store.translationLabels,
      'Data_Protection_Sub_Title_1_Sub_Title_6_Paragraph_9',
      'Die verantwortliche Stelle entscheidet allein oder gemeinsam mit anderen über die Zwecke und Mittel der Bearbeitung von personenbezogenen Daten (z.B. Namen, E-Mail-Adressen o. Ä.).',
    ),
    labelSubTitle2: i18nLabel(store.translationLabels, 'Data_Protection_Sub_Title_2', 'Auskunfts- und Einsichtsrechte'),
    labelSubTitle2DescriptionParagraph1: i18nLabel(
      store.translationLabels,
      'Data_Protection_Sub_Title_2_Description_Paragraph_1',
      'Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit das Recht auf unentgeltliche Auskunft über Ihre gespeicherten personenbezogenen Daten, deren Aufbewahrungsdauer, Herkunft und Empfänger und den Zweck der Datenbearbeitung.',
    ),
    labelSubTitle2DescriptionParagraph2: i18nLabel(
      store.translationLabels,
      'Data_Protection_Sub_Title_2_Description_Paragraph_2',
      'Sie haben jederzeit das Recht unentgeltlich Auskunft über Herkunft, Empfänger und Zweck Ihrer gespeicherten personenbezogenen Daten zu erhalten. Sie haben ausserdem ein Recht, die Berichtigung, Ergänzung oder Löschung von unrichtigen Personendaten zu verlangen.',
    ),
    labelSubTitle2DescriptionParagraph3: i18nLabel(
      store.translationLabels,
      'Data_Protection_Sub_Title_2_Description_Paragraph_3',
      'Hierzu sowie zu weiteren Fragen zum Thema personenbezogene Daten können Sie sich jederzeit unter der obgenannten Adresse an uns wenden.',
    ),
    labelSubTitle2SubTitle1: i18nLabel(
      store.translationLabels,
      'Data_Protection_Sub_Title_2_Sub_Title_1',
      'Was können Sie im Falle datenschutzrechtlicher Verstösse tun?',
    ),
    labelSubTitle2SubTitle1Paragraph1: i18nLabel(
      store.translationLabels,
      'Data_Protection_Sub_Title_2_Sub_Title_1_Paragraph_1',
      'Im Falle datenschutzrechtlicher Verstösse können Sie sich jederzeit unter der obgenannten Adresse an uns wenden. Kann so keine für Sie befriedigende Lösung erzielt werden, haben Sie das Recht, sich an die kantonale Datenschutzbeauftragte zu wenden:',
    ),
    labelSubTitle2SubTitle1Paragraph2: i18nLabel(
      store.translationLabels,
      'Data_Protection_Sub_Title_2_Sub_Title_1_Paragraph_2',
      'Beauftragte für Öffentlichkeit und Datenschutz',
    ),
    labelSubTitle2SubTitle1Paragraph3: i18nLabel(
      store.translationLabels,
      'Data_Protection_Sub_Title_2_Sub_Title_1_Paragraph_3',
      'Bahnhofplatz 13',
    ),
    labelSubTitle2SubTitle1Paragraph4: i18nLabel(
      store.translationLabels,
      'Data_Protection_Sub_Title_2_Sub_Title_1_Paragraph_4',
      '5201 Brugg',
    ),
    labelSubTitle2SubTitle1Paragraph5: i18nLabel(
      store.translationLabels,
      'Data_Protection_Sub_Title_2_Sub_Title_1_Paragraph_5',
      'Telefon: +41 62 835 45 60',
    ),
    labelSubTitle2SubTitle1Paragraph6: i18nLabel(
      store.translationLabels,
      'Data_Protection_Sub_Title_2_Sub_Title_1_Paragraph_6',
      'Mail: oedb@ag.ch',
    ),
    labelSubTitle2SubTitle1Paragraph7: i18nLabel(
      store.translationLabels,
      'Data_Protection_Sub_Title_2_Sub_Title_1_Paragraph_7',
      'Der Rechtsschutz richtet sich nach den §§ 35 - 40 des Gesetzes über die Information der Öffentlichkeit, den Datenschutz und das Archivwesen (IDAG) vom 24. Oktober 2006 (SAR 150.700).',
    ),
    labelSubTitle3: i18nLabel(store.translationLabels, 'Data_Protection_Sub_Title_3', 'Cookies'),
    labelSubTitle3SubTitle1: i18nLabel(
      store.translationLabels,
      'Data_Protection_Sub_Title_3_Sub_Title_1',
      'Was sind Cookies?',
    ),
    labelSubTitle3SubTitle1Paragraph1: i18nLabel(
      store.translationLabels,
      'Data_Protection_Sub_Title_3_Sub_Title_1_Paragraph_1',
      'Das Stipendienportal verwenden teilweise so genannte Cookies. Cookies richten auf Ihrem Rechner keinen Schaden an und enthalten keine Viren. Cookies dienen dazu, unser Angebot nutzerfreundlicher, effektiver und sicherer zu machen. Cookies sind kleine Textdateien, die auf Ihrem Rechner abgelegt werden und die Ihr Browser speichert. Die meisten der von uns verwendeten Cookies sind so genannte “Session-Cookies”. Sie werden nach Ende Ihres Besuchs automatisch gelöscht. Andere Cookies bleiben auf Ihrem Endgerät gespeichert bis Sie diese löschen. Diese Cookies ermöglichen es uns, Ihren Browser beim nächsten Besuch wiederzuerkennen.',
    ),
    labelSubTitle3SubTitle2: i18nLabel(
      store.translationLabels,
      'Data_Protection_Sub_Title_3_Sub_Title_2',
      'Was können Sie dagegen unternehmen?',
    ),
    labelSubTitle3SubTitle2Paragraph1: i18nLabel(
      store.translationLabels,
      'Data_Protection_Sub_Title_3_Sub_Title_2_Paragraph_1',
      'Sie können Ihren Browser so einstellen, dass Sie über das Setzen von Cookies informiert werden und Cookies nur im Einzelfall erlauben, die Annahme von Cookies für bestimmte Fälle oder generell ausschliessen sowie das automatische Löschen der Cookies beim Schliessen des Browsers aktivieren. Bei der Deaktivierung von Cookies kann die Funktionalität des Stipendienportals eingeschränkt oder nicht nutzbar sein.',
    ),
    labelSubTitle3SubTitle2Paragraph2: i18nLabel(
      store.translationLabels,
      'Data_Protection_Sub_Title_3_Sub_Title_2_Paragraph_2',
      'Cookies, die zur Durchführung des elektronischen Kommunikationsvorgangs oder zur Bereitstellung bestimmter, von Ihnen erwünschter Funktionen erforderlich sind, werden gespeichert. Der Portalbetreiber hat ein berechtigtes Interesse an der Speicherung von Cookies zur technisch fehlerfreien und optimierten Bereitstellung seiner Dienste. Soweit andere Cookies (z.B. Cookies zur Analyse Ihres Surfverhaltens) gespeichert werden, werden diese in dieser Datenschutzerklärung gesondert behandelt.',
    ),
    labelSubTitle4: i18nLabel(store.translationLabels, 'Data_Protection_Sub_Title_4', 'Server-Log-Dateien'),
    labelSubTitle4SubTitle1: i18nLabel(
      store.translationLabels,
      'Data_Protection_Sub_Title_4_Sub_Title_1',
      'Wie kann der Websitebetreiber Ihre Aktivitäten auf der Site nachvollziehen?',
    ),
    labelSubTitle4SubTitle1Paragraph1: i18nLabel(
      store.translationLabels,
      'Data_Protection_Sub_Title_4_Sub_Title_1_Paragraph_1',
      'Der Provider der Website erhebt und speichert automatisch Informationen in so genannten Server-Log-Dateien, die Ihr Browser automatisch übermittelt.',
    ),
    labelSubTitle4SubTitle2: i18nLabel(
      store.translationLabels,
      'Data_Protection_Sub_Title_4_Sub_Title_2',
      'Was geschieht mit diesen Daten?',
    ),
    labelSubTitle4SubTitle2Paragraph1: i18nLabel(
      store.translationLabels,
      'Data_Protection_Sub_Title_4_Sub_Title_2_Paragraph_1',
      'Der Anbieter verwendet die Protokolldaten nur für statistische Auswertungen zum Zweck des Betriebs, der Sicherheit und der Optimierung des Angebotes. Der Anbieter behält sich jedoch vor, die Protokolldaten nachträglich zu überprüfen, wenn aufgrund konkreter Anhaltspunkte der berechtigte Verdacht einer rechtswidrigen Nutzung besteht.',
    ),
    labelSubTitle4SubTitle2Paragraph2: i18nLabel(
      store.translationLabels,
      'Data_Protection_Sub_Title_4_Sub_Title_2_Paragraph_2',
      'Eine Zusammenführung dieser Daten mit anderen Datenquellen wird nicht vorgenommen.',
    ),
    labelSubTitle5: i18nLabel(store.translationLabels, 'Data_Protection_Sub_Title_5', 'Portal-Mitteilungen'),
    labelSubTitle5SubTitle1: i18nLabel(
      store.translationLabels,
      'Data_Protection_Sub_Title_5_Sub_Title_1',
      'Was geschieht, wenn Sie uns eine Mitteilung und weitere Unterlagen schicken?',
    ),
    labelSubTitle5SubTitle1Paragraph1: i18nLabel(
      store.translationLabels,
      'Data_Protection_Sub_Title_5_Sub_Title_1_Paragraph_1',
      'Wenn Sie uns im Stipendienportal Mitteilungen und weitere Unterlagen (Dokumente) zukommen lassen, werden Ihre Angaben zwecks Bearbeitung der Anfrage und für den Fall von Anschlussfragen im Portal gespeichert.',
    ),
    labelSubTitle5SubTitle2: i18nLabel(
      store.translationLabels,
      'Data_Protection_Sub_Title_5_Sub_Title_2',
      'Wo verbleiben Ihre Daten?',
    ),
    labelSubTitle5SubTitle2Paragraph1: i18nLabel(
      store.translationLabels,
      'Data_Protection_Sub_Title_5_Sub_Title_2_Paragraph_1',
      'Die von Ihnen im Mitteilungsbereich eingegebenen Daten verbleiben bei uns, bis der Zweck für die Datenspeicherung entfällt, d.h. in der Regel 10 Jahre nach Anlage des Dossiers. Zwingende gesetzliche Bestimmungen – insbesondere weitergehende Aufbewahrungsfristen – bleiben vorbehalten.',
    ),
    labelSubTitle6: i18nLabel(store.translationLabels, 'Data_Protection_Sub_Title_6', 'Rechtliche Grundlagen'),
    labelSubTitle6Paragraph1: i18nLabel(
      store.translationLabels,
      'Data_Protection_Sub_Title_6_Paragraph_1',
      'Die obgenannten Datenbearbeitungen stützen sich insbesondere auf folgende Rechtsgrundlagen: ',
    ),
    labelSubTitle6Paragraph2: i18nLabel(
      store.translationLabels,
      'Data_Protection_Sub_Title_6_Paragraph_2',
      'Interkantonale Vereinbarung zur Harmonisierung von Ausbildungsbeiträgen vom 18. Juni 2009 (SAR 471.500): https://gesetzessammlungen.ag.ch/app/de/texts_of_law/471.500',
    ),
    labelSubTitle6Paragraph3: i18nLabel(
      store.translationLabels,
      'Data_Protection_Sub_Title_6_Paragraph_3',
      'Gesetz über Ausbildungsbeiträge (Stipendiengesetz, StipG) vom 19. September 2006  (SAR 471.200): https://gesetzessammlungen.ag.ch/app/de/texts_of_law/471.200',
    ),
    labelSubTitle6Paragraph4: i18nLabel(
      store.translationLabels,
      'Data_Protection_Sub_Title_6_Paragraph_4',
      'Dekret über die Höchstansätze der Ausbildungsbeiträge (Stipendiendekret, StipD) vom 16. Januar 2007 (SAR 471.210): https://gesetzessammlungen.ag.ch/app/de/texts_of_law/471.210',
    ),
    labelSubTitle6Paragraph5: i18nLabel(
      store.translationLabels,
      'Data_Protection_Sub_Title_6_Paragraph_5',
      'Verordnung über Ausbildungsbeiträge (Stipendienverordnung, StipV) vom 2. Mai 2007 (SAR 471.211): https://gesetzessammlungen.ag.ch/app/de/texts_of_law/471.211',
    ),
    labelSubTitle6Paragraph6: i18nLabel(
      store.translationLabels,
      'Data_Protection_Sub_Title_6_Paragraph_6',
      'Gesetz über die Verwaltungsrechtspflege (Verwaltungsrechtspflegegesetz, VRPG) vom 4. Dezember 2017 (SAR 271.200): https://gesetzessammlungen.ag.ch/app/de/texts_of_law/271.200',
    ),
    labelSubTitle6Paragraph7: i18nLabel(
      store.translationLabels,
      'Data_Protection_Sub_Title_6_Paragraph_7',
      'Gesetz über die Information der Öffentlichkeit, den Datenschutz und das Archivwesen (IDAG) vom 24. Oktober 2006 (SAR 150.700): https://gesetzessammlungen.ag.ch/data/150.700',
    ),
    labelSubTitle6Paragraph8: i18nLabel(
      store.translationLabels,
      'Data_Protection_Sub_Title_6_Paragraph_8',
      'Verordnung zum Gesetz über die Information der Öffentlichkeit, den Datenschutz und das Archivwesen (VIDAG) vom 26. September 2007 (SAR 150.711): https://gesetzessammlungen.ag.ch/data/150.711',
    ),
    headerProps: mapStoreToHeaderProps(store),
    footerProps: mapStoreToFooterProps(store),
  };
}

export default connect(mapStateToProps)(DataProtection);
