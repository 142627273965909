import { Button, Divider, Link, ListItemText, Menu, MenuItem, MenuProps, withStyles } from '@material-ui/core';
import React, { ReactElement } from 'react';
import { blue } from '../../../../theme';
import { ZoomArrowRightIcon } from '../../../icons/ZoomArrowRightIcon';
import { ZoomArrowDownIcon } from './../../../icons/ZoomArrowDownIcon';
import './ZoomUserInfo.scss';

export interface PowerOfAttorney {
  firstName?: string;
  lastName?: string;
  birthDate?: Date;
  email?: string;
}

export interface Education {
  name: string;
  institution: string;
  duration: string;
}

export interface ZoomUserInfoProps {
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  ahv13n: string;
  phoneNumber: string;
  emailAddress: string;
  street: string;
  streetNumber: string;
  postalCode: string;
  city: string;
  country: string;
  labelEditData: string;
  labelEditEducation: string;
  labelEditPowerOfAttorney: string;
  labelMyData: string;
  labelMyEducation: string;
  labelMyPowerOfAttorney: string;
  labelNoEducations: string;
  labelNoMyData: string;
  education: Array<Education>;
  powerOfAttorneys: Array<PowerOfAttorney>;
  labelChooseEducationState?: string;
  labelEducationFinishedState?: string;
  labelEducationChangedState?: string;
  labelEducationInterruptedState?: string;
  labelEducationCanceledState?: string;
  labelAllEducations?: string;
  dossierId: number;
}

const StyledMenu = withStyles({
  paper: {
    border: '1px solid ' + blue.main,
  },
})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    color: blue.main,
    width: '345px',
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
    '& .MuiListItemText-root': {
      marginLeft: '7px',
    },
  },
}))(MenuItem);

const ZoomUserInfo = (props: ZoomUserInfoProps): ReactElement => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const educations = props.education ? (
    props.education.map((education) => (
      <div className="user-info-block" key={education.name + '-' + education.institution}>
        <p className="user-info-data">{education.name}</p>
        <p className="user-info-data">{education.institution}</p>
        <p className="user-info-data">{education.duration}</p>
      </div>
    ))
  ) : (
    <div className="user-info-block">
      <p className="user-info-data">{props.labelNoEducations}</p>
    </div>
  );

  const poas = props.powerOfAttorneys ? (
    props.powerOfAttorneys.map((poa, index) => (
      <div className="user-info-block" key={index}>
        <p className="user-info-data">{poa.firstName + ' ' + poa.lastName}</p>
        <p className="user-info-data">{poa.email}</p>
      </div>
    ))
  ) : (
    <div className="user-info-block">
      <p className="user-info-data">{props.labelNoEducations}</p>
    </div>
  );

  return (
    <>
      <div className="user-info-part-container">
        <h5 className="user-info-title">{props.labelMyData}</h5>
        {props.firstName !== '' ? (
          <>
            <div className="user-info-block">
              <Divider className="user-info-divider"></Divider>
              <p className="user-info-data">
                {props.firstName} {props.lastName}
              </p>
              <p className="user-info-data">{props.dateOfBirth}</p>
              <p className="user-info-data">{props.ahv13n}</p>
              <Divider className="user-info-divider"></Divider>
              <p className="user-info-data">{props.phoneNumber}</p>
              <p className="user-info-data">{props.emailAddress}</p>
              <Divider className="user-info-divider"></Divider>
              <p className="user-info-data">{props.street + ' ' + props.streetNumber}</p>
              <p className="user-info-data">{props.postalCode + ' ' + props.city}</p>
              <p className="user-info-data">{props.country}</p>
            </div>
            <Link href={'/my-data/dossier-intranet/mydata/' + props.dossierId}>
              <Button variant="outlined" size="large" fullWidth color="primary" className="user-info-btn">
                {props.labelEditData}
              </Button>
            </Link>
          </>
        ) : (
          <>
            <div className="user-info-block">
              <p className="user-info-no-educations">{props.labelNoMyData}</p>{' '}
            </div>
          </>
        )}
      </div>
      <Divider className="user-info-divider"></Divider>
      <div className="user-info-part-container">
        <h5 className="user-info-title">{props.labelMyEducation}</h5>
        {props.education.length > 0 && (
          <>
            {educations}
            <Button
              aria-controls="customized-menu"
              aria-haspopup="true"
              color="primary"
              type="reset"
              variant="outlined"
              onClick={handleClick}
              fullWidth
              size="large"
            >
              {props.labelChooseEducationState}
              <ZoomArrowDownIcon viewBox="0 0 80 80" htmlColor={blue.main}></ZoomArrowDownIcon>
            </Button>
            <StyledMenu
              id="customized-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <Link href={'/edit-complete-education/' + props.dossierId}>
                <StyledMenuItem>
                  <ListItemText primary={props.labelEducationFinishedState} />
                </StyledMenuItem>
              </Link>

              <Link href={'/edit-change-education/' + props.dossierId}>
                <StyledMenuItem>
                  <ListItemText primary={props.labelEducationChangedState} />
                </StyledMenuItem>
              </Link>
              <Link href={'/edit-interrupted-education/' + props.dossierId}>
                <StyledMenuItem>
                  <ListItemText primary={props.labelEducationInterruptedState} />
                </StyledMenuItem>
              </Link>
              <Link href={'/edit-canceled-education/' + props.dossierId}>
                <StyledMenuItem>
                  <ListItemText primary={props.labelEducationCanceledState} />
                </StyledMenuItem>
              </Link>
            </StyledMenu>
          </>
        )}
        {props.education.length === 0 && <p className="user-info-no-educations">{props.labelNoEducations}</p>}
        <Link href={'/dossier-intranet/myformation/' + props.dossierId}>
          <div className="user-info-all-educations">
            <p>{props.labelAllEducations}</p>
            <ZoomArrowRightIcon viewBox="0 0 80 80" htmlColor={blue.main}></ZoomArrowRightIcon>
          </div>
        </Link>
      </div>
      <Divider className="user-info-divider"></Divider>
      <div className="user-info-part-container">
        <h5 className="user-info-title">{props.labelMyPowerOfAttorney}</h5>
        {poas}
        <Link href={'/my-power-of-attorney-overview/' + props.dossierId}>
          <Button variant="outlined" size="large" fullWidth color="primary" className="user-info-btn">
            {props.labelEditPowerOfAttorney}
          </Button>
        </Link>
      </div>
    </>
  );
};

export default ZoomUserInfo;
