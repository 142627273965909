import { forkJoin, Observable, of } from 'rxjs';
import { Epic, ofType } from 'redux-observable';
import {
  PayoutDataAction,
  PayoutDataGetAction,
  PayoutDataGetParentDataAction,
  PayoutDataGetParentDataResultAction,
  PayoutDataGetPersonalDataAction,
  PayoutDataGetPersonalDataResultAction,
  PayoutDataGetPostalCodesAction,
  PayoutDataGetPostalCodesErrorAction,
  PayoutDataGetPostalCodesResultAction,
  PayoutDataGetResultAction,
  PayoutDataResultAction,
  PayoutDataSubmitAction,
} from './actions';
import { Action } from 'redux';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { AjaxResponse } from 'rxjs/ajax';
import { PostalCode } from '../contact-data/types';
import { PayoutData } from './types';
import { customAjax } from '../../utils/ajax-wrapper';
import { handleRequestFailureInEpic } from '../../utils/error-handling';

const addPayoutMultiple = (payloadArray: Array<PayoutData>): PayoutDataGetResultAction => {
  return {
    type: PayoutDataAction.GET_RESULT,
    payload: { ...payloadArray[0], ...payloadArray[1] },
  };
};

const getSubmitResults = (responses: Array<AjaxResponse>): PayoutDataResultAction => {
  return {
    type: PayoutDataAction.RESULT,
    payload: { ...responses[0].response, ...responses[1].response },
  };
};

const setPostalCodeData = (payload: AjaxResponse): PayoutDataGetPostalCodesResultAction => {
  return {
    type: PayoutDataAction.GET_POSTAL_CODES_RESULT,
    payload: payload.response[0] as PostalCode,
  };
};

export const getPayoutDataEpic: Epic<Action, Action> = (action$: Observable<Action>): Observable<Action> => {
  return action$.pipe(
    ofType(PayoutDataAction.GET),
    mergeMap((action) => {
      const demandId: number = (action as PayoutDataGetAction).demandId;

      const loanObservable = customAjax({
        subRoute: `/dossier/v1/demands/${demandId}/loan`,
        withCredentials: true,
        method: 'GET',
      }).pipe(
        map((res) => res.response),
        catchError(() => of({ loanRequested: false, loanAmountDesired: 0 })),
      );

      const paymentObservable = customAjax({
        subRoute: `/dossier/v1/demands/${demandId}/payments`,
        withCredentials: true,
        method: 'GET',
      }).pipe(
        map((res) => res.response),
        catchError(() => of({})),
      );
      return forkJoin([loanObservable, paymentObservable]).pipe(
        map((responseArray) => addPayoutMultiple(responseArray)),
      );
    }),
  );
};

export const sendPayoutDataEpic: Epic<Action, Action> = (action$: Observable<Action>): Observable<Action> => {
  return action$.pipe(
    ofType(PayoutDataAction.SUBMIT),
    mergeMap((action) => {
      const payoutDataAction: PayoutDataSubmitAction = action as PayoutDataSubmitAction;
      const demandId = payoutDataAction.demandId;

      const payoutObservable = customAjax({
        subRoute: `/dossier/v1/demands/${demandId}/payments`,
        withCredentials: true,
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payoutDataAction.payload),
      });

      const loanRequested = payoutDataAction.payload.loanRequested;
      const loanAmountDesired = payoutDataAction.payload.loanAmountDesired;

      const loanObservable = customAjax({
        subRoute: `/dossier/v1/demands/${demandId}/loan`,
        withCredentials: true,
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ loanRequested, loanAmountDesired }),
      });

      return forkJoin([payoutObservable, loanObservable]).pipe(map((responseArray) => getSubmitResults(responseArray)));
    }),
  );
};

export const getPayoutPostalCodeDataEpic: Epic<Action, Action> = (action$: Observable<Action>): Observable<Action> =>
  action$.pipe(
    ofType(PayoutDataAction.GET_POSTAL_CODES),
    mergeMap((action) => {
      const payoutData = (action as PayoutDataGetPostalCodesAction).payload;

      return customAjax({
        subRoute: `/master-data/v1/${payoutData.zipCode}/locality`,
        withCredentials: false,
        method: 'GET',
        headers: {
          'Accept-Language': 'de-de',
        },
      }).pipe(
        map((response) => setPostalCodeData(response)),
        catchError(() => {
          const errorAction: PayoutDataGetPostalCodesErrorAction = {
            type: PayoutDataAction.GET_POSTAL_CODES_ERROR,
            payload: 'No city found for ' + payoutData.zipCode,
          };
          return of(errorAction);
        }),
      );
    }),
  );

const loadParentData = (payload: AjaxResponse): PayoutDataGetParentDataResultAction => {
  return {
    type: PayoutDataAction.GET_PARENT_DATA_RESULT,
    payload: { ...payload.response },
  };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const loadParentDataOnError = (error: any): any => {
  if (error.status == 404) {
    return {
      type: PayoutDataAction.GET_PARENT_DATA_ERROR,
      payout: 'no data',
    };
  }
  return handleRequestFailureInEpic(error);
};

export const getParentDataForPayoutEpic: Epic<Action, Action> = (action$: Observable<Action>): Observable<Action> =>
  action$.pipe(
    ofType(PayoutDataAction.GET_PARENT_DATA),
    mergeMap((action) => {
      const payoutDataAction = action as PayoutDataGetParentDataAction;
      return customAjax({
        subRoute: `/dossier/v1/persons-in-formation/${payoutDataAction.pifId}/${payoutDataAction.payload}`,
        withCredentials: true,
        method: 'GET',
      }).pipe(
        map((response) => loadParentData(response)),
        catchError((error) => {
          return of(loadParentDataOnError(error));
        }),
      );
    }),
  );

const loadPersonalData = (payload: AjaxResponse): PayoutDataGetPersonalDataResultAction => {
  return {
    type: PayoutDataAction.GET_PERSONAL_DATA_RESULT,
    payload: { ...payload.response },
  };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const loadPersonalDataOnError = (error: any): any => {
  if (error.status == 404) {
    return {
      type: PayoutDataAction.GET_PERSONAL_DATA_ERROR,
      payout: 'no data',
    };
  }
  return handleRequestFailureInEpic(error);
};

export const getPersonalDataForPayoutEpic: Epic<Action, Action> = (action$: Observable<Action>): Observable<Action> =>
  action$.pipe(
    ofType(PayoutDataAction.GET_PERSONAL_DATA),
    mergeMap((action) => {
      const personalDataAction = action as PayoutDataGetPersonalDataAction;
      return customAjax({
        subRoute: `/dossier/v1/demands/${personalDataAction.payload}/person-in-formation`,
        withCredentials: true,
        method: 'GET',
      }).pipe(
        map((response) => loadPersonalData(response)),
        catchError((error) => {
          return of(loadPersonalDataOnError(error));
        }),
      );
    }),
  );
