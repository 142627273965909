import { Action } from 'redux';
import { Epic, ofType } from 'redux-observable';
import { createPatch } from 'rfc6902';
import { Observable, of } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { customAjax } from '../../utils/ajax-wrapper';
import { handleRequestFailureInEpic, redirectToDataUnavailableOr } from '../../utils/error-handling';
import { RoutingAction, RoutingActionType } from '../routing/actions';
import {
  EditContactDataPageAction,
  EditContactDataPageGetAction,
  EditContactDataPageGetResultAction,
  EditContactDataPageSubmitAction,
} from './actions';

const addEditContactDataPage = (payload: AjaxResponse): EditContactDataPageGetResultAction => {
  return {
    type: EditContactDataPageAction.GET_RESULT,
    payload: payload.response.contactData,
  };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getEditContactDataPageOnError = (error: any, dossierId: number): any => {
  if (error.status === 404) {
    return redirectToDataUnavailableOr(
      error,
      { type: EditContactDataPageAction.NON_EXISTENT_EDIT_CONTACT_DATA_PAGE },
      dossierId,
    );
  }
  return handleRequestFailureInEpic(error);
};

const getSubmitResult = (payload: AjaxResponse, dossierId: number): RoutingActionType => {
  return {
    type: RoutingAction.REDIRECT_TO,
    payload: `/my-data/dossier-intranet/mydata/${dossierId}`,
  };
};

export const getEditContactDataPageEpic: Epic<Action, Action> = (action$: Observable<Action>): Observable<Action> =>
  action$.pipe(
    ofType(EditContactDataPageAction.GET),
    mergeMap((action) => {
      const dossierId = (action as EditContactDataPageGetAction).payload;
      return customAjax({
        subRoute: `/dossier-intranet/v1/mydata/${dossierId}`,
        withCredentials: true,
        method: 'GET',
      }).pipe(
        map((response) => addEditContactDataPage(response)),
        catchError((error) => {
          return of(getEditContactDataPageOnError(error, dossierId));
        }),
      );
    }),
  );

export const sendEditContactDataPageEpic: Epic<Action, Action> = (action$: Observable<Action>): Observable<Action> =>
  action$.pipe(
    ofType(EditContactDataPageAction.SUBMIT),
    mergeMap((action) => {
      const outgoingDataAction: EditContactDataPageSubmitAction = action as EditContactDataPageSubmitAction;
      const dossierId = outgoingDataAction.dossierId;

      return customAjax({
        subRoute: `/dossier-intranet/v1/mydata/${dossierId}`,
        withCredentials: true,
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json-patch+json',
        },
        body: JSON.stringify(
          createPatch(
            {
              contactData: {
                phoneNumberPrivate: '',
                emailAddress: '',
              },
            },
            {
              contactData: {
                phoneNumberPrivate: outgoingDataAction.payload.phoneNumberPrivate,
                emailAddress: outgoingDataAction.payload.emailAddress,
              },
            },
          ),
        ),
      }).pipe(
        map(
          (response) => getSubmitResult(response, dossierId),
          catchError((error) => {
            return of(handleRequestFailureInEpic(error));
          }),
        ),
      );
    }),
    catchError((error) => {
      return of(handleRequestFailureInEpic(error));
    }),
  );
