import { Breadcrumbs, CircularProgress, Container, Link } from '@material-ui/core';
import React, { ReactElement, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Action } from 'redux';
import { HolderDropDownProps } from '../../components/base-page-components/holder-dropdown/HolderDropDown';
import { HolderDataAction, HolderDataGetAction } from '../../store/holder-data/actions';
import { i18nLabel } from '../../store/i18n/helpers';
import { WIAAStore } from '../../store/types';
import { setTimeToUserLocalTime } from '../../utils/date-manipulation';
import { mapStoreToHolderDropDownProps } from '../../utils/mappers';
import ZoomFooter, {
  mapStoreToFooterProps,
  ZoomFooterProps,
} from './../../components/base-page-components/footer/ZoomFooter';
import ZoomHeader, {
  mapStoreToHeaderProps,
  ZoomHeaderProps,
} from './../../components/base-page-components/header/ZoomHeader';
import { NotificationAction, NotificationMarkAllAsReadAction } from '../../store/notifications/actions';
import { Notification } from '../../store/notifications/types';
import { NavigationItems } from '../../components/base-page-components/navigation/Navigation';

export interface NotificationsProps {
  title: string;
  markAllAsReadLabel: string;
  notifications: Array<Notification>;
  headerProps: ZoomHeaderProps;
  footerProps: ZoomFooterProps;
  breadcrumbLabels: Array<string>;
  labelBackArrow: string;
  dossierId: number;
  holderDropDownProps: HolderDropDownProps;
  isLoading?: boolean;
  dispatch?: (action: Action) => void;
}

export const Notifications = (props: NotificationsProps): ReactElement => {
  useEffect(() => {
    if (props.dispatch && props.dossierId) {
      const getHolderData: HolderDataGetAction = {
        type: HolderDataAction.GET,
      };
      props.dispatch(getHolderData);
    }
  }, []);

  const markAllAsRead = () => {
    if (props.dispatch) {
      const markNotificationAsRead = {
        type: NotificationAction.MARK_ALL_AS_READ,
        dossierId: props.dossierId,
      } as NotificationMarkAllAsReadAction;
      props.dispatch(markNotificationAsRead);
    }
  };

  const notifications = props.notifications.map((notification) => {
    return (
      <li key={notification.id}>
        <div className="notification-btn-wrapper" />
        <h3 className="notification-title">
          {notification.isNew && <span className="new-notification-indicator" />}
          {notification.title}
        </h3>
        <p className="notification-message">{notification.message}</p>
        <p className="notification-date">{setTimeToUserLocalTime(notification.sendDate)}</p>
      </li>
    );
  });

  return (
    <>
      <ZoomHeader {...props.headerProps} dispatch={props.dispatch} />
      <main className="main">
        {props.isLoading ? (
          <div style={{ textAlign: 'center' }}>
            <CircularProgress />
          </div>
        ) : (
          <>
            <Container>
              <div id="main-page-container">
                <div className="main-content-limited">
                  <Breadcrumbs separator=">" aria-label="breadcrumb">
                    <Link
                      style={{ fontFamily: 'Proxima Nova Cond' }}
                      color="inherit"
                      href={'/dashboard/dossier/' + props.dossierId}
                    >
                      <b>{props.breadcrumbLabels && props.breadcrumbLabels[0]}</b>
                    </Link>
                    <Link style={{ fontFamily: 'Proxima Nova Cond' }} color="inherit">
                      {props.breadcrumbLabels && props.breadcrumbLabels[1]}
                    </Link>
                  </Breadcrumbs>
                  <NavigationItems
                    mainPageTopInfo={true}
                    labelBackArrow={props.labelBackArrow}
                    holderDropDownProps={props.holderDropDownProps}
                    dispatch={props.dispatch}
                  />
                  <div className="heading-wrapper clearfix">
                    <h1>{props.title}</h1>
                    <p className="righted-link-block">
                      <a onClick={markAllAsRead} style={{ cursor: 'pointer' }}>
                        {props.markAllAsReadLabel}
                      </a>{' '}
                    </p>
                  </div>
                </div>
                <div className="main-content-limited">
                  <ul className="notification-list">{notifications}</ul>
                </div>
              </div>
            </Container>
          </>
        )}
      </main>
      <ZoomFooter {...props.footerProps} />
    </>
  );
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
export const mapStateToNotificationProps = (store: WIAAStore, ownProps: any): NotificationsProps => {
  const { dossierId } = ownProps.match.params;
  const headerProps: ZoomHeaderProps = mapStoreToHeaderProps(store, dossierId);
  const footerProps: ZoomFooterProps = mapStoreToFooterProps(store);

  return {
    dossierId,
    headerProps,
    footerProps,
    holderDropDownProps: mapStoreToHolderDropDownProps(store, parseInt(dossierId)),
    title: i18nLabel(store.translationLabels, 'Notifications_Title', 'Benachrichtigungen'),
    notifications: store.notificationData.notifications,
    markAllAsReadLabel: i18nLabel(store.translationLabels, 'Notifications_Mark_All_Read', 'Alle als gelesen markieren'),
    breadcrumbLabels: [
      i18nLabel(store.translationLabels, 'Notifications_Breadcrumb_Dashboard', 'Übersicht'),
      i18nLabel(store.translationLabels, 'Notifications_Breadcrumb_NotificationsPage', 'Benachrichtigungen'),
    ],
    labelBackArrow: i18nLabel(store.translationLabels, 'Notifications_Back_Button', 'Zurück'),
    isLoading: store.notificationData.isLoading,
  };
};

export default withRouter(connect(mapStateToNotificationProps)(Notifications));
