import { withStyles } from '@material-ui/styles';
import { TableCell } from '@material-ui/core';
import SearchBar from 'material-ui-search-bar';

/***
 * Custom table cell style, for admin dashboard
 * text in cell is grey and bold (header)
 */
export const CustomTitleCell = withStyles({
  root: {
    color: 'grey',
    fontWeight: 'bold',
  },
})(TableCell);

/***
 * Custom table cell style, for admin dashboard
 * text in cell is grey
 */
export const CustomCell = withStyles({
  root: {
    color: 'grey',
  },
})(TableCell);

/***
 * Custom search bar style, for admin dashboard
 * search text color is grey
 */
export const CustomSearchBar = withStyles({
  input: {
    width: '100%',
    color: 'grey',
  },
})(SearchBar);
