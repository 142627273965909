import { Button, Grid } from '@material-ui/core';
import React, { ChangeEvent, ReactElement, useEffect, useState } from 'react';
import ZoomSelectInput from '../../components/forms/select-input/ZoomSelectInput';
import ZoomTextInput from '../../components/forms/text-input/ZoomTextInput';
import ZoomToggleInput from '../../components/forms/toggle-input/ZoomToggleInput';
import './EducationDataForm.scss';
import ZoomDatePickerStartEndDate from '../../components/forms/date-picker-start-end-date/ZoomDatePickerStartEndDate';
import { maxValueValidation, minValueValidation, requiredValidation } from '../../utils/validators';
import {
  EducationDataAction,
  EducationDataGetDegreeProgramsAction,
  EducationDataGetInstitutionsAction,
  EducationDataUpdateAction,
  EducationDataUpdateAvailableDegreeProgramsAction,
  EducationDataUpdateAvailableInstituionsAction,
} from '../../store/education-data/actions';
import { DegreeProgram, EducationData, Institution, InstitutionType } from '../../store/education-data/types';
import { setDateToMiddleOfMonth } from '../../utils/date-manipulation';
import {
  educationAdditionalOccupationForTypes,
  educationCreditsForTypes,
  EducationDataErrors,
  EducationDataFormProps,
  educationDegreeDesignationForTypes,
  educationVocationalBaccalaureate,
} from './EducationDataFormProps';
import { sortSelectOptions } from '../../utils/sort-select-options';
import { useHistory } from 'react-router-dom';

const getInstitutionTypeOptions = (
  isInSwitzerland: boolean,
  availableInstitutionTypes: InstitutionType[] | undefined,
): { name: string; value: string }[] => {
  if (availableInstitutionTypes) {
    return (
      availableInstitutionTypes
        // id === 1 is the default value (-) and id === 99 is (Andere)
        .filter(
          (institution) =>
            institution.inSwitzerland === isInSwitzerland || institution.id === 1 || institution.id === 99,
        )
        .map((institutionType) => ({ name: institutionType.name, value: institutionType.id.toString() }))
    );
  }
  return [];
};

const isProgramChoiceAvailable = (
  institutionTypeId: string,
  institutionTypes: InstitutionType[] | undefined,
): boolean => {
  if (institutionTypes) {
    const institution = institutionTypes.find((institution) => institution.id.toString() === institutionTypeId);
    if (institution) {
      return institution.programChoiceAvailable;
    }
  }
  return false;
};

const getInstitutionsOptions = (institutions: Institution[] | undefined): { name: string; value: string }[] => {
  if (institutions) {
    return institutions.map((institution) => ({
      name: institution.name,
      value: institution.id.toString(),
    }));
  }
  return [];
};

const getDegreeProgramOptions = (degreePrograms: DegreeProgram[] | undefined): { name: string; value: string }[] => {
  if (degreePrograms) {
    return degreePrograms.map((degreeProgram) => ({
      name: degreeProgram.name,
      value: degreeProgram.id.toString(),
    }));
  }
  return [];
};

const defaultValueDegreeId = 1;
const defaultValueFacilityId = 1;
const defaultValueEducationTypeId = 1;
const ANDERE_SELECT_ONLY_FE_VALUE = '-99';
const EDUCATION_TYPE_ID_ANDERE_SELECT_VALUE = 99;

export const EducationDataForm = (props: EducationDataFormProps): ReactElement => {
  const [inSwitzerland, setInSwitzerland] = useState(props.inSwitzerland ?? true);
  const [educationType, setEducationType] = useState(props.educationType ?? '0');
  const [educationTypeId, setEducationTypeId] = useState(props.educationTypeId ?? defaultValueEducationTypeId);
  const [educationTypeModified, setEducationTypeModified] = useState(false);
  const [educationFacilityName, setEducationFacilityName] = useState(
    props.educationFacilityName ? props.educationFacilityName : '',
  );
  const [facilityId, setFacilityId] = useState(props.facilityId ?? defaultValueFacilityId);
  const [degreeProgramId, setDegreeProgramId] = useState(props.degreeId ?? defaultValueDegreeId);
  const [educationFacilityLocation, setEducationFacilityLocation] = useState(
    props.educationFacilityLocation ? props.educationFacilityLocation : '',
  );
  const [educationDegreeDesignation, setEducationDegreeDesignation] = useState(
    props.educationDegreeDesignation ? props.educationDegreeDesignation : '',
  );
  const [educationDurationStart, setEducationDurationStart] = useState(
    props.educationDurationStart ? props.educationDurationStart : undefined,
  );
  const [educationDurationEnd, setEducationDurationEnd] = useState(
    props.educationDurationEnd ? props.educationDurationEnd : undefined,
  );
  const [educationWorkload, setEducationWorkload] = useState(props.educationWorkload ? props.educationWorkload : '');
  const [educationYearRepeat, setEducationYearRepeat] = useState(
    props.educationYearRepeat ? props.educationYearRepeat : '',
  );
  const [educationChanged, setEducationChanged] = useState(props.educationChanged ? props.educationChanged : '');
  const [educationOld, setEducationOld] = useState(props.educationOld ? props.educationOld : '');
  const [educationNew, setEducationNew] = useState(props.educationNew ? props.educationNew : '');
  const [educationAdditionalOccupation, setEducationAdditionalOccupation] = useState(
    props.educationAdditionalOccupation ? props.educationAdditionalOccupation : '',
  );
  const [educationCredits, setEducationCredits] = useState(props.educationCredits ? props.educationCredits : '');
  const [educationDegree, setEducationDegree] = useState(props.educationDegree ? props.educationDegree : '');
  const initialErrors: EducationDataErrors = {};
  const [errors, setErrors] = useState(initialErrors);
  const [educationChangeReason, setEducationChangeReason] = useState('');
  const [institutionTypes, setInstitutionTypes] = useState(
    getInstitutionTypeOptions(inSwitzerland, props.availableInstitutionTypes),
  );
  const [vocationalBaccalaureate, setVocationalBaccalaureate] = useState(props.vocationalBaccalaureate ?? '');
  const history = useHistory();

  useEffect(() => {
    setInSwitzerland(props.inSwitzerland ?? true);
    setFacilityId(props.facilityId ?? defaultValueFacilityId);
    setDegreeProgramId(props.degreeId ?? defaultValueDegreeId);
    setInstitutionTypes(getInstitutionTypeOptions(inSwitzerland, props.availableInstitutionTypes));
    setEducationType(educationTypeModified ? educationType : props.educationType ? props.educationType : '0');
    setEducationFacilityName(props.educationFacilityName ? props.educationFacilityName : '');
    setEducationFacilityLocation(props.educationFacilityLocation ? props.educationFacilityLocation : '');
    setEducationDegreeDesignation(props.educationDegreeDesignation ? props.educationDegreeDesignation : '');
    setEducationDurationStart(props.educationDurationStart ? props.educationDurationStart : undefined);
    setEducationDurationEnd(props.educationDurationEnd ? props.educationDurationEnd : undefined);
    setEducationWorkload(props.educationWorkload ? props.educationWorkload : '');
    setEducationYearRepeat(props.educationYearRepeat ? props.educationYearRepeat : '');
    setEducationChanged(props.educationChanged ? props.educationChanged : '');
    setEducationOld(props.educationOld ? props.educationOld : '');
    setEducationNew(props.educationNew ? props.educationNew : '');
    setEducationAdditionalOccupation(props.educationAdditionalOccupation ? props.educationAdditionalOccupation : '');
    setEducationCredits(props.educationCredits ? props.educationCredits : '');
    setEducationDegree(props.educationDegree ? props.educationDegree : '');
    setEducationTypeId(props.educationTypeId ? props.educationTypeId : defaultValueEducationTypeId);
    setVocationalBaccalaureate(props.vocationalBaccalaureate ?? '');
  }, [props]);

  useEffect(() => {
    if (props.dispatch) {
      if (
        educationTypeId === defaultValueEducationTypeId ||
        educationTypeId === EDUCATION_TYPE_ID_ANDERE_SELECT_VALUE
      ) {
        return;
      }
      const getInstitutionsAction: EducationDataGetInstitutionsAction = {
        type: EducationDataAction.GET_INSTITUTIONS,
        payload: educationTypeId,
      };
      props.dispatch(getInstitutionsAction);
    }
  }, [educationTypeId]);

  useEffect(() => {
    if (props.dispatch) {
      if (facilityId === defaultValueFacilityId || facilityId === +ANDERE_SELECT_ONLY_FE_VALUE) return;
      const getDegreeProgramsAction: EducationDataGetDegreeProgramsAction = {
        type: EducationDataAction.GET_DEGREE_PROGRAMS,
        payload: facilityId,
      };
      props.dispatch(getDegreeProgramsAction);
    }
  }, [facilityId]);

  const onChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    switch (e.target.name) {
      case 'educationFacilityName':
        setEducationFacilityName(e.target.value);
        setErrors({
          ...errors,
          educationFacilityName: requiredValidation(e.target.value, props.errorLabels ? props.errorLabels : {}),
        });
        break;
      case 'educationFacilityLocation':
        setEducationFacilityLocation(e.target.value);
        setErrors({
          ...errors,
          educationFacilityLocation: requiredValidation(e.target.value, props.errorLabels ? props.errorLabels : {}),
        });
        break;
      case 'educationDegreeDesignation':
        setEducationDegreeDesignation(e.target.value);
        setErrors({
          ...errors,
          educationDegreeDesignation: requiredValidation(e.target.value, props.errorLabels ? props.errorLabels : {}),
        });
        break;
      case 'educationCredits':
        setEducationCredits(e.target.value);
        setErrors({ ...errors, educationCredits: getEducationCreditErrors(e.target.value) });
        break;
      case 'educationOld':
        setEducationOld(e.target.value);
        setErrors({
          ...errors,
          educationOld: requiredValidation(e.target.value, props.errorLabels ? props.errorLabels : {}),
        });
        break;
      case 'educationNew':
        setEducationNew(e.target.value);
        setErrors({
          ...errors,
          educationNew: requiredValidation(e.target.value, props.errorLabels ? props.errorLabels : {}),
        });
        break;
      case 'educationChangeReason':
        setEducationChangeReason(e.target.value);
        setErrors({
          ...errors,
          educationChangeReason: requiredValidation(e.target.value, props.errorLabels ? props.errorLabels : {}),
        });
        break;
    }
  };

  const onClickInSwitzerlandChanged = (newValue: string) => {
    setInSwitzerland(newValue === 'true');
    setInstitutionTypes(getInstitutionTypeOptions(newValue === 'true', props.availableInstitutionTypes));
    setEducationTypeId(1);
    setFacilityId(1);
    setDegreeProgramId(1);

    if (props.dispatch) {
      const educationData: EducationData = {
        ...getEducationData(),
        inSwitzerland: newValue === 'true',
        educationTypeId: 1,
      };
      const updateFormDataAction: EducationDataUpdateAction = {
        type: EducationDataAction.UPDATE_DATA,
        payload: educationData,
      };
      props.dispatch(updateFormDataAction);

      const updateFormDataAvailableInstituionsAction: EducationDataUpdateAvailableInstituionsAction = {
        type: EducationDataAction.UPDATE_AVAILABLE_INSTITUTIONS,
        payload: [],
      };
      props.dispatch(updateFormDataAvailableInstituionsAction);

      const updateFormDataAvailableDegreeProgramsAction: EducationDataUpdateAvailableDegreeProgramsAction = {
        type: EducationDataAction.UPDATE_AVAILABLE_DEGREE_PROGRAMS,
        payload: [],
      };
      props.dispatch(updateFormDataAvailableDegreeProgramsAction);
    }
  };

  const getEducationCreditErrors = (value: string): string => {
    let error: string;
    error = maxValueValidation(value, 100, props.errorLabels ? props.errorLabels : {});
    if (!error) {
      error = minValueValidation(value, 15, props.errorLabels ? props.errorLabels : {});
    }
    return error;
  };

  const onEducationTypeChange = (newValue: string) => {
    const educationTypeId = parseInt(newValue);
    setEducationTypeModified(true);
    setEducationTypeId(educationTypeId);
    setEducationType(newValue);
    setFacilityId(defaultValueFacilityId);
    setDegreeProgramId(defaultValueDegreeId);

    if (props.dispatch) {
      const educationData: EducationData = {
        ...getEducationData(),
        educationTypeId,
        facilityId: defaultValueFacilityId,
        degreeProgramId: defaultValueDegreeId,
        formationInstitution: '',
        formationLocation: '',
        formationCertificate: '',
        vocationalBaccalaureate: null,
      };
      const updateFormDataAction: EducationDataUpdateAction = {
        type: EducationDataAction.UPDATE_DATA,
        payload: educationData,
      };
      props.dispatch(updateFormDataAction);
    }
    setErrors({
      ...errors,
      educationTypeId:
        educationTypeId === defaultValueEducationTypeId
          ? requiredValidation('', props.errorLabels ? props.errorLabels : {})
          : '',
    });
  };

  const onEducationFacilityIdChange = (newValue: string) => {
    const facilityId = parseInt(newValue);
    setFacilityId(facilityId);
    setDegreeProgramId(1);
    if (props.dispatch) {
      if (newValue === ANDERE_SELECT_ONLY_FE_VALUE) {
        const educationData: EducationData = {
          ...getEducationData(),
          educationTypeId: EDUCATION_TYPE_ID_ANDERE_SELECT_VALUE,
          facilityId: defaultValueFacilityId,
          degreeProgramId: defaultValueDegreeId,
        };
        const updateFormDataAction: EducationDataUpdateAction = {
          type: EducationDataAction.UPDATE_DATA,
          payload: educationData,
        };
        props.dispatch(updateFormDataAction);
        return;
      }

      const educationData: EducationData = {
        ...getEducationData(),
        facilityId,
        degreeProgramId: defaultValueDegreeId,
      };
      const updateFormDataAction: EducationDataUpdateAction = {
        type: EducationDataAction.UPDATE_DATA,
        payload: educationData,
      };
      props.dispatch(updateFormDataAction);
    }
    setErrors({
      ...errors,
      facilityId:
        facilityId === defaultValueFacilityId ? requiredValidation('', props.errorLabels ? props.errorLabels : {}) : '',
    });
  };

  const onEducationDegreeProgramIdChange = (newValue: string) => {
    const degreeProgramId = parseInt(newValue);
    setDegreeProgramId(degreeProgramId);
    if (props.dispatch) {
      if (newValue === ANDERE_SELECT_ONLY_FE_VALUE) {
        const educationData: EducationData = {
          ...getEducationData(),
          educationTypeId: EDUCATION_TYPE_ID_ANDERE_SELECT_VALUE,
          degreeProgramId: defaultValueDegreeId,
        };
        const updateFormDataAction: EducationDataUpdateAction = {
          type: EducationDataAction.UPDATE_DATA,
          payload: educationData,
        };
        props.dispatch(updateFormDataAction);
        return;
      }

      const educationData: EducationData = {
        ...getEducationData(),
        degreeProgramId,
      };
      const updateFormDataAction: EducationDataUpdateAction = {
        type: EducationDataAction.UPDATE_DATA,
        payload: educationData,
      };
      props.dispatch(updateFormDataAction);
    }

    setErrors({
      ...errors,
      degreeProgramId:
        degreeProgramId === defaultValueDegreeId
          ? requiredValidation('', props.errorLabels ? props.errorLabels : {})
          : '',
    });
  };

  const onEducationDegreeChange = (newValue: string) => {
    setEducationDegree(newValue);
    setErrors({
      ...errors,
      educationDegree: newValue === '0' ? requiredValidation('', props.errorLabels ? props.errorLabels : {}) : '',
    });
  };

  const onChangeEducationDurationStartDate = (newDate: Date | null) => {
    if (null === newDate) {
      setEducationDurationStart(undefined);
    } else {
      setEducationDurationStart(newDate);
    }
    setErrors({
      ...errors,
      educationDurationStart:
        newDate === null ? requiredValidation('', props.errorLabels ? props.errorLabels : {}) : '',
    });
  };

  const onChangeEducationDurationEndDate = (newDate: Date | null) => {
    if (null === newDate) {
      setEducationDurationEnd(undefined);
    } else {
      setEducationDurationEnd(newDate);
    }
    setErrors({
      ...errors,
      educationDurationEnd: newDate === null ? requiredValidation('', props.errorLabels ? props.errorLabels : {}) : '',
    });
  };

  const onClickEducationWorkload = (newValue: string) => {
    setEducationWorkload(newValue);
    setErrors({
      ...errors,
      educationWorkload: requiredValidation(newValue, props.errorLabels ? props.errorLabels : {}),
    });
  };
  const onClickEducationYearRepeat = (newValue: string) => {
    setEducationYearRepeat(newValue);
    setErrors({
      ...errors,
      educationYearRepeat: requiredValidation(newValue, props.errorLabels ? props.errorLabels : {}),
    });
  };
  const onClickEducationChanged = (newValue: string) => {
    setEducationChanged(newValue);
    setEducationNew('');
    setEducationOld('');
    setErrors({
      ...errors,
      educationChanged: requiredValidation(newValue, props.errorLabels ? props.errorLabels : {}),
    });
  };
  const onClickEducationAdditionalOccupation = (newValue: string) => {
    setEducationAdditionalOccupation(newValue);
    setErrors({
      ...errors,
      educationAdditionalOccupation: requiredValidation(newValue, props.errorLabels ? props.errorLabels : {}),
    });
  };

  const onClickVocationalBaccalaureate = (newValue: string) => {
    setVocationalBaccalaureate(newValue);
    setErrors({
      ...errors,
      vocationalBaccalaureate: requiredValidation(newValue, props.errorLabels ? props.errorLabels : {}),
    });
  };

  const getEducationData = (): EducationData => {
    const middleOfMonthStart = setDateToMiddleOfMonth(educationDurationStart);
    const middleOfMonthEnd = setDateToMiddleOfMonth(educationDurationEnd);
    const data: EducationData = {
      formationType: 99, //legacy shit, must be set to work
      //formationInstitution: educationFacilityName,
      //formationLocation: educationFacilityLocation,
      // formationCertificate: educationDegreeDesignation,
      formationStartDate: middleOfMonthStart ? middleOfMonthStart.toISOString() : '',
      formationEndDate: middleOfMonthEnd ? middleOfMonthEnd.toISOString() : '',
      formationFullTime: educationWorkload ? parseInt(educationWorkload) : undefined,
      // oldFormation: educationOld,
      // newFormation: educationNew,
      ectsPoints: educationCredits ? parseInt(educationCredits) : undefined,
      degreeProgram: educationDegree ? parseInt(educationDegree) : undefined,
      // new HABAS properties 2023
      inSwitzerland: inSwitzerland ?? true,
      educationTypeId: educationTypeId ?? undefined,
      facilityId: facilityId ?? undefined,
      degreeProgramId: degreeProgramId ?? undefined,
    };

    if (educationYearRepeat !== '') {
      data.repeatingYear = educationYearRepeat === '1';
    }

    if (educationChanged !== '') {
      data.changedFormationSinceLastDemand = educationChanged === '1';
      if (educationChanged === '1') {
        data.oldFormation = educationOld;
        data.newFormation = educationNew;
      }
    }

    if (educationAdditionalOccupation !== '') {
      data.doingAdditionalWork = educationAdditionalOccupation === '1';
    }
    if (educationDegreeDesignation !== '') {
      data.formationCertificate = educationDegreeDesignation;
    }
    if (vocationalBaccalaureate !== '') {
      data.vocationalBaccalaureate = vocationalBaccalaureate === '1';
    }

    if (degreeProgramId !== 1) {
      data.degreeProgramId = degreeProgramId;
    } else {
      data.formationInstitution = educationFacilityName;
      data.formationLocation = educationFacilityLocation;
    }

    if (facilityId !== 1) {
      data.facilityId = facilityId;
    }
    return data;
  };

  /**
   * 1) education type id is required, yes!
   * 2) - degreeProgramId is null => values educationFacilityName && educationFacilityLocation && educationDegreeDesignation are required
   * 3) - educationFacilityName is null => facilityId and degreeProgramId are required
   */
  const getFormErrors = (): EducationDataErrors => {
    const errors: EducationDataErrors = {};

    errors.educationTypeId =
      educationTypeId === defaultValueEducationTypeId
        ? requiredValidation('', props.errorLabels ? props.errorLabels : {})
        : '';

    // if program choice is available => facilityId and degreeProgramId are required
    // using if for better readability
    if (isProgramChoiceAvailable(educationTypeId.toString(), props.availableInstitutionTypes)) {
      errors.facilityId =
        facilityId === defaultValueFacilityId ? requiredValidation('', props.errorLabels ? props.errorLabels : {}) : '';
      errors.degreeProgramId =
        degreeProgramId === defaultValueDegreeId
          ? requiredValidation('', props.errorLabels ? props.errorLabels : {})
          : '';
    }

    // if program choice is not available
    // values educationFacilityName && educationFacilityLocation && educationDegreeDesignation are required
    if (!isProgramChoiceAvailable(educationTypeId.toString(), props.availableInstitutionTypes)) {
      errors.educationFacilityName = requiredValidation(
        educationFacilityName,
        props.errorLabels ? props.errorLabels : {},
      );
      errors.educationFacilityLocation = requiredValidation(
        educationFacilityLocation,
        props.errorLabels ? props.errorLabels : {},
      );

      if (educationDegreeDesignationForTypes.includes(educationTypeId)) {
        errors.educationDegreeDesignation = requiredValidation(
          educationDegreeDesignation,
          props.errorLabels ? props.errorLabels : {},
        );
      }
    }

    errors.educationDurationStart = requiredValidation(
      educationDurationStart ? educationDurationStart.toDateString() : '',
      props.errorLabels ? props.errorLabels : {},
    );
    errors.educationDurationEnd = requiredValidation(
      educationDurationEnd ? educationDurationEnd.toDateString() : '',
      props.errorLabels ? props.errorLabels : {},
    );

    if (!props.isEducationChangeForm) {
      errors.educationWorkload = requiredValidation(educationWorkload, props.errorLabels ? props.errorLabels : {});
    }
    errors.educationYearRepeat = !props.isEducationChangeForm
      ? requiredValidation(educationYearRepeat, props.errorLabels ? props.errorLabels : {})
      : '';
    errors.educationChanged = !props.isEducationChangeForm
      ? requiredValidation(educationChanged, props.errorLabels ? props.errorLabels : {})
      : '';
    if (!props.isEducationChangeForm && educationChanged === '1') {
      errors.educationOld = requiredValidation(educationOld, props.errorLabels ? props.errorLabels : {});
      errors.educationNew = requiredValidation(educationNew, props.errorLabels ? props.errorLabels : {});
    }
    if (!props.isEducationChangeForm && educationAdditionalOccupationForTypes.includes(educationTypeId)) {
      errors.educationAdditionalOccupation = requiredValidation(
        educationAdditionalOccupation,
        props.errorLabels ? props.errorLabels : {},
      );
    }
    if (!props.isEducationChangeForm && educationCreditsForTypes.includes(educationTypeId)) {
      errors.educationCredits = requiredValidation(educationCredits, props.errorLabels ? props.errorLabels : {});
      errors.educationDegree = requiredValidation(educationDegree, props.errorLabels ? props.errorLabels : {});
    }
    if (!props.isEducationChangeForm && educationVocationalBaccalaureate.includes(educationTypeId)) {
      errors.vocationalBaccalaureate = requiredValidation(
        vocationalBaccalaureate,
        props.errorLabels ? props.errorLabels : {},
      );
    }

    // validate reason required - ONLY FOR EDUCATION CHANGE PAGE!!!
    if (props.submitChangeAction) {
      errors.educationChangeReason = requiredValidation(
        educationChangeReason,
        props.errorLabels ? props.errorLabels : {},
      );
    }
    return errors;
  };

  const validateForm = (): boolean => {
    const newErrors = getFormErrors();
    setErrors(newErrors);
    const errorsPresent = Object.values(newErrors).some((value) => value !== '');
    return !errorsPresent;
  };

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    if (validateForm() && props.dispatch) {
      if (props.submitChangeAction) {
        const action = props.submitChangeAction;
        action.payload.body = JSON.stringify({
          educationChangeReason,
          ...getEducationData(),
        });
        props.dispatch(action);
      } else {
        props.dispatch({
          type: EducationDataAction.SUBMIT,
          payload: getEducationData(),
          demandId: props.demandId,
        });
      }
    }
    event.preventDefault();
  };
  let institutionOptions =
    educationTypeId === defaultValueEducationTypeId
      ? [{ name: '-', value: '1' }]
      : getInstitutionsOptions(props.availableInstitutions);
  if (institutionOptions.length > 1) {
    institutionOptions = [{ name: 'Andere', value: ANDERE_SELECT_ONLY_FE_VALUE }, ...institutionOptions];
  }
  let degreeProgramOptions =
    facilityId === defaultValueFacilityId
      ? [{ name: '-', value: '1' }]
      : getDegreeProgramOptions(props.availableDegreePrograms);
  if (degreeProgramOptions.length > 1) {
    degreeProgramOptions = [{ name: 'Andere', value: ANDERE_SELECT_ONLY_FE_VALUE }, ...degreeProgramOptions];
  }
  return (
    <>
      <form onSubmit={onSubmit}>
        <div className="form-element-container">
          <ZoomToggleInput
            value={inSwitzerland ? 'true' : 'false'}
            title={props.labelInSwitzerland}
            label1={props.inSwitzerlandTypes ? props.inSwitzerlandTypes[0].name : ''}
            value1={props.inSwitzerlandTypes ? props.inSwitzerlandTypes[0].value.toString() : ''}
            label2={props.inSwitzerlandTypes ? props.inSwitzerlandTypes[1].name : ''}
            value2={props.inSwitzerlandTypes ? props.inSwitzerlandTypes[1].value.toString() : ''}
            onChange={onClickInSwitzerlandChanged}
            error={!!errors.inSwitzerland}
            errorMessage={errors.inSwitzerland}
          ></ZoomToggleInput>
        </div>
        <div className="form-element-container">
          <ZoomSelectInput
            data-testid="test-id"
            label={props.labelEducationType}
            name="educationType"
            options={sortSelectOptions(institutionTypes)}
            value={educationTypeId.toString()}
            onChange={onEducationTypeChange}
            helpText={educationType === '28' ? props.helpTextEducationType : ''}
            error={!!errors.educationTypeId}
            errorMessage={errors.educationTypeId}
          ></ZoomSelectInput>
        </div>
        {educationTypeId != defaultValueEducationTypeId && (
          <>
            {isProgramChoiceAvailable(educationTypeId.toString(), props.availableInstitutionTypes) && (
              <>
                <div className="form-element-container">
                  <ZoomSelectInput
                    label={props.labelEducationFacilityName}
                    name="facilityId"
                    options={sortSelectOptions(institutionOptions)}
                    value={facilityId.toString()}
                    onChange={onEducationFacilityIdChange}
                    error={!!errors.facilityId}
                    errorMessage={errors.facilityId}
                  ></ZoomSelectInput>
                </div>

                <div className="form-element-container">
                  <ZoomSelectInput
                    label={props.labelDegreeProgramId}
                    name="degreeId"
                    value={degreeProgramId.toString()}
                    options={sortSelectOptions(degreeProgramOptions)}
                    onChange={onEducationDegreeProgramIdChange}
                    error={!!errors.degreeProgramId}
                    errorMessage={errors.degreeProgramId}
                  ></ZoomSelectInput>
                </div>
              </>
            )}
            {!isProgramChoiceAvailable(educationTypeId.toString(), props.availableInstitutionTypes) && (
              <>
                <div className="form-element-container">
                  <ZoomTextInput
                    label={props.labelEducationFacilityName}
                    name="educationFacilityName"
                    value={educationFacilityName}
                    onChange={onChange}
                    error={!!errors.educationFacilityName}
                    errorMessage={errors.educationFacilityName}
                  ></ZoomTextInput>
                </div>

                <div className="form-element-container">
                  <ZoomTextInput
                    label={props.labelEducationFacilityLocation}
                    name="educationFacilityLocation"
                    value={educationFacilityLocation}
                    onChange={onChange}
                    helpText={props.helpTextEducationFacilityLocation}
                    error={!!errors.educationFacilityLocation}
                    errorMessage={errors.educationFacilityLocation}
                  ></ZoomTextInput>
                </div>
                {props.educationTypeId && educationDegreeDesignationForTypes.includes(props.educationTypeId) && (
                  <div className="form-element-container">
                    <ZoomTextInput
                      label={props.labelEducationDegreeDesignation}
                      name="educationDegreeDesignation"
                      value={educationDegreeDesignation}
                      onChange={onChange}
                      error={!!errors.educationDegreeDesignation}
                      errorMessage={errors.educationDegreeDesignation}
                    ></ZoomTextInput>
                  </div>
                )}
              </>
            )}
          </>
        )}
        <div className="form-element-container">
          <ZoomDatePickerStartEndDate
            label={props.labelEducationDuration}
            name="educationDuration"
            valueStart={educationDurationStart}
            valueEnd={educationDurationEnd}
            onChangeStart={onChangeEducationDurationStartDate}
            onChangeEnd={onChangeEducationDurationEndDate}
            helpText={props.helpTextEducationDuration}
            error={!!errors.educationDurationStart || !!errors.educationDurationEnd}
            errorMessage={errors.educationDurationStart || errors.educationDurationEnd}
          ></ZoomDatePickerStartEndDate>
        </div>
        {!props.isEducationChangeForm && (
          <div className="form-element-container">
            <ZoomToggleInput
              value={educationWorkload}
              name="educationWorkload"
              title={props.labelEducationWorkload}
              label1={props.educationWorkloadTypes ? props.educationWorkloadTypes[0].name : ''}
              value1={props.educationWorkloadTypes ? props.educationWorkloadTypes[0].value.toString() : ''}
              label2={props.educationWorkloadTypes ? props.educationWorkloadTypes[1].name : ''}
              value2={props.educationWorkloadTypes ? props.educationWorkloadTypes[1].value.toString() : ''}
              onChange={onClickEducationWorkload}
              error={!!errors.educationWorkload}
              errorMessage={errors.educationWorkload}
            ></ZoomToggleInput>
          </div>
        )}
        {!props.isEducationChangeForm && (
          <div className="form-element-container">
            <ZoomToggleInput
              value={educationYearRepeat}
              name={'educationYearRepeat'}
              title={props.labelEducationYearRepeat}
              label1={
                props.toggleButtonsTypes != null && props.toggleButtonsTypes.length > 0
                  ? props.toggleButtonsTypes[0].name
                  : ''
              }
              value1={
                props.toggleButtonsTypes != null && props.toggleButtonsTypes.length > 0
                  ? props.toggleButtonsTypes[0].value.toString()
                  : ''
              }
              label2={
                props.toggleButtonsTypes != null && props.toggleButtonsTypes.length > 1
                  ? props.toggleButtonsTypes[1].name
                  : ''
              }
              value2={
                props.toggleButtonsTypes != null && props.toggleButtonsTypes.length > 1
                  ? props.toggleButtonsTypes[1].value.toString()
                  : ''
              }
              helpText={props.helpTextEducationYearRepeat}
              onChange={onClickEducationYearRepeat}
              error={!!errors.educationYearRepeat}
              errorMessage={errors.educationYearRepeat}
            ></ZoomToggleInput>
          </div>
        )}
        {!props.isEducationChangeForm && (
          <div className="form-element-container">
            <ZoomToggleInput
              value={educationChanged}
              name="educationChanged"
              title={props.labelEducationChanged}
              label1={
                props.toggleButtonsTypes != null && props.toggleButtonsTypes.length > 0
                  ? props.toggleButtonsTypes[0].name
                  : ''
              }
              value1={
                props.toggleButtonsTypes != null && props.toggleButtonsTypes.length > 0
                  ? props.toggleButtonsTypes[0].value.toString()
                  : ''
              }
              label2={
                props.toggleButtonsTypes != null && props.toggleButtonsTypes.length > 1
                  ? props.toggleButtonsTypes[1].name
                  : ''
              }
              value2={
                props.toggleButtonsTypes != null && props.toggleButtonsTypes.length > 1
                  ? props.toggleButtonsTypes[1].value.toString()
                  : ''
              }
              helpText={props.helpTextEducationChanged}
              onChange={onClickEducationChanged}
              error={!!errors.educationChanged}
              errorMessage={errors.educationChanged}
            ></ZoomToggleInput>
          </div>
        )}
        {!props.isEducationChangeForm && educationChanged === '1' && (
          <div className="form-element-container">
            <ZoomTextInput
              label={props.labelEducationOld}
              name="educationOld"
              value={educationOld}
              onChange={onChange}
              error={!!errors.educationOld}
              errorMessage={errors.educationOld}
            ></ZoomTextInput>
          </div>
        )}
        {!props.isEducationChangeForm && educationChanged === '1' && (
          <div className="form-element-container">
            <ZoomTextInput
              label={props.labelEducationNew}
              name="educationNew"
              value={educationNew}
              onChange={onChange}
              error={!!errors.educationNew}
              errorMessage={errors.educationNew}
            ></ZoomTextInput>
          </div>
        )}
        {!props.isEducationChangeForm && educationAdditionalOccupationForTypes.includes(educationTypeId) && (
          <div className="form-element-container">
            <ZoomToggleInput
              value={educationAdditionalOccupation}
              title={props.labelEducationAdditionalOccupation}
              label1={props.toggleButtonsTypes ? props.toggleButtonsTypes[0].name : ''}
              value1={props.toggleButtonsTypes ? props.toggleButtonsTypes[0].value.toString() : ''}
              label2={props.toggleButtonsTypes ? props.toggleButtonsTypes[1].name : ''}
              value2={props.toggleButtonsTypes ? props.toggleButtonsTypes[1].value.toString() : ''}
              onChange={onClickEducationAdditionalOccupation}
              error={!!errors.educationAdditionalOccupation}
              errorMessage={errors.educationAdditionalOccupation}
            ></ZoomToggleInput>
          </div>
        )}
        {!props.isEducationChangeForm && educationCreditsForTypes.includes(educationTypeId) && (
          <div className="form-element-container">
            <ZoomTextInput
              label={props.labelEducationCredits}
              name="educationCredits"
              value={educationCredits}
              onChange={onChange}
              error={!!errors.educationCredits}
              errorMessage={errors.educationCredits}
            ></ZoomTextInput>
          </div>
        )}
        {!props.isEducationChangeForm && educationCreditsForTypes.includes(educationTypeId) && (
          <div className="form-element-container">
            <ZoomSelectInput
              label={props.labelEducationDegree}
              name="educationDegree"
              options={props.degreePrograms ? props.degreePrograms : []}
              value={educationDegree}
              onChange={onEducationDegreeChange}
              helpText={props.helpTextEducationDegree}
              error={!!errors.educationDegree}
              errorMessage={errors.educationDegree}
            ></ZoomSelectInput>
          </div>
        )}
        {!props.isEducationChangeForm && educationVocationalBaccalaureate.includes(educationTypeId) && (
          <div className="form-element-container">
            <ZoomToggleInput
              value={props.vocationalBaccalaureate}
              title={props.labelVocationalBaccalaureate}
              label1={props.toggleButtonsTypes ? props.toggleButtonsTypes[0].name : ''}
              value1={props.toggleButtonsTypes ? props.toggleButtonsTypes[0].value.toString() : ''}
              label2={props.toggleButtonsTypes ? props.toggleButtonsTypes[1].name : ''}
              value2={props.toggleButtonsTypes ? props.toggleButtonsTypes[1].value.toString() : ''}
              onChange={onClickVocationalBaccalaureate}
              error={!!errors.vocationalBaccalaureate}
              errorMessage={errors.vocationalBaccalaureate}
            ></ZoomToggleInput>
          </div>
        )}
        {props.submitChangeAction ? (
          <>
            <div className="form-element-container">
              <ZoomTextInput
                label={props.labelEducationChangeReasonRequired ? props.labelEducationChangeReasonRequired : ''}
                name="educationChangeReason"
                value={educationChangeReason}
                onChange={onChange}
                multiline
                error={!!errors.educationChangeReason}
                errorMessage={errors.educationChangeReason}
              />
            </div>
            <Grid container direction="row" spacing={2} style={{ paddingBottom: '1rem' }}>
              <Grid item xs={6} sm={6}>
                <div className="form-actions-container-cancel">
                  <Button
                    color="primary"
                    size="large"
                    type="reset"
                    variant="outlined"
                    id="cancel-changed-education-data"
                    style={{ width: '144px' }}
                    onClick={() => history?.goBack()}
                  >
                    {props.labelCancel}
                  </Button>
                </div>
              </Grid>
              <Grid item xs={6} sm={6}>
                <div className="form-actions-container-save">
                  <Button
                    color="primary"
                    size="large"
                    type="submit"
                    variant="contained"
                    id="save-changed-education-data"
                    style={{ width: '144px' }}
                  >
                    {props.labelSaveChangeEducation}
                  </Button>
                </div>
              </Grid>
            </Grid>
          </>
        ) : (
          <div className="form-actions-container">
            <Button color="primary" type="submit" variant="contained" id="submit-education-data">
              {props.labelSave}
            </Button>
          </div>
        )}
      </form>
    </>
  );
};
