import produce from 'immer';
import { Action } from 'redux';
import { ChildrenDataAction } from '../children-data/actions';
import { CVDataAction } from '../cv-data/actions';
import {
  CompleteDemandErrorAction,
  DemandOverviewAction,
  DemandOverviewActionCloseTab,
  DemandOverviewActionLoadTabMobile,
  DemandOverviewActionProgressResult,
} from '../demand/actions';
import { DocumentsAction } from '../documents/actions';
import { EducationDataAction } from '../education-data/actions';
import { FatherDataAction } from '../father-data/actions';
import { GuardianDataAction } from '../guardian-data/actions';
import { IncomingDataAction } from '../incoming-data/actions';
import { LastDemandDataTakeoverAction } from '../last-demand-data-takeover/actions';
import { MotherDataAction } from '../mother-data/actions';
import { NotesDataAction } from '../notes-data/actions';
import { OutgoingDataAction } from '../outgoing-data/actions';
import { ParentsDataAction, ParentsDataGetResultAction, ParentsDataResultAction } from '../parents-data/actions';
import { PartnerDataAction } from '../partner-data/actions';
import { PayoutDataAction } from '../payout-data/actions';
import {
  ContactDataAction,
  PersonalDataAction,
  PersonalDataGetResultAction,
  PersonalDataResultAction,
} from '../personal-data/actions';
import { ScholarshipDataAction } from '../scholarship-data/actions';
import { SiblingsDataAction } from '../siblings-data/actions';
import { orderedRegisterTabs } from './helpers';
import { ContentTabKey, DemandData } from './types';

const initialContentTabState = {
  education: false,
  payout: false,
  loan: false,
  personalData: false,
  cv: false,
  contactData: false,
  income: false,
  expenses: false,
  parents: false,
  father: false,
  mother: false,
  guardian: false,
  partner: false,
  children: false,
  siblings: false,
  documents: false,
  request: false,
  notes: false,
};

export const initialState: DemandData = {
  tabLabelAdditionalData: '',
  tabLabelAttachments: 'Einzureichende Belege',
  tabLabelParentsData: 'Angaben zu den Eltern',
  tabLabelChildrenData: 'Angaben zu den Kindern',
  tabLabelSiblingsData: 'Angaben zu den Geschwistern',
  tabLabelScholarshipData: 'Angaben zum Stipendium',
  tabLabelPartnerData: 'Angaben zum Partner',
  tabLabelPifData: 'Angaben zum Antragsteller',
  tabLabelPayoutData: 'Angaben zur Auszahlung',
  contentLabelEducation: 'Ausbildung',
  contentLabelPayout: 'Auszahlung',
  contentLabelLoan: 'Darlehen',
  contentLabelPersonalData: 'Personalien',
  contentLabelContactData: 'Kontakt',
  contentLabelExpenses: 'Ausgaben',
  contentLabelIncome: 'Einnahmen',
  contentLabelCv: 'Lebenslauf',
  contentLabelPartner: 'Partner',
  labelNext: 'Danach',
  labelCompleteDemand: 'Antrag jetzt abschliessen',
  labelSavedSuccessfully: 'Speichern erfolgreich!',
  contentLabelChildren: 'Kinder',
  contentLabelFirstAcknowledgementTitle: 'Letzter Check - Stimmen Die Daten?',
  contentLabelFirstAcknowledgementParagraph1:
    'Mit dem Absenden des Formulars bestätigen Sie, dass das Formular korrekt ausgefüllt wurde. Nachträgliche Änderungen sind nur noch via Kundensupport möglich.',
  contentLabelFirstAcknowledgementParagraph2:
    'Achtung: Der Antrag ist erst gültig nach Einreichung Ihrer Unterschrift. Bitte folgen Sie den Anweisungen.',
  contentLabelFirstAcknowledgementSubmit: 'Antrag absenden',
  contentLabelSecondAcknowledgementTitle: 'Bestätigung Kenntnissnahme',
  contentLabelSecondAcknowledgementParagraph1:
    'Ich bestätige, dass die Angaben im elektronisch übermittelten Stipendiengesuch vollständig und wahrheitsgetreu sind.',
  contentLabelSecondAcknowledgementParagraph2:
    'Ich nehme zur Kenntnis, dass die Sektion Stipendien über einen Wechsel, Abbruch oder Unterbruch der Ausbildung (zum Beispiel wegen Krankheit) umgehend schriftlich informiert werden muss',
  contentLabelSecondAcknowledgementSubmit: 'Bestätigung / Kenntnisnahme',
  contentLabelModalCancel: 'Abbrechen',
  contentLabelCompleteDemandErrorTitle: 'Achtung!',
  contentLabelCompleteDemandErrorParagraph1:
    'Nur die von Ihnen bevollmächtigte Person kann den Antrag für Sie abschliessen. Dazu kann sich die bevollmäctigte Person im Stipendienportal einloggen.',
  contentLabelCompleteDemandErrorParagraph2: '',
  title: 'Stipendienantrag',
  breadcrumbLabels: ['Übersicht', 'Stipendienantrag'],
  labelBackArrow: 'zurück',
  contentTabState: initialContentTabState,
  contentTabProgress: initialContentTabState,
  activeTab: 0,
  orderedTabs: orderedRegisterTabs,
  completionTitle: 'Antrag erfolgreich abgeschlossen',
  completionParagraph1:
    'Ihr Antrag wurde erfolgreich eingereicht. <b>Achtung</b>: Bitte reichen Sie keinen weiteren Antrag für dieses Ausbildungsjahr ein.',
  completionParagraph2:
    'Falls wir noch weitere Informationen oder Unterlagen von Ihnen benötigen, werden wir uns mit Ihnen in Kontakt setzen.',
  completionParagraph3:
    'Der Stand der Gesuchsbearbeitung wird auf unserer Homepage publiziert und wöchentlich angepasst.',
  labelBackToDashboard: 'Zurück zum Dashboard',
};

export function demandReducer(state = initialState, action: Action): DemandData {
  return produce(state, (draft) => {
    switch (action.type) {
      case PersonalDataAction.GET:
        draft.contentTabProgress.personalData = true;
        draft.contentTabState.personalData = false;
        break;
      case PersonalDataAction.GET_RESULT:
        const pdGetAction = action as PersonalDataGetResultAction;
        draft.orderedTabs.tabs[4].hidden = !pdGetAction.payload.existingChildren;
        draft.orderedTabs.tabs[3].hidden = !(
          pdGetAction.payload.maritalStatus === 20 ||
          pdGetAction.payload.maritalStatus === 21 ||
          pdGetAction.payload.maritalStatus === 50
        );
        draft.orderedTabs.tabs[5].hidden = !pdGetAction.payload.existingSiblings;
        draft.contentTabProgress.personalData = false;
        draft.contentTabState.personalData = true;
        break;
      case PersonalDataAction.RESULT:
        const pdAction = action as PersonalDataResultAction;
        const childrens = pdAction.payload.existingChildren;
        const siblings = pdAction.payload.existingSiblings;
        draft.orderedTabs.tabs[4].hidden = !childrens;
        draft.orderedTabs.tabs[3].hidden = !(
          pdAction.payload.maritalStatus === 20 ||
          pdAction.payload.maritalStatus === 21 ||
          pdAction.payload.maritalStatus === 50
        );
        draft.orderedTabs.tabs[5].hidden = !siblings;
        draft.contentTabProgress.personalData = false;
        draft.contentTabState.personalData = false;
        draft.showSuccess = true;
        break;

      case ScholarshipDataAction.GET:
        draft.contentTabProgress.request = true;
        draft.contentTabState.request = false;
        break;
      case ScholarshipDataAction.GET_RESULT:
      case ScholarshipDataAction.NON_EXISTENT_SCOLARSHIP_INFO:
        draft.contentTabProgress.request = false;
        draft.contentTabState.request = true;
        break;
      case ScholarshipDataAction.RESULT:
      case ScholarshipDataAction.ADD_RESULT:
        draft.contentTabProgress.request = false;
        draft.contentTabState.request = false;
        draft.showSuccess = true;
        break;

      case EducationDataAction.GET:
        draft.contentTabProgress.education = true;
        draft.contentTabState.education = false;
        break;
      case EducationDataAction.RESULT:
        draft.contentTabProgress.education = false;
        draft.contentTabState.education = false;
        draft.showSuccess = true;
        break;
      case EducationDataAction.GET_RESULT:
      case EducationDataAction.NON_EXISTENT_EDUCATION:
        draft.contentTabProgress.education = false;
        draft.contentTabState.education = true;
        break;

      case ParentsDataAction.GET:
        draft.contentTabProgress.parents = true;
        draft.contentTabState.parents = false;
        break;
      case ParentsDataAction.GET_RESULT:
        const parentsDataActionGetResult = action as ParentsDataGetResultAction;
        const parentsGetResult = parentsDataActionGetResult.payload.typeOfRelationShip === 60;
        if (parentsGetResult) {
          draft.orderedTabs.tabs[2].forms[1].hidden = parentsGetResult;
          draft.orderedTabs.tabs[2].forms[2].hidden = parentsGetResult;
        } else {
          draft.orderedTabs.tabs[2].forms[1].hidden = parentsDataActionGetResult.payload.fatherMaritalStatus === 70;
          draft.orderedTabs.tabs[2].forms[2].hidden = parentsDataActionGetResult.payload.motherMaritalStatus === 70;
        }
        draft.orderedTabs.tabs[2].forms[3].hidden = parentsDataActionGetResult.payload.careSituation !== 4;
        draft.contentTabProgress.parents = false;
        draft.contentTabState.parents = true;
        break;
      case ParentsDataAction.RESULT:
        const parentsDataActionResult = action as ParentsDataResultAction;
        const parentsResult = parentsDataActionResult.payload.typeOfRelationShip === 60;
        const fatherResult = parentsDataActionResult.payload.fatherMaritalStatus === 70;
        const motherResult = parentsDataActionResult.payload.motherMaritalStatus === 70;
        if (parentsResult) {
          draft.orderedTabs.tabs[2].forms[1].hidden = parentsResult;
          draft.orderedTabs.tabs[2].forms[2].hidden = parentsResult;
        } else {
          draft.orderedTabs.tabs[2].forms[1].hidden = fatherResult;
          draft.orderedTabs.tabs[2].forms[2].hidden = motherResult;
        }
        draft.orderedTabs.tabs[2].forms[3].hidden = parentsDataActionResult.payload.careSituation !== 4;
        draft.contentTabProgress.parents = false;
        draft.contentTabState.parents = false;
        draft.showSuccess = true;
        break;
      case ParentsDataAction.NON_EXISTENT_PARENTS:
        draft.contentTabProgress.parents = false;
        draft.contentTabState.parents = true;
        break;

      case FatherDataAction.GET:
        draft.contentTabProgress.father = true;
        draft.contentTabState.father = false;
        break;
      case FatherDataAction.RESULT:
        draft.contentTabProgress.father = false;
        draft.contentTabState.father = false;
        draft.showSuccess = true;
        break;
      case FatherDataAction.GET_RESULT:
      case FatherDataAction.NON_EXISTENT_FATHER:
        draft.contentTabProgress.father = false;
        draft.contentTabState.father = true;
        break;

      case MotherDataAction.GET:
        draft.contentTabProgress.mother = true;
        draft.contentTabState.mother = false;
        break;
      case MotherDataAction.RESULT:
        draft.contentTabProgress.mother = false;
        draft.contentTabState.mother = false;
        draft.showSuccess = true;
        break;
      case MotherDataAction.GET_RESULT:
      case MotherDataAction.NON_EXISTENT_MOTHER:
        draft.contentTabProgress.mother = false;
        draft.contentTabState.mother = true;
        break;

      case GuardianDataAction.GET:
        draft.contentTabProgress.guardian = true;
        draft.contentTabState.guardian = false;
        break;
      case GuardianDataAction.RESULT:
        draft.contentTabProgress.guardian = false;
        draft.contentTabState.guardian = false;
        draft.showSuccess = true;
        break;
      case GuardianDataAction.GET_RESULT:
      case GuardianDataAction.NON_EXISTENT_GUARDIAN:
        draft.contentTabProgress.guardian = false;
        draft.contentTabState.guardian = true;
        break;

      case PayoutDataAction.GET:
        draft.contentTabProgress.payout = true;
        draft.contentTabState.payout = false;
        break;
      case PayoutDataAction.RESULT:
        draft.contentTabProgress.payout = false;
        draft.contentTabState.payout = false;
        draft.showSuccess = true;
        break;
      case PayoutDataAction.GET_RESULT:
        draft.contentTabProgress.payout = false;
        draft.contentTabState.payout = true;
        break;

      case IncomingDataAction.GET:
        draft.contentTabProgress.income = true;
        draft.contentTabState.income = false;
        break;
      case IncomingDataAction.RESULT:
        draft.contentTabProgress.income = false;
        draft.contentTabState.income = false;
        break;
      case IncomingDataAction.GET_RESULT:
      case IncomingDataAction.NON_EXISTENT_INCOMING:
        draft.contentTabProgress.income = false;
        draft.contentTabState.income = true;
        break;

      case OutgoingDataAction.GET:
        draft.contentTabProgress.expenses = true;
        draft.contentTabState.expenses = false;
        break;
      case OutgoingDataAction.RESULT:
        draft.contentTabProgress.expenses = false;
        draft.contentTabState.expenses = false;
        draft.showSuccess = true;
        break;
      case OutgoingDataAction.GET_RESULT:
      case OutgoingDataAction.NON_EXISTENT_OUTGOING:
        draft.contentTabProgress.expenses = false;
        draft.contentTabState.expenses = true;
        break;

      case ContactDataAction.GET:
        draft.contentTabProgress.contactData = true;
        draft.contentTabState.contactData = false;
        break;
      case ContactDataAction.RESULT:
        draft.contentTabProgress.contactData = false;
        draft.contentTabState.contactData = false;
        draft.showSuccess = true;
        break;
      case ContactDataAction.GET_RESULT:
        draft.contentTabProgress.contactData = false;
        draft.contentTabState.contactData = true;
        break;

      case CVDataAction.GET:
        draft.contentTabProgress.cv = true;
        draft.contentTabState.cv = false;
        break;
      case CVDataAction.GET_RESULT:
        draft.contentTabProgress.cv = false;
        draft.contentTabState.cv = true;
        break;

      case ChildrenDataAction.GET:
        draft.contentTabProgress.children = true;
        draft.contentTabState.children = false;
        break;
      case ChildrenDataAction.GET_RESULT:
        draft.contentTabProgress.children = false;
        draft.contentTabState.children = true;
        break;

      case SiblingsDataAction.GET:
        draft.contentTabProgress.siblings = true;
        draft.contentTabState.siblings = false;
        break;
      case SiblingsDataAction.GET_RESULT:
        draft.contentTabProgress.siblings = false;
        draft.contentTabState.siblings = true;
        break;
      case PartnerDataAction.GET:
        draft.contentTabProgress.partner = true;
        draft.contentTabState.partner = false;
        break;
      case PartnerDataAction.GET_RESULT:
      case PartnerDataAction.NON_EXISTENT_PARTNER:
        draft.contentTabProgress.partner = false;
        draft.contentTabState.partner = true;
        break;
      case PartnerDataAction.RESULT:
        draft.contentTabProgress.partner = false;
        draft.contentTabState.partner = false;
        draft.showSuccess = true;
        break;

      case DemandOverviewAction.CLOSE_TAB:
        const demandOverviewAction = (action as unknown) as DemandOverviewActionCloseTab;
        const type = (demandOverviewAction.payload as unknown) as keyof typeof initialContentTabState;
        draft.contentTabState[type] = false;
        break;

      case DemandOverviewAction.LOAD_TAB_MOBILE:
        const demandLoadTabMobileAction = (action as unknown) as DemandOverviewActionLoadTabMobile;
        const form = demandLoadTabMobileAction.payload as ContentTabKey;
        draft.activeForm = form;
        break;
      case DocumentsAction.GET_ROLES:
        draft.contentTabProgress.documents = true;
        draft.contentTabState.documents = false;
        break;
      case DocumentsAction.GET_ROLES_RESULT:
        draft.contentTabProgress.documents = false;
        draft.contentTabState.documents = true;
        break;
      case DemandOverviewAction.COMPLETE_DEMAND:
        draft.inProgress = true;
        break;
      case DemandOverviewAction.COMPLETE_DEMAND_ERROR:
        const demandCompletionError = action as CompleteDemandErrorAction;
        draft.demandCompletionError = demandCompletionError.payload.error;
        draft.inProgress = false;
        break;
      case DemandOverviewAction.COMPLETE_DEMAND_SUCCESS:
        draft.completed = true;
        draft.inProgress = false;
        break;
      case DemandOverviewAction.CLEAR_DEMAND_ERROR:
        draft.demandCompletionError = undefined;
        break;
      case DemandOverviewAction.PROGRESS_RESULT:
        const progressResult = action as DemandOverviewActionProgressResult;
        draft.demandProgress = progressResult.payload;
        break;
      case LastDemandDataTakeoverAction.RESULT:
        draft.initialDataLoaded = true;
        break;
      case LastDemandDataTakeoverAction.ERROR:
        draft.initialDataLoaded = true;
        break;
      case NotesDataAction.GET:
        draft.contentTabProgress.notes = true;
        draft.contentTabState.notes = false;
        break;
      case NotesDataAction.GET_RESULT:
        draft.contentTabProgress.notes = false;
        draft.contentTabState.notes = true;
        break;
      case NotesDataAction.SUBMIT_RESULT:
        draft.contentTabProgress.notes = false;
        draft.contentTabState.notes = false;
        break;
    }
  });
}
