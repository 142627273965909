import { PostalCode } from '../contact-data/types';
import { PersonalData } from './types';

/**
 * PersonalData and ContactData are united since they are combined into the same endpoint
 */

export enum PersonalDataAction {
  CHANGE = 'PERSONAL_DATA_CHANGE',
  SUBMIT = 'PERSONAL_DATA_SUBMIT',
  RESULT = 'PERSONAL_DATA_RESULT',
  GET = 'PERSONAL_DATA_GET',
  GET_RESULT = 'PERSONAL_DATA_GET_RESULT',
}

export enum ContactDataAction {
  CHANGE = 'CONTACT_DATA_CHANGE',
  SUBMIT = 'CONTACT_DATA_SUBMIT',
  RESULT = 'CONTACT_DATA_RESULT',
  GET = 'CONTACT_DATA_GET',
  GET_RESULT = 'CONTACT_DATA_GET_RESULT',
  GET_POSTAL_CODES = 'CONTACT_DATA_GET_POSTAL_CODES',
  GET_POSTAL_CODES_RESULT = 'CONTACT_DATA_GET_POSTAL_CODES_RESULT',
  GET_POSTAL_CODES_ERROR = 'CONTACT_DATA_GET_POSTAL_CODES_ERROR',
}

export interface PersonalDataRetrieveAction {
  type: typeof PersonalDataAction.GET;
  demandId: number;
  pifId: number;
}

export interface PersonalDataChangeAction {
  type: typeof PersonalDataAction.CHANGE;
  payload: PersonalData;
}

export interface PersonalDataSubmitAction {
  type: typeof PersonalDataAction.SUBMIT;
  payload: PersonalData;
  demandId: number;
}

export interface PersonalDataResultAction {
  type: typeof PersonalDataAction.RESULT;
  payload: PersonalData;
}

export interface PersonalDataGetAction {
  type: typeof PersonalDataAction.RESULT;
  payload: PersonalData;
}

export interface PersonalDataGetResultAction {
  type: typeof PersonalDataAction.GET_RESULT;
  payload: PersonalData;
}

export type PersonalDataActionType =
  | PersonalDataRetrieveAction
  | PersonalDataChangeAction
  | PersonalDataSubmitAction
  | PersonalDataResultAction
  | PersonalDataGetAction
  | PersonalDataGetResultAction
  | ContactDataChangeAction
  | ContactDataSubmitAction
  | ContactDataResultAction
  | ContactDataGetAction
  | ContactDataGetResultAction
  | ContactDataGetPostalCodesAction
  | ContactDataGetPostalCodesResultAction;

export interface ContactDataChangeAction {
  type: typeof ContactDataAction.CHANGE;
  payload: PersonalData;
}

export interface ContactDataSubmitAction {
  type: typeof ContactDataAction.SUBMIT;
  payload: PersonalData;
  demandId: number;
}

export interface ContactDataResultAction {
  type: typeof ContactDataAction.RESULT;
  payload: PersonalData;
}

export interface ContactDataGetAction {
  type: typeof ContactDataAction.GET;
  demandId: number;
}

export interface ContactDataGetResultAction {
  type: typeof ContactDataAction.GET_RESULT;
  payload: PersonalData;
}

export interface ContactDataGetPostalCodesAction {
  type: typeof ContactDataAction.GET_POSTAL_CODES;
  payload: PersonalData;
}

export interface ContactDataGetPostalCodesResultAction {
  type: typeof ContactDataAction.GET_POSTAL_CODES_RESULT;
  payload: PostalCode;
}

export interface ContactDataGetPostalCodesErrorAction {
  type: typeof ContactDataAction.GET_POSTAL_CODES_ERROR;
  payload: string;
}

export type ContactDataActionType =
  | ContactDataChangeAction
  | ContactDataSubmitAction
  | ContactDataResultAction
  | ContactDataGetAction
  | ContactDataGetResultAction
  | ContactDataGetPostalCodesAction
  | ContactDataGetPostalCodesResultAction
  | ContactDataGetPostalCodesErrorAction;
