import { Observable, of } from 'rxjs';
import { Epic, ofType } from 'redux-observable';
import {
  FatherDataGetResultAction,
  FatherDataSubmitAction,
  FatherDataResultAction,
  FatherDataAction,
  FatherDataGetPostalCodesAction,
  FatherDataGetPostalCodesResultAction,
  FatherDataGetPostalCodesErrorAction,
  FatherDataGetAction,
} from './actions';

import { Action } from 'redux';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { AjaxResponse } from 'rxjs/ajax';
import { PostalCode } from '../contact-data/types';
import { handleRequestFailureInEpic } from '../../utils/error-handling';
import { customAjax } from '../../utils/ajax-wrapper';

const loadFatherData = (payload: AjaxResponse): FatherDataGetResultAction => {
  return {
    type: FatherDataAction.GET_RESULT,
    payload: { ...payload.response },
  };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const loadFatherDataOnError = (error: any): any => {
  if (error.status == 404) {
    return {
      type: FatherDataAction.NON_EXISTENT_FATHER,
    };
  }

  return handleRequestFailureInEpic(error);
};

const getSubmitResult = (payload: AjaxResponse): FatherDataResultAction => {
  return {
    type: FatherDataAction.RESULT,
    payload: { ...payload.response },
  };
};

const setPostalCodeData = (payload: AjaxResponse): FatherDataGetPostalCodesResultAction => {
  return {
    type: FatherDataAction.GET_POSTAL_CODES_RESULT,
    payload: payload.response[0] as PostalCode,
  };
};

export const getFatherDataEpic: Epic<Action, Action> = (action$: Observable<Action>): Observable<Action> =>
  action$.pipe(
    ofType(FatherDataAction.GET),
    mergeMap((action) => {
      const pifId = (action as FatherDataGetAction).pifId;
      return customAjax({
        subRoute: `/dossier/v1/persons-in-formation/${pifId}/father`,
        withCredentials: true,
        method: 'GET',
      }).pipe(
        map((response) => loadFatherData(response)),
        catchError((error) => {
          return of(loadFatherDataOnError(error));
        }),
      );
    }),
  );

export const editFatherDataEpic: Epic<Action, Action> = (action$: Observable<Action>): Observable<Action> =>
  action$.pipe(
    ofType(FatherDataAction.SUBMIT),
    mergeMap((action) => {
      const fatherData = (action as FatherDataSubmitAction).payload;
      const pifId = (action as FatherDataSubmitAction).pifId;

      return customAjax({
        subRoute: `/dossier/v1/persons-in-formation/${pifId}/father`,
        withCredentials: true,
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(fatherData),
      }).pipe(
        map((response) => getSubmitResult(response)),
        catchError((error) => {
          return of(handleRequestFailureInEpic(error));
        }),
      );
    }),
  );

export const getFatherPostalCodeDataEpic: Epic<Action, Action> = (action$: Observable<Action>): Observable<Action> =>
  action$.pipe(
    ofType(FatherDataAction.GET_POSTAL_CODES),
    mergeMap((action) => {
      const payoutData = (action as FatherDataGetPostalCodesAction).payload;

      return customAjax({
        subRoute: `/master-data/v1/${payoutData.zipCode}/locality`,
        withCredentials: false,
        method: 'GET',
        headers: {
          'Accept-Language': 'de-de',
        },
      }).pipe(
        map((response) => setPostalCodeData(response)),
        catchError(() => {
          const errorAction: FatherDataGetPostalCodesErrorAction = {
            type: FatherDataAction.GET_POSTAL_CODES_ERROR,
            payload: 'No city found for ' + payoutData.zipCode,
          };
          return of(errorAction);
        }),
      );
    }),
  );
