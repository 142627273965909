import { ChildData } from './types';

export enum ChildrenDataAction {
  GET = 'CHILDREN_DATA_GET',
  GET_RESULT = 'CHILDREN_DATA_GET_RESULT',
  EDIT_ITEM = 'CHILD_DATA_EDIT',
  EDIT_RESULT = 'CHILD_DATA_EDIT_RESULT',
  ADD_ITEM = 'CHILD_ADD',
  ADD_RESULT = 'CHILD_ADD_RESULT',
  DELETE_ITEM = 'CHILD_DELETE',
  DELETE_RESULT = 'CHILD_DELETE_RESULT',
  EMPTY_CHILDREN_DATA = 'CHILD_EMPTY',
}

export interface ChildrenDataGetAction {
  type: typeof ChildrenDataAction.GET;
  pifId: number;
}

export interface ChildrenDataGetResultAction {
  type: typeof ChildrenDataAction.GET_RESULT;
  payload: ChildData[];
}

export interface ChildrenDataEditChildAction {
  type: typeof ChildrenDataAction.EDIT_ITEM;
  payload: ChildData;
  pifId: number;
}

export interface ChildrenDataEditResultAction {
  type: typeof ChildrenDataAction.EDIT_RESULT;
  payload: ChildData;
}

export interface ChildrenDataAddChildAction {
  type: typeof ChildrenDataAction.ADD_ITEM;
  payload: ChildData;
  pifId: number;
}

export interface ChildrenDataAddResultAction {
  type: typeof ChildrenDataAction.ADD_RESULT;
  payload: ChildData;
}

export interface ChildrenDataDeleteChildAction {
  type: typeof ChildrenDataAction.DELETE_ITEM;
  payload: number;
  pifId: number;
}

export interface ChildrenDataDeleteResultAction {
  type: typeof ChildrenDataAction.DELETE_RESULT;
  payload: number;
}

export type ChildrenDataActionType =
  | ChildrenDataGetAction
  | ChildrenDataGetResultAction
  | ChildrenDataEditChildAction
  | ChildrenDataEditResultAction
  | ChildrenDataAddChildAction
  | ChildrenDataAddResultAction
  | ChildrenDataDeleteChildAction
  | ChildrenDataDeleteResultAction;
