import React, { ReactElement } from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import { black } from './../../theme';

export function ZoomReceiptIcon(props: SvgIconProps): ReactElement {
  const color = props.htmlColor ? props.htmlColor : black.main;
  return (
    <SvgIcon {...props}>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <path d="M0 0H80V80H0z" transform="translate(-948 -257) translate(948 257)" />
            <path
              fill={color}
              fillRule="nonzero"
              d="M36.4 36.18L54.12 36.18 54.38 33.51 36.73 33.51zM36.94 31.64L54.65 31.64 54.91 28.98 37.27 28.98zM37.44 27.09L55.15 27.09 55.42 24.43 37.77 24.43zM41.66 40.49L48.96 40.49 49.34 37.82 42.11 37.82zM35.82 40.49L40.05 40.49 40.45 37.82 36.15 37.82z"
              transform="translate(-948 -257) translate(948 257)"
            />
            <path
              fill={color}
              fillRule="nonzero"
              d="M31.18 16.56l-3.13 32.92H17.66v9.14c0 3.64 2.27 5.9 5.91 5.9h31.34c2.454-.15 4.522-1.888 5.09-4.28l3.38-43.68h-32.2zM19.66 58.62v-7.14h29v8.88c.17.788.512 1.529 1 2.17H23.57c-2.52-.01-3.91-1.39-3.91-3.91zM58 59.83c-.437 1.697-2.033 2.833-3.78 2.69-1.635.146-3.13-.925-3.52-2.52V49.47H30.05L33 18.56h28.2L58 59.83z"
              transform="translate(-948 -257) translate(948 257)"
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}
