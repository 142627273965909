import { Action } from 'redux';
import { Epic, ofType } from 'redux-observable';
import { createPatch } from 'rfc6902';
import { Observable, of } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { customAjax } from '../../utils/ajax-wrapper';
import { handleRequestFailureInEpic, redirectToDataUnavailableOr } from '../../utils/error-handling';
import { PostalCode } from '../contact-data/types';
import {
  EditAddressDataPageAction,
  EditAddressDataPageGetAction,
  EditAddressDataPageGetPostalCodesAction,
  EditAddressDataPageGetPostalCodesErrorAction,
  EditAddressDataPageGetPostalCodesResultAction,
  EditAddressDataPageGetResultAction,
  EditAddressDataPageSubmitAction,
} from './actions';
import { RoutingAction, RoutingActionType } from '../routing/actions';

const addEditAddressDataPage = (payload: AjaxResponse): EditAddressDataPageGetResultAction => {
  return {
    type: EditAddressDataPageAction.GET_RESULT,
    payload: payload.response.addressData,
  };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getEditAddressDataPageOnError = (error: any, dossierId: number): any => {
  if (error.status === 404) {
    return redirectToDataUnavailableOr(
      error,
      { type: EditAddressDataPageAction.NON_EXISTENT_EDIT_ADDRESS_DATA_PAGE },
      dossierId,
    );
  }
  return handleRequestFailureInEpic(error);
};

const getSubmitResult = (payload: AjaxResponse, dossierId: number): RoutingActionType => {
  return {
    type: RoutingAction.REDIRECT_TO,
    payload: `/my-data/dossier-intranet/mydata/${dossierId}`,
  };
};

const setPostalCodeData = (payload: AjaxResponse): EditAddressDataPageGetPostalCodesResultAction => {
  return {
    type: EditAddressDataPageAction.GET_POSTAL_CODES_RESULT,
    payload: payload.response[0] as PostalCode,
  };
};

export const getEditAddressDataPageEpic: Epic<Action, Action> = (action$: Observable<Action>): Observable<Action> =>
  action$.pipe(
    ofType(EditAddressDataPageAction.GET),
    mergeMap((action) => {
      const dossierId = (action as EditAddressDataPageGetAction).dossierId;
      return customAjax({
        subRoute: `/dossier-intranet/v1/mydata/${dossierId}`,
        withCredentials: true,
        method: 'GET',
      }).pipe(
        map((response) => addEditAddressDataPage(response)),
        catchError((error) => {
          return of(getEditAddressDataPageOnError(error, dossierId));
        }),
      );
    }),
  );

export const sendEditAddressDataPageEpic: Epic<Action, Action> = (action$: Observable<Action>): Observable<Action> =>
  action$.pipe(
    ofType(EditAddressDataPageAction.SUBMIT),
    mergeMap((action) => {
      const outgoingDataAction: EditAddressDataPageSubmitAction = action as EditAddressDataPageSubmitAction;
      const dossierId = outgoingDataAction.dossierId;

      return customAjax({
        subRoute: `/dossier-intranet/v1/mydata/${dossierId}`,
        withCredentials: true,
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json-patch+json',
        },
        body: JSON.stringify(
          createPatch(
            {
              addressData: {
                street: '',
                houseNumber: '',
                zipCode: 0,
                town: '',
                countryIdType: 0,
                validFrom: new Date().toISOString(),
              },
            },
            {
              addressData: {
                street: outgoingDataAction.payload.street,
                houseNumber: outgoingDataAction.payload.houseNumber,
                zipCode: outgoingDataAction.payload.zipCode,
                town: outgoingDataAction.payload.town,
                countryIdType: outgoingDataAction.payload.countryIdType,
                validFrom: outgoingDataAction.payload.validFrom
                  ? outgoingDataAction.payload.validFrom.toISOString()
                  : undefined,
              },
            },
          ),
        ),
      }).pipe(
        map(
          (response) => getSubmitResult(response, dossierId),
          catchError((error) => {
            return of(handleRequestFailureInEpic(error));
          }),
        ),
      );
    }),
    catchError((error) => {
      return of(handleRequestFailureInEpic(error));
    }),
  );

export const getAddressPostalCodeDataEpic: Epic<Action, Action> = (action$: Observable<Action>): Observable<Action> =>
  action$.pipe(
    ofType(EditAddressDataPageAction.GET_POSTAL_CODES),
    mergeMap((action) => {
      const payoutData = (action as EditAddressDataPageGetPostalCodesAction).payload;

      return customAjax({
        subRoute: `/master-data/v1/${payoutData.zipCode}/locality`,
        withCredentials: false,
        method: 'GET',
        headers: {
          'Accept-Language': 'de-de',
        },
      }).pipe(
        map((response) => setPostalCodeData(response)),
        catchError(() => {
          const errorAction: EditAddressDataPageGetPostalCodesErrorAction = {
            type: EditAddressDataPageAction.GET_POSTAL_CODES_ERROR,
            payload: 'No city found for ' + payoutData.zipCode,
          };
          return of(errorAction);
        }),
      );
    }),
  );
