import { EditPersonalDataPage } from './types';

export enum EditPersonalDataPageAction {
  GET = 'EDIT_PERSONAL_DATA_PAGE_DATA_GET',
  GET_RESULT = 'EDIT_PERSONAL_DATA_PAGE_DATA_GET_RESULT',
  RESULT = 'EDIT_PERSONAL_DATA_PAGE_DATA_RESULT',
  NON_EXISTENT_EDIT_PERSONAL_DATA_PAGE = 'NON_EXISTENT_EDIT_PERSONAL_DATA_PAGE',
  CHANGE = 'EDIT_PERSONAL_DATA_PAGE_DATA_CHANGE',
  SUBMIT = 'EDIT_PERSONAL_DATA_PAGE_DATA_SUBMIT',
}

export interface EditPersonalDataPageGetAction {
  type: typeof EditPersonalDataPageAction.GET;
  dossierId: number;
}

export interface EditPersonalDataPageGetResultAction {
  type: typeof EditPersonalDataPageAction.GET_RESULT;
  payload: EditPersonalDataPage;
}

export interface EditPersonalDataPageResultAction {
  type: typeof EditPersonalDataPageAction.RESULT;
  payload: EditPersonalDataPage;
}

export interface EditPersonalDataPageChangeAction {
  type: typeof EditPersonalDataPageAction.CHANGE;
  payload: string;
  changeField: string;
}

export interface EditPersonalDataPageSubmitAction {
  type: typeof EditPersonalDataPageAction.SUBMIT;
  payload: EditPersonalDataPage;
  dossierId: number;
}

export type EditPersonalDataPageActionType =
  | EditPersonalDataPageGetAction
  | EditPersonalDataPageGetResultAction
  | EditPersonalDataPageResultAction
  | EditPersonalDataPageChangeAction
  | EditPersonalDataPageSubmitAction;
