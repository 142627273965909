import { CaseCreateDto } from './../notifications/types';

export enum RepaymentConditionsDataAction {
  REPAYMENT_CONDITIONS_CHANGE = 'REPAYMENT_CONDITIONS_CHANGE',
}

export interface RepaymentConditionsChangeAction {
  type: typeof RepaymentConditionsDataAction.REPAYMENT_CONDITIONS_CHANGE;
  payload: CaseCreateDto;
  dossierId: number;
}

export type RepaymentConditionsDataActionType = RepaymentConditionsChangeAction;
