import 'date-fns';
import { Grid } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import frLocale from 'date-fns/locale/fr';
import deLocale from 'date-fns/locale/de';
import enLocale from 'date-fns/locale/en-US';
import itLocale from 'date-fns/locale/it';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import React, { ReactElement, useState, useEffect } from 'react';
import './ZoomDatePickerStartEndDate.scss';
import { ZoomTextInputHelpTooltip } from './../../tooltips/ZoomTextInputHelpTooltip';
import { ZoomInfoIcon } from './../../icons/ZoomInfoIcon';

export interface ZoomDatePickerStartEndDateProps {
  label: string;
  valueStart?: Date | null;
  valueEnd?: Date | null;
  name?: string;
  disabled?: boolean;
  error?: boolean;
  errorMessage?: string;
  invalidDateMessage?: string;
  locale?: 'de' | 'en' | 'fr' | 'it';
  helpText?: string;
  onChangeStart?: (newDate: Date | null) => void;
  onChangeEnd?: (newDate: Date | null) => void;
}

const localeMap = {
  en: enLocale,
  de: deLocale,
  fr: frLocale,
  it: itLocale,
};

const ZoomDatePickerStartEndDate = (props: ZoomDatePickerStartEndDateProps): ReactElement => {
  const [valueStart, setValueStart] = useState(props.valueStart ? props.valueStart : null);
  const [valueEnd, setValueEnd] = useState(props.valueEnd ? props.valueEnd : null);
  const [locale] = useState(props.locale ? props.locale : 'de');
  const [error, setError] = useState(props.error ? true : false);
  const [errorMessage, setErrorMessage] = useState(props.errorMessage ? props.errorMessage : '');

  useEffect(() => {
    setValueStart(props.valueStart ? props.valueStart : null);
    setValueEnd(props.valueEnd ? props.valueEnd : null);
    setError(props.error ? true : false);
    setErrorMessage(props.errorMessage ? props.errorMessage : '');
  }, [props.valueStart, props.valueEnd, props.error, props.errorMessage]);

  const onChangeStart = (date: Date | null): void => {
    setValueStart(date);
    if (props.onChangeStart) {
      props.onChangeStart(date);
    }
  };

  const onChangeEnd = (date: Date | null): void => {
    setValueEnd(date);
    if (props.onChangeEnd) {
      props.onChangeEnd(date);
    }
  };

  return (
    <Grid container direction="row" alignItems="stretch" justify="flex-start">
      <Grid item xs={12} sm={6}>
        <div className="label">
          <label>{props.label}</label>
          {props.helpText && (
            <ZoomTextInputHelpTooltip placement="bottom" arrow={true} title={<p>{props.helpText}</p>}>
              <span style={{ width: '24px', height: '24px' }}>
                <ZoomInfoIcon color="primary" viewBox="0 0 80 80"></ZoomInfoIcon>
              </span>
            </ZoomTextInputHelpTooltip>
          )}
        </div>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Grid container direction="row" alignItems="stretch" justify="center" spacing={1}>
          <Grid item xs={6}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap[locale]}>
              <KeyboardDatePicker
                className="zoom-datepicker-start"
                fullWidth
                variant="inline"
                inputVariant="outlined"
                views={['year', 'month']}
                format="MM.yyyy"
                autoOk={true}
                margin="none"
                value={valueStart}
                name="startDate"
                error={error}
                disabled={props.disabled}
                helperText={errorMessage}
                size="small"
                onChange={onChangeStart}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={6}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap[locale]}>
              <KeyboardDatePicker
                className="zoom-datepicker-end"
                fullWidth
                variant="inline"
                inputVariant="outlined"
                views={['year', 'month']}
                format="MM.yyyy"
                autoOk={true}
                margin="none"
                minDate={valueStart}
                value={valueEnd}
                name="endDate"
                error={error}
                disabled={props.disabled}
                size="small"
                invalidDateMessage=""
                onChange={onChangeEnd}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ZoomDatePickerStartEndDate;
