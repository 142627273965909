import { Observable, of } from 'rxjs';
import { Epic, ofType } from 'redux-observable';
import {
  ParentsDataGetResultAction,
  ParentsDataSubmitAction,
  ParentsDataResultAction,
  ParentsDataAction,
  ParentsDataGetAction,
} from './actions';

import { Action } from 'redux';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { AjaxResponse } from 'rxjs/ajax';
import { handleRequestFailureInEpic } from '../../utils/error-handling';
import { customAjax } from '../../utils/ajax-wrapper';

const loadParents = (payload: AjaxResponse): ParentsDataGetResultAction => {
  return {
    type: ParentsDataAction.GET_RESULT,
    payload: { ...payload.response },
  };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const loadParentsOnError = (error: any): any => {
  if (error.status == 404) {
    return {
      type: ParentsDataAction.NON_EXISTENT_PARENTS,
    };
  }

  return handleRequestFailureInEpic(error);
};

const getSubmitResult = (payload: AjaxResponse): ParentsDataResultAction => {
  return {
    type: ParentsDataAction.RESULT,
    payload: { ...payload.response },
  };
};

export const getParentsDataEpic: Epic<Action, Action> = (action$: Observable<Action>): Observable<Action> =>
  action$.pipe(
    ofType(ParentsDataAction.GET),
    mergeMap((action) => {
      const pifId = (action as ParentsDataGetAction).pifId;

      return customAjax({
        subRoute: `/dossier/v1/persons-in-formation/${pifId}/familyrelation`,
        withCredentials: true,
        method: 'GET',
      }).pipe(
        map((response) => loadParents(response)),
        catchError((error) => {
          return of(loadParentsOnError(error));
        }),
      );
    }),
  );

export const editParentsDataEpic: Epic<Action, Action> = (action$: Observable<Action>): Observable<Action> =>
  action$.pipe(
    ofType(ParentsDataAction.SUBMIT),
    mergeMap((action) => {
      const parentsData = (action as ParentsDataSubmitAction).payload;
      const pifId = (action as ParentsDataSubmitAction).pifId;

      return customAjax({
        subRoute: `/dossier/v1/persons-in-formation/${pifId}/familyrelation`,
        withCredentials: true,
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(parentsData),
      }).pipe(
        map((response) => getSubmitResult(response)),
        catchError((error) => {
          return of(handleRequestFailureInEpic(error));
        }),
      );
    }),
  );
