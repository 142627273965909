import produce from 'immer';
import { DemoAction, DemoActionType } from './actions';
import { DemoStore } from './types';

// This is just a DEMO part of the application. It will be used
// just to give an example of the structure of this application

// Define an initial state value for the demo reducer
export const initialState: DemoStore = {
  title: 'This is just a state demonstration!',
  optionalTitle: 'Optional parameters can also be omitted',
  description: 'When creating your own components with reducers and actions, take a look at the demo structure',
};

export function demoReducer(state = initialState, action: DemoActionType): DemoStore {
  // immer is used here for convenience
  // for details, checkout https://github.com/immerjs/immer
  return produce(state, (draft) => {
    switch (action.type) {
      case DemoAction.SET_TITLE:
        draft.title = action.payload;
        break;
      case DemoAction.SET_SUBTITLE:
        draft.optionalTitle = action.payload;
        break;
      case DemoAction.SET_DESCRIPTION:
        draft.description = action.payload;
        break;
    }
    return draft;
  });
}
