import { Action } from 'redux';

// First define the possible action types with an enum
export enum DemoAction {
  SET_TITLE = 'SET_TITLE',
  SET_SUBTITLE = 'SET_SUBTITLE',
  SET_DESCRIPTION = 'SET_DESCRIPTION',
  SET_TITLE_TIMEOUT = 'SET_TITLE_TIMEOUT',
  SET_DESCRIPTION_TIMEOUT = 'SET_DESCRIPTION_TIMEOUT',
}

// CONVENTION: Name all the action interfaces with ‘Action’ as a suffix.
export interface DemoSetTitleAction extends Action {
  type: typeof DemoAction.SET_TITLE;
  payload: string;
}

export interface DemoSetTitleWithTimeoutAction extends Action {
  type: typeof DemoAction.SET_TITLE_TIMEOUT;
  payload: string;
}

export interface DemoSetSubtitleAction extends Action {
  type: typeof DemoAction.SET_SUBTITLE;
  payload: string;
}

export interface DemoSetDescriptionAction extends Action {
  type: typeof DemoAction.SET_DESCRIPTION;
  payload: string;
}

export interface DemoSetDescriptionWithTimeoutAction extends Action {
  type: typeof DemoAction.SET_DESCRIPTION_TIMEOUT;
  payload: string;
}

// create a type with all actions in order to be able to use the type in the reducer
export type DemoActionType =
  | DemoSetTitleAction
  | DemoSetSubtitleAction
  | DemoSetDescriptionAction
  | DemoSetTitleWithTimeoutAction;
