import React, { ReactElement } from 'react';
import { DocumentsActionType } from '../../store/documents/actions';
import { ZoomFile, ZoomFileInProgress, ZoomFileUpload } from '../../components/forms/file-upload/ZoomFileUpload';
import './UploadDocumentGroupForm.scss';
import { OptionTranslation } from '../../store/i18n/types';

export interface ZoomDocumentType {
  typeId: number;
  name: string;
  documents: Array<ZoomFile>;
  inProgress: Array<ZoomFileInProgress>;
  uploadSuccess?: boolean;
  uploadError?: string;
  caseId?: number; // used only for files in cases (notifications and messages)
  isNewConversation?: boolean; // used only for new conversations
}

export interface ZoomDocumentsRole {
  role: number;
  roleName: string;
  documentTypes: Array<ZoomDocumentType>;
}

export interface UploadDocumentGroupFormProps {
  role: ZoomDocumentsRole;
  labelChooseDocument: string;
  labelDragDocument: string;
  labelOr: string;
  labelSuccess: string;
  labelsDocumentTypeName: OptionTranslation[];
  labelsRoles: OptionTranslation[];
  dispatch?: (action: DocumentsActionType) => void;
  demandId: number;
}

export const UploadDocumentGroupForm = (props: UploadDocumentGroupFormProps): ReactElement => {
  const getDocumentTypeHtml = (documentType: ZoomDocumentType) => {
    const name =
      props.labelsDocumentTypeName.find((documentTypeName) => documentTypeName.id === documentType.typeId)?.name ??
      'DocumentType' + documentType.typeId;

    return (
      <div key={documentType.typeId.toString()}>
        <h3 className="file-upload-group-document-type">{name}</h3>
        <ZoomFileUpload
          key={'zoom-file-upload' + documentType.typeId.toString()}
          typeId={documentType.typeId}
          labelChooseDocument={props.labelChooseDocument}
          labelDragDocument={props.labelDragDocument}
          labelOr={props.labelOr}
          labelSuccess={props.labelSuccess}
          files={documentType.documents}
          filesInProgress={documentType.inProgress}
          dispatch={props.dispatch}
          uploadError={documentType.uploadError}
          uploadSuccessful={documentType.uploadSuccess}
          demandId={props.demandId}
        />
      </div>
    );
  };

  const documentTypesToRender = props.role.documentTypes.map((doc) => getDocumentTypeHtml(doc));
  const roleName = props.labelsRoles.find((role) => role.id === props.role.role)?.name ?? 'Rolle ' + props.role.role;
  return (
    <div>
      <h1 className="file-upload-group-role-name">{roleName}</h1>
      <div>{documentTypesToRender}</div>
    </div>
  );
};
