import { Observable, of } from 'rxjs';
import { Epic, ofType } from 'redux-observable';
import {
  MotherDataGetResultAction,
  MotherDataSubmitAction,
  MotherDataResultAction,
  MotherDataAction,
  MotherDataGetPostalCodesAction,
  MotherDataGetPostalCodesResultAction,
  MotherDataGetPostalCodesErrorAction,
  MotherDataGetAction,
} from './actions';

import { Action } from 'redux';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { AjaxResponse } from 'rxjs/ajax';
import { PostalCode } from '../contact-data/types';
import { handleRequestFailureInEpic } from '../../utils/error-handling';
import { customAjax } from '../../utils/ajax-wrapper';

const loadMotherData = (payload: AjaxResponse): MotherDataGetResultAction => {
  return {
    type: MotherDataAction.GET_RESULT,
    payload: { ...payload.response },
  };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const loadMotherDataOnError = (error: any): any => {
  if (error.status == 404) {
    return {
      type: MotherDataAction.NON_EXISTENT_MOTHER,
    };
  }

  return handleRequestFailureInEpic(error);
};

const getSubmitResult = (payload: AjaxResponse): MotherDataResultAction => {
  return {
    type: MotherDataAction.RESULT,
    payload: { ...payload.response },
  };
};

const setPostalCodeData = (payload: AjaxResponse): MotherDataGetPostalCodesResultAction => {
  return {
    type: MotherDataAction.GET_POSTAL_CODES_RESULT,
    payload: payload.response[0] as PostalCode,
  };
};

export const getMotherDataEpic: Epic<Action, Action> = (action$: Observable<Action>): Observable<Action> =>
  action$.pipe(
    ofType(MotherDataAction.GET),
    mergeMap((action) => {
      const pifId = (action as MotherDataGetAction).pifId;

      return customAjax({
        subRoute: `/dossier/v1/persons-in-formation/${pifId}/mother`,
        withCredentials: true,
        method: 'GET',
      }).pipe(
        map((response) => loadMotherData(response)),
        catchError((error) => {
          return of(loadMotherDataOnError(error));
        }),
      );
    }),
  );

export const editMotherDataEpic: Epic<Action, Action> = (action$: Observable<Action>): Observable<Action> =>
  action$.pipe(
    ofType(MotherDataAction.SUBMIT),
    mergeMap((action) => {
      const motherData = (action as MotherDataSubmitAction).payload;
      const pifId = (action as MotherDataSubmitAction).pifId;

      return customAjax({
        subRoute: `/dossier/v1/persons-in-formation/${pifId}/mother`,
        withCredentials: true,
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(motherData),
      }).pipe(
        map((response) => getSubmitResult(response)),
        catchError((error) => {
          return of(handleRequestFailureInEpic(error));
        }),
      );
    }),
  );

export const getMotherPostalCodeDataEpic: Epic<Action, Action> = (action$: Observable<Action>): Observable<Action> =>
  action$.pipe(
    ofType(MotherDataAction.GET_POSTAL_CODES),
    mergeMap((action) => {
      const payoutData = (action as MotherDataGetPostalCodesAction).payload;

      return customAjax({
        subRoute: `/master-data/v1/${payoutData.zipCode}/locality`,
        withCredentials: false,
        method: 'GET',
        headers: {
          'Accept-Language': 'de-de',
        },
      }).pipe(
        map((response) => setPostalCodeData(response)),
        catchError(() => {
          const errorAction: MotherDataGetPostalCodesErrorAction = {
            type: MotherDataAction.GET_POSTAL_CODES_ERROR,
            payload: 'No city found for ' + payoutData.zipCode,
          };
          return of(errorAction);
        }),
      );
    }),
  );
