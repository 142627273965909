import moment from 'moment';
import React, { ReactElement, useState } from 'react';
import { black, blue, lightGrey, green } from '../../theme';
import ZoomPieChart, { ZoomPieChartProps } from '../base-page-components/pie-chart/ZoomPieChart';
import { ZoomArrowDownIcon } from '../icons/ZoomArrowDownIcon';
import { ZoomArrowUpIcon } from '../icons/ZoomArrowUpIcon';
import './DemandInformation.scss';
import { Grid, Button, ListItemText, Menu, MenuItem, MenuProps, withStyles } from '@material-ui/core';
import { ZoomPdfIcon } from '../icons/ZoomPdfIcon';
import { trailingNumbersFormat } from '../../utils/formaters';

export interface Instalment {
  instalmentType: string;
  amount: string;
  date?: Date;
  finishedDate?: Date;
  status: number;
  beneficiary: string;
}

export interface ZoomDemandFile {
  typeId?: number;
  type: string;
  streamId?: string;
  fileName: string;
}

export interface DemandInformationProps {
  demandId?: number;
  dossierId?: number;
  files?: Array<ZoomDemandFile>;
  submitDate: Date;
  decisionDate?: Date;
  decision?: number;
  scholarshipTotal: number;
  scholarshipPaidOut: number;
  scholarshipStatus: string;
  debtTotal: number;
  debtPaidOut: number;
  debtStatus: string;
  isOpened: boolean;
  institution: string;
  instalments: Array<Instalment>;
  titleYear: string;
  labelTitle?: string;
  labelScholarship?: string;
  labelDebt?: string;
  labelSubmittedOn?: string;
  labelDecision?: string;
  labelDecisionResult?: string;
  labelInstalments?: string;
  labelInstalmentType?: string;
  labelInstalmentAmount?: string;
  labelInstalmentDate?: string;
  labelInstalmentFinishedDate?: string;
  labelInstalmentStatus?: string;
  labelInstalmentBeneficiary?: string;
  labelDocumentDownload?: string;
  labelDesicionForEducation?: string;
  labelPaymentPaper?: string;
  instalmentStates?: { name: string; value: string }[];
  demandDecisionStates?: { name: string; value: string }[];
  creditTypes?: { name: string; value: string }[];
}

const StyledMenu = withStyles({
  paper: {
    border: '1px solid ' + blue.main,
  },
})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    color: blue.main,
    width: '320px',
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
    '& .MuiListItemText-root': {
      marginLeft: '7px',
    },
  },
}))(MenuItem);

export const DemandInformation = (props: DemandInformationProps): ReactElement => {
  const [isOpened, setIsOpened] = useState(props.isOpened);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const files = props.files ? props.files : [];

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const toggleState = () => {
    setIsOpened(!isOpened);
  };

  const stateClass = isOpened ? 'opened' : 'closed';
  const containerClasses = `demand-information ${stateClass}`;

  const scholarshipPieChartProps: ZoomPieChartProps = {
    filledAreaPercentage: (props.scholarshipPaidOut / props.scholarshipTotal) * 100,
    filledAreaColor: blue.main,
    restAreaColor: lightGrey.main,
    size: 200,
    strokeWidth: 5,
    amountToDisplay: props.scholarshipTotal.toString(),
  };

  const debtPieChartProps: ZoomPieChartProps = {
    filledAreaPercentage: (props.debtPaidOut / props.debtTotal) * 100,
    filledAreaColor: green.main,
    restAreaColor: lightGrey.main,
    size: 200,
    strokeWidth: 5,
    amountToDisplay: props.debtTotal.toString(),
  };

  const instalmentRows = props.instalments.map((instalment, index) => {
    const instalmentType = props.creditTypes
      ? props.creditTypes.find((type) => type.value == instalment.instalmentType)
      : '';

    const instalmentState = props.instalmentStates
      ? props.instalmentStates.find((option) => option.value === instalment.status.toString())?.name
      : '';

    const date = instalment.date ? moment(instalment.date).format('DD.MM.YYYY') : '';
    const finishedDate = instalment.finishedDate ? moment(instalment.finishedDate).format('DD.MM.YYYY') : '';
    return (
      <Grid item xs={12} className="instalments-data-row" container key={index}>
        <Grid item xs={12} sm={2} className="instalments-tc">
          <span className="it-cell">{props.labelInstalmentType}</span>
          <span className="id-cell">{instalmentType ? instalmentType.name : instalment.instalmentType}</span>
        </Grid>
        <Grid item xs={12} sm={2} className="instalments-tc">
          <span className="it-cell">{props.labelInstalmentAmount}</span>
          <span className="id-cell">{trailingNumbersFormat(instalment.amount)}</span>
        </Grid>
        <Grid item xs={12} sm={2} className="instalments-tc">
          <span className="it-cell">{props.labelInstalmentDate}</span>
          <span className="id-cell">{date}</span>
        </Grid>
        <Grid item xs={12} sm={2} className="instalments-tc">
          <span className="it-cell">{props.labelInstalmentFinishedDate}</span>
          <span className="id-cell">{finishedDate}</span>
        </Grid>
        <Grid item xs={12} sm={2} className="instalments-tc">
          <span className="it-cell">{props.labelInstalmentStatus}</span>
          <span className="id-cell">{instalmentState}</span>
        </Grid>
        <Grid item xs={12} sm={2} className="instalments-tc">
          <span className="it-cell">{props.labelInstalmentBeneficiary}</span>
          <span className="id-cell">{instalment.beneficiary}</span>
        </Grid>
      </Grid>
    );
  });

  const filesToDownlaod = files.map((file) => {
    return (
      <StyledMenuItem key={file.streamId}>
        <a
          style={{ display: 'inline-flex', width: '100%' }}
          href={`${process.env.REACT_APP_API_GATEWAY_URL}/dossier-intranet/v1/mydemand/${props.dossierId}/documents?demandId=${props.demandId}&streamId=${file.streamId}`}
          download
        >
          <ZoomPdfIcon viewBox="0 0 80 80" fontSize="large" htmlColor={blue.main}></ZoomPdfIcon>
          <ListItemText primary={file.fileName} />
        </a>
      </StyledMenuItem>
    );
  });

  let decisionState = '';
  if (props.demandDecisionStates) {
    const demandDecision = props.demandDecisionStates.find((option) => option.value === props.decision?.toString());
    if (demandDecision) {
      decisionState = demandDecision.name;
    }
  }

  const decisionDate = props.decisionDate ? moment(props.decisionDate).format('DD.MM.YYYY') : '';

  return (
    <div className={containerClasses}>
      <a className="open-close-switch" onClick={toggleState}>
        {isOpened ? (
          <ZoomArrowUpIcon viewBox="0 0 80 80" htmlColor={black.main}></ZoomArrowUpIcon>
        ) : (
          <ZoomArrowDownIcon viewBox="0 0 80 80" htmlColor={black.main}></ZoomArrowDownIcon>
        )}
      </a>
      <h2 className="conversation-title">{props.labelTitle + ' ' + props.titleYear}</h2>
      <h5 className="light-subtitle">{props.institution}</h5>

      {isOpened && (
        <div>
          <Grid container className="ai-container">
            <Grid item xs={12} md={8} container className="ai-wrapper">
              <Grid item xs={12} sm={4} className="aid-block">
                <p className="aid ai-label">{props.labelSubmittedOn}</p>
                <p className="aid ai-value">{moment(props.submitDate).format('DD.MM.YYYY')}</p>
              </Grid>
              <Grid item xs={12} sm={4} className="aid-block">
                <p className="aid ai-label">{props.labelDecision}</p>
                <p className="aid ai-value">{decisionDate}</p>
              </Grid>
              <Grid item xs={12} sm={4} className="aid-block">
                <p className="aid ai-label">{props.labelDecisionResult}</p>
                <p className="aid ai-value">{decisionState}</p>
              </Grid>
            </Grid>
            <Grid item xs={12} md={4} className="cm-wrapper">
              <Button
                aria-controls="customized-menu"
                aria-haspopup="true"
                color="primary"
                type="reset"
                variant="outlined"
                onClick={handleClick}
              >
                {props.labelDocumentDownload}
                <ZoomArrowDownIcon viewBox="0 0 80 80" htmlColor={blue.main}></ZoomArrowDownIcon>
              </Button>
              <StyledMenu
                id="customized-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                {filesToDownlaod}
              </StyledMenu>
            </Grid>
          </Grid>
          <Grid container className="charts-container">
            <Grid item xs={12} sm={6} className="chart-block">
              <ZoomPieChart {...scholarshipPieChartProps}></ZoomPieChart>
              <p>
                <span className="color-sample bg-blue"></span>
                {props.labelScholarship}
              </p>
              <p>
                <span className="color-sample bg-grey"></span>
                {props.scholarshipStatus}
              </p>
            </Grid>
            <Grid item xs={12} sm={6} className="chart-block">
              <ZoomPieChart {...debtPieChartProps}></ZoomPieChart>
              <p>
                <span className="color-sample bg-green"></span>
                {props.labelDebt}
              </p>
              <p>
                <span className="color-sample bg-grey"></span>
                {props.debtStatus}
              </p>
            </Grid>
          </Grid>
          <h2 className="data-title">{props.labelInstalments}</h2>
          <Grid container className="instalments-data">
            <Grid item xs={12} id="instalments-titles-row" container>
              <Grid item xs={12} sm={2} className="instalments-th">
                {props.labelInstalmentType}
              </Grid>
              <Grid item xs={12} sm={2} className="instalments-th">
                {props.labelInstalmentAmount}
              </Grid>
              <Grid item xs={12} sm={2} className="instalments-th">
                {props.labelInstalmentDate}
              </Grid>
              <Grid item xs={12} sm={2} className="instalments-th">
                {props.labelInstalmentFinishedDate}
              </Grid>
              <Grid item xs={12} sm={2} className="instalments-th">
                {props.labelInstalmentStatus}
              </Grid>
              <Grid item xs={12} sm={2} className="instalments-th">
                {props.labelInstalmentBeneficiary}
              </Grid>
            </Grid>
            {instalmentRows}
          </Grid>
        </div>
      )}
    </div>
  );
};
