import React, { ChangeEvent, ReactElement, useState } from 'react';
import ZoomSelectInput from '../../../../components/forms/select-input/ZoomSelectInput';
import ZoomDatePickerStartEndDate from '../../../../components/forms/date-picker-start-end-date/ZoomDatePickerStartEndDate';
import ZoomToggleInput from '../../../../components/forms/toggle-input/ZoomToggleInput';
import ZoomTextInput from '../../../../components/forms/text-input/ZoomTextInput';
import { Button } from '@material-ui/core';
import {
  dateFromToValidation,
  maxValueValidation,
  minValueValidation,
  requiredValidation,
} from '../../../../utils/validators';
import { setDateToMiddleOfMonth } from '../../../../utils/date-manipulation';

export interface Education {
  id?: number;
  type?: string;
  from?: Date;
  to?: Date;
  isFinished?: boolean;
  degree?: string;
  city?: string;
  kanton?: string;
  employmentQuota?: string;
  jobDescription?: string;
  monthlySalary?: string;
}

export interface CVItemFormProps extends Education {
  labelEducationType: string;
  formationType?: { name: string; value: string }[];
  labelFromTo: string;
  labelIsFinished: string;
  labelDegree: string;
  labelCity: string;
  labelKanton: string;
  kantonOptions: Array<{ name: string; value: string }>;
  labelEmploymentPercentage: string;
  labelJobDescription: string;
  labelMonthlySalary: string;
  labelYes: string;
  labelNo: string;
  labelSave: string;
  labelCancel: string;
  helpTextType?: string;
  errorLabels?: { [key: string]: string };
  onSave?: (education: Education) => void;
  onDelete?: () => void;
}

interface CVItemFormErrors {
  type?: string;
  fromTo?: string;
  degree?: string;
  city?: string;
  employmentQuota?: string;
  jobDescription?: string;
  monthlySalary?: string;
}

export const CVItemForm = (props: CVItemFormProps): ReactElement => {
  const [type, setType] = useState(props.type ? props.type.toString() : '119');
  const [id] = useState(props.id ? props.id : 0);
  const [from, setFrom] = useState(props.from ? props.from : null);
  const [to, setTo] = useState(props.to ? props.to : null);
  const [isFinished, setIsFinished] = useState(props.isFinished ? props.isFinished : false);
  const [degree, setDegree] = useState(props.degree ? props.degree : undefined);
  const [city, setCity] = useState(props.city ? props.city : undefined);
  const [kanton, setKanton] = useState(props.kanton ? props.kanton : 'AG');
  const [employmentQuota, setEmploymentQuota] = useState(props.employmentQuota ? props.employmentQuota : '');
  const [jobDescription, setJobDescription] = useState(props.jobDescription ? props.jobDescription : undefined);
  const [monthlySalary, setMonthlySalary] = useState(props.monthlySalary ? props.monthlySalary : '');
  const initialErrors: CVItemFormErrors = {};
  const [errors, setErrors] = useState(initialErrors);
  const onSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    const newErrors = getFormErrors();
    setErrors(newErrors);
    const errorsPresent = Object.values(newErrors).some((value) => value !== '');
    if (props.onSave && !errorsPresent) {
      props.onSave({
        id,
        type,
        from: setDateToMiddleOfMonth(from),
        to: setDateToMiddleOfMonth(to),
        isFinished,
        degree,
        city,
        kanton,
        jobDescription,
        employmentQuota: employmentQuota ? employmentQuota : undefined,
        monthlySalary: monthlySalary ? monthlySalary : undefined,
      });
    }
  };

  const onDelete = () => {
    if (props.onDelete) {
      props.onDelete();
    }
  };

  const onTypeChange = (type: string) => {
    setType(type);
    setErrors({ ...errors, type: getTypeErrors(type) });
  };

  const onChangeStart = (startDate: Date | null) => {
    setFrom(startDate);
    setErrors({ ...errors, fromTo: getFromToErrors(startDate, to) });
  };

  const onChangeEnd = (endDate: Date | null) => {
    setTo(endDate);
    setErrors({ ...errors, fromTo: getFromToErrors(from, endDate) });
  };

  const onIsFinishedChange = (isFinished: string) => {
    setIsFinished(isFinished === 'true');
  };

  const onKantonChange = (kanton: string) => {
    setKanton(kanton);
  };

  const onDegreeChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setDegree(event.target.value);
    setErrors({ ...errors, degree: getDegreeErrors(event.target.value) });
  };

  const onCityChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setCity(event.target.value);
    setErrors({ ...errors, city: getCityErrors(event.target.value) });
  };

  const onJobDescriptionChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setJobDescription(event.target.value);
    setErrors({ ...errors, jobDescription: getJobDescriptionErrors(event.target.value) });
  };

  const onEmploymentQuotaChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setEmploymentQuota(event.target.value);
    setErrors({ ...errors, employmentQuota: getEmploymentQuotaErrors(event.target.value) });
  };

  const onSalaryChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setMonthlySalary(event.target.value);
    setErrors({ ...errors, monthlySalary: getSalaryErrors(event.target.value) });
  };

  const getTypeErrors = (value: string): string => {
    if (value === '0') {
      return requiredValidation('', props.errorLabels ? props.errorLabels : {});
    }
    return '';
  };

  const getFromToErrors = (from: Date | null, to: Date | null): string => {
    if (from === null || to === null) {
      return requiredValidation('', props.errorLabels ? props.errorLabels : {});
    }
    return dateFromToValidation(from, to, props.errorLabels ? props.errorLabels : {});
  };

  const getDegreeErrors = (value: string): string => {
    if (isFormElementVisible('degree')) {
      return requiredValidation(value, props.errorLabels ? props.errorLabels : {});
    }
    return '';
  };

  const getCityErrors = (value: string): string => {
    if (isFormElementVisible('city')) {
      return requiredValidation(value, props.errorLabels ? props.errorLabels : {});
    }
    return '';
  };

  const getJobDescriptionErrors = (value: string): string => {
    if (isFormElementVisible('jobDescription')) {
      return requiredValidation(value, props.errorLabels ? props.errorLabels : {});
    }
    return '';
  };

  const getEmploymentQuotaErrors = (value: string): string => {
    let error = '';
    if (isFormElementVisible('employmentQuota')) {
      error = minValueValidation(value, 0, props.errorLabels ? props.errorLabels : {});
      if (!error) {
        error = maxValueValidation(value, 100, props.errorLabels ? props.errorLabels : {});
      }
    }
    return error;
  };

  const getSalaryErrors = (value: string): string => {
    let error = '';
    if (isFormElementVisible('salary')) {
      error = minValueValidation(value, 0, props.errorLabels ? props.errorLabels : {});
      if (!error) {
        error = maxValueValidation(value, 9999999, props.errorLabels ? props.errorLabels : {});
      }
    }
    return error;
  };

  const getFormErrors = (): CVItemFormErrors => {
    const errors: CVItemFormErrors = {};
    errors.type = getTypeErrors(type);
    errors.fromTo = getFromToErrors(from, to);
    errors.degree = getDegreeErrors(degree ? degree : '');
    errors.city = getCityErrors(city ? city : '');
    errors.jobDescription = getJobDescriptionErrors(jobDescription ? jobDescription : '');
    errors.employmentQuota = getEmploymentQuotaErrors(employmentQuota.toString());
    errors.monthlySalary = getSalaryErrors(monthlySalary.toString());
    return errors;
  };

  const isFormElementVisible = (name: string): boolean => {
    switch (name) {
      case 'isFinished':
        return ['114', '102', '121', '122', '123', '124', '101', '117', '125', '108', '109', '126'].includes(type);
      case 'degree':
        const precondition = isFormElementVisible('isFinished') && isFinished;
        return ['102', '123', '124', '117', '125', '108', '109', '126'].includes(type) && precondition;
      case 'city':
        return true;
      case 'kanton':
        return true;
      case 'jobDescription':
        return type === '1102';
      case 'employmentQuota':
        return ['101', '117', '125', '108', '109', '126', '1101'].includes(type);
      case 'salary':
        return ['101', '117', '125', '1101', '129'].includes(type);
    }
    return true;
  };

  return (
    <>
      <form onSubmit={onSubmit} key={'child-item-form-' + id}>
        <div className="form-element-container">
          <ZoomSelectInput
            label={props.labelEducationType}
            options={
              props.formationType
                ? props.formationType.sort((x, y) => (x.name > y.name ? 1 : x.name === y.name ? 0 : -1))
                : []
            }
            value={type}
            helpText={props.helpTextType}
            name="educationType"
            error={!!errors.type}
            errorMessage={errors.type}
            onChange={onTypeChange}
          ></ZoomSelectInput>
        </div>
        <div className="form-element-container">
          <ZoomDatePickerStartEndDate
            label={props.labelFromTo}
            valueStart={from}
            valueEnd={to}
            error={!!errors.fromTo}
            errorMessage={errors.fromTo}
            onChangeStart={onChangeStart}
            onChangeEnd={onChangeEnd}
          ></ZoomDatePickerStartEndDate>
        </div>
        {isFormElementVisible('isFinished') && (
          <div className="form-element-container">
            <ZoomToggleInput
              title={props.labelIsFinished}
              label1={props.labelYes}
              label2={props.labelNo}
              value={isFinished ? 'true' : 'false'}
              value1="true"
              value2="false"
              name="isFinished"
              onChange={onIsFinishedChange}
            ></ZoomToggleInput>
          </div>
        )}
        {isFormElementVisible('degree') && (
          <div className="form-element-container">
            <ZoomTextInput
              label={props.labelDegree}
              name="degree"
              onChange={onDegreeChange}
              error={!!errors.degree}
              errorMessage={errors.degree}
              value={degree}
            ></ZoomTextInput>
          </div>
        )}
        {isFormElementVisible('city') && (
          <div className="form-element-container">
            <ZoomTextInput
              label={props.labelCity}
              name="city"
              value={city}
              onChange={onCityChange}
              error={!!errors.city}
              errorMessage={errors.city}
            ></ZoomTextInput>
          </div>
        )}
        {isFormElementVisible('kanton') && (
          <div className="form-element-container">
            <ZoomSelectInput
              label={props.labelKanton}
              options={props.kantonOptions}
              value={kanton}
              name="kanton"
              onChange={onKantonChange}
            ></ZoomSelectInput>
          </div>
        )}
        {isFormElementVisible('employmentQuota') && (
          <div className="form-element-container">
            <ZoomTextInput
              label={props.labelEmploymentPercentage}
              name="employmentQuota"
              value={employmentQuota.toString()}
              error={!!errors.employmentQuota}
              errorMessage={errors.employmentQuota}
              onChange={onEmploymentQuotaChange}
            ></ZoomTextInput>
          </div>
        )}
        {isFormElementVisible('jobDescription') && (
          <div className="form-element-container">
            <ZoomTextInput
              label={props.labelJobDescription}
              name="jobDescription"
              value={jobDescription}
              error={!!errors.jobDescription}
              errorMessage={errors.jobDescription}
              onChange={onJobDescriptionChange}
            ></ZoomTextInput>
          </div>
        )}
        {isFormElementVisible('salary') && (
          <div className="form-element-container">
            <ZoomTextInput
              label={props.labelMonthlySalary}
              name="salary"
              value={monthlySalary ? monthlySalary.toString() : ''}
              error={!!errors.monthlySalary}
              errorMessage={errors.monthlySalary}
              onChange={onSalaryChange}
            ></ZoomTextInput>
          </div>
        )}
        <div className="form-element-container">
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              data-testid="save-education-button"
              color="primary"
              type="submit"
              variant="contained"
              size="small"
              title={props.labelSave}
            >
              {props.labelSave}
            </Button>
            <Button
              data-testid="cancel-child-button"
              color="primary"
              variant="outlined"
              size="small"
              title={props.labelCancel}
              onClick={() => {
                onDelete();
              }}
            >
              {props.labelCancel}
            </Button>
          </div>
        </div>
      </form>
    </>
  );
};
