import { NavigateNext } from '@material-ui/icons';
import React, { ReactElement } from 'react';
import './PowerOfAttorneyItemInfo.scss';

export interface PowerOfAttorneyItemInfoProps {
  id: number;
  firstName?: string;
  lastName?: string;
  role?: string;
  error?: boolean;
  disabled?: boolean;
  onDelete?: (id: number) => void;
  onEdit?: (id: number) => void;
  rolesTypes?: { name: string; value: string }[];
}

export const PowerOfAttorneyItemInfo = (props: PowerOfAttorneyItemInfoProps): ReactElement => {
  const onDelete = () => {
    if (props.onDelete) {
      props.onDelete(props.id);
    }
  };

  return (
    <>
      <div className="attorney-info">
        <div>
          {props.rolesTypes ? props.rolesTypes.find((r) => r.value == (props.role ? props.role : '5'))?.name : ''}
        </div>
        <div>{props.firstName}</div>
        <div>{props.lastName}</div>
        <div className="attorney-info-edit-container"></div>
      </div>
      {!props.error && !props.disabled && (
        <div className="attorney-info-delete-container">
          <NavigateNext />
          <a onClick={onDelete}>Angaben löschen</a>
        </div>
      )}
    </>
  );
};
