import produce from 'immer';
import { CanCreateNewDemandResultAction, UserDataAction, UserDataActionType } from './actions';
import { EducationData, UserData, UserMyData } from './types';

export const initialState: UserData = { pifId: 1, dossierId: 1, demandId: 1 };
export const initialStateMyData: UserMyData = { dossierId: 1 };
export const initialStateMyEducationData: EducationData = { dossierId: 1 };

export function userDataReducer(state = initialState, action: UserDataActionType): UserData {
  return produce(state, (draft) => {
    switch (action.type) {
      case UserDataAction.GET:
        draft.isLoading = true;
        break;
      case UserDataAction.SET_NEW_DEMAND:
        const demandAction = action;
        draft = {
          ...state,
          dossierId: demandAction.dossierId,
          demandId: demandAction.demandId,
          pifId: demandAction.payload.pifId,
        };
        break;
      case UserDataAction.GET_RESULT:
        const userDataAction = action;
        draft = {
          ...state,
          firstName: userDataAction.payload.firstName,
          lastName: userDataAction.payload.lastName,
          svn: userDataAction.payload.svn,
          birthDate: userDataAction.payload.birthDate,
          dossierId: userDataAction.payload.dossierId,
          getNotifications: userDataAction.payload.getNotifications,
        };
        draft.isLoading = false;
        break;
    }
    return draft;
  });
}

export function userMyEducationDataReducer(
  state = initialStateMyEducationData,
  action: UserDataActionType,
): EducationData {
  return produce(state, (draft) => {
    switch (action.type) {
      case UserDataAction.GET_USER_EDUCATION_DATA:
        draft.isLoading = true;
        break;
      case UserDataAction.GET_RESULT_USER_EDUCATION_DATA:
        draft = action.payload;
        draft.isLoading = false;
        break;
    }
    return draft;
  });
}

export function userMyDataReducer(state = initialStateMyData, action: UserDataActionType): UserMyData {
  return produce(state, (draft) => {
    switch (action.type) {
      case UserDataAction.GET_USER_MY_DATA:
        draft.isLoading = true;
        break;
      case UserDataAction.GET_RESULT_USER_MY_DATA:
        draft = action.payload;
        draft.isLoading = false;
        break;
      case UserDataAction.CAN_CREATE_NEW_DEMAND_RESULT:
        const createNewDemandAction = (action as unknown) as CanCreateNewDemandResultAction;
        draft.canCreateNewDemand = createNewDemandAction.payload;
        break;
    }
    return draft;
  });
}
