import { Observable, of, Subscriber } from 'rxjs';
import { Epic, ofType } from 'redux-observable';
import { Action } from 'redux';
import { catchError, debounceTime, map, mergeMap, switchMap } from 'rxjs/operators';
import { AjaxError, AjaxResponse } from 'rxjs/ajax';
import {
  DocumentsAction,
  DocumentsActionDeleteFile,
  DocumentsActionDeleteFileSuccess,
  DocumentsActionGetRoles,
  DocumentsActionGetRolesResult,
  DocumentsProgressAction,
  DocumentsShowErrorAction,
  DocumentsShowSuccessAction,
  DocumentsUploadAction,
  DocumentsUploadErrorAction,
  DocumentsUploadReadyAction,
} from './actions';
import { ZoomDocumentsRole } from '../../containers/upload-document-group-form/UploadDocumentGroupForm';
import { customAjax } from '../../utils/ajax-wrapper';

export class DocumentAjaxError extends AjaxError {
  documentTypeId?: number;
  fileName?: string;
}

export class DocumentAjaxResponse extends AjaxResponse {
  documentTypeId?: number;
  fileName?: string;
}

export interface DocumentProgressEvent {
  event: ProgressEvent;
  documentTypeId: number;
  fileName: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getSubmitResult = (
  payload: DocumentProgressEvent | DocumentAjaxResponse | DocumentAjaxError,
): DocumentsUploadReadyAction | DocumentsProgressAction | DocumentsUploadErrorAction => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const genericPayload: any = payload;
  if (genericPayload.event) {
    const progressEvent = payload as DocumentProgressEvent;
    return {
      type: DocumentsAction.PROGRESS,
      payload: {
        name: progressEvent.fileName ? progressEvent.fileName : '',
        typeId: progressEvent.documentTypeId,
        progress: (progressEvent.event.loaded / progressEvent.event.total) * 100,
      },
    };
  }

  if (genericPayload.message) {
    const ajaxError = payload as DocumentAjaxError;
    return {
      type: DocumentsAction.UPLOAD_ERROR,
      payload: {
        error: ajaxError.response.Detail,
        typeId: ajaxError.documentTypeId ? ajaxError.documentTypeId : -1,
      },
    };
  }

  const ajaxResponse = payload as DocumentAjaxResponse;
  return {
    type: DocumentsAction.UPLOAD_READY,
    payload: { ...ajaxResponse.response },
  };
};

export const sendFileEpic: Epic<Action, Action> = (action$: Observable<Action>): Observable<Action> =>
  action$.pipe(
    ofType(DocumentsAction.UPLOAD),
    switchMap((action) => {
      const documentUploadAction: DocumentsUploadAction = action as DocumentsUploadAction;
      const formData = new FormData();
      if (documentUploadAction.payload.originalFile) {
        formData.append('input', documentUploadAction.payload.originalFile, documentUploadAction.payload.fileName);
      }

      return new Observable<DocumentProgressEvent | DocumentAjaxResponse | DocumentAjaxError>((subscriber) => {
        const documentTypeId = documentUploadAction.payload.typeId ? documentUploadAction.payload.typeId : 142;
        const progressSubject = new Subscriber<ProgressEvent>((event) => {
          const progressEvent: DocumentProgressEvent = {
            event: event,
            documentTypeId: documentTypeId,
            fileName: documentUploadAction.payload.fileName,
          };
          subscriber.next(progressEvent);
        });

        return customAjax({
          subRoute: `/dossier/v1/demands/${documentUploadAction.demandId}/documents?documentType=${documentTypeId}`,
          withCredentials: true,
          method: 'POST',
          body: formData,
          progressSubscriber: progressSubject,
        })
          .pipe(
            map((response) => {
              return {
                ...response,
                documentTypeId: documentTypeId,
                fileName: documentUploadAction.payload.fileName,
              };
            }),
            catchError((error) => {
              const ajaxError = error as AjaxError;
              return of({
                ...ajaxError,
                documentTypeId: documentTypeId,
                fileName: documentUploadAction.payload.fileName,
              });
            }),
          )
          .subscribe(subscriber);
      });
    }),
    map((response) => {
      return getSubmitResult(response);
    }),
  );

export const showStatusMessageEpic: Epic<Action, Action> = (action$: Observable<Action>): Observable<Action> =>
  action$.pipe(
    ofType(DocumentsAction.UPLOAD_READY),
    map((action) => {
      const uploadReadyAction = action as DocumentsUploadReadyAction;
      return { type: DocumentsAction.SHOW_SUCCESS, payload: uploadReadyAction.payload.typeId };
    }),
  );

export const showErrorEpic: Epic<Action, Action> = (action$: Observable<Action>): Observable<Action> =>
  action$.pipe(
    ofType(DocumentsAction.UPLOAD_ERROR),
    map((action) => {
      const errorAction = action as DocumentsUploadErrorAction;
      return { type: DocumentsAction.SHOW_ERROR, payload: errorAction.payload };
    }),
  );

export const resetStatusEpic: Epic<Action, Action> = (action$: Observable<Action>): Observable<Action> =>
  action$.pipe(
    ofType(DocumentsAction.SHOW_SUCCESS, DocumentsAction.SHOW_ERROR),
    debounceTime(2000),
    map((action) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const anyAction = action as any;
      if ('number' === typeof anyAction.payload) {
        const showSuccessAction = action as DocumentsShowSuccessAction;
        return { type: DocumentsAction.RESET_STATUS, payload: showSuccessAction.payload };
      } else {
        const showErrorAction = action as DocumentsShowErrorAction;
        return { type: DocumentsAction.RESET_STATUS, payload: showErrorAction.payload.typeId };
      }
    }),
  );

export const processRoles = (response: AjaxResponse): DocumentsActionGetRolesResult => {
  return {
    type: DocumentsAction.GET_ROLES_RESULT,
    payload: response.response as Array<ZoomDocumentsRole>,
  };
};

export const getDocumentRolesEpic: Epic<Action, Action> = (action$: Observable<Action>): Observable<Action> =>
  action$.pipe(
    ofType(DocumentsAction.GET_ROLES),
    mergeMap((action) => {
      const documentsGetRoles: DocumentsActionGetRoles = action as DocumentsActionGetRoles;
      return customAjax({
        subRoute: `/dossier/v1/demands/${documentsGetRoles.demandId}/documents/specs-generator`,
        withCredentials: true,
        method: 'GET',
      }).pipe(
        map((response) => processRoles(response)),
        catchError((error) => {
          return of(error.message);
        }),
      );
    }),
  );

export const processFileDeleteSuccess = (
  response: AjaxResponse,
  streamId: string,
): DocumentsActionDeleteFileSuccess => {
  return {
    type: DocumentsAction.DELETE_FILE_SUCCESS,
    payload: streamId,
  };
};

export const deleteDocumentEpic: Epic<Action, Action> = (action$: Observable<Action>): Observable<Action> =>
  action$.pipe(
    ofType(DocumentsAction.DELETE_FILE),
    mergeMap((action) => {
      const deleteFileAction = action as DocumentsActionDeleteFile;
      const streamId = deleteFileAction.payload.streamId ? deleteFileAction.payload.streamId : '';
      return customAjax({
        subRoute: `/dossier/v1/demands/${deleteFileAction.demandId}/documents/${deleteFileAction.payload.streamId}`,
        withCredentials: true,
        method: 'DELETE',
      }).pipe(map((response) => processFileDeleteSuccess(response, streamId)));
    }),
  );
