import { Container } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import produce from 'immer';
import React, { ReactElement, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { HolderDataGetAction } from '../../store/holder-data/actions';
import { i18nLabel } from '../../store/i18n/helpers';
import {
  PowerOfAttorneyDataAction,
  PowerOfAttorneyDataActionType,
  PowerOfAttorneyDataAddItemAction,
  PowerOfAttorneyDataDeleteItemAction,
  PowerOfAttorneyDataGetAction,
} from '../../store/mandatory-power-of-attorney-data/actions';
import {
  MandatoryPowerOfAttorneyData,
  MandatoryPowerOfAttorneyDataStore,
} from '../../store/mandatory-power-of-attorney-data/types';
import { WIAAStore } from '../../store/types';
import { Attorney, PowerOfAttorneyItemForm } from './item/form/PowerOfAttorneyItemForm';
import { PowerOfAttorneyItemInfo } from './item/info/PowerOfAttorneyItemInfo';
import './PowerOfAttorneyForm.scss';

export interface PowerOfAttorneyFormProps {
  labelTitle?: string;
  labelDescription?: string;
  labelRole?: string;
  labelFirstName?: string;
  labelLastName?: string;
  labelEmail?: string;
  labelEmailConfirmation?: string;
  labelRight?: string;
  helpTextRight?: string;
  labelCheckbox?: string;
  labelNoEntries: string;
  labelAddAdditionalPositions: string;
  labelSave: string;
  labelCancel: string;
  labelNext: string;
  piaAttorneys: Array<Attorney>;
  attorneysBeingEdited: Array<number>;
  attorneysAvailable: boolean;
  errorLabels?: { [key: string]: string };
  rolesTypes?: { name: string; value: string }[];
  error?: string;
  dossierId?: number;
  dispatch?: (action: PowerOfAttorneyDataActionType | HolderDataGetAction) => void;
}

export const PowerOfAttorneyForm = (props: PowerOfAttorneyFormProps): ReactElement => {
  const [piaAttorneys, setPiaAttorneys] = useState(props.piaAttorneys ? props.piaAttorneys : []);
  const [attorneysBeingEdited, setAttorneysBeingEdited] = useState(
    props.attorneysBeingEdited ? props.attorneysBeingEdited : [],
  );

  useEffect(() => {
    setPiaAttorneys(props.piaAttorneys ? props.piaAttorneys : []);
  }, [props.piaAttorneys]);

  useEffect(() => {
    if (props.dispatch) {
      const loadPowerOfAttorney: PowerOfAttorneyDataGetAction = {
        type: PowerOfAttorneyDataAction.GET,
      };
      props.dispatch(loadPowerOfAttorney);
    }
  }, []);

  const onSave = (index: number, attorney: Attorney) => {
    if (props.dispatch) {
      const addAttorneyAction: PowerOfAttorneyDataAddItemAction = {
        type: PowerOfAttorneyDataAction.ADD_ITEM,
        payload: {
          roleId: Number(attorney.role),
          firstName: attorney.firstName,
          lastName: attorney.lastName,
          email: attorney.email,
        } as MandatoryPowerOfAttorneyData,
      };

      props.dispatch(addAttorneyAction);
    }

    const nextStateAttorneys = produce(piaAttorneys, (draft) => {
      draft[index] = attorney;
    });
    setPiaAttorneys(nextStateAttorneys);

    const nextStateBeingEdited = produce(attorneysBeingEdited, (draft) => {
      const indexToDelete = draft.findIndex((i) => i === index);
      if (indexToDelete > -1) {
        draft.splice(indexToDelete, 1);
      }
    });
    setAttorneysBeingEdited(nextStateBeingEdited);
  };

  const onDelete = (index: number) => {
    const attorneyEmail = piaAttorneys[index].email;

    const nextStateAttorneys = produce(piaAttorneys, (draft) => {
      draft.splice(index, 1);
    });
    setPiaAttorneys(nextStateAttorneys);

    const nextStateBeingEdited = produce(attorneysBeingEdited, (draft) => {
      const indexToDelete = draft.findIndex((i) => i === index);
      if (indexToDelete > -1) {
        draft.splice(indexToDelete, 1);
      }
    });
    setAttorneysBeingEdited(nextStateBeingEdited);

    if (attorneyEmail && props.dispatch) {
      const deleteAttorneyData: PowerOfAttorneyDataDeleteItemAction = {
        type: PowerOfAttorneyDataAction.DELETE_ITEM,
        email: attorneyEmail,
      };
      props.dispatch(deleteAttorneyData);
    }
  };

  const addNewAttorney = () => {
    const nextState = produce(piaAttorneys, (draft) => {
      draft.push({});
    });
    setPiaAttorneys(nextState);
    const nextStateBeingEdited = produce(attorneysBeingEdited, (draft) => {
      draft.push(nextState.length - 1);
    });
    setAttorneysBeingEdited(nextStateBeingEdited);
  };

  const toRender = piaAttorneys.map((attorney, index) => {
    if (attorneysBeingEdited.includes(index)) {
      const params = { ...props, ...attorney };
      return (
        <div className="attorney-form-wrapper" key={index}>
          <PowerOfAttorneyItemForm
            {...params}
            onSave={(attorney) => {
              onSave(index, attorney);
            }}
            onDelete={() => {
              onDelete(index);
            }}
          ></PowerOfAttorneyItemForm>
        </div>
      );
    }

    const firstName = attorney.firstName ? attorney.firstName : '';
    const lastName = attorney.lastName ? attorney.lastName : '';
    const role = attorney.role ? attorney.role : '5';
    return (
      <PowerOfAttorneyItemInfo
        firstName={firstName}
        lastName={lastName}
        role={role}
        id={index}
        key={index}
        error={!!props.error}
        disabled={attorneysBeingEdited.length > 0}
        onDelete={() => {
          onDelete(index);
        }}
      ></PowerOfAttorneyItemInfo>
    );
  });

  const nextButtonDisabled = !props.attorneysAvailable;

  const redirectToDashboard = () => {
    if (props.dispatch) {
      props.dispatch({
        type: PowerOfAttorneyDataAction.REDIRECT_TO_DASHBOARD,
      });
    }
  };

  return (
    <>
      <main className="main">
        <Container>
          <div id="main-page-container">
            <h1>{props.labelTitle}</h1>
            <p className="description-paragraph">{props.labelDescription}</p>
            <div>{toRender}</div>
            {piaAttorneys.length === 0 && <div className="no-entries-div">{props.labelNoEntries}</div>}
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 10 }}>
              <Button
                variant="outlined"
                color="primary"
                id="add-attorney-button"
                onClick={addNewAttorney}
                disabled={!!props.error}
              >
                {props.labelAddAdditionalPositions}
              </Button>
            </div>
            {props.error && <p className="poa-error">{i18nLabel(props.errorLabels, props.error, props.error)}</p>}
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 10 }}>
              <Button
                variant="contained"
                color="primary"
                id="save-button"
                disabled={nextButtonDisabled}
                onClick={redirectToDashboard}
              >
                {props.labelNext}
              </Button>
            </div>
          </div>
        </Container>
      </main>
    </>
  );
};

export function mapStateToProps(store: WIAAStore): PowerOfAttorneyFormProps {
  const props: PowerOfAttorneyFormProps = {
    labelTitle: i18nLabel(store.translationLabels, 'Power_Of_Attorney_Title', 'Bevollmächtige hinzufügen'),
    labelDescription: i18nLabel(
      store.translationLabels,
      'Power_Of_Attorney_Description',
      'Da Sie unter 18 Jahre sind, müssen Sie mindestens ein Elternteil oder einen gesetzlichen Vormund als bevollmächtigte Person hinzufügen, welche auf ihre Anträge zugreifen kann. Die Person erhält eine Einladung zur Bevollmächtigung per E-Mail.',
    ),
    labelRole: i18nLabel(store.translationLabels, 'Power_Of_Attorney_Title_Role', 'Rolle*'),
    labelFirstName: i18nLabel(store.translationLabels, 'Power_Of_Attorney_First_Name', 'Vorname*'),
    labelLastName: i18nLabel(store.translationLabels, 'Power_Of_Attorney_Last_Name', 'Nachname*'),
    labelEmail: i18nLabel(store.translationLabels, 'Power_Of_Attorney_Email', 'E-mail*'),
    labelEmailConfirmation: i18nLabel(
      store.translationLabels,
      'Power_Of_Attorney_Email_Confirmation',
      'E-mail bestätigen*',
    ),
    labelRight: i18nLabel(store.translationLabels, 'Power_Of_Attorney_Right', 'Rechte'),
    helpTextRight: i18nLabel(
      store.translationLabels,
      'Power_Of_Attorney_Right_Help_Text',
      'Dieser Zugriff ermöglicht die Berechtigung auf alle Funktionalitäten sowie Informationen auf Ihrem Portal.',
    ),
    labelCheckbox: i18nLabel(
      store.translationLabels,
      'Power_Of_Attorney_Checkbox',
      'Ich nehme zur Kenntnis, dass die oben angegebene E-Mail dieselbe E-Mail Adresse ist, mit welcher sich die Bevollmächtigte Person auch über Mein Konto (https://login.ag.ch/sls-ng) anmeldet oder registrieren wird.',
    ),
    labelNoEntries: i18nLabel(store.translationLabels, 'Power_Of_Attorney_No_Entries', 'Keine Einträge'),
    labelAddAdditionalPositions: i18nLabel(
      store.translationLabels,
      'Power_Of_Attorney_Add_Additional_Positions',
      'Weitere Position hinzufügen',
    ),
    labelSave: i18nLabel(store.translationLabels, 'Power_Of_Attorney_Save', 'Speichern'),
    labelCancel: i18nLabel(store.translationLabels, 'Power_Of_Attorney_Cancel', 'Löschen'),
    labelNext: i18nLabel(store.translationLabels, 'Power_Of_Attorney_Next', 'Jetzt losgehen'),
    errorLabels: store.translationLabels,
    piaAttorneys: [],
    attorneysBeingEdited: [],
    attorneysAvailable: false,
    error: store.mandatoryPowerOfAttorneyData.error,
    rolesTypes: store.translationOptions['authorities-roles-types']
      ? [
          ...store.translationOptions['authorities-roles-types'].map((option) => {
            return { value: option.id.toString(), name: option.name };
          }),
        ]
      : [],
  };

  const data: MandatoryPowerOfAttorneyDataStore = store.mandatoryPowerOfAttorneyData;
  if (data && data.authorities) {
    props.piaAttorneys = Object.values(data.authorities).map(
      (authority) =>
        ({
          role: authority.roleId,
          firstName: authority.firstName,
          lastName: authority.lastName,
          email: authority.email,
          emailConfirmation: authority.email,
          right: 'Voller Zugriff',
          checkboxValue: false,
        } as Attorney),
    );
  }
  props.attorneysAvailable = props.piaAttorneys.length > 0;
  props.dossierId = data.dossierId;
  return props;
}

export default connect(mapStateToProps)(PowerOfAttorneyForm);
