import produce from 'immer';
import { LastDemandDataTakeoverAction, LastDemandDataType } from '../last-demand-data-takeover/actions';
import { MotherDataAction, MotherDataActionType } from './actions';
import { MotherData } from './types';

export const initialState: MotherData = {};

export function motherDataReducer(state = initialState, action: MotherDataActionType | LastDemandDataType): MotherData {
  return produce(state, (draft) => {
    switch (action.type) {
      case MotherDataAction.RESULT:
        draft = action.payload;
        break;
      case MotherDataAction.GET_RESULT:
        draft = action.payload;
        break;
      case MotherDataAction.GET_POSTAL_CODES:
        draft = action.payload;
        break;
      case MotherDataAction.GET_POSTAL_CODES_RESULT:
        const postalCode = action.payload;
        draft = { ...state, zipCode: postalCode.zipCode, town: postalCode.extendedName };
        break;
      case LastDemandDataTakeoverAction.RESULT:
        const lastDemandData = action.payload;
        draft = { ...lastDemandData.mother };
        break;
    }
    return draft;
  });
}
