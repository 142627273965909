import { MyLoanDataStore } from './types';

export enum MyLoanAction {
  GET = 'MY_LOAN_GET',
  GET_RESULT = 'MY_LOAN_GET_RESULT',
  RESULT = 'MY_LOAN_RESULT',
  NON_EXISTENT_MY_LOAN_DATA = 'NON_EXISTENT_MY_LOAN_DATA',
}

export interface MyLoanGetAction {
  type: typeof MyLoanAction.GET;
  payload: number;
}

export interface MyLoanGetResultAction {
  type: typeof MyLoanAction.GET_RESULT;
  payload: MyLoanDataStore;
}

export type MyLoanActionType = MyLoanGetAction | MyLoanGetResultAction;
