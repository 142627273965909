import React, { ReactElement } from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export function ZoomPlusIcon(props: SvgIconProps): ReactElement {
  return (
    <SvgIcon {...props}>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <path d="M0 0H80V80H0z" transform="translate(-156 -157) translate(156 157)" />
            <path
              fill={props.htmlColor ? props.htmlColor : '#262626'}
              fillRule="nonzero"
              d="M40.6 16.48c-13.255 0-24 10.745-24 24s10.745 24 24 24 24-10.745 24-24-10.745-24-24-24zm0 44c-11.046 0-20-8.954-20-20s8.954-20 20-20 20 8.954 20 20c.008 5.31-2.096 10.404-5.847 14.162C51 58.399 45.91 60.51 40.6 60.51v-.03z"
              transform="translate(-156 -157) translate(156 157)"
            />
            <path
              fill={props.htmlColor ? props.htmlColor : '#262626'}
              fillRule="nonzero"
              d="M42.6 27.69L38.6 27.69 38.6 38.5 27.8 38.5 27.8 42.5 38.6 42.5 38.6 53.3 42.6 53.3 42.6 42.5 53.41 42.5 53.41 38.5 42.6 38.5z"
              transform="translate(-156 -157) translate(156 157)"
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}
