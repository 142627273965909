import React, { ReactElement } from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import { blue } from './../../theme';

export function ZoomInfoIcon(props: SvgIconProps): ReactElement {
  return (
    <SvgIcon {...props}>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <path d="M0 0H80V80H0z" transform="translate(-456 -357) translate(456 357)" />
            <path
              fill={blue.main}
              fillRule="nonzero"
              d="M40.6 16.48c-13.255 0-24 10.745-24 24s10.745 24 24 24 24-10.745 24-24-10.745-24-24-24zm0 44c-11.046 0-20-8.954-20-20s8.954-20 20-20 20 8.954 20 20c.008 5.31-2.096 10.404-5.847 14.162C51 58.399 45.91 60.51 40.6 60.51v-.03z"
              transform="translate(-456 -357) translate(456 357)"
            />
            <path
              fill={blue.main}
              fillRule="nonzero"
              d="M40.47 32.42c.834 0 1.586-.503 1.904-1.274.318-.771.14-1.658-.45-2.246-.592-.589-1.48-.762-2.25-.44-.769.322-1.268 1.076-1.264 1.91 0 .545.217 1.068.604 1.453s.91.6 1.456.597zM40.56 34.52c-1.105 0-2 .895-2 2v14.16c0 1.105.895 2 2 2s2-.895 2-2V36.52c0-1.105-.895-2-2-2z"
              transform="translate(-456 -357) translate(456 357)"
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}
