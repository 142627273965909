import { useMediaQuery } from '@material-ui/core';
import React, { ReactElement, useEffect } from 'react';
import { Route, Router, Switch } from 'react-router-dom';
import { Action } from 'redux';
import ConnectedIdentification from './pages/identification/Identification';
import ConnectedMandatoryPowerOfAttorney from './pages/power-of-attorney/PowerOfAttorney';
import ConnectedDashboard from './pages/dashboard/ConnectedDashboard';
import ConnectedDemandOverview from './pages/demand-overview/DemandOverview';
import ConnectedDemandOverviewMobile from './pages/demand-overview/DemandOverviewMobile';
import ConnectedHomepage from './pages/homepage/ConnectedHomepage';
import ConnectedHomepageMobile from './pages/homepage/HomepageMobile';
import ConnectedScholarshipHolder from './pages/scholarship-holder/ScholarshipHolder';
import ConnectedScholarshipHolderMobile from './pages/scholarship-holder/ScholarshipHolderMobile';
import ConnectedMyDataOverview from './pages/my-data-overview/MyDataOverview';
import ConnectedEditPersonalData from './pages/edit-personal-data/EditPersonalData';
import ConnectedEditContactData from './pages/edit-contact-data/EditContactData';
import ConnectedEditAddressData from './pages/edit-address-data/EditAddressData';
import ConnectedEditPayoutAddressData from './pages/edit-payout-address-data/EditPayoutAddressData';
import ConnectedEducationInterrupted from './pages/education-interrupted/EducationInterrupted';
import ConnectedEducationCompleted from './pages/education-completed/EducationCompleted';
import ConnectedEducationChanged from './pages/education-changed/EducationChanged';
import ConnectedEducationCanceled from './pages/education-canceled/EducationCanceled';
import ConnectedMyEducationsOverview from './pages/my-educations-overview/MyEducationsOverview';
import ConnectedIdentificationError from './pages/identification-error/IdentificationError';
import ConnectedMyPowerOfAttorneysOverview from './pages/my-power-of-attorneys-overview/MyPowerOfAttorneysOverview';
import ConnectedDataProtection from './pages/data-protection/DataProtection';
import ConnectedDataImpressum from './pages/data-impressum/DataImpressum';
import ConnectedNotifications from './pages/notifications/Notifications';
import ConnectedConversations from './pages/conversations/Conversations';
import ConnectedDemandsAndPayouts from './pages/demands-and-payouts/DemandsAndPayouts';
import ConnectedSingleConversation from './pages/single-conversation/SingleConversation';
import ConnectedError403 from './pages/error403/Error403';
import ConnectedRepaymentConditions from './pages/repayment-conditions/RepaymentConditions';
import ConnectedForbearanceRequest from './pages/forbearance-request/ForbearanceRequest';
import ConnectedLoanContracts from './pages/loan-contracts/LoanContracts';
import ConnectedOverview from './pages/admin-portal/overview/Overview';
import ConnectedPersonDetails from './pages/admin-portal/person-details/PersonDetails';
import ConnectedNewPermission from './pages/admin-portal/new-permission/NewPermission';
import ConnectedErrorUnavailableInformation from './pages/error-unavailable-information/ErrorUnavailableInformation';
import ConnectedSettings from './pages/settings/Settings';
import { history } from './store/configureStore';
import { TranslationLabelsAction, TranslationLabelsGetAllAction } from './store/i18n/actions';
import { EducationDataAction } from './store/education-data/actions';

export interface AppProps {
  labelsLoaded: boolean;
  dispatch?: (action: Action) => void;
}

export const App = (props: AppProps): ReactElement => {
  const isDesktopDevice = useMediaQuery('(min-width: 800px)');

  useEffect(() => {
    if (props.dispatch) {
      const loadAllLabelsAction: TranslationLabelsGetAllAction = { type: TranslationLabelsAction.GET_ALL };
      props.dispatch(loadAllLabelsAction);
      props.dispatch({
        type: EducationDataAction.GET_INSTITUTION_TYPES,
      });
    }
  }, []); // the loading of labels is done here just once for the whole app

  if (!props.labelsLoaded) {
    return <></>;
  }

  return (
    <Router history={history}>
      <Switch>
        <Route
          path="/scholarship/:dossierId/demand/:demandId/pif/:pifId"
          render={(demandProps) =>
            isDesktopDevice ? (
              <>
                <ConnectedDemandOverview {...demandProps}></ConnectedDemandOverview>
              </>
            ) : (
              <ConnectedDemandOverviewMobile {...demandProps}></ConnectedDemandOverviewMobile>
            )
          }
        ></Route>
        <Route path="/dashboard/dossier/:dossierId">
          <ConnectedDashboard></ConnectedDashboard>
        </Route>
        <Route
          path="/identification/firstname/:firstName/lastname/:lastName"
          render={(identificationProps) => (
            <>
              <div style={{ paddingTop: 30, paddingBottom: 30, paddingLeft: 10, paddingRight: 10 }}>
                <ConnectedIdentification {...identificationProps}></ConnectedIdentification>
              </div>
            </>
          )}
        />
        <Route
          path="/holder/:dossierId"
          render={(scholarshipHolderProps) =>
            isDesktopDevice ? (
              <>
                <ConnectedScholarshipHolder {...scholarshipHolderProps}></ConnectedScholarshipHolder>
              </>
            ) : (
              <>
                <ConnectedScholarshipHolderMobile {...scholarshipHolderProps}></ConnectedScholarshipHolderMobile>
              </>
            )
          }
        ></Route>
        <Route path="/my-data/dossier-intranet/mydata/:dossierId">
          <ConnectedMyDataOverview {...props}></ConnectedMyDataOverview>
        </Route>
        <Route path="/edit-contact-data/dossier-intranet/mydata/:dossierId">
          <ConnectedEditContactData {...props}></ConnectedEditContactData>
        </Route>
        <Route path="/edit-personal-data/dossier-intranet/mydata/:dossierId">
          <ConnectedEditPersonalData {...props}></ConnectedEditPersonalData>
        </Route>
        <Route path="/edit-address-data/dossier-intranet/mydata/:dossierId">
          <ConnectedEditAddressData {...props}></ConnectedEditAddressData>
        </Route>
        <Route path="/edit-payment-data/dossier-intranet/mydata/:dossierId">
          <ConnectedEditPayoutAddressData {...props}></ConnectedEditPayoutAddressData>
        </Route>
        <Route path="/edit-interrupted-education/:dossierId">
          <ConnectedEducationInterrupted {...props}></ConnectedEducationInterrupted>
        </Route>
        <Route path="/edit-complete-education/:dossierId">
          <ConnectedEducationCompleted {...props}></ConnectedEducationCompleted>
        </Route>
        <Route path="/edit-change-education/:dossierId">
          <ConnectedEducationChanged {...props}></ConnectedEducationChanged>
        </Route>
        <Route path="/edit-canceled-education/:dossierId">
          <ConnectedEducationCanceled {...props}></ConnectedEducationCanceled>
        </Route>
        <Route path="/dossier-intranet/myformation/:dossierId">
          <ConnectedMyEducationsOverview {...props}></ConnectedMyEducationsOverview>
        </Route>
        <Route path="/my-data/dossier-intranet/mydemand/:dossierId">
          <ConnectedDemandsAndPayouts {...props}></ConnectedDemandsAndPayouts>
        </Route>
        <Route path="/my-power-of-attorney-overview/:dossierId">
          <ConnectedMyPowerOfAttorneysOverview {...props}></ConnectedMyPowerOfAttorneysOverview>
        </Route>
        <Route path="/repayment-conditions/:dossierId/:foreignKeyId">
          <ConnectedRepaymentConditions {...props}></ConnectedRepaymentConditions>
        </Route>
        <Route path="/forbearance-request/:dossierId/:contractId">
          <ConnectedForbearanceRequest {...props}></ConnectedForbearanceRequest>
        </Route>
        <Route path="/my-contracts/:dossierId">
          <ConnectedLoanContracts {...props}></ConnectedLoanContracts>
        </Route>
        <Route path="/data-protection">
          <ConnectedDataProtection {...props}></ConnectedDataProtection>
        </Route>
        <Route path="/data-impressum">
          <ConnectedDataImpressum {...props}></ConnectedDataImpressum>
        </Route>
        <Route path="/admin-portal/user/:id/details">
          <ConnectedPersonDetails {...props}></ConnectedPersonDetails>
        </Route>
        <Route path="/admin-portal/user/:userId/new-permission">
          <ConnectedNewPermission {...props}></ConnectedNewPermission>
        </Route>
        <Route path="/admin-portal">
          <ConnectedOverview {...props}></ConnectedOverview>
        </Route>
        <Route
          path="/login"
          render={() => {
            if (process.env.REACT_APP_LOGIN_URL) {
              window.location.href = process.env.REACT_APP_LOGIN_URL;
            } else {
              return <h1>LOGIN_URL not configured!</h1>;
            }
            return null;
          }}
        />
        <Route
          path="/logout"
          render={() => {
            if (process.env.REACT_APP_LOGOUT_URL) {
              window.location.href = process.env.REACT_APP_LOGOUT_URL;
            } else {
              return <h1>LOGOUT_URL not configured!</h1>;
            }
            return null;
          }}
        />
        <Route exact path="/mandatory-power-of-attorney">
          <>
            <ConnectedMandatoryPowerOfAttorney></ConnectedMandatoryPowerOfAttorney>
          </>
        </Route>
        <Route exact path="/identification-error">
          <ConnectedIdentificationError></ConnectedIdentificationError>
        </Route>
        <Route exact path="/notifications/:dossierId">
          <ConnectedNotifications {...props}></ConnectedNotifications>
        </Route>
        <Route exact path="/messages/:dossierId/:caseId">
          <ConnectedSingleConversation {...props}></ConnectedSingleConversation>
        </Route>
        <Route exact path="/messages/:dossierId">
          <ConnectedConversations {...props}></ConnectedConversations>
        </Route>
        <Route exact path="/error-access-forbidden">
          <ConnectedError403></ConnectedError403>
        </Route>
        <Route exact path="/error-unavailable-information/:dossierId">
          <ConnectedErrorUnavailableInformation {...props}></ConnectedErrorUnavailableInformation>
        </Route>
        <Route exact path="/settings/:dossierId">
          <ConnectedSettings {...props}></ConnectedSettings>
        </Route>
        <Route
          path="/"
          render={() =>
            isDesktopDevice ? (
              <>
                <ConnectedHomepage></ConnectedHomepage>
              </>
            ) : (
              <>
                <ConnectedHomepageMobile></ConnectedHomepageMobile>
              </>
            )
          }
        ></Route>
      </Switch>
    </Router>
  );
};
