import { Observable, of } from 'rxjs';
import { Action } from 'redux';
import { Epic, ofType } from 'redux-observable';
import { HolderDataAction, HolderDataGetResultAction } from './actions';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { AjaxResponse } from 'rxjs/ajax';
import { handleRequestFailureInEpic } from '../../utils/error-handling';
import { customAjax } from '../../utils/ajax-wrapper';

const addHolderData = (payload: AjaxResponse): HolderDataGetResultAction => {
  return {
    type: HolderDataAction.GET_RESULT,
    payload: payload.response,
  };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const triggerHolderDataError = (error: any): any => {
  if (error.status == 404) {
    return {
      type: HolderDataAction.GET_ERROR,
      payload: 'Cannot get holder data',
    };
  }

  return handleRequestFailureInEpic(error);
};

export const getHolderDataEpic: Epic<Action, Action> = (action$: Observable<Action>): Observable<Action> =>
  action$.pipe(
    ofType(HolderDataAction.GET),
    mergeMap(() => {
      return customAjax({
        subRoute: `/partner/v1/dossier-profiles`,
        method: 'GET',
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        },
      }).pipe(
        map((response) => addHolderData(response)),
        catchError((error) => {
          return of(triggerHolderDataError(error));
        }),
      );
    }),
  );
