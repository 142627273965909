import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import ConnectedApp from './ConnectedApp';
import { Provider } from 'react-redux';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider } from '@material-ui/core';
import theme from './theme';
import { configureStore } from './store/configureStore';
import { PersistGate } from 'redux-persist/integration/react';

const { store, persistor } = configureStore();

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ConnectedApp />
      </PersistGate>
    </Provider>
  </ThemeProvider>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
