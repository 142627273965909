import {
  Breadcrumbs,
  Button,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  Link,
  Modal,
  withStyles,
} from '@material-ui/core';
import { produce } from 'immer';
import React, { ReactElement, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';
import ZoomFooter, {
  mapStoreToFooterProps,
  ZoomFooterProps,
} from '../../components/base-page-components/footer/ZoomFooter';
import ZoomHeader, {
  mapStoreToHeaderProps,
  ZoomHeaderProps,
} from '../../components/base-page-components/header/ZoomHeader';
import { ZoomProgressBar } from '../../components/base-page-components/progress-bar/ZoomProgressBar';
import { ZoomArrowLeftIcon } from '../../components/icons/ZoomArrowLeftIcon';
import { ZoomCheckCircleIcon } from '../../components/icons/ZoomCheckCircleIcon';
import { ZoomWarningIcon } from '../../components/icons/ZoomWarningIcon';
import { CompleteDemandAction, DemandOverviewAction, DemandOverviewActionCloseTab } from '../../store/demand/actions';
import { filterNavigationOrder } from '../../store/demand/utils';
import { i18nLabel } from '../../store/i18n/helpers';
import {
  LastDemandDataTakeoverGetAction,
  LastDemandDataTakeoverAction,
} from '../../store/last-demand-data-takeover/actions';
import { ParentsDataAction, ParentsDataGetAction } from '../../store/parents-data/actions';
import { PersonalDataAction, PersonalDataActionType } from '../../store/personal-data/actions';
import { RoutingAction, RoutingRedirectToAction } from '../../store/routing/actions';
import { WIAAStore } from '../../store/types';
import { isPersonUnder18 } from '../../utils/date-manipulation';
import { ZoomArrowRightIcon } from './../../components/icons/ZoomArrowRightIcon';
import { ZoomCrossIcon } from './../../components/icons/ZoomCrossIcon';
import { ZoomPlusIcon } from './../../components/icons/ZoomPlusIcon';
import { getCategoryIcon } from './../../store/demand/helpers';
import { black, blue, lightGrey, white } from './../../theme';
import './DemandOverview.scss';
import { getTabProgress, loadTab } from './helpers';
import { ContentTabKey, DemandOverviewProps, initialContentTabState, NavigationForm, NavigationTab } from './types';

const CompleteRequestButton = withStyles({
  root: {
    background: lightGrey.main,
    border: 1,
    borderStyle: 'solid',
    borderColor: white.main,
    color: black.main,
    fontSize: 15,
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.4,
    letterSpacing: 'normal',
    textAlign: 'center',
  },
})(Button);

export const DemandOverview = (props: DemandOverviewProps): ReactElement => {
  const [activeTab, setActiveTab] = useState(props.activeTab ? props.activeTab : 0);
  const [contentTabState, setContentTabState] = useState(
    props.contentTabState ? props.contentTabState : initialContentTabState,
  );

  const [contentTabProgress, setContentTabProgress] = useState(
    props.contentTabProgress ? props.contentTabProgress : initialContentTabState,
  );

  const history = useHistory();

  const [openFirstAcknowlegde, setOpenFirstAcknowledge] = useState(false);
  const [openSecondAcknowledge, setOpenSecondAcknowledge] = useState(false);
  const [openErrorAcknowledge, setOpenErrorAcknowledge] = useState(false);

  useEffect(() => {
    loadTab('education', props);
    if (props.dispatch) {
      const getLastDemandData: LastDemandDataTakeoverGetAction = {
        type: LastDemandDataTakeoverAction.GET,
        payload: props.dossierId,
      };
      props.dispatch(getLastDemandData);
    }
  }, []);

  useEffect(() => {
    if (props.dispatch && props.initialDataLoaded) {
      const loadPersonalDataAction: PersonalDataActionType = {
        type: PersonalDataAction.GET,
        demandId: props.demandId,
        pifId: props.pifId,
      };
      props.dispatch(loadPersonalDataAction);
      const getParentsData: ParentsDataGetAction = {
        type: ParentsDataAction.GET,
        payload: {},
        pifId: props.pifId,
      };
      props.dispatch(getParentsData);
      props.dispatch({ type: DemandOverviewAction.COMPONENT_OPENED });
    }
  }, [props.initialDataLoaded]);

  useEffect(() => {
    setContentTabState(props.contentTabState ? props.contentTabState : initialContentTabState);
    setContentTabProgress(props.contentTabProgress ? props.contentTabProgress : initialContentTabState);
  }, [props]);

  const openSecondAcknowledgeModal = () => {
    setOpenFirstAcknowledge(false);
    setOpenSecondAcknowledge(true);
  };

  const closeTab = (tab: ContentTabKey) => {
    const newState = produce(contentTabState, (draft) => {
      draft[tab] = false;
    });
    setContentTabState(newState);

    if (props.dispatch) {
      const closeTabAction: DemandOverviewActionCloseTab = { type: DemandOverviewAction.CLOSE_TAB, payload: tab };
      props.dispatch(closeTabAction);
    }
  };

  const openNavigationTab = (tab: NavigationTab, index: number) => {
    setActiveTab(index);
    setContentTabState(initialContentTabState);
    loadTab(tab.forms[0].form, props);
  };

  const getTabElementLabel = (tab: NavigationTab): string => {
    switch (tab.tab) {
      case 'scholarship':
        return props.tabLabelScholarshipData;
      case 'pia':
        return props.tabLabelPifData;
      case 'parents':
        return props.tabLabelParentsData;
      case 'partner':
        return props.tabLabelPartnerData;
      case 'children':
        return props.tabLabelChildrenData;
      case 'siblings':
        return props.tabLabelSiblingsData;
      case 'payout':
        return props.tabLabelPayoutData;
      case 'documents':
        return props.tabLabelAttachments;
      case 'notes':
        return props.contentLabelNotes;
    }
    return '';
  };

  const getTabElement = (tab: NavigationTab, index: number): ReactElement => {
    return (
      <div
        key={index}
        className={activeTab === index ? 'main-page-content-navigation-tab-active' : 'main-page-content-navigation-tab'}
        onClick={() => {
          openNavigationTab(tab, index);
        }}
      >
        {getCategoryIcon(tab.tab, false, true, getTabProgress(tab.tab, props.tabProgress))}
        <p>{getTabElementLabel(tab)}</p>
        {activeTab !== index && (
          <ZoomArrowRightIcon className="tab-element-arrow-icon" viewBox="0 0 80 80"></ZoomArrowRightIcon>
        )}
      </div>
    );
  };

  const getTabContentLabel = (form: NavigationForm): string => {
    switch (form.form) {
      case 'education':
        return props.contentLabelEducation;
      case 'request':
        return props.contentLabelRequest;
      case 'personalData':
        return props.contentLabelPersonalData;
      case 'contactData':
        return props.contentLabelContactData;
      case 'cv':
        return props.contentLabelCv;
      case 'income':
        return props.contentLabelIncome;
      case 'expenses':
        return props.contentLabelExpenses;
      case 'parents':
        return props.contentLabelParents;
      case 'father':
        return props.contentLabelFather;
      case 'mother':
        return props.contentLabelMother;
      case 'guardian':
        return props.contentLabelGuardian;
      case 'partner':
        return props.contentLabelPartner;
      case 'children':
        return props.contentLabelChildren;
      case 'siblings':
        return props.contentLabelSiblings;
      case 'payout':
        return props.contentLabelPayout;
      case 'documents':
        return props.contentLabelDocuments;
      case 'notes':
        return props.contentLabelNotes;
    }
    return '';
  };

  const getTabContent = (tab: NavigationTab) => {
    const filteredForms = tab.forms.filter((form) => form.hidden !== true);
    return filteredForms.map((form: NavigationForm, index: number) => {
      return (
        <div key={index}>
          <div className="main-page-content-tab-control">
            <h2>
              {getTabContentLabel(form)}{' '}
              <small style={{ fontSize: 14, fontWeight: 'lighter' }}>
                {index + 1}/{filteredForms.length}
              </small>
            </h2>
            {!contentTabState[form.form] && !contentTabProgress[form.form] && (
              <IconButton
                color="primary"
                aria-label={form.label}
                onClick={() => {
                  loadTab(form.form, props);
                }}
              >
                <ZoomPlusIcon htmlColor={blue.main} fontSize="large" viewBox="0 0 80 80" />
              </IconButton>
            )}

            {contentTabState[form.form] && !contentTabProgress[form.form] && (
              <IconButton
                color="primary"
                aria-label={form.label}
                onClick={() => {
                  closeTab(form.form);
                }}
              >
                <ZoomCrossIcon htmlColor={blue.main} fontSize="large" viewBox="0 0 80 80" />
              </IconButton>
            )}

            {contentTabProgress[form.form] && <CircularProgress />}
          </div>

          {contentTabState[form.form] && <div className="main-page-content-tab-content">{form.component}</div>}
        </div>
      );
    });
  };

  const navigationTabElements = filterNavigationOrder(props.orderedTabs).tabs.map((tab: NavigationTab, index: number) =>
    getTabElement(tab, index),
  );

  const tabContents = getTabContent(filterNavigationOrder(props.orderedTabs).tabs[activeTab]);

  const submitDemand = () => {
    if (props.piaUnder18) {
      setOpenErrorAcknowledge(true);
    } else {
      if (props.dispatch) {
        const completeDemandAction: CompleteDemandAction = {
          type: DemandOverviewAction.COMPLETE_DEMAND,
          payload: { dossierId: props.dossierId, demandId: props.demandId },
        };
        props.dispatch(completeDemandAction);
      }
    }
  };

  const closeSecondAcknowledgeAndResetError = () => {
    setOpenSecondAcknowledge(false);
    if (props.dispatch) {
      props.dispatch({ type: DemandOverviewAction.CLEAR_DEMAND_ERROR });
    }
  };

  const goToDashboard = () => {
    if (props.dispatch) {
      const redirectAction: RoutingRedirectToAction = {
        type: RoutingAction.REDIRECT_TO,
        payload: '/dashboard/dossier/' + props.dossierId,
      };
      props.dispatch(redirectAction);
    }
  };

  const isFinalCompleteDemandButtonDisabled = props.demandCompletionError !== undefined;

  return (
    <>
      <ZoomHeader {...props.headerProps} dispatch={props.dispatch}></ZoomHeader>
      <main className="main">
        <Container>
          <div id="main-page-container" className="partial">
            <Breadcrumbs separator=">" aria-label="breadcrumb">
              <Link
                style={{ fontFamily: 'Proxima Nova Cond' }}
                color="inherit"
                href={'/dashboard/dossier/' + props.dossierId}
              >
                <b>{props.breadcrumbLabels && props.breadcrumbLabels[0]}</b>
              </Link>
              <Link style={{ fontFamily: 'Proxima Nova Cond' }} color="inherit">
                <>{props.breadcrumbLabels && props.breadcrumbLabels[1]}</>
              </Link>
            </Breadcrumbs>
            <div className="back-button-desktop" onClick={() => history.goBack()}>
              <ZoomArrowLeftIcon viewBox="0 0 80 80" fontSize="large" htmlColor={blue.main}></ZoomArrowLeftIcon>
              <span className="back-button">{props.labelBackArrow}</span>
            </div>
            <div>
              <h1 className="title-desktop">{props.title}</h1>
            </div>
          </div>
          <Grid container id="dark-page-container">
            <Grid item sm={9}>
              <ZoomProgressBar
                id="global-progress-bar"
                labelFinished={
                  props.labelGlobalProgressReady ? props.labelGlobalProgressReady : 'Super, alles ausgefüllt!'
                }
                progressPercentage={props.globalProgress ? props.globalProgress : 10}
              />
            </Grid>
            <Grid item sm={3}>
              <div className="demand-status-and-completion">
                <CompleteRequestButton variant="contained" size="large" onClick={() => setOpenFirstAcknowledge(true)}>
                  {props.labelCompleteDemand}
                </CompleteRequestButton>
              </div>
            </Grid>
            <Grid item sm={12} md={4}>
              {navigationTabElements}
            </Grid>
            <Grid item sm={12} md={8} className="main-page-content-tab-container">
              {tabContents}
            </Grid>
          </Grid>

          <Modal
            open={openFirstAcknowlegde}
            aria-labelledby="demand-complete-acknowledge"
            aria-describedby="demand-complete-acknowledge-description"
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          >
            <div className="demand-modal-desktop">
              <h1 id="demand-complete-acknowledge">{props.contentLabelFirstAcknowledgementTitle}</h1>
              <div id="demand-complete-acknowledge-description">
                <p>{props.contentLabelFirstAcknowledgementParagraph1}</p>
                <p>{props.contentLabelFirstAcknowledgementParagraph2}</p>
              </div>

              <div className="demand-modal-desktop-actions">
                <Button color="primary" variant="outlined" fullWidth onClick={() => setOpenFirstAcknowledge(false)}>
                  {props.contentLabelModalCancel}
                </Button>
                <Button color="primary" variant="contained" fullWidth onClick={() => openSecondAcknowledgeModal()}>
                  {props.contentLabelFirstAcknowledgementSubmit}
                </Button>
              </div>
            </div>
          </Modal>

          <Modal
            open={openSecondAcknowledge}
            aria-labelledby="demand-complete-acknowledge-two"
            aria-describedby="demand-complete-acknowledge-two-description"
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          >
            <div className="demand-modal-desktop">
              <h1 id="demand-complete-acknowledge-two">{props.contentLabelSecondAcknowledgementTitle}</h1>
              <div id="demand-complete-acknowledge-two-description">
                <p dangerouslySetInnerHTML={{ __html: props.contentLabelSecondAcknowledgementParagraph1 }}></p>
                <p dangerouslySetInnerHTML={{ __html: props.contentLabelSecondAcknowledgementParagraph2 }}></p>
              </div>

              {props.demandCompletionError && (
                <div className="demand-error-message">
                  <ZoomWarningIcon htmlColor={white.main} viewBox="0 0 80 80"></ZoomWarningIcon>
                  <span>{props.demandCompletionError}</span>
                </div>
              )}

              {!props.inProgress && (
                <div className="demand-modal-desktop-actions">
                  <Button
                    color="primary"
                    variant="outlined"
                    fullWidth
                    onClick={() => closeSecondAcknowledgeAndResetError()}
                  >
                    {props.contentLabelModalCancel}
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    disabled={isFinalCompleteDemandButtonDisabled}
                    fullWidth
                    onClick={() => submitDemand()}
                  >
                    {props.contentLabelSecondAcknowledgementSubmit}
                  </Button>
                </div>
              )}
              {!!props.inProgress && (
                <div className="demand-modal-desktop-progress">
                  <CircularProgress></CircularProgress>
                </div>
              )}
            </div>
          </Modal>

          <Modal
            open={openErrorAcknowledge}
            aria-labelledby="demand-complete-error"
            aria-describedby="demand-complete-error"
            onClose={() => {
              setOpenErrorAcknowledge(false);
              setOpenSecondAcknowledge(false);
            }}
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          >
            <div className="demand-modal-desktop">
              <h1 id="demand-complete-acknowledge-two">{props.contentLabelCompleteDemandErrorTitle}</h1>
              <div id="demand-complete-acknowledge-two-description">
                <p>{props.contentLabelCompleteDemandErrorParagraph1}</p>
                <p>{props.contentLabelCompleteDemandErrorParagraph2}</p>
              </div>
            </div>
          </Modal>

          <Modal
            open={!!props.completed}
            aria-labelledby="demand-complete-success"
            aria-describedby="demand-complete-success"
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          >
            <div className="demand-modal-desktop">
              <h1>{props.completionTitle}</h1>
              <div id="demand-complete-success">
                <div>
                  <ZoomCheckCircleIcon
                    id="demand-complete-success-icon"
                    viewBox="0 0 80 80"
                    htmlColor={white.main}
                  ></ZoomCheckCircleIcon>
                </div>
                <div>
                  <p dangerouslySetInnerHTML={{ __html: props.completionParagraph1 }}></p>
                  <p>{props.completionParagraph2}</p>
                  <p>{props.completionParagraph3}</p>
                </div>
              </div>
              <div className="demand-modal-desktop-actions">
                <Button variant="contained" color="primary" fullWidth onClick={goToDashboard}>
                  {props.labelBackToDashboard}
                </Button>
              </div>
            </div>
          </Modal>
        </Container>
      </main>
      <ZoomFooter {...props.footerProps}></ZoomFooter>
    </>
  );
};

// Map global store to component parameters
// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export function mapStateToDemandOverviewProps(store: WIAAStore, ownProps: any): DemandOverviewProps {
  const { demandId, pifId, dossierId } = ownProps.match.params;
  const headerProps: ZoomHeaderProps = mapStoreToHeaderProps(store, dossierId);
  const footerProps: ZoomFooterProps = mapStoreToFooterProps(store);

  return {
    ...store.demandData,
    headerProps: headerProps,
    footerProps: footerProps,
    title: i18nLabel(store.translationLabels, 'Demand_Overview_Title', 'Stipendienantrag'),
    labelNext: i18nLabel(store.translationLabels, 'Demand_Overview_Next', 'Danach'),
    labelCompleteDemand: i18nLabel(
      store.translationLabels,
      'Demand_Overview_Complete_Demand',
      'Antrag jetzt abschliessen',
    ),
    labelSavedSuccessfully: i18nLabel(
      store.translationLabels,
      'Demand_Overview_Saved_Successfully',
      'Speichern erfolgreich!',
    ),
    tabLabelScholarshipData: i18nLabel(
      store.translationLabels,
      'Demand_Overview_Scholarship_Data_Tab',
      'Angaben zum Stipendium',
    ),
    tabLabelPifData: i18nLabel(store.translationLabels, 'Demand_Overview_Pif_Data_Tab', 'Angaben zum Antragsteller'),
    tabLabelParentsData: i18nLabel(
      store.translationLabels,
      'Demand_Overview_Parents_Data_Tab',
      'Angaben zu den Eltern',
    ),
    tabLabelPartnerData: i18nLabel(store.translationLabels, 'Demand_Overview_Partner_Data_Tab', 'Angaben zum Partner'),
    tabLabelChildrenData: i18nLabel(
      store.translationLabels,
      'Demand_Overview_Children_Data_Tab',
      'Angaben zu den Kindern',
    ),
    tabLabelAdditionalData: i18nLabel(store.translationLabels, 'Demand_Overview_Additional_Data_Tab', ''),
    tabLabelSiblingsData: i18nLabel(
      store.translationLabels,
      'Demand_Overview_Siblings_Data_Tab',
      'Angaben zu den Geschwistern',
    ),
    tabLabelAttachments: i18nLabel(
      store.translationLabels,
      'Demand_Overview_Attachments_Tab',
      'Einzureichende Dokumente',
    ),
    tabLabelPayoutData: i18nLabel(store.translationLabels, 'Demand_Overview_Payout_Data_Tab', 'Angaben zur Auszahlung'),
    contentLabelPersonalData: i18nLabel(
      store.translationLabels,
      'Demand_Overview_Personal_Data_Content',
      'Personalien',
    ),
    contentLabelChildren: i18nLabel(store.translationLabels, 'Demand_Overview_Children_Content', 'Kinder'),
    contentLabelCv: i18nLabel(store.translationLabels, 'Demand_Overview_CV_Content', 'Lebenslauf'),
    contentLabelContactData: i18nLabel(store.translationLabels, 'Demand_Overview_Contact_Data_Content', 'Kontakt'),
    contentLabelIncome: i18nLabel(store.translationLabels, 'Demand_Overview_Income_Content', 'Einnahmen'),
    contentLabelExpenses: i18nLabel(store.translationLabels, 'Demand_Overview_Expenses_Content', 'Ausgaben'),
    contentLabelLoan: i18nLabel(store.translationLabels, 'Demand_Overview_Loan_Content', 'Darlehen'),
    contentLabelPayout: i18nLabel(store.translationLabels, 'Demand_Overview_Payout_Content', 'Auszahlung'),
    contentLabelEducation: i18nLabel(store.translationLabels, 'Demand_Overview_Education_Content', 'Ausbildung'),
    contentLabelPartner: i18nLabel(store.translationLabels, 'Demand_Overview_Partner_Content', 'Partner'),
    contentLabelRequest: i18nLabel(store.translationLabels, 'Demand_Overview_Request_Content', 'Gesuch'),
    contentLabelParents: i18nLabel(store.translationLabels, 'Demand_Overview_Parents_Content', 'Eltern'),
    contentLabelFather: i18nLabel(store.translationLabels, 'Demand_Overview_Father_Content', 'Vater'),
    contentLabelMother: i18nLabel(store.translationLabels, 'Demand_Overview_Mother_Content', 'Mutter'),
    contentLabelGuardian: i18nLabel(store.translationLabels, 'Demand_Overview_Guardian_Content', 'Vormund/Beistand'),
    contentLabelSiblings: i18nLabel(store.translationLabels, 'Demand_Overview_Siblings_Content', 'Geschwister'),
    contentLabelDocuments: i18nLabel(store.translationLabels, 'Demand_Overview_Documents_Content', 'Dokumente'),
    contentLabelNotes: i18nLabel(store.translationLabels, 'Demand_Overview_Notes_Content', 'Bemerkungen'),
    contentLabelFirstAcknowledgementTitle: i18nLabel(
      store.translationLabels,
      'Demand_Overview_First_Acknowledgement_Title',
      'Letzter Check - Stimmen Die Daten?',
    ),
    contentLabelFirstAcknowledgementParagraph1: i18nLabel(
      store.translationLabels,
      'Demand_Overview_First_Acknowledgement_Paragraph_1',
      'Mit dem Absenden des Formulars bestätigen Sie, dass das Formular korrekt ausgefüllt wurde. Nachträgliche Änderungen sind nur noch via Kundensupport möglich.',
    ),
    contentLabelFirstAcknowledgementParagraph2: i18nLabel(
      store.translationLabels,
      'Demand_Overview_First_Acknowledgement_Paragraph_2',
      'Achtung: Der Antrag ist erst gültig nach Einreichung Ihrer Unterschrift. Bitte folgen Sie den Anweisungen.',
    ),
    contentLabelFirstAcknowledgementSubmit: i18nLabel(
      store.translationLabels,
      'Demand_Overview_First_Acknowledgement_Submit',
      'Antrag absenden',
    ),
    contentLabelSecondAcknowledgementTitle: i18nLabel(
      store.translationLabels,
      'Demand_Overview_Second_Acknowledgement_Title',
      'Bestätigung Kenntnissnahme',
    ),
    contentLabelSecondAcknowledgementParagraph1: i18nLabel(
      store.translationLabels,
      'Demand_Overview_Second_Acknowledgement_Paragraph_1',
      'Ich bestätige, dass die Angaben im elektronisch übermittelten Stipendiengesuch vollständig und wahrheitsgetreu sind.',
    ),
    contentLabelSecondAcknowledgementParagraph2: i18nLabel(
      store.translationLabels,
      'Demand_Overview_Second_Acknowledgement_Paragraph_2',
      'Ich nehme zur Kenntnis, dass die Sektion Stipendien über einen Wechsel, Abbruch oder Unterbruch der Ausbildung (zum Beispiel wegen Krankheit) umgehend schriftlich informiert werden muss',
    ),
    contentLabelSecondAcknowledgementSubmit: i18nLabel(
      store.translationLabels,
      'Demand_Overview_Second_Acknowledgement_Submit',
      'Bestätigung / Kenntnisnahme',
    ),
    contentLabelModalCancel: i18nLabel(store.translationLabels, 'Demand_Overview_Modal_Cancel', 'Abbrechen'),
    contentLabelCompleteDemandErrorTitle: i18nLabel(
      store.translationLabels,
      'Demand_Overview_Complete_Demand_Error_Title',
      'Achtung!',
    ),
    contentLabelCompleteDemandErrorParagraph1: i18nLabel(
      store.translationLabels,
      'Demand_Overview_Complete_Demand_Error_Paragraph_1',
      'Nur die von Ihnen bevollmächtigte Person kann den Antrag für Sie abschliessen. Dazu kann sich die bevollmäctigte Person im Stipendienportal einloggen.',
    ),
    contentLabelCompleteDemandErrorParagraph2: i18nLabel(
      store.translationLabels,
      'Demand_Overview_Complete_Demand_Error_Paragraph_2',
      '',
    ),
    mobileTabsTitleLabel: i18nLabel(store.translationLabels, 'Demand_Overview_Mobile_Tabs_Title', 'Antragsseiten'),
    labelBackArrow: i18nLabel(store.translationLabels, 'Demand_Overview_Back_Arrow', 'zurück'),
    breadcrumbLabels: [
      i18nLabel(store.translationLabels, 'Demand_Overview_Breadcrumb_Label_1', 'Übersicht'),
      i18nLabel(store.translationLabels, 'Demand_Overview_Breadcrumb_Label_2', 'Stipendienantrag'),
    ],
    demandCompletionError: store.demandData.demandCompletionError
      ? i18nLabel(
          store.translationLabels,
          store.demandData.demandCompletionError,
          store.demandData.demandCompletionError,
        )
      : undefined,
    dossierId,
    demandId,
    pifId,
    piaUnder18: isPersonUnder18(store.userData.birthDate),
    completionParagraph1: i18nLabel(
      store.translationLabels,
      'Demand_Overview_Completion_Paragraph1',
      'Ihr Antrag wurde erfolgreich eingereicht. <b>Achtung</b>: Bitte reichen Sie keinen weiteren Antrag für dieses Ausbildungsjahr ein.',
    ),
    completionParagraph2: i18nLabel(
      store.translationLabels,
      'Demand_Overview_Completion_Paragraph2',
      'Falls wir noch weitere Informationen oder Unterlagen von Ihnen benötigen, werden wir uns mit Ihnen in Kontakt setzen.',
    ),
    completionParagraph3: i18nLabel(
      store.translationLabels,
      'Demand_Overview_Completion_Paragraph3',
      'Der Stand der Gesuchsbearbeitung wird auf unserer Homepage publiziert und wöchentlich angepasst.',
    ),
    labelBackToDashboard: i18nLabel(
      store.translationLabels,
      'Demand_Overview_Back_To_Dashboard',
      'Zurück zum Dashboard',
    ),
    completionTitle: i18nLabel(
      store.translationLabels,
      'Demand_Overview_Success_Title',
      'Antrag erfolgreich abgeschlossen',
    ),
    tabProgress: store.demandData.demandProgress ? store.demandData.demandProgress.tabProgress : [],
    globalProgress: store.demandData.demandProgress ? store.demandData.demandProgress.globalPercentage : 10,
    labelGlobalProgressReady: i18nLabel(
      store.translationLabels,
      'Demand_Overview_Global_Progress_Complete',
      'Super, alles ausgefüllt!',
    ),
    initialDataLoaded: store.demandData.initialDataLoaded,
  };
}

// default export the connected component
export default withRouter(connect(mapStateToDemandOverviewProps)(DemandOverview));
