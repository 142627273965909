import produce from 'immer';
import { MyContractsAction, MyContractsActionType } from './actions';
import { MyContractsDataStore } from './types';

export const initialState: MyContractsDataStore = {
  myContracts: [],
};

export function myContractsReducer(state = initialState, action: MyContractsActionType): MyContractsDataStore {
  return produce(state, (draft) => {
    switch (action.type) {
      case MyContractsAction.GET_ALL_CONTRACTS:
        draft.isLoading = true;
        break;
      case MyContractsAction.RESULT_ALL_CONTRACTS:
        draft.myContracts = action.payload;
        break;
      case MyContractsAction.GET_ALL_CONTRACTS_RESULT:
        draft.myContracts = action.payload;
        draft.isLoading = false;
        break;
      case MyContractsAction.GET_SINGLE_CONTRACT:
        const getSingleContractAction = action;
        draft.isLoading = true;
        if (draft.myContracts) {
          const contractId = draft.myContracts.findIndex(
            (contract) => contract.id === getSingleContractAction.contractId,
          );
          if (contractId !== undefined && contractId > -1 && draft.myContracts) {
            draft.myContracts[contractId].isLoading = true;
          }
        }
        break;
      case MyContractsAction.GET_SINGLE_CONTRACT_RESULT:
        const resultSingleContract = action;
        const newContract = action.payload;
        newContract.isLoading = false;
        if (resultSingleContract.payload) {
          const contractToBeUpdatedIndex = draft.myContracts?.findIndex((contract) => contract.id === newContract.id);
          if (draft.myContracts && contractToBeUpdatedIndex !== undefined && contractToBeUpdatedIndex > -1) {
            draft.myContracts[contractToBeUpdatedIndex] = newContract;
          }
        }
        draft.isLoading = false;
        break;
    }
    return draft;
  });
}
