import produce from 'immer';
import {
  NewPermissionDataAction,
  NewPermissionActionType,
  NewPermissionSearchUsersGetResultAction,
  NewPermissionAddResultAction,
} from './actions';
import { NewPermissionDataStore } from './types';

export const initialState: NewPermissionDataStore = {
  users: [],
};

export function newPermissionDataReducer(
  state = initialState,
  action: NewPermissionActionType,
): NewPermissionDataStore {
  return produce(state, (draft) => {
    switch (action.type) {
      case NewPermissionDataAction.SEARCH_USERS_GET:
        draft.isLoading = true;
        break;
      case NewPermissionDataAction.SEARCH_USERS_GET_RESULT:
        draft.users = (action as NewPermissionSearchUsersGetResultAction).payload;
        draft.isLoading = false;
        break;
      case NewPermissionDataAction.ADD_RESULT:
        draft.permission = (action as NewPermissionAddResultAction).payload;
        break;
    }
    return draft;
  });
}
