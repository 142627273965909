import { Observable } from 'rxjs';
import { Epic, ofType } from 'redux-observable';
import { Action } from 'redux';
import {
  IdentificationDataAction,
  IdentificationDataSubmitAction,
  IdentificationErrorAction,
  IdentificationSuccessAction,
} from './actions';
import { catchError, debounceTime, map, mergeMap } from 'rxjs/operators';
import { IdentificationData } from './types';
import { handleRequestFailureInEpic } from '../../utils/error-handling';
import { customAjax } from '../../utils/ajax-wrapper';
import moment from 'moment';
import { ErrorHandlingActionType } from '../error-handling/actions';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const handleIdentificationError = (error: any): IdentificationErrorAction | ErrorHandlingActionType => {
  if (error.status == 400 && error.response?.ExceptionType == 2) {
    return {
      type: IdentificationDataAction.ERROR,
      payload: 'Identification_AHV_Birth_Date_Geres_ErrorMessage',
    };
  }
  if (error.status == 409) {
    return {
      type: IdentificationDataAction.ERROR,
      payload: 'Identification_AHVConflict_ErrorMessage',
    };
  }
  if (error.status == 500) {
    return {
      type: IdentificationDataAction.ERROR,
      payload: 'Unknown_ErrorMessage',
    };
  }

  return handleRequestFailureInEpic(error);
};

/***
 * setting identification success data
 * @param dossierId
 */
const setIdentificationSuccessData = (dossierId: number, isUnder18: boolean): IdentificationSuccessAction => {
  return {
    type: IdentificationDataAction.IDENTIFICATION_SUCCESS,
    dossierId,
    isUnder18,
  };
};

/***
 * Submit identification data
 * If success, then the new dossier id is returned
 * @param action$
 * @param store
 * @param dependencies
 */
export const submitIdentificationDataEpic: Epic<Action, Action> = (action$: Observable<Action>): Observable<Action> =>
  action$.pipe(
    ofType(IdentificationDataAction.SUBMIT),
    mergeMap((action) => {
      const identificationData: IdentificationData = (action as IdentificationDataSubmitAction).payload;
      return customAjax({
        subRoute: '/partner/v1/account',
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        withCredentials: true,
        body: JSON.stringify(identificationData),
      }).pipe(
        map((ajaxResponse) => {
          const { dossierId } = ajaxResponse.response;

          if (!dossierId) {
            throw new Error('An error occurred, the dossier id is null');
          }
          const birthDateMoment = moment(identificationData.birthDate);
          const isUnder18 = moment().subtract(18, 'years').isBefore(birthDateMoment);
          return setIdentificationSuccessData(dossierId, isUnder18);
        }),
        catchError(async (error) => handleIdentificationError(error)),
      );
    }),
  );

export const identificationTriggerErrorHideEpic: Epic<Action, Action> = (
  action$: Observable<Action>,
): Observable<Action> =>
  action$.pipe(
    ofType(IdentificationDataAction.ERROR),
    debounceTime(5000),
    map(() => {
      return { type: IdentificationDataAction.RESET_ERROR };
    }),
  );
