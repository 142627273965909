import { Breadcrumbs, Card, CardContent, CircularProgress, Container, Grid, Link } from '@material-ui/core';
import React, { ReactElement, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';
import { Action } from 'redux';
import ZoomFooter, {
  mapStoreToFooterProps,
  ZoomFooterProps,
} from '../../components/base-page-components/footer/ZoomFooter';
import ZoomHeader, {
  mapStoreToHeaderProps,
  ZoomHeaderProps,
} from '../../components/base-page-components/header/ZoomHeader';
import { ZoomArrowLeftIcon } from '../../components/icons/ZoomArrowLeftIcon';
import { mapEducationStateToProps } from '../../containers/education-form/ConnectedEducationDataForm';
import { EducationDataForm } from '../../containers/education-form/EducationDataForm';
import { EducationDataFormProps } from '../../containers/education-form/EducationDataFormProps';
import { EducationChangeAction, EducationDataAction } from '../../store/education-data/actions';
import { i18nLabel } from '../../store/i18n/helpers';
import { WIAAStore } from '../../store/types';
import { UserDataAction, UserMyEducationDataGetAction } from '../../store/user-data/actions';
import { blue } from '../../theme';
import { NotificationCaseType, getDefaultSelectOption } from '../../utils/form-data';
import './EducationChanged.scss';

export interface EducationChangedLabels {
  labelTitle?: string;
  labelDescription?: string;
  labelActualEducationSubTitle?: string;
  labelEducationType?: string;
  labelEducationFacilityName?: string;
  labelEducationFacilityLocation?: string;
  labelDuration?: string;
  labelNewEducationSubTitle?: string;
  labelEducationTypeRequired?: string;
  labelEducationFacilityNameRequired?: string;
  labelEducationFacilityLocationRequired?: string;
  labelEducationDegreeNameRequired?: string;
  labelDurationRequired?: string;
  labelEducationChangeReasonRequired?: string;
  helpTextEducationType?: string;
  helpTextEducationFacilityLocation?: string;
  helpTextEducationDuration?: string;
  breadcrumbLabels?: Array<string>;
  headerProps: ZoomHeaderProps;
  footerProps: ZoomFooterProps;
  labelBackArrow?: string;
  labelCancel?: string;
  labelSave?: string;
  isLoading?: boolean;
  errorLabels?: { [key: string]: string };
}

export interface EducationChangedProps extends EducationChangedLabels {
  dossierId: number;
  oldEducationType?: string;
  oldEducationFacilityName?: string;
  oldEducationFacilityLocation?: string;
  oldEducationDurationStart?: string;
  oldEducationDurationEnd?: string;
  newEducationType?: string;
  newEducationFacilityName?: string;
  newEducationFacilityLocation?: string;
  newEducationDurationStart?: Date;
  newEducationDurationEnd?: Date;
  newEducationDegreeName?: string;
  educationChangeReason?: string;
  educationForeignKeyId?: number;
  formationType?: { name: string; value: string }[];
  educationDataFormProps?: EducationDataFormProps;
  dispatch?: (action: Action) => void;
}

export const EducationChanged = (props: EducationChangedProps): ReactElement => {
  const history = useHistory();

  useEffect(() => {
    if (props.dispatch) {
      const getUserMyEducationData: UserMyEducationDataGetAction = {
        type: UserDataAction.GET_USER_EDUCATION_DATA,
        payload: props.dossierId,
      };
      props.dispatch(getUserMyEducationData);
    }
  }, []);

  const changeAction: EducationChangeAction = {
    type: EducationDataAction.EDUCATION_CHANGE,
    payload: {
      typeId: NotificationCaseType.FORMATIONCHANGE,
      subject: 'CHANGE REQUEST',
      foreignKeyId: props.educationForeignKeyId,
    },
    dossierId: props.dossierId,
  };

  const educationDataFormProps = props.educationDataFormProps
    ? {
        ...props.educationDataFormProps,
        dispatch: props.dispatch,
        submitChangeAction: changeAction,
        labelEducationChangeReasonRequired: props.labelEducationChangeReasonRequired,
        labelCancel: props.labelCancel,
        labelSaveChangeEducation: props.labelSave,
      }
    : undefined;
  if (educationDataFormProps) {
    educationDataFormProps.isEducationChangeForm = true;
  }
  return (
    <>
      <ZoomHeader {...props.headerProps} dispatch={props.dispatch} />
      <main className="main">
        {props.isLoading ? (
          <div style={{ textAlign: 'center' }}>
            <CircularProgress></CircularProgress>
          </div>
        ) : (
          <>
            <Container>
              <div id="main-page-container">
                <Breadcrumbs separator=">" aria-label="breadcrumb">
                  <Link
                    style={{ fontFamily: 'Proxima Nova Cond' }}
                    color="inherit"
                    href={'/dashboard/dossier/' + props.dossierId}
                  >
                    {props.breadcrumbLabels && props.breadcrumbLabels[0]}
                  </Link>
                  <Link
                    style={{ fontFamily: 'Proxima Nova Cond' }}
                    color="inherit"
                    href={'/dossier-intranet/myformation/' + props.dossierId}
                  >
                    {props.breadcrumbLabels && props.breadcrumbLabels[1]}
                  </Link>
                  <Link style={{ fontFamily: 'Proxima Nova Cond' }} color="inherit">
                    <b>{props.breadcrumbLabels && props.breadcrumbLabels[2]}</b>
                  </Link>
                </Breadcrumbs>
                <div className="main-page-info">
                  <div className="back-button-desktop" onClick={() => history.goBack()}>
                    <ZoomArrowLeftIcon viewBox="0 0 80 80" fontSize="large" htmlColor={blue.main} />
                    {props.labelBackArrow}
                  </div>
                  <h1 className="title-desktop">{props.labelTitle}</h1>
                  <p>{props.labelDescription}</p>
                </div>
                <Card>
                  <CardContent>
                    <div className="label-element-container">
                      <h1>{props.labelActualEducationSubTitle}</h1>
                    </div>
                    <div className="label-element-container">
                      <Grid container direction="row" alignItems="stretch" justify="flex-start">
                        <Grid item xs={12} sm={6}>
                          <div className="label-bottom-border">
                            <label>{props.labelEducationType ? props.labelEducationType : ''}</label>
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <div className="label-bottom-border">
                            <label>{props.oldEducationType}</label>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                    <div className="label-element-container">
                      <Grid container direction="row" alignItems="stretch" justify="flex-start">
                        <Grid item xs={12} sm={6}>
                          <div className="label-bottom-border">
                            <label>{props.labelEducationFacilityName ? props.labelEducationFacilityName : ''}</label>
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <div className="label-bottom-border">
                            <label>{props.oldEducationFacilityName}</label>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                    <div className="label-element-container">
                      <Grid container direction="row" alignItems="stretch" justify="flex-start">
                        <Grid item xs={12} sm={6}>
                          <div className="label-bottom-border">
                            <label>
                              {props.labelEducationFacilityLocation ? props.labelEducationFacilityLocation : ''}
                            </label>
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <div className="label-bottom-border">
                            <label>{props.oldEducationFacilityLocation}</label>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                    <div className="label-element-container">
                      <Grid container direction="row" alignItems="stretch" justify="flex-start">
                        <Grid item xs={12} sm={6}>
                          <div className="label-bottom-border">
                            <label>{props.labelDuration ? props.labelDuration : ''}</label>
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <div className="label-bottom-border">
                            <label>{`${props.oldEducationDurationStart} - ${props.oldEducationDurationEnd}`}</label>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  </CardContent>
                </Card>
                <Card style={{ marginTop: '20px' }}>
                  <CardContent>
                    <div className="form-container">
                      <h1>{props.labelNewEducationSubTitle}</h1>
                    </div>
                    {educationDataFormProps ? (
                      <EducationDataForm {...educationDataFormProps}></EducationDataForm>
                    ) : (
                      <p>No education data form properties!</p>
                    )}
                  </CardContent>
                </Card>
              </div>
            </Container>
          </>
        )}
      </main>
      <ZoomFooter {...props.footerProps} />
    </>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export function mapStateToProps(store: WIAAStore, urlProps: any): EducationChangedProps {
  const { dossierId } = urlProps.match.params;
  const headerProps: ZoomHeaderProps = mapStoreToHeaderProps(store, dossierId);
  const footerProps: ZoomFooterProps = mapStoreToFooterProps(store);

  const currentFormation = store.userMyEducationData.formations?.find((f) => f.currentFormation);

  const educationDataFormProps = mapEducationStateToProps(store, urlProps);
  return {
    dossierId,
    headerProps,
    footerProps,
    labelTitle: i18nLabel(store.translationLabels, 'Education_Changed_Data_Title', 'Ausbildung gewechselt'),
    labelDescription: i18nLabel(
      store.translationLabels,
      'Education_Changed_Data_Description',
      'Falls Sie Ihre Ausbildung während dem Ausbildungsjahr gewechselt haben, bitten wir Sie, uns dies mitzuteilen. Bitte teilen Sie uns mit, weshalb und wann Sie die Ausbildung gewechselt haben.',
    ),
    labelActualEducationSubTitle: i18nLabel(
      store.translationLabels,
      'Education_Changed_Data_Current_Education_Sub_Title',
      'Aktuelle Ausbildung',
    ),
    labelNewEducationSubTitle: i18nLabel(
      store.translationLabels,
      'Education_Changed_Data_New_Education_Sub_Title',
      'Neue Ausbildung',
    ),
    labelEducationType: i18nLabel(store.translationLabels, 'Education_Changed_Data_Type', 'Art der Ausbildung'),
    labelEducationFacilityName: i18nLabel(
      store.translationLabels,
      'Education_Changed_Data_Facility_Name',
      'Name der Ausbildungsstätte',
    ),
    labelEducationFacilityLocation: i18nLabel(
      store.translationLabels,
      'Education_Changed_Data_Facility_Location',
      'Ort der Ausbildungsstätte',
    ),
    labelDuration: i18nLabel(store.translationLabels, 'Education_Changed_Data_Duration', 'Ausbildungsdauer'),
    labelEducationTypeRequired: i18nLabel(
      store.translationLabels,
      'Education_Changed_Data_Type_Required',
      'Art der Ausbildung*',
    ),
    labelEducationFacilityNameRequired: i18nLabel(
      store.translationLabels,
      'Education_Changed_Data_Facility_Name_Required',
      'Name der Ausbildungsstätte*',
    ),
    labelEducationFacilityLocationRequired: i18nLabel(
      store.translationLabels,
      'Education_Changed_Data_Facility_Location_Required',
      'Ort der Ausbildungsstätte*',
    ),
    labelDurationRequired: i18nLabel(
      store.translationLabels,
      'Education_Changed_Data_Duration_Required',
      'Ausbildungsdauer*',
    ),
    labelEducationDegreeNameRequired: i18nLabel(
      store.translationLabels,
      'Education_Changed_Data_Degree_Name_Required',
      'Bezeichnung des Abschluss*',
    ),
    labelEducationChangeReasonRequired: i18nLabel(
      store.translationLabels,
      'Education_Changed_Data_Change_Reason_Required',
      'Grund für den Wechsel*',
    ),
    helpTextEducationType: i18nLabel(
      store.translationLabels,
      'Education_Changed_Data_Education_Type_Help_Text',
      'Der Vorkurs ist nur beitragsberechtigt, wenn er für Ihr Studium vorausgesetzt wird.',
    ),
    helpTextEducationFacilityLocation: i18nLabel(
      store.translationLabels,
      'Education_Changed_Data_Education_Facility_Location_Help_Text',
      'Falls Sie Ihre Ausbildung an mehreren Orten absolvieren, bitte sämtliche Orte angeben.',
    ),
    helpTextEducationDuration: i18nLabel(
      store.translationLabels,
      'Education_Changed_Data_Education_Duration_Help_Text',
      'Als Ausbildungsbildungsbeginn an Universitäten, ETH und Fachhochschulen gilt der Monat des Vorlesungsstarts.',
    ),
    labelBackArrow: i18nLabel(store.translationLabels, 'Education_Changed_Data_Back_Arrow', 'Zurück'),
    labelCancel: i18nLabel(store.translationLabels, 'Education_Changed_Data_Cancel', 'Abbrechen'),
    labelSave: i18nLabel(store.translationLabels, 'Education_Changed_Data_Save', 'Absenden'),
    breadcrumbLabels: [
      i18nLabel(store.translationLabels, 'Education_Changed_Data_Breadcrumb_Label_1', 'Übersicht'),
      i18nLabel(store.translationLabels, 'Education_Changed_Data_Breadcrumb_Label_2', 'Meine Ausbildung'),
      i18nLabel(store.translationLabels, 'Education_Changed_Data_Breadcrumb_Label_3', 'Ausbildung gewechselt'),
    ],
    errorLabels: store.translationLabels,
    oldEducationType: currentFormation?.formationArt ? currentFormation.formationArt : '',
    oldEducationFacilityName: currentFormation?.formationInstitution,
    oldEducationFacilityLocation: currentFormation?.formationLocation,
    oldEducationDurationStart: currentFormation?.formationStartDate ? currentFormation.formationStartDate : undefined,
    oldEducationDurationEnd: currentFormation?.formationEndDate ? currentFormation.formationEndDate : undefined,
    educationForeignKeyId: currentFormation?.formationDbKey,
    isLoading: store.userMyEducationData.isLoading,
    formationType: store.translationOptions.formationtypes
      ? [
          { value: '0', name: getDefaultSelectOption(store.translationLabels) },
          ...store.translationOptions.formationtypes.map((option) => {
            return { value: option.id.toString(), name: option.name };
          }),
        ]
      : [],
    educationDataFormProps: educationDataFormProps,
  };
}

export default withRouter(connect(mapStateToProps)(EducationChanged));
