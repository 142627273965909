import { Button } from '@material-ui/core';
import React, { ChangeEvent, ReactElement, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ZoomMultipleTextInput from '../../components/forms/multiple-text-input/ZoomMultipleTextInput';
import ZoomSelectInput from '../../components/forms/select-input/ZoomSelectInput';
import ZoomTextInput from '../../components/forms/text-input/ZoomTextInput';
import { GuardianDataAction, GuardianDataActionType } from '../../store/guardian-data/actions';
import { GuardianData } from '../../store/guardian-data/types';
import { i18nLabel } from '../../store/i18n/helpers';
import { WIAAStore } from '../../store/types';
import { requiredValidation } from '../../utils/validators';

export interface GuardianDataFormProps {
  id?: string;
  guardianship?: string;
  guardianshipFirstName?: string;
  guardianshipLastName?: string;
  guardianshipStreet?: string;
  guardianshipStreetNumber?: string;
  guardianshipPostalCode?: string;
  guardianshipCity?: string;
  guardianshipCountry?: string;
  labelGuardianship?: string;
  labelGuardianshipFirstName?: string;
  labelGuardianshipLastName?: string;
  labelStreet?: string;
  labelStreetNumber?: string;
  labelGuardianshipPostalCode?: string;
  labelGuardianshipCity?: string;
  labelGuardianshipCountry?: string;
  helpTextGuardianship?: string;
  helpTextGuardianshipStreetAndNumber?: string;
  helpTextGuardianshipPostalCode?: string;
  helpTextGuardianshipCity?: string;
  helpTextGuardianshipCountry?: string;
  labelSave?: string;
  demandId: number;
  pifId: number;
  countryOptions?: { name: string; value: string }[];
  errorLabels?: { [key: string]: string };
  dispatch?: (action: GuardianDataActionType) => void;
}

interface GuardianDataErrors {
  guardianship?: string;
  guardianshipFirstName?: string;
  guardianshipLastName?: string;
  guardianshipStreet?: string;
  guardianshipStreetNumber?: string;
  guardianshipPostalCode?: string;
  guardianshipCity?: string;
  guardianshipCountry?: string;
}

export const GuardianDataForm = (props: GuardianDataFormProps): ReactElement => {
  const [guardianship, setGuardianship] = useState(props.guardianship ? props.guardianship : '');
  const [guardianshipFirstName, setGuardianshipFirstName] = useState(
    props.guardianshipFirstName ? props.guardianshipFirstName : '',
  );
  const [guardianshipLastName, setGuardianshipLastName] = useState(
    props.guardianshipLastName ? props.guardianshipLastName : '',
  );
  const [guardianshipStreet, setGuardianshipStreet] = useState(
    props.guardianshipStreet ? props.guardianshipStreet : '',
  );
  const [guardianshipStreetNumber, setGuardianshipStreetNumber] = useState(
    props.guardianshipStreetNumber ? props.guardianshipStreetNumber : '',
  );
  const [guardianshipPostalCode, setGuardianshipPostalCode] = useState(
    props.guardianshipPostalCode ? props.guardianshipPostalCode : '',
  );
  const [guardianshipCity, setGuardianshipCity] = useState(props.guardianshipCity ? props.guardianshipCity : '');
  const [guardianshipCountry, setGuardianshipCountry] = useState(
    props.guardianshipCountry ? props.guardianshipCountry : '8100',
  );
  const initialErrors: GuardianDataErrors = {};
  const [errors, setErrors] = useState(initialErrors);

  useEffect(() => {
    if (errors.guardianshipCity && props.guardianshipCity !== '' && guardianshipCity === '') {
      setErrors({ ...errors, guardianshipCity: '' });
    }
    setGuardianship(props.guardianship ? props.guardianship : '');
    setGuardianshipFirstName(props.guardianshipFirstName ? props.guardianshipFirstName : '');
    setGuardianshipLastName(props.guardianshipLastName ? props.guardianshipLastName : '');
    setGuardianshipStreet(props.guardianshipStreet ? props.guardianshipStreet : '');
    setGuardianshipStreetNumber(props.guardianshipStreetNumber ? props.guardianshipStreetNumber : '');
    setGuardianshipPostalCode(props.guardianshipPostalCode ? props.guardianshipPostalCode : '');
    setGuardianshipCity(props.guardianshipCity ? props.guardianshipCity : '');
    setGuardianshipCountry(props.guardianshipCountry ? props.guardianshipCountry : '8100');
  }, [props]);

  const onChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    switch (e.target.name) {
      case 'guardianship':
        setGuardianship(e.target.value);
        setErrors({
          ...errors,
          guardianship: requiredValidation(e.target.value, props.errorLabels ? props.errorLabels : {}),
        });
        break;
      case 'guardianshipFirstName':
        setGuardianshipFirstName(e.target.value);
        setErrors({
          ...errors,
          guardianshipFirstName: requiredValidation(e.target.value, props.errorLabels ? props.errorLabels : {}),
        });
        break;
      case 'guardianshipLastName':
        setGuardianshipLastName(e.target.value);
        setErrors({
          ...errors,
          guardianshipLastName: requiredValidation(e.target.value, props.errorLabels ? props.errorLabels : {}),
        });
        break;
      case 'guardianshipStreet':
        setGuardianshipStreet(e.target.value);
        setErrors({
          ...errors,
          guardianshipStreet: requiredValidation(e.target.value, props.errorLabels ? props.errorLabels : {}),
        });
        break;
      case 'guardianshipStreetNumber':
        setGuardianshipStreetNumber(e.target.value);
        setErrors({
          ...errors,
          guardianshipStreetNumber: requiredValidation(e.target.value, props.errorLabels ? props.errorLabels : {}),
        });
        break;
      case 'guardianshipPostalCode':
        setGuardianshipPostalCode(e.target.value);
        if (e.target.value.length === 4) {
          if (props.dispatch) {
            const enteredZipCode = e.target.value;
            const oldData = getGuardianData();
            props.dispatch({
              type: GuardianDataAction.GET_VORMUND_POSTAL_CODES,
              payload: { ...oldData, zipCode: enteredZipCode },
            });
          }
        }
        setErrors({
          ...errors,
          guardianshipPostalCode: requiredValidation(e.target.value, props.errorLabels ? props.errorLabels : {}),
        });
        break;
      case 'guardianshipCity':
        setGuardianshipCity(e.target.value);
        setErrors({
          ...errors,
          guardianshipCity: requiredValidation(e.target.value, props.errorLabels ? props.errorLabels : {}),
        });
        break;
    }
  };

  const onChangeGuardianshipCountry = (newValue: string) => {
    setGuardianshipCountry(newValue);
  };

  const getGuardianData = (): GuardianData => {
    return {
      guardianShipFunction: guardianship,
      firstName: guardianshipFirstName,
      lastName: guardianshipLastName,
      address: guardianshipStreet,
      houseNumber: guardianshipStreetNumber,
      zipCode: guardianshipPostalCode,
      town: guardianshipCity,
      countryId: parseInt(guardianshipCountry),
    };
  };

  const getFormErrors = (): GuardianDataErrors => {
    const errors: GuardianDataErrors = {};
    errors.guardianship = requiredValidation(guardianship, props.errorLabels ? props.errorLabels : {});
    errors.guardianshipFirstName = requiredValidation(
      guardianshipFirstName,
      props.errorLabels ? props.errorLabels : {},
    );
    errors.guardianshipLastName = requiredValidation(guardianshipLastName, props.errorLabels ? props.errorLabels : {});
    errors.guardianshipStreet = requiredValidation(guardianshipStreet, props.errorLabels ? props.errorLabels : {});
    errors.guardianshipStreetNumber = requiredValidation(
      guardianshipStreetNumber,
      props.errorLabels ? props.errorLabels : {},
    );
    errors.guardianshipPostalCode = requiredValidation(
      guardianshipPostalCode,
      props.errorLabels ? props.errorLabels : {},
    );
    errors.guardianshipCity = requiredValidation(guardianshipCity, props.errorLabels ? props.errorLabels : {});
    errors.guardianshipCountry = requiredValidation(guardianshipCountry, props.errorLabels ? props.errorLabels : {});
    return errors;
  };

  const onMultipleTextFieldChange = (index: number, event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    onChange(event);
  };

  const validateForm = (): boolean => {
    const newErrors = getFormErrors();
    setErrors(newErrors);
    const errorsPresent = Object.values(newErrors).some((value) => value !== '');
    return !errorsPresent;
  };

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    if (validateForm() && props.dispatch) {
      props.dispatch({ type: GuardianDataAction.SUBMIT, payload: getGuardianData(), pifId: props.pifId });
    }
    validateForm();
    event.preventDefault();
  };

  return (
    <>
      <form onSubmit={onSubmit}>
        <div>
          <div className="form-element-container">
            <ZoomTextInput
              label={props.labelGuardianship ? props.labelGuardianship : ''}
              name="guardianship"
              value={guardianship}
              onChange={onChange}
              helpText={props.helpTextGuardianship}
              error={!!errors.guardianship}
              errorMessage={errors.guardianship}
            ></ZoomTextInput>
          </div>
          <div className="form-element-container">
            <ZoomTextInput
              label={props.labelGuardianshipFirstName ? props.labelGuardianshipFirstName : ''}
              name="guardianshipFirstName"
              value={guardianshipFirstName}
              onChange={onChange}
              error={!!errors.guardianshipFirstName}
              errorMessage={errors.guardianshipFirstName}
            ></ZoomTextInput>
          </div>
          <div className="form-element-container">
            <ZoomTextInput
              label={props.labelGuardianshipLastName ? props.labelGuardianshipLastName : ''}
              name="guardianshipLastName"
              value={guardianshipLastName}
              onChange={onChange}
              error={!!errors.guardianshipLastName}
              errorMessage={errors.guardianshipLastName}
            ></ZoomTextInput>
          </div>
          <div className="form-element-container">
            <ZoomMultipleTextInput
              data={[
                {
                  label: props.labelStreet ? props.labelStreet : '',
                  value: guardianshipStreet,
                  gridSize: 9,
                  name: 'guardianshipStreet',
                  error: !!errors.guardianshipStreet,
                  errorMessage: errors.guardianshipStreet,
                  onChange: onMultipleTextFieldChange,
                },
                {
                  label: props.labelStreetNumber ? props.labelStreetNumber : '',
                  value: guardianshipStreetNumber,
                  gridSize: 3,
                  name: 'guardianshipStreetNumber',
                  error: !!errors.guardianshipStreetNumber,
                  errorMessage: errors.guardianshipStreetNumber,
                  onChange: onMultipleTextFieldChange,
                },
              ]}
              helpText={props.helpTextGuardianshipStreetAndNumber}
            ></ZoomMultipleTextInput>
          </div>
          <div className="form-element-container">
            <ZoomTextInput
              label={props.labelGuardianshipPostalCode ? props.labelGuardianshipPostalCode : ''}
              name="guardianshipPostalCode"
              value={guardianshipPostalCode}
              onChange={onChange}
              helpText={props.helpTextGuardianshipPostalCode}
              error={!!errors.guardianshipPostalCode}
              errorMessage={errors.guardianshipPostalCode}
            ></ZoomTextInput>
          </div>
          <div className="form-element-container">
            <ZoomTextInput
              label={props.labelGuardianshipCity ? props.labelGuardianshipCity : ''}
              name="guardianshipCity"
              value={guardianshipCity}
              onChange={onChange}
              helpText={props.helpTextGuardianshipCity}
              error={!!errors.guardianshipCity}
              errorMessage={errors.guardianshipCity}
            ></ZoomTextInput>
          </div>
          <div className="form-element-container">
            <ZoomSelectInput
              name="guardianshipCountry"
              value={guardianshipCountry}
              label={props.labelGuardianshipCountry ? props.labelGuardianshipCountry : ''}
              options={props.countryOptions ? props.countryOptions : []}
              onChange={onChangeGuardianshipCountry}
              helpText={props.helpTextGuardianshipCountry}
            ></ZoomSelectInput>
          </div>
        </div>
        <div className="form-actions-container">
          <Button color="primary" type="submit" variant="contained" id="submit-guardian-data">
            {props.labelSave}
          </Button>
        </div>
      </form>
    </>
  );
};

// Map global store to component parameters
// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export function mapStateToProps(store: WIAAStore, ownProps: any): GuardianDataFormProps {
  const { demandId } = ownProps.match.params;
  const { pifId } = ownProps.match.params;
  const props: GuardianDataFormProps = {
    labelGuardianship: i18nLabel(
      store.translationLabels,
      'Guardianship_Guardianship',
      'Vormundschaft / Beistandschaft*',
    ),
    labelGuardianshipFirstName: i18nLabel(store.translationLabels, 'Guardianship_First_Name', 'Vorname*'),
    labelGuardianshipLastName: i18nLabel(store.translationLabels, 'Guardianship_Last_Name', 'Nachname*'),
    labelStreet: i18nLabel(store.translationLabels, 'Guardianship_Street', 'Strasse'),
    labelStreetNumber: i18nLabel(store.translationLabels, 'Guardianship_Street_Number', 'Nr.*'),
    labelGuardianshipPostalCode: i18nLabel(store.translationLabels, 'Guardianship_Postal_Code', 'PLZ*'),
    labelGuardianshipCity: i18nLabel(store.translationLabels, 'Guardianship_City', 'Ort*'),
    labelGuardianshipCountry: i18nLabel(store.translationLabels, 'Guardianship_Country', 'Land*'),
    labelSave: i18nLabel(store.translationLabels, 'Guardianship_Save', 'Speichern'),
    helpTextGuardianship: i18nLabel(
      store.translationLabels,
      'Guardianship_Guardianship_Help_Text',
      'Welche Funktion hat Ihr Vormund/Beistand? z.B. Vormund / umfassende Beistandschaft / Begleitbeistandschaft etc.',
    ),
    helpTextGuardianshipStreetAndNumber: i18nLabel(
      store.translationLabels,
      'Guardianship_Street_And_Number_Help_Text',
      'Wenn Strasse nicht bekannt, "unbekannt" eingeben.',
    ),
    helpTextGuardianshipPostalCode: i18nLabel(
      store.translationLabels,
      'Guardianship_Postal_Code_Help_Text',
      'Wenn PLZ nicht bekannt, dann "?" eingeben.',
    ),
    helpTextGuardianshipCity: i18nLabel(
      store.translationLabels,
      'Guardianship_City_Help_Text',
      'Wenn Ort nicht bekannt, dann "unbekannt" angeben.',
    ),
    helpTextGuardianshipCountry: i18nLabel(
      store.translationLabels,
      'Guardianship_Country_Help_Text',
      'Bitte geben Sie den Wohnsitzstaat Ihrer Vormundschafts- Beistandschaftsbehörde an.',
    ),
    errorLabels: store.translationLabels,
    demandId: Number(demandId),
    pifId: Number(pifId),
    countryOptions: store.translationOptions.nationalities
      ? [
          ...store.translationOptions.nationalities.map((option) => {
            return { value: option.id.toString(), name: option.name };
          }),
        ]
      : [],
  };

  const data = store.guardianData;
  if (data) {
    return {
      guardianship: data.guardianShipFunction,
      guardianshipFirstName: data.firstName,
      guardianshipLastName: data.lastName,
      guardianshipStreet: data.address,
      guardianshipStreetNumber: data.houseNumber,
      guardianshipPostalCode: data.zipCode,
      guardianshipCity: data.town,
      guardianshipCountry: data.countryId ? data.countryId.toString() : '',
      ...props,
    };
  }
  return props;
}

// default export the connected component
export default withRouter(connect(mapStateToProps)(GuardianDataForm));
