import { ChildrenDataAction, ChildrenDataGetAction } from '../../store/children-data/actions';
import { CVDataAction, CVDataGetCVAction } from '../../store/cv-data/actions';
import { DocumentsAction, DocumentsActionGetRoles } from '../../store/documents/actions';
import { EducationDataAction, EducationDataGetAction } from '../../store/education-data/actions';
import { FatherDataAction, FatherDataGetAction } from '../../store/father-data/actions';
import { GuardianDataAction, GuardianDataGetAction } from '../../store/guardian-data/actions';
import { IncomingDataAction, IncomingDataGetAction } from '../../store/incoming-data/actions';
import { MotherDataAction, MotherDataGetAction } from '../../store/mother-data/actions';
import { NotesDataAction, NotesDataGetAction } from '../../store/notes-data/actions';
import { OutgoingDataAction, OutgoingDataGetAction } from '../../store/outgoing-data/actions';
import { ParentsDataAction, ParentsDataGetAction } from '../../store/parents-data/actions';
import { PartnerDataAction, PartnerDataGetAction } from '../../store/partner-data/actions';
import { PayoutDataAction, PayoutDataGetAction } from '../../store/payout-data/actions';
import {
  ContactDataAction,
  ContactDataGetAction,
  PersonalDataAction,
  PersonalDataActionType,
} from '../../store/personal-data/actions';
import { ScholarshipDataAction, ScholarshipDataGetAction } from '../../store/scholarship-data/actions';
import { SiblingsDataAction, SiblingsDataGetAction } from '../../store/siblings-data/actions';
import { ContentTabKey, DemandOverviewProps, orderOfTabs, TabProgress } from './types';

export const loadTab = (tab: ContentTabKey, props: DemandOverviewProps): void => {
  if (tab === 'personalData') {
    if (props.dispatch) {
      const loadPersonalDataAction: PersonalDataActionType = {
        type: PersonalDataAction.GET,
        demandId: props.demandId,
        pifId: props.pifId,
      };
      props.dispatch(loadPersonalDataAction);
    }
  }

  if (tab === 'education') {
    if (props.dispatch) {
      const getEducationData: EducationDataGetAction = {
        type: EducationDataAction.GET,
        payload: {},
        demandId: props.demandId,
      };
      props.dispatch(getEducationData);
    }
  }

  if (tab === 'parents') {
    if (props.dispatch) {
      const getParentsData: ParentsDataGetAction = {
        type: ParentsDataAction.GET,
        payload: {},
        pifId: props.pifId,
      };
      props.dispatch(getParentsData);
    }
  }

  if (tab === 'father') {
    if (props.dispatch) {
      const getFatherData: FatherDataGetAction = {
        type: FatherDataAction.GET,
        payload: {},
        pifId: props.pifId,
      };
      props.dispatch(getFatherData);
    }
  }

  if (tab === 'mother') {
    if (props.dispatch) {
      const getMotherData: MotherDataGetAction = {
        type: MotherDataAction.GET,
        payload: {},
        pifId: props.pifId,
      };
      props.dispatch(getMotherData);
    }
  }

  if (tab === 'guardian') {
    if (props.dispatch) {
      const getGuardianData: GuardianDataGetAction = {
        type: GuardianDataAction.GET,
        payload: {},
        pifId: props.pifId,
      };
      props.dispatch(getGuardianData);
    }
  }

  if (tab === 'payout') {
    if (props.dispatch) {
      const getPayoutData: PayoutDataGetAction = {
        type: PayoutDataAction.GET,
        payload: {},
        demandId: props.demandId,
      };
      props.dispatch(getPayoutData);
    }
  }

  if (tab === 'contactData') {
    if (props.dispatch) {
      const getContactData: ContactDataGetAction = {
        type: ContactDataAction.GET,
        demandId: props.demandId,
      };
      props.dispatch(getContactData);
    }
  }

  if (tab === 'income') {
    if (props.dispatch) {
      const getIncomingsData: IncomingDataGetAction = {
        type: IncomingDataAction.GET,
        payload: {},
        demandId: props.demandId,
      };
      props.dispatch(getIncomingsData);
    }
  }

  if (tab === 'expenses') {
    if (props.dispatch) {
      const getOutgoingsData: OutgoingDataGetAction = {
        type: OutgoingDataAction.GET,
        payload: {},
        demandId: props.demandId,
      };
      props.dispatch(getOutgoingsData);
    }
  }

  if (tab === 'partner') {
    if (props.dispatch) {
      const getPartnerData: PartnerDataGetAction = {
        type: PartnerDataAction.GET,
        payload: {},
        pifId: props.pifId,
      };
      props.dispatch(getPartnerData);
    }
  }

  if (tab === 'children') {
    if (props.dispatch) {
      const getChildrenData: ChildrenDataGetAction = {
        type: ChildrenDataAction.GET,
        pifId: props.pifId,
      };
      props.dispatch(getChildrenData);
    }
  }

  if (tab === 'siblings') {
    if (props.dispatch) {
      const getSiblingsData: SiblingsDataGetAction = { type: SiblingsDataAction.GET, pifId: props.pifId };
      props.dispatch(getSiblingsData);
    }
  }

  if (tab === 'cv') {
    if (props.dispatch) {
      const getCVData: CVDataGetCVAction = {
        type: CVDataAction.GET,
        demandId: props.demandId,
      };
      props.dispatch(getCVData);
    }
  }

  if (tab === 'request') {
    if (props.dispatch) {
      const getScholarshipInfoData: ScholarshipDataGetAction = {
        type: ScholarshipDataAction.GET,
        payload: {},
        dossierId: props.dossierId,
      };
      props.dispatch(getScholarshipInfoData);
    }
  }

  if (tab === 'documents') {
    if (props.dispatch) {
      const getDocumentRolesAction: DocumentsActionGetRoles = {
        type: DocumentsAction.GET_ROLES,
        demandId: props.demandId,
      };
      props.dispatch(getDocumentRolesAction);
    }
  }

  if (tab === 'notes') {
    if (props.dispatch) {
      const getNotesAction: NotesDataGetAction = {
        type: NotesDataAction.GET,
        payload: props.demandId,
      };
      props.dispatch(getNotesAction);
    }
  }
};

export const getTabProgress = (tab: string, tabProgress: TabProgress[] | undefined): number => {
  if (tabProgress === undefined || tabProgress.length === 0) {
    return 0;
  }
  const indexOfSearchedTab = orderOfTabs.indexOf(tab);
  const tp = tabProgress.find((tp: TabProgress) => tp.id === indexOfSearchedTab);
  if (tp) {
    return tp.percentage;
  }
  return 0;
};
