import produce from 'immer';
import { OutgoingDataAction, OutgoingDataActionType } from './actions';
import { OutgoingData } from './types';

export const initialState: OutgoingData = {};

export function outgoingDataReducer(state = initialState, action: OutgoingDataActionType): OutgoingData {
  return produce(state, (draft) => {
    switch (action.type) {
      case OutgoingDataAction.RESULT:
        draft = action.payload;
        break;
      case OutgoingDataAction.GET:
        const getResult = action;
        if (getResult.payload) {
          draft = getResult.payload;
        }
        break;
      case OutgoingDataAction.GET_RESULT:
        draft = action.payload;
        break;
    }
    return draft;
  });
}
