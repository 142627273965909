import { Observable, of } from 'rxjs';
import { Epic, ofType } from 'redux-observable';
import {
  FinalizeUserAccountAction,
  FinalizeUserAccountSuccessAction,
  UserDataAction,
  UserDataCreateNewDemandAction,
  UserDataGetActiveDemandAction,
  UserDataGetResultAction,
  UserDataLaunchCreateNewDemandAction,
  UserDataSetActiveDemandAction,
  UserDataSetNewDemandAction,
  UserMyEducationDataGetAction,
  UserMyEducationDataGetResultAction,
  UserMyDataGetAction,
  UserMyDataGetResultAction,
  CanCreateNewDemandResultAction,
  UserDataSetNotificationSettingsAction,
  UserDataSetNotificationSettingsResultAction,
} from './actions';
import { Action } from 'redux';
import { map, mergeMap, catchError, concatMap } from 'rxjs/operators';
import { CanCreateNewDemandResult, DemandData, UserData } from './types';
import { handleRequestFailureInEpic } from '../../utils/error-handling';
import { customAjax } from '../../utils/ajax-wrapper';
import { UserDemandData } from '../../pages/dashboard/Dashboard';
import { AjaxResponse } from 'rxjs/ajax';
import { IdentificationDataAction, IdentificationSuccessAction } from '../identification-data/actions';
import { RoutingAction } from '../routing/actions';
import { DemandOverviewAction } from '../demand/actions';
import { createPatch } from 'rfc6902';

const setNewDemandInfo = (
  dossierId: number,
  demandId: number,
  demandData: UserDemandData,
): UserDataSetNewDemandAction => {
  return {
    type: UserDataAction.SET_NEW_DEMAND,
    demandId: demandId,
    dossierId: dossierId,
    payload: demandData,
  };
};

const createNewDemand = (dossierId: number, newDemand: DemandData): UserDataCreateNewDemandAction => {
  return {
    type: UserDataAction.CREATE_NEW_DEMAND,
    dossierId: dossierId,
    newDemand: newDemand,
  };
};

export const createNewDemandEpic: Epic<Action, Action> = (
  action$: Observable<Action>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  store: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dependencies: any,
): Observable<Action> => {
  return action$.pipe(
    ofType(UserDataAction.CREATE_NEW_DEMAND),
    mergeMap((action) => {
      const { dossierId } = action as UserDataCreateNewDemandAction;

      return customAjax({
        subRoute: `/dossier/v1/dossiers/${dossierId}/demands/dashboard-create`,
        withCredentials: true,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        //body: JSON.stringify(newDemand),
      }).pipe(
        map((ajaxResponse) => {
          const demandData = ajaxResponse.response as UserDemandData;
          const id = demandData.id ? demandData.id : 0;
          const pifId = demandData.pifId ? demandData.pifId : 0;
          dependencies.history.push(`/scholarship/${dossierId}/demand/${id}/pif/${pifId}`);
          return setNewDemandInfo(dossierId, id, demandData);
        }),
        catchError(async (error) => handleRequestFailureInEpic(error)),
      );
    }),
  );
};

export const launchCreateNewDemandEpic: Epic<Action, Action> = (action$: Observable<Action>): Observable<Action> => {
  return action$.pipe(
    ofType(UserDataAction.LAUNCH_CREATE_NEW_DEMAND),
    mergeMap((action) => {
      const { dossierId, newDemand } = action as UserDataLaunchCreateNewDemandAction;

      return customAjax({
        subRoute: `/dossier/v1/dossiers/${dossierId}/active-demand`,
        withCredentials: true,
        method: 'GET',
      }).pipe(
        concatMap((ajaxResponse) => {
          const demandId = (ajaxResponse.response as DemandData).id;
          return customAjax({
            subRoute: `/dossier/v1/dossiers/${dossierId}/demands/${demandId}/active-status?isActive=false`,
            withCredentials: true,
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
            },
          }).pipe(map(() => createNewDemand(dossierId, newDemand)));
        }),
        catchError((error) => {
          if (error.status == 404) {
            return of(createNewDemand(dossierId, newDemand));
          }
          return of(handleRequestFailureInEpic(error));
        }),
      );
    }),
  );
};

export const getActiveDemandEpic: Epic<Action, Action> = (action$: Observable<Action>): Observable<Action> => {
  return action$.pipe(
    ofType(UserDataAction.GET_ACTIVE_DEMAND),
    mergeMap((action) => {
      const dossierId = (action as UserDataGetActiveDemandAction).dossierId;

      return customAjax({
        subRoute: `/dossier/v1/dossiers/${dossierId}/active-demand`,
        withCredentials: true,
        method: 'GET',
      }).pipe(
        map((ajaxResponse) => {
          const demandData = ajaxResponse.response as UserDemandData;
          const id = demandData.id ? demandData.id : 0;
          return setNewDemandInfo(dossierId, id, demandData);
        }),
        catchError((error) => {
          return of(handleRequestFailureInEpic(error));
        }),
      );
    }),
  );
};

export const setActiveDemandEpic: Epic<Action, Action> = (action$: Observable<Action>): Observable<Action> => {
  return action$.pipe(
    ofType(UserDataAction.SET_ACTIVE_DEMAND),
    mergeMap((action) => {
      const setActiveDemandAction = action as UserDataSetActiveDemandAction;
      const dossierId = setActiveDemandAction.dossierId;
      const demandId = setActiveDemandAction.demandId;
      return customAjax({
        subRoute: `/dossier/v1/dossiers/${dossierId}/demands/${demandId}/active-status?isActive=true`,
        withCredentials: true,
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
      }).pipe(
        map((ajaxResponse) => {
          const demandData = ajaxResponse.response as UserDemandData;
          return setNewDemandInfo(dossierId, demandId, demandData);
        }),
        catchError((error) => {
          return of(handleRequestFailureInEpic(error));
        }),
      );
    }),
  );
};

const returnUserData = (userData: UserData): UserDataGetResultAction => {
  return {
    type: UserDataAction.GET_RESULT,
    payload: userData,
  };
};

export const getUserDataEpic: Epic<Action, Action> = (action$: Observable<Action>): Observable<Action> => {
  return action$.pipe(
    ofType(UserDataAction.GET),
    mergeMap(() => {
      return customAjax({
        subRoute: `/partner/v1/users/info`,
        withCredentials: true,
        method: 'GET',
      }).pipe(
        map((ajaxResponse) => {
          const userData = ajaxResponse.response as UserData;
          return returnUserData(userData);
        }),
        catchError((error) => {
          return of(handleRequestFailureInEpic(error));
        }),
      );
    }),
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getUserMyEducationDataOnError = (error: any): any => {
  if (error.status === 404) {
    return { type: UserDataAction.NON_EXISTENT_USER_EDUCATION_DATA };
  }
  return handleRequestFailureInEpic(error);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getUserMyDataOnError = (error: any): any => {
  if (error.status === 404) {
    return { type: UserDataAction.NON_EXISTENT_USER_MY_DATA };
  }
  return handleRequestFailureInEpic(error);
};

const addUserMyEducationData = (payload: AjaxResponse): UserMyEducationDataGetResultAction => {
  return {
    type: UserDataAction.GET_RESULT_USER_EDUCATION_DATA,
    payload: { ...payload.response },
  };
};

const addUserMyData = (payload: AjaxResponse): UserMyDataGetResultAction => {
  return {
    type: UserDataAction.GET_RESULT_USER_MY_DATA,
    payload: { ...payload.response },
  };
};

export const getUserMyEducationDataEpic: Epic<Action, Action> = (action$: Observable<Action>): Observable<Action> =>
  action$.pipe(
    ofType(UserDataAction.GET_USER_EDUCATION_DATA),
    mergeMap((action) => {
      const dossierId = (action as UserMyEducationDataGetAction).payload;
      return customAjax({
        subRoute: `/dossier-intranet/v1/myformation/${dossierId}`,
        withCredentials: true,
        method: 'GET',
      }).pipe(
        map((response) => addUserMyEducationData(response)),
        catchError((error) => {
          return of(getUserMyEducationDataOnError(error));
        }),
      );
    }),
  );

export const getUserMyDataEpic: Epic<Action, Action> = (action$: Observable<Action>): Observable<Action> =>
  action$.pipe(
    ofType(UserDataAction.GET_USER_MY_DATA),
    mergeMap((action) => {
      const dossierId = (action as UserMyDataGetAction).payload;
      return customAjax({
        subRoute: `/dossier-intranet/v1/mydata/${dossierId}`,
        withCredentials: true,
        method: 'GET',
      }).pipe(
        map((response) => addUserMyData(response)),
        catchError((error) => {
          return of(getUserMyDataOnError(error));
        }),
      );
    }),
  );

export const finalizeAccountAfterIdentificationEpic: Epic<Action, Action> = (
  action$: Observable<Action>,
): Observable<Action> => {
  return action$.pipe(
    ofType(IdentificationDataAction.IDENTIFICATION_SUCCESS),
    map((action) => {
      const identificationSuccessAction = action as IdentificationSuccessAction;
      const dossierId = identificationSuccessAction.dossierId;
      const isUnder18 = identificationSuccessAction.isUnder18;
      if (!isUnder18) {
        return { type: UserDataAction.FINALIZE_ACCOUNT, payload: dossierId };
      } else {
        return { type: RoutingAction.REDIRECT_TO, payload: '/mandatory-power-of-attorney' };
      }
    }),
  );
};

export const finalizeUserAccountEpic: Epic<Action, Action> = (action$: Observable<Action>): Observable<Action> => {
  return action$.pipe(
    ofType(UserDataAction.FINALIZE_ACCOUNT),
    mergeMap((action) => {
      const dossierId = (action as FinalizeUserAccountAction).payload;
      return customAjax({
        subRoute: `/partner/v1/account/finalize-initial-setup`,
        withCredentials: true,
        method: 'POST',
      }).pipe(
        map(() => {
          return { type: UserDataAction.FINALIZE_ACCOUNT_SUCCESS, payload: dossierId };
        }),
        catchError((error) => {
          return of(handleRequestFailureInEpic(error));
        }),
      );
    }),
  );
};

export const onSuccessfulFinalizationEpic: Epic<Action, Action> = (action$: Observable<Action>): Observable<Action> => {
  return action$.pipe(
    ofType(UserDataAction.FINALIZE_ACCOUNT_SUCCESS),
    map((action) => {
      const dossierId = (action as FinalizeUserAccountSuccessAction).payload;
      return { type: RoutingAction.REDIRECT_TO, payload: '/dashboard/dossier/' + dossierId };
    }),
  );
};

export const getActiveDemandProgressEpic: Epic<Action, Action> = (action$: Observable<Action>): Observable<Action> => {
  return action$.pipe(
    ofType(UserDataAction.SET_NEW_DEMAND),
    map((action) => {
      const demandId = (action as UserDataSetNewDemandAction).demandId;
      return { type: DemandOverviewAction.REQUEST_PROGRESS, payload: demandId };
    }),
  );
};

const addUserCanCreateNewDemand = (response: AjaxResponse): CanCreateNewDemandResultAction => {
  return {
    type: UserDataAction.CAN_CREATE_NEW_DEMAND_RESULT,
    payload: response.response as CanCreateNewDemandResult,
  };
};

export const canCreateNewDemandEpic: Epic<Action, Action> = (action$: Observable<Action>): Observable<Action> =>
  action$.pipe(
    ofType(UserDataAction.CAN_CREATE_NEW_DEMAND),
    mergeMap((action) => {
      const dossierId = (action as UserMyDataGetAction).payload;
      return customAjax({
        subRoute: `/dossier/v1/dossiers/${dossierId}/can-create-new-demand`,
        withCredentials: true,
        method: 'GET',
      }).pipe(
        map((response) => addUserCanCreateNewDemand(response)),
        catchError((error) => {
          return of(handleRequestFailureInEpic(error));
        }),
      );
    }),
  );

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getNotificationResult = (newValue: boolean): UserDataSetNotificationSettingsResultAction => {
  return {
    type: UserDataAction.SET_NOTIFICATION_SETTINGS_RESULT,
    payload: newValue,
  };
};

export const setNotificationSettingsEpic: Epic<Action, Action> = (action$: Observable<Action>): Observable<Action> =>
  action$.pipe(
    ofType(UserDataAction.SET_NOTIFICATION_SETTINGS),
    mergeMap((action) => {
      const setSettingsAction = action as UserDataSetNotificationSettingsAction;
      const body = { getNotifications: setSettingsAction.payload };

      return customAjax({
        subRoute: `/partner/v1/users/settings`,
        withCredentials: true,
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json-patch+json',
        },
        body: JSON.stringify(createPatch({ getNotifications: !setSettingsAction.payload }, body)),
      }).pipe(map(() => getNotificationResult(setSettingsAction.payload)));
    }),
  );
