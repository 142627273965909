import { PostalCode } from '../contact-data/types';
import { FatherData } from '../father-data/types';
import { PersonalData } from '../personal-data/types';
import { PayoutData } from './types';

export enum PayoutDataAction {
  GET = 'PAYOUT_DATA_GET',
  GET_RESULT = 'PAYOUT_DATA_GET_RESULT',
  CHANGE = 'PAYOUT_DATA_CHANGE',
  SUBMIT = 'PAYOUT_DATA_SUBMIT',
  RESULT = 'PAYOUT_DATA_RESULT',
  GET_POSTAL_CODES = 'PAYOUT_DATA_GET_POSTAL_CODES',
  GET_POSTAL_CODES_RESULT = 'PAYOUT_DATA_GET_POSTAL_CODES_RESULT',
  GET_POSTAL_CODES_ERROR = 'PAYOUT_DATA_GET_POSTAL_CODES_ERROR',
  GET_PARENT_DATA = 'PAYOUT_DATA_GET_PARENT_DATA',
  GET_PARENT_DATA_RESULT = 'PAYOUT_DATA_GET_PARENT_DATA_RESULT',
  GET_PARENT_DATA_ERROR = 'PAYPUT_DATA_GET_PARENT_DATA_ERROR',
  GET_PERSONAL_DATA = 'PAYOUT_DATE_GET_PERSONAL_DATA',
  GET_PERSONAL_DATA_RESULT = 'PAYOUT_DATA_GET_PERSONAL_DATA_RESULT',
  GET_PERSONAL_DATA_ERROR = 'PAYOUT_DATA_GET_PERSONAL_DATE_ERROR',
  RESET = 'PAYOUT_DATA_RESET',
}

export interface PayoutDataGetAction {
  type: typeof PayoutDataAction.GET;
  payload: PayoutData;
  demandId: number;
}

export interface PayoutDataGetResultAction {
  type: typeof PayoutDataAction.GET_RESULT;
  payload: PayoutData;
}

export interface PayoutDataChangeAction {
  type: typeof PayoutDataAction.CHANGE;
  payload: string;
  changeField: string;
}

export interface PayoutDataSubmitAction {
  type: typeof PayoutDataAction.SUBMIT;
  payload: PayoutData;
  demandId: number;
}

export interface PayoutDataResultAction {
  type: typeof PayoutDataAction.RESULT;
  payload: PayoutData;
}

export interface PayoutDataGetPostalCodesAction {
  type: typeof PayoutDataAction.GET_POSTAL_CODES;
  payload: PayoutData;
}

export interface PayoutDataGetPostalCodesResultAction {
  type: typeof PayoutDataAction.GET_POSTAL_CODES_RESULT;
  payload: PostalCode;
}

export interface PayoutDataGetPostalCodesErrorAction {
  type: typeof PayoutDataAction.GET_POSTAL_CODES_ERROR;
  payload: string;
}

export interface PayoutDataGetParentDataAction {
  type: typeof PayoutDataAction.GET_PARENT_DATA;
  payload: 'mother' | 'father';
  pifId: number;
}

export interface PayoutDataGetParentDataResultAction {
  type: typeof PayoutDataAction.GET_PARENT_DATA_RESULT;
  payload: FatherData;
}

export interface PayoutDataGetParentDataErrorAction {
  type: typeof PayoutDataAction.GET_PARENT_DATA_ERROR;
  payload: string;
}

export interface PayoutDataGetPersonalDataAction {
  type: typeof PayoutDataAction.GET_PERSONAL_DATA;
  payload: number;
}

export interface PayoutDataGetPersonalDataResultAction {
  type: typeof PayoutDataAction.GET_PERSONAL_DATA_RESULT;
  payload: PersonalData;
}

export interface PayoutDataGetPersonalDataErrorAction {
  type: typeof PayoutDataAction.GET_PERSONAL_DATA_ERROR;
  payload: string;
}

export interface PayoutDataResetAction {
  type: typeof PayoutDataAction.RESET;
}

export type PayoutDataActionType =
  | PayoutDataGetAction
  | PayoutDataGetResultAction
  | PayoutDataChangeAction
  | PayoutDataSubmitAction
  | PayoutDataResultAction
  | PayoutDataGetPostalCodesAction
  | PayoutDataGetPostalCodesResultAction
  | PayoutDataGetPostalCodesErrorAction
  | PayoutDataGetParentDataAction
  | PayoutDataGetParentDataResultAction
  | PayoutDataGetParentDataErrorAction
  | PayoutDataGetPersonalDataAction
  | PayoutDataGetPersonalDataResultAction
  | PayoutDataGetPersonalDataErrorAction
  | PayoutDataResetAction;
