import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { AjaxResponse } from 'rxjs/ajax';
import { Epic, ofType } from 'redux-observable';
import { Action } from 'redux';
import {
  CompleteDemandAction,
  CompleteDemandErrorAction,
  DemandOverviewAction,
  DemandOverviewActionProgressResult,
  DemandOverviewActionRequestProgress,
  DemandOverviewActionType,
} from './actions';
import { customAjax } from '../../utils/ajax-wrapper';
import { ContactDataAction, PersonalDataAction } from '../personal-data/actions';
import { PayoutDataAction } from '../payout-data/actions';
import { IncomingDataAction } from '../incoming-data/actions';
import { OutgoingDataAction } from '../outgoing-data/actions';
import { ParentsDataAction } from '../parents-data/actions';
import { FatherDataAction } from '../father-data/actions';
import { MotherDataAction } from '../mother-data/actions';
import { DocumentsAction } from '../documents/actions';
import { CVDataAction } from '../cv-data/actions';
import { ChildrenDataAction } from '../children-data/actions';
import { SiblingsDataAction } from '../siblings-data/actions';
import { EducationDataAction } from '../education-data/actions';
import { ScholarshipDataAction } from '../scholarship-data/actions';
import { PartnerDataAction } from '../partner-data/actions';

const processResult = (
  payload: AjaxResponse,
  params: { dossierId: number; demandId: number },
): DemandOverviewActionType => {
  return {
    type: DemandOverviewAction.COMPLETE_DEMAND_SUCCESS,
    payload: params,
  };
};

export const finalizeDemandEpic: Epic<Action, Action> = (action$: Observable<Action>): Observable<Action> =>
  action$.pipe(
    ofType(DemandOverviewAction.COMPLETE_DEMAND),
    mergeMap((action) => {
      const completeDemandAction: CompleteDemandAction = action as CompleteDemandAction;
      return customAjax({
        subRoute: `/dossier/v1/dossiers/${completeDemandAction.payload.dossierId}/demands/${completeDemandAction.payload.demandId}/demand-finalization`,
        withCredentials: true,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json-patch+json',
        },
      }).pipe(
        map((response) => processResult(response, completeDemandAction.payload)),
        catchError((error) => {
          const errorMessage = error.response?.Context?.ErrorDetails[0]?.ErrorMessage;
          const errorAction: CompleteDemandErrorAction = {
            type: DemandOverviewAction.COMPLETE_DEMAND_ERROR,
            payload: { ...completeDemandAction.payload, error: errorMessage ? errorMessage : error.message },
          };
          return of(errorAction);
        }),
      );
    }),
  );

const processProgressResponse = (payload: AjaxResponse): DemandOverviewActionProgressResult => {
  return { type: DemandOverviewAction.PROGRESS_RESULT, payload: payload.response };
};

const getDemandIdFromUrl = (url: string) => {
  if (/demand/.test(url)) {
    const parts = url.split('/');
    const demandIndex = parts.findIndex((value) => value === 'demand');
    if (parts[demandIndex + 1]) {
      return Number(parts[demandIndex + 1]);
    }
  }
  return 0;
};

export const requestDemandProgressEpic: Epic<Action, Action> = (action$: Observable<Action>): Observable<Action> =>
  action$.pipe(
    ofType(DemandOverviewAction.REQUEST_PROGRESS),
    mergeMap((action) => {
      const requestDemandAction: DemandOverviewActionRequestProgress = action as DemandOverviewActionRequestProgress;
      return customAjax({
        subRoute: `/dossier/v1/demands/${requestDemandAction.payload}/progress-tracking`,
        withCredentials: true,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      }).pipe(
        map((response) => processProgressResponse(response)),
        catchError(() => {
          return of({ type: 'VOID' });
        }),
      );
    }),
  );

export const refreshDemandProgressEpic: Epic<Action, Action> = (action$: Observable<Action>): Observable<Action> =>
  action$.pipe(
    ofType(
      PersonalDataAction.RESULT as string,
      ContactDataAction.RESULT as string,
      PayoutDataAction.RESULT as string,
      IncomingDataAction.RESULT as string,
      OutgoingDataAction.RESULT as string,
      ParentsDataAction.RESULT as string,
      PartnerDataAction.RESULT as string,
      FatherDataAction.RESULT as string,
      MotherDataAction.RESULT as string,
      DemandOverviewAction.COMPONENT_OPENED as string,
      DocumentsAction.UPLOAD_READY as string,
      DocumentsAction.DELETE_FILE_SUCCESS as string,
      CVDataAction.ADD_RESULT as string,
      CVDataAction.DELETE_RESULT as string,
      ChildrenDataAction.ADD_RESULT as string,
      ChildrenDataAction.DELETE_RESULT as string,
      SiblingsDataAction.ADD_RESULT as string,
      SiblingsDataAction.DELETE_RESULT as string,
      EducationDataAction.RESULT as string,
      ScholarshipDataAction.RESULT as string,
    ),
    map(() => {
      return { type: DemandOverviewAction.REQUEST_PROGRESS, payload: getDemandIdFromUrl(window.location.href) };
    }),
  );
