import { Breadcrumbs, Button, Card, CardContent, CircularProgress, Container, Grid, Link } from '@material-ui/core';
import React, { ChangeEvent, ReactElement, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';
import { Action } from 'redux';
import ZoomFooter, {
  mapStoreToFooterProps,
  ZoomFooterProps,
} from '../../components/base-page-components/footer/ZoomFooter';
import ZoomHeader, {
  mapStoreToHeaderProps,
  ZoomHeaderProps,
} from '../../components/base-page-components/header/ZoomHeader';
import ZoomDatePicker from '../../components/forms/date-picker/ZoomDatePicker';
import ZoomRadioInput from '../../components/forms/radio-input/ZoomRadioInput';
import ZoomSelectInput from '../../components/forms/select-input/ZoomSelectInput';
import ZoomTextInput from '../../components/forms/text-input/ZoomTextInput';
import { ZoomArrowLeftIcon } from '../../components/icons/ZoomArrowLeftIcon';
import { i18nLabel } from '../../store/i18n/helpers';
import { EditPersonalDataPageAction, EditPersonalDataPageGetAction } from '../../store/my-data-personal/actions';
import { WIAAStore } from '../../store/types';
import { blue } from '../../theme';
import { requiredValidation } from '../../utils/validators';
import { EditPersonalDataPage } from './../../store/my-data-personal/types';

export interface EditPersonalDataLabels {
  labelMrMrsType?: string;
  labelFirstName?: string;
  labelLastName?: string;
  labelAhvn13?: string;
  labelDateOfBirth?: string;
  labelMaritalStatus?: string;
  labelNationality?: string;
  labelCity?: string;
  labelTitle?: string;
  labelBackArrow?: string;
  labelCancel?: string;
  labelSave?: string;
  breadcrumbLabels?: Array<string>;
  pifMaritalStatuses?: { name: string; value: string }[];
  errorLabels?: { [key: string]: string };
  helpTextAhvn13?: string;
  headerProps: ZoomHeaderProps;
  footerProps: ZoomFooterProps;
}

export interface EditPersonalDataProps extends EditPersonalDataLabels {
  mrMrsType?: string;
  firstName?: string;
  lastName?: string;
  ahvn13?: string;
  dateOfBirth?: Date;
  maritalStatus?: string;
  nationality?: string;
  city?: string;
  dossierId: number;
  countryOptions?: { name: string; value: string }[];
  mrMrsTypes?: { name: string; value: string }[];
  isLoading?: boolean;
  dispatch?: (action: Action) => void;
}

interface EditPersonalDataErrors {
  mrMrsType?: string;
  lastName?: string;
  maritalStatus?: string;
  nationality?: string;
  city?: string;
}

export const EditPersonalData = (props: EditPersonalDataProps): ReactElement => {
  const firstName = props.firstName ? props.firstName : '';
  const ahvn13 = props.ahvn13 ? props.ahvn13 : '';
  const dateOfBirth = props.dateOfBirth ? props.dateOfBirth : undefined;

  const [mrMrsType, setMrMrsType] = useState(props.mrMrsType ? props.mrMrsType : '');
  const [lastName, setLastName] = useState(props.lastName ? props.lastName : '');
  const [maritalStatus, setMaritalStatus] = useState(props.maritalStatus ? props.maritalStatus : '0');
  const [city, setCity] = useState(props.city ? props.city : '');
  const [nationality, setNationality] = useState(props.nationality ? props.nationality : '8100');
  const history = useHistory();
  const initialErrors: EditPersonalDataErrors = {};
  const [errors, setErrors] = useState(initialErrors);

  useEffect(() => {
    if (props.dispatch) {
      const getUserEditPersonalData: EditPersonalDataPageGetAction = {
        type: EditPersonalDataPageAction.GET,
        dossierId: props.dossierId,
      };
      props.dispatch(getUserEditPersonalData);
    }
  }, []);

  useEffect(() => {
    setMrMrsType(props.mrMrsType ? props.mrMrsType : '');
    setLastName(props.lastName ? props.lastName : '');
    setMaritalStatus(props.maritalStatus ? props.maritalStatus : '0');
    setCity(props.city ? props.city : '');
    setNationality(props.nationality ? props.nationality : '8100');
  }, [props]);

  const onChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    switch (e.target.name) {
      case 'lastName':
        setLastName(e.target.value);
        setErrors({
          ...errors,
          lastName: requiredValidation(e.target.value, props.errorLabels ? props.errorLabels : {}),
        });
        break;
      case 'city':
        setCity(e.target.value);
        setErrors({ ...errors, city: requiredValidation(e.target.value, props.errorLabels ? props.errorLabels : {}) });
        break;
    }
  };

  const onChangeMrMrsType = (newValue: string) => {
    setMrMrsType(newValue);
    setErrors({ ...errors, mrMrsType: requiredValidation(newValue, props.errorLabels ? props.errorLabels : {}) });
  };

  const onNationalityChange = (newValue: string) => {
    setNationality(newValue);
  };

  const onMaritalStatusChange = (newValue: string) => {
    setMaritalStatus(newValue);
  };

  const getEditPersonalData = (): EditPersonalDataPage => {
    return {
      mrMrsType: parseInt(mrMrsType),
      firstName: firstName,
      lastName: lastName,
      ahvNo: ahvn13,
      dateOfBirth: dateOfBirth,
      maritalStatus: parseInt(maritalStatus),
      nationalityId: parseInt(nationality),
      originName: city,
    };
  };

  const getFormErrors = (): EditPersonalDataErrors => {
    const errors: EditPersonalDataErrors = {};
    errors.mrMrsType = requiredValidation(mrMrsType, props.errorLabels ? props.errorLabels : {});
    errors.lastName = requiredValidation(lastName, props.errorLabels ? props.errorLabels : {});
    errors.city = requiredValidation(city, props.errorLabels ? props.errorLabels : {});
    return errors;
  };

  const validateForm = (): boolean => {
    const newErrors = getFormErrors();
    setErrors(newErrors);
    const errorsPresent = Object.values(newErrors).some((value) => value !== '');
    return !errorsPresent;
  };

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    if (validateForm() && props.dispatch) {
      const editPersonalDataSubmitAction = {
        type: EditPersonalDataPageAction.SUBMIT,
        payload: getEditPersonalData(),
        dossierId: props.dossierId,
      };
      props.dispatch(editPersonalDataSubmitAction);
    }
    event.preventDefault();
  };

  return (
    <>
      <ZoomHeader {...props.headerProps} dispatch={props.dispatch}></ZoomHeader>
      <main className="main">
        {props.isLoading ? (
          <div style={{ textAlign: 'center' }}>
            <CircularProgress></CircularProgress>
          </div>
        ) : (
          <>
            <Container>
              <div id="main-page-container">
                <Breadcrumbs separator=">" aria-label="breadcrumb">
                  <Link
                    style={{ fontFamily: 'Proxima Nova Cond' }}
                    color="inherit"
                    href={'/dashboard/dossier/' + props.dossierId}
                  >
                    <>{props.breadcrumbLabels && props.breadcrumbLabels[0]}</>
                  </Link>
                  <Link
                    style={{ fontFamily: 'Proxima Nova Cond' }}
                    color="inherit"
                    href={'/my-data/dossier-intranet/mydata/' + props.dossierId}
                  >
                    <>{props.breadcrumbLabels && props.breadcrumbLabels[1]}</>
                  </Link>
                  <Link style={{ fontFamily: 'Proxima Nova Cond' }} color="inherit">
                    <b>{props.breadcrumbLabels && props.breadcrumbLabels[2]}</b>
                  </Link>
                </Breadcrumbs>
                <div className="main-page-info">
                  <div className="back-button-desktop" onClick={() => history.goBack()}>
                    <ZoomArrowLeftIcon viewBox="0 0 80 80" fontSize="large" htmlColor={blue.main}></ZoomArrowLeftIcon>
                    {props.labelBackArrow}
                  </div>
                  <h1 className="title-desktop">{props.labelTitle}</h1>
                </div>
                <Card>
                  <CardContent>
                    <form onSubmit={onSubmit}>
                      <div className="form-element-container">
                        <ZoomRadioInput
                          value={mrMrsType}
                          error={!!errors.mrMrsType}
                          errorMessage={errors.mrMrsType}
                          title={props.labelMrMrsType ? props.labelMrMrsType : ''}
                          label1={props.mrMrsTypes ? props.mrMrsTypes[0].name : ''}
                          value1={props.mrMrsTypes ? props.mrMrsTypes[0].value.toString() : ''}
                          label2={props.mrMrsTypes ? props.mrMrsTypes[1].name : ''}
                          value2={props.mrMrsTypes ? props.mrMrsTypes[1].value.toString() : ''}
                          onChange={onChangeMrMrsType}
                        ></ZoomRadioInput>
                      </div>
                      <div className="form-element-container">
                        <ZoomTextInput
                          label={props.labelFirstName ? props.labelFirstName : ''}
                          name="firstName"
                          disabled
                          value={firstName}
                        ></ZoomTextInput>
                      </div>
                      <div className="form-element-container">
                        <ZoomTextInput
                          label={props.labelLastName ? props.labelLastName : ''}
                          name="lastName"
                          value={lastName}
                          onChange={onChange}
                          error={!!errors.lastName}
                          errorMessage={errors.lastName}
                        ></ZoomTextInput>
                      </div>
                      <div className="form-element-container">
                        <ZoomTextInput
                          label={props.labelAhvn13 ? props.labelAhvn13 : ''}
                          value={ahvn13}
                          disabled
                          name="ahvn13"
                          helpText={props.helpTextAhvn13}
                        ></ZoomTextInput>
                      </div>
                      <div className="form-element-container">
                        <ZoomDatePicker
                          label={props.labelDateOfBirth ? props.labelDateOfBirth : ''}
                          name="dateOfBirth"
                          disabled
                          value={dateOfBirth}
                        ></ZoomDatePicker>
                      </div>
                      <div className="form-element-container">
                        <ZoomSelectInput
                          onChange={onMaritalStatusChange}
                          label={props.labelMaritalStatus ? props.labelMaritalStatus : ''}
                          options={props.pifMaritalStatuses ? props.pifMaritalStatuses : []}
                          value={maritalStatus}
                          name="maritalStatus"
                        ></ZoomSelectInput>
                      </div>
                      <div className="form-element-container">
                        <ZoomSelectInput
                          label={props.labelNationality ? props.labelNationality : ''}
                          options={props.countryOptions ? props.countryOptions : []}
                          value={nationality}
                          name="nationality"
                          onChange={onNationalityChange}
                        ></ZoomSelectInput>
                      </div>
                      <div className="form-element-container">
                        <ZoomTextInput
                          label={props.labelCity ? props.labelCity : ''}
                          value={city}
                          name="city"
                          onChange={onChange}
                          error={!!errors.city}
                          errorMessage={errors.city ? errors.city : undefined}
                        ></ZoomTextInput>
                      </div>
                      <Grid container direction="row" spacing={2} style={{ paddingBottom: '1rem' }}>
                        <Grid item xs={6} sm={6}>
                          <div className="form-actions-container-cancel">
                            <Button
                              color="primary"
                              size="large"
                              type="reset"
                              variant="outlined"
                              id="cancel-edit-personal-data"
                              style={{ width: '144px' }}
                              onClick={() => history.goBack()}
                            >
                              {props.labelCancel}
                            </Button>
                          </div>
                        </Grid>
                        <Grid item xs={6} sm={6}>
                          <div className="form-actions-container-save">
                            <Button
                              color="primary"
                              size="large"
                              type="submit"
                              variant="contained"
                              id="save-edit-personal-data"
                              style={{ width: '144px' }}
                            >
                              {props.labelSave}
                            </Button>
                          </div>
                        </Grid>
                      </Grid>
                    </form>
                  </CardContent>
                </Card>
              </div>
            </Container>
          </>
        )}
      </main>
      <ZoomFooter {...props.footerProps}></ZoomFooter>
    </>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export function mapStateToProps(store: WIAAStore, ownProps: any): EditPersonalDataProps {
  const { dossierId } = ownProps.match.params;
  const headerProps: ZoomHeaderProps = mapStoreToHeaderProps(store, dossierId);
  const footerProps: ZoomFooterProps = mapStoreToFooterProps(store);

  return {
    headerProps,
    footerProps,
    labelTitle: i18nLabel(store.translationLabels, 'Edit_Personal_Data_Title', 'Personalien bearbeiten'),
    labelMrMrsType: i18nLabel(store.translationLabels, 'Edit_Personal_Data_Mr_Mrs_Type', 'Anrede*'),
    labelFirstName: i18nLabel(store.translationLabels, 'Edit_Personal_Data_First_Name', 'Vorname'),
    labelLastName: i18nLabel(store.translationLabels, 'Edit_Personal_Data_Last_Name', 'Nachname*'),
    labelAhvn13: i18nLabel(store.translationLabels, 'Edit_Personal_Data_Ahv_Number', 'AHV-Nummer'),
    labelDateOfBirth: i18nLabel(store.translationLabels, 'Edit_Personal_Data_Date_Of_Birth', 'Geburtstag'),
    labelMaritalStatus: i18nLabel(store.translationLabels, 'Edit_Personal_Data_Marital_Status', 'Zivilstand*'),
    labelNationality: i18nLabel(store.translationLabels, 'Edit_Personal_Data_Nationality', 'Nationalität*'),
    labelCity: i18nLabel(store.translationLabels, 'Edit_Personal_Data_City', 'Heimatort*'),
    labelBackArrow: i18nLabel(store.translationLabels, 'Edit_Personal_Data_Back_Arrow', 'Zurück'),
    labelCancel: i18nLabel(store.translationLabels, 'Edit_Personal_Data_Cancel', 'Abbrechen'),
    labelSave: i18nLabel(store.translationLabels, 'Edit_Personal_Data_Save', 'Speichern'),
    breadcrumbLabels: [
      i18nLabel(store.translationLabels, 'Edit_Personal_Data_Breadcrumb_Label_1', 'Übersicht'),
      i18nLabel(store.translationLabels, 'Edit_Personal_Data_Breadcrumb_Label_2', 'Meine Daten'),
      i18nLabel(store.translationLabels, 'Edit_Personal_Data_Breadcrumb_Label_3', 'Personalien bearbeiten'),
    ],
    errorLabels: store.translationLabels,
    ahvn13: store.editPersonalDataPage.ahvNo,
    mrMrsType: store.editPersonalDataPage.mrMrsType ? store.editPersonalDataPage.mrMrsType.toString() : '',
    firstName: store.editPersonalDataPage.firstName,
    lastName: store.editPersonalDataPage.lastName,
    dateOfBirth: store.editPersonalDataPage.dateOfBirth,
    maritalStatus: store.editPersonalDataPage.maritalStatus ? store.editPersonalDataPage.maritalStatus.toString() : '',
    nationality: store.editPersonalDataPage.nationalityId ? store.editPersonalDataPage.nationalityId.toString() : '',
    city: store.editPersonalDataPage.originName,
    dossierId: dossierId,
    countryOptions: store.translationOptions.nationalities
      ? [
          ...store.translationOptions.nationalities.map((option) => {
            return { value: option.id.toString(), name: option.name };
          }),
        ]
      : [],
    mrMrsTypes: store.translationOptions.titles
      ? [
          ...store.translationOptions.titles.map((option) => {
            return { value: option.id.toString(), name: option.name };
          }),
        ]
      : [],
    pifMaritalStatuses: store.translationOptions['piF-marital-statuses']
      ? [
          ...store.translationOptions['piF-marital-statuses'].map((option) => {
            return { value: option.id.toString(), name: option.name };
          }),
        ]
      : [],
    isLoading: store.editPersonalDataPage.isLoading,
  };
}

export default withRouter(connect(mapStateToProps)(EditPersonalData));
