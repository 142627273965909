import { Action } from 'redux';
import { Epic, ofType } from 'redux-observable';
import { Observable, of } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { customAjax } from '../../../utils/ajax-wrapper';
import { handleRequestFailureInEpic } from '../../../utils/error-handling';
import {
  PersonDetailsDataAction,
  PersonDetailsDataGetResultAction,
  PersonDetailsEditResultPermissionsDataAction,
  PersonDetailsDataEditResultPersonDetailsAction,
  PersonDetailsDeleteResultPermissionsDataAction,
  PersonDetailsDataGetAction,
  PersonDetailsDeletePermissionsDataAction,
  PersonDetailsEditPermissionsDataAction,
  PersonDetailsDataEditPersonDetailsAction,
  PersonDetailsManualDemandActivationAction,
  PersonDetailsManualDemandActivationResultAction,
} from './actions';
import { createPatch } from 'rfc6902';

const loadPersonDetailsData = (payload: AjaxResponse): PersonDetailsDataGetResultAction => {
  return {
    type: PersonDetailsDataAction.GET_RESULT,
    user: payload.response.user,
    permissions: payload.response.permissions,
    canActivateDemand: payload.response.canActivateDemand,
    ownedDossierId: payload.response.ownedDossierId,
    auditList: payload.response.auditList,
  };
};

export const getPersonDetailsDataEpic: Epic<Action, Action> = (action$: Observable<Action>): Observable<Action> =>
  action$.pipe(
    ofType(PersonDetailsDataAction.GET),
    mergeMap((action) => {
      const portalId = (action as PersonDetailsDataGetAction).payload;
      return customAjax({
        subRoute: `/partner/v1/users/${portalId}/dashboard-get`,
        withCredentials: true,
        method: 'GET',
      }).pipe(
        map((response) => loadPersonDetailsData(response)),
        catchError((error) => {
          return of(handleRequestFailureInEpic(error));
        }),
      );
    }),
  );

const permissionDataDeleted = (payload: number): PersonDetailsDeleteResultPermissionsDataAction => {
  return {
    type: PersonDetailsDataAction.DELETE_RESULT_PERMISSIONS_PERSON_DETAILS,
    payload,
  };
};

export const deletePermissionDataEpic: Epic<Action, Action> = (action$: Observable<Action>): Observable<Action> =>
  action$.pipe(
    ofType(PersonDetailsDataAction.DELETE_PERMISSIONS_PERSON_DETAILS),
    mergeMap((action) => {
      const id = (action as PersonDetailsDeletePermissionsDataAction).payload;
      return customAjax({
        subRoute: `/partner/v1/permissions/${id}/dashboard-delete`,
        withCredentials: true,
        method: 'DELETE',
      }).pipe(
        map(() => permissionDataDeleted(id)),
        catchError((error) => {
          return of(handleRequestFailureInEpic(error));
        }),
      );
    }),
  );

const permissionDataEdited = (payload: AjaxResponse): PersonDetailsEditResultPermissionsDataAction => {
  return {
    type: PersonDetailsDataAction.EDIT_RESULT_PERMISSIONS_PERSON_DETAILS,
    payload: { ...payload.response },
  };
};

export const editPermissionDataEpic: Epic<Action, Action> = (action$: Observable<Action>): Observable<Action> =>
  action$.pipe(
    ofType(PersonDetailsDataAction.EDIT_PERMISSIONS_PERSON_DETAILS),
    mergeMap((action) => {
      const permission = (action as PersonDetailsEditPermissionsDataAction).payload;
      return customAjax({
        subRoute: `/partner/v1/permissions/${permission.id}/dashboard-patch`,
        withCredentials: true,
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json-patch+json',
        },
        body: JSON.stringify(createPatch({}, permission)),
      }).pipe(
        map((response) => permissionDataEdited(response)),
        catchError((error) => {
          return of(handleRequestFailureInEpic(error));
        }),
      );
    }),
  );

const personDetailsDataEdited = (payload: AjaxResponse): PersonDetailsDataEditResultPersonDetailsAction => {
  return {
    type: PersonDetailsDataAction.EDIT_RESULT_PERSON_DETAILS,
    payload: { ...payload.response },
  };
};

export const editPersonDetailsDataEpic: Epic<Action, Action> = (action$: Observable<Action>): Observable<Action> =>
  action$.pipe(
    ofType(PersonDetailsDataAction.EDIT_PERSON_DETAILS),
    mergeMap((action) => {
      const personDetails = (action as PersonDetailsDataEditPersonDetailsAction).payload;
      return customAjax({
        subRoute: `/partner/v1/users/${personDetails.id}/dashboard-patch`,
        withCredentials: true,
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json-patch+json',
        },
        body: JSON.stringify(
          createPatch(
            {
              uid: '',
              svn: '',
              birthDate: '',
            },
            {
              uid: personDetails.uid,
              svn: personDetails.svn,
              birthDate: personDetails.birthDate,
            },
          ),
        ),
      }).pipe(
        map((response) => personDetailsDataEdited(response)),
        catchError((error) => {
          return of(handleRequestFailureInEpic(error));
        }),
      );
    }),
  );

const getSubmitResult = (payload: AjaxResponse): PersonDetailsManualDemandActivationResultAction => {
  return {
    type: PersonDetailsDataAction.MANUAL_DEMAND_ACTIVATION_RESULT,
    payload: { ...payload.response },
  };
};

export const sendManualDemandActivation: Epic<Action, Action> = (action$: Observable<Action>): Observable<Action> =>
  action$.pipe(
    ofType(PersonDetailsDataAction.MANUAL_DEMAND_ACTIVATION),
    mergeMap((action) => {
      const portalId = (action as PersonDetailsManualDemandActivationAction).portalId;
      const manualDemandActivation = (action as PersonDetailsManualDemandActivationAction).manualDemandActivation;
      return customAjax({
        subRoute: `/partner/v1/users/${portalId}/dashboard-manual-demand-activation`,
        withCredentials: true,
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          manualDemandActivation: manualDemandActivation,
        }),
      }).pipe(
        map((response) => getSubmitResult(response)),
        catchError((error) => {
          return of(handleRequestFailureInEpic(error));
        }),
      );
    }),
  );
