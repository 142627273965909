import React, { ReactElement } from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import { black } from '../../theme';

export function ZoomUserIcon(props: SvgIconProps): ReactElement {
  const color = props.htmlColor ? props.htmlColor : black.main;
  return (
    <SvgIcon {...props}>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <path d="M0 0H80V80H0z" transform="translate(-156 -57) translate(156 57)" />
            <path
              fill={color}
              fillRule="nonzero"
              d="M40.61 48.82c4.616.004 8.78-2.773 10.549-7.037 1.769-4.263.795-9.172-2.467-12.438-3.263-3.265-8.171-4.243-12.436-2.477-4.265 1.765-7.046 5.926-7.046 10.542.006 6.295 5.105 11.399 11.4 11.41zm0-18.81c2.999-.004 5.705 1.8 6.854 4.57 1.15 2.77.516 5.96-1.604 8.08s-5.31 2.754-8.08 1.604c-2.77-1.15-4.574-3.855-4.57-6.854 0-4.089 3.311-7.404 7.4-7.41v.01z"
              transform="translate(-156 -57) translate(156 57)"
            />
            <path
              fill={color}
              fillRule="nonzero"
              d="M40.61 16.5c-13.255 0-24 10.745-24 24s10.745 24 24 24 24-10.745 24-24c0-6.365-2.529-12.47-7.03-16.97-4.5-4.501-10.605-7.03-16.97-7.03zm0 4c7.158-.002 13.772 3.823 17.341 10.028 3.57 6.205 3.55 13.845-.051 20.032H23.33c-1.781-3.032-2.723-6.484-2.73-10-.016-5.316 2.086-10.42 5.84-14.184 3.755-3.764 8.854-5.879 14.17-5.876zm0 40c-5.359-.011-10.489-2.173-14.24-6h28.48c-3.746 3.838-8.877 6.01-14.24 6.03v-.03z"
              transform="translate(-156 -57) translate(156 57)"
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}
