import { Button } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import React, { ReactElement, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ZoomDatePickerStartEndDate from '../../components/forms/date-picker-start-end-date/ZoomDatePickerStartEndDate';
import ZoomRadioInput from '../../components/forms/radio-input/ZoomRadioInput';
import ZoomSelectInput from '../../components/forms/select-input/ZoomSelectInput';
import { i18nLabel } from '../../store/i18n/helpers';
import { ScholarshipDataAction, ScholarshipDataActionType } from '../../store/scholarship-data/actions';
import { ScholarshipData } from '../../store/scholarship-data/types';
import { WIAAStore } from '../../store/types';
import { setDateToMiddleOfMonth } from '../../utils/date-manipulation';
import { requiredValidation } from '../../utils/validators';
import { getDefaultSelectOption } from '../../utils/form-data';
import './ScholarshipInfoForm.scss';

export interface ScholarshipInfoFormProps {
  id?: number;
  requestInitial?: string;
  requestPeriodStartDate?: Date;
  requestPeriodEndDate?: Date;
  professionalTraining?: string;
  financiallyIndependent2Years?: string;
  employedFinanciallyIndependent6Years?: string;
  citizenInCanton2Years?: string;
  dossierId: number;
  labelRequestInitial?: string;
  labelContributionYear?: string;
  labelProfessionalTraining?: string;
  labelFinanciallyIndependent2Years?: string;
  labelEmployedFinanciallyIndependent6Years?: string;
  labelCitizenInCanton2Years?: string;
  helpTextRequestInitialType?: string;
  helpTextRequestPeriodStartDate?: string;
  helpTextRequestPeriodEndDate?: string;
  helpTextProfessionalTraining?: string;
  helpTextFinanciallyIndependent2Years?: string;
  helpTextFinanciallyIndependent6Years?: string;
  infoText?: string;
  labelSave?: string;
  toggleButtonsTypes?: { name: string; value: string }[];
  demandType?: Array<{ name: string; value: string }>;
  errorLabels?: { [key: string]: string };
  dispatch?: (action: ScholarshipDataActionType) => void;
}

interface ScholarshipInfoFormErrors {
  id?: string;
  requestInitial?: string;
  requestPeriodStartDate?: string;
  requestPeriodEndDate?: string;
  professionalTraining?: string;
  financiallyIndependent2Years?: string;
  employedFinanciallyIndependent6Years?: string;
  citizenInCanton2Years?: string;
}
export const ScholarshipInfoForm = (props: ScholarshipInfoFormProps): ReactElement => {
  const [id, setId] = useState(props.id ? props.id : 0);
  const [requestInitial, setRequestInitial] = useState(props.requestInitial ? props.requestInitial : '0');
  const [requestPeriodStartDate, setRequestPeriodStartDate] = useState(
    props.requestPeriodStartDate ? props.requestPeriodStartDate : undefined,
  );
  const [requestPeriodEndDate, setRequestPeriodEndDate] = useState(
    props.requestPeriodStartDate ? props.requestPeriodStartDate : undefined,
  );
  const [professionalTraining, setProfessionalTraining] = useState(
    props.professionalTraining ? props.professionalTraining : '',
  );
  const [financiallyIndependent2Years, setFinanciallyIndependent2Years] = useState(
    props.financiallyIndependent2Years ? props.financiallyIndependent2Years : '',
  );
  const [employedFinanciallyIndependent6Years, setEmployedFinanciallyIndependent6Years] = useState(
    props.employedFinanciallyIndependent6Years ? props.employedFinanciallyIndependent6Years : '',
  );
  const [citizenInCanton2Years, setCitizenInCanton2Years] = useState(
    props.citizenInCanton2Years ? props.citizenInCanton2Years : '1',
  );
  const initialErrors: ScholarshipInfoFormErrors = {};
  const [errors, setErrors] = useState(initialErrors);

  useEffect(() => {
    setId(props.id ? props.id : 0);
    setRequestInitial(props.requestInitial ? props.requestInitial : '0');
    setRequestPeriodStartDate(props.requestPeriodStartDate ? props.requestPeriodStartDate : undefined);
    setRequestPeriodEndDate(props.requestPeriodEndDate ? props.requestPeriodEndDate : undefined);
    setProfessionalTraining(props.professionalTraining ? props.professionalTraining : '');
    setFinanciallyIndependent2Years(props.financiallyIndependent2Years ? props.financiallyIndependent2Years : '');
    setEmployedFinanciallyIndependent6Years(
      props.employedFinanciallyIndependent6Years ? props.employedFinanciallyIndependent6Years : '',
    );
    setCitizenInCanton2Years(props.citizenInCanton2Years ? props.citizenInCanton2Years : '1');
  }, [props]);

  const onRequestInitialChange = (newValue: string) => {
    setRequestInitial(newValue);
    setErrors({
      ...errors,
      requestInitial: newValue === '0' ? requiredValidation('', props.errorLabels ? props.errorLabels : {}) : '',
    });
  };

  const onChangeRequestPeriodStartDate = (newDate: Date | null) => {
    if (null === newDate) {
      setRequestPeriodStartDate(undefined);
    } else {
      setRequestPeriodStartDate(newDate);
    }
    setErrors({
      ...errors,
      requestPeriodStartDate:
        newDate === null ? requiredValidation('', props.errorLabels ? props.errorLabels : {}) : '',
    });
  };

  const onChangeRequestPeriodEndDate = (newDate: Date | null) => {
    if (null === newDate) {
      setRequestPeriodEndDate(undefined);
    } else {
      setRequestPeriodEndDate(newDate);
    }
    setErrors({
      ...errors,
      requestPeriodEndDate: newDate === null ? requiredValidation('', props.errorLabels ? props.errorLabels : {}) : '',
    });
  };

  const onChangeProfessionalTraining = (newValue: string) => {
    setProfessionalTraining(newValue);
    setFinanciallyIndependent2Years('');
    setEmployedFinanciallyIndependent6Years('');
    setCitizenInCanton2Years('');
    setErrors({
      ...errors,
      professionalTraining: requiredValidation(newValue, props.errorLabels ? props.errorLabels : {}),
    });
  };

  const onChangeFinanciallyIndependent2Years = (newValue: string) => {
    setFinanciallyIndependent2Years(newValue);
    setEmployedFinanciallyIndependent6Years('');
    setCitizenInCanton2Years('');
    setErrors({
      ...errors,
      financiallyIndependent2Years: requiredValidation(newValue, props.errorLabels ? props.errorLabels : {}),
    });
  };

  const onChangeEmployedFinanciallyIndependent6Years = (newValue: string) => {
    setEmployedFinanciallyIndependent6Years(newValue);
    setFinanciallyIndependent2Years('');
    setCitizenInCanton2Years('');
    setErrors({
      ...errors,
      employedFinanciallyIndependent6Years: requiredValidation(newValue, props.errorLabels ? props.errorLabels : {}),
    });
  };

  const onChangeCitizenInCanton2Years = (newValue: string) => {
    setCitizenInCanton2Years(newValue);
    setErrors({
      ...errors,
      citizenInCanton2Years: requiredValidation(newValue, props.errorLabels ? props.errorLabels : {}),
    });
  };

  const getScholarshipData = (): ScholarshipData => {
    const middleOfMonthStart = setDateToMiddleOfMonth(requestPeriodStartDate);
    const middleOfMonthEnd = setDateToMiddleOfMonth(requestPeriodEndDate);

    const scholarshipData: ScholarshipData = {
      id: id,
      requestInitialType: requestInitial ? parseInt(requestInitial) : undefined,
      requestPeriodStartDate: middleOfMonthStart ? middleOfMonthStart.toISOString() : '',
      requestPeriodEndDate: middleOfMonthEnd ? middleOfMonthEnd.toISOString() : '',
      completedEducation: professionalTraining === '1' ? true : false,
      financiallyIndependent2Years: financiallyIndependent2Years === '1' ? true : false,
      employedFinanciallyIndependent6Years: employedFinanciallyIndependent6Years === '1' ? true : false,
    };

    if (scholarshipData.financiallyIndependent2Years || scholarshipData.employedFinanciallyIndependent6Years) {
      scholarshipData.citizenInCanton2Years = citizenInCanton2Years === '1' ? true : false;
    }

    return scholarshipData;
  };

  const getFormErrors = (): ScholarshipInfoFormErrors => {
    const errors: ScholarshipInfoFormErrors = {};
    errors.requestInitial =
      requestInitial === '0' ? requiredValidation('', props.errorLabels ? props.errorLabels : {}) : '';
    errors.requestPeriodStartDate = requiredValidation(
      requestPeriodStartDate ? requestPeriodStartDate.toDateString() : '',
      props.errorLabels ? props.errorLabels : {},
    );
    errors.requestPeriodEndDate = requiredValidation(
      requestPeriodEndDate ? requestPeriodEndDate.toDateString() : '',
      props.errorLabels ? props.errorLabels : {},
    );
    errors.professionalTraining = requiredValidation(professionalTraining, props.errorLabels ? props.errorLabels : {});
    if (professionalTraining === '1') {
      errors.financiallyIndependent2Years = requiredValidation(
        financiallyIndependent2Years,
        props.errorLabels ? props.errorLabels : {},
      );
    }
    if (professionalTraining === '2') {
      errors.employedFinanciallyIndependent6Years = requiredValidation(
        employedFinanciallyIndependent6Years,
        props.errorLabels ? props.errorLabels : {},
      );
    }
    if (financiallyIndependent2Years === '1' || employedFinanciallyIndependent6Years === '1') {
      errors.citizenInCanton2Years = requiredValidation(
        citizenInCanton2Years,
        props.errorLabels ? props.errorLabels : {},
      );
    }
    return errors;
  };

  const validateForm = (): boolean => {
    const newErrors = getFormErrors();
    setErrors(newErrors);
    const errorsPresent = Object.values(newErrors).some((value) => value !== '');
    return !errorsPresent;
  };

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    if (validateForm() && props.dispatch) {
      if (!!!id || id === 0) {
        props.dispatch({
          type: ScholarshipDataAction.ADD,
          payload: getScholarshipData(),
          dossierId: props.dossierId,
        });
      } else {
        props.dispatch({
          type: ScholarshipDataAction.SUBMIT,
          payload: getScholarshipData(),
          dossierId: props.dossierId,
        });
      }
    }
    event.preventDefault();
  };

  return (
    <>
      <form onSubmit={onSubmit}>
        <div className="form-element-container">
          <ZoomSelectInput
            onChange={onRequestInitialChange}
            label={props.labelRequestInitial ? props.labelRequestInitial : ''}
            options={props.demandType ? props.demandType : []}
            value={requestInitial}
            name="requestInitial"
            error={!!errors.requestInitial}
            errorMessage={errors.requestInitial}
            helpText={props.helpTextRequestInitialType}
          ></ZoomSelectInput>
        </div>
        <div className="form-element-container">
          <ZoomDatePickerStartEndDate
            label={props.labelContributionYear ? props.labelContributionYear : ''}
            name="contributionYear"
            valueStart={requestPeriodStartDate}
            valueEnd={requestPeriodEndDate}
            onChangeStart={onChangeRequestPeriodStartDate}
            onChangeEnd={onChangeRequestPeriodEndDate}
            error={!!errors.requestPeriodStartDate || !!errors.requestPeriodEndDate}
            errorMessage={errors.requestPeriodStartDate || errors.requestPeriodEndDate}
            helpText={props.helpTextRequestPeriodStartDate + ' ' + props.helpTextRequestPeriodEndDate}
          ></ZoomDatePickerStartEndDate>
        </div>
        <div className="form-element-container">
          <ZoomRadioInput
            value={professionalTraining}
            error={!!errors.professionalTraining}
            errorMessage={errors.professionalTraining}
            title={props.labelProfessionalTraining ? props.labelProfessionalTraining : ''}
            label1={props.toggleButtonsTypes ? props.toggleButtonsTypes[0].name : ''}
            value1={props.toggleButtonsTypes ? props.toggleButtonsTypes[0].value.toString() : ''}
            label2={props.toggleButtonsTypes ? props.toggleButtonsTypes[1].name : ''}
            value2={props.toggleButtonsTypes ? props.toggleButtonsTypes[1].value.toString() : ''}
            onChange={onChangeProfessionalTraining}
            helpText={props.helpTextProfessionalTraining}
          ></ZoomRadioInput>
        </div>
        {professionalTraining === '1' && (
          <div className="form-element-container">
            <ZoomRadioInput
              value={financiallyIndependent2Years}
              error={!!errors.financiallyIndependent2Years}
              errorMessage={errors.financiallyIndependent2Years}
              title={props.labelFinanciallyIndependent2Years ? props.labelFinanciallyIndependent2Years : ''}
              label1={props.toggleButtonsTypes ? props.toggleButtonsTypes[0].name : ''}
              value1={props.toggleButtonsTypes ? props.toggleButtonsTypes[0].value.toString() : ''}
              label2={props.toggleButtonsTypes ? props.toggleButtonsTypes[1].name : ''}
              value2={props.toggleButtonsTypes ? props.toggleButtonsTypes[1].value.toString() : ''}
              onChange={onChangeFinanciallyIndependent2Years}
              helpText={props.helpTextFinanciallyIndependent2Years}
            ></ZoomRadioInput>
          </div>
        )}
        {professionalTraining === '2' && (
          <div className="form-element-container">
            <ZoomRadioInput
              value={employedFinanciallyIndependent6Years}
              error={!!errors.employedFinanciallyIndependent6Years}
              errorMessage={errors.employedFinanciallyIndependent6Years}
              title={
                props.labelEmployedFinanciallyIndependent6Years ? props.labelEmployedFinanciallyIndependent6Years : ''
              }
              label1={props.toggleButtonsTypes ? props.toggleButtonsTypes[0].name : ''}
              value1={props.toggleButtonsTypes ? props.toggleButtonsTypes[0].value.toString() : ''}
              label2={props.toggleButtonsTypes ? props.toggleButtonsTypes[1].name : ''}
              value2={props.toggleButtonsTypes ? props.toggleButtonsTypes[1].value.toString() : ''}
              onChange={onChangeEmployedFinanciallyIndependent6Years}
              helpText={props.helpTextFinanciallyIndependent6Years}
            ></ZoomRadioInput>
          </div>
        )}
        {(financiallyIndependent2Years === '1' || employedFinanciallyIndependent6Years === '1') && (
          <div className="form-element-container">
            <ZoomRadioInput
              value={citizenInCanton2Years}
              error={!!errors.citizenInCanton2Years}
              errorMessage={errors.citizenInCanton2Years}
              title={props.labelCitizenInCanton2Years ? props.labelCitizenInCanton2Years : ''}
              label1={props.toggleButtonsTypes ? props.toggleButtonsTypes[0].name : ''}
              value1={props.toggleButtonsTypes ? props.toggleButtonsTypes[0].value.toString() : ''}
              label2={props.toggleButtonsTypes ? props.toggleButtonsTypes[1].name : ''}
              value2={props.toggleButtonsTypes ? props.toggleButtonsTypes[1].value.toString() : ''}
              onChange={onChangeCitizenInCanton2Years}
            ></ZoomRadioInput>
          </div>
        )}
        {citizenInCanton2Years === '2' &&
          (financiallyIndependent2Years === '1' || employedFinanciallyIndependent6Years === '1') && (
            <div className="form-element-container">
              <Alert severity="info">{props.infoText}</Alert>
            </div>
          )}
        <div className="form-actions-container">
          <Button color="primary" type="submit" variant="contained" id="submit-scholarship-data">
            {props.labelSave}
          </Button>
        </div>
      </form>
    </>
  );
};

// Map global store to component parameters
// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export function mapStateToProps(store: WIAAStore, ownProps: any): ScholarshipInfoFormProps {
  const { dossierId } = ownProps.match.params;
  const props: ScholarshipInfoFormProps = {
    labelRequestInitial: i18nLabel(store.translationLabels, 'Scholarship_Info_Request_Initial', 'Gesuchstyp*'),
    labelContributionYear: i18nLabel(
      store.translationLabels,
      'Scholarship_Info_Contribution_Year',
      'Beginn / Ende aktuelles Beitragsjahr*',
    ),
    labelProfessionalTraining: i18nLabel(
      store.translationLabels,
      'Scholarship_Info_Professional_Training',
      'Haben Sie eine abgeschlossene berufsbefähigende Ausbildung?*',
    ),
    labelFinanciallyIndependent2Years: i18nLabel(
      store.translationLabels,
      'Scholarship_Info_Financially_Independent_2_Years',
      'Sind Sie seit mindestens 2 Jahren aufgrund eigener Erwerbstätigkeit finanziell unabhängig?*',
    ),
    labelEmployedFinanciallyIndependent6Years: i18nLabel(
      store.translationLabels,
      'Scholarship_Info_Employed_Financially_Independent_6_Years',
      'Waren Sie in den letzten 6 Jahren durchgehend erwerbstätig und finanziell unabhängig?*',
    ),
    labelCitizenInCanton2Years: i18nLabel(
      store.translationLabels,
      'Scholarship_Info_Citizen_In_Canton_2_Years',
      'Sind Sie seit mindestens 2 Jahren ununterbrochen vor der neuen Ausbildung im Kanton Aargau wohnhaft?*',
    ),
    helpTextRequestInitialType: i18nLabel(
      store.translationLabels,
      'Scholarship_Info_Request_Initial_Type_Help_Text',
      'Bezieht sich auf die aktuelle Ausbildung. Falls Sie für die aktuelle Ausbildung bereits ein Gesuch eingereicht haben, wählen Sie bitte "Folgegesuch"',
    ),
    helpTextRequestPeriodStartDate: i18nLabel(
      store.translationLabels,
      'Scholarship_Info_Request_Period_Start_Date_Help_Text',
      'Bei Ausbildungen der Tertiärstufe gilt das Beitragsjahr ab Vorlesungsbeginn.',
    ),
    helpTextRequestPeriodEndDate: i18nLabel(
      store.translationLabels,
      'Scholarship_Info_Request_Period_End_Date',
      'Bitte nur aktuelles Beitragsjahr und nicht die gesamte Ausbildungsdauer erfassen.',
    ),
    helpTextProfessionalTraining: i18nLabel(
      store.translationLabels,
      'Scholarship_Info_Professional_Training_Help_Text',
      'Zu den berufsbefähigenden Ausbildungen gehören WMS, IMS, EBA, EFZ, HF, FH und Uni (auch Bachelor).',
    ),
    helpTextFinanciallyIndependent2Years: i18nLabel(
      store.translationLabels,
      'Scholarship_Info_Financially_Independent_2_Years_Help_Text',
      'Als Erwerbstätig gilt auch der Militärdienst/Zivildienst und der Anspruch/Bezug von Arbeitslosengeld.',
    ),
    helpTextFinanciallyIndependent6Years: i18nLabel(
      store.translationLabels,
      'Scholarship_Info_Financially_Independent_6_Years_Help_Text',
      "Bitte nur mit Ja beantworten, wenn Sie mindestens ein durchschnittliches Jahreseinkommen von Fr. 25'000.-- erzielt haben.",
    ),
    infoText: i18nLabel(
      store.translationLabels,
      'Scholarship_Info_Info_Text',
      'Aufgrund Ihrer Angaben ist unklar, ob der Kanton Aargau für Ihr Stipendiengesuch zuständig ist. Bitte nehmen Sie mit der Sektion Stipendien (tel. 062 835 22 70) Kontakt auf.',
    ),
    labelSave: i18nLabel(store.translationLabels, 'Scholarship_Info_Save', 'Speichern'),
    errorLabels: store.translationLabels,
    dossierId: Number(dossierId),
    demandType: store.translationOptions['demand-types']
      ? [
          { value: '0', name: getDefaultSelectOption(store.translationLabels) },
          ...store.translationOptions['demand-types'].map((option) => {
            return { value: option.id.toString(), name: option.name };
          }),
        ]
      : [],
    toggleButtonsTypes: store.translationOptions['toggle-buttons']
      ? [
          ...store.translationOptions['toggle-buttons'].map((option) => {
            return { value: option.id.toString(), name: option.name };
          }),
        ]
      : [],
  };

  const data = store.scholarshipData;
  if (data) {
    props.id = data.id ? data.id : 0;
    props.requestInitial = data.requestInitialType ? data.requestInitialType.toString() : '';
    props.requestPeriodStartDate = data.requestPeriodStartDate ? new Date(data.requestPeriodStartDate) : undefined;
    props.requestPeriodEndDate = data.requestPeriodEndDate ? new Date(data.requestPeriodEndDate) : undefined;
    props.professionalTraining = data.completedEducation ? '1' : '2';
    props.financiallyIndependent2Years = data.financiallyIndependent2Years ? '1' : '2';
    props.employedFinanciallyIndependent6Years = data.employedFinanciallyIndependent6Years ? '1' : '2';
    props.citizenInCanton2Years = data.citizenInCanton2Years ? '1' : '2';
  }
  return props;
}

// default export the connected component
export default withRouter(connect(mapStateToProps)(ScholarshipInfoForm));
