import { Observable, of } from 'rxjs';
import { Epic, ofType } from 'redux-observable';
import {
  IncomingDataGetResultAction,
  IncomingDataSubmitAction,
  IncomingDataResultAction,
  IncomingDataAction,
  IncomingDataGetAction,
} from './actions';

import { Action } from 'redux';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { AjaxResponse } from 'rxjs/ajax';
import { handleRequestFailureInEpic } from '../../utils/error-handling';
import { customAjax } from '../../utils/ajax-wrapper';

const loadIncoming = (payload: AjaxResponse): IncomingDataGetResultAction => {
  return {
    type: IncomingDataAction.GET_RESULT,
    payload: { ...payload.response },
  };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const loadIncomingOnError = (error: any): any => {
  if (error.status == 404) {
    return {
      type: IncomingDataAction.NON_EXISTENT_INCOMING,
    };
  }

  return handleRequestFailureInEpic(error);
};

const getSubmitResult = (payload: AjaxResponse): IncomingDataResultAction => {
  return {
    type: IncomingDataAction.RESULT,
    payload: { ...payload.response },
  };
};

export const getIncomingDataEpic: Epic<Action, Action> = (action$: Observable<Action>): Observable<Action> =>
  action$.pipe(
    ofType(IncomingDataAction.GET),
    mergeMap((action) => {
      const demandId = (action as IncomingDataGetAction).demandId;

      return customAjax({
        subRoute: `/dossier/v1/demands/${demandId}/incomings`,
        withCredentials: true,
        method: 'GET',
      }).pipe(
        map((response) => loadIncoming(response)),
        catchError((error) => {
          return of(loadIncomingOnError(error));
        }),
      );
    }),
  );

export const editIncomingDataEpic: Epic<Action, Action> = (action$: Observable<Action>): Observable<Action> =>
  action$.pipe(
    ofType(IncomingDataAction.SUBMIT),
    mergeMap((action) => {
      const incomingData = (action as IncomingDataSubmitAction).payload;
      const demandId = (action as IncomingDataSubmitAction).demandId;

      return customAjax({
        subRoute: `/dossier/v1/demands/${demandId}/incomings`,
        withCredentials: true,
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(incomingData),
      }).pipe(
        map((response) => getSubmitResult(response)),
        catchError((error) => {
          return of(handleRequestFailureInEpic(error));
        }),
      );
    }),
  );
