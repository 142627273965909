import produce from 'immer';
import moment from 'moment';
import { Action } from 'redux';
import { ZoomDebtInfoProps } from '../../components/base-page-components/debt/ZoomDebtInfo';
import { ZoomPayoutInfoProps } from '../../components/base-page-components/payout/payout-info/ZoomPayoutInfo';
import { ZoomScholarshipItemProps } from '../../components/base-page-components/scholarship/scholarship-item/ZoomScholarshipItem';
import {
  Education,
  PowerOfAttorney,
  ZoomUserInfoProps,
} from '../../components/base-page-components/user/user-info/ZoomUserInfo';
import { DemandOverviewAction, DemandOverviewActionProgressResult } from '../demand/actions';
import { UserDataAction, UserDataSetNewDemandAction } from '../user-data/actions';
import { DashboardData } from './types';

const education: Education = {
  name: 'Bachelorstudium',
  duration: '2020 - 2023',
  institution: 'FH Windisch',
};

const powerOfAttorney1: PowerOfAttorney = {
  firstName: 'Karin',
  lastName: 'Hauser',
  birthDate: new Date(),
  email: 'karin.hauser@gmail.com',
};

const powerOfAttorney2: PowerOfAttorney = {
  firstName: 'Beat',
  lastName: 'Hauser',
  birthDate: new Date(),
  email: 'beat.hauser@gmail.com',
};

const userInfo: ZoomUserInfoProps = {
  firstName: 'Adrian',
  lastName: 'Hauser',
  dateOfBirth: '14.05.2003',
  ahv13n: '756.0000.0000.12',
  phoneNumber: '0764777777',
  emailAddress: 'beat.hauser@gmail.com',
  street: 'Blumenweg',
  streetNumber: '8',
  postalCode: '5410',
  city: 'Ennetbaden',
  country: 'Schweiz',
  labelEditData: 'Meine Daten bearbeiten',
  labelMyData: 'Meine Daten',
  labelMyEducation: 'Meine Ausbildung',
  labelMyPowerOfAttorney: 'Meine Vollmachten',
  labelNoEducations: 'Keine Ausbildung erfasst',
  labelNoMyData: 'Daten werden nach den ersten Sptipendienantrag angezeigt',
  labelEditEducation: 'Ausbildungsstand melden',
  labelEditPowerOfAttorney: 'Vollmachten verwalten',
  education: [education],
  powerOfAttorneys: [powerOfAttorney1, powerOfAttorney2],
  dossierId: 1,
};

const debtInfo: ZoomDebtInfoProps = {
  debtAmount: 5000,
  title: 'Meine Schulden beim Kanton Aargau',
  subtitle: 'Offener Betrag',
  amount: 15000,
  totalAmount: 20000,
  labelTotalAmount: 'Total',
  labelLegendRepayment: 'Darlehen zurückbezahlt',
  labelLegendStatus: 'offen',
};

const payoutInfo: ZoomPayoutInfoProps = {
  title: 'Aktuelle Auszahlungssituation',
  labelDetails: 'Details',
};

const initialState: DashboardData = {
  userInfo: userInfo,
  debtInfo: debtInfo,
  payoutInfo: payoutInfo,
  scholarships: [],
};

export function dashboardReducer(state = initialState, action: Action): DashboardData {
  return produce(state, (draft) => {
    switch (action.type) {
      case UserDataAction.SET_NEW_DEMAND:
        const demandData = action as UserDataSetNewDemandAction;
        let demandYear = '';
        if (demandData.payload.requestPeriodStartDate && demandData.payload.requestPeriodEndDate) {
          const from = moment(demandData.payload.requestPeriodStartDate).format('YYYY');
          const to = moment(demandData.payload.requestPeriodEndDate).format('YYYY');
          demandYear = from + '/' + to;
        }

        const scholarship: ZoomScholarshipItemProps = {
          name: 'Stipendienantrag ' + demandYear,
          institution: '',
          status: 'STATUS',
          completeness: 10,
          active: demandData.payload.isActive,
          tags: [],
          labelContinueFillin: '',
          labelDetails: '',
          labelCompleted: '',
          dossierId: demandData.dossierId,
          demandId: demandData.demandId,
          pifId: demandData.payload.pifId,
        };
        draft.scholarships = [scholarship];
        break;
      case DemandOverviewAction.PROGRESS_RESULT:
        const progressResult = action as DemandOverviewActionProgressResult;
        const index = draft.scholarships.findIndex(
          (scholarship) => scholarship.demandId === progressResult.payload.demandId,
        );
        if (index > -1) {
          draft.scholarships[index].completeness = Number(progressResult.payload.globalPercentage.toFixed(0));
        }
        break;
    }
    return draft;
  });
}
