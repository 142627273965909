import { Button, Container, Grid } from '@material-ui/core';
import React, { ReactElement } from 'react';
import ZoomFooter, { ZoomFooterProps } from '../../components/base-page-components/footer/ZoomFooter';
import ZoomHeader, { ZoomHeaderProps } from './../../components/base-page-components/header/ZoomHeader';
import './Homepage.scss';

export interface HomepageProps {
  title?: string;
  imageTextTitle?: string;
  imageTextDescription?: string;
  loginTitle?: string;
  loginFirstParagraph?: string;
  loginSecondParagraph?: string;
  loginButton?: string;
  registerTitle?: string;
  registerFirstParagraph?: string;
  registerSecondParagraph?: string;
  registerButton?: string;
  breadcrumbLabels?: Array<string>;
  footerProps: ZoomFooterProps;
  headerProps: ZoomHeaderProps;
}

export const Homepage = (props: HomepageProps): ReactElement => {
  const openRegisterUrlInNewWindow = () => {
    window.open(process.env.REACT_APP_REGISTER_URL, '_blank');
  };

  return (
    <>
      <ZoomHeader
        appName="Stipendienportal"
        userName="Max Mustermann"
        ahvn="756-4352-9990-13"
        hideUserMenu={true}
        labelMyData="Meine Daten"
        labelMyEducation="Meine Ausbildungen"
        labelMyScholarship="Meine Stipendienanträge"
        labelPayout="Auszahlungen"
        labelRefund="Rückzahlungen"
        labelPowerOfAttorney="Vollmachten"
        labelScholarshipHolder="Stipendiat wählen"
        labelLogout="Abmelden"
      />
      <div className="main main-home">
        <Grid>
          <Grid item>
            <div className="top-div">
              <Container>
                <div className="top-pagesection">
                  <div className="image-text">
                    <h1>{props.imageTextTitle}</h1>
                    <p>{props.imageTextDescription}</p>
                  </div>
                </div>
              </Container>
            </div>
            <Container className="login-register-div">
              <Grid container direction="row" alignItems="stretch" justify="space-around" spacing={2}>
                <Grid item xs={5} sm={5}>
                  <h1>{props.loginTitle}</h1>
                  <p>{props.loginFirstParagraph}</p>
                  <p>{props.loginSecondParagraph}</p>
                </Grid>
                <Grid item xs={5} sm={5}>
                  <h1>{props.registerTitle}</h1>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: props.registerFirstParagraph ? props.registerFirstParagraph : '',
                    }}
                  ></p>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: props.registerSecondParagraph ? props.registerSecondParagraph : '',
                    }}
                  ></p>
                </Grid>
                <Grid item xs={5} sm={5}>
                  <Button color="primary" href="/login" size="large" variant="contained" id="login-btn">
                    {props.loginButton}
                  </Button>
                </Grid>
                <Grid item xs={5} sm={5}>
                  <Button
                    color="primary"
                    onClick={openRegisterUrlInNewWindow}
                    size="large"
                    variant="outlined"
                    id="register-btn"
                  >
                    {props.registerButton}
                  </Button>
                </Grid>
              </Grid>
            </Container>
          </Grid>
        </Grid>
      </div>
      <ZoomFooter {...props.footerProps}></ZoomFooter>
    </>
  );
};
