import React, { ReactElement } from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import { black } from './../../theme';

export function ZoomChildIcon(props: SvgIconProps): ReactElement {
  const color = props.htmlColor ? props.htmlColor : black.main;
  return (
    <SvgIcon {...props}>
      <g fill="none" fillRule="evenodd">
        <g>
          <g transform="translate(-1048 -257) translate(1048 257)">
            <path d="M0 0H80V80H0z" />
            <path
              fill={color}
              fillRule="nonzero"
              d="M40.6 17.53c-12.658 0-22.92 10.262-22.92 22.92 0 12.658 10.262 22.92 22.92 22.92 12.658 0 22.92-10.262 22.92-22.92-.017-12.652-10.268-22.903-22.92-22.92zm0 2c11.12-.073 20.38 8.512 21.148 19.605.768 11.093-7.22 20.872-18.245 22.332-11.023 1.46-21.282-5.902-23.429-16.813-2.146-10.91 4.56-21.61 15.316-24.434-.743 1.855-.648 3.94.26 5.72 1.255 2.165 3.503 3.566 6 3.74.552 0 1-.448 1-1s-.448-1-1-1c-1.759-.16-3.333-1.158-4.23-2.68-1.3-2.47.93-5.16 1-5.28l2.18-.19z"
            />
            <circle cx="33.49" cy="35.61" r="2" fill={color} fillRule="nonzero" />
            <circle cx="47.9" cy="35.61" r="2" fill={color} fillRule="nonzero" />
            <path
              fill={color}
              fillRule="nonzero"
              d="M46.41 46.39c-.475-.282-1.088-.125-1.37.35-.952 1.529-2.591 2.496-4.39 2.59-1.77-.086-3.38-1.05-4.29-2.57-.179-.31-.51-.503-.868-.503-.359-.001-.69.19-.87.5-.18.31-.18.692-.002 1.003 1.256 2.144 3.517 3.501 6 3.6 2.507-.098 4.8-1.441 6.11-3.58.144-.226.192-.5.131-.762-.06-.261-.222-.488-.451-.628z"
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}
