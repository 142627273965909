import { CaseCreateDto } from './../notifications/types';

export enum ForbearanceAction {
  SUBMIT = 'FORBEARANCE_SUBMIT',
  SUBMIT_ERROR = 'FORBEARANCE_SUBMIT_ERROR',
}

export interface ForbearanceSubmitAction {
  type: typeof ForbearanceAction.SUBMIT;
  payload: CaseCreateDto;
  dossierId: number;
}

export interface ForbearanceSubmitErrorAction {
  type: typeof ForbearanceAction.SUBMIT_ERROR;
  payload: string;
}

export type ForbearanceActionType = ForbearanceSubmitAction | ForbearanceSubmitErrorAction;
