import React from 'react';

import { Menu, MenuItem, MenuProps } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { blue } from '../theme';

export const ZoomStyledMenu = withStyles({
  paper: {
    border: '1px solid ' + blue.main,
  },
})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

export const ZoomStyledMenuItem = withStyles((theme) => ({
  root: {
    color: blue.main,
    width: '320px',
    '&:focus': {
      backgroundColor: theme.palette?.primary?.main ? theme.palette.primary.main : 'blue',
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette?.common?.white ? theme.palette.common.white : 'white',
      },
    },
    '& .MuiListItemText-root': {
      marginLeft: '7px',
    },
  },
}))(MenuItem);
