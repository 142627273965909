import React, { ReactElement } from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import { black } from './../../theme';

export function ZoomPdfIcon(props: SvgIconProps): ReactElement {
  const color = props.htmlColor ? props.htmlColor : black.main;
  return (
    <SvgIcon {...props}>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <path d="M0 0H80V80H0z" transform="translate(-156 -257) translate(156 257)" />
            <path
              d="M60.65 31.31L49.36 31.31 49.36 20.36 31.94 20.36 31.95 35.29 60.65 35.29z"
              transform="translate(-156 -257) translate(156 257)"
            />
            <path d="M52.48 28.18L59.01 28.18 52.48 21.65z" transform="translate(-156 -257) translate(156 257)" />
            <path
              fill={color}
              fillRule="nonzero"
              d="M60.65 60.2L31.95 60.2 31.95 56.22 27.95 56.22 27.95 64.2 64.65 64.2 64.65 56.22 60.65 56.22zM63.14 27.72l-5.35-5.35-4.62-4.61c-1.046-.934-2.408-1.435-3.81-1.4H28v18.93h4V20.36h17.36v11h11.29v4h4v-4c-.061-1.353-.596-2.641-1.51-3.64zm-10.66.47v-6.54L59 28.18l-6.52.01zM43.17 42.26c-.38-.414-.848-.739-1.37-.95-.574-.22-1.186-.325-1.8-.31h-2.68v9.67H40c.62.012 1.236-.104 1.81-.34.528-.222.998-.564 1.37-1 .374-.434.653-.942.82-1.49.401-1.324.401-2.736 0-4.06-.17-.558-.453-1.075-.83-1.52zM28.65 41.57c-.249-.22-.542-.383-.86-.48-.401-.124-.82-.182-1.24-.17h-1.91v4.93h1.91c.417.008.833-.053 1.23-.18.324-.1.621-.27.87-.5.232-.222.407-.496.51-.8.115-.32.173-.66.17-1 .007-.341-.05-.68-.17-1-.098-.306-.274-.582-.51-.8z"
              transform="translate(-156 -257) translate(156 257)"
            />
            <path
              fill={color}
              fillRule="nonzero"
              d="M16.62 35.29v20.93h48V35.29h-48zm14.94 9.86c-.217.548-.56 1.038-1 1.43-.485.424-1.054.741-1.67.93-.758.233-1.547.345-2.34.33h-1.91v4.84h-2.55V38.94h4.46c.809-.015 1.615.096 2.39.33.611.179 1.177.485 1.66.9.442.375.785.852 1 1.39.217.561.326 1.158.32 1.76-.001.627-.124 1.249-.36 1.83zm14.89 3.44c-.326.817-.82 1.556-1.45 2.17-.627.62-1.376 1.104-2.2 1.42-.91.343-1.877.512-2.85.5h-5.19V38.94H40c.974-.014 1.941.16 2.85.51.806.322 1.538.805 2.15 1.42.628.6 1.122 1.326 1.45 2.13.367.891.554 1.846.55 2.81-.002.953-.189 1.897-.55 2.78zM58.78 41h-6.09v4h5.14v2h-5.14v5.6h-2.57V38.94h8.66V41z"
              transform="translate(-156 -257) translate(156 257)"
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}
