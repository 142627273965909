import { FormControl, FormControlLabel, FormGroup, FormHelperText } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import React, { ChangeEvent, ReactElement, useEffect, useState } from 'react';

export interface ZoomCheckboxInputProps {
  label: string;
  error?: boolean;
  errorMessage?: string;
  value?: boolean;
  name?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
}

const ZoomTextInput = (props: ZoomCheckboxInputProps): ReactElement => {
  const [value, setValue] = useState(props.value ? props.value : false);
  const [error, setError] = useState(props.error ? true : false);
  const [errorMessage, setErrorMessage] = useState(props.errorMessage ? props.errorMessage : '');

  useEffect(() => {
    setValue(props.value ? props.value : false);
    setError(props.error ? true : false);
    setErrorMessage(props.errorMessage ? props.errorMessage : '');
  }, [props.value, props.name, props.error, props.errorMessage]);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setValue(e.target.checked);
    if (props.onChange) {
      props.onChange(e);
    }
  };

  return (
    <div>
      <FormControl required error={error} component="fieldset">
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                data-testid="checkbox-input"
                checked={value}
                color="primary"
                name={props.name}
                onChange={handleInputChange}
              ></Checkbox>
            }
            label={props.label}
          />
        </FormGroup>
        <FormHelperText>{errorMessage}</FormHelperText>
      </FormControl>
    </div>
  );
};

export default ZoomTextInput;
