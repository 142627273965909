import produce from 'immer';
import { IncomingDataAction, IncomingDataActionType } from './actions';
import { IncomingData } from './types';

export const initialState: IncomingData = {};

export function incomingDataReducer(state = initialState, action: IncomingDataActionType): IncomingData {
  return produce(state, (draft) => {
    switch (action.type) {
      case IncomingDataAction.RESULT:
        draft = action.payload;
        break;
      case IncomingDataAction.GET:
        const getResult = action;
        if (getResult.payload) {
          draft = getResult.payload;
        }
        break;
      case IncomingDataAction.GET_RESULT:
        draft = action.payload;
        break;
    }
    return draft;
  });
}
