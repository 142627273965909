import { Observable, of } from 'rxjs';
import { Epic, ofType } from 'redux-observable';
import {
  NotesDataGetResultAction,
  NotesDataSubmitAction,
  NotesDataAction,
  NotesDataGetAction,
  NotesDataSubmitResultAction,
} from './actions';

import { Action } from 'redux';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { AjaxResponse } from 'rxjs/ajax';
import { handleRequestFailureInEpic } from '../../utils/error-handling';
import { customAjax } from '../../utils/ajax-wrapper';

const loadNotes = (payload: AjaxResponse): NotesDataGetResultAction => {
  return {
    type: NotesDataAction.GET_RESULT,
    payload: payload.response.comment,
  };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const loadNotesOnError = (error: any): any => {
  if (error.status == 404) {
    return {
      type: NotesDataAction.GET_RESULT,
      payload: '',
    };
  }

  return handleRequestFailureInEpic(error);
};

const getNotesResult = (payload: AjaxResponse): NotesDataSubmitResultAction => {
  return {
    type: NotesDataAction.SUBMIT_RESULT,
    payload: payload.response.comment,
  };
};

export const getNotesDataEpic: Epic<Action, Action> = (action$: Observable<Action>): Observable<Action> =>
  action$.pipe(
    ofType(NotesDataAction.GET),
    mergeMap((action) => {
      const demandId = (action as NotesDataGetAction).payload;

      return customAjax({
        subRoute: `/dossier/v1/demands/${demandId}/comment-entity`,
        withCredentials: true,
        method: 'GET',
      }).pipe(
        map((response) => loadNotes(response)),
        catchError((error) => {
          return of(loadNotesOnError(error));
        }),
      );
    }),
  );

export const editNotesDataEpic: Epic<Action, Action> = (action$: Observable<Action>): Observable<Action> =>
  action$.pipe(
    ofType(NotesDataAction.SUBMIT),
    mergeMap((action) => {
      const outgoingData = (action as NotesDataSubmitAction).payload;
      const demandId = (action as NotesDataSubmitAction).demandId;

      return customAjax({
        subRoute: `/dossier/v1/demands/${demandId}/comment-entity`,
        withCredentials: true,
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(outgoingData),
      }).pipe(
        map((response) => getNotesResult(response)),
        catchError((error) => {
          return of(handleRequestFailureInEpic(error));
        }),
      );
    }),
  );
