import { Observable, of } from 'rxjs';
import { Epic, ofType } from 'redux-observable';
import {
  PartnerDataGetResultAction,
  PartnerDataAction,
  PartnerDataResultAction,
  PartnerDataSubmitAction,
  PartnerDataGetAction,
} from './actions';

import { Action } from 'redux';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { AjaxResponse } from 'rxjs/ajax';
import { handleRequestFailureInEpic } from '../../utils/error-handling';
import { customAjax } from '../../utils/ajax-wrapper';

const addPartner = (payload: AjaxResponse): PartnerDataGetResultAction => {
  return {
    type: PartnerDataAction.GET_RESULT,
    payload: { ...payload.response },
  };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getPartnerOnError = (error: any): any => {
  if (error.status === 404) {
    return { type: PartnerDataAction.NON_EXISTENT_PARTNER };
  }

  return handleRequestFailureInEpic(error);
};

const getSubmitResult = (payload: AjaxResponse): PartnerDataResultAction => {
  return {
    type: PartnerDataAction.RESULT,
    payload: { ...payload.response },
  };
};

export const getParentDataEpic: Epic<Action, Action> = (action$: Observable<Action>): Observable<Action> =>
  action$.pipe(
    ofType(PartnerDataAction.GET),
    mergeMap((action) => {
      const pifId = (action as PartnerDataGetAction).pifId;
      return customAjax({
        subRoute: `/dossier/v1/persons-in-formation/${pifId}/lifepartner`,
        withCredentials: true,
        method: 'GET',
      }).pipe(
        map((response) => addPartner(response)),
        catchError((error) => {
          return of(getPartnerOnError(error));
        }),
      );
    }),
  );

export const sendPartnerDataEpic: Epic<Action, Action> = (action$: Observable<Action>): Observable<Action> =>
  action$.pipe(
    ofType(PartnerDataAction.SUBMIT),
    mergeMap((action) => {
      const outgoingDataAction: PartnerDataSubmitAction = action as PartnerDataSubmitAction;
      const pifId = (action as PartnerDataSubmitAction).pifId;

      return customAjax({
        subRoute: `/dossier/v1/persons-in-formation/${pifId}/lifepartner`,
        withCredentials: true,
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(outgoingDataAction.payload),
      }).pipe(
        map((response) => getSubmitResult(response)),
        catchError((error) => {
          return of(handleRequestFailureInEpic(error));
        }),
      );
    }),
  );
