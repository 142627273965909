import { Breadcrumbs, Button, Card, CardContent, CircularProgress, Container, Grid, Link } from '@material-ui/core';
import React, { ChangeEvent, ReactElement, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';
import { Action } from 'redux';
import ZoomFooter, {
  mapStoreToFooterProps,
  ZoomFooterProps,
} from '../../components/base-page-components/footer/ZoomFooter';
import ZoomHeader, {
  mapStoreToHeaderProps,
  ZoomHeaderProps,
} from '../../components/base-page-components/header/ZoomHeader';
import ZoomTextInput from '../../components/forms/text-input/ZoomTextInput';
import { ZoomArrowLeftIcon } from '../../components/icons/ZoomArrowLeftIcon';
import { i18nLabel } from '../../store/i18n/helpers';
import { EditContactDataPageAction, EditContactDataPageGetAction } from '../../store/my-data-contact/actions';
import { EditContactDataPage } from '../../store/my-data-contact/types';
import { WIAAStore } from '../../store/types';
import { blue } from './../../theme';
import { emailValidation, requiredValidation } from './../../utils/validators';
import './EditContactData.scss';

export interface EditContactDataProps {
  labelTitle?: string;
  phoneNumber?: string;
  email?: string;
  labelPhoneNumber?: string;
  labelEmail?: string;
  labelBackArrow?: string;
  labelCancel?: string;
  labelSave?: string;
  breadcrumbLabels?: Array<string>;
  errorLabels?: { [key: string]: string };
  headerProps: ZoomHeaderProps;
  footerProps: ZoomFooterProps;
  dossierId: number;
  isLoading?: boolean;
  dispatch?: (action: Action) => void;
}

interface EditContactDataErrors {
  phoneNumber?: string;
  email?: string;
}

export const EditContactData = (props: EditContactDataProps): ReactElement => {
  const [phoneNumber, setPhoneNumber] = useState(props.phoneNumber ? props.phoneNumber : '');
  const [email, setEmail] = useState(props.email ? props.email : '');
  const history = useHistory();
  const initialErrors: EditContactDataErrors = {};
  const [errors, setErrors] = useState(initialErrors);

  useEffect(() => {
    if (props.dispatch) {
      const getUserEditContactData: EditContactDataPageGetAction = {
        type: EditContactDataPageAction.GET,
        payload: props.dossierId,
      };
      props.dispatch(getUserEditContactData);
    }
  }, []);

  useEffect(() => {
    setPhoneNumber(props.phoneNumber ? props.phoneNumber : '');
    setEmail(props.email ? props.email : '');
  }, [props]);

  const onChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    switch (e.target.name) {
      case 'phoneNumber':
        setPhoneNumber(e.target.value);
        setErrors({
          ...errors,
          phoneNumber: requiredValidation(e.target.value, props.errorLabels ? props.errorLabels : {}),
        });
        break;
      case 'email':
        setEmail(e.target.value);
        setErrors({ ...errors, email: emailValidation(e.target.value, props.errorLabels ? props.errorLabels : {}) });
        break;
    }
  };

  const getEditContactData = (): EditContactDataPage => {
    return {
      phoneNumberPrivate: phoneNumber,
      emailAddress: email,
    };
  };

  const getFormErrors = (): EditContactDataErrors => {
    const errors: EditContactDataErrors = {};
    errors.phoneNumber = requiredValidation(phoneNumber, props.errorLabels ? props.errorLabels : {});
    errors.email = emailValidation(email, props.errorLabels ? props.errorLabels : {});
    return errors;
  };

  const validateForm = (): boolean => {
    const newErrors = getFormErrors();
    setErrors(newErrors);
    const errorsPresent = Object.values(newErrors).some((value) => value !== '');
    return !errorsPresent;
  };

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    if (validateForm() && props.dispatch) {
      const submitAction = {
        type: EditContactDataPageAction.SUBMIT,
        payload: getEditContactData(),
        dossierId: props.dossierId,
      };
      props.dispatch(submitAction);
    }
    event.preventDefault();
  };

  return (
    <>
      <ZoomHeader {...props.headerProps} dispatch={props.dispatch}></ZoomHeader>
      <main className="main">
        {props.isLoading ? (
          <div style={{ textAlign: 'center' }}>
            <CircularProgress></CircularProgress>
          </div>
        ) : (
          <>
            <Container>
              <div id="main-page-container">
                <Breadcrumbs separator=">" aria-label="breadcrumb">
                  <Link
                    style={{ fontFamily: 'Proxima Nova Cond' }}
                    color="inherit"
                    href={'/dashboard/dossier/' + props.dossierId}
                  >
                    <>{props.breadcrumbLabels && props.breadcrumbLabels[0]}</>
                  </Link>
                  <Link
                    style={{ fontFamily: 'Proxima Nova Cond' }}
                    color="inherit"
                    href={'/my-data/dossier-intranet/mydata/' + props.dossierId}
                  >
                    <>{props.breadcrumbLabels && props.breadcrumbLabels[1]}</>
                  </Link>
                  <Link style={{ fontFamily: 'Proxima Nova Cond' }} color="inherit">
                    <b>{props.breadcrumbLabels && props.breadcrumbLabels[2]}</b>
                  </Link>
                </Breadcrumbs>
                <div className="main-page-info">
                  <div className="back-button-desktop" onClick={() => history.goBack()}>
                    <ZoomArrowLeftIcon viewBox="0 0 80 80" fontSize="large" htmlColor={blue.main}></ZoomArrowLeftIcon>
                    {props.labelBackArrow}
                  </div>
                  <h1 className="title-desktop">{props.labelTitle}</h1>
                </div>
                <Card>
                  <CardContent>
                    <form onSubmit={onSubmit}>
                      <div className="form-element-container">
                        <ZoomTextInput
                          label={props.labelPhoneNumber ? props.labelPhoneNumber : ''}
                          value={phoneNumber}
                          name="phoneNumber"
                          onChange={onChange}
                          error={!!errors.phoneNumber}
                          errorMessage={errors.phoneNumber}
                        />
                      </div>
                      <div className="form-element-container">
                        <ZoomTextInput
                          label={props.labelEmail ? props.labelEmail : ''}
                          value={email}
                          name="email"
                          onChange={onChange}
                          error={!!errors.email}
                          errorMessage={errors.email}
                        />
                      </div>
                      <Grid container direction="row" spacing={2} style={{ paddingBottom: '1rem' }}>
                        <Grid item xs={6} sm={6}>
                          <div className="form-actions-container-cancel">
                            <Button
                              color="primary"
                              size="large"
                              type="reset"
                              variant="outlined"
                              id="cancel-edit-contact-data"
                              style={{ width: '144px' }}
                              onClick={() => history.goBack()}
                            >
                              {props.labelCancel}
                            </Button>
                          </div>
                        </Grid>
                        <Grid item xs={6} sm={6}>
                          <div className="form-actions-container-save">
                            <Button
                              color="primary"
                              size="large"
                              type="submit"
                              variant="contained"
                              id="save-edit-contact-data"
                              style={{ width: '144px' }}
                            >
                              {props.labelSave}
                            </Button>
                          </div>
                        </Grid>
                      </Grid>
                    </form>
                  </CardContent>
                </Card>
              </div>
            </Container>
          </>
        )}
      </main>
      <ZoomFooter {...props.footerProps}></ZoomFooter>
    </>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export function mapStateToProps(store: WIAAStore, ownProps: any): EditContactDataProps {
  const { dossierId } = ownProps.match.params;
  const headerProps: ZoomHeaderProps = mapStoreToHeaderProps(store, dossierId);
  const footerProps: ZoomFooterProps = mapStoreToFooterProps(store);

  return {
    headerProps,
    footerProps,
    labelTitle: i18nLabel(store.translationLabels, 'Edit_Contact_Data_Title', 'Kontaktdaten bearbeiten'),
    labelPhoneNumber: i18nLabel(store.translationLabels, 'Edit_Contact_Data_Phone_Number', 'Telefon*'),
    labelEmail: i18nLabel(store.translationLabels, 'Edit_Contact_Data_Email', 'E-Mail*'),
    labelBackArrow: i18nLabel(store.translationLabels, 'Edit_Contact_Data_Back_Arrow', 'Zurück'),
    labelCancel: i18nLabel(store.translationLabels, 'Edit_Contact_Data_Cancel', 'Abbrechen'),
    labelSave: i18nLabel(store.translationLabels, 'Edit_Contact_Data_Save', 'Speichern'),
    breadcrumbLabels: [
      i18nLabel(store.translationLabels, 'Edit_Contact_Data_Breadcrumb_Label_1', 'Übersicht'),
      i18nLabel(store.translationLabels, 'Edit_Contact_Data_Breadcrumb_Label_2', 'Meine Daten'),
      i18nLabel(store.translationLabels, 'Edit_Contact_Data_Breadcrumb_Label_3', 'Kontaktdaten bearbeiten'),
    ],
    errorLabels: store.translationLabels,
    phoneNumber: store.editContactDataPage.phoneNumberPrivate,
    email: store.editContactDataPage.emailAddress,
    dossierId: dossierId,
    isLoading: store.editContactDataPage.isLoading,
  };
}

export default withRouter(connect(mapStateToProps)(EditContactData));
