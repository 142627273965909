import { Button } from '@material-ui/core';
import React, { ChangeEvent, ReactElement, useEffect, useState } from 'react';
import ZoomDatePicker from '../../../../components/forms/date-picker/ZoomDatePicker';
import ZoomSelectInput from '../../../../components/forms/select-input/ZoomSelectInput';
import ZoomTextInput from '../../../../components/forms/text-input/ZoomTextInput';
import ZoomToggleInput from '../../../../components/forms/toggle-input/ZoomToggleInput';
import { setDateToMiddleOfMonth } from '../../../../utils/date-manipulation';
import { maxValueValidation, requiredValidation } from '../../../../utils/validators';

export interface Sibling {
  id?: number;
  firstName?: string;
  lastName?: string;
  dateOfBirth?: Date;
  livingType?: string;
  degreeOfRelation?: string;
  financiallyIndependent?: string;
  inFormation?: string;
  formationType?: string;
  formationEndDate?: Date;
  alimonyPayment?: string;
  alimonyAmount?: string;
  pensionReceived?: string;
  pensionAmount?: string;
}

export interface SiblingItemFormProps extends Sibling {
  labelFirstName?: string;
  labelLastName?: string;
  labelDateOfBirth?: string;
  labelLivingType?: string;
  labelDegreeOfRelation?: string;
  labelFinanciallyIndependent?: string;
  labelInFormation?: string;
  labelFormationType?: string;
  labelFormationEndDate?: string;
  labelAlimonyPayment?: string;
  labelAlimonyAmount?: string;
  labelPensionReceived?: string;
  labelPensionAmount?: string;
  helpTextDateOfBirth?: string;
  helpTextDegreeOfRelation?: string;
  helpTextFinanciallyIndependent?: string;
  helpTextInFormation?: string;
  labelSave?: string;
  labelCancel?: string;
  toggleButtonsTypes?: { name: string; value: string }[];
  livingTypeType?: { name: string; value: string }[];
  degreeOfRelationship?: { name: string; value: string }[];
  onSave?: (sibling: Sibling) => void;
  onDelete?: () => void;
  errorLabels?: { [key: string]: string };
}

interface SiblingItemDataErrors {
  id?: string;
  firstName?: string;
  lastName?: string;
  dateOfBirth?: string;
  livingType?: string;
  degreeOfRelation?: string;
  financiallyIndependent?: string;
  inFormation?: string;
  formationType?: string;
  formationEndDate?: string;
  alimonyPayment?: string;
  alimonyAmount?: string;
  pensionReceived?: string;
  pensionAmount?: string;
}

export const SiblingItemForm = (props: SiblingItemFormProps): ReactElement => {
  const [id] = useState(props.id ? props.id : 0);
  const [firstName, setFirstName] = useState(props.firstName ? props.firstName : '');
  const [lastName, setLastName] = useState(props.lastName ? props.lastName : '');
  const [dateOfBirth, setDateOfBirth] = useState(props.dateOfBirth ? props.dateOfBirth : undefined);
  const [livingType, setLivingType] = useState(props.livingType ? props.livingType : '9');
  const [degreeOfRelation, setDegreeOfRelation] = useState(props.degreeOfRelation ? props.degreeOfRelation : '0');
  const [financiallyIndependent, setFinanciallyIndependent] = useState(
    props.financiallyIndependent ? props.financiallyIndependent : '',
  );
  const [inFormation, setInFormation] = useState(props.inFormation ? props.inFormation : '');
  const [formationType, setFormationType] = useState(props.formationType ? props.formationType : '');
  const [formationEndDate, setFormationEndDate] = useState(props.formationEndDate ? props.formationEndDate : undefined);
  const [alimonyPayment, setAlimonyPayment] = useState(props.alimonyPayment ? props.alimonyPayment : '');
  const [alimonyAmount, setAlimonyAmount] = useState(props.alimonyAmount ? props.alimonyAmount : '');
  const [pensionReceived, setPensionReceived] = useState(props.pensionReceived ? props.pensionReceived : '');
  const [pensionAmount, setPensionAmount] = useState(props.pensionAmount ? props.pensionAmount : '');
  const initialErrors: SiblingItemDataErrors = {};
  const [errors, setErrors] = useState(initialErrors);

  useEffect(() => {
    setFirstName(props.firstName ? props.firstName : '');
    setLastName(props.lastName ? props.lastName : '');
    setDateOfBirth(props.dateOfBirth ? props.dateOfBirth : undefined);
    setLivingType(props.livingType ? props.livingType : '9');
    setDegreeOfRelation(props.degreeOfRelation ? props.degreeOfRelation : '0');
    setFinanciallyIndependent(props.financiallyIndependent ? props.financiallyIndependent : '');
    setInFormation(props.inFormation ? props.inFormation : '');
    setFormationType(props.formationType ? props.formationType : '');
    setFormationEndDate(props.formationEndDate ? props.formationEndDate : undefined);
    setAlimonyPayment(props.alimonyPayment ? props.alimonyPayment : '');
    setAlimonyAmount(props.alimonyAmount ? props.alimonyAmount : '');
    setPensionReceived(props.pensionReceived ? props.pensionReceived : '');
    setPensionAmount(props.pensionAmount ? props.pensionAmount : '');
  }, [props]);

  const onChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    switch (e.target.name) {
      case 'firstName':
        setFirstName(e.target.value);
        setErrors({
          ...errors,
          firstName: requiredValidation(e.target.value, props.errorLabels ? props.errorLabels : {}),
        });
        break;
      case 'lastName':
        setLastName(e.target.value);
        setErrors({
          ...errors,
          lastName: requiredValidation(e.target.value, props.errorLabels ? props.errorLabels : {}),
        });
        break;
      case 'formationType':
        setFormationType(e.target.value);
        setErrors({
          ...errors,
          formationType: requiredValidation(e.target.value, props.errorLabels ? props.errorLabels : {}),
        });
        break;
      case 'alimonyAmount':
        setAlimonyAmount(e.target.value);
        setErrors({
          ...errors,
          alimonyAmount: maxValueValidation(e.target.value, 9999999, props.errorLabels ? props.errorLabels : {}),
        });
        break;
      case 'pensionAmount':
        setPensionAmount(e.target.value);
        setErrors({
          ...errors,
          pensionAmount: maxValueValidation(e.target.value, 9999999, props.errorLabels ? props.errorLabels : {}),
        });
        break;
    }
  };

  const onChangeDateOfBirth = (newDate: Date | null) => {
    if (null === newDate) {
      setDateOfBirth(undefined);
    } else {
      setDateOfBirth(newDate);
    }
    setErrors({
      ...errors,
      dateOfBirth: newDate === null ? requiredValidation('', props.errorLabels ? props.errorLabels : {}) : '',
    });
  };

  const onChangeLivingType = (newValue: string) => {
    setLivingType(newValue);
    setErrors({
      ...errors,
      livingType: newValue === '9' ? requiredValidation('', props.errorLabels ? props.errorLabels : {}) : '',
    });
  };

  const onChangeDegreeOfRelation = (newValue: string) => {
    setDegreeOfRelation(newValue);
    setErrors({
      ...errors,
      degreeOfRelation: newValue === '0' ? requiredValidation('', props.errorLabels ? props.errorLabels : {}) : '',
    });
  };

  const onClickFinanciallyIndependent = (newValue: string) => {
    setFinanciallyIndependent(newValue);
    setErrors({
      ...errors,
      financiallyIndependent: requiredValidation(newValue, props.errorLabels ? props.errorLabels : {}),
    });
  };

  const onClickInFormation = (newValue: string) => {
    setInFormation(newValue);
    setErrors({
      ...errors,
      inFormation: requiredValidation(newValue, props.errorLabels ? props.errorLabels : {}),
    });
  };

  const onChangeFormationEndDate = (newDate: Date | null) => {
    if (null === newDate) {
      setFormationEndDate(undefined);
    } else {
      setFormationEndDate(newDate);
    }
    setErrors({
      ...errors,
      formationEndDate: newDate === null ? requiredValidation('', props.errorLabels ? props.errorLabels : {}) : '',
    });
  };

  const onClickAlimonyPayment = (newValue: string) => {
    setAlimonyPayment(newValue);
    setErrors({
      ...errors,
      alimonyPayment: requiredValidation(newValue, props.errorLabels ? props.errorLabels : {}),
    });
  };

  const onClickPensionReceived = (newValue: string) => {
    setPensionReceived(newValue);
    setErrors({
      ...errors,
      pensionReceived: requiredValidation(newValue, props.errorLabels ? props.errorLabels : {}),
    });
  };

  const getFormErrors = (): SiblingItemDataErrors => {
    const errors: SiblingItemDataErrors = {};
    errors.firstName = requiredValidation(firstName, props.errorLabels ? props.errorLabels : {});
    errors.lastName = requiredValidation(lastName, props.errorLabels ? props.errorLabels : {});
    errors.dateOfBirth = requiredValidation(
      dateOfBirth ? new Date(dateOfBirth).toDateString() : '',
      props.errorLabels ? props.errorLabels : {},
    );
    errors.livingType = livingType === '9' ? requiredValidation('', props.errorLabels ? props.errorLabels : {}) : '';
    errors.degreeOfRelation =
      degreeOfRelation === '0' ? requiredValidation('', props.errorLabels ? props.errorLabels : {}) : '';
    errors.financiallyIndependent = requiredValidation(
      financiallyIndependent,
      props.errorLabels ? props.errorLabels : {},
    );
    errors.inFormation = requiredValidation(inFormation, props.errorLabels ? props.errorLabels : {});
    errors.alimonyPayment = requiredValidation(alimonyPayment, props.errorLabels ? props.errorLabels : {});
    errors.pensionReceived = requiredValidation(pensionReceived, props.errorLabels ? props.errorLabels : {});
    if (inFormation === '1') {
      errors.formationType = requiredValidation(formationType, props.errorLabels ? props.errorLabels : {});
      errors.formationEndDate = requiredValidation(
        formationEndDate ? new Date(formationEndDate).toDateString() : '',
        props.errorLabels ? props.errorLabels : {},
      );
    }
    if (alimonyPayment === '1') {
      errors.alimonyAmount = maxValueValidation(alimonyAmount, 9999999, props.errorLabels ? props.errorLabels : {});
    }
    if (pensionReceived === '1') {
      errors.pensionAmount = maxValueValidation(pensionAmount, 9999999, props.errorLabels ? props.errorLabels : {});
    }
    return errors;
  };

  const validateForm = (): boolean => {
    const newErrors = getFormErrors();
    setErrors(newErrors);
    const errorsPresent = Object.values(newErrors).some((value) => value !== '');
    return !errorsPresent;
  };

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    if (validateForm()) {
    }
    event.preventDefault();
    const newErrors = getFormErrors();
    setErrors(newErrors);
    const errorsPresent = Object.values(newErrors).some((value) => value !== '');
    if (props.onSave && !errorsPresent) {
      props.onSave({
        id,
        firstName,
        lastName,
        dateOfBirth,
        livingType,
        degreeOfRelation,
        financiallyIndependent,
        inFormation,
        formationType,
        formationEndDate: setDateToMiddleOfMonth(formationEndDate),
        alimonyPayment,
        alimonyAmount,
        pensionReceived,
        pensionAmount,
      });
    }
  };

  const onDelete = () => {
    if (props.onDelete) {
      props.onDelete();
    }
  };

  return (
    <>
      <form onSubmit={onSubmit}>
        <div className="form-element-container">
          <ZoomTextInput
            label={props.labelFirstName ? props.labelFirstName : ''}
            name="firstName"
            value={firstName}
            onChange={onChange}
            error={!!errors.firstName}
            errorMessage={errors.firstName}
          ></ZoomTextInput>
        </div>
        <div className="form-element-container">
          <ZoomTextInput
            label={props.labelLastName ? props.labelLastName : ''}
            name="lastName"
            value={lastName}
            onChange={onChange}
            error={!!errors.lastName}
            errorMessage={errors.lastName}
          ></ZoomTextInput>
        </div>
        <div className="form-element-container">
          <ZoomDatePicker
            label={props.labelDateOfBirth ? props.labelDateOfBirth : ''}
            name="dateOfBirth"
            value={dateOfBirth}
            helpText={props.helpTextDateOfBirth}
            onChange={onChangeDateOfBirth}
            error={!!errors.dateOfBirth}
            errorMessage={errors.dateOfBirth}
          ></ZoomDatePicker>
        </div>
        <div className="form-element-container">
          <ZoomSelectInput
            label={props.labelLivingType ? props.labelLivingType : ''}
            options={props.livingTypeType ? props.livingTypeType : []}
            value={livingType}
            name="livingType"
            onChange={onChangeLivingType}
            error={!!errors.livingType}
            errorMessage={errors.livingType}
          ></ZoomSelectInput>
        </div>
        <div className="form-element-container">
          <ZoomSelectInput
            label={props.labelDegreeOfRelation ? props.labelDegreeOfRelation : ''}
            options={props.degreeOfRelationship ? props.degreeOfRelationship : []}
            value={degreeOfRelation}
            name="degreeOfRelation"
            helpText={props.helpTextDegreeOfRelation}
            onChange={onChangeDegreeOfRelation}
            error={!!errors.degreeOfRelation}
            errorMessage={errors.degreeOfRelation}
          ></ZoomSelectInput>
        </div>
        <div className="form-element-container">
          <ZoomToggleInput
            name="financiallyIndependent"
            value={financiallyIndependent}
            title={props.labelFinanciallyIndependent ? props.labelFinanciallyIndependent : ''}
            label1={props.toggleButtonsTypes ? props.toggleButtonsTypes[0].name : ''}
            value1={props.toggleButtonsTypes ? props.toggleButtonsTypes[0].value.toString() : ''}
            label2={props.toggleButtonsTypes ? props.toggleButtonsTypes[1].name : ''}
            value2={props.toggleButtonsTypes ? props.toggleButtonsTypes[1].value.toString() : ''}
            helpText={props.helpTextFinanciallyIndependent}
            onChange={onClickFinanciallyIndependent}
            error={!!errors.financiallyIndependent}
            errorMessage={errors.financiallyIndependent}
          ></ZoomToggleInput>
        </div>
        <div className="form-element-container">
          <ZoomToggleInput
            name="inFormation"
            value={inFormation}
            title={props.labelInFormation ? props.labelInFormation : ''}
            label1={props.toggleButtonsTypes ? props.toggleButtonsTypes[0].name : ''}
            value1={props.toggleButtonsTypes ? props.toggleButtonsTypes[0].value.toString() : ''}
            label2={props.toggleButtonsTypes ? props.toggleButtonsTypes[1].name : ''}
            value2={props.toggleButtonsTypes ? props.toggleButtonsTypes[1].value.toString() : ''}
            helpText={props.helpTextInFormation}
            onChange={onClickInFormation}
            error={!!errors.inFormation}
            errorMessage={errors.inFormation}
          ></ZoomToggleInput>
        </div>
        {inFormation === '1' && (
          <div className="form-element-container">
            <ZoomTextInput
              label={props.labelFormationType ? props.labelFormationType : ''}
              name="formationType"
              value={formationType}
              onChange={onChange}
              error={!!errors.formationType}
              errorMessage={errors.formationType}
            ></ZoomTextInput>
          </div>
        )}
        {inFormation === '1' && (
          <div className="form-element-container">
            <ZoomDatePicker
              label={props.labelFormationEndDate ? props.labelFormationEndDate : ''}
              name="formationEndDate"
              value={formationEndDate}
              formatMonthYearOnly={true}
              onChange={onChangeFormationEndDate}
              error={!!errors.formationEndDate}
              errorMessage={errors.formationEndDate}
            ></ZoomDatePicker>
          </div>
        )}
        <div className="form-element-container">
          <ZoomToggleInput
            name="alimonyPayment"
            value={alimonyPayment}
            title={props.labelAlimonyPayment ? props.labelAlimonyPayment : ''}
            label1={props.toggleButtonsTypes ? props.toggleButtonsTypes[0].name : ''}
            value1={props.toggleButtonsTypes ? props.toggleButtonsTypes[0].value.toString() : ''}
            label2={props.toggleButtonsTypes ? props.toggleButtonsTypes[1].name : ''}
            value2={props.toggleButtonsTypes ? props.toggleButtonsTypes[1].value.toString() : ''}
            onChange={onClickAlimonyPayment}
            error={!!errors.alimonyPayment}
            errorMessage={errors.alimonyPayment}
          ></ZoomToggleInput>
        </div>
        {alimonyPayment === '1' && (
          <div className="form-element-container">
            <ZoomTextInput
              label={props.labelAlimonyAmount ? props.labelAlimonyAmount : ''}
              name="alimonyAmount"
              value={alimonyAmount}
              onChange={onChange}
              error={!!errors.alimonyAmount}
              errorMessage={errors.alimonyAmount}
            ></ZoomTextInput>
          </div>
        )}
        <div className="form-element-container">
          <ZoomToggleInput
            name="pensionReceived"
            value={pensionReceived}
            title={props.labelPensionReceived ? props.labelPensionReceived : ''}
            label1={props.toggleButtonsTypes ? props.toggleButtonsTypes[0].name : ''}
            value1={props.toggleButtonsTypes ? props.toggleButtonsTypes[0].value.toString() : ''}
            label2={props.toggleButtonsTypes ? props.toggleButtonsTypes[1].name : ''}
            value2={props.toggleButtonsTypes ? props.toggleButtonsTypes[1].value.toString() : ''}
            onChange={onClickPensionReceived}
            error={!!errors.pensionReceived}
            errorMessage={errors.pensionReceived}
          ></ZoomToggleInput>
        </div>
        {pensionReceived === '1' && (
          <div className="form-element-container">
            <ZoomTextInput
              label={props.labelPensionAmount ? props.labelPensionAmount : ''}
              name="pensionAmount"
              value={pensionAmount}
              onChange={onChange}
              error={!!errors.pensionAmount}
              errorMessage={errors.pensionAmount}
            ></ZoomTextInput>
          </div>
        )}
        <div className="form-actions-container">
          <Button
            data-testid="save-sibling-button"
            id="save-sibling-button"
            color="primary"
            type="submit"
            variant="contained"
            size="small"
            title={props.labelSave}
          >
            {props.labelSave}
          </Button>
          <Button
            data-testid="cancel-sibling-button"
            id="cancel-sibling-button"
            color="primary"
            variant="outlined"
            size="small"
            title={props.labelCancel}
            onClick={() => {
              onDelete();
            }}
          >
            {props.labelCancel}
          </Button>
        </div>
      </form>
    </>
  );
};
