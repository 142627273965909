import { Container } from '@material-ui/core';
import React, { ReactElement } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Action } from 'redux';
import ZoomFooter, {
  mapStoreToFooterProps,
  ZoomFooterProps,
} from '../../components/base-page-components/footer/ZoomFooter';
import ZoomHeader, {
  mapStoreToHeaderProps,
  ZoomHeaderProps,
} from '../../components/base-page-components/header/ZoomHeader';
import { ZoomArrowLeftIcon } from '../../components/icons/ZoomArrowLeftIcon';
import { i18nLabel } from '../../store/i18n/helpers';
import { RoutingAction, RoutingRedirectToAction } from '../../store/routing/actions';
import { WIAAStore } from '../../store/types';
import { blue } from '../../theme';

export interface ErrorUnavailableInformationProps {
  message: string;
  labelBackToDashboard: string;
  headerProps: ZoomHeaderProps;
  footerProps: ZoomFooterProps;
  dossierId: number;
  dispatch?: (action: Action) => void;
}

export const ErrorUnavailableInformation = (props: ErrorUnavailableInformationProps): ReactElement => {
  const goToDashboard = () => {
    if (props.dispatch) {
      const redirectAction: RoutingRedirectToAction = {
        type: RoutingAction.REDIRECT_TO,
        payload: `/dashboard/dossier/${props.dossierId}`,
      };
      props.dispatch(redirectAction);
    }
  };

  return (
    <>
      <ZoomHeader {...props.headerProps} />
      <main className="main">
        <Container>
          <div id="main-page-container">
            <div className="main-page-info">
              <div className="back-button-desktop" onClick={() => goToDashboard()}>
                <ZoomArrowLeftIcon viewBox="0 0 80 80" fontSize="large" htmlColor={blue.main}></ZoomArrowLeftIcon>
                {props.labelBackToDashboard}
              </div>
            </div>
            <h2>{props.message}</h2>
          </div>
        </Container>
      </main>
      <ZoomFooter {...props.footerProps}></ZoomFooter>
    </>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function mapErrorUnavailableInformationStateToProps(
  store: WIAAStore,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
  ownProps: any,
): ErrorUnavailableInformationProps {
  const { dossierId } = ownProps.match.params;
  const headerProps: ZoomHeaderProps = mapStoreToHeaderProps(store);
  const footerProps: ZoomFooterProps = mapStoreToFooterProps(store);
  const props: ErrorUnavailableInformationProps = {
    dossierId,
    headerProps: headerProps,
    footerProps: footerProps,
    message: i18nLabel(
      store.translationLabels,
      'Error_Information_Not_Yet_Available_Message',
      'Dies Funktionalität steht Ihnen innerhalb einer Arbeitswoche, nach Einreichung des Antrages, zur Verfügung.',
    ),
    labelBackToDashboard: i18nLabel(
      store.translationLabels,
      'Error_Information_Not_Yet_Available_Back_To_Dashboard',
      'zurück zur Übersicht',
    ),
  };
  return props;
}

export default withRouter(connect(mapErrorUnavailableInformationStateToProps)(ErrorUnavailableInformation));
