import { OptionTranslation, Translation } from './types';

export enum TranslationLabelsAction {
  GET_ALL = 'TRANSLATION_LABELS_GET_ALL',
  GET_ALL_RESULT = 'TRANSLATION_LABELS_GET_ALL_RESULT',
}

export interface TranslationLabelsGetAllAction {
  type: typeof TranslationLabelsAction.GET_ALL;
}

export interface TranslationLabelsGetAllResultAction {
  type: typeof TranslationLabelsAction.GET_ALL_RESULT;
  payload: { labels: Array<Translation>; dropDowns: { [key: string]: Array<OptionTranslation> } };
}

export type TranslationLabelsActionType = TranslationLabelsGetAllAction | TranslationLabelsGetAllResultAction;
