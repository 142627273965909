import { produce } from 'immer';
import { Action } from 'redux';
import { ZoomHeaderProps } from '../../components/base-page-components/header/ZoomHeader';
import { UserDataAction, UserDataGetResultAction } from '../user-data/actions';

export const initialState: ZoomHeaderProps = {
  appName: 'Stipendienportal',
  userName: '',
  ahvn: '',
  labelMyData: 'Meine Daten',
  labelMyEducation: 'Meine Ausbildungen',
  labelMyScholarship: 'Meine Stipendienanträge',
  labelPayout: 'Auszahlungen',
  labelRefund: 'Rückzahlungen',
  labelPowerOfAttorney: 'Vollmachten',
  labelScholarshipHolder: 'Stipendiat wählen',
  labelLogout: 'Abmelden',
};

export function headerDataReducer(state = initialState, action: Action): ZoomHeaderProps {
  return produce(state, (draft) => {
    if (action.type == UserDataAction.GET_RESULT) {
      const userDataAction = action as UserDataGetResultAction;
      draft = {
        ...state,
        userName: userDataAction.payload.firstName + ' ' + userDataAction.payload.lastName,
        ahvn: userDataAction.payload.svn ? userDataAction.payload.svn : '',
      };
    }
    return draft;
  });
}
