import produce from 'immer';
import { ScholarshipDataAction, ScholarshipDataActionType } from './actions';
import { ScholarshipData } from './types';

export const initialState: ScholarshipData = {};

export function scholarshipDataReducer(state = initialState, action: ScholarshipDataActionType): ScholarshipData {
  return produce(state, (draft) => {
    switch (action.type) {
      case ScholarshipDataAction.RESULT:
        draft = action.payload;
        break;
      case ScholarshipDataAction.GET:
        const getResult = action;
        draft = getResult.payload;
        break;
      case ScholarshipDataAction.GET_RESULT:
        draft = action.payload;
        break;
    }
    return draft;
  });
}
