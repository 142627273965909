import { Observable, of } from 'rxjs';
import { Epic, ofType } from 'redux-observable';
import {
  LastDemandDataTakeoverGetAction,
  LastDemandDataTakeoverResultAction,
  LastDemandDataTakeoverAction,
} from './actions';

import { Action } from 'redux';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { AjaxResponse } from 'rxjs/ajax';
import { handleRequestFailureInEpic } from '../../utils/error-handling';
import { customAjax } from '../../utils/ajax-wrapper';

const processData = (payload: AjaxResponse): LastDemandDataTakeoverResultAction => {
  return {
    type: LastDemandDataTakeoverAction.RESULT,
    payload: payload.response,
  };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const handleGetError = (error: any): any => {
  if (error.status == 404) {
    return {
      type: LastDemandDataTakeoverAction.ERROR,
      payload: 'Does not exist',
    };
  }

  return handleRequestFailureInEpic(error);
};

export const getLastDemandDataEpic: Epic<Action, Action> = (action$: Observable<Action>): Observable<Action> =>
  action$.pipe(
    ofType(LastDemandDataTakeoverAction.GET),
    mergeMap((action) => {
      const getAction = action as LastDemandDataTakeoverGetAction;
      return customAjax({
        subRoute: `/dossier-intranet/v1/last-demand/${getAction.payload}`,
        withCredentials: true,
        method: 'GET',
      }).pipe(
        map((response) => processData(response)),
        catchError((error) => {
          return of(handleGetError(error));
        }),
      );
    }),
  );
