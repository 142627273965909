export enum NotesDataAction {
  GET = 'NOTES_GET',
  GET_RESULT = 'NOTES_GET_RESULT',
  SUBMIT = 'NOTES_SUBMIT',
  SUBMIT_RESULT = 'NOTES_SUBMIT_RESULT',
}

export interface NotesDataGetAction {
  type: typeof NotesDataAction.GET;
  payload: number;
}

export interface NotesDataGetResultAction {
  type: typeof NotesDataAction.GET_RESULT;
  payload: string;
}

export interface NotesDataSubmitAction {
  type: typeof NotesDataAction.SUBMIT;
  payload: { comment: string };
  demandId: number;
}

export interface NotesDataSubmitResultAction {
  type: typeof NotesDataAction.SUBMIT_RESULT;
  payload: string;
}
