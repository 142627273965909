import Button from '@material-ui/core/Button';
import produce from 'immer';
import React, { ReactElement, useEffect, useState } from 'react';
import { Sibling, SiblingItemForm } from './item/form/SiblingItemForm';
import { SiblingItemInfo } from './item/info/SiblingItemInfo';
import { WIAAStore } from '../../store/types';
import './SiblingsForm.scss';
import { SiblingData, SiblingsDataStore } from '../../store/siblings-data/types';
import { connect } from 'react-redux';
import {
  SiblingsDataAction,
  SiblingsDataActionType,
  SiblingsDataAddSiblingAction,
  SiblingsDataDeleteSiblingAction,
  SiblingsDataEditSiblingAction,
} from '../../store/siblings-data/actions';
import { withRouter } from 'react-router-dom';
import { i18nLabel } from '../../store/i18n/helpers';
import { setTimeToMiddleOfDay } from '../../utils/date-manipulation';
import { Grid, ListItemText, useMediaQuery } from '@material-ui/core';
import { blue, lightGrey } from '../../theme';
import { ZoomArrowDownIcon } from '../../components/icons/ZoomArrowDownIcon';
import { ZoomStyledMenu, ZoomStyledMenuItem } from './../../utils/zoom-styled-menu';
import { getDefaultSelectOption } from '../../utils/form-data';
import { extractToggleValue } from '../../utils/object-manipulation';

export interface SiblingsFormProps {
  labelFirstName?: string;
  labelLastName?: string;
  labelDateOfBirth?: string;
  labelLivingType?: string;
  labelDegreeOfRelation?: string;
  labelFinanciallyIndependent?: string;
  labelInFormation?: string;
  labelFormationType?: string;
  labelFormationEndDate?: string;
  labelAlimonyPayment?: string;
  labelAlimonyAmount?: string;
  labelPensionReceived?: string;
  labelPensionAmount?: string;
  labelTakeoverDataFromPreviousYear?: string;
  helpTextDateOfBirth?: string;
  helpTextDegreeOfRelation?: string;
  helpTextFinanciallyIndependent?: string;
  helpTextInFormation?: string;
  labelNoEntries?: string;
  labelAddAdditionalPositions?: string;
  labelSave?: string;
  labelCancel?: string;
  piaSiblings: Array<Sibling>;
  siblingsBeingEdited: Array<number>;
  pifId: number;
  errorLabels?: { [key: string]: string };
  toggleButtonsTypes?: { name: string; value: string }[];
  livingTypeType?: { name: string; value: string }[];
  degreeOfRelationship?: { name: string; value: string }[];
  piaSiblingsFromPreviousDemand?: Array<SiblingData>;
  dispatch?: (action: SiblingsDataActionType) => void;
}

const siblingData2sibling = (data: SiblingData, isFromLastDemand: boolean): Sibling => {
  return {
    id: data.id,
    firstName: data.firstName,
    lastName: data.lastName,
    dateOfBirth: data.dateOfBirth,
    livingType: data.livingType ? data.livingType.toString() : '',
    degreeOfRelation: data.degreeOfRelationId ? data.degreeOfRelationId.toString() : '',
    financiallyIndependent: !isFromLastDemand ? extractToggleValue(data.financiallyIndependent) : '',
    inFormation: !isFromLastDemand ? extractToggleValue(data.inFormation) : '',
    formationType: data.formationTypeId,
    formationEndDate: data.formationEndDate,
    alimonyPayment: extractToggleValue(data.alimonyPayment),
    alimonyAmount: data.alimonyAmount ? data.alimonyAmount.toString() : '',
    pensionReceived: extractToggleValue(data.pensionReceived),
    pensionAmount: data.pensionAmount ? data.pensionAmount.toString() : '',
  };
};

export const SiblingsForm = (props: SiblingsFormProps): ReactElement => {
  const [piaSiblings, setPiaSiblings] = useState(props.piaSiblings ? props.piaSiblings : []);
  const [siblingsBeingEdited, setSiblingsBeingEdited] = useState(
    props.siblingsBeingEdited ? props.siblingsBeingEdited : [],
  );
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const dataFromPreviousDemand = props.piaSiblingsFromPreviousDemand ? props.piaSiblingsFromPreviousDemand : [];
  const isSmallDevice = useMediaQuery('(max-width: 600px)');
  const [disableControls, setDisableControls] = useState(false);

  useEffect(() => {
    setPiaSiblings(props.piaSiblings ? props.piaSiblings : []);
  }, [props.piaSiblings]);

  const onSave = (index: number, sibling: Sibling) => {
    if (props.dispatch) {
      const siblingDataAction: SiblingsDataAddSiblingAction | SiblingsDataEditSiblingAction = {
        type: !sibling.id || sibling.id === 0 ? SiblingsDataAction.ADD_ITEM : SiblingsDataAction.EDIT_ITEM,
        payload: {
          id: sibling.id,
          dateOfBirth: setTimeToMiddleOfDay(sibling.dateOfBirth),
          firstName: sibling.firstName,
          lastName: sibling.lastName,
          livingType: sibling.livingType ? Number(sibling.livingType) : 0,
          degreeOfRelationId: sibling.degreeOfRelation ? Number(sibling.degreeOfRelation) : 0,
          financiallyIndependent: sibling.financiallyIndependent == '1' ? true : false,
          inFormation: sibling.inFormation == '1' ? true : false,
          formationTypeId: sibling.formationType,
          formationEndDate: sibling.formationEndDate,
          alimonyPayment: sibling.alimonyPayment == '1' ? true : false,
          alimonyAmount: sibling.alimonyAmount ? Number(sibling.alimonyAmount) : 0,
          pensionReceived: sibling.pensionReceived == '1' ? true : false,
          pensionAmount: sibling.pensionAmount ? Number(sibling.pensionAmount) : 0,
        } as SiblingData,
        pifId: props.pifId,
      };
      setDisableControls(false);
      props.dispatch(siblingDataAction);
    }

    const nextStateBeingEdited = produce(siblingsBeingEdited, (draft) => {
      const indexToDelete = draft.findIndex((i) => i === index);
      if (indexToDelete > -1) {
        draft.splice(indexToDelete, 1);
      }
    });
    setSiblingsBeingEdited(nextStateBeingEdited);
  };

  const onEdit = (index: number) => {
    const nextStateBeingEdited = produce(siblingsBeingEdited, (draft) => {
      draft.push(index);
    });
    setSiblingsBeingEdited(nextStateBeingEdited);
    setDisableControls(true);
  };

  const onDelete = (index: number) => {
    const siblingItemId = piaSiblings[index].id;

    const nextStateSiblings = produce(piaSiblings, (draft) => {
      draft.splice(index, 1);
    });
    setPiaSiblings(nextStateSiblings);

    const nextStateBeingEdited = produce(siblingsBeingEdited, (draft) => {
      const indexToDelete = draft.findIndex((i) => i === index);
      if (indexToDelete > -1) {
        draft.splice(indexToDelete, 1);
      }
    });
    setSiblingsBeingEdited(nextStateBeingEdited);

    if (siblingItemId && siblingItemId > 0 && props.dispatch) {
      const deleteChildData: SiblingsDataDeleteSiblingAction = {
        type: SiblingsDataAction.DELETE_ITEM,
        payload: siblingItemId,
        pifId: props.pifId,
      };
      props.dispatch(deleteChildData);
    }
    setDisableControls(false);
  };

  const addNewSibling = (data: SiblingData, isFromLastDemand: boolean) => {
    const nextState = produce(piaSiblings, (draft) => {
      draft.push(siblingData2sibling(data, isFromLastDemand));
    });
    setPiaSiblings(nextState);
    const nextStateBeingEdited = produce(siblingsBeingEdited, (draft) => {
      draft.push(nextState.length - 1);
    });
    setSiblingsBeingEdited(nextStateBeingEdited);
    setDisableControls(true);
  };

  const toRender = piaSiblings.map((sibling, index) => {
    if (siblingsBeingEdited.includes(index)) {
      const params = { ...props, ...sibling };
      return (
        <div className="sibling-form-wrapper" key={index} id={'edit-sibling-' + index}>
          <SiblingItemForm
            {...params}
            onSave={(sibling) => {
              onSave(index, sibling);
            }}
            onDelete={() => {
              onDelete(index);
            }}
          ></SiblingItemForm>
        </div>
      );
    }

    const firstName = sibling.firstName ? sibling.firstName : '';
    const lastName = sibling.lastName ? sibling.lastName : '';
    const dateOfBirth = sibling.dateOfBirth ? sibling.dateOfBirth : undefined;
    return (
      <SiblingItemInfo
        firstName={firstName}
        lastName={lastName}
        dateOfBirth={dateOfBirth}
        id={index}
        key={index}
        onEdit={() => {
          onEdit(index);
        }}
        onDelete={() => {
          onDelete(index);
        }}
        disableControls={disableControls}
      ></SiblingItemInfo>
    );
  });

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const piaSiblingsDataFromPreviousDemand = dataFromPreviousDemand.map((sibling, index) => {
    return (
      <ZoomStyledMenuItem key={index}>
        <a
          data-testid={'previous-data-' + index}
          style={{ display: 'inline-flex', width: '100%' }}
          onClick={() => {
            const count = piaSiblings.length;
            addNewSibling(sibling, true);
            handleClose();
            setTimeout(() => {
              document.getElementById('edit-sibling-' + count)?.scrollIntoView();
              window.scrollBy(0, -100);
            }, 200);
          }}
        >
          <ListItemText primary={sibling.firstName + ' ' + sibling.lastName} />
        </a>
      </ZoomStyledMenuItem>
    );
  });

  return (
    <>
      <div>{toRender}</div>
      {piaSiblings.length === 0 && <div>{props.labelNoEntries}</div>}
      <Grid container>
        <Grid item xs={12} sm={6}>
          {dataFromPreviousDemand.length > 0 && (
            <div style={{ display: 'flex', justifyContent: 'flex-start', marginTop: 10 }}>
              <Button
                aria-controls="customized-menu"
                aria-haspopup="true"
                color="primary"
                type="reset"
                variant="outlined"
                id="data-takeover-button"
                fullWidth={isSmallDevice}
                onClick={handleClick}
                disabled={disableControls}
              >
                {props.labelTakeoverDataFromPreviousYear}
                <ZoomArrowDownIcon
                  viewBox="0 0 80 80"
                  htmlColor={disableControls ? lightGrey.main : blue.main}
                ></ZoomArrowDownIcon>
              </Button>
              <ZoomStyledMenu
                id="customized-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                {piaSiblingsDataFromPreviousDemand}
              </ZoomStyledMenu>
            </div>
          )}
        </Grid>
        <Grid item xs={12} sm={6}>
          <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 10 }}>
            <Button
              variant="outlined"
              color="primary"
              id="add-sibling-button"
              fullWidth={isSmallDevice}
              onClick={() => addNewSibling({}, false)}
              disabled={disableControls}
            >
              {props.labelAddAdditionalPositions}
            </Button>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export function mapStateToProps(store: WIAAStore, ownProps: any): SiblingsFormProps {
  const { pifId } = ownProps.match.params;

  const props: SiblingsFormProps = {
    piaSiblings: [],
    piaSiblingsFromPreviousDemand: store.siblingsData.siblingsFromPreviousDemand,
    siblingsBeingEdited: [],
    labelFirstName: i18nLabel(store.translationLabels, 'Siblings_First_Name', 'Vorname*'),
    labelLastName: i18nLabel(store.translationLabels, 'Siblings_Last_Name', 'Nachname*'),
    labelDateOfBirth: i18nLabel(store.translationLabels, 'Siblings_Date_Of_Birth', 'Geburtsdatum*'),
    labelLivingType: i18nLabel(store.translationLabels, 'Siblings_Living_Type', 'Wohnt bei*'),
    labelDegreeOfRelation: i18nLabel(store.translationLabels, 'Siblings_Degree_Of_Relation', 'Verwandtschaftsgrad*'),
    labelFinanciallyIndependent: i18nLabel(
      store.translationLabels,
      'Siblings_Financially_Independent',
      'Ist Ihr Geschwister finanziell unabhängig von den Eltern?*',
    ),
    labelInFormation: i18nLabel(store.translationLabels, 'Siblings_In_Formation', 'Ist Ihr Geschwister in Ausbildung*'),
    labelFormationType: i18nLabel(store.translationLabels, 'Siblings_Formation_Type', 'Art der Ausbildung*'),
    labelFormationEndDate: i18nLabel(
      store.translationLabels,
      'Siblings_Formation_End_Date',
      'Ende der Ausbildung (Monat/Jahr)*',
    ),
    labelAlimonyPayment: i18nLabel(
      store.translationLabels,
      'Siblings_Alimony_Payment',
      'Erhält Ihr Geschwister Alimente?*',
    ),
    labelAlimonyAmount: i18nLabel(store.translationLabels, 'Siblings_Alimony_Amount', 'Betrag pro Jahr*'),
    labelPensionReceived: i18nLabel(
      store.translationLabels,
      'Siblings_Pension_Received',
      'Erhält Ihr Geschwister Renten?*',
    ),
    labelPensionAmount: i18nLabel(store.translationLabels, 'Siblings_Pension_Amount', 'Betrag pro Jahr*'),
    helpTextDateOfBirth: i18nLabel(
      store.translationLabels,
      'Siblings_Date_Of_Birth_Help_Text',
      'Falls Sie das Geburtsdatum Ihres Geschwisters nicht kennen, bitte "01.01.1900" eingeben.',
    ),
    helpTextDegreeOfRelation: i18nLabel(
      store.translationLabels,
      'Siblings_Degree_Of_Relation_Help_Text',
      'Bei Halb- oder Stiefgeschwister ist der Elternteil der Person in Ausbildung massgebend. Wenn das Halb- und/oder Stiefgeschwister nicht im Haushalt eines Elterteils der Person in Ausbildung wohnt, bitte eigener Hauhalt anwählen.',
    ),
    helpTextFinanciallyIndependent: i18nLabel(
      store.translationLabels,
      'Siblings_Financially_Independent_Help_Text',
      'Ob Ihr Geschwister als finanziell unabhängig berücksichtigt wird, entnehmen können Sie im Zweifelsfall in unseren FAQ nachlesen.',
    ),
    helpTextInFormation: i18nLabel(
      store.translationLabels,
      'Siblings_In_Formation_Help_Text',
      'Ob Ihr Geschwister als in nachobligatorischer Ausbildung berücksichtigt wird, entnehmen können Sie im Zweifelsfall in unseren FAQ nachlesen.',
    ),
    labelNoEntries: i18nLabel(store.translationLabels, 'Siblings_No_Entries', 'Keine Einträge'),
    labelAddAdditionalPositions: i18nLabel(
      store.translationLabels,
      'Siblings_Add_Additional_Positions',
      'Weitere Position hinzufügen',
    ),
    labelSave: i18nLabel(store.translationLabels, 'Siblings_Save', 'Speichern'),
    labelCancel: i18nLabel(store.translationLabels, 'Siblings_Cancel', 'Löschen'),
    labelTakeoverDataFromPreviousYear: i18nLabel(
      store.translationLabels,
      'Siblings_Takeover_Data',
      'Daten aus Vorjahr übernehmen',
    ),
    livingTypeType: store.translationOptions.livingtypes
      ? [
          { value: '0', name: getDefaultSelectOption(store.translationLabels) },
          ...store.translationOptions.livingtypes.map((option) => {
            return { value: option.id.toString(), name: option.name };
          }),
        ]
      : [],
    degreeOfRelationship: store.translationOptions['degree-of-relationships']
      ? [
          { value: '0', name: getDefaultSelectOption(store.translationLabels) },
          ...store.translationOptions['degree-of-relationships'].map((option) => {
            return { value: option.id.toString(), name: option.name };
          }),
        ]
      : [],
    toggleButtonsTypes: store.translationOptions['toggle-buttons']
      ? [
          ...store.translationOptions['toggle-buttons'].map((option) => {
            return { value: option.id.toString(), name: option.name };
          }),
        ]
      : [],
    errorLabels: store.translationLabels,
    pifId: Number(pifId),
  };

  const data: SiblingsDataStore = store.siblingsData;
  if (data && data.siblings) {
    props.piaSiblings = data.siblings.map((sibling) => siblingData2sibling(sibling, false));
  }

  return props;
}

export default withRouter(connect(mapStateToProps)(SiblingsForm));
