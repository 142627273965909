import { Action } from 'redux';
import { ErrorHandlingAction, ErrorHandlingActionType } from '../store/error-handling/actions';
import { RoutingAction, RoutingRedirectToAction } from '../store/routing/actions';

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export const handleRequestFailureInEpic = (error: any): ErrorHandlingActionType => {
  if (error.status === 401) {
    window.location.href = '/login';
  }

  if (error.status === 403) {
    if (error.response?.ExceptionType == 2 || error.response?.ExceptionType == 3) {
      window.location.href = '/mandatory-power-of-attorney';
    } else {
      window.location.href = '/error-access-forbidden';
    }
  }
  return {
    type: ErrorHandlingAction.RAISE_ERROR,
    payload: error,
  };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export const redirectToDataUnavailableOr = (error: any, defaultAction: Action, dossieId: number): Action => {
  if (error.status === 404 && pageContainsPersonalData()) {
    const redirectAction: RoutingRedirectToAction = {
      type: RoutingAction.REDIRECT_TO,
      payload: `/error-unavailable-information/${dossieId}`,
    };
    return redirectAction;
  }
  return defaultAction;
};

const pageContainsPersonalData = (): boolean => {
  const path = window.location.pathname;
  return (
    path.includes('/my-data/dossier-intranet/mydata') ||
    path.includes('/edit-contact-data/dossier-intranet/mydata/') ||
    path.includes('/edit-personal-data/dossier-intranet/mydata') ||
    path.includes('/edit-address-data/dossier-intranet/mydata') ||
    path.includes('/edit-payment-data/dossier-intranet/mydata/') ||
    path.includes('/my-data/dossier-intranet/mydemand') ||
    path.includes('/my-contracts') ||
    path.includes('dossier-intranet/myformation') ||
    path.includes('/messages/') ||
    path.includes('/notifications/')
  );
};
