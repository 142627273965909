// Map global store to component parameters

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { i18nLabel } from '../../store/i18n/helpers';
import { WIAAStore } from '../../store/types';
import { EducationData } from '../../store/education-data/types';
import { getDefaultSelectOption, inSwitzerlandTypeOptions } from '../../utils/form-data';
import { extractToggleValueNumber, extractToggleValue } from '../../utils/object-manipulation';
import { EducationDataForm } from './EducationDataForm';
import { EducationDataFormProps } from './EducationDataFormProps';

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export function mapEducationStateToProps(store: WIAAStore, ownProps: any): EducationDataFormProps {
  const { demandId } = ownProps.match.params;
  const props: EducationDataFormProps = {
    isEducationChangeForm: false,
    title: i18nLabel(store.translationLabels, 'Education_Title', 'Ausbildung'),
    labelDegreeProgramId: i18nLabel(store.translationLabels, 'Education_Degree_Program_Id', 'Fachrichtung*'),
    labelEducationType: i18nLabel(store.translationLabels, 'Education_Type', 'Ausbildung*'),
    labelEducationFacilityName: i18nLabel(
      store.translationLabels,
      'Education_Facility_Name',
      'Ausbildungsstätte/-art*',
    ),
    labelEducationFacilityLocation: i18nLabel(
      store.translationLabels,
      'Education_Facility_Location',
      'Ort der Ausbildungsstätte*',
    ),
    labelEducationDegreeDesignation: i18nLabel(
      store.translationLabels,
      'Education_Degree_Designation',
      'Bezeichnung des Abschlusses*',
    ),
    labelEducationDuration: i18nLabel(store.translationLabels, 'Education_Duration', 'Dauer*'),
    labelEducationWorkload: i18nLabel(
      store.translationLabels,
      'Education_Workload',
      'Zu welchem Pensum machen Sie die Ausbildung?*',
    ),
    labelEducationYearRepeat: i18nLabel(
      store.translationLabels,
      'Education_Year_Repeat',
      'Machen Sie ein Wiederholungsjahr?*',
    ),
    labelEducationChanged: i18nLabel(
      store.translationLabels,
      'Education_Changed',
      'Haben Sie Ihre Ausbildung seit dem letzten Stipendienantrag gewechselt?*',
    ),
    labelEducationOld: i18nLabel(store.translationLabels, 'Education_Old', 'Alter Ausbildungs- oder Studiengang*'),
    labelEducationNew: i18nLabel(store.translationLabels, 'Education_New', 'Neuer Ausbildungs- oder Studiengang*'),
    labelEducationAdditionalOccupation: i18nLabel(
      store.translationLabels,
      'Education_Additional_Occupation',
      'Gehen Sie neben Ihrer Ausbildung einer zusätzl. Erwerbstätigkeit nach?*',
    ),
    labelEducationCredits: i18nLabel(store.translationLabels, 'Education_Credits', 'Kreditpunkte (ECTS) pro Jahr*'),
    labelEducationDegree: i18nLabel(store.translationLabels, 'Education_Degree', 'Studiengang*'),
    labelVocationalBaccalaureate: i18nLabel(
      store.translationLabels,
      'Education_Vocational_Baccalaureate',
      'Absolvieren Sie die Berufsmatura?*',
    ),
    labelSave: i18nLabel(store.translationLabels, 'Education_Save', 'Speichern'),
    labelInSwitzerland: i18nLabel(store.translationLabels, 'Education_In_Switzerland', 'Ausbildungsort*'),
    helpTextEducationType: i18nLabel(
      store.translationLabels,
      'Education_Type_Help_Text',
      'Der Vorkurs ist nur beitragsberechtigt, wenn er für Ihr Studium vorausgesetzt wird.',
    ),
    helpTextEducationFacilityLocation: i18nLabel(
      store.translationLabels,
      'Education_Facility_Location_Help_Text',
      'Falls Sie Ihre Ausbildung an mehreren Orten absolvieren, bitte sämtliche Orte angeben.',
    ),
    helpTextEducationDuration: i18nLabel(
      store.translationLabels,
      'Education_Duration_Help_Text',
      'Als Ausbildungsbildungsbeginn an Universitäten, ETH und Fachhochschulen gilt der Monat des Vorlesungsstarts.',
    ),
    helpTextEducationYearRepeat: i18nLabel(
      store.translationLabels,
      'Education_Year_Repeat_Help_Text',
      'Wenn Sie Ihr Ausbildungsjahr wiederholen, müssen Sie ja anwählen',
    ),
    helpTextEducationChanged: i18nLabel(
      store.translationLabels,
      'Education_Changed_Help_Text',
      'Wenn Sie Ihre Ausbildung oder Ihre Ausbildungsrichtung vom Vorjahr gewechselt haben, müssen sie ja anwählen',
    ),
    helpTextEducationDegree: i18nLabel(
      store.translationLabels,
      'Education_Degree_Help_Text',
      'Bitte wählen Sie den aktuellen Studiengang.',
    ),
    errorLabels: store.translationLabels,
    demandId: Number(demandId),
    degreePrograms: store.translationOptions['degree-programs']
      ? [
          { value: '0', name: getDefaultSelectOption(store.translationLabels) },
          ...store.translationOptions['degree-programs'].map((option) => {
            return { value: option.id.toString(), name: option.name };
          }),
        ]
      : [],
    toggleButtonsTypes: store.translationOptions['toggle-buttons']
      ? [
          ...store.translationOptions['toggle-buttons'].map((option) => {
            return { value: option.id.toString(), name: option.name };
          }),
        ]
      : [],
    educationWorkloadTypes: store.translationOptions['education-workloads']
      ? [
          ...store.translationOptions['education-workloads'].map((option) => {
            return { value: option.id.toString(), name: option.name };
          }),
        ]
      : [],
    inSwitzerlandTypes: inSwitzerlandTypeOptions,
    availableInstitutionTypes: store.educationData.availableInstitutionTypes,
    availableInstitutions: store.educationData.availableInstitutions,
    availableDegreePrograms: store.educationData.availableDegreePrograms,
  };

  const data: EducationData = store.educationData.education;
  if (data) {
    props.educationType = data.formationType ? data.formationType.toString() : '';
    props.educationFacilityName = data.formationInstitution;
    props.educationFacilityLocation = data.formationLocation;
    props.educationDegreeDesignation = data.formationCertificate;
    props.educationDurationStart = data.formationStartDate ? new Date(data.formationStartDate) : undefined;
    props.educationDurationEnd = data.formationEndDate ? new Date(data.formationEndDate) : undefined;
    props.educationWorkload = extractToggleValueNumber(data.formationFullTime);
    props.educationYearRepeat = extractToggleValue(data.repeatingYear);
    props.educationChanged = extractToggleValue(data.changedFormationSinceLastDemand);
    props.educationOld = data.oldFormation;
    props.educationNew = data.newFormation;
    props.educationAdditionalOccupation = extractToggleValue(data.doingAdditionalWork);
    props.educationCredits = data.ectsPoints ? data.ectsPoints.toString() : '';
    props.educationDegree = data.degreeProgram ? data.degreeProgram.toString() : '';
    props.inSwitzerland = data.inSwitzerland ?? true;
    props.educationTypeId = data.educationTypeId;
    props.facilityId = data.facilityId;
    props.degreeId = data.degreeProgramId;
    props.vocationalBaccalaureate = extractToggleValue(data.vocationalBaccalaureate);
  }
  return props;
}

// default export the connected component
export default withRouter(connect(mapEducationStateToProps)(EducationDataForm));
