import { NavigationOrder, NavigationTab } from '../../pages/demand-overview/types';

export const filterNavigationOrder = (orderToFilter: NavigationOrder): NavigationOrder => {
  const filtered: NavigationOrder = {
    tabs: [],
  };

  const tabIndex = 1;

  orderToFilter.tabs.forEach((tab: NavigationTab) => {
    if (tab.hidden !== true) {
      const tabToAdd: NavigationTab = {
        ...tab,
        forms: tab.forms
          .filter((form) => form.hidden !== true)
          .map((form) => {
            return { ...form, tabIndex: tabIndex };
          }),
      };

      if (tabToAdd.forms.length > 0) {
        filtered.tabs.push(tabToAdd);
      }
    }
  });

  return filtered;
};
