import React, { ReactElement } from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import { black } from './../../theme';

export function ZoomPartnerIcon(props: SvgIconProps): ReactElement {
  const color = props.htmlColor ? props.htmlColor : black.main;
  return (
    <SvgIcon {...props}>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <path d="M0 0H80V80H0z" transform="translate(-848 -57) translate(848 57)" />
            <path
              fill={color}
              fillRule="nonzero"
              d="M38.62 47.73h-1.79l-1.66-2.54c1.953-1.487 3.269-3.66 3.68-6.08 1.162-.326 1.974-1.373 2-2.58v-2.4c-.044-.874-.515-1.67-1.26-2.13v-1c.88-1.206 1.037-2.795.41-4.15-1.18-1.988-3.373-3.147-5.68-3-1.744.015-3.447.536-4.9 1.5-4.59.11-6.65 1.72-6.65 5.2V32c-.729.461-1.188 1.248-1.23 2.11v2.4c.024 1.205.838 2.251 2 2.57.397 2.364 1.65 4.5 3.52 6l-1.74 2.66h-1.8c-4 0-6.94 2.7-6.94 6.42v1.34c0 .552.448 1 1 1s1-.448 1-1v-1.35c0-2.64 2-4.42 4.94-4.42h2c.547-.001 1.057-.275 1.36-.73l1.91-2.91c.762.31 1.577.469 2.4.47.755 0 1.503-.135 2.21-.4L35.26 49c.305.465.824.743 1.38.74h2c3 0 4.94 1.78 4.94 4.42v1.34c0 .552.448 1 1 1s1-.448 1-1v-1.35c-.02-3.72-2.94-6.42-6.96-6.42zm-13.19-9.52l-.07-.79-.79-.11c-.63-.1-1-.38-1-.78v-2.4c0-.11.2-.4.72-.69l.51-.29v-2.62c0-1.33 0-3.16 5-3.21h.31l.26-.18c1.167-.837 2.564-1.294 4-1.31 1.541-.125 3.028.6 3.88 1.89.365.81.198 1.762-.42 2.4l-.21.27v2.76l.51.29c.52.29.72.58.72.69v2.4c0 .39-.39.69-1 .78l-.79.11-.07.8c-.24 3-2.67 6.32-5.76 6.32s-5.56-3.33-5.8-6.33z"
              transform="translate(-848 -57) translate(848 57)"
            />
            <path
              fill={color}
              fillRule="nonzero"
              d="M48.66 47.73h-1.74L46 45.57c1.5-1.56 1.77-3.45 2-5.8 1.131-.343 1.911-1.378 1.93-2.56v-2.4c-.044-.861-.502-1.648-1.23-2.11v-1.49c0-3.55-2.15-5.16-7-5.21-.552 0-1 .448-1 1s.448 1 1 1c5 .05 5 1.88 5 3.21v2.62l.51.29c.53.29.72.58.72.69v2.4c0 .4-.38.69-1 .78l-.79.11-.14.9c-.27 3.38-.4 4.93-3.08 6.35-.34.153-.568.483-.589.856-.02.373.168.727.49.917.321.19.722.185 1.039-.013l.49-.28.83 2 .09.16c.301.47.822.753 1.38.75h2c3 0 5 1.78 5 4.42v1.34c0 .552.448 1 1 1s1-.448 1-1v-1.35c-.04-3.72-2.96-6.42-6.99-6.42z"
              transform="translate(-848 -57) translate(848 57)"
            />
            <path
              fill={color}
              fillRule="nonzero"
              d="M57.62 47.73h-1.74L55 45.57c1.5-1.56 1.77-3.45 2-5.8 1.131-.343 1.911-1.378 1.93-2.56v-2.4c-.044-.861-.502-1.648-1.23-2.11v-1.49c0-3.55-2.14-5.16-7-5.21-.552 0-1 .448-1 1s.448 1 1 1c5 .05 5 1.88 5 3.21v2.62l.51.29c.53.29.72.58.72.69v2.4c0 .4-.38.69-1 .78l-.79.11-.14.9c-.27 3.38-.4 4.93-3.08 6.35-.34.153-.568.483-.589.856-.02.373.168.727.49.917.321.19.722.185 1.039-.013l.49-.28.83 2 .09.16c.303.468.822.75 1.38.75h2c3 0 4.94 1.78 4.94 4.42v1.34c0 .552.448 1 1 1s1-.448 1-1v-1.35c-.03-3.72-2.95-6.42-6.97-6.42z"
              transform="translate(-848 -57) translate(848 57)"
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}
