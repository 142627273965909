import React, { ReactElement } from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import { black } from './../../theme';

export function ZoomParentIcon(props: SvgIconProps): ReactElement {
  const color = props.htmlColor ? props.htmlColor : black.main;
  return (
    <SvgIcon {...props}>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <path d="M0 0H80V80H0z" transform="translate(-848 -257) translate(848 257)" />
            <path
              fill={color}
              fillRule="nonzero"
              d="M33.88 54.32c-.195-.07-.386-.154-.57-.25-.35-.477-.577-1.034-.66-1.62 1.552-1.904 2.45-4.256 2.56-6.71.093-2.334-1.1-4.532-3.107-5.726-2.008-1.195-4.508-1.195-6.516 0-2.007 1.194-3.2 3.392-3.107 5.726.11 2.533 1.06 4.957 2.7 6.89-.089.523-.301 1.016-.62 1.44-.184.096-.375.18-.57.25-1.521.494-2.815 1.516-3.65 2.88-1.29 2.22-1 5.95-.95 6.36.047.517.481.912 1 .91h.09c.55-.053.952-.54.9-1.09-.09-1-.14-3.76.69-5.18.529-.87 1.34-1.53 2.3-1.87C25.1 57.24 26.8 59 29 59c2.2 0 3.73-1.8 4.37-2.74 1.011.339 1.871 1.022 2.43 1.93.83 1.42.78 4.22.69 5.18-.025.265.057.53.228.735.17.204.416.332.682.355h.09c.519.002.953-.393 1-.91 0-.41.35-4.14-.95-6.36-.838-1.363-2.136-2.381-3.66-2.87zm-5-13c2.317.008 4.223 1.826 4.34 4.14-1.55-.029-3.095-.183-4.62-.46-1.24-.291-2.408-.825-3.44-1.57.792-1.272 2.181-2.05 3.68-2.06l.04-.05zm-4.33 4c1.12.732 2.364 1.257 3.67 1.55 1.574.338 3.172.549 4.78.63-.66 2.46-2.46 5.07-4.11 5.07-2.06 0-4.36-4.07-4.36-6.83-.05-.13-.03-.24-.02-.36l.04-.06zM29 57c-1.144-.165-2.176-.776-2.87-1.7.314-.39.56-.83.73-1.3.606.36 1.296.553 2 .56.759-.013 1.499-.234 2.14-.64.162.46.392.896.68 1.29-.568.979-1.558 1.64-2.68 1.79zM47 53.35c-.552 0-1 .448-1 1s.448 1 1 1 1-.448 1-1-.448-1-1-1zM47 45.31c-.552 0-1 .448-1 1s.448 1 1 1 1-.448 1-1-.448-1-1-1zM47 49.33c-.552 0-1 .448-1 1s.448 1 1 1 1-.448 1-1-.448-1-1-1z"
              transform="translate(-848 -257) translate(848 257)"
            />
            <path
              fill={color}
              fillRule="nonzero"
              d="M53.77 38c-.983-.907-1.8-1.98-2.41-3.17 2.528-2.737 4.008-6.278 4.18-10 0-4.65-3.77-8.42-8.42-8.42s-8.42 3.77-8.42 8.42V25.52c-.01.066-.01.134 0 .2.42 4.73 4 11.18 8.38 11.18.944-.019 1.865-.3 2.66-.81.458.784.969 1.536 1.53 2.25l-4.15 3.05-1.48-1.27c-.272-.232-.646-.302-.983-.183-.337.12-.585.409-.65.76-.066.351.061.71.333.943l2.09 1.79c.181.155.412.24.65.24.213-.002.42-.072.59-.2l5.09-3.75c.195.122.413.203.64.24 1.06.14 4.81 1.37 4.81 3.69v19.82c0 .552.448 1 1 1s1-.448 1-1V43.61c.04-3.95-5.21-5.5-6.44-5.61zM40.85 26.39c.812-.069 1.603-.293 2.33-.66.682-.443 1.318-.952 1.9-1.52.72.602 1.501 1.129 2.33 1.57 1.017.405 2.073.706 3.15.9h.18c.552.05 1.04-.358 1.09-.91.05-.552-.358-1.04-.91-1.09-.91-.14-1.804-.368-2.67-.68-.915-.527-1.776-1.144-2.57-1.84-.398-.348-.998-.326-1.37.05-.657.664-1.377 1.264-2.15 1.79-.458.21-.948.339-1.45.38.193-3.48 3.127-6.17 6.61-6.061 3.484.108 6.244 2.976 6.22 6.461 0 3.79-3.11 10.07-6.42 10.07-2.88.01-5.6-4.73-6.27-8.46zM41.43 37.68c-.461-.234-1.025-.078-1.3.36-.335.268-.719.468-1.13.59-.914.037-1.774.445-2.38 1.13-.374.404-.352 1.034.05 1.41.18.18.426.277.68.27.276.002.54-.11.73-.31.245-.303.61-.486 1-.5 1.108-.156 2.11-.741 2.79-1.63.234-.487.039-1.07-.44-1.32z"
              transform="translate(-848 -257) translate(848 257)"
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}
