export const ahvn13Format = (value: string): string => {
  if (value === undefined || value === null || value === '' || !Number(value) || value.length !== 13) {
    return value;
  }
  return value.substring(0, 3) + '.' + value.substring(3, 7) + '.' + value.substring(7, 11) + '.' + value.substring(11);
};

export const ibanFormat = (value: string): string => {
  if (value === undefined || value === null) {
    return value;
  }

  let valueWithoutWhitespaces = value.split(' ').join('');
  if (valueWithoutWhitespaces.substring(0, 2) === 'CH') {
    valueWithoutWhitespaces = valueWithoutWhitespaces.substring(2);
  }
  if (valueWithoutWhitespaces.length !== 19) {
    return value;
  }
  const iban =
    valueWithoutWhitespaces.substring(0, 2) +
    ' ' +
    valueWithoutWhitespaces.substring(2, 6) +
    ' ' +
    valueWithoutWhitespaces.substring(6, 10) +
    ' ' +
    valueWithoutWhitespaces.substring(10, 14) +
    ' ' +
    valueWithoutWhitespaces.substring(14, 18) +
    ' ' +
    valueWithoutWhitespaces.substring(18);
  return 'CH' + iban;
};

export const multipleFirstNameFormat = (value: string): string => {
  if (value === undefined || value === null) {
    return value;
  }

  return value.split('+').join(' ');
};

export const trailingNumbersFormat = (value: string | number): string => {
  if (value === undefined || value === null) {
    return value;
  }
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'");
};
