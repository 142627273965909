import React, { ReactElement } from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import { blue } from './../../theme';

export function ZoomArrowDownIcon(props: SvgIconProps): ReactElement {
  const color = props.htmlColor ? props.htmlColor : blue.main;
  return (
    <SvgIcon {...props}>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <path d="M0 0H80V80H0z" transform="translate(-356 -57) translate(356 57)" />
            <path
              fill={color}
              fillRule="nonzero"
              d="M40.52 53c-.528 0-1.035-.208-1.41-.58L17.23 30.56c-.781-.781-.781-2.049 0-2.83.781-.781 2.049-.781 2.83 0L40.52 48.2 61 27.73c.781-.781 2.049-.781 2.83 0 .781.781.781 2.049 0 2.83L41.94 52.44c-.38.367-.891.568-1.42.56z"
              transform="translate(-356 -57) translate(356 57)"
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}
