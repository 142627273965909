import { ZoomNotificationProps } from '../components/base-page-components/notification/ZoomNotification';
import { Notification } from '../store/notifications/types';

// Event Type Ids
export const SUCCESS_EVENT_ID = 100;
export const WARNING_EVENT_ID = 120;
export const ERROR_EVENT_ID = 110;

export const formatUnreadNotifications = (unreadNotifications: Notification[]): ZoomNotificationProps[] => {
  return unreadNotifications.map((notification) => ({
    id: notification.id,
    labelText: notification.title,
    labelButtonText: notification.message,
    clientEventIds: notification.clientEvents
      ? notification.clientEvents.map((clientEvent) => (clientEvent.id ? clientEvent.id : -1))
      : [],
    isSuccess:
      notification.clientEvents &&
      notification.clientEvents.length > 0 &&
      notification.clientEvents[notification.clientEvents.length - 1].caseStep?.eventType?.id === SUCCESS_EVENT_ID,
    isWarning:
      notification.clientEvents &&
      notification.clientEvents.length > 0 &&
      notification.clientEvents[notification.clientEvents.length - 1].caseStep?.eventType?.id === WARNING_EVENT_ID,
    isError:
      notification.clientEvents &&
      notification.clientEvents.length > 0 &&
      notification.clientEvents[notification.clientEvents.length - 1].caseStep?.eventType?.id === ERROR_EVENT_ID,
  }));
};
