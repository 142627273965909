import { Breadcrumbs, CircularProgress, Container, Link } from '@material-ui/core';
import React, { ReactElement, useEffect } from 'react';
import ZoomHeader, {
  mapStoreToHeaderProps,
  ZoomHeaderProps,
} from './../../components/base-page-components/header/ZoomHeader';
import ZoomFooter, {
  mapStoreToFooterProps,
  ZoomFooterProps,
} from './../../components/base-page-components/footer/ZoomFooter';
import { withRouter } from 'react-router-dom';
import './LoanContracts.scss';
import { LoanContractInfo, LoanContractInfoProps } from '../../components/loan-contract-info/LoanContractInfo';
import { Action } from 'redux';
import { WIAAStore } from '../../store/types';
import { connect } from 'react-redux';
import { i18nLabel } from '../../store/i18n/helpers';
import { MyContractsAction, MyContractsAllContractsGetAction } from '../../store/my-contracts/actions';
import { MyContractsDataStore } from '../../store/my-contracts/types';
import moment from 'moment';
import { mapStoreToHolderDropDownProps } from '../../utils/mappers';
import { HolderDropDownProps } from '../../components/base-page-components/holder-dropdown/HolderDropDown';
import { HolderDataAction, HolderDataGetAction } from '../../store/holder-data/actions';
import { NavigationItems } from '../../components/base-page-components/navigation/Navigation';

export interface LoanContractsProps {
  pageTitle: string;
  loanContracts: Array<LoanContractInfoProps>;
  headerProps: ZoomHeaderProps;
  footerProps: ZoomFooterProps;
  breadcrumbLabels: Array<string>;
  labelBackArrow: string;
  dossierId: number;
  contractPaymentPeriodOptions?: { name: string; value: string }[];
  contractTypes?: { name: string; value: string }[];
  isLoading?: boolean;
  holderDropDownProps: HolderDropDownProps;
  dispatch?: (action: Action) => void;
}

export const LoanContracts = (props: LoanContractsProps): ReactElement => {
  useEffect(() => {
    if (props.dispatch) {
      const getUserContractsData: MyContractsAllContractsGetAction = {
        type: MyContractsAction.GET_ALL_CONTRACTS,
        payload: props.dossierId,
      };
      props.dispatch(getUserContractsData);
    }
  }, []);

  useEffect(() => {
    if (props.dispatch && props.dossierId) {
      const getHolderData: HolderDataGetAction = {
        type: HolderDataAction.GET,
      };
      props.dispatch(getHolderData);
    }
  }, []);

  const contracts = props.loanContracts.reverse().map((contract) => {
    const loanContractInfoProps: LoanContractInfoProps = { ...contract, ...props, isLoading: contract.isLoading };
    return <LoanContractInfo {...loanContractInfoProps} key={contract.id} />;
  });

  return (
    <>
      <ZoomHeader {...props.headerProps} dispatch={props.dispatch} />
      <main className="main">
        {props.isLoading ? (
          <div style={{ textAlign: 'center' }}>
            <CircularProgress />
          </div>
        ) : (
          <>
            <Container>
              <div id="main-page-container">
                <div className="main-content-limited">
                  <Breadcrumbs separator=">" aria-label="breadcrumb">
                    <Link
                      style={{ fontFamily: 'Proxima Nova Cond' }}
                      color="inherit"
                      href={'/dashboard/dossier/' + props.dossierId}
                    >
                      <b>{props.breadcrumbLabels && props.breadcrumbLabels[0]}</b>
                    </Link>
                    <Link style={{ fontFamily: 'Proxima Nova Cond' }} color="inherit">
                      {props.breadcrumbLabels && props.breadcrumbLabels[1]}
                    </Link>
                  </Breadcrumbs>
                  <NavigationItems
                    holderDropDownProps={props.holderDropDownProps}
                    mainPageTopInfo={false}
                    title={props.pageTitle}
                    dispatch={props.dispatch}
                    labelBackArrow={props.labelBackArrow}
                  />
                </div>

                <div className="main-content-limited">
                  <div>{contracts}</div>
                </div>
              </div>
            </Container>
          </>
        )}
      </main>
      <ZoomFooter {...props.footerProps} />
    </>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export const mapStateToProps = (store: WIAAStore, urlProps: any): LoanContractsProps => {
  const { dossierId } = urlProps.match.params;
  const headerProps: ZoomHeaderProps = mapStoreToHeaderProps(store, dossierId);
  const footerProps: ZoomFooterProps = mapStoreToFooterProps(store);

  const props: LoanContractsProps = {
    headerProps: headerProps,
    footerProps: footerProps,
    dossierId: dossierId,
    holderDropDownProps: mapStoreToHolderDropDownProps(store, parseInt(dossierId)),
    pageTitle: i18nLabel(store.translationLabels, 'Loan_Contracts_Title', 'Alle Darlehensverträge'),
    labelBackArrow: i18nLabel(store.translationLabels, 'Loan_Contracts_Back_Arrow', 'Zurück'),
    breadcrumbLabels: [
      i18nLabel(store.translationLabels, 'Loan_Contracts_Breadcrumb_Label_1', 'Übersicht'),
      i18nLabel(store.translationLabels, 'Loan_Contracts_Breadcrumb_Label_2', 'Alle Darlehensverträge'),
    ],
    loanContracts: [],
    isLoading: false,
  };

  const data: MyContractsDataStore = store.myContractsData;
  if (data && data.myContracts) {
    props.loanContracts = data.myContracts.map((contract) => ({
      dossierId: dossierId,
      id: contract.id ? contract.id : 0,
      isLoading: contract.isLoading,
      showOnlyInfoText: contract.convention === undefined || contract.convention === null,
      name: contract.contractType ? contract.contractType.toString() : '',
      loanAmount: contract.accountTotal?.totalCharged ? contract.accountTotal.totalCharged : 0,
      reimbursedAmount: contract.accountTotal?.totalRepayed ? contract.accountTotal.totalRepayed : 0,
      remainingAmount: contract.accountTotal?.balance ? contract.accountTotal.balance : 0,
      reimbursementStartDate: moment(contract.convention?.conventionStartDate).toDate(),
      periodicity: contract.convention?.amortisationPeriod ? contract.convention.amortisationPeriod : 0,
      rate: contract.convention?.amortisationValue ? contract.convention.amortisationValue : 0,
      receivedUntil: moment(contract.accountTotal?.lastEntryDate).toDate(),
      scholarshipEndDate: moment(contract.formationEndDate).toDate(),
      amortizationDueFrom: contract.amortizationDueFrom ? contract.amortizationDueFrom : null,
      labelLoanAmount: i18nLabel(store.translationLabels, 'Loan_Contracts_Amount', 'Darlehen'),
      labelReimbursedAmount: i18nLabel(store.translationLabels, 'Loan_Contracts_Reimbursed_Amount', 'Zurückbezahlt'),
      labelRemainingAmount: i18nLabel(store.translationLabels, 'Loan_Contracts_Remeining_Amount', 'Offener Betrag'),
      labelReimbursementStartDate: i18nLabel(
        store.translationLabels,
        'Loan_Contracts_Reimbursed_Start_Date',
        'Amortisationspflichtig ab',
      ),
      labelPeriodicity: i18nLabel(store.translationLabels, 'Loan_Contracts_Periodicity', 'Periodizität'),
      labelRate: i18nLabel(store.translationLabels, 'Loan_Contracts_Rate', 'Rate pro Periode'),
      labelReceivedUntil: i18nLabel(store.translationLabels, 'Loan_Contracts_Received_Until', 'Letzte Buchung'),
      labelScholarshipEndDate: i18nLabel(
        store.translationLabels,
        'Loan_Contracts_Scholarship_End_Date',
        'Ausbildungsabschluss-/abbruch',
      ),
      labelDocumentDownload: i18nLabel(store.translationLabels, 'Loan_Contracts_Document_Download', 'Saldoliste'),
      labelChangeContract: i18nLabel(
        store.translationLabels,
        'Loan_Contracts_Change_Contract',
        'Rückzahlkonditionen ändern',
      ),
      labelForbearanceRequest: i18nLabel(
        store.translationLabels,
        'Loan_Contracts_Forbearance_Request',
        'Stundungsgesuch',
      ),
      labelNoPlanArranged: i18nLabel(
        store.translationLabels,
        'Loan_Contracts_No_Plan_Arranged_Info',
        'Der Umfang und der Zeitpunkt der Darlehensrückzahlung (Rückzahlplan) werden erst nach Abschluss/Abbruch Ihrer Ausbildung hier angezeigt. Der aktuelle gesamte Darlehensstand ist auf der Übersichtsseite ersichtlich oder entnehmen Sie der untenstehenden Saldoliste mit Stand per 31.12.',
      ),
      contractPaymentPeriodOptions: store.translationOptions['contract-payment-periods']
        ? [
            ...store.translationOptions['contract-payment-periods'].map((option) => {
              return { value: option.id.toString(), name: option.name };
            }),
          ]
        : [],
      contractTypes: store.translationOptions.contracts
        ? [
            ...store.translationOptions.contracts.map((option) => {
              return { value: option.id.toString(), name: option.name };
            }),
          ]
        : [],
    }));
  }
  return props;
};

export default withRouter(connect(mapStateToProps)(LoanContracts));
