import React, { ReactElement, useState } from 'react';
import OutboxMessage, { OutboxMessageProps } from './OutboxMessage';
import InboxMessage, { InboxMessageProps } from './InboxMessage';
import ReplyWithFile, { ReplyWithFileProps } from './ReplyWithFile';
import { ZoomAttachmentIcon } from '../icons/ZoomAttachmentIcon';
import { ZoomArrowUpIcon } from '../icons/ZoomArrowUpIcon';
import { ZoomArrowDownIcon } from '../icons/ZoomArrowDownIcon';
import { black } from '../../theme';
import { NotificationAction, NotificationMarkAsReadAction, NotificationTypes } from '../../store/notifications/actions';
import { CaseCreateDto } from '../../store/notifications/types';
import { ERROR_EVENT_ID } from '../../utils/unread-notifications';

export interface ConversationProps {
  messages: Array<InboxMessageProps | OutboxMessageProps>;
  replyProps: ReplyWithFileProps;
  replyLabel: string;
  title: string;
  date: string;
  supportNumberLabel: string;
  supportNumber: string;
  hasAttachments?: boolean;
  hasNewMessages?: boolean;
  isOpened: boolean;
  dossierId: number;
  caseId: number;
  stepId?: number;
  typeId: number;
  answerFromPortalAllowed: boolean;
  dispatch?: (action: NotificationTypes) => void;
}

const Conversation = (props: ConversationProps): ReactElement => {
  //console.log(props.messages);
  const messageElements = props.messages.map((messageProps, index) => {
    if (messageProps.inbox) {
      return <InboxMessage key={index} {...messageProps}></InboxMessage>;
    }
    return <OutboxMessage key={index} {...messageProps}></OutboxMessage>;
  });

  const [isOpened, setIsOpened] = useState(props.isOpened ? props.isOpened : false);
  const [hasNewMessages, setHasNewMessages] = useState(props.hasNewMessages ? props.hasNewMessages : false);

  const toggleState = () => {
    if (!isOpened && props.dispatch) {
      const messagesToMarkAsRead = props.messages
        .filter((message) => message.eventTypeId !== ERROR_EVENT_ID)
        .map((message) => message.clientEventId);
      const markAsReadAction: NotificationMarkAsReadAction = {
        type: NotificationAction.MARK_AS_READ,
        dossierId: props.dossierId,
        caseId: props.caseId,
        clientEventIds: messagesToMarkAsRead,
      };
      if (markAsReadAction.clientEventIds.length > 0) {
        props.dispatch(markAsReadAction);
        setHasNewMessages(messagesToMarkAsRead.length < props.messages.length);
      }
    }
    setIsOpened(!isOpened);
  };

  const stateClass = isOpened ? 'opened' : 'closed';
  const containerClasses = `conversation-container ${stateClass}`;

  const onReply = (message: CaseCreateDto) => {
    if (props.dispatch) {
      props.dispatch({
        type: NotificationAction.SEND_TEXT_MESSAGE_ANSWER,
        payload: message,
        dossierId: props.dossierId,
      });

      const messagesToMarkAsRead = props.messages
        .filter((message) => message.eventTypeId === ERROR_EVENT_ID)
        .map((message) => message.clientEventId);
      if (messagesToMarkAsRead.length > 0) {
        const markAsReadAction: NotificationMarkAsReadAction = {
          type: NotificationAction.MARK_AS_READ,
          dossierId: props.dossierId,
          caseId: props.caseId,
          clientEventIds: messagesToMarkAsRead,
        };
        props.dispatch(markAsReadAction);
      }
    }
  };

  const replyProps: ReplyWithFileProps = {
    ...props.replyProps,
    reply: onReply,
    caseId: props.caseId,
    stepId: props.stepId,
    dispatch: props.dispatch,
  };

  const toggleIcon = isOpened ? (
    <ZoomArrowUpIcon viewBox="0 0 80 80" htmlColor={black.main}></ZoomArrowUpIcon>
  ) : (
    <ZoomArrowDownIcon viewBox="0 0 80 80" htmlColor={black.main}></ZoomArrowDownIcon>
  );
  return (
    <div className={containerClasses}>
      <a className="open-close-switch" onClick={toggleState}>
        {toggleIcon}
      </a>
      <h2 className="conversation-title subject-title">
        {hasNewMessages && <span className="new-message-indicator"></span>}
        <span className="subject-title-text">{props.title}</span>
        {props.hasAttachments && (
          <ZoomAttachmentIcon
            viewBox="0 0 80 80"
            htmlColor={black.main}
            className="attachment-icon"
          ></ZoomAttachmentIcon>
        )}
      </h2>
      <p className="subject-subheading">
        <span className="subheading-date">{props.date}</span>
        <span className="subheading-label">{props.supportNumberLabel}</span>
        <span className="subheading-number">{props.supportNumber}</span>
      </p>
      {isOpened && <div className="messages-wrapper">{messageElements}</div>}
      {isOpened && props.answerFromPortalAllowed && <h2 className="conversation-title">{props.replyLabel}</h2>}
      {isOpened && props.answerFromPortalAllowed && <ReplyWithFile {...replyProps}></ReplyWithFile>}
    </div>
  );
};

export default Conversation;
